import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Customized, DOCUMENT_TYPE_NAMES } from "../../../Utils/Recon/Recon360/Constants";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import "../Recon360.scss";
import DeleteDocTypeSubGrp from "./DeleteDocTypeSubGrp";
import EditGroupName from "./EditGroupName";

export const DocumentTypeConfigContext = createContext<any>(null);
export interface Codes {
  code: string;
  sign: number;
  addParticulars: string[];
  excludeParticulars: string[];
  group: { groupId: string; groupName: string } | null | undefined | {};
}

export interface Element {
  name: string;
  group?: { groupId: string; groupName: string };
  codes: Codes[];
}
interface Result {
  [key: string]: Codes[];
}
const DEFAULT_CODES = {
  code: "",
  sign: 0,
  addParticulars: [] as string[],
  excludeParticulars: [] as string[],
  group: { groupId: "All", groupName: "All" },
};

function getFlattenCode(code: Codes): string {
  const flatAdds = code.addParticulars.join("+");
  const flatExcs = code.excludeParticulars.join("-");
  let joinedString = "";

  if (flatAdds.length) joinedString = "+" + flatAdds;
  else if (flatExcs.length) joinedString = "-" + flatExcs;

  if (flatAdds.length && flatExcs.length) joinedString = "+" + flatAdds + "-" + flatExcs;

  return code.code + joinedString;
}

function drag(ev: React.DragEvent<HTMLButtonElement>, ele: Element, flattenCode: string) {
  ev.dataTransfer.dropEffect = "move";
  ev.dataTransfer.setData("identifier", `${ele.name}-${ele.group?.groupId}-${ele.group?.groupName}`);
  ev.dataTransfer.setData("code", flattenCode);
}

function allowDrop(ev: React.DragEvent<HTMLDivElement>) {
  ev.preventDefault();
}

type DelRow = (element: Element, index: number) => void;
const getConfigTagsPerSign = (element: Element, sign: 0 | 1 | 2, delRow: DelRow) => {
  return (
    <>
      {element.codes
        .filter((code) => code.sign === sign && code.code.trim() !== "")
        .map((code) => (
          <button
            className="config_tags"
            style={{ cursor: "move", userSelect: "text" }}
            key={Math.random()}
            draggable={true}
            onDragStart={(e) => drag(e, element, getFlattenCode(code))}
          >
            <span>
              {code.code}
              <span style={{ color: "#00c1ff" }}>
                {code.addParticulars?.length > 0 ? "+" + code.addParticulars.join("+") : ""}
              </span>
              <span style={{ color: "red" }}>
                {code.excludeParticulars?.length > 0 ? "-" + code.excludeParticulars.join(" - ") : ""}
              </span>
            </span>
            <button
              className="delete config_delete"
              aria-label="close"
              onClick={() => {
                const codeIndex = element.codes.findIndex((_code) => getFlattenCode(_code) === getFlattenCode(code));
                delRow(element, codeIndex);
              }}
            />
          </button>
        ))}
    </>
  );
};

const getConfigTags = (element: Element, delRow: DelRow) => {
  return (
    <>
      {element.codes.filter((c) => c.sign === 0).length > 0 && (
        <div className="all_row">
          <span className="transacation_type">All Transaction: </span>
          {getConfigTagsPerSign(element, 0, delRow)}
        </div>
      )}
      {element.codes.filter((c) => c.sign === 1).length > 0 && (
        <div className="debit_row">
          <span className="transacation_type">Debit Transaction: </span>
          {getConfigTagsPerSign(element, 1, delRow)}
        </div>
      )}
      {element.codes.filter((c) => c.sign === 2).length > 0 && (
        <div className="credit_row">
          <span className="transacation_type">Credit Transaction: </span>
          {getConfigTagsPerSign(element, 2, delRow)}
        </div>
      )}
      {element.codes.length === 0 && <div className="no-data">No Data to show</div>}
    </>
  );
};

const DocumentTypeConfig = (props: any) => {
  const { actor } = useContext(userContext);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [transactionValue, setTransactionValue] = useState<number>(0);
  const [saveValueDocumentType, setSaveValueDocumentType] = useState<any>(null);
  const [addElementObj, setAddElementObj] = useState<any>(null);
  const [addElementIndex, setAddElementIndex] = useState<number>(null);
  // const labels: any =
  //   props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE ? documentTypeLabelForPaymentAdvice : documentTypeLabelForLedger;
  const [documentTypeRow, setDocumentTypeRow] = useState(
    DOCUMENT_TYPE_NAMES.map((documentTypeName) => ({
      name: documentTypeName,
      codes: [DEFAULT_CODES],
    }))
  );
  const [checkedAddParticular, setCheckedAddParticular] = useState<boolean>(false);
  const [addParticularText, setAddParticularText] = useState<string>("");
  const [addParticularTextArr, setAddParticularTextArr] = useState<string[]>([]);
  const [checkedExcludeParticular, setCheckedExcludeParticular] = useState<boolean>(false);
  const [excludeParticularText, setExcludeParticularText] = useState<string>("");
  const [excludeParticularTextArr, setExcludeParticularTextArr] = useState<string[]>([]);
  const [duplicateTagName, setDuplicateTagName] = useState<string>("");
  const [openEditGroupName, setOpenEditGroupName] = useState(false);
  const [editGroupEle, setEditGroupEle] = useState();
  const [isOpenDeleteDocTypeSubGrp, setIsOpenDeleteDocTypeSubGrp] = useState(false);

  const docTypeLabels = (word: string) => {
    const replacements = {
      invoice: "Invoice",
      payment: props.subTab === "Payment Advice" ? "Discount/Rebate" : "Payment",
      debitAndCreditNotes: "DN/CN",
      reversal: "Reversal",
      interDocument: "Inter Document",
      tds: "TDS",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };

  function convertToDoctypeWithGrp(doctypeWithoutGrp) {
    const groupedByNameAndGroup = {};

    doctypeWithoutGrp?.forEach((item) => {
      const { name, codes } = item;
      if (codes?.length > 0) {
        codes.forEach((codeItem) => {
          const { code, sign, addParticulars, excludeParticulars, group } = codeItem;

          const key = `${name}-${group?.groupId}`;

          if (!groupedByNameAndGroup[key]) {
            groupedByNameAndGroup[key] = {
              name,
              group,
              codes: [],
              // codes:[{
              //   code: "",
              //   sign: 0,
              //   addParticulars: [] as string[],
              //   excludeParticulars: [] as string[],
              //   group,
              // }]
            };
          }

          groupedByNameAndGroup[key].codes.push({
            code,
            sign,
            addParticulars,
            excludeParticulars,
            group,
          });
        });
      } else {
        groupedByNameAndGroup[name] = {
          name,
          group: { groupId: item.group?.groupId || "All", groupName: item.group?.groupName || "All" },
          codes: [],
          // codes:[{
          //   code: "",
          //   sign: 0,
          //   addParticulars: [] as string[],
          //   excludeParticulars: [] as string[],
          //   group:item.group || "All",
          // }]
        };
      }
    });

    const doctypeWithGrp = Object.values(groupedByNameAndGroup);

    return doctypeWithGrp;
  }

  function convertToDoctypeWithoutGrp(doctypeWithGrp) {
    const doctypeWithoutGrp = [];

    doctypeWithGrp?.forEach((item) => {
      const { name, group, codes } = item;
      // Check if a matching entry exists in abc2
      const existingEntry = doctypeWithoutGrp.find((entry) => entry.name === name);

      if (existingEntry) {
        // If an entry with the same name exists, add the codes to it
        if (codes.length === 0) {
          existingEntry.codes.push({
            code: "",
            sign: 0,
            addParticulars: [],
            excludeParticulars: [],
            group,
          });
        } else {
          existingEntry.codes.push(...codes);
        }
      } else {
        // If no matching entry exists, create a new entry

        doctypeWithoutGrp.push({
          name,
          codes,
        });
      }
    });

    doctypeWithoutGrp.forEach((item) => {
      if (item.codes && item.codes.length > 0) {
        const allGroupIndex = item.codes.findIndex((codeItem) => codeItem.group?.groupId === "All");
        if (allGroupIndex === -1) {
          // If "All" group is not present, add it with empty code
          item.codes.push({
            code: "",
            sign: 0,
            addParticulars: [],
            excludeParticulars: [],
            group: { groupId: "All", groupName: "All" },
          });
        }
      }
    });

    doctypeWithoutGrp.forEach((item) => {
      if (item.codes && item.codes.length > 0) {
        const allGroupIndex = item.codes.findIndex((codeItem) => codeItem.group?.groupId === "All");
        if (allGroupIndex !== -1 && allGroupIndex !== 0) {
          // If "All" group is present and not already at index 0, move it to index 0
          const allGroup = item.codes.splice(allGroupIndex, 1)[0];
          item.codes.unshift(allGroup);
        }
      }
    });

    return doctypeWithoutGrp;
  }

  useEffect(() => {
    const tempDocumentTypes = DOCUMENT_TYPE_NAMES.map((name) => {
      const value = props.headerName[name] || [];
      return {
        name,
        codes: value,
      };
    });
    const doctypeWithGrp: any = convertToDoctypeWithGrp(tempDocumentTypes);
    setDocumentTypeRow(doctypeWithGrp);
  }, [props.headerName]);

  const onBackClick = () => {
    const doctypeWithoutGrp = convertToDoctypeWithoutGrp(documentTypeRow);
    const payload = doctypeWithoutGrp.reduce((result: Result, item: Element) => {
      result[item.name] = item.codes;
      return result;
    }, {});

    const tempObj = props.headerName;
    Object.assign(tempObj, payload);
    props.backTemplate();
  };
  const onSaveClickError = () => {
    const doctypeWithoutGrp = convertToDoctypeWithoutGrp(documentTypeRow);
    const payload = doctypeWithoutGrp.reduce((result: Result, item: Element) => {
      result[item.name] = item.codes;
      return result;
    }, {});

    const tempObj = props.headerName;
    Object.assign(tempObj, payload);
    props.saveRulesApi(tempObj);
  };

  const delRow = (element: Element, ind: number) => {
    element.codes.splice(ind, 1);
    const newData = documentTypeRow;
    const user = newData.find((d: any) => d.name === element.name && d.group?.groupId === element.group?.groupId);
    Object.assign(user, element);
    setDocumentTypeRow([...newData]);
    // createdocumentTypeTags([...newData]);
  };

  const handleChange = (event: any) => {
    setTransactionValue(event.target.value);
  };

  const handleChangeAddParticular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAddParticular(event.target.checked);
  };
  const handleChangeExcludeParticular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedExcludeParticular(event.target.checked);
  };

  const closeConfigModal = async () => {
    if (actor.actorType !== "Admin") {
      await props.getConfig();
      props.setShowCustomOption(false);
    }
    props.setDebitAmountSignReverseFlag(false);
    props.setCreditAmountSignReverseFlag(false);
    props.setOpenDialog(false);
    props.setColumnHeaderConfigDialog(true);
  };

  const checkIsDuplicateSign = (oldSign: 0 | 1 | 2, newSign: 0 | 1 | 2) => {
    if (oldSign === 0) return true;
    else if (oldSign === newSign) return true;
    else if (oldSign !== newSign && newSign === 0) return true;
    else return false;
  };

  const checkArrayValueEqual = (ComparandArray: string[], ComparatorArray: string[]) => {
    if (ComparandArray.length !== ComparatorArray.length) return false;
    ComparandArray.sort();
    ComparatorArray.sort();
    for (let i = 0; i < ComparandArray.length; i++) {
      if (ComparandArray[i].trim().toLowerCase() !== ComparatorArray[i].trim().toLowerCase()) return false;
    }
    return true;
  };

  const checkIsDuplicateCode = (newCode: Codes, docTypeCodes: Element[], addCodeIndex: number) => {
    let isDuplicateCode = false;
    let duplicateDocTypeName = "";

    if (newCode.excludeParticulars.length !== 0) {
      docTypeCodes[addCodeIndex].codes.forEach((existingCode) => {
        if (
          existingCode.code.trim().toLowerCase() === newCode.code.trim().toLowerCase() &&
          checkArrayValueEqual(newCode.addParticulars, existingCode.addParticulars) &&
          checkArrayValueEqual(newCode.excludeParticulars, existingCode.excludeParticulars) &&
          newCode.sign === existingCode.sign
        ) {
          isDuplicateCode = true;
          duplicateDocTypeName = docTypeCodes[addCodeIndex].name;
        }
      });
    } else {
      docTypeCodes.forEach((docTypeCode) => {
        docTypeCode.codes.forEach((existingCode) => {
          if (existingCode.code.trim().toLowerCase() === newCode.code.trim().toLowerCase()) {
            if (
              checkArrayValueEqual(existingCode.addParticulars, newCode.addParticulars) &&
              checkArrayValueEqual(existingCode.excludeParticulars, newCode.excludeParticulars)
            ) {
              const duplicateSign = checkIsDuplicateSign(existingCode.sign as 0 | 1 | 2, newCode.sign as 0 | 1 | 2);

              if (duplicateSign) isDuplicateCode = true;
              if (isDuplicateCode) {
                duplicateDocTypeName = docTypeCode.name[0].toUpperCase() + docTypeCode.name.slice(1);
                return;
              }
            }
          }
        });
      });
    }

    return { isDuplicateCode, duplicateDocTypeName };
  };

  function drop(ev: React.DragEvent<HTMLDivElement>, ele: Element) {
    ev.preventDefault();
    const dataCode = ev.dataTransfer.getData("code");
    const identifier = ev.dataTransfer.getData("identifier");

    document.querySelectorAll(".drop-area").forEach((el) => el.classList.remove("active"));
    const currentIdentifier = ele;
    const foundIdentifier = documentTypeRow?.find(
      (el: any) => `${el.name}-${el.group?.groupId}-${el.group?.groupName}` === identifier
    );
    const foundCode = foundIdentifier?.codes?.find((code) => getFlattenCode(code) === dataCode);
    foundCode.group = currentIdentifier.group;
    if (foundCode) currentIdentifier?.codes?.push(foundCode);
    foundIdentifier?.codes?.splice(foundIdentifier.codes.indexOf(foundCode), 1);

    setDocumentTypeRow(Array.from(documentTypeRow));
  }

  ondragend = () => {
    document.querySelectorAll(".drop-area").forEach((el) => el.classList.remove("active"));
  };

  function getNextGroupName(presentElements) {
    // Extract existing group IDs
    const existingGroupIds = presentElements.map((element) => element.group.groupId);

    // Find the first missing group number starting from 1
    let nextGroupNumber = 1;
    while (existingGroupIds.includes(`Group ${nextGroupNumber}`)) {
      nextGroupNumber++;
    }

    // Generate the next group name
    return `Group ${nextGroupNumber}`;
  }

  const addNewGroup = (el: any) => {
    const presentElements = documentTypeRow.filter((element) => element.name === el.name);
    // let numberOfElements = presentElements.length;
    // console.log("presentElements", presentElements);

    // console.log("documentTypeRow", documentTypeRow);
    // console.log("`Group ${numberOfElements}`", `Group ${numberOfElements}`);

    // if (presentElements.some((element: any) => element.group?.groupId === `Group ${numberOfElements}`)) {
    //   numberOfElements = numberOfElements + 1;
    // }
    // console.log("`Group ${numberOfElements}` new", `Group ${numberOfElements}`);

    const newGrpName = getNextGroupName(presentElements);
    // console.log("newgrpName------", newgrpName);

    const addNewGroupVal: any = convertToDoctypeWithGrp(documentTypeRow);
    addNewGroupVal.push({
      name: el.name,
      // group: { groupId: `Group ${numberOfElements}`, groupName: `Group ${numberOfElements}` },
      group: { groupId: newGrpName, groupName: newGrpName },
      codes: [
        {
          code: "",
          sign: 0,
          addParticulars: [] as string[],
          excludeParticulars: [] as string[],
          group: { groupId: newGrpName, groupName: newGrpName },
        },
      ],
    });

    const sortedDocVal = [];

    DOCUMENT_TYPE_NAMES.forEach((docType) => {
      const matchingElements = addNewGroupVal.filter((element) => element.name === docType);
      sortedDocVal.push(...matchingElements);
    });

    // Function to remove duplicate objects in the 'codes' array of each element
    const removeDuplicateCodes = (data) => {
      return data.map((element) => ({
        ...element,
        codes: element.codes.reduce((uniqueCodes, codeObj) => {
          const codeString = JSON.stringify(codeObj);
          if (!uniqueCodes[codeString]) {
            uniqueCodes[codeString] = true;
            return [...uniqueCodes, codeObj];
          }
          return uniqueCodes;
        }, []),
      }));
    };

    // Apply the function to your data
    const newData = removeDuplicateCodes(sortedDocVal);

    setDocumentTypeRow(newData);
  };

  const DocumentTypeConfigContextValue: any = {
    openEditGroupName,
    setOpenEditGroupName,
    editGroupEle,
    documentTypeRow,
    setDocumentTypeRow,
    isOpenDeleteDocTypeSubGrp,
    setIsOpenDeleteDocTypeSubGrp,
  };

  return (
    <>
      <DocumentTypeConfigContext.Provider value={DocumentTypeConfigContextValue}>
        <header className="modal-card-head">
          <p className="modal-card-title fs_20 fw_600">Document Type Mapper</p>
          {props.name === "Own Documents" && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.specificConfig}
                    disabled={props.disableSpecificConfigCheckbox}
                    onChange={() => {
                      props.setSpecificConfig(!props.specificConfig);
                    }}
                  />
                }
                label="Apply only to selected business partner"
              />
            </FormGroup>
          )}
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              closeConfigModal();
            }}
          />
        </header>
        <section className="modal-card-body">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column ">
                {documentTypeRow.map((element: any, docTypeIndex) => {
                  return (
                    <Grid
                      key={`key${docTypeIndex}`}
                      style={{ marginLeft: element.group?.groupId !== "All" ? "30px" : "" }}
                    >
                      <Grid className="theme_bg d_flex" xs={12} sm={12} md={12}>
                        <Grid className="b label_head" xs={6} sm={6} md={6}>
                          {/* <div className="control"> */}
                          {/* <h3 className="fw_900">{labels[docTypeIndex]}</h3> */}
                          <h3 className="fw_900">
                            {element.group?.groupId === "All"
                              ? docTypeLabels(element.name)
                              : element.group?.groupId !== element.group?.groupName
                              ? `${element.group?.groupId} : ${element.group?.groupName}`
                              : element.group?.groupName}
                          </h3>
                          {/* </div> */}
                        </Grid>
                        <Grid className="c space_between vertical_center_align" xs={8} sm={8} md={8}>
                          <button
                            className="config_add_btn"
                            style={{ marginLeft: "-20px" }}
                            onClick={() => {
                              setAddElementObj(element);
                              setAddElementIndex(docTypeIndex);
                              // setSaveValueColumnHeader([])
                              setSaveValueDocumentType(null);
                              setTransactionValue(0);
                              setOpenAddDialog(true);
                              setDuplicateTagName("");
                            }}
                          >
                            Add +
                          </button>
                          {element.group?.groupId === "All" ? (
                            <span
                              onClick={() => {
                                addNewGroup(element);
                              }}
                            >
                              <AddIcon style={{ color: "white", marginRight: "5px", cursor: "pointer" }} />
                            </span>
                          ) : (
                            <span>
                              <span
                                onClick={() => {
                                  setOpenEditGroupName(true);
                                  setEditGroupEle(element);
                                }}
                              >
                                <EditIcon style={{ color: "white", marginRight: "10px", cursor: "pointer" }} />
                              </span>
                              <span
                                onClick={() => {
                                  setIsOpenDeleteDocTypeSubGrp(true);
                                  setEditGroupEle(element);
                                }}
                              >
                                <DeleteIcon style={{ color: "white", marginRight: "5px", cursor: "pointer" }} />
                              </span>
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <div
                        className="drop-zone"
                        onDrop={(e) => drop(e, element)}
                        onDragOver={(e) => allowDrop(e)}
                        onDragEnter={(e) => {
                          e.stopPropagation();
                          const el = e.target as HTMLElement;
                          const dropAreaParent = el.querySelector(".drop-area") ? el : el.parentElement;
                          dropAreaParent?.querySelector(".drop-area")?.classList.add("active");
                        }}
                        onDragLeave={(e) => e.stopPropagation()}
                      >
                        <div className="drop-area">
                          <svg
                            width="80px"
                            height="80px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cls_1"
                          >
                            <path d="M4,7V6A2,2,0,0,1,6,4H7.29 M7,20H6a2,2,0,0,1-2-2V17" />
                            <path d="m20 17v1a2 2 0 0 1-2 2h-1" />
                            <line x1="20" x2="20" y1="10.59" y2="14" />
                            <line x1="4" x2="4" y1="10.59" y2="14" />
                            <path d="m17 4h1a2 2 0 0 1 2 2v1" />
                            <line x1="10.4" x2="14" y1="4" y2="4" />
                            <line x1="10.4" x2="14" y1="20" y2="20" />
                          </svg>
                        </div>

                        {getConfigTags(element, delRow)}
                      </div>
                    </Grid>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot space_between">
          <div>
            <button className="button is-success theme_btn" onClick={() => onBackClick()}>
              Back
            </button>
          </div>
          <div>
            <button className="button is-success theme_btn" onClick={onSaveClickError}>
              Save
            </button>
          </div>
        </footer>
        <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(true)}>
          <header className="modal-card-head">
            <p className="modal-card-title">Select document type</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setOpenAddDialog(false);
                setSaveValueDocumentType(null);
                setTransactionValue(0);
              }}
            />
          </header>
          <section className="modal-card-body">
            {duplicateTagName ? (
              <div className="mb_15 error_text_config">This tag is already mapped in {duplicateTagName} </div>
            ) : (
              ""
            )}
            <Grid xs={12} md={12} className="d_flex">
              <Grid xs={6} md={6}>
                <div className="mb_15">Select doc type</div>
                <TextField
                  variant="outlined"
                  label="Enter a new doc type"
                  placeholder="Enter a new doc type"
                  autoFocus={true}
                  sx={{ width: 300 }}
                  value={saveValueDocumentType}
                  onChange={(e) => {
                    setSaveValueDocumentType(e.target.value);
                  }}
                />
              </Grid>
              <Grid xs={6} md={6} className="pl_50">
                <div>Select transaction</div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={transactionValue}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="All Transaction" />
                    <FormControlLabel value={1} control={<Radio />} label="Debit Transaction" />
                    <FormControlLabel value={2} control={<Radio />} label="Credit Transaction" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid xs={12} md={12}>
              <div>
                <div className="mt_10">
                  <div>
                    <span>
                      <Checkbox
                        checked={checkedAddParticular}
                        onChange={handleChangeAddParticular}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                    <span className="fw_600">Add Particular/ Narration</span>
                    <span>
                      <Tooltip title="Please click on the check box and add the word that you want to match that will appear in particular/narration column. You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank , TDS for ‘Particular’">
                        <IconButton>
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                  {checkedAddParticular && (
                    <div>
                      <TextField
                        id="outlined-name-addParticularText"
                        placeholder="Enter Particular/ Narration"
                        fullWidth={true}
                        value={addParticularText}
                        onChange={(e: any) => {
                          const strAdd = e.target.value.replace(/\s\s+/g, " ");
                          const arrAdd = strAdd.split(",");
                          const arrWithoutEmptyStringAdd = arrAdd.filter((entry: any) => entry.trim() !== "");
                          const arrWithTrimmedStringAdd = arrWithoutEmptyStringAdd.map((stringAdd: any) =>
                            stringAdd.trim()
                          );
                          const uniqArrAdd: any = [...new Set(arrWithTrimmedStringAdd)];
                          setAddParticularTextArr(uniqArrAdd);
                          setAddParticularText(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt_10">
                  <div>
                    <span>
                      <Checkbox
                        checked={checkedExcludeParticular}
                        onChange={handleChangeExcludeParticular}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                    <span className="fw_600">Exclude Particular/ Narration</span>
                    <span>
                      <Tooltip title="Please click on the check box and add the word that you want to match that will appear in particular/narration column. You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank , TDS for ‘Particular’">
                        <IconButton>
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                  {checkedExcludeParticular && (
                    <div>
                      <TextField
                        id="outlined-name-excludeParticularText"
                        placeholder="Enter Particular/ Narration"
                        fullWidth={true}
                        value={excludeParticularText}
                        onChange={(e: any) => {
                          const str = e.target.value.replace(/\s\s+/g, " ");
                          const arr = str.split(",");
                          const arrWithoutEmptyString = arr.filter((entry: any) => entry.trim() !== "");
                          const arrWithTrimmedStringExclude = arrWithoutEmptyString.map((stringExclude: any) =>
                            stringExclude.trim()
                          );
                          const uniqArr: any = [...new Set(arrWithTrimmedStringExclude)];
                          setExcludeParticularTextArr(uniqArr);
                          setExcludeParticularText(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </section>
          <footer className="modal-card-foot right_align">
            <div>
              <Button
                className="button is-success"
                onClick={() => {
                  const { isDuplicateCode, duplicateDocTypeName } = checkIsDuplicateCode(
                    {
                      code: saveValueDocumentType,
                      sign: Number(transactionValue),
                      addParticulars: addParticularTextArr,
                      excludeParticulars: excludeParticularTextArr,
                      group: addElementObj.group,
                    },
                    documentTypeRow,
                    addElementIndex
                  );

                  if (isDuplicateCode) setDuplicateTagName(duplicateDocTypeName);
                  if (!isDuplicateCode) {
                    if (saveValueDocumentType !== "" && saveValueDocumentType !== null) {
                      documentTypeRow[addElementIndex].codes = documentTypeRow[addElementIndex].codes.concat({
                        code: saveValueDocumentType,
                        sign: Number(transactionValue),
                        addParticulars: addParticularTextArr,
                        excludeParticulars: excludeParticularTextArr,
                        group: addElementObj.group,
                      });
                      if (actor.actorType !== "Admin") {
                        props.setTemplateName(Customized);
                      }
                    }
                    if (documentTypeRow[addElementIndex].codes.length > 1) {
                      const removeEmptyVal = documentTypeRow[addElementIndex].codes.filter((el) => {
                        return el.code !== "";
                      });
                      documentTypeRow[addElementIndex].codes = removeEmptyVal;
                    }
                    if (saveValueDocumentType !== null) {
                      for (const obj of documentTypeRow) {
                        obj.codes.sort((a, b) => a.sign - b.sign);
                      }
                    }
                    // createdocumentTypeTags(documentTypeRow);
                    setDocumentTypeRow(documentTypeRow);

                    setOpenAddDialog(false);
                    setSaveValueDocumentType(null);
                    setTransactionValue(0);
                    setAddParticularTextArr([]);
                    setExcludeParticularTextArr([]);
                    setCheckedAddParticular(false);
                    setCheckedExcludeParticular(false);
                    setAddParticularText("");
                    setExcludeParticularText("");
                  }
                }}
              >
                Save
              </Button>
            </div>
          </footer>
        </Dialog>
        {openEditGroupName && <EditGroupName />}
        {isOpenDeleteDocTypeSubGrp && <DeleteDocTypeSubGrp />}
      </DocumentTypeConfigContext.Provider>
    </>
  );
};

export default DocumentTypeConfig;
