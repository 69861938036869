import { Button, FormControl, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorResponse, ListSanitizationRulesResponse } from "../../entity/recon-entity/ReconInterfaces";
import "../Common/Common.scss";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import { Dialog } from "../Dialog/Dialog";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import "../Recon360/Recon360.scss";
import ColumnHeaderConfig from "../Recon360/Transformer/ColumnHeaderConfig";
import DocumentTypeConfig from "../Recon360/Transformer/DocumentTypeConfig";
import "./Admin.scss";

export interface CodesWithSign {
  code: string;
  sign: number;
  addParticulars: string[];
  excludeParticulars: string[];
  group: { groupId: string; groupName: string };
}

export interface LedgerConfiguration {
  documentDate: string[];
  documentType: string[];
  otherUniqueId: string[];
  paymentId: string[];
  dncnId: string[];
  invoiceIdForDncn: string[];
  particulars: string[];
  amount: string;
  debitAmount: string[];
  creditAmount: string[];
  referenceNumber: string[];
  postingDate: string[];
  documentNumber: string[];
  clearingDocument: string[];
  clearingDate: string[];
  closingBalance: number;

  invoice: CodesWithSign[];
  payment: CodesWithSign[];
  debitNote: CodesWithSign[];
  debitAndCreditNotes: CodesWithSign[];
  reversal: CodesWithSign[];
  tds: CodesWithSign[];
  creditNote: CodesWithSign[];
  interDocument: CodesWithSign[];
  debitAmountSignReverseFlag: boolean;
  creditAmountSignReverseFlag: boolean;
}
export interface SanitizeConfiguration {
  ruleId: number;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
}
export interface ColumnsToSanitize {
  columnToSanitize: string;
  sanitizationRules: SanitizeConfiguration[];
}

export interface AnchorLedgerConfig {
  templateName: string;
  config: LedgerConfiguration;
  columnsToSanitize?: ColumnsToSanitize[];
}

export interface ListAnchorLedgerConfigResponse {
  ledgerConfigTemplate: AnchorLedgerConfig;
}

const ConfigTemplates = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [headerNameChanged, setChangedHeaderName] = useState({});
  const [headerName, setHeaderName] = useState<LedgerConfiguration>({} as LedgerConfiguration);
  const [sanitizeCol, setSanitizeCol] = useState([]);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [allRules, setAllRules] = useState([]);
  const [rulesArray, setRulesArray] = useState([]);
  const [templateName, setTemplateName] = useState<string>("");
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState<boolean>(false);
  const [showUploadErrorAlert, setShowUploadErrorAlert] = useState<boolean>(false);
  const [templateNameChanged, setChangedTemplateName] = useState("");
  const [configList, setConfigList] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [newConfig, setNewConfig] = useState<boolean>(false);
  const [specificConfig, setSpecificConfig] = useState(false);
  const [columnHeaderConfigDialog, setColumnHeaderConfigDialog] = useState<boolean>(true);
  const [debitAmountSignReverseFlag, setDebitAmountSignReverseFlag] = useState<boolean>(false);
  const [creditAmountSignReverseFlag, setCreditAmountSignReverseFlag] = useState<boolean>(false);

  const saveNewconfig = async (configData: any, template: any, data: any) => {
    configData.debitAmountSignReverseFlag = debitAmountSignReverseFlag;
    configData.creditAmountSignReverseFlag = creditAmountSignReverseFlag;
    try {
      await axios
        .post(
          "/api/InsertLedgerConfigTemplate",
          {
            templateName: template,
            config: configData,
            columnsToSanitize: data,
          },
          {
            params: {
              templateId: Number(selectedTemplateId) === 0 ? null : selectedTemplateId,
            },
          }
        )

        .then((response) => {
          console.log("sussess msg", response.data);
          getLedgerConfigList();
          // getConfigTemplate(selectedTemplateId);
          setApiErrorMsg(response?.data?.message);
          setShowUploadSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error InsertLedgerConfigTemplate", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}InsertLedgerConfigTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error InsertLedgerConfigTemplate", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}InsertLedgerConfigTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  useEffect(() => {
    // getAnchorLedgerConfigResponse();
    getLedgerConfigList();
    try {
      axios
        .get<ListSanitizationRulesResponse>("/api/listSanitizeRule")
        .then((response) => {
          setRulesArray(response.data.sanitizationRules);
          console.log("rulesssss", response.data);
        })
        .catch((error) => {
          console.log("error listSanitizeRule", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}listSanitizeRule`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error listSanitizeRule", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}listSanitizeRule`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  }, []);

  const changeTemplate = (event: any) => {
    if (Number(event.target.value) === 0) {
      setTemplateName("");
      setHeaderName(null);
      setNewConfig(false);
      setSanitizeCol([]);
      setSelectedTemplateId(event.target.value);
    } else {
      setNewConfig(true);
      getConfigTemplate(event.target.value);
      setSelectedTemplateId(event.target.value);
    }
    console.log("aaaaa", event.target.value);
    // setSelectedTemplate(event.target.value);
  };
  const getConfigTemplate = async (id: number) => {
    try {
      await axios
        .get<ListAnchorLedgerConfigResponse>(`/api/ListLedgerConfigTemplate/?templateId=${id}`)
        .then((response) => {
          setTemplateName(response.data.ledgerConfigTemplate.templateName);
          setHeaderName(response.data.ledgerConfigTemplate.config);
          setSanitizeCol(response.data.ledgerConfigTemplate.columnsToSanitize);

          setDebitAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.debitAmountSignReverseFlag);
          setCreditAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.creditAmountSignReverseFlag);
        })
        .catch((error) => {
          console.log("error ListLedgerConfigTemplate", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListLedgerConfigTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListLedgerConfigTemplate", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListLedgerConfigTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getLedgerConfigList = async () => {
    try {
      await axios
        .get("/api/ListAllLedgerConfigTemplate")
        .then((response) => {
          console.log("listdata", response.data);
          setConfigList(response.data.ledgerConfigTemplates);
        })
        .catch((error) => {
          console.log("error ListAllLedgerConfigTemplate", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllLedgerConfigTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllLedgerConfigTemplate", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllLedgerConfigTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Config Templates")}>
      <ApiErrorCatch
        showUploadErrorAlert={showUploadErrorAlert}
        apiErrorMsg={apiErrorMsg}
        showUploadSuccessAlert={showUploadSuccessAlert}
      />

      <div className="benificiary_wrapper">
        <Box className="benificiary_box">
          <Grid container={true} className="tier2-bulk-upload">
            <Grid xs={12} style={{ paddingTop: "0" }}>
              <h1>Config Templates</h1>
            </Grid>
            <Grid xs={12} sx={{ pt: 3 }} style={{ paddingTop: "0" }} className="main_row space_around">
              <Grid xs={12} md={12} className="center_align" style={{ marginBottom: "20px", marginLeft: "20px" }}>
                <div>
                  <FormControl sx={{ minWidth: "300px" }} className="closingblc_input">
                    <select name="configfile" className="select_ui" id="configfile" onChange={(e) => changeTemplate(e)}>
                      <option key={"select"} value="0" selected={true}>
                        Add New Config
                      </option>{" "}
                      {configList.map((element) => (
                        <option value={element.templateId} key={element.templateId}>
                          {element.templateName}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </div>

                {!newConfig ? (
                  <Button
                    key="add"
                    className="theme_btn config_btn"
                    onClick={() => {
                      setChangedHeaderName({});
                      setTemplateName("");
                      setChangedTemplateName("");
                      setOpenDialog(true);
                    }}
                  >
                    <i className="fas fa-plus-square" />
                  </Button>
                ) : (
                  <Button
                    key="edit"
                    className="theme_btn config_btn"
                    onClick={() => {
                      setOpenDialog(true);
                      getConfigTemplate(selectedTemplateId);
                    }}
                  >
                    <i className="fas fa-edit" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(true)}>
        {columnHeaderConfigDialog ? (
          <ColumnHeaderConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={newConfig ? headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={setHeaderName}
            rules={rulesArray}
            templateName={newConfig ? templateName : templateNameChanged || ""}
            sanitizeCol={sanitizeCol}
            template={true}
            newconfig={newConfig}
            setNewMapping={setNewConfig}
            heading="Column header mapping"
            openNextDialog={(value: any, header: string, template: string) => {
              setAllRules(value);
              setSanitizeCol(value);
              setChangedHeaderName(header);
              setChangedTemplateName(template);
              setColumnHeaderConfigDialog(false);
            }}
            showSettingsIcon={true}
            setOpenDialog={setOpenDialog}
            specificConfig={specificConfig}
            setSpecificConfig={setSpecificConfig}
            disableSpecificConfigCheckbox={true}
            configList={configList}
            debitAmountSignReverseFlag={debitAmountSignReverseFlag}
            setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlag}
            creditAmountSignReverseFlag={creditAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlag}
            subTab={"Ledger"}
            saveRulesApi={(header: string) => {
              saveNewconfig(header, templateNameChanged || templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
          />
        ) : (
          <DocumentTypeConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={newConfig ? headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={setHeaderName}
            setNewMapping={setNewConfig}
            name="Configuration File"
            heading="Document Type Key"
            backTemplate={() => {
              setColumnHeaderConfigDialog(true);
            }}
            setOpenDialog={setOpenDialog}
            saveRulesApi={(header: string) => {
              console.log("'header = ", header);
              saveNewconfig(header, templateNameChanged || templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlag}
          />
        )}
      </Dialog>
    </LoggedInSkeleton>
  );
};

export default ConfigTemplates;
