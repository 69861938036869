import { default as React, useState } from "react";

export interface BankRejectionFields {
  rejectedOff: string;
  rejectionRemarks: string;
}
/**
 * This component renders the UI for the modal when bank decides to decline a requested loan. It asks bank to enlist the reason/s (if any ).
 * @Component
 */
const BankRejectionModal = (props: {
  rejectionHandler: (rejectedFields: BankRejectionFields) => Promise<void>;
  onClose: () => void;
  loanType: string;
}) => {
  const [rejectedFields, setRejectedFields] = useState<BankRejectionFields>({
    rejectedOff: "Tier1",
    rejectionRemarks: "",
  });

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) =>
    setRejectedFields({
      ...rejectedFields,
      [event.target.name]: event.target.value,
    });
  console.log("Rejected due to", rejectedFields);
  return (
    <div className="modal" style={{ display: "flex" }}>
      <div className="modal-background" />
      <div className="modal-card" style={{ width: "100%", minHeight: "50%" }}>
        <header className="modal-card-head">
          <p className="modal-card-title">Attention</p>
          <button className="delete" aria-label="close" onClick={props.onClose} />
        </header>
        <section className="modal-card-body">
          <strong>Do you want to reject this claim?</strong>
          <br />
          <br />
          <div className="field">
            <label className="label">Rejected Due to</label>
            <div className="control">
              <div className="select is-normal is-info">
                <select name="rejectedOff" required={true} onChange={onChangeHandler}>
                  <option value="Tier1">Problem in Buyer Invoice</option>
                  {props.loanType === "Tier1EarlyPayment" ? (
                    ""
                  ) : (
                    <option value="Tier2">Problem in Customer Invoice</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label className="label">Remark</label>
              <textarea
                className="input"
                name="rejectionRemarks"
                placeholder="Write your remark here(if any)"
                style={{ height: "80px" }}
                value={rejectedFields.rejectionRemarks}
                onChange={onChangeHandler}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={() => {
              console.log("PROCEED");
              props.rejectionHandler(rejectedFields);
            }}
          >
            Proceed
          </button>
          <button className="button" onClick={props.onClose}>
            Cancel
          </button>
        </footer>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={props.onClose} />
    </div>
  );
};

export { BankRejectionModal };
