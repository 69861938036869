import React, { useState } from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import { FrontendActor } from "../../src/models/FrontendActor";
// import Anchor from "./Components/Anchor/Anchor";
import AnchorAuditTrail from "./Components/AnchorAuditTrail/AnchorAuditTrail";
import { AnchorLeadershipDashboard } from "./Components/AnchorLeadershipDashboard/AnchorLeadershipDashboard";
// import { AnchorEarlyPayment } from "./Components/AnchorEarlyPayment/AnchorEarlyPayment";
import AnchorLedgerAdjustment from "./Components/AnchorLedgerAdjustment/AnchorLedgerAdjustment";
import AnchorPayables from "./Components/AnchorPayables/AnchorPayables";
import Recon360 from "./Components/Recon360/Recon360";

import CBUpdate from "./Components/Admin/CBUpdate";
import CollectionAccount from "./Components/Admin/CollectionAccount";
import ConfigTemplates from "./Components/Admin/ConfigTemplates";
import DisbursmentAccount from "./Components/Admin/DisbursmentAccount";
import LedgerData from "./Components/Admin/LedgerData";
import PaymentDetails from "./Components/Admin/PaymentDetails";
import ReconMapping from "./Components/Admin/ReconMapping";
import Register from "./Components/Admin/Register";
import SapData from "./Components/Admin/SapData";
import TallyLedger from "./Components/Admin/TallyLedger";
import TallyVoucher from "./Components/Admin/TallyVoucher";
import UnpaidInvoice from "./Components/Admin/UnpaidInvoice";
import Userlist from "./Components/Admin/Userlist";
import { AnchorBulkUpload } from "./Components/AnchorUpload/AnchorBulkUpload";
import BankCollection from "./Components/BankCollection/BankCollection";
import BankCollectionLoanDetails from "./Components/BankCollection/BankCollectionLoanDetails";
import { BankDisbursement } from "./Components/BankDisbursement/BankDisbursement";
import BankLoanDetails from "./Components/BankDisbursement/BankLoanDetails";
import "./Components/Common/Common.scss";
import { userContext } from "./Components/Contexts/userContext";
import Login from "./Components/Login/Login";
import Faq from "./Components/Recon360/Faq/Faq";
import BalanceConfirmation from "./Components/Recon360/PartnerCommunication/BalanceConfirmation";
import LedgerRequest from "./Components/Recon360/PartnerCommunication/LedgerRequest";
import PartnerCommunicationContext from "./Components/Recon360/PartnerCommunication/PartnerCommunicationContext";
import PartnerMaster from "./Components/Recon360/PartnerMaster/PartnerMaster";
import AnnotationPage from "./Components/Recon360/PDFAnnotate";
import APReconInsights from "./Components/Recon360/ReconMIS/APReconInsights/APReconInsights";
import ARReconInsights from "./Components/Recon360/ReconMIS/ARReconInsights/ARReconInsights";
import ReconMIS from "./Components/Recon360/ReconMIS/ReconMIS";
// import ReconDashboard from "./Components/Recon360/ReconDashboard/ReconDashboard";
import ReconSettings from "./Components/Recon360/ReconSettings/ReconSettings";
import SupplierChainDashboard from "./Components/Recon360/SupplierChainDashboard/SupplierChainDashboard";
// import Register from "./Components/Register/Register";
import ChangePassword from "./Components/ResetPassword/ChangePassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import { AnchorSettings } from "./Components/Settings/AnchorSettings";
import { BankSettings } from "./Components/Settings/BankSettings";
import { Tier1Settings } from "./Components/Settings/Tier1Settings";
import { Tier2Settings } from "./Components/Settings/Tier2Settings";
import Tier1AuditTrail from "./Components/Tier1AuditTrail/Tier1AuditTrail";
// import Tier1Action from "./Components/Tier1Action/Tier1Action";
// import Tier1Consolidated from "./Components/Tier1Consolidated/Tier1Consolidated";
// import Tier1DataUpdate from "./Components/Tier1DataUpdate/Tier1DataUpdate";
import Tier1EarlyPaymentPage from "./Components/Tier1EarlyPayment/Tier1EarlyPayment";
import Tier1LedgerAdjustment from "./Components/Tier1LedgerAdjusment/Tier1LedgerAdjustment";
// import Tier1Action from "./Components/Tier1Action/Tier1Action";
// import Tier1Consolidated from "./Components/Tier1Consolidated/Tier1Consolidated";
// import Tier1DataUpdate from "./Components/Tier1DataUpdate/Tier1DataUpdate";
// import Tier1Recon360 from "./Components/Tier1Recon360/Tier1Recon360";
import { Tier1Upload } from "./Components/Tier1Upload/Tier1Upload";
import Tier2Account from "./Components/Tier2Account/Tier2Account";
import Tier2AuditTrail from "./Components/Tier2AuditTrail/Tier2AuditTrail";
// import Tier2Consolidated from "./Components/Tier2Consolidated/Tier2Consolidated";
import Tier2EarlyPayment from "./Components/Tier2EarlyPayment/Tier2EarlyPayment";
import Tier2LedgerAdjustment from "./Components/Tier2LedgerAdjustment/Tier2LedgerAdjustment";
import { FrontendActor } from "./entity/models/FrontendActor";

// import Tier2Upload from "./Components/Tier2Upload/Tier2Upload";
const App = () => {
  const [actor, setActor] = useState<FrontendActor>(() => {
    const localData = localStorage.getItem("actor");
    try {
      return localData ? JSON.parse(localData) : null;
    } catch (e) {
      return null;
    }
  });
  return (
    <BrowserRouter>
      <userContext.Provider value={{ actor, setActor }}>
        <Switch>
          <Route exact={true} path="/">
            <Login />
          </Route>
          <Route exact={true} path="/reset/:token/:accountType">
            <ChangePassword />
          </Route>
          <Route exact={true} path="/annotate">
            <AnnotationPage />
          </Route>
          <Route exact={true} path="/reset">
            <ResetPassword />
          </Route>
          <Route exact={true} path="/faq">
            <Faq />
          </Route>
          {/* <Route exact={true} path="/register">
            <Register />
          </Route> */}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            //  || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier1/early">
              <Tier1EarlyPaymentPage />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier1/audit/:pathParam?">
              <Tier1AuditTrail />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            //  || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier1/ledger/:pathParam?">
              <Tier1LedgerAdjustment />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier1/upload">
              <Tier1Upload />
            </Route>
          ) : null}
          {/* <Route exact={true} path="/tier1/recon360/:pathParam?">
            <Tier1Recon360 />
          </Route> */}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier2/account">
              <Tier2Account />
            </Route>
          ) : null}
          {/* <Route exact={true} path="/tier2/consolidated/:pathParam?">
            <Tier2Consolidated />
          </Route> */}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier2/ledger/:pathParam?">
              <Tier2LedgerAdjustment />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier2/audit/:pathParam?">
              <Tier2AuditTrail />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route path="/tier2/early/:pathParam?">
              <Tier2EarlyPayment />
            </Route>
          ) : null}
          {/* <Route exact={true} path="/tier2/upload">
            <Tier2Upload />
          </Route> */}
          <Route exact={true} path="/bank/collection">
            <BankCollection />
          </Route>
          <Route exact={true} path="/bank/disbursement">
            <BankDisbursement />
          </Route>
          <Route exact={true} path="/bank/settings">
            <BankSettings />
          </Route>
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/anchor/settings">
              <AnchorSettings />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier1/settings">
              <Tier1Settings />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/tier2/settings">
              <Tier2Settings />
            </Route>
          ) : null}

          {/* <Route exact={true} path="/anchor/early/:pathParam?">
            <AnchorEarlyPayment />
          </Route> */}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/anchor/api/payables">
              <AnchorPayables />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/anchor/early/:pathParam?">
              <AnchorLedgerAdjustment />
            </Route>
          ) : null}
          {/* <Route exact={true} path="/anchor/payables">
            <AnchorPayables />
          </Route> */}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/anchor/upload">
              <AnchorBulkUpload />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/anchor/ld">
              <AnchorLeadershipDashboard />
            </Route>
          ) : null}
          {process.env.REACT_APP_NAKAD_ENV === "test" ? (
            // || process.env.REACT_APP_NAKAD_ENV === "staging"
            <Route exact={true} path="/anchor/audit/:pathParam?">
              <AnchorAuditTrail />
            </Route>
          ) : null}
          {/* <Route exact={true} path="/:actorName/recon360/:pathParam?">
            <Recon360 />
          </Route> */}

          <Route exact={true} path="/:actorName/recon360/:pathParam?/PaymentAdvice">
            <Recon360 subTab="Payment Advice" />
          </Route>
          <Route exact={true} path="/:actorName/recon360/:pathParam?/Ledger">
            <Recon360 subTab="Ledger" />
          </Route>
          {/* <Route exact={true} path="/:actorName/businesspartnerdashboard">
            <ReconDashboard />
          </Route> */}
          {process.env.REACT_APP_NAKAD_ENV !== "production" ? (
            <Route exact={true} path="/:actorName/supplierchaindashboard">
              <SupplierChainDashboard />
            </Route>
          ) : null}
          <Route exact={true} path="/:actorName/reconSettings">
            <ReconSettings />
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/ledgerRequest">
            <PartnerCommunicationContext>
              <LedgerRequest />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/balanceConfirmation">
            <PartnerCommunicationContext>
              <BalanceConfirmation />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerMaster">
            <PartnerMaster />
          </Route>
          <Route exact={true} path="/:actorName/ReconMIS/ProcessManagement">
            <ReconMIS />
          </Route>
          <Route exact={true} path="/:actorName/ReconMIS/APReconInsights">
            <APReconInsights />
          </Route>
          <Route exact={true} path="/:actorName/ReconMIS/ARReconInsights">
            <ARReconInsights />
          </Route>
          <Route path="/bank/disbursement/:loanId" component={BankLoanDetails} />
          <Route path="/bank/collection/:loanId" component={BankCollectionLoanDetails} />
          <Route exact={true} path="/admin/userlist">
            <Userlist />
          </Route>
          <Route exact={true} path="/admin/reconmapping">
            <ReconMapping />
          </Route>
          <Route exact={true} path="/admin/sapdata">
            <SapData />
          </Route>
          <Route exact={true} path="/admin/ledgerdata">
            <LedgerData />
          </Route>
          <Route exact={true} path="/admin/collectionaccount">
            <CollectionAccount />
          </Route>
          <Route exact={true} path="/admin/disbursmentaccount">
            <DisbursmentAccount />
          </Route>
          <Route exact={true} path="/admin/configtemplates">
            <ConfigTemplates />
          </Route>
          <Route exact={true} path="/admin/tallyvoucher">
            <TallyVoucher />
          </Route>
          <Route exact={true} path="/admin/tallyledger">
            <TallyLedger />
          </Route>
          <Route exact={true} path="/admin/CBUpdate">
            <CBUpdate />
          </Route>
          <Route exact={true} path="/admin/UnpaidInvoice">
            <UnpaidInvoice />
          </Route>
          <Route exact={true} path="/admin/PaymentDetails">
            <PaymentDetails />
          </Route>
          <Route exact={true} path="/admin/Register">
            <Register />
          </Route>
          <Redirect to="/" />
        </Switch>
      </userContext.Provider>
    </BrowserRouter>
  );
};

export default App;
