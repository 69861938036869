import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
// import Nakad from "../../Graphics/Nakad_Blue.png";
// import Nakad from "../../Graphics/nakad-logo.png";
import Nakad from "../../Graphics/NAKAD_FINAL (1).png";
import { userContext } from "../Contexts/userContext";
import Register from "../Register/Register";
import illustration from "./../../Graphics/illustration.svg";

import Grid from "@mui/material/Grid";

import { Box } from "@mui/material";
import "./Login.scss";

const eye = <FontAwesomeIcon icon={faEye as IconProp} />;

const Login = () => {
  const { setActor } = useContext(userContext);
  const history = useHistory();
  const location = useLocation();
  const [form, setForm] = useState({
    companyid: "",
    username: "",
    password: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  // const disableSignUpBtnForNow = true;

  const update = (event: React.ChangeEvent<HTMLInputElement>) =>
    setForm({ ...form, [event.target.name]: event.target.value });

  useEffect(() => {
    if (location.state !== undefined && (location.state as any).alert === "true") {
      document.getElementById("flash-msg").style.display = "block";
      document.getElementById("msg").innerHTML = "Please Login to access Action page";
    }
  }, [location]);

  useEffect(() => {
    axios.get("/loginCheck").then((response) => {
      if (response.status === 200) {
        setActor({
          name: response.data.actorInfo.name as string,
          id: response.data.actorId,
          acceptedTNC: response.data.acceptedTNC,
          actorType: response.data.actorType,
          integration: response.data.integration,
          userId: response.data.userId,
          actorInfo: response.data.actorInfo,
          replyToEmail: response.data.replyToEmail,
          userType: response.data.userType,
        });
        redirect(response, history, response.data.actorInfo.name);
      }
    });
    // eslint-disable-next-line
  }, []);

  const loginUser = async (event: any) => {
    event.preventDefault();
    for (let RETRY_COUNT = 3; RETRY_COUNT > 0; RETRY_COUNT--) {
      await axios
        .post("/login", {
          username: form.username,
          password: form.password,
        })
        .then((response) => {
          console.log("response", response);
          console.log(response.data);
          setActor({
            name: response.data.actorInfo.name,
            id: response.data.actorId,
            acceptedTNC: response.data.acceptedTNC,
            actorType: response.data.actorType,
            integration: response.data.integration,
            userId: response.data.userId,
            actorInfo: response.data.actorInfo,
            replyToEmail: response.data.replyToEmail,
            userType: response.data.userType,
          });
          if (response.status === 200) {
            console.log("RETRY_COUNT 200", RETRY_COUNT);
            RETRY_COUNT = 0;
            redirect(response, history, response.data.actorInfo.name);
          } else if (response.status === 401) {
            console.log("RETRY_COUNT 401 ", RETRY_COUNT);
            document.getElementById("flash-msg").style.display = "block";
            document.getElementById("msg").innerHTML = "Wrong Credential ! Please Try Again";
          }
        })
        .catch((error) => {
          document.getElementById("flash-msg").style.display = "block";
          document.getElementById("msg").innerHTML = "Login Failed! Please Try Again";
          console.log("RETRY_COUNT error", RETRY_COUNT);
          console.log("error", error?.response);
        });
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const closeflash = () => {
    document.getElementById("flash-msg").style.display = "none";
  };

  return (
    <Box className=" login-page">
      <Grid container={true}>
        <Grid item={true} sm={12} md={5} className="login-left">
          <img src={illustration} alt="" />
        </Grid>

        {showSignUpForm ? (
          <Grid item={true} sm={12} md={7} className="login-right">
            <Grid item={true} sm={12} md={12}>
              <Register setShowSignUpForm={setShowSignUpForm} />
              <p className="contact">
                Reach us at <a href="mailto:support@nakad.co">support@nakad.co</a>
              </p>
            </Grid>
          </Grid>
        ) : (
          <Grid item={true} sm={12} md={7} className="login-right">
            <div className="login-right-height">
              <div className="login-right-center">
                <div className="center-box column is-5">
                  <img src={Nakad} alt="" style={{ width: "90%" }} />
                  <p className="enter-info-text">Please enter your user information</p>
                  <div className="notification is-danger" id="flash-msg">
                    <button className="delete" onClick={closeflash} />
                    <p id="msg" />
                  </div>
                  <form onSubmit={loginUser}>
                    <div className="field">
                      <div className="control">
                        <input
                          className="input input-box"
                          type="text"
                          name="username"
                          placeholder="User ID"
                          value={form.username}
                          onChange={update}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-icons-right">
                        <div style={{ display: "flex" }}>
                          <input
                            className="input input-box"
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            value={form.password}
                            onChange={update}
                            required={true}
                          />
                          <i onClick={togglePasswordVisiblity} id="eye">
                            {eye}
                          </i>
                        </div>
                      </div>
                      <Link className="help has-text-right" to="/reset">
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="buttons">
                      <button type="submit" className="button login-button">
                        Login
                      </button>
                      {/* {disableSignUpBtnForNow ? null : ( */}
                      <button
                        onClick={() => {
                          setShowSignUpForm(true);
                        }}
                        className="button login-button"
                      >
                        Sign Up
                      </button>
                      {/* )} */}
                    </div>
                  </form>
                </div>
              </div>
              <p className="contact">
                Reach us at <a href="mailto:support@nakad.co">support@nakad.co</a>
              </p>
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Login;
function redirect(response: any, history: any, actor: string) {
  console.log(response);
  // switch (response.data.actorType) {
  //   case "Tier1":
  //     process.env.REACT_APP_NAKAD_ENV === "test"
  //       ? // || process.env.REACT_APP_NAKAD_ENV === "staging"
  //         history.push("/tier1/early")
  //       : history.push(`/${actor}/recon360/Summary`);

  //     break;
  //   case "Tier2":
  //     process.env.REACT_APP_NAKAD_ENV === "test"
  //       ? // || process.env.REACT_APP_NAKAD_ENV === "staging"
  //         history.push("/tier2/early")
  //       : history.push(`/${actor}/recon360/Summary`);

  //     break;
  //   case "Bank":
  //     history.push("/bank/disbursement");
  //     break;
  //   case "Anchor":
  //     process.env.REACT_APP_NAKAD_ENV === "test"
  //       ? // || process.env.REACT_APP_NAKAD_ENV === "staging"
  //         // history.push("/anchor/upload")
  //         history.push(`/${actor}/recon360/Summary/Ledger`)
  //       : history.push(`/${actor}/recon360/Summary/Ledger`);

  //     break;
  //   case "AtherTest":
  //     history.push("/athertest/recon360");
  //     break;
  //   case "Admin":
  //     history.push("/admin/userlist");
  //     break;
  //   default:
  //     alert(response.data.actorType + " not implemented");
  // }
  if (response.data.actorType === "Admin") {
    history.push("/admin/userlist");
  } else if (
    response.data.actorType === "Tier1" ||
    response.data.actorType === "Tier2" ||
    response.data.actorType === "Anchor" ||
    response.data.actorType === "GroupedPartner" ||
    response.data.actorType === "PLG"
  ) {
    const accountName = actor
      .toString()
      .toLowerCase()
      .replace(/[^a-z\d]+/gi, "");
    history.push(`/${accountName}/partnerCommunication/balanceConfirmation`);
    // history.push(`/${actor}/recon360/Summary`);
  } else if (response.data.actorType === "Bank") {
    history.push("/bank/disbursement");
  } else {
    alert(response.data.actorType + " not implemented");
  }
}
