import Card from "@mui/material/Card";
import React, { useEffect, useMemo } from "react";
import { useBlockLayout, useExpanded, useFilters, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import "./Table.scss";

const defaultPropGetter = () => ({});

function ReconSettingsTable({
  columns,
  rows,
  getCellProps = defaultPropGetter,
  id,
  sortEnable,
  renderRowSubComponent,
  setSelectedRow,
  actorType,
}: any) {
  const columnsData: any = useMemo(() => columns, [columns]);

  const rowsData: any = useMemo(() => rows, [rows]);

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable(
    {
      columns: columnsData,
      data: rowsData,
      defaultColumn,
      initialState: { pageSize: 25 },
    },

    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useRowSelect
  );

  // console.log("selectedFlatRows",selectedFlatRows, selectedRowIds);

  useEffect(() => {
    if (actorType === "admin" || actorType === "BPDashboard") {
      const filterselectedFlatRows = selectedFlatRows.map((item) => item.original);
      setSelectedRow(filterselectedFlatRows);
    }
  }, [selectedFlatRows, setSelectedRow, actorType]);

  return (
    <Card style={{ overflowX: "scroll", padding: "20px" }}>
      {/* <CssBaseline /> */}
      <table {...getTableProps()} id={id} style={{ borderRadius: "1%" }}>
        <thead>
          {headerGroups.map((headerGroup, iHead) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={"thead_tr" + iHead}>
              {headerGroup.headers.map((column, iRow) => (
                <th {...column.getHeaderProps()} key={"th" + iRow}>
                  {sortEnable ? (
                    <div className="column" {...column.getSortByToggleProps()}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <div>
                            {column.render("Header")} <i className="fa fa-sort-desc" aria-hidden="true" />
                          </div>
                        ) : (
                          <div>
                            {column.render("Header")} <i className="fa fa-sort-asc" aria-hidden="true" />
                          </div>
                        )
                      ) : (
                        <div>
                          {column.render("Header")}{" "}
                          <i className="fa fa-sort" style={{ display: "none" }} aria-hidden="true" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="column">{column.render("Header")} </div>
                  )}

                  {/* <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, iRow) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            // console.log("rowProps", rowProps);
            // console.log("row",row,)
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...row.getRowProps()} key={"tbody_tr" + iRow}>
                  {row.cells.map((cell, iCell) => {
                    return (
                      <td
                        key={"td" + iCell}
                        {...cell.getCellProps([
                          {
                            className: cell.column,
                            style: cell.column,
                          },
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}

export default ReconSettingsTable;
