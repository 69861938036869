import { Alert, Autocomplete, Box, Button, Chip, Grid, MenuItem, TextField } from "@mui/material";
import { DialogActions, DialogContent, DialogTitle, FormLabel } from "@mui/material";
import SendMailDialog from "@mui/material/Dialog";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { EmailSendCount, EmailTemplate, ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { dynamicContentOptions } from "../../../Utils/PartnerCommunication";
import { userContext } from "../../Contexts/userContext";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import Editor from "./Editor";
import "./SendEmailTemplate.scss";

import { Delete } from "@mui/icons-material";
import { PartnerCommsContext } from "../PartnerCommunication/PartnerCommunicationContext";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;
interface SendEmailTemplateProps<T extends object> {
  companyId: string;
  base64Image: string;
  signature: string;
  setApiErrorMsg: StateDispatch<string>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowSuccessAlert: StateDispatch<boolean>;
  sendSoleOrBulkMail: EmailSendCount;
  selectedRow: object[] | T[] | any;
  listAllBusinessPartnerMapping: () => void;
  listAllBusinessPartnersWSR: () => Promise<void>;
  listUserEmailTemplate: (param: string) => void;
  partnerCommunicationSelected: "Ledger request" | "Balance confirmation";
  templateType: "LR" | "CB";
  storeAllBusinessPartnerCheck: React.MutableRefObject<object[]>;
  mailTo?: string;
  files?: File[];
}

const SendEmailTemplate = <T extends object>(props: SendEmailTemplateProps<T>) => {
  const { actor } = useContext(userContext);
  const [dynamicContent, setDynamicContent] = useState<string>("");
  // const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [files, setFiles] = useState<File[]>(props.files || []);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [errEmailSubject, setErrEmailSubject] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [showUserEmailTemplateToBeUpdatedModal, setUserEmailTemplateToBeUpdatedModal] = useState<boolean>(false);

  // Email States BEGIN
  const {
    emailTemplateContent,
    setEmailTemplateContent,
    openSendEmailTemplate,
    setOpenSendEmailTemplate,
    setOpenCreateEmailTemplate,
    emailTemplates,
    emailBusinessPartnerId,
    emailDisplayName,
    fromEmailAddress,
    setIsCompleteBulkEmailSend,
    setBulkSendingEmailsStart,
  } = useContext(PartnerCommsContext);
  const contextData = useContext(PartnerCommsContext);
  // Email States END
  let intervalId;

  useEffect(() => {
    if (emailTemplateContent) {
      setDynamicContent(emailTemplateContent.emailBody);
      setEmailSubject(emailTemplateContent.emailSubject);
    }
  }, [emailTemplateContent]);

  const handleClose = () => {
    setOpenSendEmailTemplate(false);
    setSelectedTemplateName("");
    setFiles([]);
  };

  const checkIfUserEmailTemplateHasToUpdate = () => {
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("templateName", emailTemplateContent.templateName);

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      try {
        Axios.post<any>("/api/CheckIfEmailTemplateNeedsToBeAdded", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            // check response here
            setShowLoadingIcon(false);
            if (response.data.isTemplateUpdated) {
              setUserEmailTemplateToBeUpdatedModal(true);
            } else {
              if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
                sendEmail();
              } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
                sendMailInBulk();
              }
            }
          })
          .catch((error) => {
            setShowLoadingIcon(false);
            console.log("error CheckIfEmailTemplateNeedsToBeAdded", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}CheckIfEmailTemplateNeedsToBeAdded`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        setShowLoadingIcon(false);
        console.log("error CheckIfEmailTemplateNeedsToBeAdded", error?.response);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}CheckIfEmailTemplateNeedsToBeAdded`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const updateUserEmailTemplate = () => {
    setUserEmailTemplateToBeUpdatedModal(false);
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId);
    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      try {
        Axios.post<any>("/api/AddUserEmailTemplate", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            setShowLoadingIcon(false);
            if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
              sendEmail();
            } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
              sendMailInBulk();
            }
            handleClose();

            // reload templates after Adding/Updating User Email Template
            props.listUserEmailTemplate("");

            // props.setLoader(true);
            if (actor.actorInfo.accountingSoftware !== "Tally") {
              props.listAllBusinessPartnerMapping();
            }

            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error AddUserEmailTemplate", error?.response);
            setShowLoadingIcon(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}AddUserEmailTemplate`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error AddUserEmailTemplate", error?.response);
        setShowLoadingIcon(false);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}AddUserEmailTemplate`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const sendEmail = () => {
    setUserEmailTemplateToBeUpdatedModal(false);
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId);
    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    console.log(contextData);

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      try {
        Axios.post<any>("/api/SendMail", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            setShowLoadingIcon(false);
            handleClose();
            // props.setLoader(true);
            props.listAllBusinessPartnersWSR();

            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error SendMail", error?.response);
            setShowLoadingIcon(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}SendMail`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error SendMail", error?.response);
        setShowLoadingIcon(false);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}SendMail`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };
  const sendMailInBulk = () => {
    console.log("props.selectedRow", props.selectedRow);
    setUserEmailTemplateToBeUpdatedModal(false);

    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }
    let selectedBPIds: any;
    if (props.partnerCommunicationSelected === "Ledger request") {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item.businessPartnerId;
      });
    } else if (props.partnerCommunicationSelected === "Balance confirmation") {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item.businessPartnerId;
      });
    } else {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item.ownBusinessPartnerMapping.businessPartnerId;
      });
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiversIds", selectedBPIds);
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId);
    bodyFormData.append("templateId", emailTemplateContent.templateId.toString());

    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      setIsCompleteBulkEmailSend(false);

      try {
        Axios.post<any>("/api/SendMailInBulk", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            console.log(response);
            setShowLoadingIcon(false);
            setBulkSendingEmailsStart(true);
            handleClose();
            // Repeatedly call getEmailsSentInBulkStatus every 30 seconds until isComplete is true
            intervalId = setInterval(async () => {
              const isComplete = await getEmailsSentInBulkStatus();
              if (isComplete) {
                // Bulk email operation is complete, stop the interval and cleanup
                clearInterval(intervalId);
              }
            }, 30000);
            // props.setShowSuccessAlert(true);
            // props.setApiErrorMsg(response.data.message);
            // apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error SendMailInBulk", error?.response);
            setShowLoadingIcon(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}SendMailInBulk`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error SendMailInBulk", error?.response);
        setShowLoadingIcon(false);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}SendMailInBulk`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const getEmailsSentInBulkStatus = () => {
    try {
      Axios.post("/api/GetEmailsSentInBulkStatus", {
        templateType: props.templateType,
      })
        .then((response) => {
          if (response.data.isComplete) {
            props.listAllBusinessPartnersWSR();
            // Stop the interval and cleanup
            clearInterval(intervalId);
            setIsCompleteBulkEmailSend(true);
            setBulkSendingEmailsStart(false);
            // props.listAllBusinessPartnerMapping();
          }
          return response.data.isComplete;
        })
        .catch((error) => {
          console.log("error GetEmailsSentInBulkStatus", error?.response);
          // Stop the interval and cleanup
          clearInterval(intervalId);
          setIsCompleteBulkEmailSend(true);
          setBulkSendingEmailsStart(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setApiErrorMsg(dataObj.message);
            props.setShowErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}GetEmailsSentInBulkStatus`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
          return true;
        });
    } catch (error: any) {
      console.log("error GetEmailsSentInBulkStatus", error?.response);
      // Stop the interval and cleanup
      clearInterval(intervalId);
      setIsCompleteBulkEmailSend(true);
      setBulkSendingEmailsStart(false);
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}GetEmailsSentInBulkStatus`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      return true;
    }
  };

  const getShortName = (name: string) => {
    const splittedName = name.split(".");
    const extension = splittedName[splittedName.length - 1];
    splittedName.splice(splittedName.length - 1);
    let fileName = splittedName.join("");
    if (fileName.length > 12) {
      fileName = fileName.substring(0, 6) + "..." + fileName.substring(fileName.length - 6);
    }
    return fileName + "." + extension;
  };

  return (
    <>
      <SendMailDialog
        fullWidth={true}
        maxWidth="lg"
        open={openSendEmailTemplate}
        classes={{ paper: "maxWidth_1000" }}
        // onClose={handleClose}
        aria-labelledby="SendEmailTemplate"
        // className="editBpContactMaster_popup"
      >
        <DialogContent sx={{ py: 1.25 }}>
          <div className="email_text">
            <Box>
              <p className="Email_Field">
                <FormLabel className="w_100">From : </FormLabel>
                {fromEmailAddress}
              </p>
              <p className="Email_Field">
                <FormLabel className="w_100">Display Name : </FormLabel>
                {emailDisplayName}
              </p>
              <p className="Email_Field">
                <FormLabel className="w_100">To : </FormLabel>{" "}
                {props.sendSoleOrBulkMail === EmailSendCount.Sole && props.mailTo ? props.mailTo : ""}
                {props.sendSoleOrBulkMail === EmailSendCount.Bulk && props.selectedRow?.length > 0
                  ? props.selectedRow?.length === 1
                    ? props.selectedRow[0]?.businessPartnerName
                    : `${props.selectedRow[0]?.businessPartnerName} + ${props.selectedRow?.length - 1} more`
                  : ""}
              </p>
              <div className="mt_5" style={{ marginTop: 5 }}>
                <FormLabel>+ CC</FormLabel>
                <FormLabel className="ml_10">+ BCC</FormLabel>
              </div>
            </Box>
            <Box display={"flex"} gap={2} alignItems="center" my={2}>
              <FormLabel className="w_100">Subject : </FormLabel>
              <TextField
                size="small"
                id="mail_subject"
                variant="outlined"
                placeholder="Enter Subject"
                value={emailSubject}
                error={errEmailSubject}
                helperText={errEmailSubject ? "please enter subject" : ""}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrEmailSubject(false);
                  } else {
                    setErrEmailSubject(true);
                  }
                }}
                sx={{
                  width: "90%",
                }}
              />
            </Box>
            <div>
              <Editor
                dynamicContent={dynamicContent}
                setDynamicContent={setDynamicContent}
                selectedTemplateName={selectedTemplateName}
                selectedEmailTemplateContent={emailTemplateContent}
              />
            </div>
            <div className="mt_10 pl_10" style={{ whiteSpace: "pre-wrap" }}>
              {props.signature !== null && props.signature !== undefined && props.signature !== "" ? (
                <span>{props.signature}</span>
              ) : (
                <>
                  <p>
                    <FormLabel className="w_100">Thanks,</FormLabel>
                  </p>
                  <p>{actor?.name}</p>
                  {/* <p>{props.emailDisplayName}</p> */}
                </>
              )}
            </div>
            <div className="Signature_Box">
              {props.base64Image && <img src={props.base64Image} style={{ width: "180px", height: "80px" }} />}
            </div>
            <div className="Footer_Content">
              <div style={{ height: 10 }} className="mb_10 flex_end">
                {copyAlert && (
                  <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -30 }}>
                    Copied!
                  </Alert>
                )}
              </div>
              <Box display={"flex"} gap={1}>
                {files !== null &&
                  files.length > 0 &&
                  files.map((_file, fileInd) => (
                    <Chip
                      title={files[fileInd].name}
                      key={files[fileInd].name}
                      label={getShortName(files[fileInd].name)}
                      onDelete={() => {
                        getShortName(files[fileInd].name);
                        files.splice(fileInd, 1);
                        setFiles([...files]);
                      }}
                      deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                      sx={{
                        maxWidth: 200,
                        "&, span": {
                          textOverflow: "unset !important",
                        },
                      }}
                    />
                  ))}
              </Box>
              <Grid item={true} xs={12} sm={12} className="d_flex">
                {(props.sendSoleOrBulkMail === EmailSendCount.Sole ||
                  props.sendSoleOrBulkMail === EmailSendCount.Bulk) && (
                  <div className="email_width">
                    <Grid item={true} xs={4} sm={4}>
                      <div>
                        <Button
                          variant="contained"
                          component="label"
                          className="theme_btn"
                          style={{ width: "100%" }}
                          size="small"
                        >
                          <span className="file-icon">
                            <i className="fas fa-upload" />
                          </span>
                          Attachment
                          <input
                            hidden={true}
                            multiple={true}
                            type="file"
                            onChange={(e) => {
                              console.log(files, e.target.files);
                              setFiles([...files, ...Array.from(e.target.files)]);
                            }}
                          />
                        </Button>
                      </div>
                    </Grid>
                  </div>
                )}
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <Autocomplete
                      size="small"
                      onChange={(e, value: any) => {
                        console.log(e);
                        if (value !== null) {
                          navigator.clipboard.writeText(value);
                          // insertDynamiccontent(value);
                          setCopyAlert(true);
                          setTimeout(() => {
                            setCopyAlert(false);
                          }, 3000);
                        }
                      }}
                      options={dynamicContentOptions}
                      //   value={dynamicContent}
                      renderInput={(params) => (
                        <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                      )}
                    />
                  </Grid>
                </div>
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <TextField
                      select={true}
                      size="small"
                      label="Choose Template"
                      name="Choosetemplate"
                      variant="outlined"
                      fullWidth={true}
                      onChange={(e) => {
                        if (e.target.value === "Others+#Create template") {
                          setSelectedTemplateName("");
                          handleClose();
                          setOpenCreateEmailTemplate(true);
                        } else {
                          setSelectedTemplateName(e.target.value);
                          const filteredSelectedTemplate = emailTemplates.filter((item: EmailTemplate) => {
                            return item.templateName === e.target.value;
                          })[0];
                          setEmailTemplateContent(filteredSelectedTemplate);
                        }
                      }}
                      value={selectedTemplateName}
                    >
                      {emailTemplates?.length > 0 &&
                        emailTemplates.map((item: EmailTemplate, itemInd: number) => (
                          <MenuItem key={itemInd} value={item.templateName}>
                            {item.templateName}
                          </MenuItem>
                        ))}
                      <MenuItem key="Others" value="Others+#Create template" style={{ borderTop: "1px solid" }}>
                        + Create template
                      </MenuItem>
                    </TextField>
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="email_dialog_footer_btn">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn"
              onClick={() => {
                Object.keys(props.storeAllBusinessPartnerCheck?.current || {}).forEach((key) => {
                  props.storeAllBusinessPartnerCheck.current[key] = false;
                });
                checkIfUserEmailTemplateHasToUpdate();
              }}
              disabled={showLoadingIcon}
            >
              {showLoadingIcon ? (
                <span className="file-icon">
                  <i className="fas fa-circle-notch fa-spin" />
                </span>
              ) : null}
              Send
            </Button>
          </div>
        </DialogActions>
      </SendMailDialog>
      <SendMailDialog open={showUserEmailTemplateToBeUpdatedModal}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Update E-mail template</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => setUserEmailTemplateToBeUpdatedModal(false)}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <p className="mt_30 mb_25 ">Do you want to update the email template to use it in future?</p>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
                  sendEmail();
                } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
                  sendMailInBulk();
                }
              }}
            >
              No
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                updateUserEmailTemplate();
              }}
            >
              Yes
            </Button>
          </div>
        </DialogActions>
      </SendMailDialog>
    </>
  );
};

export default SendEmailTemplate;
