import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  amountEntryType,
  ErrorResponse,
  ListReconSummaryAccountingSettingResponse,
} from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
import ReconSettingsTable from "../../ReactTable/ReconSettingsTable";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const ReconSummaryCalculation = () => {
  const [selectedReconSummaryCalculation, setSelectedReconSummaryCalculation] = useState<string>(null);
  const [errReconSummaryCalculation, setErrReconSummaryCalculation] = useState<boolean>(false);
  const [showUploadErrorAlert, setShowUploadErrorAlert] = useState<boolean>(false);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [summaryTypeSelected, setSummaryTypeSelected] = useState<string>(null);
  const [loader, setLoader] = useState(true);
  const [rowsData, setRowsData] = useState<any[]>();
  const options = ["Consider every accounting correct", "Source party is correct", "Custom calculation"];
  const [outOfPeriod, setOutOfPeriod] = useState<boolean>(false);
  const [outOfPeriodValue, setOutOfPeriodValue] = useState<string>("No");
  const [accountingTerminology, setAccountingTerminology] = useState<string>(amountEntryType.positiveNegative);
  const [numberOfDays, setNumberOfDays] = useState<number>(null);
  const [errNumberOfDays, setErrNumberOfDays] = useState<boolean>(false);

  useEffect(() => {
    listReconSummaryAccountingSettingsForUI();
  }, []);

  const listReconSummaryAccountingSettingsForUI = () => {
    try {
      Axios.get<ListReconSummaryAccountingSettingResponse>("/api/ListReconSummaryAccountingSettingsForUI")
        .then((response) => {
          console.log("response ListReconSummaryAccountingSettingsForUI", response);
          setOutOfPeriod(response.data.isOutOfPeriodSelected);
          setAccountingTerminology(response.data.amountEntryType);

          if (response.data.isOutOfPeriodSelected === true) {
            setOutOfPeriodValue("Yes");
            setNumberOfDays(response.data.dateRangeForOutOfPeriod);
          } else if (response.data.isOutOfPeriodSelected === false) {
            setOutOfPeriodValue("No");
            setNumberOfDays(response.data.dateRangeForOutOfPeriod);
          }
          setSummaryTypeSelected(response.data.settingType);
          setSelectedReconSummaryCalculation(response.data.settingType);
          setRowsData(response.data.reconSummaryAccountingSettingsList);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ListReconSummaryAccountingSettingsForUI", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListReconSummaryAccountingSettingsForUI`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListReconSummaryAccountingSettingsForUI", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListReconSummaryAccountingSettingsForUI`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const columnDefinition = [
    {
      Header: "Cases",
      accessor: "cases",
      maxWidth: 400,
      minWidth: 400,
      width: 400,
    },
    {
      Header: "Add to BP ledger",
      accessor: "addToBusinessPartnerLedger",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Checkbox
            checked={row.original.addToBusinessPartnerLedger} // row state
            disabled={
              selectedReconSummaryCalculation === "Source party is correct" ||
              selectedReconSummaryCalculation === "Consider every accounting correct"
                ? true
                : false
            }
            onChange={(e) => {
              handleChange(
                e,
                row,
                "addToBusinessPartnerLedger",
                "removeFromBusinessPartnerLedger",
                "addToOwnLedger",
                "removeFromOwnLedger"
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
    {
      Header: "Remove from BP ledger",
      accessor: "removeFromBusinessPartnerLedger",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Checkbox
            checked={row.original.removeFromBusinessPartnerLedger}
            disabled={
              selectedReconSummaryCalculation === "Source party is correct" ||
              selectedReconSummaryCalculation === "Consider every accounting correct"
                ? true
                : false
            }
            onChange={(e) => {
              handleChange(
                e,
                row,
                "removeFromBusinessPartnerLedger",
                "addToBusinessPartnerLedger",
                "addToOwnLedger",
                "removeFromOwnLedger"
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
    {
      Header: "Add to RU ledger",
      accessor: "addToOwnLedger",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Checkbox
            checked={row.original.addToOwnLedger}
            disabled={
              selectedReconSummaryCalculation === "Source party is correct" ||
              selectedReconSummaryCalculation === "Consider every accounting correct"
                ? true
                : false
            }
            onChange={(e) => {
              handleChange(
                e,
                row,
                "addToOwnLedger",
                "addToBusinessPartnerLedger",
                "removeFromBusinessPartnerLedger",
                "removeFromOwnLedger"
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
    {
      Header: "Remove from RU ledger",
      accessor: "removeFromOwnLedger",
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: ({ row }: any) => (
        <div>
          <Checkbox
            checked={row.original.removeFromOwnLedger}
            disabled={
              selectedReconSummaryCalculation === "Source party is correct" ||
              selectedReconSummaryCalculation === "Consider every accounting correct"
                ? true
                : false
            }
            onChange={(e) => {
              handleChange(
                e,
                row,
                "removeFromOwnLedger",
                "addToBusinessPartnerLedger",
                "removeFromBusinessPartnerLedger",
                "addToOwnLedger"
              );
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
    },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: any,
    columnName1: string,
    columnName2: string,
    columnName3: string,
    columnName4: string
  ) => {
    const tempRowData = rowsData?.map((item, ind) => {
      if (ind === row.index) {
        console.log("item", item);
        console.log("item", item[columnName1]);
        console.log("item", item[columnName2]);
        console.log("item", item[columnName3]);
        console.log("item", item[columnName4]);

        item[columnName1] = event.target.checked;
        item[columnName2] = false;
        item[columnName3] = false;
        item[columnName4] = false;
      }
      return item;
    });
    setRowsData(tempRowData);
  };

  const reconSummaryCalculationSelect = (e: any, value: any) => {
    if (value !== null) {
      e.persist();
      setSelectedReconSummaryCalculation(value);
      setErrReconSummaryCalculation(false);
    } else {
      setSelectedReconSummaryCalculation(null);
    }
  };

  const insertReconSummaryAccountingSetting = () => {
    if (selectedReconSummaryCalculation === null) {
      setErrReconSummaryCalculation(true);
    }
    if (selectedReconSummaryCalculation !== null) {
      try {
        Axios.post<any>("/api/InsertReconSummaryAccountingSetting", {
          settingSelected: selectedReconSummaryCalculation,
          reconSummaryAccountingSettingsList: rowsData,
        })
          .then((response) => {
            console.log("response InsertReconSummaryAccountingSetting", response);
            setSummaryTypeSelected(selectedReconSummaryCalculation);
            setApiErrorMsg(response.data.message);
            setShowUploadSuccessAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error InsertReconSummaryAccountingSetting", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowUploadErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            } else {
              setShowUploadErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}InsertReconSummaryAccountingSetting`);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error InsertReconSummaryAccountingSetting", error?.response);
        setShowUploadErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}InsertReconSummaryAccountingSetting`);
        apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
      }
    }
  };
  const updateOutOfPeriodSetting = () => {
    if ((numberOfDays === 0 || numberOfDays === null) && outOfPeriod) {
      setErrNumberOfDays(true);
    }
    if ((numberOfDays !== 0 && numberOfDays !== null) || !outOfPeriod) {
      try {
        Axios.put<any>("/api/UpdateOutOfPeriodSetting", {
          isOutOfPeriodSelected: outOfPeriod,
          dateRangeForOutOfPeriod: numberOfDays,
        })
          .then((response) => {
            console.log("response UpdateOutOfPeriodSetting", response);
            setApiErrorMsg(response.data.message);
            setShowUploadSuccessAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error UpdateOutOfPeriodSetting", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowUploadErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            } else {
              setShowUploadErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UpdateOutOfPeriodSetting`);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error UpdateOutOfPeriodSetting", error?.response);
        setShowUploadErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}UpdateOutOfPeriodSetting`);
        apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
      }
    }
  };

  const UpdateAmountEntryType = () => {
    if ((numberOfDays !== 0 && numberOfDays !== null) || !outOfPeriod) {
      try {
        Axios.post<any>("/api/updateAmountEntryType", {
          amountEntryType: accountingTerminology,
        })
          .then((response) => {
            console.log("response updateAmountEntryType", response);
            setApiErrorMsg(response.data.message);
            setShowUploadSuccessAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error updating AmountEntryType", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowUploadErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            } else {
              setShowUploadErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg} UpdateAmountEntryType`);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error UpdateAmountEntryType", error?.response);
        setShowUploadErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg} UpdateAmountEntryType`);
        apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
      }
    }
  };

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showUploadSuccessAlert}
        showUploadErrorAlert={showUploadErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <Grid container={true}>
            <Grid item={true} xs={12} sm={12} md={12}>
              <Grid className="mb_15">
                <Card>
                  <CardContent>
                    <div className="d_flex">
                      <div style={{ width: "90%" }}>
                        <div className="vertical_center_align">
                          <div className="fs_20 fw_600">Show out of period entries separately in recon summary</div>
                          <div className="ml_60">
                            <FormControl>
                              <RadioGroup
                                row={true}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={outOfPeriodValue}
                                onChange={(e) => {
                                  if (e.target.value === "Yes") {
                                    setOutOfPeriodValue("Yes");
                                    setOutOfPeriod(true);
                                  } else if ("No") {
                                    setOutOfPeriodValue("No");
                                    setOutOfPeriod(false);
                                  }
                                }}
                              >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                        {outOfPeriod && (
                          <div className="vertical_center_align mt_20">
                            <div style={{ width: "60%" }}>
                              Please set the buffer days that will decide the cutoff dates for “out of period” entry
                              criteria in the “Recon Summary Gap”
                            </div>
                            <div className="ml_20 numberOfDaysTextField">
                              <TextField
                                id="outlined-basic"
                                label="Days"
                                variant="outlined"
                                type="number"
                                size="small"
                                required={true}
                                value={numberOfDays !== 0 ? numberOfDays : ""}
                                error={errNumberOfDays}
                                helperText={errNumberOfDays ? "please enter number of days" : ""}
                                onChange={(e) => {
                                  setErrNumberOfDays(false);
                                  setNumberOfDays(Number(e.target.value));
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="vertical_center_align">
                        <Button
                          variant="contained"
                          onClick={() => {
                            updateOutOfPeriodSetting();
                          }}
                          className="theme_btn"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid className="mb_15">
                <Card>
                  <CardContent>
                    <div className="d_flex">
                      <div style={{ width: "90%" }}>
                        <div className="vertical_center_align">
                          <div className="fs_20 fw_600">Amount Entry terminology to be used for accounting</div>
                          <div className="ml_60">
                            <FormControl>
                              <RadioGroup
                                row={true}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={accountingTerminology}
                                onChange={(e) => {
                                  if (e.target.value === amountEntryType.debitCredit) {
                                    setAccountingTerminology(amountEntryType.debitCredit);
                                  } else {
                                    setAccountingTerminology(amountEntryType.positiveNegative);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value={amountEntryType.debitCredit}
                                  control={<Radio />}
                                  label="Debit/Credit"
                                />
                                <FormControlLabel
                                  value={amountEntryType.positiveNegative}
                                  control={<Radio />}
                                  label="+ve/-ve"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      <div className="vertical_center_align">
                        <Button
                          variant="contained"
                          onClick={() => {
                            UpdateAmountEntryType();
                          }}
                          className="theme_btn"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Card>
                <CardContent style={{ height: "100px" }}>
                  <div className="space_between pt_15">
                    <div className="w_50_per">
                      <div className="fs_20 fw_600">
                        Recon summary calculation{" "}
                        {summaryTypeSelected ? (
                          <>
                            <span>:</span>
                            <p className="fs_18 fw_600">({summaryTypeSelected})</p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div>Selected summary</div> */}
                    </div>
                    <div className="space_between w_50_per">
                      <div>
                        <Autocomplete
                          onChange={(e, value) => reconSummaryCalculationSelect(e, value)}
                          id="ReconSummarySelect"
                          className="w_350"
                          options={options}
                          size="small"
                          //   groupBy={(option) => option}
                          getOptionLabel={(option) => option}
                          defaultValue={summaryTypeSelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={true}
                              label="Choose calculation"
                              error={errReconSummaryCalculation}
                              helperText={errReconSummaryCalculation ? "please select recon summary calculation" : ""}
                            />
                          )}
                        />
                      </div>
                      <div>
                        {selectedReconSummaryCalculation === summaryTypeSelected ? (
                          <Button variant="contained" className="theme_btn" disabled={true}>
                            Calculation Selected
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => {
                              insertReconSummaryAccountingSetting();
                            }}
                            className="theme_btn"
                          >
                            Save Selection
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {selectedReconSummaryCalculation && (
            <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ pt: 2 }}>
              <Grid item={true} xs={12} sm={12} md={12}>
                {selectedReconSummaryCalculation === "Consider every accounting correct" ? (
                  <div>
                    <div className="reconSummaryCalculation_note">
                      <p>Logic: To prepare the summary, we are assuming the following:</p>
                      <p>
                        1) In the summary we are assuming, whatever is accounted in buyer's and supplier's ledger is
                        always correct and the other side has missed to account that entries.
                      </p>
                      <p>
                        2) In case of amount mismatch as well, whoever is having the larger amount, we will consider
                        that as the correct entry, as no one will account extra amount.
                      </p>
                    </div>
                    <div>
                      <ReconSettingsTable columns={columnDefinition} rows={rowsData} />
                    </div>
                  </div>
                ) : selectedReconSummaryCalculation === "Source party is correct" ? (
                  <div>
                    <div className="reconSummaryCalculation_note">
                      <p>To prepare the summary, we are assuming the following:</p>
                      <p>1) In case of Invoice docs, Supplier will be our source of truth.</p>
                      <p>2) In case of Payments, CN, DN, and TDS entries the buyer will be our source of truth.</p>
                      <p>Both of the above points will be applicable for amount mismatch as well.</p>
                    </div>
                    <div>
                      <ReconSettingsTable columns={columnDefinition} rows={rowsData} />
                    </div>
                  </div>
                ) : selectedReconSummaryCalculation === "Custom calculation" ? (
                  <div>
                    <div className="reconSummaryCalculation_note">
                      <p>Custom setting</p>
                      <p>User can define their own summary accounting setting</p>
                    </div>
                    <div>
                      <ReconSettingsTable columns={columnDefinition} rows={rowsData} />
                    </div>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ReconSummaryCalculation;
