import { Alert } from "@mui/material";
import React from "react";

const ApiErrorCatch = (props: any) => {
  return (
    <>
      {props.showUploadSuccessAlert ? (
        <Alert severity="success" style={{ position: "fixed", right: 0, width: "460px", zIndex: 11010 }}>
          {props.apiErrorMsg}
        </Alert>
      ) : null}
      {props.showUploadErrorAlert ? (
        <Alert severity="error" style={{ position: "fixed", right: 0, width: "460px", zIndex: 11010 }}>
          {props.apiErrorMsg}
        </Alert>
      ) : null}
      {props.showUploadWarningAlert ? (
        <Alert severity="warning" style={{ position: "fixed", right: 0, width: "460px", zIndex: 11010 }}>
          {props.apiErrorMsg}
        </Alert>
      ) : null}
    </>
  );
};

export default ApiErrorCatch;
