import {
  Download,
  FilterAlt,
  FilterAltOff,
  FilterList,
  FilterListOff,
  // PersonAdd,
  Settings,
  Sync,
} from "@mui/icons-material";
// import CachedIcon from "@mui/icons-material/Cached";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Autocomplete, Chip, CircularProgress, InputAdornment } from "@mui/material";
import { Avatar, Button, Card, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { ColumnDef, Row } from "@tanstack/react-table";
import Axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Tour from "reactour";
import {
  BusinessPartnersListForBalanceConfirmation,
  BusinessPartnersListForBalanceConfirmationRes,
  EmailSendCount,
  EmailTemplate,
  ErrorResponse,
  ListOwnTallyCompanies,
  ListOwnTallyCompaniesRes,
  ListUserEmailTemplateResponse,
  UseCaseType,
} from "../../../entity/recon-entity/ReconInterfaces";
import LoadingGif from "../../../Graphics/loading.gif";
import IndeterminateCheckbox from "../../Admin/IndeterminateCheckbox";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import MonetaryInput from "../../Common/MonetaryInput";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import ListBusinessPartnerUsers from "../PartnerMaster/ListBusinessPartnerUsers";
import BalanceConfirmationFilter from "./BalanceConfirmationFilter";
import BalanceConfirmationSettings from "./BalanceConfirmationSettings";
import CreateEmailTemplate from "./CreateEmailTemplate";
import "./PartnerCommunication.scss";
import { PartnerCommsContext } from "./PartnerCommunicationContext";
import SelectEmailTemplate from "./SelectEmailTemplate";
import SendEmailTemplate from "./SendEmailTemplate";
import StatusResetWarningModal from "./StatusResetWarningModal";
import useFetch from "./useFetch";

export const PartnerCommunicationContext = createContext<any>(null);
// const filter = createFilterOptions<any>();

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};

interface TallyClosingBalanceForAllBpResponse {
  message: string;
  closingBalanceOfAllBp: {
    businessPartnerId: number;
    closingBalance: number | string;
    closingBalanceDate: string;
  }[];
}

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      businessPartnerId: 0,
      allCategories: [],
      category: [],
      balanceConfirmationStatus: " ",
      balanceConfirmationStatusWithReminderCount: " ",
      closingBalance: " ",
      dateOfClosingBalance: " ",
      latestBalanceConfirmedOn: null,
      dateOfLastBalanceConfirmationStatusChange: null,
    } as BusinessPartnersListForBalanceConfirmation);
  }
  return dataArray;
};

const BalanceConfirmation = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [tourState, setTourState] = useState<boolean>(true);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [rowsDataBalanceConfirmation, setRowsDataBalanceConfirmation] = useState<
    BusinessPartnersListForBalanceConfirmation[]
  >(getBlankData());
  const [storeRowsDataBalanceConfirmation, setStoreRowsDataBalanceConfirmation] = useState<
    BusinessPartnersListForBalanceConfirmation[]
  >([]);
  const [openBalanceConfirmationSettings, setOpenBalanceConfirmationSettings] = useState<boolean>(false);
  const [openBalanceConfirmationFilter, setOpenBalanceConfirmationFilter] = useState<boolean>(false);

  const storeAllCategories = useRef<any[]>([]);
  const storeBalanceConfirmationData = useRef<any[]>([]);
  const [isFilterAppliedBalanceConfirmation, setIsFilterAppliedBalanceConfirmation] = useState<boolean>(false);
  // const [tempLoader, setTempLoader] = useState<boolean>(false);
  const storeClosingBalanceValueFlag = useRef<boolean>(false);
  const storeClosingBalanceDateFlag = useRef<boolean>(false);
  const storeClosingBalanceRowID = useRef<number>(null);
  const [storeClosingBalanceDateValue, setStoreClosingBalanceDateValue] = useState<any>(null);
  const [statusDataReady, setStatusDataReady] = useState(false);
  const [statusDataFetching, setStatusDataFetching] = useState(false);
  const [statusButtonClicked, setStatusButtonClicked] = useState(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>("Never");
  const lastInterval = useRef(null);
  // ----//

  const [showResetButton, setShowResetButton] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [file, setFile] = useState<any>(undefined);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [bulkTallyCbDate, setBulkTallyCbDate] = useState<string>("");
  const [defaultTallyCbDate, setDefaultTallyCbDate] = useState<string>("");
  const [updatingTallyCbDate, setUpdatingTallyCbDate] = useState(false);
  const [loadingTallyCbAll, setLoadingTallyCbAll] = useState(false);
  const lastTallyInterval = useRef(null);
  const [lastSelectedTemplateID, SetLastSelectedTemplateID] = useState(0);

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
    isCompleteBulkEmailSend,
    bulkSendingEmailsStart,
  } = useContext(PartnerCommsContext);
  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);

  // Email States END

  const buttonRef = useRef(null);
  const [listOwnTallyCompaniesForRecon, setListOwnTallyCompaniesForRecon] = useState<ListOwnTallyCompanies[]>([]);
  const [lastOwnTallyCompany, setLastOwnTallyCompany] = useState<ListOwnTallyCompanies>(null);
  const companyId = useRef<string>(null);
  const storeAllBusinessPartnerIds = useRef<number[]>(null);
  const storeAllBusinessPartnerCheck = useRef<any>(null);

  //  ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<any>(null);

  // reset status
  const [manualStatusChangeMethod, setManualStatusChangeMethod] = useState<boolean>(false);
  const [isOpenResetModal, setIsOpenResetModal] = useState<boolean>(false);
  const [downloadBalanceConfirmationReportBase64, setDownloadBalanceConfirmationReportBase64] = useState<string>(null);

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  // frequency
  const [optionsBalanceConfirmationFrequency, setOptionsBalanceConfirmationFrequency] = useState<string[]>([]);
  const [frequencyTableSettings, setFrequencyTableSettings] = useState<any>(null);

  const columnDefinitionBalanceConfirmation = useMemo(
    (): ColDef<BusinessPartnersListForBalanceConfirmation>[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div className="step4">
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        minSize: 35,
        maxSize: 35,
        size: 35,
      },
      {
        header: () => <div>Business Partner</div>,
        id: "Business partner name",
        sticky: "left",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        cell: ({ row }) => (
          <div>
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        maxSize: 300,
        minSize: 180,
        size: 300,
      },

      // {
      //   header: "Category",
      //   accessorFn: (row) => row.category?.[0]?.toLowerCase(),
      //   enableSorting: statusDataReady,
      //   maxSize: 200,
      //   minSize: 180,
      //   size: 200,
      //   cell: ({ row }) => (
      //     <div className="category_ui" key={row.original.businessPartnerId}>
      //       <Autocomplete
      //         openOnFocus={true}
      //         limitTags={1}
      //         value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
      //         disabled={!statusDataReady}
      //         onChange={(_ev, val) => {
      //           let value = val as string[];
      //           console.log(_ev);
      //           if (value !== null && value?.length > 0) {
      //             if (value[value.length - 1].includes("+Add => ")) {
      //               value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
      //               value[value.length - 1] = value[value.length - 1].replace('"', "");
      //               const removeEmptyVal = value.filter((el) => {
      //                 return el !== "";
      //               });
      //               value = removeEmptyVal;
      //             }
      //           }
      //           storeAllCategories.current = [...new Set([...storeAllCategories?.current, ...value])];
      //           updateOwnAndBusinessPartnerMappingCategories(row, value);
      //         }}
      //         noOptionsText={"Enter a new category"}
      //         multiple={true}
      //         id="tags-outlined"
      //         options={storeAllCategories?.current}
      //         getOptionLabel={(option: any) => option}
      //         filterSelectedOptions={true}
      //         filterOptions={(optionsFilt, params) => {
      //           console.log(optionsFilt);
      //           const filtered = filter(
      //             storeAllCategories?.current === null ? [] : storeAllCategories?.current,
      //             params
      //           );
      //           // Suggest the creation of a new value
      //           if (params.inputValue !== "") {
      //             filtered.push(`+Add => "${params.inputValue.trim()}"`);
      //           }
      //           return filtered;
      //         }}
      //         renderInput={(params) => (
      //           <TextField
      //             {...params}
      //             // placeholder="Add"
      //             sx={{
      //               "& input": {
      //                 px: "12px !important",
      //               },
      //             }}
      //           />
      //         )}
      //         sx={{
      //           "&, & div": {
      //             borderTopLeftRadius: "12px",
      //             borderTopRightRadius: "12px",
      //           },
      //           "& fieldset": {
      //             borderWidth: "0.4px",
      //             borderRadius: "12px",
      //           },
      //           "& .MuiChip-root": {
      //             borderRadius: "12px",
      //             marginTop: "4px",
      //           },
      //         }}
      //       />
      //     </div>
      //   ),
      // },
      {
        header: () => (
          <>
            <div className={!statusDataReady ? "noSort" : ""}>Status</div>
            {statusDataFetching && (
              <CircularProgress
                size={18}
                //  color="inherit"
                className="white-progress"
                sx={{ "svg *": { strokeWidth: 4 } }}
              />
            )}
          </>
        ),
        id: "Status",
        accessorKey: "balanceConfirmationStatus",
        enableSorting: statusDataReady,
        maxSize: 260,
        minSize: 260,
        size: 260,
        cell: ({ row }) => (
          <>
            {row.original.balanceConfirmationStatus ? (
              <Button
                onClick={() => {
                  downloadLedger(row.original?.downloadLedgerBase64);
                }}
                style={{
                  width: "240px",
                  overflowWrap: "break-word",
                  color:
                    row.original.balanceConfirmationStatus === "Balance confirmation requested" ||
                    row.original.balanceConfirmationStatus === "Check partner reply" ||
                    row.original.balanceConfirmationStatus === "Reminder sent" ||
                    row.original.balanceConfirmationStatus === "Configuration Underway" ||
                    row.original.balanceConfirmationStatus === "Balance confirmed" ||
                    row.original.balanceConfirmationStatus === "Recon shared with Partner"
                      ? "black"
                      : row.original.balanceConfirmationStatus
                      ? "white"
                      : "gray",
                  backgroundColor:
                    row.original.balanceConfirmationStatus === "Balance confirmation requested" ||
                    row.original.balanceConfirmationStatus === "Check partner reply" ||
                    row.original.balanceConfirmationStatus === "Reminder sent" ||
                    row.original.balanceConfirmationStatus === "Configuration Underway" ||
                    row.original.balanceConfirmationStatus === "Send Reminder"
                      ? "#dfae38"
                      : row.original.balanceConfirmationStatus === "Download ledger"
                      ? "#27b27c"
                      : row.original.balanceConfirmationStatus === "Balance confirmed" ||
                        row.original.balanceConfirmationStatus === "Recon shared with Partner"
                      ? "green"
                      : row.original.balanceConfirmationStatus === "Request balance confirmation"
                      ? "#774570"
                      : null,
                }}
                className="status_theme_btn"
                disabled={
                  row.original.balanceConfirmationStatus === "Download ledger" ||
                  row.original.balanceConfirmationStatus === "Configuration Underway"
                    ? false
                    : true
                }
              >
                {row.original.balanceConfirmationStatus === "Reminder sent"
                  ? row.original?.balanceConfirmationStatusWithReminderCount
                  : row.original.balanceConfirmationStatus}
              </Button>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Date as of last status change ​",
        accessorKey: "dateOfLastBalanceConfirmationStatusChange",
        enableSorting: true,
        maxSize: 280,
        minSize: 200,
        size: 280,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.dateOfLastBalanceConfirmationStatusChange !== null
              ? moment(row.original.dateOfLastBalanceConfirmationStatusChange)?.format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: () => (
          <div className="step1">
            Closing balance
            {(updatingTallyCbDate || loadingTallyCbAll) && (
              <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
            )}
          </div>
        ),
        accessorKey: "closingBalance",
        enableSorting: statusDataReady,
        maxSize: 180,
        minSize: 180,
        size: 180,
        cell: ({ row }) => (
          <div className="textField_height">
            <MonetaryInput
              key={row.original.closingBalance}
              value={
                !isNaN(parseFloat(row.original.closingBalance as string))
                  ? parseFloat(row.original.closingBalance as string).toLocaleString("en-IN")
                  : row.original.closingBalance || ""
              }
              placeholder={row.original.closingBalance as string}
              setValue={(value) => {
                if (value.toString() !== row.original?.closingBalance?.toString() && value)
                  updateClosingBalanceForBalanceConfirmation(row, value ? value : 0);
              }}
              disabled={!row.original.balanceConfirmationStatus || actor.integration ? true : false}
              InputProps={{
                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
              }}
              sx={{
                "input::placeholder": {
                  fontSize: "14px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="step2">
            <div>Date of closing balance</div>
            {actor.integration && actor.actorInfo.accountingSoftware === "Tally" && (
              <div className="textField_height">
                <TextField
                  key="bulkCBDate"
                  type="date"
                  size="small"
                  disabled={updatingTallyCbDate}
                  defaultValue={bulkTallyCbDate}
                  // onChange={(_e) => setBulkTallyCbDate(_e.target.value)}
                  onBlur={(_e) => {
                    setBulkTallyCbDate(_e.target.value);
                    if (_e.target.value && bulkTallyCbDate !== _e.target.value)
                      UpdateTallyClosingBalanceForAllBp(_e.target.value);
                  }}
                  onKeyUp={(_e) => updateClosingBalanceByClosingBalanceDate}
                  sx={{
                    div: { borderColor: "white !important", borderRadius: 16 },
                    input: { background: "white", p: "1px 8px", borderRadius: 16 },
                  }}
                  onClick={(_e) => _e.stopPropagation()}
                />
                {(updatingTallyCbDate || loadingTallyCbAll) && (
                  <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
                )}
              </div>
            )}
          </div>
        ),
        accessorKey: "dateOfClosingBalance",
        enableSorting: statusDataReady,
        maxSize: 240,
        minSize: 200,
        size: 240,
        cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original?.dateOfClosingBalance?.toString()}
              id="outlined-basic"
              variant="outlined"
              disabled={
                actor.integration && actor.actorInfo.accountingSoftware === "Tally"
                  ? loadingTallyCbAll
                  : !row.original.balanceConfirmationStatus
              }
              // placeholder="yyyy-mm-dd"
              type="date"
              size="small"
              // className="textField_height"
              defaultValue={
                storeClosingBalanceDateFlag.current &&
                storeClosingBalanceRowID.current === row.original.businessPartnerId
                  ? storeClosingBalanceDateValue !== null
                    ? moment(storeClosingBalanceDateValue)?.format("YYYY-MM-DD")
                    : storeClosingBalanceDateValue
                  : row.original.dateOfClosingBalance !== null
                  ? moment(row.original.dateOfClosingBalance)?.format("YYYY-MM-DD")
                  : row.original.dateOfClosingBalance
              }
              onClick={() => {
                storeClosingBalanceDateFlag.current = true;
                storeClosingBalanceRowID.current = row.original.businessPartnerId;
                setStoreClosingBalanceDateValue(row.original.dateOfClosingBalance);
              }}
              onChange={(e) => {
                setStoreClosingBalanceDateValue(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (actor.integration === true && actor.actorInfo.accountingSoftware === "Tally") {
                    // updateClosingBalanceByClosingBalanceDate(row, e.target.value);
                    UpdateTallyClosingBalance(row, e.target.value);
                    setBulkTallyCbDate("");
                  } else {
                    updateClosingBalanceDateForBalanceConfirmation(row, e.target.value);
                  }
                }
              }}
            />
          </div>
        ),
      },
      {
        header: "Latest balance confirmed on​​",
        accessorKey: "latestBalanceConfirmedOn",
        enableSorting: true,
        maxSize: 260,
        minSize: 200,
        size: 260,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.latestBalanceConfirmedOn !== null
              ? moment(row.original.latestBalanceConfirmedOn)?.format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: () => <div className="step3">Send Email</div>,
        accessorKey: "businessPartnerId",
        enableSorting: false,
        id: "Send Email",
        maxSize: 120,
        minSize: 70,
        size: 120,
        sticky: "right",
        cell: ({ row }) => (
          <Tooltip
            title={
              statusDataFetching || updatingTallyCbDate || loadingTallyCbAll
                ? `Please Wait while ${statusDataFetching ? "status" : "closing balance"} is Loading`
                : ""
            }
            arrow={true}
          >
            <span>
              <button
                disabled={statusDataFetching}
                onClick={() => {
                  setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                  setSendSoleOrBulkMail(EmailSendCount.Sole);
                  getListUserEmailSetting();
                  setOpenSelectEmailTemplate(true);
                }}
                style={{ background: "none", border: "none", cursor: "pointer" }}
              >
                <MailOutlineIcon sx={{ fill: "#541c4c" }} />
              </button>
            </span>
          </Tooltip>
        ),
      },
      // {
      //   header: "View recon",
      //   id: "View recon",
      //   accessorKey: "businessPartnerId",
      //   enableSorting: false,
      //   maxSize: 120,
      //   minSize: 70,
      //   size: 120,
      //   sticky: "right",
      //   cell: ({ row }) => (
      //     <button
      //       style={{ background: "none", border: "none", cursor: "pointer" }}
      //       onClick={() => {
      //         const { businessPartnerId, businessPartnerName } = row.original;
      //         const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
      //         const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
      //         window.open(uri, "_blank").focus();
      //       }}
      //     >
      //       <CachedIcon sx={{ fill: "#541c4c" }} />
      //     </button>
      //   ),
      // },
    ],
    // eslint-disable-next-line
    [
      actor,
      history,
      statusDataReady,
      statusDataFetching,
      updatingTallyCbDate,
      bulkTallyCbDate,
      loadingTallyCbAll,
      rowsDataBalanceConfirmation,
    ]
  );

  const PartnerCommunicationContextValue: any = {};

  useEffect(() => {
    listPartnerCommunicationStatusResetMethod();
    listFrequencyTable();

    if (actor.integration === true && actor.actorInfo.accountingSoftware === "Tally") {
      getListOwnTallyCompaniesForRecon();
    } else {
      listAllBusinessPartnerIds();
      // listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      // listBusinessPartnersForBalanceConfirmation();
      listUserEmailTemplateForBalanceConfirmation("");
      getListUserEmailSetting();
      pollIsCBStatusFetched();
    }
    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    listPartnerCommunicationStatusResetMethod();
  }, [openBalanceConfirmationSettings]);

  useEffect(() => {
    if (lastOwnTallyCompany) {
      pollIsCBStatusFetched();
      PollTallyClosingBalanceStatus();
    }
    // eslint-disable-next-line
  }, [lastOwnTallyCompany]);

  useEffect(() => {
    if (statusDataFetching) {
      setStatusText("Syncing Now...");
      pollIsCBStatusFetched();
      const intervalsInMs = [10_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            pollIsCBStatusFetched();
          } finally {
            // if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastInterval.current);
      if (actor.integration && actor.actorInfo.accountingSoftware === "Tally") {
        if (companyId.current) listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      } else listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
    }

    return () => window.clearInterval(lastInterval.current);
    // eslint-disable-next-line
  }, [statusDataFetching, actor]);

  const listAllBusinessPartnerIds = async () => {
    try {
      const params: { companyId?: string | null } = {};
      if (actor.integration === true && actor.actorInfo.accountingSoftware === "Tally") {
        params.companyId = companyId.current;
      }

      await Axios.get<any>("/api/ListAllBusinessPartnerIds", {
        params,
      })
        .then((response) => {
          // storeAllBusinessPartnerIds.current = response.data?.businessPartnerIds;
          storeAllBusinessPartnerCheck.current = response.data?.businessPartnerCheck;
          listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
          if (!actor.integration && actor.actorInfo.accountingSoftware !== "Tally") {
            setRowsDataBalanceConfirmation(getBlankData());
            setStatusDataReady(false);
          }
        })
        .catch((error: any) => {
          console.log("error ListAllBusinessPartnerIds", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnerIds`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllBusinessPartnerIds", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnerIds`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  // this API is called repeatedly until errorOccurred or isFetched is true
  const pollIsCBStatusFetched = async () => {
    try {
      await Axios.get<{ errorOccurred: boolean; isFetched: boolean; lastSyncDate: string }>(
        "/api/isBalanceConfirmationStatusFetched",
        {
          params: {
            companyId: companyId.current,
          },
        }
      )
        .then(async (response) => {
          if (response.data.isFetched || response.data.errorOccurred) {
            setStatusDataFetching(false);
            setStatusButtonClicked(false);
            if (response.data.lastSyncDate) setStatusText(moment(response.data.lastSyncDate).format("DD-MM-YY hh:mmA"));
          }
          if (!response.data.isFetched && !response.data.errorOccurred) setStatusDataFetching(true);
        })
        .catch((error: any) => {
          console.log("error IsLedgerRequestStatusFetched", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}IsLedgerRequestStatusFetched`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error IsLedgerRequestStatusFetched", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}IsLedgerRequestStatusFetched`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  // this function calls the API which initiates a backend request to fetch the status(es) for each business partners
  const listBusinessPartnersForBalanceConfirmation = async () => {
    setStatusButtonClicked(true);
    try {
      await Axios.post<BusinessPartnersListForBalanceConfirmationRes>(
        "/api/ListAllBusinessPartnersForBalanceConfirmation",
        {
          companyId: companyId.current,
        }
      )
        .then(() => {
          setStatusDataFetching(true);
        })
        .catch((error: any) => {
          setStatusDataFetching(false);
          setStatusButtonClicked(false);
          console.log("error ListBusinessPartnersForBalanceConfirmation", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListBusinessPartnersForBalanceConfirmation`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      setStatusDataFetching(false);
      setStatusButtonClicked(false);
      console.log("error ListBusinessPartnersForBalanceConfirmation", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListBusinessPartnersForBalanceConfirmation`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh = async () => {
    try {
      await Axios.get<BusinessPartnersListForBalanceConfirmationRes>(
        "/api/ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh",
        {
          params: {
            companyId: companyId.current,
          },
        }
      )
        .then((response) => {
          // Extract the businessPartnerId values and store them in a new array
          storeAllBusinessPartnerIds.current = response.data?.businessPartnersList?.map(
            (businessPartner) => businessPartner?.businessPartnerId
          );
          storeBalanceConfirmationData.current = response.data.businessPartnersList;
          storeAllCategories.current = response.data.businessPartnersList[0]?.allCategories;
          setRowsDataBalanceConfirmation(response.data.businessPartnersList);
          setStoreRowsDataBalanceConfirmation(response.data.businessPartnersList);
          setLoader(false);
          setStatusDataReady(true);
          if (Object.keys(appliedFilters).length) setReFilter(true);
          if (rowsDataBalanceConfirmation && defaultTallyCbDate)
            GetTallyClosingBalanceForAllBp(response.data.businessPartnersList);
        })
        .catch((error: any) => {
          console.log("error ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh", error?.response);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh", error?.response);
      setLoader(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const listUserEmailTemplateForBalanceConfirmation = (createTemplateName: string) => {
    try {
      Axios.get<ListUserEmailTemplateResponse>("/api/ListUserEmailTemplate", {
        params: {
          templateType: "CB",
        },
      })
        .then((response) => {
          const { emailTemplates, lastEmailTemplateSelected } = response.data;
          setEmailTemplates(emailTemplates);
          if (lastEmailTemplateSelected) SetLastSelectedTemplateID(lastEmailTemplateSelected);
          if (createTemplateName.trim() !== "") {
            if (response.data?.emailTemplates !== null && response.data?.emailTemplates.length > 0) {
              const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
                return item.templateName === createTemplateName;
              })[0];
              if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
            }
            setOpenSelectEmailTemplate(false);
            setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
            // setSelectedTemplate("");
            setHideSendEmailTemplate(false);
          }
        })
        .catch((error: any) => {
          console.log("error ListUserEmailTemplate", error?.response);

          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListUserEmailTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListUserEmailTemplate", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListUserEmailTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getListUserEmailSetting = async () => {
    try {
      await Axios.get<any>("/api/ListUserEmailSetting", {
        params: {
          userId: actor.userId,
        },
      })
        .then((response) => {
          if (
            response.data.userEmailSetting.display_name !== null &&
            response.data.userEmailSetting.display_name !== undefined &&
            response.data.userEmailSetting.display_name !== ""
          ) {
            setEmailDisplayName(response.data.userEmailSetting.display_name);
          }
          if (
            response.data.userEmailSetting.from_email !== null &&
            response.data.userEmailSetting.from_email !== undefined &&
            response.data.userEmailSetting.from_email !== ""
          ) {
            setFromEmailAddress(response.data.userEmailSetting.from_email);
          }

          if (
            response.data.userEmailSetting.signature.image !== null &&
            response.data.userEmailSetting.signature.image !== undefined &&
            response.data.userEmailSetting.signature.image !== ""
          ) {
            // setFile(response.data.userEmailSetting.signature.image);
            setFile(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
          }
          if (
            response.data.userEmailSetting.signature.text !== null &&
            response.data.userEmailSetting.signature.text !== undefined &&
            response.data.userEmailSetting.signature.text !== ""
          ) {
            setSignature(response.data.userEmailSetting.signature.text);
          }
        })
        .catch((error: any) => {
          console.log("error ListUserEmailSetting", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListUserEmailSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListUserEmailSetting", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListUserEmailSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  // const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
  //   try {
  //     await Axios.post<any>("/api/UpdateOwnAndBusinessPartnerMappingCategories", {
  //       ownId: actor.id,
  //       businessPartnerId: row.original.businessPartnerId,
  //       category: value,
  //     })
  //       .then((response) => {
  //         console.log("response", response);
  //         setTempLoader(true);
  //         updateCategory(row, value);
  //       })
  //       .catch((error: any) => {
  //         console.log("error UpdateOwnAndBusinessPartnerMappingCategories", error?.response);
  //         if (error?.response?.data?.message !== undefined) {
  //           const dataObj = error.response.data as ErrorResponse;
  //           setShowErrorAlert(true);
  //           setApiErrorMsg(dataObj.message);
  //           apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //         } else {
  //           setShowErrorAlert(true);
  //           setApiErrorMsg(`${defaultErrorMsg}UpdateOwnAndBusinessPartnerMappingCategories`);
  //           apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //         }
  //       });
  //   } catch (error: any) {
  //     console.log("error UpdateOwnAndBusinessPartnerMappingCategories", error?.response);
  //     setShowErrorAlert(true);
  //     setApiErrorMsg(`${defaultErrorMsg}UpdateOwnAndBusinessPartnerMappingCategories`);
  //     apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //   }
  // };

  // const updateCategory = (row: any, value: any) => {
  //   storeBalanceConfirmationData.current.find(
  //     (v: any) => v.businessPartnerId === row.original.businessPartnerId
  //   ).category = value;
  //   // setRowsDataBalanceConfirmation(storeBalanceConfirmationData.current);
  //   // setStoreRowsDataBalanceConfirmation(storeBalanceConfirmationData.current);
  //   console.log(tempLoader);
  //   setTempLoader(false);
  // };

  const updateClosingBalanceForBalanceConfirmation = async (
    row: Row<BusinessPartnersListForBalanceConfirmation>,
    value: number
  ) => {
    try {
      await Axios.post("/api/UpdateClosingBalanceForBalanceConfirmation", {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        closingBalance: value,
      })
        .then((response) => {
          console.log("response", response);
          storeClosingBalanceValueFlag.current = false;
          storeClosingBalanceDateFlag.current = false;

          if (actor.actorInfo.accountingSoftware !== "Tally") {
            // listBusinessPartnersForBalanceConfirmation();
            storeBalanceConfirmationData.current?.map((item: any) => {
              if (item.businessPartnerId === row.original.businessPartnerId) {
                item.closingBalance = value;
              }
              return item;
            });
          }
        })
        .catch((error: any) => {
          console.log("error UpdateClosingBalanceForBalanceConfirmation", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceForBalanceConfirmation`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UpdateClosingBalanceForBalanceConfirmation", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceForBalanceConfirmation`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const updateClosingBalanceDateForBalanceConfirmation = async (
    row: Row<BusinessPartnersListForBalanceConfirmation>,
    value: string
  ) => {
    try {
      await Axios.post("/api/UpdateClosingBalanceDateForBalanceConfirmation", {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        closingBalanceDate: value,
      })
        .then((response) => {
          console.log("response", response);
          storeClosingBalanceValueFlag.current = false;
          storeClosingBalanceDateFlag.current = false;

          // setLoader(true);
          if (actor.actorInfo.accountingSoftware !== "Tally") {
            // listBusinessPartnersForBalanceConfirmation();
            storeBalanceConfirmationData.current?.map((item: any) => {
              if (item.businessPartnerId === row.original.businessPartnerId) {
                item.dateOfClosingBalance = value;
              }
              return item;
            });
          }
        })
        .catch((error: any) => {
          console.log("error UpdateClosingBalanceDateForBalanceConfirmation", error?.response);
          // setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceDateForBalanceConfirmation`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UpdateClosingBalanceDateForBalanceConfirmation", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceDateForBalanceConfirmation`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const downloadLedger = async (finalWorkbookBase64: any) => {
    for (const item of finalWorkbookBase64) {
      const parts = item.split(":split:");
      if (parts[0] === "excel") {
        const excelData = Buffer.from(parts[2], "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, parts[1]);
      } else if (parts[0] === "pdf") {
        const excelData = Buffer.from(parts[2], "base64");
        const fileType = "application/pdf";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, parts[1]);
      } else if (parts[0] === "link") {
        const link = document.createElement("a");
        link.href = parts[1];
        link.rel = "noopener noreferrer";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };

  /**
   * @deprecated
   * method deprecated and superseded by method UpdateTallyClosingBalance
   */
  const updateClosingBalanceByClosingBalanceDate = async (
    row: Row<BusinessPartnersListForBalanceConfirmation>,
    value: string
  ) => {
    try {
      await Axios.put("/api/UpdateClosingBalanceByClosingBalanceDate", {
        businessPartnerId: row.original.businessPartnerId,
        closingBalanceDate: value,
        companyId: companyId.current,
      })
        .then((response) => {
          console.log("response UpdateClosingBalanceByClosingBalanceDate", response);

          storeClosingBalanceValueFlag.current = false;
          storeClosingBalanceDateFlag.current = false;

          // setTempLoader(true);
          updateClosingBalanceByClosingBalanceDateVal(response?.data?.businessPartnerUpdatedDetails, value);

          if (response.data.message) {
            setShowWarningAlert(true);
            setApiErrorMsg(response?.data?.message);
            apiSuccessErrorAlertSetTimeout(setShowWarningAlert, setApiErrorMsg);
          }
        })
        .catch((error: any) => {
          console.log("error UpdateClosingBalanceByClosingBalanceDate", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceByClosingBalanceDate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UpdateClosingBalanceByClosingBalanceDate", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateClosingBalanceByClosingBalanceDate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const updateClosingBalanceByClosingBalanceDateVal = (obj: any, value: any) => {
    storeBalanceConfirmationData.current?.map((item: any) => {
      if (item.businessPartnerId === obj?.business_partner_id) {
        item.closingBalance = obj?.closing_balance;
        item.dateOfClosingBalance = value;
      }
      return item;
    });
    // setRowsDataBalanceConfirmation(tempRowsData);
    // setStoreRowsDataBalanceConfirmation(tempRowsData);
    // console.log(tempLoader);
    // setTempLoader(false);
  };

  const resetStatesForDemoSales = async () => {
    try {
      await Axios.post("/api/ResetStatesForDemoSales", {})
        .then((response) => {
          console.log("response ResetStatesForDemoSales", response);
          setShowLoadingIcon(false);
          listAllBusinessPartnerIds();
          // listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
          // listBusinessPartnersForBalanceConfirmation();
          setShowResetButton(false);
          if (response.data.message) {
            setShowWarningAlert(true);
            setApiErrorMsg(response?.data?.message);
            apiSuccessErrorAlertSetTimeout(setShowWarningAlert, setApiErrorMsg);
          }
        })
        .catch((error: any) => {
          console.log("error ResetStatesForDemoSales", error?.response);
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ResetStatesForDemoSales`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ResetStatesForDemoSales", error?.response);
      setShowLoadingIcon(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ResetStatesForDemoSales`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  useEffect(() => {
    // add event listener to the document object
    document.addEventListener("click", handleClickOutside);

    return () => {
      // remove event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    // check if click target is outside of the button
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowResetButton(false);
    }
  };

  const optionsListOwnTallyCompaniesForRecon = listOwnTallyCompaniesForRecon?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const companyNameSelect = (e: any, option: ListOwnTallyCompanies) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastOwnTallyCompany(option);
      listAllBusinessPartnerIds();
      // listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh();
      // listBusinessPartnersForBalanceConfirmation();
      listUserEmailTemplateForBalanceConfirmation("");
      getListUserEmailSetting();
    } else if (option === null) {
      // setOwnBusinessPartners([]);
      companyId.current = null;
    }
  };
  const getListOwnTallyCompaniesForRecon = async () => {
    try {
      await Axios.get<ListOwnTallyCompaniesRes>("/api/ListOwnTallyCompaniesForPartnerCommunication", {
        params: {
          useCaseType: UseCaseType.balanceConfirmation,
        },
      })
        .then((response) => {
          setListOwnTallyCompaniesForRecon(response?.data?.tallyCompanies);
          const lastTallyCompID = response?.data?.lastSelectedTallyCompanyId;
          if (lastTallyCompID) {
            const foundTallyCompany = response.data.tallyCompanies.find((c) => c.companyId === lastTallyCompID);
            const foundLastOwnTallyCompany = {
              firstLetter: foundTallyCompany?.companyName[0].toUpperCase(),
              ...foundTallyCompany,
            };
            setLastOwnTallyCompany(foundLastOwnTallyCompany);
            companyNameSelect({}, foundLastOwnTallyCompany);
          }
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ListOwnTallyCompaniesForRecon", error?.response);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListOwnTallyCompaniesForRecon`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListOwnTallyCompaniesForRecon", error?.response);
      setLoader(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListOwnTallyCompaniesForRecon`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const listPartnerCommunicationStatusResetMethod = () => {
    try {
      Axios.get<any>("/api/ListEmailAutoResetSetting")
        .then((response) => {
          console.log("response", response);

          if (!response.data?.autoReset) {
            setManualStatusChangeMethod(true);
          } else setManualStatusChangeMethod(false);
        })
        .catch((error) => {
          console.log("error updating AmountEntryType", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg} ListEmailAutoResetSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListEmailAutoResetSetting", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg} ListEmailAutoResetSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const resetStatesPartnerCommunication = () => {
    setShowLoadingIcon(true);
    const businessPartnerIds = rowsDataBalanceConfirmation?.map((entry) => entry?.businessPartnerId);
    try {
      Axios.post<any>("/api/ResetStatesPartnerCommunication", {
        businessPartnerIds,
        useCaseType: UseCaseType.balanceConfirmation,
        companyId: companyId.current ? companyId.current : undefined,
      })
        .then((response) => {
          const businessPartnersList = response.data?.businessPartnersList;
          const updatedData = storeBalanceConfirmationData.current?.map((data) => {
            const matchingPartner = businessPartnersList?.find(
              (partner) => partner.businessPartnerId === data.businessPartnerId
            );
            if (matchingPartner) {
              return { ...data, balanceConfirmationStatus: matchingPartner.balanceConfirmationStatus };
            }
            return data;
          });

          storeBalanceConfirmationData.current = updatedData;
          setRowsDataBalanceConfirmation(updatedData);
          setStoreRowsDataBalanceConfirmation(updatedData);
          setOpenBalanceConfirmationFilter(true);
          const buttonElement = document.getElementById("filterBalanceConfirmation_button");
          if (buttonElement) {
            buttonElement.click();
            setOpenBalanceConfirmationFilter(false);
          }
          setShowLoadingIcon(false);
          handleCloseResetModal();
        })
        .catch((error) => {
          console.log("error ResetStatesPartnerCommunication", error?.response);
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg} ResetStatesPartnerCommunication`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ResetStatesPartnerCommunication", error?.response);
      setShowLoadingIcon(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg} ResetStatesPartnerCommunication`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const handleCloseResetModal = () => {
    setIsOpenResetModal(false);
  };

  const getDownloadBalanceConfirmationReport = () => {
    try {
      Axios.get<any>("/api/DownloadBalanceConfirmationReport")
        .then((response) => {
          console.log("response", response);
          setDownloadBalanceConfirmationReportBase64(response.data?.reportWorkbookBase64);
          downloadBalanceConfirmationReport(response.data?.reportWorkbookBase64);
        })
        .catch((error) => {
          console.log("error DownloadBalanceConfirmationReport", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg} DownloadBalanceConfirmationReport`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DownloadBalanceConfirmationReport", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg} DownloadBalanceConfirmationReport`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const downloadBalanceConfirmationReport = (reportBase64) => {
    const excelData = Buffer.from(reportBase64 || downloadBalanceConfirmationReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Balance Confirmation.xlsx`);
  };

  const deleteUserEmailTemplate = (templateId) => {
    setShowDeleteLoadingIcon(true);
    try {
      Axios.delete<any>("/api/DeleteUserEmailTemplate", {
        data: {
          useCaseType: UseCaseType.balanceConfirmation,
          templateId,
        },
      })
        .then((response) => {
          console.log("response", response);
          listUserEmailTemplateForBalanceConfirmation("");
          handleCloseDeleteModal();
          setShowDeleteLoadingIcon(false);
        })
        .catch((error) => {
          console.log("error DeleteUserEmailTemplate", error?.response);
          setShowDeleteLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg} DeleteUserEmailTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DeleteUserEmailTemplate", error?.response);
      setShowDeleteLoadingIcon(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg} DeleteUserEmailTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const listFrequencyTable = () => {
    try {
      Axios.get<any>("/api/ListFrequencyTable")
        .then((response) => {
          const frequencyArray = response.data?.frequencyTableSettingsFilteredByCategories?.map(
            (item: { frequency: string }) => item.frequency
          );
          setFrequencyTableSettings(response.data?.frequencyTableSettingsFilteredByCategories);
          setOptionsBalanceConfirmationFrequency(frequencyArray);
        })
        .catch((error: any) => {
          console.log("error ListFrequencyTable", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListFrequencyTable`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListFrequencyTable", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListFrequencyTable`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  useEffect(() => {
    if (rowsDataBalanceConfirmation && defaultTallyCbDate) GetTallyClosingBalanceForAllBp(rowsDataBalanceConfirmation);

    // eslint-disable-next-line
  }, [statusDataReady, defaultTallyCbDate]);

  useEffect(() => {
    if (updatingTallyCbDate) {
      const intervalsInMs = [10_000, 20_000, 30_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            PollTallyClosingBalanceStatus();
          } finally {
            if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastTallyInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastTallyInterval.current);
    }

    return () => window.clearInterval(lastTallyInterval.current);
    // eslint-disable-next-line
  }, [updatingTallyCbDate]);

  const PollTallyClosingBalanceStatus = async () => {
    interface Response {
      isClosingBalanceUpdating: boolean;
      error: string;
      closingBalanceDate: string;
    }

    useFetch<Response>("/api/GetTallyClosingBalanceStatus", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          companyId: companyId.current,
        },
      },
      thenCallBack: (res) => {
        const { isClosingBalanceUpdating, error, closingBalanceDate } = res?.data;
        let TallyCbDate = "";
        if (!isClosingBalanceUpdating && !error) {
          if (closingBalanceDate) TallyCbDate = moment(closingBalanceDate).format("YYYY-MM-DD");
          else TallyCbDate = moment().format("YYYY-MM-DD");

          setDefaultTallyCbDate(TallyCbDate);
          setUpdatingTallyCbDate(false);

          // if (closingBalanceDate) setBulkTallyCbDate(TallyCbDate);
        } else if (isClosingBalanceUpdating) {
          setUpdatingTallyCbDate(true);
          if (closingBalanceDate && !bulkTallyCbDate)
            setBulkTallyCbDate(moment(closingBalanceDate).format("YYYY-MM-DD"));
          else if (!closingBalanceDate) setDefaultTallyCbDate(moment().format("YYYY-MM-DD"));
        }
      },
    });
  };

  const GetTallyClosingBalanceForAllBp = (rowsBalanceConfirmation: BusinessPartnersListForBalanceConfirmation[]) => {
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/GetTallyClosingBalanceForAllBp", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          companyId: companyId.current,
          closingBalanceDate: moment(defaultTallyCbDate).format("YYYY-MM-DD"),
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceOfAllBp } = res.data;
        if (closingBalanceOfAllBp) {
          rowsBalanceConfirmation.forEach((row) => {
            const closingBalObj = closingBalanceOfAllBp.find((cb) => cb.businessPartnerId === row.businessPartnerId);
            if (closingBalObj) {
              row.closingBalance = closingBalObj.closingBalance;
              row.dateOfClosingBalance = closingBalObj.closingBalanceDate;
            }
          });
          setRowsDataBalanceConfirmation([...rowsBalanceConfirmation]);

          // find highest count of balance confirmation date
          // const count: { [k: string]: number } = {};
          const firstRowCBDate = closingBalanceOfAllBp[0].closingBalanceDate;
          const allRowCBDateSame = closingBalanceOfAllBp.every((row) => row.closingBalanceDate === firstRowCBDate);
          // closingBalanceOfAllBp.forEach((e) => {
          //   if (count.hasOwnProperty(e.closingBalanceDate)) count[e.closingBalanceDate] += 1;
          //   else count[e.closingBalanceDate] = 1;
          // });
          // const arr = Object.entries(count)?.sort((a, b) => b[1] - a[1]);
          if (allRowCBDateSame) setBulkTallyCbDate(firstRowCBDate);
          else setBulkTallyCbDate(null);
        }
        setLoadingTallyCbAll(false);
      },
    });
  };

  const UpdateTallyClosingBalanceForAllBp = (date: string) => {
    setUpdatingTallyCbDate(true);
    setLoadingTallyCbAll(true);
    const momentDate = moment(date).isValid() ? moment(date) : moment();
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/UpdateTallyClosingBalanceForAllBp", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      config: {
        companyId: companyId.current,
        closingBalanceDate: momentDate.format("YYYY-MM-DD"),
      },
    });
  };

  const UpdateTallyClosingBalance = (row: Row<BusinessPartnersListForBalanceConfirmation>, date: string) => {
    const Response = {
      businessPartnerId: 0,
      closingBalance: 0,
      closingBalanceDate: "",
      openingBalance: 0,
    };

    useFetch<typeof Response>("/api/UpdateTallyClosingBalance", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      config: {
        businessPartnerId: row.original.businessPartnerId,
        companyId: companyId.current,
        closingBalanceDate: moment(date).format("YYYY-MM-DD"),
      },
      thenCallBack: (res) => {
        if (res.data.businessPartnerId === row.original.businessPartnerId)
          row.original.closingBalance = res.data.closingBalance;
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  const steps = [
    {
      selector: ".step1",
      content: "Update closing balance for the partners",
    },
    {
      selector: ".step2",
      content: "Update closing balance date for the partners",
    },
    {
      selector: ".step3",
      content: "Send email from here",
    },
    {
      selector: ".step4",
      content: "Select all partners at once to send emails",
    },
    {
      selector: ".step5",
      content: "Send emails to all partners",
    },
    {
      selector: ".step6",
      content: "Change reminder settings from here",
    },
    {
      selector: ".step7",
      content: "Apply filters on the table data",
    },
    {
      selector: ".step8",
      content: "Download email report for all partners",
    },
    {
      selector: ".step9",
      content: "Update email status from here",
    },
    {
      selector: ".step10",
      content: "Check latest email status update date from here",
    },
  ];

  const closeTour = () => {
    setTourState(false);
  };

  const enableTour = () => {
    setTourState(true);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Balance Confirmation", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <PartnerCommunicationContext.Provider value={PartnerCommunicationContextValue}>
        <ApiErrorCatch
          showUploadSuccessAlert={showSuccessAlert}
          showUploadErrorAlert={showErrorAlert}
          apiErrorMsg={apiErrorMsg}
          showUploadWarningAlert={showWarningAlert}
        />
        {
          <Grid className="table_list">
            <Grid position="relative">
              <Grid className="space_between mb_15">
                <div className="d_flex" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {!openBalanceConfirmationSettings && (
                    <>
                      {actor.integration === true && actor.actorInfo.accountingSoftware === "Tally" && (
                        <div className="">
                          <Autocomplete
                            sx={{
                              width: "300px",
                              backgroundColor: "#fff",
                            }}
                            value={lastOwnTallyCompany}
                            size="small"
                            onChange={(e, value) => companyNameSelect(e, value)}
                            id="businessPartner"
                            options={optionsListOwnTallyCompaniesForRecon.sort(
                              (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            disableClearable={true}
                            groupBy={(option: any) => option.firstLetter}
                            getOptionLabel={(option: any) => option.companyName}
                            renderInput={(params) => <TextField {...params} placeholder="Select Company" />}
                          />
                        </div>
                      )}
                      <Button
                        variant="contained"
                        className="theme_btn step9"
                        disabled={statusButtonClicked}
                        onClick={() => {
                          listBusinessPartnersForBalanceConfirmation();
                        }}
                        startIcon={<Sync className={statusDataFetching ? "loading-active" : ""} />}
                      >
                        Update email status
                      </Button>
                      {manualStatusChangeMethod && (
                        <Button
                          variant="contained"
                          className="theme_outline_btn"
                          onClick={() => {
                            setIsOpenResetModal(true);
                          }}
                        >
                          Reset status
                        </Button>
                      )}
                      <div className="step10">
                        <div style={{ fontSize: "10px" }}>Last Status Updated at:</div>
                        <Chip label={statusText} size="small" />
                      </div>
                      <Button
                        variant="contained"
                        className="theme_btn"
                        // disabled={statusButtonClicked}
                        onClick={() => {
                          enableTour();
                        }}
                        // startIcon={<Sync className={statusDataFetching ? "loading-active" : ""} />}
                      >
                        Take a Tour
                      </Button>
                      {((actor.id === 2866 && process.env.REACT_APP_NAKAD_ENV === "production") ||
                        (actor.id === 1882 && process.env.REACT_APP_NAKAD_ENV === "staging") ||
                        (actor.id === 11920 && process.env.REACT_APP_NAKAD_ENV === "test")) && (
                        <span
                          ref={buttonRef}
                          onClick={() => {
                            setShowResetButton(true);
                          }}
                          style={{ width: "155px", marginLeft: "60px" }}
                        >
                          {showResetButton && (
                            <Button
                              variant="contained"
                              className="theme_outline_btn"
                              onClick={() => {
                                setShowLoadingIcon(true);
                                resetStatesForDemoSales();
                              }}
                            >
                              {showLoadingIcon ? (
                                <span className="file-icon">
                                  <i className="fas fa-circle-notch fa-spin" />
                                </span>
                              ) : null}
                              Reset States
                            </Button>
                          )}
                        </span>
                      )}
                    </>
                  )}
                </div>

                {openBalanceConfirmationSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: "#27b27c", ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenBalanceConfirmationSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {!openBalanceConfirmationSettings && (
                  <div className="d_flex" style={{ gap: 16, flexWrap: "wrap" }}>
                    {/* <Tooltip title="Add Partner Menu" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton color="inherit">
                          <PersonAdd fontSize={"medium"} />
                        </IconButton>
                      </Avatar>
                    </Tooltip> */}
                    <Tooltip title="Balance Confirmation Settings" arrow={true}>
                      <Avatar className="step6" sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => setOpenBalanceConfirmationSettings(true)} color="inherit">
                          <Settings />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip
                      title={
                        statusDataFetching || updatingTallyCbDate || loadingTallyCbAll
                          ? `Please wait till ${statusDataFetching ? "status" : "closing balance"} gets updated`
                          : "Filter"
                      }
                      arrow={true}
                    >
                      <Avatar className="step7" sx={{ background: "#27b27c" }}>
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            onClick={() => setOpenBalanceConfirmationFilter(true)}
                            color="inherit"
                            disabled={!statusDataReady}
                            sx={{
                              pointerEvents: "auto !important",
                            }}
                          >
                            {isFilterAppliedBalanceConfirmation ? <FilterAlt /> : <FilterAltOff />}
                          </IconButton>
                        </span>
                      </Avatar>
                    </Tooltip>
                    <Tooltip
                      title={
                        statusDataFetching || updatingTallyCbDate || loadingTallyCbAll
                          ? ` Please wait till ${statusDataFetching ? "status" : "closing balance"} gets updated`
                          : "Bulk Email"
                      }
                      arrow={true}
                    >
                      <Avatar className="step5" sx={{ background: "#27b27c" }}>
                        <IconButton
                          onClick={() => {
                            setOpenSelectEmailTemplate(true);
                            setSendSoleOrBulkMail(EmailSendCount.Bulk);
                          }}
                          disabled={statusDataFetching || (selectedRow.length < 2 ? true : false)}
                          color="inherit"
                        >
                          {bulkSendingEmailsStart && !isCompleteBulkEmailSend ? (
                            <span
                              className="file-icon"
                              style={{
                                position: "absolute",
                                transform: "scale(2.5)",
                                left: 8,
                              }}
                            >
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fa-icon">
                            <i className="fas fa-envelope" />
                          </span>
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Balance Confirmation Report" arrow={true}>
                      <Avatar className="step8" sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => getDownloadBalanceConfirmationReport()} color="inherit">
                          <Download />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </Grid>
              {!openBalanceConfirmationSettings && (
                <Card
                  className="vertical_center_align mb_15"
                  sx={{
                    p: 0.5,
                    borderRadius: "32px !important",
                    gap: 1,
                    width: Object.keys(appliedFilters).length ? "auto" : "fit-content",
                  }}
                >
                  <IconButton disableTouchRipple={true}>
                    {Object.keys(appliedFilters).length ? <FilterList /> : <FilterListOff />}
                  </IconButton>
                  {Object.keys(appliedFilters).length ? (
                    <div className="d_flex" style={{ flexWrap: "wrap", gap: "8px", paddingInline: "8px" }}>
                      {Object.keys(appliedFilters).map((_key) =>
                        appliedFilters[_key] ? (
                          <div>
                            <div style={{ fontSize: "10px" }}>{_key}</div>
                            <Chip label={appliedFilters[_key]} size="small" />
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </Card>
              )}
              <div
                className="bpDashboard"
                style={{
                  height: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openBalanceConfirmationSettings ? "-50px" : "unset",
                }}
              >
                <Grid>
                  {!openBalanceConfirmationSettings && (
                    <BpDashboardTable
                      columns={columnDefinitionBalanceConfirmation}
                      rows={rowsDataBalanceConfirmation}
                      sortEnable={true}
                      setSelectedRow={setSelectedRow}
                      actorType="BPDashboard"
                      loading={loader || !statusDataReady}
                    />
                  )}

                  {openBalanceConfirmationSettings && (
                    <BalanceConfirmationSettings
                      frequencyTableSettings={frequencyTableSettings}
                      optionsBalanceConfirmationFrequency={optionsBalanceConfirmationFrequency}
                      listUserEmailTemplate={listUserEmailTemplateForBalanceConfirmation}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }

        <BalanceConfirmationFilter
          openBalanceConfirmationFilter={openBalanceConfirmationFilter}
          setOpenBalanceConfirmationFilter={setOpenBalanceConfirmationFilter}
          allCategories={storeAllCategories.current}
          rowsDataBalanceConfirmation={rowsDataBalanceConfirmation}
          setRowsDataBalanceConfirmation={setRowsDataBalanceConfirmation}
          storeRowsDataBalanceConfirmation={storeRowsDataBalanceConfirmation}
          setIsFilterAppliedBalanceConfirmation={setIsFilterAppliedBalanceConfirmation}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
        />

        {/* Balance confirmation */}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
        />
        <SendEmailTemplate
          companyId={companyId.current}
          base64Image={file}
          signature={signature}
          setShowErrorAlert={setShowErrorAlert}
          setApiErrorMsg={setApiErrorMsg}
          setShowSuccessAlert={setShowSuccessAlert}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          listAllBusinessPartnerMapping={listBusinessPartnersForBalanceConfirmation}
          // after sending mail to update date
          listAllBusinessPartnersWSR={listAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh}
          // setLoader={setLoader}
          partnerCommunicationSelected={"Balance confirmation"}
          templateType={"CB"}
          listUserEmailTemplate={listUserEmailTemplateForBalanceConfirmation}
          storeAllBusinessPartnerCheck={storeAllBusinessPartnerCheck}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          setShowErrorAlert={setShowErrorAlert}
          setApiErrorMsg={setApiErrorMsg}
          setShowSuccessAlert={setShowSuccessAlert}
          listUserEmailTemplate={listUserEmailTemplateForBalanceConfirmation}
          templateType={"CB"}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          width70Per={true}
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            setShowErrorAlert={setShowErrorAlert}
            setShowSuccessAlert={setShowSuccessAlert}
            setApiErrorMsg={setApiErrorMsg}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers}
            // listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
            // listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
          />
        </Dialog>

        <StatusResetWarningModal
          isOpenResetModal={isOpenResetModal}
          handleCloseResetModal={handleCloseResetModal}
          handleReset={resetStatesPartnerCommunication}
          showLoadingIcon={showLoadingIcon}
        />
      </PartnerCommunicationContext.Provider>
      <Tour steps={steps} isOpen={tourState} onRequestClose={closeTour} />
    </LoggedInSkeleton>
  );
};

export default BalanceConfirmation;
