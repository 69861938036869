import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useState } from "react";
import { Loan } from "../../entity/Loan";
import { formatDate } from "../../Utils/DateUtils";
import { formatMoney, subtractMoney } from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getBankTopBarButtons } from "../Common/TopNavBar";
import "./../Tier1DataUpdate/Tier1DataUpdate.scss";
import "./../Tier2EarlyPayment/Tier2EarlyPayment.scss";
import BankDisbursementDetailsButtonCellRenderer from "./BtnCellRenderer";

export interface ListLoansForBankApprovalResponse {
  loans: Loan[];
}

/**
 * This is a utility interface which extracts all the fields our system needs to have for a sufficient representation of a invoice.
 */
interface DisbursementRow {
  loan_number: number;
  customer_name: string;
  loan_amount: string;
  anchor_name: string;
  annualized_irr: string;
  repayment_date: string; // check type
  details: number;
}
/**
 * This component renders the disbursement screen of the bank module. It displays all the loan potentialities i.e all the Tier1 and Tier2 invoices which are matched and against which loan is claimed by Tier2. It facilitates the sanctioning of loans by the Bank. By clicking to "More Details" button, it redirects to loan details for the complete information regarding the concerned loan.
 * @Component
 */
const BankDisbursement = () => {
  const [pendingLoans, setPendingLoans] = useState([] as Loan[]);
  // const [selectedLoan, setSelectedLoan] = useState({} as Loan);
  const [loader, setLoader] = useState(true);
  /**
   * This is the column defintion for the table present on the Disbursement screen.
   */
  const columnDefs: ColDef[] = [
    { headerName: "Loan Number", field: "loan_number", minWidth: 200 },
    { headerName: "Customer", field: "customer_name", minWidth: 200 },
    {
      headerName: "Loan",
      field: "loan_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 200,
    },
    {
      headerName: "Interest Income",
      field: "interest_income",
      cellStyle: { color: "#48AC23" },
      minWidth: 200,
    },
    { headerName: "Payer", field: "anchor_name", minWidth: 200 },
    { headerName: "Annualized IRR", field: "annualized_irr", minWidth: 200 },
    {
      headerName: "Repayment Date",
      field: "repayment_date",
      //  valueFormatter: dateValueFormatter,
      minWidth: 200,
    },
    {
      headerName: "Details",
      field: "details",
      suppressMenu: true,
      cellRenderer: "btnCellRenderer",
      headerClass: "grid-header-centered",
      cellClass: "center-cell",
      cellRendererParams: {
        clicked(_bankLoan: Loan) {
          // console.log(rowIndex)
          // setSelectedLoan(_bankLoan);
        },
        pendingLoans,
      },
      minWidth: 200,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  };
  /**
   * This function renders the UI for "More Detail" on the disbursement screen..
   */
  const frameworkComponents = {
    btnCellRenderer: BankDisbursementDetailsButtonCellRenderer,
  };
  /**
   * This method returns the data which is published on the screen.
   * @public
   */
  const getPendingLoanRows = () => {
    return pendingLoans.map((loan) => {
      if (loan.loanType === "Tier1EarlyPayment") {
        return {
          loan_number: loan.loanId,
          customer_name: loan.tier1EarlyPayment.tier1.actorInfo.name,
          loan_amount: formatMoney(loan.tier1EarlyPayment.earlyPaymentAmount),
          // interest_income: '',
          interest_income: formatMoney(
            subtractMoney(loan.tier1EarlyPayment.loanAmount, loan.tier1EarlyPayment.earlyPaymentAmount)
          ),
          anchor_name: loan.tier1EarlyPayment.anchor.actorInfo.name,
          annualized_irr: loan.tier1EarlyPayment.interestRate.toFixed(2) + " %",
          repayment_date: formatDate(loan.tier1EarlyPayment.bankSettlementDate),
          details: loan.loanId,
          // annualized_irr:
          // repayment_date:
        } as DisbursementRow;
      } else if (loan.loanType === "Tier2EarlyPayment") {
        return {
          loan_number: loan.loanId,
          customer_name: loan.tier1Tier2InvoiceMapping.tier2.actorInfo.name,
          loan_amount: formatMoney(loan.tier1Tier2InvoiceMapping.earlyPaymentAmount),
          // interest_income: '',
          interest_income: formatMoney(
            subtractMoney(loan.tier1Tier2InvoiceMapping.loanAmount, loan.tier1Tier2InvoiceMapping.earlyPaymentAmount)
          ),
          anchor_name: loan.tier1Tier2InvoiceMapping.anchor.actorInfo.name,
          annualized_irr: loan.tier1Tier2InvoiceMapping.interestRate.toFixed(2) + " %",
          repayment_date: formatDate(loan.tier1Tier2InvoiceMapping.bankSettlementDate),
          details: loan.loanId,
        } as DisbursementRow;
      }
      return {};
      // return {
      //   loan_number: loan.loanId,
      //   customer_name: loan.tier1Tier2InvoiceMapping.tier2.tier1Tier2InvoiceMapping.name,
      //   anchor_name: loan.tier1Tier2InvoiceMapping.anchor.actorInfo.name,
      //   loan_amount: formatMoney(loan.tier1Tier2InvoiceMapping.loanAmount),
      //   annualized_irr: loan.tier1Tier2InvoiceMapping.interestRate.toFixed(2) + " %",
      //   repayment_date: loan.tier1Tier2InvoiceMapping.tier1Invoice.dueDate,
      //   details: loan,
      // } as DisbursementRow;
    });
  };
  /**
   * This method fetches all the invoices which are pending for bank appproval.
   */
  const onGridReady = () => {
    axios
      .get<ListLoansForBankApprovalResponse>("/api/ListLoansForBankApproval", {
        params: {
          approvalStatus: "Pending",
        },
      })
      .then((response) => {
        console.log("LOANS", response.data.loans);
        setPendingLoans(response.data.loans);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <LoggedInSkeleton topBarButtons={getBankTopBarButtons("Disbursement")}>
      <div>
        <div className="ag-theme-alpine mygrid" style={{ paddingLeft: "2vw", paddingRight: "2vw" }}>
          {loader && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReady}
            rowData={getPendingLoanRows()}
            domLayout="autoHeight"
          />
        </div>
      </div>
    </LoggedInSkeleton>
  );
};
export { BankDisbursement };
