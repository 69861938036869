/* eslint-disable react-hooks/exhaustive-deps */
import { OutlinedTextFieldProps, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

type Modify<T, R> = Omit<T, keyof R>;
type focusEvent = React.FocusEvent<HTMLInputElement>;

type TextFieldProps = Modify<
  OutlinedTextFieldProps,
  {
    onBlur?: (e: focusEvent, value: number) => void;
    variant?: string;
  }
>;

interface MonetaryInputProps extends TextFieldProps {
  value: string | number;
  setValue: (value: number) => void;
  allowNegative?: boolean;
  disableFormatting?: boolean;
  onBlur?: (e: focusEvent, value: number) => void;
  onKeyUp?: TextFieldProps["onKeyUp"];
  onInput?: TextFieldProps["onInput"];
  reformatMoney?: (fn: (allowNegative?: boolean) => void) => void;
}

const MonetaryInput = (props: MonetaryInputProps) => {
  const [balance, setBalance] = useState(props.value?.toLocaleString("en-IN") || "");

  const formatMoney = useCallback(
    (allowNegative: boolean = true) => {
      if (props.disableFormatting) return;

      let newBal = "";
      if (allowNegative) {
        newBal = balance.toString().replace(/(?<=-)[^\d.]+/g, "") || "";
        newBal = newBal.replace(/[^\-\d.]+/g, "") || "";
      } else {
        newBal = balance.toString().replace(/[^\d.]+/g, "") || "";
      }
      if (newBal.endsWith(".")) {
        newBal.match(/\./g).length > 1 ? setBalance(parseFloat(newBal).toLocaleString("en-IN")) : setBalance(newBal);
      } else if (newBal) {
        if (parseFloat(newBal)) newBal = parseFloat(newBal).toLocaleString("en-IN");

        setBalance(newBal);
      } else {
        setBalance("");
      }
    },
    [balance]
  );

  useEffect(() => {
    if (props.reformatMoney) props.reformatMoney(formatMoney);
  }, [props, props.value]);

  const parseBalance = (): number => {
    const parsedBalance = parseFloat(balance.replace(/[^\-\d.]+/g, "")) || 0;
    return parsedBalance;
  };
  return (
    <TextField
      data-monetary-input={true}
      {...props}
      type={"text"}
      value={balance}
      variant="outlined"
      onInput={(e) => {
        setBalance((e.target as HTMLInputElement).value);
        if (props.onInput) props.onInput(e);
      }}
      onKeyUp={(e) => {
        formatMoney(props.allowNegative);
        if (props.onKeyUp) props.onKeyUp(e);
      }}
      onBlur={(e) => {
        const newBalance = e.target.value;
        isNaN(parseFloat(newBalance)) ? setBalance("0") : setBalance(newBalance);

        if (newBalance.endsWith(".")) {
          setBalance(parseFloat(newBalance) ? parseFloat(newBalance.slice(0, -1)).toLocaleString("en-IN") : "0");
        }
        const validBalance = parseBalance();
        props.setValue(validBalance);
        if (props.onBlur) props.onBlur(e as focusEvent, validBalance);
      }}
    />
  );
};

export default MonetaryInput;
