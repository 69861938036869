import { ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useState } from "react";
import { Loan } from "../../../src/entity/Loan";
import { formatDate } from "../../Utils/DateUtils";
import { formatMoney, subtractMoney } from "../../Utils/MoneyUtils";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getBankTopBarButtons } from "../Common/TopNavBar";
import "./../Tier1DataUpdate/Tier1DataUpdate.scss";
import "./../Tier2EarlyPayment/Tier2EarlyPayment.scss";
import BtnCellRenderer from "./BtnCellRenderer";

export interface ListLoansForBankApprovalResponse {
  loans: Loan[];
}

/**
 * This is a utility interface which extracts all the fields our system needs to have for a sufficient representation of a invoice.
 */
interface CollectionRow {
  loan_number: number;
  customer_name: string;
  loan_amount: string;
  anchor_name: string;
  annualized_irr: string;
  repayment_date: string;
  // anchor_ack: string;
  details: number;
}
/**
 * This method contain collection of all the loans which are approved by Bank. It also have a "Payer Acknowledgement" entry which indicates whether payer(Anchor) has adjusted the markings in it's ledger for the particular loan.
 * @Component
 */
const BankCollection = () => {
  const [loans, setLoans] = useState([] as Loan[]);
  // const [selectedLoan, setSelectedLoan] = useState({} as BankLoan);
  const [loader, setLoader] = useState(true);
  const columnDefs: ColDef[] = [
    { headerName: "Loan Number", field: "loan_number", minWidth: 200 },
    { headerName: "Customer", field: "customer_name", minWidth: 200 },
    {
      headerName: "Loan",
      field: "loan_amount",
      cellStyle: { color: "#48AC23" },
      minWidth: 200,
    },
    {
      headerName: "Interest Income",
      field: "interest_income",
      cellStyle: { color: "#48AC23" },
      minWidth: 200,
    },
    { headerName: "Payer", field: "anchor_name", minWidth: 200 },
    { headerName: "Annualized IRR", field: "annualized_irr", minWidth: 200 },
    {
      headerName: "Repayment Date",
      field: "repayment_date",
      // valueFormatter: dateValueFormatter,
      minWidth: 200,
    },
    // {
    //   headerName: "Payer acknowledgement",
    //   field: "anchor_ack",
    //   minWidth: 200,
    //   cellStyle: (params: any) => (params.value === "Yes" ? { color: "#29ba74" } : { color: "red" }),
    // },
    {
      headerName: "Details",
      field: "details",
      suppressMenu: true,
      cellRenderer: "btnCellRenderer",
      headerClass: "grid-header-centered",
      cellClass: "center-cell",
      cellRendererParams: {
        clicked(_bankLoan: Loan) {
          // setSelectedLoan(bankLoan);
        },
      },
      minWidth: 200,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  /**
   * This function renders the UI for "More Details" page.
   */
  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer,
  };
  /**
   * This handle is called to fetch all the invoices on which loan is approved by Bank.
   */
  const onGridReady = () => {
    axios
      .get<ListLoansForBankApprovalResponse>("/api/ListLoansForBankApproval", {
        params: {
          approvalStatus: "Approved",
        },
      })
      .then((response) => {
        setLoans(response.data.loans);
        console.log("Collection Loan", response.data.loans);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  /**
   * This method returns the data which is published on the screen.
   * @public
   */
  const getBankLoans = () => {
    return loans.map((loan) => {
      // add if else
      // const row: CollectionRow = {
      //   anchor_name: loan.tier1EarlyPayment.anchor.actorInfo.name,
      //   // anchor_ack: loan.tier1Tier2InvoiceMapping.anchorAdjustment === "Done" ? "Yes" : "No",
      //   // annualized_irr: loan.tier1Tier2InvoiceMapping.interestRate.toFixed(2) + " %",
      //   customer_name: loan.tier1EarlyPayment.tier1.actorInfo.name,
      //   loan_amount: formatMoney(loan.tier1EarlyPayment.loanAmount),
      //   // repayment_date: loan.tier1Tier2InvoiceMapping.tier1Invoice.dueDate,
      //   // details: loan,
      //   loan_number: loan.loanId,
      //   details : loan.loanId,
      // };
      // return row;
      if (loan.loanType === "Tier1EarlyPayment") {
        return {
          loan_number: loan.loanId,
          customer_name: loan.tier1EarlyPayment.tier1.actorInfo.name,
          loan_amount: formatMoney(loan.tier1EarlyPayment.earlyPaymentAmount),
          // interest_income: '',
          interest_income: formatMoney(
            subtractMoney(loan.tier1EarlyPayment.loanAmount, loan.tier1EarlyPayment.earlyPaymentAmount)
          ),
          anchor_name: loan.tier1EarlyPayment.anchor.actorInfo.name,
          details: loan.loanId,
          annualized_irr: loan.tier1EarlyPayment.interestRate.toFixed(2) + " %",
          repayment_date: formatDate(loan.tier1EarlyPayment.bankSettlementDate),
          // repayment_date:formatDate(loan.tier1EarlyPayment.tier1Invoice.dueDate),
          // annualized_irr:
          // repayment_date:
        } as CollectionRow;
      } else if (loan.loanType === "Tier2EarlyPayment") {
        return {
          loan_number: loan.loanId,
          customer_name: loan.tier1Tier2InvoiceMapping.tier2.actorInfo.name,
          loan_amount: formatMoney(loan.tier1Tier2InvoiceMapping.earlyPaymentAmount),
          // interest_income: '',
          interest_income: formatMoney(
            subtractMoney(loan.tier1Tier2InvoiceMapping.loanAmount, loan.tier1Tier2InvoiceMapping.earlyPaymentAmount)
          ),
          anchor_name: loan.tier1Tier2InvoiceMapping.anchor.actorInfo.name,
          annualized_irr: loan.tier1Tier2InvoiceMapping.interestRate.toFixed(2) + " %",
          repayment_date: formatDate(loan.tier1Tier2InvoiceMapping.bankSettlementDate),
          details: loan.loanId,
          // annualized_irr:
          // repayment_date:
        } as CollectionRow;
      }
      return {};
    });
  };

  return (
    <LoggedInSkeleton topBarButtons={getBankTopBarButtons("Collection")}>
      <div>
        <div className="ag-theme-alpine mygrid" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
          {loader && <Loader />}
          <AgGridReact
            enableCellTextSelection={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReady}
            rowData={getBankLoans()}
            domLayout="autoHeight"
            rowClassRules={{
              highlight(params) {
                return params.data.loan_number === "1";
              },
            }}
          />
        </div>
      </div>
    </LoggedInSkeleton>
  );
};

export default BankCollection;
