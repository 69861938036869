import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { ErrorResponse, TallyAcceptedConfigColumnsResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { columnHeaderDescription, labels } from "../../../Utils/Recon/ColumnHeaderConfig/Constants";
import {
  columnHeaderNameLabel,
  Customized,
  DOCUMENT_TYPE_NAMES,
  EMPTY_CONFIG,
  RECON360_SUB_TAB,
} from "../../../Utils/Recon/Recon360/Constants";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import SanitizerRules from "../DataSanitizer/SanitizerRules";
import "../Recon360.scss";

export interface Element {
  name: string;
  value: string[];
}

export interface CodesWithSign {
  code: string;
  sign: number;
  addParticulars: string[];
  excludeParticulars: string[];
  group: { groupId: string; groupName: string };
}

export interface LedgerConfiguration {
  [key: string]: any;
  documentDate: string[];
  documentType: string[];
  otherUniqueId: string[];
  paymentId: string[];
  dncnId: string[];
  invoiceIdForDncn: string[];
  particulars: string[];
  debitAmount: string[];
  creditAmount: string[];
  referenceNumber: string[];
  postingDate: string[];
  documentNumber: string[];
  clearingDocument: string[];
  clearingDate: string[];
  closingBalance: number;

  invoice: CodesWithSign[];
  payment: CodesWithSign[];
  debitNote: CodesWithSign[];
  debitAndCreditNotes: CodesWithSign[];
  creditNote: CodesWithSign[];
  reversal: CodesWithSign[];
  tds: CodesWithSign[];
  interDocument: CodesWithSign[];
}

export interface ColumnsToSanitize {
  columnToSanitize: string;
  sanitizationRules: SanitizeConfiguration[];
}

export interface SanitizeConfiguration {
  ruleId: number;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
}

export interface ListAnchorLedgerConfigResponse {
  ledgerConfigTemplate: LedgerConfiguration;
}

export type removeitem = {
  nthIndex: number;
  ruleId: number;
  text: string;
  textToReplaceWith: string;
};

export type Item = {
  category: string;
  n: false;
  rule: string;
  ruleId: number;
  string1: true;
  string2: false;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
};

export type rule = {
  category: string;
  n: false;
  rule: string;
  ruleId: number;
  string1: true;
  string2: false;
};

export interface ConfigpoupRecon {
  saveRules: (rulesSaveRules: Item[], deleted: boolean) => void;
  columnName: string;
  labelName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  items: Item[];
  rules: rule[];
  setItems: (items: Item[]) => void;
  rulesWithVal: any;
  setRulesWithVal: (rulesWithVal: any) => void;
  isDelete: boolean;
  handleRemoveClick: (row: removeitem, ind: number) => void;
}

const options: any[] = [];
const filter = createFilterOptions<any>();
export const ConfigPopupAnchorRecon360Context = createContext<ConfigpoupRecon | null>(null);

const ColumnHeaderConfig = (props: any) => {
  const { actor } = useContext(userContext);
  const [saveValueColumnHeader, setSaveValueColumnHeader] = useState<string[]>([]);
  const [addElementIndex, setAddElementIndex] = useState<number>(null);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>(props.templateName || "");
  const [configHeaderName, setConfigHeaderName] = useState<LedgerConfiguration>(
    props.headerName || ({} as LedgerConfiguration)
  );
  const [headerName, setHeaderName] = useState(
    Object.values(columnHeaderNameLabel).map((name) => ({ name, value: [] }))
  );
  const [sanitizeCol, setSanitizeCol] = useState<ColumnsToSanitize[]>(props.allRules || props.sanitizeCol || []);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [columnName, setColumnName] = useState<string>("");
  const [labelName, setLabelName] = useState("");
  const [items, setItems] = React.useState([]);
  const [rulesWithVal, setRulesWithVal] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [duplicateTagName, setDuplicateTagName] = useState("");
  const [tallyHeaderNames, setTallyHeaderNames] = useState<string[]>([]);

  useEffect(() => {
    const tempHeaderNames = Object.values(columnHeaderNameLabel)?.map((name) => {
      const value = props.headerName[name] || [];
      return {
        name,
        value,
      };
    });
    setHeaderName(tempHeaderNames);
  }, [props.headerName]);

  useEffect(() => {
    if (actor.actorType !== "Admin") {
      const nonCustomizeTemplate = props.configList.some((template: any) => template.templateName === templateName);
      if (nonCustomizeTemplate) {
        setSelectedTemplateName(templateName);
        setTemplateName(templateName);
      } else {
        props.setShowCustomOption(true);
        setSelectedTemplateName(Customized);
        setTemplateName(Customized);
      }
    }
    // eslint-disable-next-line
  }, [selectedTemplateName, templateName, props.configList]);

  const sortArraysInObject = (obj: LedgerConfiguration) => {
    DOCUMENT_TYPE_NAMES.forEach((key) => {
      if (obj[key]) {
        obj[key].sort((a: any, b: any) => a.sign - b.sign);
      }
    });
  };

  const onNextClickError = () => {
    const configPayload = Object.fromEntries(headerName.map((ele) => [ele.name, ele.value]));
    let tempObj: any;
    if (selectedTemplate > 0) {
      tempObj = configHeaderName;
      sortArraysInObject(tempObj);
      Object.assign(tempObj, configPayload);
    } else {
      tempObj = props.headerName;
      sortArraysInObject(tempObj);
      Object.assign(tempObj, configPayload);
    }
    if (!tempObj.documentType?.[0]?.trim() && props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE) {
      // if there is no documentType added in payment advice case do not show doctype modal
      const emptyDocumentsObject = Object.fromEntries(DOCUMENT_TYPE_NAMES.map((key) => [key, []]));
      Object.assign(tempObj, emptyDocumentsObject);
      props.saveRulesApi(tempObj);
    } else {
      if (templateName?.trim().length < 1 && actor.actorType === "Admin") {
        document.getElementById("templateNameError").innerHTML = "Template Name is required";
      } else {
        setSanitizeCol(sanitizeCol);
        props.openNextDialog(sanitizeCol, tempObj, templateName);
      }
    }
  };

  const rules = props.rules;

  useEffect(() => {
    const getRules = (name: string) => {
      const rulesGetRules = sanitizeCol?.filter((val: { columnToSanitize: string }) => name === val.columnToSanitize);
      if (rulesGetRules?.length > 0) {
        const sanitizationRules = rulesGetRules[0].sanitizationRules;
        return sanitizationRules;
      } else {
        return [];
      }
    };
    const result = getRules(columnName);
    setItems(result);
  }, [columnName, sanitizeCol]);

  const changeTemplate = (event: any) => {
    if (Number(event.target.value) === 0) {
      setTemplateName("");
      setHeaderName(Object.values(columnHeaderNameLabel).map((name) => ({ name, value: [] })));
      setSanitizeCol([]);
      setSelectedTemplate(event.target.value);
    } else {
      getConfigTemplate(event.target.value);
      setSelectedTemplate(event.target.value);
    }
  };
  const getConfigTemplate = async (id: number) => {
    try {
      await axios
        .get<ListAnchorLedgerConfigResponse>(`/api/ListLedgerConfigTemplate/?templateId=${id}`)
        .then((response) => {
          const preValue = response.data.ledgerConfigTemplate.config;
          const newTemp = Object.values(columnHeaderNameLabel).map((name) => {
            const value = preValue[name] || [];
            return {
              name,
              value,
            };
          });
          props.setNewMapping(false);
          setHeaderName(newTemp);
          props.setDebitAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.debitAmountSignReverseFlag);
          props.setCreditAmountSignReverseFlag(
            response?.data?.ledgerConfigTemplate?.config?.creditAmountSignReverseFlag
          );
          setConfigHeaderName(preValue);
          setSanitizeCol(response.data.ledgerConfigTemplate.columnsToSanitize);
        })
        .catch((err) => {
          if (err?.response?.data?.message !== undefined) {
            const dataObj = err.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListLedgerConfigTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (err: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListLedgerConfigTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const handleRemoveClick = (row: removeitem, index: any) => {
    console.log("row", row);
    const rowArray = items.filter((item, ind) => {
      console.log("item", item);
      return ind !== index;
    });
    setItems(rowArray);
    setIsDelete(true);
  };

  const onChangeTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 16) {
      document.getElementById("templateNameError").innerHTML = "You can't use more than 16 characters";
      setTimeout(() => {
        document.getElementById("templateNameError").innerHTML = "";
      }, 3000);
    } else {
      setTemplateName(event.target.value);
      document.getElementById("templateNameError").innerHTML = "";
    }
  };

  const saveRules = (rulesSaveRules: SanitizeConfiguration[], deleted: boolean) => {
    const sanCol = [...sanitizeCol];

    console.log(deleted);

    const payload = {
      columnToSanitize: columnName,
      sanitizationRules: rulesSaveRules,
    };

    if (sanCol.length > 0) {
      let columnFound = false;
      for (const [i] of sanCol.entries()) {
        if (sanCol[i].columnToSanitize === columnName) {
          sanCol[i].sanitizationRules = rulesSaveRules;
          columnFound = true;
          break;
        }
      }

      if (columnFound === false) {
        sanCol.push(payload);
      }
    } else if (sanCol.length === 0) {
      sanCol.push(payload);
    }
    setSanitizeCol(sanCol);
  };

  const providerValue: ConfigpoupRecon = {
    saveRules,
    columnName,
    labelName,
    open,
    setOpen,
    rules,
    items,
    setItems,
    rulesWithVal,
    setRulesWithVal,
    isDelete,
    handleRemoveClick,
  };

  const delRow = (element: Element, ind: number, index: number) => {
    element.value.splice(ind, 1);
    const newData = headerName;
    const user = newData.find((d) => d.name === element.name);
    Object.assign(user, element);
    setHeaderName([...newData]);
    if (headerName[index].value.length === 0) {
      headerName[index].value = [""];
    }
  };

  const handleChangeReverseSign = (e: any, ele: any) => {
    if (ele?.name === "debitAmount") {
      props.setDebitAmountSignReverseFlag(e.target.checked);
    } else if (ele?.name === "creditAmount") {
      props.setCreditAmountSignReverseFlag(e.target.checked);
    }
  };

  const closeConfigModal = async () => {
    if (actor.actorType !== "Admin") {
      await props.getConfig();
      props.setShowCustomOption(false);
    }
    props.setDebitAmountSignReverseFlag(false);
    props.setCreditAmountSignReverseFlag(false);
    props.setOpenDialog(false);
  };

  const handleChangeSelectTemplate = (event: any) => {
    setSelectedTemplateName(event.target.value);
    setTemplateName(event.target.value);
    if (event.target.value === Customized) {
      props.getConfig();
    } else if (event.target.value === "Remove existing template") {
      setSelectedTemplateName(Customized);
      setTemplateName(Customized);
      props.removeExistingTemplate();
      setHeaderName(Object.values(columnHeaderNameLabel).map((name) => ({ name, value: [] })));
      setConfigHeaderName(EMPTY_CONFIG as LedgerConfiguration);
      props.setDebitAmountSignReverseFlag(false);
      props.setCreditAmountSignReverseFlag(false);
      setSanitizeCol([]);
    } else {
      const selectedTemplateVal = props.configList.find(
        (template: any) => template.templateName === event.target.value
      );
      const selectedTemplateId = selectedTemplateVal.templateId;
      setSelectedTemplate(selectedTemplateId);
      getConfigTemplate(selectedTemplateId);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const checkColumnToSanitize = (jsonData: any, name: string) => {
    return jsonData?.some((obj: any) => obj.columnToSanitize === name && obj.sanitizationRules?.length > 0);
  };
  useEffect(() => {
    console.log("sanitizeCol has changed!", sanitizeCol);
  }, [sanitizeCol]);

  const checkIsDuplicateHeader = (newColumns: string[], allColumns: Element) => {
    let isDuplicate = false;
    isDuplicate = newColumns.some((column) => {
      return allColumns.value.some((value) => value.toLowerCase() === column.toLowerCase());
    });

    isDuplicate =
      isDuplicate ||
      newColumns.some((column, _i) =>
        newColumns.some((col, i) => col.toLowerCase() === column.toLowerCase() && i !== _i)
      );

    return {
      isDuplicate,
      DuplicateTagName: allColumns.name[0].toUpperCase() + allColumns.name.slice(1),
    };
  };

  useEffect(() => {
    // fetch tally column on ComponentDidMount
    if (props.integration) {
      try {
        axios
          .get<TallyAcceptedConfigColumnsResponse>(`/api/GetTallyAcceptedConfigColumns/`)
          .then((response) => {
            if (response.data?.columns) setTallyHeaderNames(response.data?.columns);
          })
          .catch((err) => {
            if (err?.response?.data?.message !== undefined) {
              const dataObj = err.response.data as ErrorResponse;
              setApiErrorMsg(dataObj.message);
              setShowErrorAlert(true);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            } else {
              setShowErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}GetTallyAcceptedConfigColumns`);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            }
          });
      } catch (err: any) {
        setShowErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}GetTallyAcceptedConfigColumns`);
        apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigPopupAnchorRecon360Context.Provider value={providerValue}>
      <>
        <ApiErrorCatch showUploadErrorAlert={showErrorAlert} apiErrorMsg={apiErrorMsg} />
        <>
          <header className="modal-card-head">
            <p className="modal-card-title fs_20 fw_600">Column Header Mapper</p>
            {props.name === "Own Documents" && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.specificConfig}
                      disabled={props.disableSpecificConfigCheckbox}
                      onChange={() => {
                        props.setSpecificConfig(!props.specificConfig);
                      }}
                    />
                  }
                  label="Apply only to selected business partner"
                />
              </FormGroup>
            )}
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                closeConfigModal();
              }}
            />
          </header>
          <section className="modal-card-body">
            <div>
              {isExpanded ? (
                <span>
                  Map your ledger file’s column names with column names given below. Click on{" "}
                  <span style={{ verticalAlign: "middle" }}>
                    <InfoIcon color="primary" />
                  </span>{" "}
                  button to view the description of the columns. You can also transform the values of any column for
                  matching/ doc type identification using{" "}
                  <span style={{ verticalAlign: "middle" }}>
                    <SettingsIcon style={{ color: "gray" }} />
                  </span>
                  .{" "}
                </span>
              ) : (
                <span>
                  Map your ledger file’s column names with column names given below. Click on{" "}
                  <span style={{ verticalAlign: "middle" }}>
                    <InfoIcon color="primary" />
                  </span>{" "}
                  button to view...{" "}
                </span>
              )}
              <span onClick={toggleExpand} className="read_text">
                {isExpanded ? "  read less" : "  read more"}
              </span>
            </div>
            <div className="container">
              <div className="column" style={{ paddingLeft: "0" }}>
                {actor.actorType === "Admin" ? (
                  <div className="field">
                    {props.template && (
                      <div className="temp_data_row">
                        {props.newconfig ? (
                          ""
                        ) : (
                          <div className="config_data">
                            <FormControl sx={{ minWidth: "300px" }} className="closingblc_input">
                              <label>
                                <b>ERP Templates</b>
                              </label>
                              <select
                                name="configfile"
                                className="select_ui"
                                id="configfile"
                                onChange={(e) => changeTemplate(e)}
                              >
                                <option key={"select"} value="0" selected={true}>
                                  Select Template
                                </option>{" "}
                                {props.configList.map((element: any) => (
                                  <option value={element.templateId} key={element.templateId}>
                                    {element.templateName}
                                  </option>
                                ))}
                              </select>
                            </FormControl>
                          </div>
                        )}
                        <div className="temp_name">
                          <label>
                            <b>Configuration Name</b>
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="templateName"
                              value={templateName || ""}
                              onChange={onChangeTemplateName}
                            />
                            <label id="templateNameError" style={{ color: "red" }} />
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "22px" }}>{props.heading}</label>
                    </div>
                  </div>
                ) : (
                  <div className="vertical_center_align gap_10">
                    <label>
                      <b>Template selected :</b>
                    </label>
                    <FormControl>
                      <Select
                        labelId="my-select-label"
                        id="my-select"
                        value={selectedTemplateName}
                        onChange={handleChangeSelectTemplate}
                        size="small"
                        className="mw_250"
                      >
                        {props.configList?.map((option: any) => (
                          <MenuItem key={option.templateId} value={option.templateName}>
                            {option.templateName}
                          </MenuItem>
                        ))}
                        {props.showCustomOption && (
                          <MenuItem
                            // onClick={() => setChangedTemplateName('Customized')}
                            key="Custom-template"
                            value={Customized}
                            // style={{border: selectedTemplateName === Customized ?'1px solid':''}}
                          >
                            {Customized}
                          </MenuItem>
                        )}
                        <MenuItem
                          // onClick={() => setChangedTemplateName('Customized')}
                          key="Remove-template"
                          value="Remove existing template"
                          style={{ border: "1px solid", color: "red" }}
                        >
                          Remove existing template
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="columns is-mobile">
                <div className="column ">
                  {headerName &&
                    headerName.map((element: Element, index: number) => {
                      return (
                        <>
                          <Grid key={index}>
                            <Grid className="theme_bg d_flex mt_15 br_5" xs={12} sm={12} md={12}>
                              <Grid className="b label_head" xs={4} sm={4} md={4}>
                                {/* <div className="control"> */}
                                <h3 className="fw_900">{labels[index]}</h3>
                                {/* </div> */}
                              </Grid>
                              <Grid className="c space_between vertical_center_align" xs={8} sm={8} md={8}>
                                <div className="vertical_center_align">
                                  <button
                                    className="config_add_btn"
                                    onClick={() => {
                                      setAddElementIndex(index);
                                      setSaveValueColumnHeader([]);
                                      setOpenAddDialog(true);
                                      setDuplicateTagName("");
                                    }}
                                  >
                                    Add +
                                  </button>
                                  <span
                                    style={{ verticalAlign: "middle" }}
                                    className="ml_10 cursor_pointer vertical_center_align"
                                  >
                                    <Tooltip title={columnHeaderDescription[index]}>
                                      <InfoIcon color="primary" />
                                    </Tooltip>
                                  </span>
                                  {(element?.name === "debitAmount" || element?.name === "creditAmount") && (
                                    <>
                                      <span>
                                        <Checkbox
                                          checked={
                                            element?.name === "debitAmount"
                                              ? props.debitAmountSignReverseFlag
                                              : element?.name === "creditAmount"
                                              ? props.creditAmountSignReverseFlag
                                              : null
                                          }
                                          onChange={(e) => {
                                            handleChangeReverseSign(e, element);
                                          }}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          sx={{
                                            color: "white",
                                          }}
                                        />
                                      </span>
                                      <span className="fw_600" style={{ color: "white" }}>
                                        Reverse Sign
                                      </span>
                                    </>
                                  )}
                                </div>
                                <span className="mr_10">
                                  {props.showSettingsIcon && (
                                    <Tooltip title="Sanitizer Rules">
                                      <span
                                        className="sanitizerButton"
                                        id={index === 0 ? "docId" : ""}
                                        onClick={() => {
                                          clarity.setTag(
                                            `${props.name === "Own Documents" ? "Own" : "BP"} Sanitizer`,
                                            `Open`
                                          );
                                          setOpen(true);
                                          setColumnName(element.name);
                                          setLabelName(element.name);
                                          props.uiLogger(
                                            props.name === "Own Documents"
                                              ? "ui-OwnSanitizerOpen"
                                              : "ui-BpSanitizerOpen"
                                          );
                                        }}
                                      >
                                        <SettingsIcon
                                          color="primary"
                                          className={
                                            checkColumnToSanitize(sanitizeCol, element?.name)
                                              ? "withSanitizer"
                                              : "withoutSanitizer"
                                          }
                                          sx={{ mt: "8px" }}
                                        />
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                              </Grid>
                            </Grid>
                            {element.value.length > 0 && element.value[0] !== "" ? (
                              <div className="config_tags_div ">
                                {element.value.map((item, itemInd) => {
                                  return (
                                    <button className="config_tags" key={itemInd}>
                                      {typeof item === "string" && <span>{item}</span>}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          delRow(element, itemInd, index);
                                        }}
                                      />
                                    </button>
                                  );
                                })}
                              </div>
                            ) : null}
                            {columnName === element.name && <SanitizerRules />}
                          </Grid>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="mt_30">
              <p className="note_config">
                {" "}
                Note : “The column mappings are done left to right, top to bottom on the inputs given”.
              </p>
            </div>
          </section>
          <footer className="modal-card-foot flex_end">
            <div>
              <Button className="button is-success theme_btn" onClick={() => onNextClickError()}>
                Next
              </Button>
            </div>
          </footer>
          <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(true)}>
            <header className="modal-card-head">
              <p className="modal-card-title">Select column name</p>
              <button className="delete" aria-label="close" onClick={() => setOpenAddDialog(false)} />
            </header>
            <section className="modal-card-body">
              {duplicateTagName ? (
                <div className="mb_15 error_text_config">Some of the tags are already mapped in {duplicateTagName}</div>
              ) : (
                ""
              )}
              <Grid>
                <div className="mb_15">
                  <p className="vertical_center_align">
                    <span className="mr_10">
                      <InfoIcon color="primary" />
                    </span>
                    {columnHeaderDescription[addElementIndex]}
                  </p>
                </div>
              </Grid>
              <Grid>
                {props.integration && tallyHeaderNames?.length ? (
                  <>
                    <FormControl fullWidth={true}>
                      <InputLabel id="select-header-label">Select Column Header</InputLabel>
                      <Select
                        labelId="select-header-label"
                        id="select-header"
                        value={saveValueColumnHeader}
                        label="Select Column Header"
                        multiple={false}
                        onChange={(e) => {
                          setSaveValueColumnHeader([e.target.value as string]);
                        }}
                        sx={{
                          width: "100%",
                          "& span": {
                            fontSize: "inherit",
                          },
                        }}
                      >
                        {tallyHeaderNames.map((header) => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <Autocomplete
                    onChange={(name, value) => {
                      console.log(name);
                      if (value.length > 0) {
                        if (value[value.length - 1].includes("Add a new column name => ")) {
                          value[value.length - 1] = value[value.length - 1].replace('Add a new column name => "', "");
                          value[value.length - 1] = value[value.length - 1].replace('"', "");
                          const removeEmptyVal = value.filter((el) => {
                            return el !== "";
                          });
                          value = removeEmptyVal;
                        }
                      }
                      setSaveValueColumnHeader(value);
                    }}
                    noOptionsText={"Add a new column name"}
                    multiple={true}
                    id="tags-outlined"
                    options={options}
                    getOptionLabel={(option) => option}
                    // defaultValue={[options[13]]}
                    filterSelectedOptions={true}
                    filterOptions={(optionsFilter, params) => {
                      console.log(optionsFilter);
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.push(`Add a new column name => "${params.inputValue.trim()}"`);
                      }
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter a new column name"
                        placeholder="Enter a new column name"
                        autoFocus={true}
                      />
                    )}
                    sx={{
                      "& span": {
                        fontSize: "inherit",
                      },
                    }}
                  />
                )}
              </Grid>
            </section>
            <footer className="modal-card-foot right_align">
              <div>
                <Button
                  className="button is-success"
                  onClick={() => {
                    const { isDuplicate, DuplicateTagName } = checkIsDuplicateHeader(
                      saveValueColumnHeader,
                      headerName[addElementIndex]
                    );

                    if (isDuplicate) {
                      setDuplicateTagName(DuplicateTagName);
                    } else {
                      if (saveValueColumnHeader.length > 0) {
                        headerName[addElementIndex].value =
                          headerName[addElementIndex].value.concat(saveValueColumnHeader);
                        if (actor.actorType !== "Admin") {
                          setSelectedTemplateName(Customized);
                          setTemplateName(Customized);
                        }
                      }
                      if (headerName[addElementIndex].value.length > 1) {
                        const removeEmptyVal = headerName[addElementIndex].value.filter((el) => {
                          return el !== "";
                        });
                        headerName[addElementIndex].value = removeEmptyVal;
                      }
                      setOpenAddDialog(false);
                      setSaveValueColumnHeader([]);
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </footer>
          </Dialog>
        </>
      </>
    </ConfigPopupAnchorRecon360Context.Provider>
  );
};

export default ColumnHeaderConfig;
