import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import { gapAccountedAsName } from "../../../Utils/Recon/Recon360/Constants";
import { ManualReconContext } from "./ManualRecon";

const ReconcileConfirmWarningModal = () => {
  const {
    isOpenReconcileConfirmWarningModal,
    setIsOpenReconcileConfirmWarningModal,
    reconcileEntries,
    absoluteReconcileTotalSum,
    selectedRowOwn,
    selectedRowBp,
    amountTolerance,
    showLoadingIconReconcile,
    gapAccountedName,
  } = useContext(ManualReconContext);

  return (
    <div>
      <Dialog open={isOpenReconcileConfirmWarningModal} aria-labelledby="reconcileConfirmWarningModal">
        <DialogTitle>Where do you want to put the Gap?</DialogTitle>
        <DialogContent />
        <DialogActions className="dialog_footer">
          <div className="space_between">
            {selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 && (
              <Button
                variant="contained"
                className="theme_outline_btn"
                disabled={showLoadingIconReconcile}
                onClick={() => {
                  reconcileEntries(gapAccountedAsName.tds);
                }}
                sx={{ marginRight: 1 }}
              >
                {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.tds ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                TDS
              </Button>
            )}
            {absoluteReconcileTotalSum < amountTolerance && (
              <Button
                variant="contained"
                disabled={showLoadingIconReconcile}
                onClick={() => {
                  reconcileEntries(gapAccountedAsName.roundingError);
                }}
                className="theme_outline_btn mr_10"
              >
                {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.roundingError ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                Rounding Off
              </Button>
            )}
            {absoluteReconcileTotalSum > amountTolerance && selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 && (
              <Button
                variant="contained"
                disabled={showLoadingIconReconcile}
                onClick={() => {
                  reconcileEntries(gapAccountedAsName.mismatchOthers);
                }}
                className="theme_outline_btn mr_10"
              >
                {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.mismatchOthers ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                Amount-Mismatch
              </Button>
            )}
            <Button
              variant="outlined"
              disabled={showLoadingIconReconcile}
              onClick={() => {
                setIsOpenReconcileConfirmWarningModal(false);
              }}
              className="theme_outline_btn mr_10"
            >
              close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReconcileConfirmWarningModal;
