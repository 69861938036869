import { ValueFormatterParams } from "ag-grid-community";
import moment from "moment";

/** This is a column definition of the grid on Anchor page */
export function formatDate(dateString: string | any) {
  console.log("DATE FORMAT", dateString);
  if (dateString.result) {
    console.log("here1");
    return moment(dateString.result).format("DD/MM/YYYY");
  }
  console.log("here2");
  return moment(dateString).format("DD/MM/YYYY");
}
export function formatDateToTime(dateString: string | Date) {
  return moment(dateString).format("DD/MM/YYYY HH:mm:ss");
}

// /**
//  * return full name of the user
//  * @param   {string} firstName  First Name of the User
//  * @param   {string} lastName   Last Name of the User
//  * @return  {string}            Fullname of the user
//  */

export function dateValueFormatter(params: ValueFormatterParams) {
  return formatDate(params.value);
}
