import JoditEditor, { Jodit } from "jodit-react";
import React, { useMemo, useRef } from "react";
import { EmailTemplate } from "../../../entity/recon-entity/ReconInterfaces";
import "./Editor.scss";

interface JoditConfig extends Partial<Jodit["options"]> {
  placeholder: string;
  toolbarAdaptive: boolean;
}

interface EditorProps {
  selectedEmailTemplateContent: EmailTemplate;
  selectedTemplateName: string;
  dynamicContent?: string;
  setDynamicContent: React.Dispatch<React.SetStateAction<string>>;
}

const Editor = (props: EditorProps) => {
  const editor = useRef<Jodit>(null);
  const config: Partial<Jodit["options"]> = {
    placeholder: "Enter...",
    toolbarAdaptive: false,
    toolbarButtonSize: "small",
    // height: 300,
    containerStyle: {
      editorStyle: {
        "max-height": "300px",
      },
    },
  } as JoditConfig as Jodit["options"];

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={
          props.selectedEmailTemplateContent !== null && props.selectedEmailTemplateContent !== undefined
            ? props.selectedEmailTemplateContent.emailBody
            : ""
        }
        config={config}
        onChange={(content) => {
          props.setDynamicContent(content);
        }}
      />
    ),
    // eslint-disable-next-line
    [props.selectedTemplateName]
  );
};

export default Editor;
