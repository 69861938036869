import { Autocomplete, Button, Card, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import Table from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import "./Admin.scss";

export interface ListTallyVoucherResponse {
  data: TallyVocherData[];
  message: string;
  totalVouchers: number;
}

export interface ListTallyLedgerByAdminResponse {
  name: string;
  totalLedgers: number;
  ledgerIds: LedgerIdsInterface[];
  message: string;
}

export interface LedgerIdsInterface {
  ledgerId: string;
}

export interface TallyVocherData {
  itemId: string;
  ownId: number;
  businessPartnerId: string;
  documentNumber: string;
  documentType: string;
  documentDate: Date;
  reference: string;
  amount: number;
  postingDate: Date;
  voucherData?: object | string;
  createdAt?: Date;
  updatedAt?: Date;
}

const TallyVoucher = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [username, setUsername] = useState<string>(null);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [tallyLedgerData, setTallyLedgerData] = useState<any[]>([]);
  const [tallyCompanies, setTallyCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedLedgerId, setSelectedLedgerId] = useState<any>(null);
  const [totalVouchers, setTotalVouchers] = useState<number>(null);

  const columnDefinition = React.useMemo(
    () => [
      {
        Header: "OwnId",
        accessor: "ownId",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "Ledger Id",
        accessor: "ledgerId",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
      {
        Header: "Voucher Type",
        accessor: "voucherType",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Ledger Type",
        accessor: "ledgerType",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Voucher Number",
        accessor: "voucherNumber",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "BillAllocation Name",
        accessor: "billAllocationName",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Effective Date",
        accessor: "effectiveDate",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Reference Date",
        accessor: "referenceDate",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Date",
        accessor: "date",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Debit Amount",
        accessor: "debitAmount",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Credit Amount",
        accessor: "creditAmount",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Reference",
        accessor: "reference",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Narration",
        accessor: "narration",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
      {
        Header: "CompanyId",
        accessor: "companyId",
        Sort: true,
        minWidth: 400,
        maxWidth: 400,
        width: 400,
      },
      {
        Header: "guId",
        accessor: "guId",
        Sort: true,
        minWidth: 480,
        maxWidth: 480,
        width: 480,
      },
    ],
    []
  );

  const downloadTallyVoucherData = () => {
    try {
      axios
        .get<ListTallyVoucherResponse>("/api/ListTallyVocuher", {
          params: {
            username,
            companyId: selectedCompany.companyId,
            ledgerId: selectedLedgerId.ledgerId,
          },
        })
        .then((response) => {
          console.log("response.data", response.data);
          const worksheet1 = XLSX.utils.json_to_sheet(response.data.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet1, "Tally Voucher data");
          XLSX.writeFile(workbook, `Tally Voucher data.xlsx`);
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error ListTallyVocuher", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyVocuher`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListTallyVocuher", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyVocuher`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const downloadRawTallyVoucherData = () => {
    try {
      axios
        .get<ListTallyVoucherResponse>("/api/ListTallyRawVoucherByAdmin", {
          params: {
            username,
          },
        })
        .then((response) => {
          console.log("response.data", response.data);
          const worksheet1 = XLSX.utils.json_to_sheet(response.data.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet1, "Raw Tally Voucher data");
          XLSX.writeFile(workbook, `Raw Tally Voucher data.xlsx`);
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error ListTallyRawVoucherByAdmin", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyRawVoucherByAdmin`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListTallyRawVoucherByAdmin", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyRawVoucherByAdmin`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getTallyVoucher = (id: any) => {
    try {
      axios
        .get<ListTallyVoucherResponse>("/api/ListTallyVocuher", {
          params: {
            username,
            companyId: selectedCompany.companyId,
            ledgerId: id,
          },
        })
        .then((response) => {
          console.log("response.data", response.data);
          setRowsData(response.data.data);
          setTotalVouchers(response.data?.totalVouchers);
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error ListTallyVocuher", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyVocuher`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListTallyVocuher", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyVocuher`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getTallyCompanyByAdmin = () => {
    try {
      axios
        .get<any>("/api/ListTallyCompanyByAdmin", {
          params: {
            username,
          },
        })
        .then((response) => {
          console.log("response.data", response.data);
          setTallyCompanies(response.data.tallyCompanies);
        })
        .catch((error) => {
          console.log("error ListTallyCompanyByAdmin", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyCompanyByAdmin`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListTallyCompanyByAdmin", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyCompanyByAdmin`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const optionsListTallyCompanyByAdmin = tallyCompanies?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const optionsListTallyBusinessPartner = tallyLedgerData?.map((option: any) => {
    let firstLetter = "";

    if (option.businessPartnerName) firstLetter = option?.businessPartnerName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const getTallyLedger = (id: any) => {
    try {
      axios
        .get<ListTallyLedgerByAdminResponse>("/api/ListTallyLedgerByAdmin", {
          params: {
            username,
            companyId: id,
          },
        })
        .then((response) => {
          console.log("response.data", response.data);
          setTallyLedgerData(response.data.ledgerIds);
        })
        .catch((error) => {
          console.log("error ListTallyLedgerByAdmin", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListTallyLedgerByAdmin`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListTallyLedgerByAdmin", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListTallyLedgerByAdmin`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const companyNameSelect = (e: any, option: any) => {
    setSelectedCompany(option);
    if (option !== null) {
      e.persist();
      getTallyLedger(option.companyId);
    } else if (option === null) {
      setRowsData([]);
    }
  };
  const tallyBusinessPartnerSelect = (e: any, option: any) => {
    setSelectedLedgerId(option);
    if (option !== null) {
      e.persist();
      getTallyVoucher(option.ledgerId);
    } else if (option === null) {
      setRowsData([]);
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Tally Voucher")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid className="pb_40">
        <Grid className="center_align">
          <Card className="center_align tallyVoucher_card">
            <div>
              <div className="vertical_center_align">
                <TextField
                  id="outlined-number"
                  label="User Name"
                  value={username}
                  onChange={(e: any) => setUsername(e.target.value)}
                />
                <Button
                  variant="contained"
                  className="theme_btn tallyVoucher_submit_btn"
                  onClick={() => getTallyCompanyByAdmin()}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  className="theme_btn tallyVoucher_submit_btn"
                  onClick={() => downloadTallyVoucherData()}
                >
                  Download
                </Button>
                <Button
                  variant="contained"
                  className="theme_btn"
                  style={{ marginLeft: "20px" }}
                  onClick={() => downloadRawTallyVoucherData()}
                >
                  Raw Voucher Data Download
                </Button>
              </div>
              <div>
                {tallyCompanies.length > 0 && (
                  <div className="d_flex">
                    <div className="mt_20 w_50_per">
                      <div className="center_align_ver_horiz h_40 text_center">
                        <label>Select Tally Company</label>
                      </div>
                      <Autocomplete
                        className="dropdown_Tally"
                        onChange={(e, value) => companyNameSelect(e, value)}
                        id="tallyCompany"
                        options={optionsListTallyCompanyByAdmin.sort(
                          (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option: any) => option.firstLetter}
                        getOptionLabel={(option: any) => option.companyName}
                        renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                      />
                    </div>
                    {tallyLedgerData.length > 0 && (
                      <div className="mt_20 w_50_per">
                        <div className="center_align_ver_horiz h_40 text_center">
                          <label>Select Business Partner</label>
                        </div>
                        <Autocomplete
                          className="dropdown_Tally"
                          onChange={(e, value) => tallyBusinessPartnerSelect(e, value)}
                          id="businessPartner"
                          options={optionsListTallyBusinessPartner.sort(
                            (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option: any) => option.firstLetter}
                          getOptionLabel={(option: any) => option.businessPartnerName}
                          renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Grid>
        <Grid>{totalVouchers && <div className="center_align mt_30">Total Vouchers : {totalVouchers}</div>}</Grid>

        {rowsData.length > 0 ? (
          <Grid>
            <Grid className="center_align tallyVoucher_table">
              <Table columns={columnDefinition} rows={rowsData} sortEnable={true} showPagination={true} />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </LoggedInSkeleton>
  );
};

export default TallyVoucher;
