export const labels: string[] = [
  "Document Identifier",
  "Date",
  "Posting Date",
  "Invoice Number",
  "Other Unique ID",
  "Debit Amount",
  "Credit Amount",
  "Payment ID",
  "Particulars/ Narration",
  "DN/CN ID",
  "Inv ID for DN/CN",
  "Document Number",
  "Clearing Document",
  "Clearing Date",
];
export const columnHeaderDescription = [
  "Name of the column which contains the document Or voucher type.",
  "Name of the column which contains date of Invoice/ payment/ debit note etc.",
  "Name of the column which contains date on which the entry has been posted in the ERP.  If not present, kindly leave it blank.",
  "Name of the column containing invoice numbers",
  "Name of the column having ID generated by the ERP software or any other ID like Assignment Number. If not present, kindly leave it blank.",
  "Name of the column containing entries which are debited",
  "Name of the column containing entries which are credited",
  "Name of the column containing Payment IDs. If not present, kindly leave it blank.",
  "Name of the column containing particular/narration information of the entry.",
  "Unique Identifier to match DN/CN entries, if you maintain it in “invoice no.” column then add the same field here",
  "Applicable only for DNCN entries Column Name which contains reference to the Invoice on which the DNCN has been raised.",
  "Internal reference number, which shall be used for clubbing Invoices, Payments, DNCN and then match with entries on BP side.",
  "The column which contains the document no. which has been used to clear the entry.",
  "The Column which contains the date, on which the entry has been closed.",
];
