import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useState } from "react";
import { DocumentTypeConfigContext } from "./DocumentTypeConfig";

const DeleteDocTypeSubGrp = () => {
  const { isOpenDeleteDocTypeSubGrp, setIsOpenDeleteDocTypeSubGrp, editGroupEle, documentTypeRow, setDocumentTypeRow } =
    useContext(DocumentTypeConfigContext);
  const [showDeleteLoadingIcon, setShowDeleteLoadingIcon] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteDocTypeSubGrp(false);
  };
  const handleDelete = () => {
    setShowDeleteLoadingIcon(true);
    // Use the filter method to remove the editGroupEle object from documentTypeRow
    const updatedDocumentTypeRow = documentTypeRow.filter((item) => {
      return item.name !== editGroupEle.name || item.group?.groupId !== editGroupEle.group?.groupId;
    });
    setDocumentTypeRow(updatedDocumentTypeRow);
    setShowDeleteLoadingIcon(false);
    handleCloseDeleteModal();
  };
  return (
    <div>
      <Dialog open={isOpenDeleteDocTypeSubGrp} aria-labelledby="DeleteDocTypeSubGrpModal">
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent />
        <DialogActions className="dialog_footer space_between">
          <div className="space_between w_100_per">
            <div>
              <Button variant="outlined" onClick={handleCloseDeleteModal} className="theme_outline_btn mr_10">
                Cancel
              </Button>
            </div>
            <div>
              <Button variant="contained" className="theme_btn" onClick={handleDelete} sx={{ marginRight: 1 }}>
                {showDeleteLoadingIcon ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                Delete
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDocTypeSubGrp;
