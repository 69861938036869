import React, { useEffect, useState } from "react";
function SubRows({ row, rowProps, data }: any) {
  return (
    <>
      {data.map((x: any, i: any) => {
        return (
          <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
            {row.cells.map((cell: any, index: any) => {
              return (
                <td
                  {...cell.getCellProps()}
                  key={index}
                  className={cell.column.Header === "See details" ? "openBtnPadding" : ""}
                >
                  {cell.render(cell.column.SubCell ? "SubCell" : "Cell", {
                    value: cell.column.accessor && cell.column.accessor(x, i),
                    row: { ...row, original: x },
                  })}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

const CollapseSubRowForSettings = ({ row, rowProps, visibleColumns }: any) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (row.id === "1") {
      setData([
        {
          line_item: <p className="table_subrow">Amount Mismatch</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Invoice not present in Partner Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Invoice not present in Own Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }

    if (row.id === "2") {
      setData([
        {
          line_item: <p className="table_subrow">Amount Mismatch</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Payment not present in Partner Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Payment not present in Own Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }

    if (row.id === "3") {
      setData([
        {
          line_item: <p className="table_subrow">Amount Mismatch</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">DN/CN not present in Partner Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">DN/CN not present in Own Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }
    if (row.id === "4") {
      setData([
        {
          line_item: <p className="table_subrow">TDS/TCS not present in Partner Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">TDS/TCS not present in Own Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Suggested TDS gap in invoice/payment/DN/CN value</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }
    if (row.id === "5") {
      setData([
        {
          line_item: <p className="table_subrow">In Own Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">In Partner Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }
    if (row.id === "8") {
      setData([
        {
          line_item: <p className="table_subrow">Opening balance in Own Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Opening balance in Partner Ledger</p>,
          sub_total: "",
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.id]);

  return <SubRows row={row} rowProps={rowProps} visibleColumns={visibleColumns} data={data} loading={loading} />;
};

export default CollapseSubRowForSettings;
