export type BankTopBarType = "Disbursement" | "Collection" | "Settings" | "None";
export type Tier2TopBarType =
  | "Early Payment"
  | "Consolidated View"
  | "Ledger Adjustment"
  | "Audit Trail"
  | "Settings"
  | "Upload Invoice"
  | "Account"
  | "Rejected Invoices"
  | "Recon360"
  | "Supplier Chain Dashboard"
  | "Recon Dashboard"
  | "Partner Communication"
  | "Recon Settings";
export type Tier1TopBarType =
  | "Invoice Approval"
  | "Entry Adjustment"
  | "Audit Trail"
  | "Upload"
  | "Settings"
  | "Recon360"
  | "Supplier Chain Dashboard"
  | "Recon Dashboard"
  | "Partner Communication"
  | "Recon Settings";
export type AnchorTopBarType =
  | "Upload"
  | "Anchor Payables"
  | "Early Payment"
  | "Anchor Landing"
  | "Anchor Adjustment"
  | "Settings"
  | "Recon360"
  | "Payment Advice"
  | "Ledger"
  | "Leadership Dashboard"
  | "Supplier Chain Dashboard"
  | "Recon Dashboard"
  | "Partner Communication"
  | "Recon Settings";
export type AtherTestTopBarType =
  // | "Upload"
  // | "Anchor Payables"
  // | "Early Payment"
  // | "Anchor Landing"
  // | "Anchor Adjustment"
  // | "Settings"
  "Recon360";
// | "Leadership Dashboard";
export type AdminTopBarType =
  | "Userlist"
  | "Recon Mapping"
  | "Sap Data"
  | "Ledger Data"
  | "Disbursment Account"
  | "Collection Account"
  | "Config Templates"
  | "Tally Voucher"
  | "Tally Ledger"
  | "CB Update"
  | "Unpaid Invoice"
  | "Payment Details"
  | "Register";

export type ReconTopBarType =
  | "Recon360"
  | "Recon MIS"
  | "Process Management"
  | "AP Recon Insights"
  | "AR Recon Insights"
  | "Payment Advice"
  | "Ledger"
  | "Recon Dashboard"
  | "Supplier Chain Dashboard"
  | "Partner Communication"
  | "Partner Master"
  | "Recon Settings"
  // tier1
  | "Invoice Approval"
  | "Entry Adjustment"
  | "Audit Trail"
  | "Upload"
  | "Settings"
  | "Recon360"
  | "Supplier Chain Dashboard"
  | "Recon Dashboard"
  | "Partner Communication"
  | "Recon Settings";

export const BankTopBar = {
  Disbursement: "Disbursement",
  Collection: "Collection",
  Settings: "Settings",
  None: "None",
};

export const Tier2TopBar = {
  EarlyPayment: "Early Payment",
  // ConsolidatedView: "Consolidated View",
  LedgerAdjustment: "Ledger Adjustment",
  AuditTrail: "Audit Trail",
  Settings: "Settings",
  UploadInvoice: "Upload Invoice",
  Account: "Account",
  RejectedInvoices: "Rejected Invoices",
  Recon360: "Recon360",
  SupplierChainDashboard: "Supplier Chain Dashboard",
  ReconDashboard: "Recon Dashboard",
  ReconSettings: "Recon Settings",
  PartnerCommunication: "Partner Communication",
};

export const Tier1TopBar = {
  InvoiceApproval: "Invoice Approval",
  EntryAdjustment: "Entry Adjustment",
  AuditTrail: "Audit Trail",
  Upload: "Upload",
  Settings: "Settings",
  Recon360: "Recon360",
  SupplierChainDashboard: "Supplier Chain Dashboard",
  ReconDashboard: "Recon Dashboard",
  ReconSettings: "Recon Settings",
  PartnerCommunication: "Partner Communication",
};

export const AnchorTopBar = {
  AnchorLanding: "Anchor Landing",
  EarlyPayment: "Early Payment",
  Upload: "Upload",
  Settings: "Settings",
  Payables: "Anchor Payables",
  Adjustment: "Anchor Adjustment",
  LD: "Leadership Dashboard",
  Recon360: "Recon360",
  PaymentAdvice: "Payment Advice",
  Ledger: "Ledger",
  SupplierChainDashboard: "Supplier Chain Dashboard",
  ReconDashboard: "Recon Dashboard",
  ReconSettings: "Recon Settings",
  PartnerCommunication: "Partner Communication",
};

export const AtherTestTopBar = {
  // AnchorLanding: "Anchor Landing",
  // EarlyPayment: "Early Payment",
  // Upload: "Upload",
  // Settings: "Settings",
  // Payables: "Anchor Payables",
  // Adjustment: "Anchor Adjustment",
  // LD: "Leadership Dashboard",
  Recon360: "Recon360",
};
export const AdminTopBar = {
  Userlist: "Userlist",
  ReconMapping: "Recon Mapping",
  SapData: "Sap Data",
  LedgerData: "Ledger Data",
  DisbursmentAccount: "Disbursment Account",
  CollectionAccount: "Collection Account",
  ConfigTemplates: "Config Templates",
  TallyVoucher: "Tally Voucher",
  TallyLedger: "Tally Ledger",
  CBUpdate: "CB Update",
  UnpaidInvoice: "Unpaid Invoice",
  PaymentDetails: "Payment Details",
  Register: "Register",
};

export const ReconTopBar = {
  Recon360: "Recon360",
  ReconMIS: "Recon MIS",
  ProcessManagement: "Process Management",
  APReconInsights: "AP Recon Insights",
  ARReconInsights: "AR Recon Insights",
  PaymentAdvice: "Payment Advice",
  Ledger: "Ledger",
  ReconDashboard: "Recon Dashboard",
  SupplierChainDashboard: "Supplier Chain Dashboard",
  PartnerCommunication: "Partner Communication",
  LedgerRequest: "Ledger Request",
  BalanceConfirmation: "Balance Confirmation",
  PartnerMaster: "Partner Master",
  ReconSettings: "Recon Settings",
};
