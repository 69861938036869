import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

const StatusResetWarningModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.isOpenResetModal} aria-labelledby="StatusResetWarningModal">
        <DialogTitle>Are you sure you want to reset?</DialogTitle>
        <DialogContent />
        <DialogActions className="dialog_footer">
          <div className="space_between">
            <Button variant="outlined" onClick={props.handleCloseResetModal} className="theme_outline_btn mr_10">
              Cancel
            </Button>
            <Button variant="contained" className="theme_btn" onClick={props.handleReset} sx={{ marginRight: 1 }}>
              {props.showLoadingIcon ? (
                <span className="file-icon">
                  <i className="fas fa-circle-notch fa-spin" />
                </span>
              ) : null}
              Reset
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusResetWarningModal;
