import { FilterList, FilterListOff, PersonAdd, Upload } from "@mui/icons-material";
// import CheckIcon from "@mui/icons-material/Check";
// import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Chip,
  // createFilterOptions,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import Axios, { AxiosError } from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import {
  ErrorResponse,
  ListOwnTallyCompanies,
  ListOwnTallyCompaniesRes,
} from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import AddNewBusinessPartner from "../NewBusinessPartner/AddNewBusinessPartner";
import EditBusinessPartner from "../ReconDashboard/EditBusinessPartner";
import AddNewPartnerUser from "./AddNewPartnerUser";
import ListBusinessPartnerUsers from "./ListBusinessPartnerUsers";
import PartnerUseCaseMasterFilter from "./PartnerUseCaseMasterFilter";
import PartnerUserBulkUploadErrorReport from "./PartnerUserBulkUploadErrorReport";
import PartnerUserMasterFilter from "./PartnerUserMasterFilter";

// const filter = createFilterOptions<any>();

// interface ApplicableTds {
//   value: number;
// }

type ColDef = ColumnDef<any> & {
  sticky?: "left" | "right";
};

const PartnerMaster = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  // const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const optionsPartnerMaster = ["Partner use case master", "Partner user master"];
  const [partnerMasterSelected, setPartnerMasterSelected] = useState<string>("Partner use case master");
  const partnerMasterSelectedValue = useRef<string>("Partner use case master");
  const [openPartnerUseCaseMasterFilter, setOpenPartnerUseCaseMasterFilter] = useState<boolean>(false);
  const [openPartnerUserMasterFilter, setOpenPartnerUserMasterFilter] = useState<boolean>(false);
  const [isFilterAppliedPartnerUseCaseMaster, setIsFilterAppliedPartnerUseCaseMaster] = useState<boolean>(false);
  const [isFilterAppliedPartnerUserMaster, setIsFilterAppliedPartnerUserMaster] = useState<boolean>(false);
  const [rowsDataPartnerUseCaseMaster, setRowsDataPartnerUseCaseMaster] = useState<any[]>([]);
  const [storeRowsDataPartnerUseCaseMaster, setStoreRowsDataPartnerUseCaseMaster] = useState<any[]>([]);
  const [rowsDataPartnerUserMaster, setRowsDataPartnerUserMaster] = useState<any[]>([]);
  const [storeRowsDataPartnerUserMaster, setStoreRowsDataPartnerUserMaster] = useState<any[]>([]);
  const storeAllCategories = useRef<any>(null);
  const storePartnerUseCaseMasterData = useRef<any[]>([]);
  const storePartnerUserMasterData = useRef<any[]>([]);
  // const [tempLoader, setTempLoader] = useState<boolean>(false);
  const [partnerUsersTemplateBase64, setPartnerUsersTemplateBase64] = useState<string>(null);
  const [openPartnerUserBulkUploadErrorReport, setOpenPartnerUserBulkUploadErrorReport] = useState<boolean>(false);
  const [rowsDataPartnerUserBulkUploadErrorReport, setRowsDataPartnerUserBulkUploadErrorReport] = useState<any[]>([]);
  const [numberOfUsersFailed, setNumberOfUsersFailed] = useState<number>(null);
  const [numberOfUsersSaved, setNumberOfUsersSaved] = useState<number>(null);
  const [errorListsPartnerUsersBase64, setErrorListsPartnerUsersBase64] = useState<string>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [storePartnerUserNameValue, setStorePartnerUserNameValue] = useState<any>(null);
  const storePartnerUserNameValueRef = useRef<any>(null);
  const storePartnerUserNameValueFlag = useRef<boolean>(false);
  const [storePartnerNameValue, setStorePartnerNameValue] = useState<any>(null);
  const storePartnerNameValueRef = useRef<any>(null);
  const storePartnerIdValueRef = useRef<any>(null);
  const storePartnerNameValueFlag = useRef<boolean>(false);
  const [storeEmailValue, setStoreEmailValue] = useState<any>(null);
  const storeEmailValueRef = useRef<any>(null);
  const storeEmailValueFlag = useRef<boolean>(false);
  // const [storeDesignationValue, setStoreDesignationValue] = useState<any>(null);
  const storeDesignationValueRef = useRef<any>(null);
  const storeDesignationValueFlag = useRef<boolean>(false);
  // const [storeBalanceConfirmationValue, setStoreBalanceConfirmationValue] = useState<any>(null);
  const storeBalanceConfirmationValueRef = useRef<any>(null);
  const storeBalanceConfirmationValueFlag = useRef<boolean>(false);
  // const [storeLedgerRequestValue, setStoreLedgerRequestValue] = useState<any>(null);
  const storeLedgerRequestValueRef = useRef<any>(null);
  const storeLedgerRequestValueFlag = useRef<boolean>(false);
  // const [storePaymentAdviceValue, setStorePaymentAdviceValue] = useState<any>(null);
  const storePaymentAdviceValueRef = useRef<any>(null);
  const storePaymentAdviceValueFlag = useRef<boolean>(false);
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<any>(null);
  const [listAllDesignation, setListAllDesignation] = useState<any[]>([]);
  const [listOwnTallyCompaniesForRecon, setListOwnTallyCompaniesForRecon] = useState<ListOwnTallyCompanies[]>([]);
  const [lastOwnTallyCompany, setLastOwnTallyCompany] = useState<ListOwnTallyCompanies>(null);
  const companyId = useRef<string>(null);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);
  const [uploadCategoriesInBulkTemplateBase64, setUploadCategoriesInBulkTemplateBase64] = useState<string>(null);
  const [downloadPartnerUseCaseMasterReportBase64, setDownloadPartnerUseCaseMasterReportBase64] =
    useState<string>(null);
  const [downloadPartnerUserMasterReportBase64, setDownloadPartnerUserMasterReportBase64] = useState<string>(null);
  const [appliedUMFilters, setAppliedUMFilters] = useState<{ [key: string]: string }>({});
  const [appliedUCMFilters, setAppliedUCMFilters] = useState<{ [key: string]: string }>({});
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});

  // edit partner
  const [openBusinessPopup, setOpenBusinessPopup] = useState(false);
  const [businessPartnerId, setBusinessPartnerId] = useState<number>(null);
  const anchorEl = useRef(null);
  const [openAddPartnerMenu, setOpenAddPartnerMenu] = useState(false);
  const anchorUploadEl = useRef(null);
  const uploadInput = useRef(null);
  const [openUploadBulkMenu, setOpenUploadBulkMenu] = useState(false);

  const handleClickOpen = (id: any) => {
    setOpenBusinessPopup(true);
    setBusinessPartnerId(id);
  };
  // const formatValuesToPercentage = (values: ApplicableTds[] | string) => {
  //   if (values === null || values === undefined) {
  //     return "";
  //   } else if (Array.isArray(values)) {
  //     const formattedValues = values.map((obj) => `${obj?.value}%`);
  //     const formattedString = formattedValues.join(", ");

  //     return formattedString;
  //   } else return `${values}%`;
  // };

  const columnDefinitionPartnerUseCaseMaster = useMemo(
    (): ColDef[] => [
      {
        header: "Partner Name",
        sticky: "left",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        maxSize: 600,
        minSize: 600,
        size: 600,
        cell: ({ row }: any) => (
          <a
            onClick={() => {
              // // this is for to open user list and edit
              // storeRowOpenListBusinessPartnerUsers.current = row;
              // setOpenListBusinessPartnerUsers(true);

              // this is for to open edit partner
              handleClickOpen(row?.original?.businessPartnerId);
            }}
            style={{ overflowWrap: "break-word" }}
          >
            {row.original.businessPartnerName}
          </a>
        ),
      },
      // {
      //   header: "Relationship",
      //   accessorKey: "relationship",
      //   maxSize: 150,
      //   minSize: 150,
      //   size: 150,
      //   cell: ({ row }: any) => <div>{row.original?.relationship}</div>,
      // },
      // {
      //   header: "Category",
      //   accessorFn: (row: any) => row.category?.[0]?.toLowerCase(),
      //   enableSorting: false,
      //   maxSize: 200,
      //   minSize: 200,
      //   size: 200,
      //   cell: ({ row }: any) => (
      //     <p className="category_ui">
      //       <Autocomplete
      //         limitTags={1}
      //         value={
      //           row.original.category !== null && row.original.category?.length > 0 && row.original.category?.[0] !== ""
      //             ? row.original.category
      //             : []
      //         }
      //         onChange={(name, value) => {
      //           console.log(name);
      //           if (value !== null && value?.length > 0) {
      //             if (value[value.length - 1]?.includes("+Add => ")) {
      //               value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
      //               value[value.length - 1] = value[value.length - 1].replace('"', "");
      //               const removeEmptyVal = value?.filter((el) => {
      //                 return el !== "";
      //               });
      //               value = removeEmptyVal;
      //             }
      //           }
      //           storeAllCategories.current = [...new Set([...storeAllCategories?.current, ...value])];
      //           updateOwnAndBusinessPartnerMappingCategories(row, value);
      //         }}
      //         noOptionsText={"Enter a new category"}
      //         multiple={true}
      //         id="tags-outlined"
      //         options={storeAllCategories?.current}
      //         getOptionLabel={(option: any) => option}
      //         filterSelectedOptions={true}
      //         filterOptions={(optionsFilt, params) => {
      //           console.log(optionsFilt);
      //           const filtered = filter(
      //             storeAllCategories?.current === null ? [] : storeAllCategories?.current,
      //             params
      //           );
      //           // Suggest the creation of a new value
      //           if (params.inputValue !== "") {
      //             filtered.push(`+Add => "${params.inputValue.trim()}"`);
      //           }
      //           return filtered;
      //         }}
      //         renderInput={(params) => (
      //           <TextField
      //             {...params}
      //             // placeholder="Add"
      //           />
      //         )}
      //       />
      //     </p>
      //   ),
      // },
      // {
      //   header: "ERP Software",
      //   accessorKey: "erpSoftware",
      //   maxSize: 150,
      //   minSize: 150,
      //   size: 150,
      //   cell: ({ row }: any) => <div>{row.original?.erpSoftware}</div>,
      // },
      // {
      //   header: "Applicable TDS",
      //   accessorKey: "applicableTds",
      //   maxSize: 200,
      //   minSize: 200,
      //   size: 200,
      //   cell: ({ row }: any) => <div>{formatValuesToPercentage(row.original?.applicableTds)}</div>,
      // },
      {
        header: "# Contacts",
        accessorKey: "numberOfUsers",
        maxSize: 600,
        minSize: 600,
        size: 600,
      },
      // {
      //   header: "Balance Confirmation",
      //   accessorKey: "balanceConfirmation",
      //   maxSize: 240,
      //   minSize: 240,
      //   size: 240,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original?.balanceConfirmation ? (
      //         <span style={{ color: "green" }}>
      //           <CheckIcon />
      //         </span>
      //       ) : (
      //         <span style={{ color: "red" }}>
      //           <ClearIcon />
      //         </span>
      //       )}
      //     </div>
      //   ),
      // },
      // {
      //   header: "Ledger request",
      //   accessorKey: "ledgerRequest",
      //   maxSize: 180,
      //   minSize: 180,
      //   size: 180,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original?.ledgerRequest ? (
      //         <span style={{ color: "green" }}>
      //           <CheckIcon />
      //         </span>
      //       ) : (
      //         <span style={{ color: "red" }}>
      //           <ClearIcon />
      //         </span>
      //       )}
      //     </div>
      //   ),
      // },
      // {
      //   header: "Payment advice",
      //   accessorKey: "paymentAdvice",
      //   maxSize: 200,
      //   minSize: 200,
      //   size: 200,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original?.paymentAdvice ? (
      //         <span style={{ color: "green" }}>
      //           <CheckIcon />
      //         </span>
      //       ) : (
      //         <span style={{ color: "red" }}>
      //           <ClearIcon />
      //         </span>
      //       )}
      //     </div>
      //   ),
      // },
    ],
    // eslint-disable-next-line
    []
  );
  const columnDefinitionPartnerUserMaster = useMemo(
    (): ColumnDef<any>[] => [
      {
        header: "Partner user name",
        accessorFn: (row: any) => row.name?.toLowerCase(),
        maxSize: 300,
        minSize: 300,
        size: 300,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                name="name"
                value={storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name}
                onClick={() => {
                  storePartnerUserNameValueFlag.current = true;
                  storePartnerUserNameValueRef.current = row.original.name;
                  setStorePartnerUserNameValue(row.original.name);
                }}
                onChange={(e) => {
                  setStorePartnerUserNameValue(e.target.value);
                  storePartnerUserNameValueRef.current = e.target.value;
                }}
              />
            ) : (
              <span>{row.original.name}</span>
            )}
          </div>
        ),
      },
      {
        header: "Partner Name",
        accessorFn: (row: any) => row.businessPartnerName?.toLowerCase(),
        maxSize: 300,
        minSize: 300,
        size: 300,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="businessPartnerName"
                value={
                  storePartnerNameValueFlag.current
                    ? storePartnerNameValueRef.current
                    : row.original.businessPartnerName
                }
                onChange={(e) => {
                  storePartnerNameValueFlag.current = true;
                  setStorePartnerNameValue(e.target.value);
                  storePartnerNameValueRef.current = e.target.value;

                  let bpId;
                  storePartnerUseCaseMasterData.current?.forEach((ele: any) => {
                    if (e.target.value === ele.businessPartnerName) {
                      return (bpId = ele.businessPartnerId);
                    }
                  });
                  storePartnerIdValueRef.current = bpId;
                }}
              >
                {storePartnerUseCaseMasterData.current?.map((val: any, valInd: number) => (
                  <MenuItem value={val.businessPartnerName} key={valInd}>
                    {val.businessPartnerName}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <span>{row.original.businessPartnerName}</span>
            )}
          </div>
        ),
      },
      {
        header: "Mail ID",
        accessorFn: (row: any) => row.email?.toLowerCase(),
        maxSize: 300,
        minSize: 300,
        size: 300,
        cell: ({ row }: any) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                disabled={true}
                name="email"
                value={storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email}
                onClick={() => {
                  storeEmailValueFlag.current = true;
                  storeEmailValueRef.current = row.original.email;
                  setStoreEmailValue(row.original.email);
                }}
                onChange={(e) => {
                  setStoreEmailValue(e.target.value);
                  storeEmailValueRef.current = e.target.value;
                }}
              />
            ) : (
              <span>{row.original.email}</span>
            )}
          </div>
        ),
      },
      // {
      //   header: "Designation",
      //   accessorFn: (row: any) => row.designationName?.toLowerCase(),
      //   maxSize: 100,
      //   minSize: 100,
      //   size: 100,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original.isEdit ? (
      //         <TextField
      //           size="small"
      //           variant="outlined"
      //           // fullWidth={true}
      //           // label="Name"
      //           name="designationName"
      //           value={
      //             storeDesignationValueFlag.current ? storeDesignationValueRef.current : row.original.designationName
      //           }
      //           onChange={(e) => {
      //             storeDesignationValueFlag.current = true;
      //             setStoreDesignationValue(e.target.value);
      //             storeDesignationValueRef.current = e.target.value;
      //           }}
      //         />
      //       ) : (
      //         <span>{row.original.designationName}</span>
      //       )}
      //     </div>
      //   ),
      // },

      // {
      //   header: "Balance Confirmation",
      //   accessorKey: "balanceConfirmation",
      //   maxSize: 150,
      //   minSize: 150,
      //   size: 150,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original.isEdit ? (
      //         <Select
      //           size="small"
      //           fullWidth={true}
      //           labelId="demo-simple-select-label"
      //           id="demo-simple-select"
      //           name="balanceConfirmation"
      //           value={
      //             storeBalanceConfirmationValueFlag.current
      //               ? storeBalanceConfirmationValueRef.current
      //               : row.original.balanceConfirmation
      //           }
      //           onChange={(e) => {
      //             storeBalanceConfirmationValueFlag.current = true;
      //             setStoreBalanceConfirmationValue(e.target.value);
      //             storeBalanceConfirmationValueRef.current = e.target.value;
      //           }}
      //         >
      //           <MenuItem value={"NA"}>NA</MenuItem>
      //           <MenuItem value={"to"}>to</MenuItem>
      //           <MenuItem value={"cc"}>cc</MenuItem>
      //           <MenuItem value={"bcc"}>bcc</MenuItem>
      //         </Select>
      //       ) : (
      //         <span>{row.original.balanceConfirmation}</span>
      //       )}
      //     </div>
      //   ),
      // },
      // {
      //   header: "Ledger request",
      //   accessorKey: "ledgerRequest",
      //   maxSize: 100,
      //   minSize: 100,
      //   size: 100,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original.isEdit ? (
      //         <Select
      //           size="small"
      //           fullWidth={true}
      //           labelId="demo-simple-select-label"
      //           id="demo-simple-select"
      //           name="ledgerRequest"
      //           value={
      //             storeLedgerRequestValueFlag.current ? storeLedgerRequestValueRef.current : row.original.ledgerRequest
      //           }
      //           onChange={(e) => {
      //             storeLedgerRequestValueFlag.current = true;
      //             setStoreLedgerRequestValue(e.target.value);
      //             storeLedgerRequestValueRef.current = e.target.value;
      //           }}
      //         >
      //           <MenuItem value={"NA"}>NA</MenuItem>
      //           <MenuItem value={"to"}>to</MenuItem>
      //           <MenuItem value={"cc"}>cc</MenuItem>
      //           <MenuItem value={"bcc"}>bcc</MenuItem>
      //         </Select>
      //       ) : (
      //         <span>{row.original.ledgerRequest}</span>
      //       )}
      //     </div>
      //   ),
      // },
      // {
      //   header: "Payment advice",
      //   accessorKey: "paymentAdvice",
      //   maxSize: 100,
      //   minSize: 100,
      //   size: 100,
      //   cell: ({ row }: any) => (
      //     <div>
      //       {row.original.isEdit ? (
      //         <Select
      //           size="small"
      //           fullWidth={true}
      //           labelId="demo-simple-select-label"
      //           id="demo-simple-select"
      //           name="paymentAdvice"
      //           value={
      //             storePaymentAdviceValueFlag.current ? storePaymentAdviceValueRef.current : row.original.paymentAdvice
      //           }
      //           onChange={(e) => {
      //             storePaymentAdviceValueFlag.current = true;
      //             setStorePaymentAdviceValue(e.target.value);
      //             storePaymentAdviceValueRef.current = e.target.value;
      //           }}
      //         >
      //           <MenuItem value={"NA"}>NA</MenuItem>
      //           <MenuItem value={"to"}>to</MenuItem>
      //           <MenuItem value={"cc"}>cc</MenuItem>
      //           <MenuItem value={"bcc"}>bcc</MenuItem>
      //         </Select>
      //       ) : (
      //         <span>{row.original.paymentAdvice}</span>
      //       )}
      //     </div>
      //   ),
      // },
      {
        header: "Actions",
        columns: [
          {
            header: "",
            id: "edit",
            accessorKey: "businessPartnerId",
            maxSize: 150,
            minSize: 150,
            size: 150,
            cell: ({ row }: any) => (
              <div>
                {row.original.isEdit ? (
                  <span
                    className="cursor_pointer"
                    onClick={() => {
                      let emailSettingErr = false;
                      if (
                        storeLedgerRequestValueRef.current === "NA" &&
                        storeBalanceConfirmationValueRef.current === "NA" &&
                        storePaymentAdviceValueRef.current === "NA" &&
                        storeBalanceConfirmationValueFlag.current &&
                        storeLedgerRequestValueFlag.current &&
                        storePaymentAdviceValueFlag.current
                      ) {
                        emailSettingErr = true;
                      }
                      let partnerNameErr = false;
                      if (storePartnerNameValueFlag.current && storePartnerNameValueRef.current === null) {
                        partnerNameErr = true;
                      }
                      let emailErr = false;
                      if (
                        storeEmailValueFlag.current &&
                        !storeEmailValueRef.current.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
                      ) {
                        emailErr = true;
                      }
                      if (!partnerNameErr && !emailErr && !emailSettingErr) {
                        addNewPartnerUser(row);
                      } else {
                        if (emailErr) {
                          setApiErrorMsg(`please enter valid email`);
                          setShowErrorAlert(true);
                          apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
                        } else if (partnerNameErr) {
                          setApiErrorMsg(`please select business partner`);
                          setShowErrorAlert(true);
                          apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
                        } else if (emailSettingErr) {
                          setApiErrorMsg(`please select Email Setting`);
                          setShowErrorAlert(true);
                          apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
                        }
                      }
                    }}
                  >
                    <SaveIcon />
                  </span>
                ) : (
                  <span
                    className="cursor_pointer"
                    onClick={() => {
                      storePartnerUserMasterData.current?.forEach((ele: any, eleInd: number) => {
                        if (eleInd === row.index) {
                          ele.isEdit = true;
                        } else {
                          ele.isEdit = false;
                        }
                        return;
                      });
                      setRowsDataPartnerUserMaster(storePartnerUserMasterData.current);
                      setStoreRowsDataPartnerUserMaster(storePartnerUserMasterData.current);
                      setToggle(!toggle);
                    }}
                  >
                    <EditIcon />
                  </span>
                )}
              </div>
            ),
          },
          {
            header: "",
            id: "delete",
            accessorKey: "businessPartnerId",
            maxSize: 150,
            minSize: 150,
            size: 150,
            cell: ({ row }: any) => (
              <div
                className="cursor_pointer"
                onClick={() => {
                  //         console.log("row", row);
                  // console.log("storePartnerUserMasterData.current", storePartnerUserMasterData.current);
                  // storePartnerUserMasterData.current.splice(row.index,1)
                  // console.log("storePartnerUserMasterData.current aft", storePartnerUserMasterData.current);

                  // setToggle(!toggle)
                  deleteBusinessPartnerUser(row);
                }}
              >
                <DeleteIcon />
              </div>
            ),
          },
        ],
      },
    ],
    // eslint-disable-next-line
    [toggle]
  );

  useEffect(() => {
    if (actor.integration === true && actor.actorInfo.accountingSoftware === "Tally") {
      getListOwnTallyCompaniesForRecon();
    } else {
      listAllBusinessPartnersForPartnerUseCaseMaster();
      listAllBusinessPartnersForPartnerUserMaster();
      getPartnerUserBulkUploadExcelTemplate();
    }
    getListAllDesignation();
    getCategoriesBulkUploadExcelTemplate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    partnerMasterSelected === "Partner user master"
      ? setAppliedFilters(appliedUMFilters)
      : setAppliedFilters(appliedUCMFilters);
    // eslint-disable-next-line
  }, [partnerMasterSelected, appliedUCMFilters, appliedUMFilters]);

  const listAllBusinessPartnersForPartnerUseCaseMaster = () => {
    try {
      Axios.get<any>("/api/ListAllBusinessPartnersForPartnerMaster", {
        params: {
          companyId: companyId.current,
        },
      })
        .then((response) => {
          storePartnerUseCaseMasterData.current = response.data.businessPartnersList;
          storeAllCategories.current = response.data.businessPartnersList[0]?.allCategories;
          setRowsDataPartnerUseCaseMaster(response.data?.businessPartnersList);
          setStoreRowsDataPartnerUseCaseMaster(response.data?.businessPartnersList);
          setDownloadPartnerUseCaseMasterReportBase64(response.data?.reportWorkbookBase64);
          setOpenPartnerUseCaseMasterFilter(true);
          const buttonElement = document.getElementById("filterPartnerUseCaseMaster_button");
          if (buttonElement) {
            buttonElement.click();
            setOpenPartnerUseCaseMasterFilter(false);
          }
          setOpenAddPartnerMenu(false);
          setLoader(false);
        })
        .catch((error: any) => {
          console.log("error ListAllBusinessPartnersForPartnerMaster", error?.response);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnersForPartnerMaster`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllBusinessPartnersForPartnerMaster", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnersForPartnerMaster`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const listAllBusinessPartnersForPartnerUserMaster = () => {
    try {
      Axios.get<any>("/api/ListAllBusinessPartnerContacts", {
        params: {
          companyId: companyId.current,
        },
      })
        .then((response) => {
          const businessPartnerContactList = response.data.businessPartnerContactList?.map((object: any) => {
            return { ...object, isEdit: false };
          });
          storePartnerUserMasterData.current = businessPartnerContactList;
          setRowsDataPartnerUserMaster(businessPartnerContactList);
          setStoreRowsDataPartnerUserMaster(businessPartnerContactList);
          setDownloadPartnerUserMasterReportBase64(response.data?.reportWorkbookBase64);
          // setLoader(false);
          setOpenPartnerUserMasterFilter(true);
          const buttonElement = document.getElementById("filterPartnerUserMaster_button");
          if (buttonElement) {
            buttonElement.click();
            setOpenPartnerUserMasterFilter(false);
          }
          setOpenAddPartnerMenu(false);
        })
        .catch((error: any) => {
          console.log("error ListAllBusinessPartnerContacts", error?.response);
          //   setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnerContacts`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllBusinessPartnerContacts", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllBusinessPartnerContacts`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  // const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
  //   try {
  //     await Axios.post<any>("/api/UpdateOwnAndBusinessPartnerMappingCategories", {
  //       ownId: actor.id,
  //       businessPartnerId: row.original.businessPartnerId,
  //       category: value,
  //     })
  //       .then((response) => {
  //         console.log("response", response);
  //         getCategoriesBulkUploadExcelTemplate();
  //         setTempLoader(true);
  //         updateCategory(row, value);
  //       })
  //       .catch((error: any) => {
  //         console.log("error UpdateOwnAndBusinessPartnerMappingCategories", error?.response);
  //         if (error?.response?.data?.message !== undefined) {
  //           const dataObj = error.response.data as ErrorResponse;
  //           setShowErrorAlert(true);
  //           setApiErrorMsg(dataObj.message);
  //           apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //         } else {
  //           setShowErrorAlert(true);
  //           setApiErrorMsg(`${defaultErrorMsg}UpdateOwnAndBusinessPartnerMappingCategories`);
  //           apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //         }
  //       });
  //   } catch (error: any) {
  //     console.log("error UpdateOwnAndBusinessPartnerMappingCategories", error?.response);
  //     setShowErrorAlert(true);
  //     setApiErrorMsg(`${defaultErrorMsg}UpdateOwnAndBusinessPartnerMappingCategories`);
  //     apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //   }
  // };

  // const updateCategory = (row: any, value: any) => {
  //   storePartnerUseCaseMasterData.current.find(
  //     (v: any) => v.businessPartnerId === row.original.businessPartnerId
  //   ).category = value;

  //   // storePartnerUserMasterData.current.find(
  //   //   (v: any) => v.businessPartnerId === row.original.businessPartnerId
  //   // ).category = value;

  //   console.log(tempLoader);
  //   setTempLoader(false);
  // };

  const partnerMasterSelect = (e: any, value: any) => {
    if (value !== null) {
      e.persist();
      setPartnerMasterSelected(value);
      partnerMasterSelectedValue.current = value;
    } else {
      setPartnerMasterSelected(null);
      partnerMasterSelectedValue.current = null;
    }
  };

  const getPartnerUserBulkUploadExcelTemplate = () => {
    try {
      Axios.get<{ response: string }>("/api/GetBusinessPartnerUserBulkUploadExcelTemplate", {
        params: {
          companyId: companyId.current,
        },
      })
        .then((response) => {
          setPartnerUsersTemplateBase64(response.data.response);
        })
        .catch((error: AxiosError<ErrorResponse>) => {
          console.log("error GetBusinessPartnerUserBulkUploadExcelTemplate", error?.response);

          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetBusinessPartnerUserBulkUploadExcelTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetBusinessPartnerUserBulkUploadExcelTemplate", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetBusinessPartnerUserBulkUploadExcelTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const addNewPartnerUser = async (row: any) => {
    try {
      await Axios.post<any>("/api/AddBusinessPartnerUserWithEmailSetting", {
        businessPartnerId: storePartnerNameValueFlag.current
          ? storePartnerIdValueRef.current
          : row.original.businessPartnerId,
        contact: {
          name: storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name,
          email: storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email,
          designationName: storeDesignationValueFlag.current
            ? storeDesignationValueRef.current
            : row.original.designationName,
          ledgerRequest: storeLedgerRequestValueFlag.current
            ? storeLedgerRequestValueRef.current
            : row.original.ledgerRequest,
          balanceConfirmation: storeBalanceConfirmationValueFlag.current
            ? storeBalanceConfirmationValueRef.current
            : row.original.balanceConfirmation,
          paymentAdvice: storePaymentAdviceValueFlag.current
            ? storePaymentAdviceValueRef.current
            : row.original.paymentAdvice,
        },
      })
        .then((response) => {
          console.log(
            storePartnerUserNameValue,
            storePartnerNameValue,
            storeEmailValue
            // storeDesignationValue,
            // storeBalanceConfirmationValue,
            // storeLedgerRequestValue,
            // storePaymentAdviceValue
          );

          const partnerUserMasterDataObj = {
            businessPartnerId: storePartnerNameValueFlag.current
              ? storePartnerIdValueRef.current
              : row.original.businessPartnerId,
            businessPartnerName: storePartnerNameValueFlag.current
              ? storePartnerNameValueRef.current
              : row.original.businessPartnerName,
            name: storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name,
            email: storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email,
            designationName: storeDesignationValueFlag.current
              ? storeDesignationValueRef.current
              : row.original.designationName,
            ledgerRequest: storeLedgerRequestValueFlag.current
              ? storeLedgerRequestValueRef.current
              : row.original.ledgerRequest,
            balanceConfirmation: storeBalanceConfirmationValueFlag.current
              ? storeBalanceConfirmationValueRef.current
              : row.original.balanceConfirmation,
            paymentAdvice: storePaymentAdviceValueFlag.current
              ? storePaymentAdviceValueRef.current
              : row.original.paymentAdvice,
            isEdit: false,
          };

          const newPartnerUserList: any = [];
          storePartnerUserMasterData.current?.forEach((ele: any, eleInd: number) => {
            if (eleInd === row.index) {
              newPartnerUserList.push(partnerUserMasterDataObj);
            } else {
              newPartnerUserList.push(ele);
            }
          });

          storePartnerUserMasterData.current = newPartnerUserList;
          setRowsDataPartnerUserMaster(newPartnerUserList);
          setStoreRowsDataPartnerUserMaster(newPartnerUserList);

          storePartnerUserNameValueFlag.current = false;
          storePartnerNameValueFlag.current = false;
          storeEmailValueFlag.current = false;
          storeDesignationValueFlag.current = false;
          storeBalanceConfirmationValueFlag.current = false;
          storeLedgerRequestValueFlag.current = false;
          storePaymentAdviceValueFlag.current = false;

          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error AddBusinessPartnerUserWithEmailSetting", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}AddBusinessPartnerUserWithEmailSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error AddBusinessPartnerUserWithEmailSetting", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}AddBusinessPartnerUserWithEmailSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
    // }
  };

  const deleteBusinessPartnerUser = async (row: any) => {
    try {
      await Axios.post<any>("/api/DeleteBusinessPartnerUser", {
        businessPartnerId: row.original.businessPartnerId,
        businessPartnerName: row.original.businessPartnerName,
        emailId: row.original.email,
      })
        .then((response) => {
          storePartnerUserMasterData.current.splice(row.index, 1);
          setToggle(!toggle);

          listAllBusinessPartnersForPartnerUseCaseMaster();
          // listAllBusinessPartnersForPartnerUserMaster();

          setApiErrorMsg(response.data.message);
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error: any) => {
          console.log("error DeleteBusinessPartnerUser", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DeleteBusinessPartnerUser`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DeleteBusinessPartnerUser", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DeleteBusinessPartnerUser`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getListAllDesignation = async () => {
    try {
      await Axios.get<any>("/api/ListAllDesignation")
        .then((response) => {
          console.log("ListAllDesignation", response.data.designations);

          setListAllDesignation(response.data.designations);
        })
        .catch((error: any) => {
          console.log("error ListAllDesignation", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllDesignation`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllDesignation", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllDesignation`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const getListOwnTallyCompaniesForRecon = async () => {
    try {
      await Axios.get<ListOwnTallyCompaniesRes>("/api/ListOwnTallyCompaniesForPartnerMaster")
        .then((response) => {
          setListOwnTallyCompaniesForRecon(response?.data?.tallyCompanies);
          const lastTallyCompID = response?.data?.lastSelectedTallyCompanyId;
          if (lastTallyCompID) {
            const foundTallyCompany = response.data.tallyCompanies.find((c) => c.companyId === lastTallyCompID);
            const foundLastOwnTallyCompany = {
              firstLetter: foundTallyCompany?.companyName[0].toUpperCase(),
              ...foundTallyCompany,
            };
            setLastOwnTallyCompany(foundLastOwnTallyCompany);
            companyNameSelect({}, foundLastOwnTallyCompany);
          }
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ListOwnTallyCompaniesForRecon", error?.response);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListOwnTallyCompaniesForRecon`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListOwnTallyCompaniesForRecon", error?.response);
      setLoader(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListOwnTallyCompaniesForRecon`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const optionsListOwnTallyCompaniesForRecon = listOwnTallyCompaniesForRecon?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const companyNameSelect = (e: any, option: ListOwnTallyCompanies) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      listAllBusinessPartnersForPartnerUseCaseMaster();
      listAllBusinessPartnersForPartnerUserMaster();
      getPartnerUserBulkUploadExcelTemplate();
    } else if (option === null) {
      // setOwnBusinessPartners([]);
      companyId.current = null;
    }
  };

  const downloadUploadCategoriesInBulkTemplate = () => {
    const excelData = Buffer.from(uploadCategoriesInBulkTemplateBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Upload Categories template.xlsx");
  };

  const uploadCategoriesInBulk = (filePayload) => {
    const reader = new FileReader();
    let data = null;
    reader.readAsDataURL(filePayload[0]);
    reader.onloadend = (event) => {
      data = event.target.result;
      try {
        setShowLoadingIconUpload(true);
        Axios.post<any>("/api/UploadCategoriesInBulk", {
          userName: actor?.name,
          categoriesDataBase64: data
            .toString()
            .split("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,")[1],
        })
          .then((response) => {
            console.log("response", response);
            listAllBusinessPartnersForPartnerUseCaseMaster();
            listAllBusinessPartnersForPartnerUserMaster();
            setShowLoadingIconUpload(false);
            setShowSuccessAlert(true);
            setApiErrorMsg(response.data?.message);
            apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error UploadCategoriesInBulk", error?.response);
            setShowLoadingIconUpload(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            } else {
              setShowErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UploadCategoriesInBulk`);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error UploadCategoriesInBulk", error?.response);
        setShowLoadingIconUpload(false);
        setShowErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}UploadCategoriesInBulk`);
        apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
      }
    };
  };

  const getCategoriesBulkUploadExcelTemplate = () => {
    try {
      Axios.post<any>("/api/GetCategoriesBulkUploadExcelTemplate", {
        userName: actor?.name,
      })
        .then((response) => {
          console.log("getCategoriesBulkUploadExcelTemplate", response);

          setUploadCategoriesInBulkTemplateBase64(response.data?.response);
        })
        .catch((error: any) => {
          console.log("error GetCategoriesBulkUploadExcelTemplate", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetCategoriesBulkUploadExcelTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetCategoriesBulkUploadExcelTemplate", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetCategoriesBulkUploadExcelTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const downloadPartnerUseCaseMasterReport = () => {
    const excelData = Buffer.from(downloadPartnerUseCaseMasterReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} UseCaseMasterReport.xlsx`);
  };
  const downloadPartnerUserMasterReport = () => {
    const excelData = Buffer.from(downloadPartnerUserMasterReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} UserMasterReport.xlsx`);
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Partner Master", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
        // showUploadWarningAlert={showWarningAlert}
      />
      {loader ? (
        <Loader />
      ) : (
        <Grid className="table_list">
          <Grid>
            <Grid className="space_between mb_15 mt_15">
              <div className="d_flex">
                <Card sx={{ p: 0.5 }}>
                  <Autocomplete
                    onChange={(e, value) => partnerMasterSelect(e, value)}
                    id="partnerMasterSelect"
                    className="w_250"
                    options={optionsPartnerMaster}
                    size="small"
                    getOptionLabel={(option) => option}
                    defaultValue={partnerMasterSelected}
                    disableClearable={true}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Card>
                {actor.integration === true && actor.actorInfo.accountingSoftware === "Tally" && (
                  <Card className="ml_20" sx={{ p: 0.5 }}>
                    <Autocomplete
                      className="w_250"
                      size="small"
                      defaultValue={lastOwnTallyCompany}
                      onChange={(e, value) => companyNameSelect(e, value)}
                      id="businessPartner"
                      options={optionsListOwnTallyCompaniesForRecon.sort(
                        (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      disableClearable={true}
                      groupBy={(option: any) => option.firstLetter}
                      getOptionLabel={(option: any) => option.companyName}
                      renderInput={(params) => <TextField {...params} placeholder="Select Company" />}
                    />
                  </Card>
                )}
              </div>

              <Box display={"inline-flex"} gap={2}>
                <Tooltip title="Add Partner Menu" arrow={true}>
                  <Avatar sx={{ background: "#27b27c" }}>
                    <IconButton ref={anchorEl} onClick={() => setOpenAddPartnerMenu(true)} color="inherit">
                      <PersonAdd fontSize={"medium"} />
                    </IconButton>
                  </Avatar>
                </Tooltip>
                <PopOverMenu
                  anchorEl={anchorEl}
                  open={openAddPartnerMenu}
                  closeFn={() => {
                    setOpenAddPartnerMenu(false);
                  }}
                >
                  <AddNewBusinessPartner
                    setShowUploadSuccessAlert={setShowSuccessAlert}
                    setShowUploadErrorAlert={setShowErrorAlert}
                    setApiErrorMsg={setApiErrorMsg}
                    from={"Partner Master"}
                    listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
                    listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
                    getPartnerUserBulkUploadExcelTemplate={getPartnerUserBulkUploadExcelTemplate}
                  />
                  <AddNewPartnerUser
                    setShowSuccessAlert={setShowSuccessAlert}
                    setShowErrorAlert={setShowErrorAlert}
                    setApiErrorMsg={setApiErrorMsg}
                    listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
                    listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
                    rowsDataPartnerUseCaseMaster={rowsDataPartnerUseCaseMaster}
                    setLoader={setLoader}
                    partnerUsersTemplateBase64={partnerUsersTemplateBase64}
                    setOpenPartnerUserBulkUploadErrorReport={setOpenPartnerUserBulkUploadErrorReport}
                    setRowsDataPartnerUserBulkUploadErrorReport={setRowsDataPartnerUserBulkUploadErrorReport}
                    setNumberOfUsersFailed={setNumberOfUsersFailed}
                    setNumberOfUsersSaved={setNumberOfUsersSaved}
                    setErrorListsPartnerUsersBase64={setErrorListsPartnerUsersBase64}
                    listAllDesignation={listAllDesignation}
                    getListAllDesignation={getListAllDesignation}
                  />
                </PopOverMenu>

                {partnerMasterSelected === "Partner use case master" && (
                  <>
                    <Tooltip title="Filter" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => setOpenPartnerUseCaseMasterFilter(true)} color="inherit">
                          {isFilterAppliedPartnerUseCaseMaster ? <FilterAltIcon /> : <FilterAltOffIcon />}
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Report" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => downloadPartnerUseCaseMasterReport()} color="inherit">
                          <DownloadIcon />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </>
                )}
                {partnerMasterSelected === "Partner user master" && (
                  <>
                    <Tooltip title="Filter" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => setOpenPartnerUserMasterFilter(true)} color="inherit">
                          {isFilterAppliedPartnerUserMaster ? <FilterAltIcon /> : <FilterAltOffIcon />}
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Report" arrow={true}>
                      <Avatar sx={{ background: "#27b27c" }}>
                        <IconButton onClick={() => downloadPartnerUserMasterReport()} color="inherit">
                          <DownloadIcon />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </>
                )}
                <Tooltip title="Upload Categories Menu" arrow={true}>
                  <Avatar sx={{ background: "#27b27c" }}>
                    <IconButton ref={anchorUploadEl} onClick={() => setOpenUploadBulkMenu(true)} color="inherit">
                      <Upload fontSize={"medium"} />
                    </IconButton>
                  </Avatar>
                </Tooltip>
                <PopOverMenu
                  anchorEl={anchorUploadEl}
                  open={openUploadBulkMenu}
                  closeFn={() => {
                    setOpenUploadBulkMenu(false);
                  }}
                >
                  <MenuItem onClick={() => uploadInput.current?.click()}>
                    {showLoadingIconUpload ? null : (
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                    )}
                    {showLoadingIconUpload ? (
                      <span className="file-icon">
                        <i className="fas fa-circle-notch fa-spin" />
                      </span>
                    ) : null}
                    Upload Categories in Bulk
                    <input
                      ref={uploadInput}
                      hidden={true}
                      accept=".xlsx"
                      multiple={true}
                      required={true}
                      type="file"
                      onChange={(e) => {
                        uploadCategoriesInBulk(e.target.files);
                        e.target.value = null; // Clear the selected files from the input field
                      }}
                    />
                  </MenuItem>
                  <MenuItem onClick={downloadUploadCategoriesInBulkTemplate}>
                    <span className="file-icon">
                      <i className="fas fa-download" />
                    </span>
                    Download Categories Format
                  </MenuItem>
                </PopOverMenu>
              </Box>
            </Grid>
            {/* <Grid className="mb_15 mt_15">
              <div className="flex_end">
                <Button variant="contained" component="label" className="theme_btn ml_20">
                  {showLoadingIconUpload ? null : (
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                  )}
                  {showLoadingIconUpload ? (
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                  ) : null}
                  Upload categories in bulk
                  <input
                    hidden={true}
                    accept=".xlsx"
                    multiple={true}
                    required={true}
                    type="file"
                    onChange={(e) => {
                      uploadCategoriesInBulk(e.target.files);
                      e.target.value = null; // Clear the selected files from the input field
                    }}
                  />
                </Button>
                <Button
                  variant="contained"
                  className="theme_btn ml_20"
                  onClick={() => {
                    downloadUploadCategoriesInBulkTemplate();
                  }}
                >
                  Download Format
                </Button>
              </div>
            </Grid> */}
            <Card
              className="vertical_center_align mb_15"
              sx={{
                p: 0.5,
                borderRadius: "32px !important",
                gap: 1,
                width: Object.keys(appliedFilters).length ? "auto" : "fit-content",
              }}
            >
              <IconButton disableTouchRipple={true}>
                {Object.keys(appliedFilters).length ? <FilterList /> : <FilterListOff />}
              </IconButton>
              {Object.keys(appliedFilters).length ? (
                <div className="d_flex" style={{ flexWrap: "wrap", gap: "8px", paddingInline: "8px" }}>
                  {Object.keys(appliedFilters).map((_key) =>
                    appliedFilters[_key] ? (
                      <div>
                        <div style={{ fontSize: "10px" }}>{_key}</div>
                        <Chip label={appliedFilters[_key]} size="small" />
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </div>
              ) : (
                <></>
              )}
            </Card>
            <div className="bpDashboard ">
              <Grid>
                {partnerMasterSelected === "Partner use case master" && (
                  <BpDashboardTable
                    columns={columnDefinitionPartnerUseCaseMaster}
                    rows={rowsDataPartnerUseCaseMaster}
                    sortEnable={true}
                  />
                )}
                {partnerMasterSelected === "Partner user master" && (
                  <BpDashboardTable
                    columns={columnDefinitionPartnerUserMaster}
                    rows={rowsDataPartnerUserMaster}
                    sortEnable={true}
                  />
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openPartnerUserBulkUploadErrorReport}
        onClose={() => setOpenPartnerUserBulkUploadErrorReport(true)}
        width70Per={true}
      >
        <PartnerUserBulkUploadErrorReport
          setOpenPartnerUserBulkUploadErrorReport={setOpenPartnerUserBulkUploadErrorReport}
          rowsDataPartnerUserBulkUploadErrorReport={rowsDataPartnerUserBulkUploadErrorReport}
          numberOfUsersFailed={numberOfUsersFailed}
          numberOfUsersSaved={numberOfUsersSaved}
          errorListsPartnerUsersBase64={errorListsPartnerUsersBase64}
        />
      </Dialog>
      <Dialog
        open={openListBusinessPartnerUsers}
        onClose={() => setOpenListBusinessPartnerUsers(true)}
        width70Per={true}
      >
        <ListBusinessPartnerUsers
          setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers}
          listAllBusinessPartnersForPartnerUseCaseMaster={listAllBusinessPartnersForPartnerUseCaseMaster}
          listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
        />
      </Dialog>
      <PartnerUseCaseMasterFilter
        openPartnerUseCaseMasterFilter={openPartnerUseCaseMasterFilter}
        setOpenPartnerUseCaseMasterFilter={setOpenPartnerUseCaseMasterFilter}
        allCategories={storeAllCategories.current}
        rowsDataPartnerUseCaseMaster={rowsDataPartnerUseCaseMaster}
        setRowsDataPartnerUseCaseMaster={setRowsDataPartnerUseCaseMaster}
        storeRowsDataPartnerUseCaseMaster={storeRowsDataPartnerUseCaseMaster}
        setIsFilterAppliedPartnerUseCaseMaster={setIsFilterAppliedPartnerUseCaseMaster}
        setAppliedFilters={setAppliedUCMFilters}
      />
      <PartnerUserMasterFilter
        openPartnerUserMasterFilter={openPartnerUserMasterFilter}
        setOpenPartnerUserMasterFilter={setOpenPartnerUserMasterFilter}
        rowsDataPartnerUserMaster={rowsDataPartnerUserMaster}
        setRowsDataPartnerUserMaster={setRowsDataPartnerUserMaster}
        storeRowsDataPartnerUserMaster={storeRowsDataPartnerUserMaster}
        setIsFilterAppliedPartnerUserMaster={setIsFilterAppliedPartnerUserMaster}
        storePartnerUserMasterData={storePartnerUserMasterData}
        listAllBusinessPartnersForPartnerUserMaster={listAllBusinessPartnersForPartnerUserMaster}
        setAppliedFilters={setAppliedUMFilters}
      />

      {/* edit partner dialog */}
      {openBusinessPopup ? (
        <EditBusinessPartner
          openBusinessPopup={openBusinessPopup}
          setOpenBusinessPopup={setOpenBusinessPopup}
          businessPartnerId={businessPartnerId}
          setShowErrorAlert={setShowErrorAlert}
          setApiErrorMsg={setApiErrorMsg}
          setShowSuccessAlert={setShowSuccessAlert}
          storePartnerUseCaseMasterData={storePartnerUseCaseMasterData}
        />
      ) : null}
    </LoggedInSkeleton>
  );
};

interface PopOverMenuProps {
  anchorEl: React.MutableRefObject<HTMLElement>;
  open: boolean;
  closeFn: () => void;
  clickFn?: () => void;
  children: React.ReactElement<typeof MenuItem>[];
}
const PopOverMenu = ({ anchorEl, open, closeFn, clickFn, children }: PopOverMenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl.current}
      open={open}
      onClose={closeFn}
      onClick={clickFn}
      PopoverClasses={{ root: "zIndex_10" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children as unknown as JSX.Element[]}
    </Menu>
  );
};

export default PartnerMaster;
