import { Button, Grid, TextField } from "@mui/material";
import Axios from "axios";
import React, { useState } from "react";
import { EmailSMTPHosts, ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const SendEmailFromOfficialEmail = (props: any) => {
  const [errUserEmailID, setErrUserEmailID] = useState<boolean>(false);
  const [errPassword, setErrPassword] = useState<boolean>(false);
  const [authenticationErrMsg, setAuthenticationErrMsg] = useState<string>("");
  const [authenticationErr, setAuthenticationErr] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);

  const saveSendEmailFromOfficialEmail = () => {
    if (!props.userEmailID.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
      setErrUserEmailID(true);
    }
    if (props.password.trim() === "") {
      setErrPassword(true);
    }
    if (props.password.trim() !== "" && props.userEmailID.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
      setShowLoadingIcon(true);
      try {
        Axios.post("/api/GetEmailCredentialsAuthenticated", {
          email: props.userEmailID,
          password: props.password,
          smtpHost: props.smtpHostName,
        })
          .then((response) => {
            setShowLoadingIcon(false);
            props.setSmtpHost(props.smtpHostName);
            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);

            props.setFromEmailAddress(props.userEmailID);
            if (!(props.replyToEmailAddress as string[]).includes(props.userEmailID))
              props.setReplyToEmailAddress([props.userEmailID, ...props.replyToEmailAddress]);
            props.setEmailCredentialsProvided(true);
            props.setOpenSendEmailFromOfficialEmail(false);
          })
          .catch((error) => {
            console.log("error GetEmailCredentialsAuthenticated", error?.response);
            setShowLoadingIcon(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setAuthenticationErr(true);
              setAuthenticationErrMsg(dataObj.message);

              // props.setShowErrorAlert(true);
              // props.setApiErrorMsg(dataObj.message);
              // apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.etApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}GetEmailCredentialsAuthenticated`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error GetEmailCredentialsAuthenticated", error?.response);
        setShowLoadingIcon(false);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}GetEmailCredentialsAuthenticated`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const handleClose = () => {
    props.setOpenSendEmailFromOfficialEmail(false);
    props.setOpenSelectEmailDomain(true);
    props.setUserEmailID("");
    props.setPassword("");
  };

  return (
    <>
      <header className="modal-card-head">
        <p className="modal-card-title">{props.smtpHostName}</p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
        />
      </header>
      <section className="modal-card-body">
        <Grid>
          <Grid>
            <p className="p_10">Please fill the below information to authenticate the email:</p>
          </Grid>
          {authenticationErr && (
            <Grid>
              <p style={{ color: "red" }} className="p_10">
                {authenticationErrMsg}
              </p>
            </Grid>
          )}
          <Grid className="d_flex mt_15">
            <Grid className="width_250 vertical_center_align p_10">
              <label className="addBpUser_label">User email ID :</label>
            </Grid>
            <Grid className="textfeild_width vertical_center_align">
              <TextField
                // disabled
                variant="outlined"
                required={true}
                fullWidth={true}
                label="User email ID"
                name="UseremailID"
                error={errUserEmailID}
                helperText={errUserEmailID ? "please enter valid email" : ""}
                value={props.userEmailID}
                onChange={(e) => {
                  props.setUserEmailID(e.target.value);
                  setAuthenticationErr(false);
                  if (e.target.value.length > 0) {
                    if (!e.target.value.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
                      setErrUserEmailID(true);
                    } else {
                      setErrUserEmailID(false);
                    }
                  } else {
                    setErrUserEmailID(false);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid className="d_flex mt_15">
            <Grid className="width_250 vertical_center_align p_10">
              <label className="addBpUser_label">
                {props.smtpHostName === EmailSMTPHosts.gmail ? "Gmail 'App Password':" : "Password:"}
              </label>
            </Grid>
            <Grid className="textfeild_width vertical_center_align">
              <TextField
                variant="outlined"
                type="password"
                required={true}
                fullWidth={true}
                label="Password"
                name="Password"
                error={errPassword}
                helperText={errPassword ? "please enter password" : ""}
                value={props.password}
                onChange={(e) => {
                  props.setPassword(e.target.value);
                  setAuthenticationErr(false);
                  if (e.target.value.trim() !== "") {
                    setErrPassword(false);
                  } else {
                    setErrPassword(true);
                  }
                }}
              />
            </Grid>
          </Grid>
          {props.smtpHostName === EmailSMTPHosts.gmail && (
            <Grid className="d_flex mt_15">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Refer to this link to get your 'App Password'.</label>
                <a
                  href="https://support.google.com/accounts/answer/185833?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </Grid>
            </Grid>
          )}
        </Grid>
      </section>
      <footer className="modal-card-foot space_between">
        <div>
          <Button
            className="theme_outline_btn"
            onClick={() => {
              handleClose();
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            className="theme_btn"
            onClick={() => {
              saveSendEmailFromOfficialEmail();
            }}
            disabled={showLoadingIcon}
          >
            {showLoadingIcon ? (
              <span className="file-icon">
                <i className="fas fa-circle-notch fa-spin" />
              </span>
            ) : null}
            Authenticate
          </Button>
        </div>
      </footer>
    </>
  );
};

export default SendEmailFromOfficialEmail;
