import {
  Close,
  Download,
  FilterAlt,
  FilterAltOff,
  FilterList,
  FilterListOff,
  RateReview,
  ViewWeek,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Column, ColumnDef, Row } from "@tanstack/react-table";
import Axios from "axios";
import { Buffer } from "buffer";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import * as XLSX from "xlsx";
import {
  DataInterfaceForPartnerLevelDataResponse,
  ErrorResponse,
  // GetReconMisDataResponse,
  PartnerLevelData,
  ReconOverviewData,
  ReconOverviewFilters,
} from "../../../entity/recon-entity/ReconInterfaces";
import { MOM, QOQ, TRACKER_LEVEL, TRACKER_SUMMARY } from "../../../Utils/Recon/ReconMIS/Constants";
import IndeterminateCheckbox from "../../Admin/IndeterminateCheckbox";
import Loader from "../../Common/Loader";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import useFetch from "../PartnerCommunication/useFetch";
import OverviewLevelFilter from "./OverviewLevelFilter";
import "./ReconMIS.scss";
import OverViewChart from "./ReconMISOverviewChart";
import TrackerCommentModal from "./TrackerCommentModal";
import TrackerLevelFilter from "./TrackerLevelFilter";

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};
const filterCategory = createFilterOptions<any>();

const ReconMIS = () => {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  // const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [selectedSegment, setSelectedSegment] = useState<string>(TRACKER_SUMMARY);

  const periodOptions = {
    // [DOD]: "DoD (Current + Last 7 days)",
    // [WOW]: "WoW (Current + Last 4 weeks)",
    [MOM]: "MoM (Current + Last 3 months)",
    [QOQ]: "QoQ (Current + Last 3 quarters)",
  };
  // const selectedMetricValue = useRef<string>("Balance Confirmation");
  const selectedPeriodValue = useRef<string>("MoM (Current + Last 3 months)");
  const [rowsDataTrackerLevel, setRowsDataTrackerLevel] = useState<any>([]);
  const storeTrackerLevelData = useRef([]);
  const [rowsDataOverview, setRowsDataOverview] = useState<ReconOverviewData[]>([]);
  // const [openTrackerLevelFilter, setOpenTrackerLevelFilter] = useState<boolean>(false);
  const storeAllCategories = useRef<any[]>([]);
  const [storeRowsDataTrackerLevel, setStoreRowsDataTrackerLevel] = useState<any>([]);
  const [isFilterAppliedTrackerLevelFilter, setIsFilterAppliedTrackerLevelFilter] = useState<boolean>(false);
  // const [downloadOverview, setDownloadOverview] = useState<any>(null);
  // const [downloadEntryLevel, setDownloadEntryLevel] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [openOverviewLevelFilter, setOpenOverviewLevelFilter] = useState<boolean>(false);
  const [isFilterAppliedOverviewLevelFilter, setIsFilterAppliedOverviewLevelFilter] = useState<boolean>(false);
  const filter = useRef<ReconOverviewFilters | "all">("all");
  const [tempLoader, setTempLoader] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [openTrackerCommentModal, setOpenTrackerCommentModal] = useState<boolean>(false);
  const selectedRowTrackerComment = useRef(null);
  const [openTrackerLevelFilter, setOpenTrackerLevelFilter] = useState<boolean>(false);
  const [openColumnModal, setOpenColumnModal] = useState<boolean>(false);
  const [allLeafColumns, setAllLeafColumns] = useState<Column<any>[]>(null);
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [openTargetModal, setOpenTargetModal] = useState(false);
  const [openTargetUploadErrorModal, setOpenTargetUploadErrorModal] = useState<boolean>(false);
  const [targetUploadErrorMsg, setTargetUploadErrorMsg] = useState<string>("");
  const [targetUploadExcelBase64, setTargetUploadExcelBase64] = useState();

  const columnDefinitionTrackerLevel: ColDef<PartnerLevelData>[] = [
    {
      header: "Partner Name",
      accessorKey: "businessPartnerName",
      sticky: "left",
      size: 180,
      enableHiding: false,
    },
    {
      header: "Activity Month",
      accessorKey: "activityMonth",
      size: 180,
      enableHiding: false,
    },
    {
      header: "Target Type",
      accessorKey: "targetType",
      size: 180,
    },
    {
      header: "Category",
      accessorFn: (row) => row.category?.[0]?.toLowerCase(),
      size: 200,
      cell: ({ row }) => (
        <p className="category_ui" key={row.original.businessPartnerId}>
          <Autocomplete
            className="category_autocomplete"
            openOnFocus={true}
            limitTags={1}
            value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
            onChange={(_ev, val) => {
              let value = val as string[];
              console.log(_ev);
              if (value !== null && value?.length > 0) {
                if (value[value.length - 1].includes("+Add => ")) {
                  value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                  value[value.length - 1] = value[value.length - 1].replace('"', "");
                  const removeEmptyVal = value.filter((el) => {
                    return el !== "";
                  });
                  value = removeEmptyVal;
                }
              }
              storeAllCategories.current = [...new Set([...storeAllCategories?.current, ...value])];
              updateOwnAndBusinessPartnerMappingCategories(row, value);
            }}
            noOptionsText={"Enter a new category"}
            multiple={true}
            id="tags-outlined"
            options={storeAllCategories?.current}
            getOptionLabel={(option: any) => option}
            filterSelectedOptions={true}
            filterOptions={(optionsFilt, params) => {
              console.log(optionsFilt);
              const filtered = filterCategory(
                storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                params
              );
              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push(`+Add => "${params.inputValue.trim()}"`);
              }
              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // placeholder="Add"
                sx={{
                  "& input": {
                    px: "12px !important",
                  },
                }}
              />
            )}
            sx={{}}
          />
        </p>
      ),
    },
    {
      header: "User Email",
      accessorKey: "userEmailsConcatenated",
      size: 300,
    },
    {
      header: "Balance confirmation/Ledger request Date​",
      accessorKey: "balanceConfirmationLedgerRequestDate",
      enableSorting: true,
      size: 280,
      cell: ({ row }) => (
        <p>
          {row.original.balanceConfirmationLedgerRequestDate !== null &&
          row.original.balanceConfirmationLedgerRequestDate !== "Not Requested"
            ? moment(row.original.balanceConfirmationLedgerRequestDate)?.format("DD-MM-YYYY")
            : row.original.balanceConfirmationLedgerRequestDate}
        </p>
      ),
    },
    {
      header: "Reminder count in Month",
      accessorKey: "reminderCount",
      size: 160,
    },
    {
      header: "Days Past since last partner reply",
      accessorKey: "daysPastSinceLastPartnerReply",
      size: 220,
    },
    {
      header: `Days past since last mail by ${actor.name}`,
      accessorKey: "daysPastSinceLastMailByRU",
      size: 220,
    },
    {
      header: "Ledger received date",
      accessorKey: "ledgerReceivedDate",
      enableSorting: true,
      size: 180,
      cell: ({ row }) => (
        <p>
          {row.original.ledgerReceivedDate !== null && row.original.ledgerReceivedDate !== "Not Received"
            ? moment(row.original.ledgerReceivedDate)?.format("DD-MM-YYYY")
            : row.original.ledgerReceivedDate}
        </p>
      ),
    },
    {
      header: "Recon run on tool",
      accessorKey: "reconRunDate",
      enableSorting: true,
      size: 150,
      cell: ({ row }) => (
        <p>
          {row.original.reconRunDate !== null && row.original.reconRunDate !== "Not Run"
            ? moment(row.original.reconRunDate)?.format("DD-MM-YYYY")
            : row.original.reconRunDate}
        </p>
      ),
    },
    {
      header: "Generated Gap summary",
      accessorKey: "summaryGeneratedDate",
      enableSorting: true,
      size: 150,
      cell: ({ row }) => (
        <p>
          {row.original.summaryGeneratedDate !== null && row.original.summaryGeneratedDate !== "Not Generated"
            ? moment(row.original.summaryGeneratedDate)?.format("DD-MM-YYYY")
            : row.original.summaryGeneratedDate}
        </p>
      ),
    },
    {
      header: "Date of Recon sent to Partner",
      accessorKey: "reconSentToPartnerDate",
      enableSorting: true,
      size: 200,
      cell: ({ row }) => (
        <p>
          {row.original.reconSentToPartnerDate !== null && row.original.reconSentToPartnerDate !== "Not Sent"
            ? moment(row.original.reconSentToPartnerDate)?.format("DD-MM-YYYY")
            : row.original.reconSentToPartnerDate}
        </p>
      ),
    },
    {
      header: "Comments/ Remarks",
      accessorKey: "remarks",
      enableSorting: false,
      size: 80,
      cell: ({ row }) => (
        <div key={`${row.original.businessPartnerId}-${row.original.activityMonth}`} className="center_align_ver_horiz">
          <RateReview
            className="cursor_pointer"
            onClick={() => {
              selectedRowTrackerComment.current = row;
              setOpenTrackerCommentModal(true);
            }}
          />
        </div>
      ),
    },
    {
      header: "Status",
      accessorKey: "statusDone",
      enableSorting: false,
      size: 200,
      enableHiding: false,
      cell: ({ row }) => (
        <div key={`${row.original.businessPartnerId}-${row.original.activityMonth}`}>
          <FormControl>
            <Select
              labelId={`dropdown-label-${row.original.businessPartnerId}`}
              id={`dropdown-${row.original.businessPartnerId}`}
              value={row.original.statusDone ? "Done" : "Not done"}
              onChange={(e) => {
                updateStatus(row, e.target.value === "Done" ? true : false);
              }}
              size="small"
              sx={{ width: "150px", height: 30 }}
            >
              <MenuItem value="Not done">Not done</MenuItem>
              <MenuItem value="Done">Done</MenuItem>
            </Select>
          </FormControl>
        </div>
      ),
    },
    {
      header: "Download Result",
      accessorKey: "businessPartnerId",
      enableSorting: false,
      sticky: "right",
      size: 100,
      enableHiding: false,
      cell: ({ row }) => (
        <div key={`${row.original.businessPartnerId}-${row.original.activityMonth}`} className="center_align_ver_horiz">
          <Download
            className="cursor_pointer"
            onClick={() => {
              downloadResult(row);
            }}
            sx={{ fill: "#27b27c" }}
          />
        </div>
      ),
    },
  ];

  const replacePeriodValue = (input: string) => {
    return Object.entries(periodOptions).find((period) => period[1] === input)[0];
  };

  const selectPeriod = (e: any, value: any) => {
    if (value !== null) {
      e.persist();
      selectedPeriodValue.current = value;
      //   call api function
      getReconMis(selectedSegment);
    } else {
      selectedPeriodValue.current = null;
    }
  };

  useEffect(() => {
    getReconMis(TRACKER_LEVEL);
    getReconMis(TRACKER_SUMMARY);
    // getReconMis(PARTNER_LEVEL);
    // eslint-disable-next-line
  }, []);

  const getReconMis = async (selectedSegmentName: string) => {
    try {
      await Axios.post("/api/getReconMis", {
        viewLevel: selectedSegmentName,
        // tableId: selectedMetricValue.current,
        dateRange: replacePeriodValue(selectedPeriodValue.current),
        filters: filter.current,
      })
        .then((response) => {
          if (selectedSegmentName === TRACKER_SUMMARY) {
            // const processed = processOverViewData(response.data?.dataObj as unknown as ReconOverviewData[] as any);
            setRowsDataOverview(response.data?.dataObj?.summaryData as ReconOverviewData[]);
            setUserEmails(response.data?.dataObj?.allUserEmails || []);
            setAllCategories(response.data?.dataObj?.allCategories || []);
            // setDownloadOverview();
            // } else if (selectedSegmentName === "Entry Level") {
            // setRowsDataEntryLevel([{}]);
            // setStoreRowsDataEntryLevel([{}]);
            // setDownloadEntryLevel();
          } else if (selectedSegmentName === TRACKER_LEVEL) {
            // Sorting function
            const dataObj =
              (response.data.dataObj as DataInterfaceForPartnerLevelDataResponse) ||
              ({ data: [], allCategories: [] } as DataInterfaceForPartnerLevelDataResponse);
            const sortedData = [...dataObj.data]?.sort((a, b) => {
              // Parse activityMonth strings into Date objects
              const dateA = new Date(a.activityMonth?.replace(/(\w+) (\d+)$/, "$2 $1"));
              const dateB = new Date(b.activityMonth?.replace(/(\w+) (\d+)$/, "$2 $1"));

              // Compare the dates
              if (dateA > dateB) return -1;
              if (dateA < dateB) return 1;

              // If dates are equal, compare businessPartnerName in ascending order
              return a.businessPartnerName?.localeCompare(b.businessPartnerName);
            });

            setRowsDataTrackerLevel(sortedData);
            setStoreRowsDataTrackerLevel(sortedData);
            storeTrackerLevelData.current = sortedData;
            storeAllCategories.current = dataObj.allCategories;
            setLoader(false);
          }
          // setShowSuccessAlert(true);
          // setApiErrorMsg(response.data.message);
          // apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error: any) => {
          console.log("error getReconMis", error?.response, error);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}getReconMis`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error getReconMis", error?.response, error);
      setLoader(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}getReconMis`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const downloadExcelReconMIS = (fileBase64: WithImplicitCoercion<string>, fileName: string) => {
    const excelData = Buffer.from(fileBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${fileName}.xlsx`);
    setShowLoadingIcon(false);
  };

  const createSummaryDataExcel = async (summaryData: ReconOverviewData[]) => {
    const workbook = XLSX.utils.book_new();

    const rows = summaryData.map((row) => {
      const Entries = row.Entries;

      return {
        Section: row.Section,
        ...Entries,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Summary Data");

    XLSX.writeFile(workbook, `${actor.name}-${selectedPeriodValue.current}-SummaryData.xlsx`);
  };

  const createTrackerDataExcel = async (trackerData: PartnerLevelData[]) => {
    setShowLoadingIcon(true);
    const reportWorkbook = new Excel.Workbook();
    reportWorkbook.addWorksheet("Tracker Level");
    const headerColumn = [
      "Partner Name",
      "Activity Month",
      "Category",
      "User Email",
      "Balance confirmation/Ledger request Date",
      "Reminder count",
      "Days Past since last partner reply",
      "Days past since last mail by own",
      "Ledger received date",
      "Recon run on tool",
      "Generated Gap summary",
      "Date of Recon sent to Partner",
      "Comments/ Remarks",
      "Status",
    ];

    for (let index = 1; index <= headerColumn.length; index++) {
      reportWorkbook.worksheets[0].getRow(1).getCell(index).value = headerColumn[index - 1];
    }

    for (let i = 0; i < trackerData.length; i++) {
      const row = trackerData[i];

      reportWorkbook.worksheets[0].getRow(i + 2).getCell(1).value = row.businessPartnerName;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(2).value = row.activityMonth;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(3).value = row.category?.join(",");
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(4).value = row.userEmailsConcatenated;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(5).value =
        row.balanceConfirmationLedgerRequestDate !== null &&
        row.balanceConfirmationLedgerRequestDate !== "Not Requested"
          ? moment(row.balanceConfirmationLedgerRequestDate)?.format("DD-MM-YYYY")
          : row.balanceConfirmationLedgerRequestDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(6).value = row.reminderCount;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(7).value = row.daysPastSinceLastPartnerReply;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(8).value = row.daysPastSinceLastMailByRU;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(9).value =
        row.ledgerReceivedDate !== null && row.ledgerReceivedDate !== "Not Received"
          ? moment(row.ledgerReceivedDate)?.format("DD-MM-YYYY")
          : row.ledgerReceivedDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(10).value =
        row.reconRunDate !== null && row.reconRunDate !== "Not Run"
          ? moment(row.reconRunDate)?.format("DD-MM-YYYY")
          : row.reconRunDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(11).value =
        row.summaryGeneratedDate !== null && row.summaryGeneratedDate !== "Not Generated"
          ? moment(row.summaryGeneratedDate)?.format("DD-MM-YYYY")
          : row.summaryGeneratedDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(12).value =
        row.reconSentToPartnerDate !== null && row.reconSentToPartnerDate !== "Not Sent"
          ? moment(row.reconSentToPartnerDate)?.format("DD-MM-YYYY")
          : row.reconSentToPartnerDate;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(13).value = row.remarks;
      reportWorkbook.worksheets[0].getRow(i + 2).getCell(14).value = row.statusDone ? "Done" : "Not done";
    }

    const reportWorkbookBuffer: any = await reportWorkbook.xlsx.writeBuffer();
    const reportWorkbookBase64: string = reportWorkbookBuffer.toString("base64");

    return downloadExcelReconMIS(reportWorkbookBase64, TRACKER_LEVEL);
  };

  const updateOwnAndBusinessPartnerMappingCategories = async (row: any, value: any) => {
    try {
      await Axios.post<any>("/api/UpdateOwnAndBusinessPartnerMappingCategories", {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
      })
        .then((response) => {
          console.log("response", response);
          setTempLoader(true);
          updateCategory(row, value);
        })
        .catch((error: any) => {
          console.log("error UpdateOwnAndBusinessPartnerMappingCategories", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UpdateOwnAndBusinessPartnerMappingCategories`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UpdateOwnAndBusinessPartnerMappingCategories", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UpdateOwnAndBusinessPartnerMappingCategories`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const updateCategory = (row: any, value: any) => {
    storeTrackerLevelData.current.find(
      (v: any) =>
        v.businessPartnerId === row.original.businessPartnerId && v.activityMonth === row.original.activityMonth
    ).category = value;

    console.log(tempLoader);
    setTempLoader(false);
  };

  const updateStatus = (row: any, value: any) => {
    const updatedRowsDataTrackerLevel = rowsDataTrackerLevel?.map((v) =>
      v.businessPartnerId === row.original.businessPartnerId && v.activityMonth === row.original.activityMonth
        ? { ...v, statusDone: value }
        : v
    );
    setRowsDataTrackerLevel(updatedRowsDataTrackerLevel);
    setStoreRowsDataTrackerLevel(updatedRowsDataTrackerLevel);
    saveUpdatedStatus(row.original.businessPartnerId, row.original.activityMonth, value);
  };

  const saveUpdatedStatus = (id: number, month: string, statusVal: boolean) => {
    try {
      Axios.post("/api/updateStatusDoneTracker", {
        businessPartnerId: id,
        activityMonth: month,
        statusDone: statusVal,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error: any) => {
          console.log("error updateStatusDoneTracker", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}updateStatusDoneTracker`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error updateStatusDoneTracker", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}updateStatusDoneTracker`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const saveUpdateRemarksTracker = (id: number, month: string, comment: string) => {
    try {
      Axios.post("/api/updateRemarksTracker", {
        businessPartnerId: id,
        activityMonth: month,
        remarks: comment,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error: any) => {
          console.log("error updateRemarksTracker", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}updateRemarksTracker`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error updateRemarksTracker", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}updateRemarksTracker`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const downloadResult = (row: Row<any>) => {
    try {
      Axios.get(`/api/download/${row.original.activityMonth}/${actor.id}/${row.original.businessPartnerId}/null/null`, {
        responseType: "blob", // Set the response type to blob
      })
        .then((response) => {
          console.log(response);
          const blob = new Blob([response.data], { type: response.headers["content-type"] });
          // Create a temporary URL for the blob
          const url = window.URL.createObjectURL(blob);
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${row.original.businessPartnerName}-${row.original.activityMonth} Recon Output.xlsm`
          ); // Set the desired file name
          // Trigger the download by clicking the link
          link.click();
          // Clean up the temporary URL
          window.URL.revokeObjectURL(url);
        })
        .catch((error: any) => {
          console.log("error download", error?.response);
          if (error?.response?.status === 404) {
            // const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg("No recon output found for partner in given activity month");
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}download`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error download", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}download`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Process Management", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
        // showUploadWarningAlert={showWarningAlert}
      />
      {loader ? (
        <Loader />
      ) : (
        <Grid>
          <Grid className="mb_15">
            <Button
              variant="contained"
              className={`${selectedSegment === TRACKER_SUMMARY ? "theme_btn" : "theme_outline_btn"} width_180`}
              onClick={() => {
                setSelectedSegment(TRACKER_SUMMARY);
                // getReconMis(TRACKER_SUMMARY);
              }}
            >
              Overview
            </Button>

            <Button
              variant="contained"
              className={`${selectedSegment === TRACKER_LEVEL ? "theme_btn" : "theme_outline_btn"} width_180 ml_20`}
              onClick={() => {
                setSelectedSegment(TRACKER_LEVEL);
                // getReconMis(TRACKER_LEVEL);
              }}
            >
              Closure Tracker
            </Button>
          </Grid>
          {selectedSegment === TRACKER_SUMMARY && (
            <Grid maxHeight={"60vh"}>
              <Grid className="space_between mb_25">
                <div className="d_flex">
                  <Card sx={{ p: 0.5 }}>
                    <Autocomplete
                      onChange={(e, value) => selectPeriod(e, value)}
                      id="selectPeriod"
                      className="w_300"
                      options={Object.values(periodOptions)}
                      size="small"
                      getOptionLabel={(option) => option}
                      value={selectedPeriodValue.current}
                      disableClearable={true}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Card>
                </div>
                <div className="d_flex" style={{ gap: 16 }}>
                  <Tooltip title={"Filter"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenOverviewLevelFilter(true)} color="inherit">
                        {isFilterAppliedOverviewLevelFilter ? <FilterAlt /> : <FilterAltOff />}
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                  <Tooltip title={"Download"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => createSummaryDataExcel(rowsDataOverview)} color="inherit">
                        <Download />
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                </div>

                {/* <Button
                    variant="contained"
                    className="theme_btn width_180 ml_20"
                    onClick={() => {
                      // downloadExcelReconMIS(downloadOverview, "Overview");
                    }}
                  >
                    Download
                  </Button> */}
              </Grid>

              <Card
                className="vertical_center_align mb_15"
                sx={{
                  p: 0.5,
                  borderRadius: "32px !important",
                  gap: 1,
                  width: Object.keys(appliedFilters).length ? "auto" : "fit-content",
                }}
              >
                <IconButton disableTouchRipple={true}>
                  {Object.keys(appliedFilters).length ? <FilterList /> : <FilterListOff />}
                </IconButton>
                {Object.keys(appliedFilters).length ? (
                  <div className="d_flex" style={{ flexWrap: "wrap", gap: "8px", paddingInline: "8px" }}>
                    {Object.keys(appliedFilters).map(
                      (_key) =>
                        appliedFilters[_key] && (
                          <div>
                            <div style={{ fontSize: "10px" }}>{_key}</div>
                            <Chip label={appliedFilters[_key]} size="small" className="fs_14" />
                          </div>
                        )
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Card>

              <Card className="center_align" sx={{ height: "60vh" }}>
                <OverViewChart
                  pData={rowsDataOverview}
                  pDateRangeType={replacePeriodValue(selectedPeriodValue.current) as any}
                />
              </Card>
            </Grid>
          )}

          {selectedSegment === TRACKER_LEVEL && (
            <Grid maxHeight={"75vh"}>
              <Grid className="mb_25">
                <div className="flex_end" style={{ gap: 16 }}>
                  <Tooltip title={"Filter"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenTrackerLevelFilter(true)} color="inherit">
                        {isFilterAppliedTrackerLevelFilter ? <FilterAlt /> : <FilterAltOff />}
                      </IconButton>
                    </Avatar>
                  </Tooltip>

                  {/* <Button variant="contained" className="theme_btn  ml_20" onClick={() => {}}>
                    Set Target
                  </Button> */}
                  <Tooltip title={"Set Target"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenTargetModal(true)} color="inherit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32px"
                          height="32px"
                          viewBox="-5 0 32 32"
                          fill="#fff"
                        >
                          <path d="M21.56 15.16h-1.56c-0.44-4.2-3.76-7.56-7.96-7.96v-1.56c0-0.48-0.36-0.84-0.84-0.84s-0.84 0.36-0.84 0.84v1.56c-4.2 0.4-7.56 3.76-7.96 7.96h-1.56c-0.48 0-0.84 0.36-0.84 0.84s0.36 0.84 0.84 0.84h1.56c0.4 4.2 3.76 7.56 7.96 7.96v1.56c0 0.48 0.36 0.84 0.84 0.84s0.84-0.36 0.84-0.84v-1.56c4.2-0.4 7.56-3.76 7.96-7.96h1.56c0.48 0 0.84-0.36 0.84-0.84s-0.4-0.84-0.84-0.84zM16.8 16.84h1.48c-0.4 3.28-3 5.88-6.24 6.24v-1.48c0-0.48-0.36-0.84-0.84-0.84s-0.84 0.36-0.84 0.84v1.48c-3.28-0.4-5.88-3-6.24-6.24h1.48c0.48 0 0.84-0.36 0.84-0.84s-0.36-0.84-0.84-0.84h-1.48c0.4-3.28 3-5.88 6.24-6.24v1.48c0 0.48 0.36 0.84 0.84 0.84s0.84-0.36 0.84-0.84v-1.48c3.28 0.4 5.88 3 6.24 6.24h-1.48c-0.48 0-0.84 0.36-0.84 0.84s0.4 0.84 0.84 0.84z" />
                        </svg>
                      </IconButton>
                    </Avatar>
                  </Tooltip>

                  <Tooltip title={"Toggle Columns"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => setOpenColumnModal(true)} color="inherit">
                        <ViewWeek sx={{ fontSize: "22px !important" }} />
                      </IconButton>
                    </Avatar>
                  </Tooltip>

                  <Tooltip title={"Download Report"} arrow={true}>
                    <Avatar sx={{ background: "#27b27c" }}>
                      <IconButton onClick={() => createTrackerDataExcel(rowsDataTrackerLevel)} color="inherit">
                        {showLoadingIcon ? (
                          <span
                            className="file-icon"
                            style={{
                              position: "absolute",
                              transform: "scale(2.5)",
                              left: 11,
                            }}
                          >
                            <i className="fas fa-circle-notch fa-spin" />
                          </span>
                        ) : null}
                        <Download />
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                </div>
              </Grid>
              <Grid height={"50vh"}>
                <BpDashboardTable
                  columns={columnDefinitionTrackerLevel}
                  rows={rowsDataTrackerLevel}
                  sortEnable={true}
                  setAllLeafColumns={setAllLeafColumns}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      <OverviewLevelFilter
        openOverviewLevelFilter={openOverviewLevelFilter}
        setOpenOverviewLevelFilter={setOpenOverviewLevelFilter}
        filter={filter}
        getReconMis={() => {
          getReconMis(selectedSegment);
        }}
        setIsFilterAppliedOverviewLevelFilter={setIsFilterAppliedOverviewLevelFilter}
        allCategories={allCategories}
        allEmails={userEmails}
        setAppliedFilters={setAppliedFilters}
      />
      {openTrackerCommentModal && (
        <TrackerCommentModal
          openTrackerCommentModal={openTrackerCommentModal}
          setOpenTrackerCommentModal={setOpenTrackerCommentModal}
          selectedRowTrackerComment={selectedRowTrackerComment.current}
          rowsDataTrackerLevel={rowsDataTrackerLevel}
          setRowsDataTrackerLevel={setRowsDataTrackerLevel}
          setStoreRowsDataTrackerLevel={setStoreRowsDataTrackerLevel}
          saveUpdateRemarksTracker={(id, month, val) => {
            saveUpdateRemarksTracker(id, month, val);
          }}
        />
      )}
      <TrackerLevelFilter
        openTrackerLevelFilter={openTrackerLevelFilter}
        setOpenTrackerLevelFilter={setOpenTrackerLevelFilter}
        allCategories={storeAllCategories.current}
        rowsDataTrackerLevel={rowsDataTrackerLevel}
        setRowsDataTrackerLevel={setRowsDataTrackerLevel}
        storeRowsDataTrackerLevel={storeRowsDataTrackerLevel}
        setIsFilterAppliedTrackerLevelFilter={setIsFilterAppliedTrackerLevelFilter}
        // setAppliedFilters={setAppliedFilters}
      />
      <ShowHideColumnModal open={openColumnModal} setOpen={setOpenColumnModal} leafColumns={allLeafColumns} />
      <TargetModal
        open={openTargetModal}
        setOpen={setOpenTargetModal}
        setShowErrorAlert={setShowErrorAlert}
        setShowSuccessAlert={setShowSuccessAlert}
        setApiErrorMsg={setApiErrorMsg}
        setOpenTargetUploadErrorModal={setOpenTargetUploadErrorModal}
        setTargetUploadErrorMsg={setTargetUploadErrorMsg}
        setTargetUploadExcelBase64={setTargetUploadExcelBase64}
      />
      <TargetModalUploadError
        open={openTargetUploadErrorModal}
        setOpen={setOpenTargetUploadErrorModal}
        setShowErrorAlert={setShowErrorAlert}
        setApiErrorMsg={setApiErrorMsg}
        targetUploadErrorMsg={targetUploadErrorMsg}
        targetUploadExcelBase64={targetUploadExcelBase64}
      />
    </LoggedInSkeleton>
  );
};

const ShowHideColumnModal = ({ open, setOpen, leafColumns }: { leafColumns: Column<any>[]; [k: string]: any }) => {
  const [update, setUpdate] = useState(0);

  const camelToWords = (str: string) => str.replace(/[A-Z]/g, (letter) => ` ${letter}`).trim();

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Show/Hide Columns</DialogTitle>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent key={update} sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        {leafColumns &&
          leafColumns.map((column) => (
            <React.Fragment key={column.id}>
              {column.getCanHide() ? (
                <FormGroup
                  row={true}
                  sx={{
                    "&:has(:hover)": {
                      background: "#eee",
                    },
                    px: 1,
                  }}
                >
                  <FormLabel sx={{ display: "inline-flex", gap: 1, alignItems: "center", cursor: "pointer" }}>
                    <IndeterminateCheckbox
                      checked={column.getIsVisible()}
                      onChange={() => {
                        column.toggleVisibility();
                        setUpdate(update + 1);
                      }}
                    />
                    {column.id[0].toUpperCase() + camelToWords(column.id.slice(1))}
                  </FormLabel>
                </FormGroup>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button autoFocus={true} onClick={() => setOpen(false)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TargetModal = ({
  open,
  setOpen,
  setShowErrorAlert,
  setApiErrorMsg,
  setOpenTargetUploadErrorModal,
  setShowSuccessAlert,
  setTargetUploadErrorMsg,
  setTargetUploadExcelBase64,
}: {
  [k: string]: any;
}) => {
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);

  const uploadTrackerTargetExcel = (e) => {
    setShowLoadingIconUpload(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);
    try {
      Axios.post("/api/uploadTrackerTargetExcel", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log("uploadTrackerTargetExcel", response);
          setShowLoadingIconUpload(false);
          if (response.data?.addedPartners === response.data?.readPartners) {
            setShowSuccessAlert(true);
            setApiErrorMsg(response.data?.message);
            apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
          } else if (response.data?.addedPartners < response.data?.readPartners) {
            setOpenTargetUploadErrorModal(true);
            const errMsg = `${response.data?.addedPartners}/${
              response.data?.readPartners
            } entries are successfully added, please correct ${
              response.data?.readPartners - response.data?.addedPartners
            } entries as per file below`;
            setTargetUploadErrorMsg(errMsg);
            setTargetUploadExcelBase64(response.data?.excelBase64);
          }
        })
        .catch((error) => {
          console.log("error uploadTrackerTargetExcel", error?.response);
          setShowLoadingIconUpload(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}uploadTrackerTargetExcel`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error uploadTrackerTargetExcel", error?.response);
      setShowLoadingIconUpload(false);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}uploadTrackerTargetExcel`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  /* eslint-disable react-hooks/rules-of-hooks */
  const getTrackerUploadExcelTemplate = () => {
    setShowLoadingIcon(true);
    useFetch("/api/getTrackerUploadExcelTemplate", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack: (res) => {
        console.log("getTrackerUploadExcelTemplate", res);
        const excelData = Buffer.from(res.data?.excelBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, "Tracker upload template.xlsx");
        setShowLoadingIcon(false);
      },
    });
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Set Target</DialogTitle>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <p className="mb_15">
          You can have a filtered view of the partner on which you want to take target this month to get better view of
          the tracker dashboard
        </p>
        {/* <Button autoFocus={true} onClick={() => setOpen(false)}>
          Upload Target
        </Button> */}
        <div>
          <label
            id="file-js-example"
            className="field file has-name uploadSection center_align_ver_horiz"
            style={{ marginBottom: "0" }}
          >
            <input
              className="file-input"
              type="file"
              name="invoicefile"
              value={""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => uploadTrackerTargetExcel(e)}
              required={true}
              multiple={true}
              accept=".xlsx"
            />
            <div>
              <div className="center_align m_20">
                <span className="file-icon">
                  <i className="fas fa-upload" style={{ fontSize: "25px" }} />
                </span>
              </div>
              <div>
                <span
                  className="file-cta closingblc_input businesspartner_btn theme_btn"
                  style={{
                    minWidth: "300px",
                    justifyContent: "center",
                  }}
                >
                  {showLoadingIconUpload ? (
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                  ) : null}
                  <span className="file-label">Upload Target Partners</span>
                </span>
              </div>
            </div>
          </label>
        </div>
      </DialogContent>
      <DialogActions className="dialog_footer flex_end">
        <Button
          onClick={() => {
            getTrackerUploadExcelTemplate();
          }}
          className="theme_btn"
          disabled={showLoadingIcon}
        >
          {showLoadingIcon ? (
            <span className="file-icon">
              <i className="fas fa-circle-notch fa-spin" />
            </span>
          ) : null}
          Download Format
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TargetModalUploadError = ({
  open,
  setOpen,
  // setShowErrorAlert,
  // setApiErrorMsg,
  targetUploadErrorMsg,
  targetUploadExcelBase64,
}: {
  [k: string]: any;
}) => {
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  /* eslint-disable react-hooks/rules-of-hooks */
  const getTrackerUploadExcelError = () => {
    setShowLoadingIcon(true);
    const excelData = Buffer.from(targetUploadExcelBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Tracker upload error report.xlsx");
    setShowLoadingIcon(false);
  };

  return (
    <Dialog open={open} fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Target upload error</DialogTitle>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <p className="mb_15">{targetUploadErrorMsg}</p>
      </DialogContent>
      <DialogActions className="dialog_footer flex_end">
        <Button
          onClick={() => {
            getTrackerUploadExcelError();
          }}
          className="theme_btn"
        >
          {showLoadingIcon ? (
            <span className="file-icon">
              <i className="fas fa-circle-notch fa-spin" />
            </span>
          ) : null}
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconMIS;
