import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "../PartnerCommunication/LedgerRequestFilter.scss";
import { CheckboxGroup } from "./utils";

const TrackerLevelFilter = (props: any) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [partnerName, setPartnerName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [activityMonth, setActivityMonth] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [targetFilter, setTargetFilter] = useState({
    newTarget: false,
    spillover: false,
    other: false,
  });

  const handleClose = () => {
    props.setOpenTrackerLevelFilter(false);
  };

  const filterTrackerLevel = () => {
    let tempRowData = props.storeRowsDataTrackerLevel as any[];

    if (partnerName?.trim()) {
      const partnerNameWithoutMultiSpace = partnerName?.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace?.toLowerCase();
      props.setIsFilterAppliedTrackerLevelFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.businessPartnerName?.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (userEmail?.trim()) {
      const partnerNameWithoutMultiSpace = userEmail?.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace?.toLowerCase();
      props.setIsFilterAppliedTrackerLevelFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.userEmailsConcatenated?.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (activityMonth?.trim()) {
      const partnerNameWithoutMultiSpace = activityMonth?.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace?.toLowerCase();
      props.setIsFilterAppliedTrackerLevelFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.activityMonth?.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (comment?.trim()) {
      const partnerNameWithoutMultiSpace = comment?.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace?.toLowerCase();
      props.setIsFilterAppliedTrackerLevelFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.remarks?.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (status?.trim()) {
      props.setIsFilterAppliedTrackerLevelFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (item.statusDone && status === "Done") || (!item.statusDone && status === "Not done");
      });
    }

    if (targetFilter.newTarget || targetFilter.spillover || targetFilter.other) {
      const someData = [];
      if (targetFilter.newTarget)
        tempRowData?.forEach((item) => {
          if (item.targetType === "New Target") someData.push(item);
        });
      if (targetFilter.spillover)
        tempRowData?.forEach((item) => {
          if (item.targetType === "Spillover") someData.push(item);
        });
      if (targetFilter.other)
        tempRowData?.forEach((item) => {
          if (item.targetType === "Other") someData.push(item);
        });
      tempRowData = [...someData];
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedTrackerLevelFilter(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedCategory?.some((val) => item.category?.includes(val));
      });
    }
    props.setRowsDataTrackerLevel(tempRowData);
    handleClose();
  };

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setPartnerName("");
    setUserEmail("");
    setActivityMonth("");
    setComment("");
    setStatus("");
    setTargetFilter({ newTarget: false, spillover: false, other: false });

    handleClose();
    setTimeout(() => {
      props.setOpenTrackerLevelFilter(true);
      props.setRowsDataTrackerLevel(props.storeRowsDataTrackerLevel);
      props.setIsFilterAppliedTrackerLevelFilter(false);
    }, 700);
  };

  // Function to compare activityMonth values (e.g., "Aug 2022")
  function compareActivityMonths(monthA, monthB) {
    const [monthAName, yearA] = monthA.split(" ");
    const [monthBName, yearB] = monthB.split(" ");

    const monthsOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    if (yearA !== yearB) {
      return parseInt(yearB, 10) - parseInt(yearA, 10); // Specify radix 10
    } else {
      return monthsOrder.indexOf(monthBName) - monthsOrder.indexOf(monthAName);
    }
  }

  return (
    <div>
      <Dialog open={props.openTrackerLevelFilter} aria-labelledby="LedgerRequestFilter" className="ledgerRequestFilter">
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Status:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <FormControl>
                  <RadioGroup
                    row={true}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <FormControlLabel value="Done" control={<Radio />} label="Done" />
                    <FormControlLabel value="Not done" control={<Radio />} label="Not done" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Target Type :</div>
              <div className="w_400 category_textFeild_width_390">
                <CheckboxGroup
                  onChange={(_e, v) => {
                    setTargetFilter({
                      newTarget: v.newTarget,
                      spillover: v.spillover,
                      other: v.other,
                    });
                  }}
                  sx={{ flexFlow: "row" }}
                >
                  <FormControlLabel
                    control={<Checkbox checked={targetFilter.newTarget} name="newTarget" />}
                    label="New Target"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={targetFilter.spillover} name="spillover" />}
                    label="Spillover"
                  />
                  <FormControlLabel control={<Checkbox checked={targetFilter.other} name="other" />} label="Other" />
                </CheckboxGroup>
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(e: any, value: any) => {
                    console.log(e);
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories}
                  getOptionLabel={(option: any) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Activity Month:</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={[
                    ...new Set(
                      (props.storeRowsDataTrackerLevel || [])
                        .filter((option) => option?.activityMonth)
                        .map((option) => option.activityMonth)
                    ),
                  ].sort((a, b) => compareActivityMonths(b, a))}
                  value={activityMonth}
                  onChange={(event, value) => {
                    console.log(event);
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setActivityMonth(value);
                    } else setActivityMonth("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Activity Month"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">User email:</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={[
                    ...new Set(
                      (props.storeRowsDataTrackerLevel || [])
                        ?.filter((option) => option?.userEmailsConcatenated && option.userEmailsConcatenated !== "-")
                        ?.flatMap((option) => option.userEmailsConcatenated.split(",")) // Split concatenated emails
                        ?.map((email) => email.trim()) // Remove leading/trailing spaces
                    ),
                  ]}
                  value={userEmail}
                  onChange={(event, value) => {
                    console.log(event);
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setUserEmail(value);
                    } else setUserEmail("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter User email"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Name:</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={[...new Set(props.storeRowsDataTrackerLevel?.map((option) => option?.businessPartnerName))]}
                  value={partnerName}
                  onChange={(event, value) => {
                    console.log(event);
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setPartnerName(value);
                    } else setPartnerName("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Comments/ Remarks:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  //   className="mr_20"
                  label="Enter Comment"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={comment}
                  onChange={(e: any) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                filterTrackerLevel();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrackerLevelFilter;
