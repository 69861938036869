import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import ERPDialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useHistory } from "react-router";
import { amountEntryType, ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { Customized } from "../../Utils/Recon/Recon360/Constants";
import MonetaryInput from "../Common/MonetaryInput";
import { Dialog } from "../Dialog/Dialog";
import ApiErrorCatch from "./ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "./ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import LedgerPdfUpload from "./LedgerPdfUpload";
import "./LedgerPdfUpload.scss";
import "./Recon360.scss";
import ColumnHeaderConfig, { LedgerConfiguration } from "./Transformer/ColumnHeaderConfig";
import DocumentTypeConfig from "./Transformer/DocumentTypeConfig";

const LedgerUpload = (props: any) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [allRules, setAllRules] = useState([]);
  const [headerNameChanged, setChangedHeaderName] = useState({} as LedgerConfiguration);
  const [templateNameChanged, setChangedTemplateName] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [columnHeaderConfigDialog, setColumnHeaderConfigDialog] = useState<boolean>(true);
  const history = useHistory();
  const [accountingSign, setAccountingSign] = useState<string>("debit");
  const [balance, setBalance] = useState<number>((props.closingBalance as number) || 0);
  const [selectOpen, setSelectOpen] = useState<boolean>(true);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [openSelectTemplate, setOpenSelectTemplate] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState("Tally");
  const [selectTemplateData, setSelectTemplateData] = useState(null);
  const [showCustomOption, setShowCustomOption] = useState<boolean>(false);
  const [fromCreateCustomTemplate, setFromCreateCustomTemplate] = useState<boolean>(false);

  useEffect(() => {
    const handleRouterChange = () => {
      setOpenDialog(false);
      setColumnHeaderConfigDialog(true);
      setInputFocused(false);
      setSelectOpen(true);
    };

    const unregisterListener = history.listen(handleRouterChange);

    return () => {
      unregisterListener();
    };
  }, [history]);

  useEffect(() => {
    let bal = Number(props.closingBalance) || 0;
    const accTerm = props.accountingTerminology;

    if (accTerm === amountEntryType.debitCredit) {
      bal = bal >= 0 ? bal : -1 * bal;
    }

    setBalance(bal || 0);

    setAccountingSign(props.closingBalance >= 0 ? "debit" : "credit");
  }, [props.accountingTerminology, props.closingBalance]);

  const getDefaultConfigTemplate = () => {
    const selectedTemplate = props.configList?.find((template: any) => template.templateName === "Tally");
    const selectedTemplateId = selectedTemplate?.templateId;
    getConfigTemplate(selectedTemplateId);
  };

  const getConfigTemplate = async (id: number) => {
    try {
      await Axios.get<any>(`/api/ListLedgerConfigTemplate/?templateId=${id}`)
        .then((response) => {
          setSelectTemplateData(response.data?.ledgerConfigTemplate);
        })
        .catch((err) => {
          if (err?.response?.data?.message !== undefined) {
            const dataObj = err.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListLedgerConfigTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (err: any) {
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListLedgerConfigTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const handleChangeSelectTemplate = (event: any) => {
    setSelectTemplateData(null);
    setSelectedValue(event.target.value);
    if (event.target.value !== "Create Custom template") {
      const selectedTemplate = props.configList.find((template: any) => template.templateName === event.target.value);
      const selectedTemplateId = selectedTemplate.templateId;
      getConfigTemplate(selectedTemplateId);
    }
  };
  const handleCloseSelectTemplate = () => {
    setOpenSelectTemplate(false);
    setSelectedValue("Tally");
    setSelectTemplateData(null);
    setChangedTemplateName("");
  };
  const getConfig = async () => {
    if (!fromCreateCustomTemplate) {
      await props.getConfigResponse(props.businessPartnerSelected);
    }
  };
  const removeExistingTemplate = () => {
    // setChangedHeaderName({} as LedgerConfiguration)
    props.setHeaderName({});
    props.setSanitizeCol([]);
    setAllRules([]);
    props.setDebitAmountSignReverseFlag(false);
    props.setCreditAmountSignReverseFlag(false);
    props.setTemplateName("");
  };
  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
        showUploadWarningAlert={showWarningAlert}
      />
      <Grid container={true} className="center_align">
        <Grid className="box_height ">
          <Grid xs={12} md={12} className="center_align mt_20" style={{ marginBottom: "20px", marginLeft: "20px" }}>
            <div>
              {props.integration ? (
                <>
                  <Grid xs={12} md={12} className="center_align">
                    <Button variant="contained" className="min_width_300" disabled={true}>
                      Synced from ERP
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid xs={12} md={12} style={{ alignItems: "center" }} className="center_align">
                  <div className="closingblc_input theme_btn">
                    <label
                      id="file-js-example"
                      className="field file has-name cursor_pointer"
                      style={{ marginBottom: "0" }}
                      // style={{ marginTop: "-5%" }}
                    >
                      <input
                        disabled={props.loadingConfig || props.businessPartnerSelected === null}
                        className="file-input"
                        type="file"
                        name="invoicefile"
                        value={""}
                        onChange={props.excelUpload}
                        required={true}
                        multiple={true}
                        accept=".xlsx,.pdf,.xls"
                        onClick={() => {
                          props.guidCommonTimestamp.current = Date.now()?.toString();
                        }}
                      />
                      <span
                        className="file-cta closingblc_input theme_btn"
                        style={{
                          minWidth: "300px",
                          justifyContent: "center",
                        }}
                      >
                        {props.showLoadingIcon ? null : (
                          <span className="file-icon">
                            <i className="fas fa-upload" />
                          </span>
                        )}
                        {props.showLoadingIcon ? (
                          <span className="file-icon">
                            <i className="fas fa-circle-notch fa-spin" />
                          </span>
                        ) : null}
                        <span className="file-label">{props.uploadSectionName}</span>
                      </span>
                    </label>
                  </div>
                </Grid>
              )}
            </div>

            {props.newMapping ? (
              <Tooltip title="Configuration">
                {props.loadingConfig ? (
                  <span
                    id="ConfigBtn"
                    style={{
                      color: "rgb(90 90 90)",
                      width: "20px",
                      fontSize: "25px",
                      cursor: "pointer",
                      paddingLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : (
                  <span
                    key="edit"
                    id="ConfigBtn"
                    style={{
                      color: "rgb(90 90 90)",
                      width: "20px",
                      fontSize: "25px",
                      cursor: "pointer",
                      paddingLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      clarity.setTag(`${props.name === "Own Documents" ? "Own" : "BP"} Config`, `Open`);
                      setOpenDialog(true);
                      props.uiLogger(props.name === "Own Documents" ? "ui-OwnConfigOpen" : "ui-BpConfigOpen");
                    }}
                  >
                    <i className="fas fa-edit" />
                  </span>
                )}
              </Tooltip>
            ) : (
              <span
                key="add"
                style={{
                  color: "rgb(90 90 90))",
                  width: "20px",
                  fontSize: "25px",
                  cursor: "pointer",
                  paddingLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (
                    props.businessPartnerSelected !== null &&
                    (props.newMappingOwn || props.name === "Own Documents")
                  ) {
                    setChangedHeaderName({} as LedgerConfiguration);
                    setChangedTemplateName("");
                    // setOpenDialog(true);
                    setOpenSelectTemplate(true);
                    getDefaultConfigTemplate();
                  } else {
                    setShowWarningAlert(true);
                    if (props.businessPartnerSelected === null) {
                      setApiErrorMsg("Please select partner");
                    } else {
                      setApiErrorMsg("Please first create own config");
                    }
                    apiSuccessErrorAlertSetTimeout(setShowWarningAlert, setApiErrorMsg);
                  }
                }}
              >
                <i className="fas fa-plus-square" />
              </span>
            )}
          </Grid>
          {props.subTab === "Ledger" && (
            <Grid xs={12} md={12} className="center_align" style={{ marginBottom: "20px" }}>
              <Tooltip
                title={
                  props.ledgerUploadedOwn && props.ledgerUploadedBP
                    ? ""
                    : "Please upload the file on both sides before reconcile"
                }
              >
                <FormControl
                  style={{ minWidth: "300px" }}
                  className="closingblc_input"
                  disabled={props.ledgerUploadedOwn && props.ledgerUploadedBP ? false : true}
                >
                  {/* <InputLabel htmlFor="outlined-adornment-closingBalance">Closing Balance</InputLabel> */}
                  <MonetaryInput
                    key={balance}
                    id="outlined-adornment-closingBalance"
                    disabled={!props.ledgerUploadedOwn || !props.ledgerUploadedBP}
                    label="Closing Balance"
                    allowNegative={props.accountingTerminology !== amountEntryType.debitCredit}
                    value={balance}
                    setValue={(value: number) => {
                      if (props.accountingTerminology === amountEntryType.debitCredit) {
                        props.setClosingBalance(accountingSign === "debit" ? value : -1 * value);
                        props.setClosingBalanceRef.current = accountingSign === "debit" ? value : -1 * value;
                      } else {
                        props.setClosingBalance(value);
                        props.setClosingBalanceRef.current = value;
                      }
                    }}
                    onBlur={(_e, _v) => setInputFocused(true)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                      endAdornment:
                        props.accountingTerminology === amountEntryType.debitCredit ? (
                          <FormControl sx={{ right: 16 }}>
                            <Select
                              labelId="accTerminology"
                              value={accountingSign}
                              open={selectOpen && inputFocused}
                              onClose={() => setSelectOpen(false)}
                              onOpen={() => setSelectOpen(true)}
                              onChange={(e) => {
                                const validBalance = props.closingBalance;

                                setAccountingSign(e.target.value);

                                if (e.target.value === "debit") {
                                  props.setClosingBalance(validBalance >= 0 ? validBalance : -1 * validBalance);
                                  props.setClosingBalanceRef.current =
                                    validBalance >= 0 ? validBalance : -1 * validBalance;
                                } else {
                                  props.setClosingBalance(validBalance <= 0 ? validBalance : -1 * validBalance);
                                  props.setClosingBalanceRef.current =
                                    validBalance <= 0 ? validBalance : -1 * validBalance;
                                }
                              }}
                              sx={{
                                minWidth: "90px",
                                "& > fieldset": {
                                  borderColor: "transparent !important",
                                },
                              }}
                            >
                              <MenuItem value={"debit"}>Debit</MenuItem>
                              <MenuItem value={"credit"}>Credit</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <></>
                        ),
                    }}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          )}
          {props.dateSyncFromSAP?.oldestEntry &&
          props.dateSyncFromSAP?.latestEntry &&
          props.dateSyncFromSAP?.lastTimeToSync &&
          props.businessPartnerSelected !== null ? (
            <>
              <Grid xs={12} md={12} className="min_width_300 pt_10 pb_5 text_center">
                <p className="date_text">Oldest entry - {props.dateSyncFromSAP.oldestEntry}</p>
              </Grid>
              <Grid xs={12} md={12} className="min_width_300 pt_5 pb_5 date_text text_center">
                <p className="date_text">Latest entry - {props.dateSyncFromSAP.latestEntry}</p>
              </Grid>
              {props.dateSyncFromSAP?.overallEnteries && props.dateSyncFromSAP?.overallEnteries !== null && (
                <Grid xs={12} md={12} className="min_width_300 pt_5 pb_5 date_text text_center">
                  <p className="date_text">Total entries - {props.dateSyncFromSAP?.overallEnteries}</p>
                </Grid>
              )}
              <Grid xs={12} md={12} className="min_width_300 pt_5 pb_10 date_text text_center">
                <p className="date_text">Last sync - {props.dateSyncFromSAP.lastTimeToSync}</p>
              </Grid>
            </>
          ) : null}
          {props.dateSyncFromSAP?.oldestEntry === null &&
          props.dateSyncFromSAP?.latestEntry === null &&
          props.dateSyncFromSAP?.lastTimeToSync === null &&
          props.businessPartnerSelected !== null ? (
            <Grid className="no_ledger_entries">No ledger entries found in database</Grid>
          ) : null}
          <Grid xs={12} className="center_align" style={{ height: "15px" }}>
            {props.uploadedLastModifiedDate !== null ? (
              <p className="date_text">Last update : {props.uploadedLastModifiedDate}</p>
            ) : null}
          </Grid>
          <Grid xs={12} className="center_align" style={{ height: "15px" }}>
            {props.uploadedFileName !== null ? (
              <>
                <Tooltip
                  title={props.uploadedFileName?.map((fileItem: string, fileInd: number) => {
                    return (
                      <div key={fileInd} className="date_text">
                        {fileItem}
                      </div>
                    );
                  })}
                >
                  <p className="date_text cursor_pointer">
                    File name:{" "}
                    {props.uploadedFileName[0]?.substring(0, 16) +
                      (props.uploadedFileName[0]?.length > 15 ? "..." : "")}
                    {props.uploadedFileName?.length > 1 && (
                      <span className="date_text"> + {props.uploadedFileName?.length - 1}</span>
                    )}
                  </p>
                </Tooltip>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(true)}>
        {columnHeaderConfigDialog ? (
          <ColumnHeaderConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={props.newMapping ? props.headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={props.setHeaderName}
            rules={props.rulesArray}
            templateName={props.newMapping ? props.templateName : templateNameChanged || ""}
            sanitizeCol={props.sanitizeCol}
            template={true}
            newMapping={props.newMapping}
            setNewMapping={props.setNewMapping}
            heading="Column header mapping"
            openNextDialog={(value: any, header: LedgerConfiguration, template: string) => {
              setAllRules(value);
              props.setSanitizeCol(value);
              setChangedHeaderName(header);
              setChangedTemplateName(template);
              setColumnHeaderConfigDialog(false);
            }}
            showSettingsIcon={true}
            setOpenDialog={setOpenDialog}
            specificConfig={props.specificConfig}
            setSpecificConfig={props.setSpecificConfig}
            disableSpecificConfigCheckbox={props.disableSpecificConfigCheckbox}
            configList={props.configList}
            debitAmountSignReverseFlag={props.debitAmountSignReverseFlag}
            setDebitAmountSignReverseFlag={props.setDebitAmountSignReverseFlag}
            creditAmountSignReverseFlag={props.creditAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={props.setCreditAmountSignReverseFlag}
            subTab={props.subTab}
            // setShowErrorAlert={setShowErrorAlert}
            // setApiErrorMsg={setApiErrorMsg}
            saveRulesApi={(header: string) => {
              console.log("src header save", header);

              props.saveRules(header, templateNameChanged || props.templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            name={props.name}
            getConfig={getConfig}
            showCustomOption={showCustomOption}
            setShowCustomOption={setShowCustomOption}
            removeExistingTemplate={removeExistingTemplate}
            integration={props.integration}
            uiLogger={(functionName: string) => props.uiLogger(functionName)}
          />
        ) : (
          <DocumentTypeConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={props.newMapping ? props.headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={props.setHeaderName}
            name={props.name}
            heading="Document Type Key"
            backTemplate={() => {
              setColumnHeaderConfigDialog(true);
            }}
            setOpenDialog={setOpenDialog}
            saveRulesApi={(header: string) => {
              props.saveRules(header, templateNameChanged || props.templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            setDebitAmountSignReverseFlag={props.setDebitAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={props.setCreditAmountSignReverseFlag}
            subTab={props.subTab}
            specificConfig={props.specificConfig}
            setSpecificConfig={props.setSpecificConfig}
            disableSpecificConfigCheckbox={props.disableSpecificConfigCheckbox}
            getConfig={getConfig}
            setShowCustomOption={setShowCustomOption}
            setTemplateName={props.setTemplateName}
          />
        )}
      </Dialog>
      <div className="pdfUpload">
        <Dialog open={props.openPdfDialog} onClose={() => props.setOpenPdfDialog(true)} width70Per={true}>
          <LedgerPdfUpload
            pdfTableData={props.pdfTableData}
            setOpenPdfDialog={props.setOpenPdfDialog}
            setUploadedFileName={props.setUploadedFileName}
            setUploadedLastModifiedDate={props.setUploadedLastModifiedDate}
            setShowSuccessAlert={setShowSuccessAlert}
            setShowErrorAlert={setShowErrorAlert}
            setShowWarningAlert={setShowWarningAlert}
            setApiErrorMsg={setApiErrorMsg}
            integration={props.integration}
            businessPartnerAccountingSoftware={props.businessPartnerAccountingSoftware}
            businessPartnerSelectedCompanyId={props.businessPartnerSelectedCompanyId}
            getOwnDateSyncFromSAP={props.getOwnDateSyncFromSAP}
            getOwnDataSyncFromTally={props.getOwnDataSyncFromTally}
            getOwnDataGetReconMetaData={props.getOwnDataGetReconMetaData}
            businessPartnerSelected={props.businessPartnerSelected}
            getBusinessPartnerDateSyncFromSAP={props.getBusinessPartnerDateSyncFromSAP}
            getBusinessPartnerDataSyncFromTally={props.getBusinessPartnerDataSyncFromTally}
            getBusinessPartnerDateGetReconMetaData={props.getBusinessPartnerDateGetReconMetaData}
            headerName={props.headerName}
            setColumnHeaderArr={props.setColumnHeaderArr}
            setConfigUserType={props.setConfigUserType}
            setConfigUserName={props.setConfigUserName}
            setOpenColumnHeaderDialog={props.setOpenColumnHeaderDialog}
            setDocumentTypesArr={props.setDocumentTypesArr}
            assignBusinessPartnerName={props.assignBusinessPartnerName}
            // setFinaldocumentTypesObj={props.setFinaldocumentTypesObj}
            finaldocumentTypesObj={props.finaldocumentTypesObj}
            setOpenDocumentTypeDialog={props.setOpenDocumentTypeDialog}
            setShowUploadedSummary={props.setShowUploadedSummary}
            setShowUploadedSummaryObject={props.setShowUploadedSummaryObject}
            setEndDateOwn={props.setEndDateOwn}
            setEndDateBusinessPartner={props.setEndDateBusinessPartner}
            selectedPdfConverter={props.selectedPdfConverter}
            isPdfOptionConfirm={props.isPdfOptionConfirm}
            onChangePdfConversionMethod={props.onChangePdfConversionMethod}
            disablePdfTableOptionForMultiplePdf={props.disablePdfTableOptionForMultiplePdf}
            selectedBusinessPartnerName={props.selectedBusinessPartnerName}
            downloadUploadedPdfName={props.downloadUploadedPdfName}
            pdfConversionLoader={props.pdfConversionLoader}
            setPdfConversionLoader={props.setPdfConversionLoader}
            subTab={props.subTab}
          />
        </Dialog>
      </div>
      <ERPDialog open={openSelectTemplate} disableEnforceFocus={true}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Select ERP template</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleCloseSelectTemplate()} />
        </DialogTitle>
        <DialogContent>
          <div className="mt_20">
            <FormControl>
              <Select
                labelId="my-select-label"
                id="my-select"
                value={selectedValue}
                onChange={handleChangeSelectTemplate}
                defaultValue="Tally"
                size="small"
                className="mw_250"
              >
                {props.configList?.map((option: any) => (
                  <MenuItem key={option.templateId} value={option.templateName}>
                    {option.templateName}
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => setChangedTemplateName(Customized)}
                  key="new-option"
                  value="Create Custom template"
                  style={{ border: "1px solid" }}
                >
                  Create Custom template
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div>
            {selectedValue === "Create Custom template" ? (
              <Button
                className="theme_btn"
                onClick={() => {
                  setFromCreateCustomTemplate(true);
                  handleCloseSelectTemplate();
                  setOpenDialog(true);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                className="theme_btn"
                onClick={() => {
                  props.saveRules(
                    selectTemplateData?.config,
                    selectTemplateData?.templateName,
                    selectTemplateData?.columnsToSanitize
                  );
                  handleCloseSelectTemplate();
                }}
                disabled={selectTemplateData ? false : true}
              >
                Save
              </Button>
            )}
          </div>
        </DialogActions>
      </ERPDialog>
    </>
  );
};

export default LedgerUpload;
