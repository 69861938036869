import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React, { useContext, useState } from "react";
import { ActorMappings, BankAnchorMapping } from "../../entity/ActorMappings";
import Loader from "../Common/Loader";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getBankTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
// import "./Settings.scss";

export interface ListAllActorMappingsResponse {
  actorMappings: ActorMappings[];
}

export const BankSettings = () => {
  const { actor } = useContext(userContext);
  const [mappingData, setMappingData] = useState({ actorMappings: [] } as ListAllActorMappingsResponse);
  const columnDefs = [
    { headerName: "Anchor Name", field: "anchor", minWidth: 200 },
    { headerName: "Interest Rate", field: "interest_rate", minWidth: 200 },
    { headerName: "LTV Ratio", field: "ltv", minWidth: 200 },
  ];

  const onGridReady = async () => {
    const { data } = await axios.get<ListAllActorMappingsResponse>("/api/ListAllActorMappings", {
      params: {
        parentId: actor.id,
      },
    });
    console.log(data);
    setMappingData(data);
  };
  const getrowdata = () => {
    return mappingData.actorMappings.map((mdata) => {
      return {
        anchor: mdata.child.actorInfo.name,
        interest_rate: (mdata.mappingData as BankAnchorMapping).interestRate,
        ltv: (mdata.mappingData as BankAnchorMapping).ltvRatio,
      };
    });
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  };

  /*useEffect(() => {
    async function mappings() {
      const { data } = await axios.get<ListAllActorMappingsResponse>("/api/ListAllActorMappings");
      console.log(data);
      setMappingData(data.actorMappings);
    }
    mappings();
    console.log(mappingData);
  }, []);
  const bankSettings = () => {
    return (
      <div className="container">
        <div className="columns " style={{ width: "60%" }}>
          <div className="column is-two-thirds bold">Anchor Name</div>
          <div className="column bold">
            Interest Rate <br />
            (%)
          </div>
        </div>
        {mappingData &&
          mappingData.actorMappings.map((mdata) => (
            <div key={mdata.childId} className="columns margin" style={{ width: "60%" }}>
              <div className="column is-two-thirds background-blue">{mdata.child.actorInfo.name}</div>
              <div className="column background-white">{(mdata.mappingData as BankAnchorMapping).interestRate}</div>
            </div>
          ))}
      </div>
    );
  };*/

  return (
    <LoggedInSkeleton topBarButtons={getBankTopBarButtons("Settings")}>
      <div className="ag-theme-alpine mygrid">
        {mappingData.actorMappings.length === 0 && <Loader />}
        <AgGridReact
          enableCellTextSelection={true}
          suppressMenuHide={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowData={getrowdata()}
          domLayout="autoHeight"
        />
      </div>
    </LoggedInSkeleton>
  );
};
