import { CodesWithSign } from "../../../entity/recon-entity/ReconInterfaces";

export const insightsMetricName = {
  MISSING_DEBIT_EXTRA_CREDIT: "Missing debit/Extra credit", // both ru and bp
  DEBIT_NOTE_MISSED_BY_SUPPLIER: "Debit note missed by supplier", // ru
  INCORRECT_AMOUNT: "Incorrect amount", // both ru and bp
  POTENTIAL_MISSED_INVOICES: "Potential missed invoices", // bp
  INVOICE_MISSED_BY_BUYER: "Invoices missed by buyer", // ru
  POTENTIAL_MISSED_DEBIT_NOTES: "Potential missed Debit Notes", // bp
  TDS_VALUE_GAP: "TDS value GAP", // both ru and bp
  NET_INVOICE_VALUE_RECONCILED: "Net Invoice value reconciled", // ru
  MATCH_PERCENTAGE: "Match %",
  NUM_RECONCILIATION_DONE: "# of reconciliation done",
};
export const insightsMetricNameUpdated = {
  MISSING_DEBIT_EXTRA_CREDIT: "Missing Debit/Extra Credit", // both ru and bp
  DEBIT_NOTE_MISSED_BY_SUPPLIER: "Debit Note missed by Supplier", // ru
  INCORRECT_AMOUNT: "Incorrect Amount", // both ru and bp
  POTENTIAL_MISSED_INVOICES: "Potential Missed Invoices", // bp
  INVOICE_MISSED_BY_BUYER: "Invoice missed by Buyer", // ru
  POTENTIAL_MISSED_DEBIT_NOTES: "Potential missed Debit Notes", // bp
  TDS_VALUE_GAP: "TDS Value Gap", // both ru and bp
  NET_INVOICE_VALUE_RECONCILED: "Net Invoice Value Reconciled", // ru
  MATCH_PERCENTAGE: "Match %",
  NUM_RECONCILIATION_DONE: "# of Reconciliation done",
};

export const metricNameMapping: StringUpdate = {
  [insightsMetricName?.MISSING_DEBIT_EXTRA_CREDIT]: insightsMetricNameUpdated?.MISSING_DEBIT_EXTRA_CREDIT,
  [insightsMetricName?.DEBIT_NOTE_MISSED_BY_SUPPLIER]: insightsMetricNameUpdated?.DEBIT_NOTE_MISSED_BY_SUPPLIER,
  [insightsMetricName?.INCORRECT_AMOUNT]: insightsMetricNameUpdated?.INCORRECT_AMOUNT,
  [insightsMetricName?.POTENTIAL_MISSED_INVOICES]: insightsMetricNameUpdated?.POTENTIAL_MISSED_INVOICES,
  [insightsMetricName?.INVOICE_MISSED_BY_BUYER]: insightsMetricNameUpdated?.INVOICE_MISSED_BY_BUYER,
  [insightsMetricName?.POTENTIAL_MISSED_DEBIT_NOTES]: insightsMetricNameUpdated?.POTENTIAL_MISSED_DEBIT_NOTES,
  [insightsMetricName?.TDS_VALUE_GAP]: insightsMetricNameUpdated?.TDS_VALUE_GAP,
  [insightsMetricName?.NET_INVOICE_VALUE_RECONCILED]: insightsMetricNameUpdated?.NET_INVOICE_VALUE_RECONCILED,
  [insightsMetricName?.MATCH_PERCENTAGE]: insightsMetricNameUpdated?.MATCH_PERCENTAGE,
  [insightsMetricName?.NUM_RECONCILIATION_DONE]: insightsMetricNameUpdated.NUM_RECONCILIATION_DONE,
};

export const transFormMetricName = (value: string): string => {
  const uniqueMetricName = metricNameMapping[value];
  if (!uniqueMetricName) {
    return value;
  }
  return uniqueMetricName;
};

export const columnHeaderNameLabel = {
  documentType: "documentType",
  documentDate: "documentDate",
  postingDate: "postingDate",
  referenceNumber: "referenceNumber",
  otherUniqueId: "otherUniqueId",
  debitAmount: "debitAmount",
  creditAmount: "creditAmount",
  paymentId: "paymentId",
  particulars: "particulars",
  dncnId: "dncnId",
  invoiceIdForDncn: "invoiceIdForDncn",
  documentNumber: "documentNumber",
  clearingDocument: "clearingDocument",
  clearingDate: "clearingDate",
};
export const uniqueColumnHeader = {
  OtherUniqueId: "Other Unique ID",
  DocType: "Document Identifier",
  DocumentDate: "Date",
  ReferenceNumber: "Invoice Number",
  PostingDate: "Posting Date",
  DebitAmount: "Debit Amount",
  CreditAmount: "Credit Amount",
  PaymentId: "Payment ID",
  Particulars: "Particulars",
  DNCNId: "Dncn ID",
  InvoiceIdForDncn: "Inv ID for DN/CN",
  DocumentNumber: "Document Number",
  ClearingDocument: "Clearing Document",
  ClearingDate: "Clearing Date",
};
type StringUpdate = Record<string, string>;
export const columnHeaderMapping: StringUpdate = {
  [columnHeaderNameLabel.otherUniqueId]: uniqueColumnHeader.OtherUniqueId,
  [columnHeaderNameLabel.documentType]: uniqueColumnHeader.DocType,
  [columnHeaderNameLabel.documentDate]: uniqueColumnHeader.DocumentDate,
  [columnHeaderNameLabel.referenceNumber]: uniqueColumnHeader.ReferenceNumber,
  [columnHeaderNameLabel.postingDate]: uniqueColumnHeader.PostingDate,
  [columnHeaderNameLabel.debitAmount]: uniqueColumnHeader.DebitAmount,
  [columnHeaderNameLabel.creditAmount]: uniqueColumnHeader.CreditAmount,
  [columnHeaderNameLabel.paymentId]: uniqueColumnHeader.PaymentId,
  [columnHeaderNameLabel.particulars]: uniqueColumnHeader.Particulars,
  [columnHeaderNameLabel.dncnId]: uniqueColumnHeader.DNCNId,
  [columnHeaderNameLabel.invoiceIdForDncn]: uniqueColumnHeader.InvoiceIdForDncn,
  [columnHeaderNameLabel.documentNumber]: uniqueColumnHeader.DocumentNumber,
  [columnHeaderNameLabel.clearingDocument]: uniqueColumnHeader.ClearingDocument,
  [columnHeaderNameLabel.clearingDate]: uniqueColumnHeader.ClearingDate,
};
export const transFormColumnHeader = (value: string): string => {
  const uniqueHeader = columnHeaderMapping[value];
  if (!uniqueHeader) {
    return value;
  }
  return uniqueHeader;
};

export const DOCUMENT_TYPE_NAMES = ["invoice", "payment", "debitAndCreditNotes", "reversal", "tds", "interDocument"];

export const DOCUMENT_TYPES = ["Invoice", "Payment", "DNCN", "Reversal", "TDS", "Internal Document"];

// subTab Constants
export const RECON360_SUB_TAB = {
  LEDGER: "Ledger",
  PAYMENT_ADVICE: "Payment Advice",
};

export const Customized = "Customized";
export const EMPTY_CONFIG = {
  documentType: [] as string[],
  documentDate: [] as string[],
  postingDate: [] as string[],
  referenceNumber: [] as string[],
  otherUniqueId: [] as string[],
  debitAmount: [] as string[],
  creditAmount: [] as string[],
  paymentId: [] as string[],
  particulars: [] as string[],
  dncnId: [] as string[],
  invoiceIdForDncn: [] as string[],
  documentNumber: [] as string[],
  clearingDocument: [] as string[],
  clearingDate: [] as string[],
  invoice: [] as CodesWithSign[],
  payment: [] as CodesWithSign[],
  debitAndCreditNotes: [] as CodesWithSign[],
  reversal: [] as CodesWithSign[],
  tds: [] as CodesWithSign[],
  interDocument: [] as CodesWithSign[],
};

/**
 * `total` is a key returned in uploadSummary Object.
 *
 * This Contains Calculated Total Summary for all the uploaded files,
 * which is being ignored while extracting uploaded file names.
 */
export const UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY = "total";

export const reconciliationStatus = {
  invoiceAmountMismatch: "Invoice amount mismatch",
  invoiceNotPresentInPartnerLedger: "Invoice not present in Partner Ledger",
  invoiceNotPresentInOwnLedger: "Invoice not present in Own Ledger",
  paymentAmountMismatch: "Payment amount mismatch",
  paymentNotPresentInPartnerLedger: "Payment not present in Partner Ledger",
  paymentNotPresentInOwnLedger: "Payment not present in Own Ledger",
  debitNoteCreditNoteAmountMismatch: "Debit Note/Credit Note amount mismatch",
  dnCnNotPresentInPartnerLedger: "DN/CN not present in Partner Ledger",
  dnCnNotPresentInOwnLedger: "DN/CN not present in Own Ledger",
  tdsTcsNotPresentInPartnerLedger: "TDS/TCS not present in Partner Ledger",
  tdsTcsNotPresentInOwnLedger: "TDS/TCS not present in Own Ledger",
  tdsAmountMismatch: "TDS amount mismatch",
  miscellaneousEntriesInOwnSOA: "Miscellaneous entries in own SOA",
  miscellaneousEntriesInBusinessPartnersSOA: "Miscellaneous entries in business partner's SOA",
};

export const summaryTypeSelectedStatus = {
  legacy: "legacy",
  ledgerPlusGaps: "Ledger + Gaps",
  both: "Both",
};

export const manualReconTypeName = {
  UNRECONCILE: "UNRECONCILE",
  RECONCILE: "RECONCILE",
};
export const manualReconStatusName = {
  manuallyUnreconciled: "Manually Unreconciled",
  manuallyReconciled: "Manually Reconciled",
};

export const gapAccountedAsName = {
  roundingError: "roundingError",
  mismatchOthers: "mismatchOthers",
  tds: "Tds",
};
