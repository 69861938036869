import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { DineroObject } from "dinero.js";
import { Row, Workbook, Worksheet } from "exceljs";
import { ReactNode, useCallback, useContext } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ExternalDocTemplate } from "../../entity/ExternalDocTemplate";
import { FrontendActor } from "../../entity/models/FrontendActor";
import { Tier1Invoice } from "../../entity/Tier1Invoice";
import { findTier1IdByName, toDineroObject, validateDateType } from "../../Utils/BulkUploadUtility";
import { dateValueFormatter, formatDate } from "../../Utils/DateUtils";
import { currencyValueFormatter, formatMoney } from "../../Utils/MoneyUtils";
import { InvoiceFlowChecksForAnchorInvoicesBulkUploads } from "../Common/InvoiceFlowChecksForAnchorInvoicesBulkUploads";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import { Dialog } from "../Dialog/Dialog";
import "../Tier2Upload/Tier2BulkUpload.scss";

export interface ListExternalDocTemplateResponse {
  templates: ExternalDocTemplate[];
}

export interface Tier1InvoiceResponse {
  invoice: Tier1Invoice;
}

export interface ListTier1InvoicesResponse {
  invoices: Tier1Invoice[];
}

export interface ListTier2CustomersResponse {
  results: Tier2Customer[];
}

export interface Tier2Customer {
  customerActor: FrontendActor;
}

/**
 * This is a utility interface which extracts all the fields our system needs to have for a sufficient representation of a invoice.
 */
export interface AnchorInvoiceRow {
  invoiceNumber: string;
  supplier: string;
  invoiceDate: Date;
  invoiceAmount: DineroObject;
  supplierId?: number;
}
/**
 * This is utility interface for the column header mapping tool.
 */
interface HeadersName {
  [key: string]: any;
  templateName: string;
  invoiceNumber: string;
  invoiceAmount: string;
  invoiceDate: string;
  supplier: string;
}
type Fields = keyof AnchorInvoiceRow;

interface MyColDef {
  headerName: string;
  field: Fields;
  valueFormatter?: (params: ValueFormatterParams) => string;
}
/**
 * This is used to separate all the pre-existing invoices in our system by the state they are in.
 */
export interface InvoicesTypes {
  discountedInvoices: Tier1Invoice[];
  nonDiscountedInvoices: Tier1Invoice[];
}
/**
 * @Component
 * The anchor bulk upload tool allows the user to upload multitudes of invoices at one go. It accepts an excel file which contain the details of invoices as input. It also checks for the different types of error in the same file and parses the database to account for any already existing invoices.
 */
export const AnchorBulkUpload = () => {
  const location = useLocation<any>();
  const [anchorInvoicesRows, setAnchorInvoiceRows] = useState<AnchorInvoiceRow[]>([]);
  const [excelFileErrors, setExcelFileErrors] = useState<string[]>([]);
  const isFirstRun = useRef(true);
  const [excelUploadClicked, setExcelUploadClicked] = useState<boolean>(true);
  // const [anchorInvoicesCheck, setAnchorInvoicesCheck] = useState<AnchorInvoice[]>([])
  const [anchorInvoices, setAnchorInvoices] = useState<Tier1Invoice[]>([]);
  const [modalState, setModalState] = useState<number>(-1);
  const [invoiceCheck, setInvoiceCheck] = useState<boolean>(false);
  const [allDuplicatesinvoicesIds, setAllDuplicateInvoiceIds] = useState<string[]>([]);

  const [anchorInvoicesCheck, setAnchorInvoicesCheck] = useState<InvoicesTypes>({
    discountedInvoices: [],
    nonDiscountedInvoices: [],
  });
  const [uploading, setUploading] = useState<boolean>(false);
  const history = useHistory();
  const { actor } = useContext(userContext);
  const [customers, setCustomers] = useState<Tier2Customer[]>([]);
  const [columnHeaderError, setColumnHeaderError] = useState<boolean>(false);
  // Anchor Transpose
  const nakadFormat: {
    [key: string]: { name: any };
  } = {
    InvoiceNumber: { name: "InvoiceNumber" },
    InvoiceAmount: { name: "InvoiceAmount" },
    InvoiceDate: { name: "InvoiceDate" },
    Supplier: { name: "Supplier" },
  };
  const [customFormat, setCustomFormat] = useState<ExternalDocTemplate[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<{
    [key: string]: { name: any };
  }>(nakadFormat);

  const updateCustomFormat = useCallback(async (): Promise<number> => {
    const { data } = await axios.get<ListExternalDocTemplateResponse>("/api/ListExernalDocTemplates");
    console.log(data);
    setCustomFormat(data.templates);
    return data.templates.length > 0 && data.templates[data.templates.length - 1].templateId;
  }, []);

  useEffect(() => {
    axios
      .get<ListTier2CustomersResponse>("/api/ListTier2Customers", {
        params: {
          anchorId: actor.id,
        },
      })
      .then((response) => {
        setCustomers(response.data.results);
        console.log("Customers", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    updateCustomFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomFormat]);

  const excelFileUploadOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcelFileErrors([]);
    const file = event.target.files[0];
    const workbook = new Workbook();
    const buffer = await file.arrayBuffer();
    await workbook.xlsx.load(buffer);
    (window as any).xxx = workbook;
    const worksheet = workbook.worksheets[0];
    const { rowIndex, mappingColumnHeaderToColumnIndex } = setColumnHeaderNameWithColumnIndex(worksheet);
    console.log("Row Index, Total rows", rowIndex, worksheet.rowCount);
    const count = assertColumnHeaders(mappingColumnHeaderToColumnIndex);
    if (count > 0) {
      setColumnHeaderError(true);
      return;
    } else {
      setColumnHeaderError(false);
    }

    const inv = worksheet.getRows(rowIndex + 1, worksheet.actualRowCount);
    console.log("Inv", inv);
    const invoicesRows = worksheet
      .getRows(rowIndex + 1, worksheet.rowCount - rowIndex)
      // left this comment purposely - might need this.
      .filter((r) => {
        return (
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.InvoiceNumber.name)).text.trim() !== "" ||
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.InvoiceAmount.name)).text.trim() !== "" ||
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.Supplier.name)).text.trim() !== "" ||
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.InvoiceDate.name)).text.trim() !== ""
        );
      })
      .map((row) => rowToAnchorInvoiceRow(row, mappingColumnHeaderToColumnIndex));
    console.log("InvoiceRows", invoicesRows);

    const nonDuplicates = eliminateDuplicateRows(invoicesRows);
    validateMandatoryInvoiceFields(nonDuplicates);
    checkForErrorsInRowsWithSameInvoiceId(nonDuplicates);
    setAnchorInvoiceRows(nonDuplicates);
    setExcelUploadClicked(!excelUploadClicked);
    setAllDuplicateInvoiceIds([]);
  };
  /**
   * This method maps the name and index of the column header in a excel. It also returns the index of first non empty row in the excel file.
   * @param worksheet {Worksheet}
   *
   */
  const eliminateDuplicateRows = (invoiceRows: AnchorInvoiceRow[]) => {
    const nonDuplicateRows = [];
    for (let i = 0; i < invoiceRows.length; i++) {
      let booleanCheck = false;
      for (let j = 0; j < i; j++) {
        if (
          invoiceRows[j].invoiceNumber === invoiceRows[i].invoiceNumber &&
          invoiceRows[j].supplier === invoiceRows[i].supplier &&
          formatDate(invoiceRows[j].invoiceDate) === formatDate(invoiceRows[i].invoiceDate) &&
          formatMoney(invoiceRows[j].invoiceAmount) === formatMoney(invoiceRows[i].invoiceAmount)
        ) {
          console.log("Check ELimiation", i, j);
          booleanCheck = true;
          break;
        }
      }
      if (!booleanCheck) {
        nonDuplicateRows.push(invoiceRows[i]);
      }
    }
    return nonDuplicateRows;
  };
  const checkForErrorsInRowsWithSameInvoiceId = (invoiceRows: AnchorInvoiceRow[]) => {
    for (let i = 0; i < invoiceRows.length; i++) {
      for (let j = 0; j < i; j++) {
        if (invoiceRows[j].invoiceNumber === invoiceRows[i].invoiceNumber) {
          setExcelFileErrors((currentErrors) => {
            const errors = [...currentErrors];
            errors.push(
              `Row Number ${j + 1} and ${i + 1} have the same invoice id - ${
                invoiceRows[j].invoiceNumber
              } - but different other fields. Kindly change the invoice ids or make it uniform. `
            );
            return errors;
          });
        }
      }
    }
  };
  const setColumnHeaderNameWithColumnIndex = (worksheet: Worksheet) => {
    const mappingColumnHeaderToColumnIndex = new Map<string, number>();
    let rowIndex = 0;
    for (let i = 1; i < 101; i++) {
      let checkBoolean = false;
      for (let j = 1; j < 101; j++) {
        const columnHeading = worksheet.getRow(i).getCell(j).text.trim();
        if (
          columnHeading === selectedFormat.InvoiceDate.name ||
          columnHeading === selectedFormat.InvoiceNumber.name ||
          columnHeading === selectedFormat.Supplier.name ||
          columnHeading === selectedFormat.InvoiceAmount.name
        ) {
          checkBoolean = true;

          mappingColumnHeaderToColumnIndex.set(columnHeading, j);
          rowIndex = i;
        }
      }
      if (checkBoolean) break;
    }
    console.log("Column Header To Column Index", mappingColumnHeaderToColumnIndex);

    return { rowIndex, mappingColumnHeaderToColumnIndex };
  };
  /**
   *   This method check whether any intentional invoice already exist in our system. It does so by InvoiceId, Tier1Id, AnchorId as its parameters. This method also separates the invoices depending upon the state (pending,approved,rejected) which they are in our system.
   * @public
   */
  const check2 = async () => {
    const alreadyUploadedInvoices = await getAlreadyUploadedInvoices(anchorInvoicesRows);

    if (alreadyUploadedInvoices.length > 0) {
      const filteredNonDiscountedAnchorInvoices = alreadyUploadedInvoices.filter(
        (inv) => inv.tier1Tier2InvoiceMappings.length === 0 || inv.isRejected === true
      );
      const filteredDiscountedAnchorInvoices = alreadyUploadedInvoices.filter(
        (inv) =>
          inv.tier1Tier2InvoiceMappings.length > 0 &&
          (inv.tier1Tier2InvoiceMappings[0].bankApprovalStatus === "Pending" ||
            inv.tier1Tier2InvoiceMappings[0].bankApprovalStatus === "Approved")
      );
      setAnchorInvoicesCheck({
        discountedInvoices: filteredDiscountedAnchorInvoices,
        nonDiscountedInvoices: filteredNonDiscountedAnchorInvoices,
      });
      setAnchorInvoices(alreadyUploadedInvoices);
      setModalState(1);
      setInvoiceCheck(true);

      //  setExcelFileErrors([]);
      return;
    }
  };
  if (modalState === 0) {
    setAnchorInvoiceRows((rows) => {
      const updatedRows = [...rows];
      console.log("UPDATED ROWS", updatedRows);
      console.log("Duplicate Ids to be deleted", allDuplicatesinvoicesIds);
      return updatedRows.filter((row) => !allDuplicatesinvoicesIds.includes(row.invoiceNumber));
    });
    setModalState(-1);
    setInvoiceCheck(false);
  }
  /**
   * This method is called after check2(duplicate invoices). It allows the user to decide between the old invoice or new invoice.
   * @param ids  {string[]} Invoice Ids of the duplicate invoices.
   *
   */
  const updateDuplicateInvoices = (ids: string[]) => {
    if (modalState === 1) {
      setAnchorInvoicesCheck({
        ...anchorInvoicesCheck,
        nonDiscountedInvoices: [],
      });
      setAllDuplicateInvoiceIds((duplicateIds) => {
        const allIds = [...duplicateIds];
        for (const id of ids) {
          allIds.push(id);
        }
        return allIds;
      });

      setModalState(() => {
        return anchorInvoicesCheck.discountedInvoices.length > 0 ? 2 : 0;
      });
    }

    if (modalState === 2) {
      console.log("Discounted Invoices Ids", ids);
      setAnchorInvoicesCheck({
        ...anchorInvoicesCheck,
        discountedInvoices: [],
      });
      setAllDuplicateInvoiceIds((duplicateIds) => {
        const allIds = [...duplicateIds];
        for (const id of ids) {
          allIds.push(id);
        }
        return allIds;
      });
      setModalState(0);
    }
  };
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      if (excelFileErrors.length > 0) {
        console.log(excelFileErrors);
        // setAnchorInvoiceRows([]);
        return;
      } else {
        check2();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelUploadClicked]);

  const assertColumnHeaders = (mapping: Map<string, number>) => {
    console.log("Selected Format", selectedFormat);
    let count = 0;
    setExcelFileErrors((currentErrors) => {
      const errors = [...currentErrors];
      for (const element in selectedFormat) {
        if (mapping.get(selectedFormat[element].name) === undefined) {
          errors.push(`${selectedFormat[element].name} column is missing from excel file.`);
          count++;
        }
      }

      return errors;
    });

    return count;
  };

  const validateMandatoryInvoiceFields = (nonDuplicates: AnchorInvoiceRow[]) => {
    setExcelFileErrors((currentErrors) => {
      const errors = [...currentErrors];
      nonDuplicates.map((item, index) => {
        if (item.invoiceNumber.length === 0) {
          errors.push(`Row num ${index + 1} has empty column Invoice Number`);
        }
        if (item.supplier.length === 0) {
          errors.push(`Row num ${index + 1} has empty column Supplier`);
        }
        if (item.invoiceAmount.amount <= 0) {
          errors.push(`Row num ${index + 1} has invalid Invoice Amount`);
        }
        if (!validateDateType(item.invoiceDate as Date)) {
          errors.push(`Row num ${index + 1} has invalid Date`);
        }
        return null;
      });
      return errors;
    });
  };

  /**
   * This method examines the validity of every row present in the intended excel file. This method throws the type error, sanity error etc. It returns all the valid rows.
   * @param row {Row}
   * @param startingRowIndex {number}
   * @param mapping {Map<string, number>}
   * @returns {AnchorInvoiceRow}
   */
  const rowToAnchorInvoiceRow = (row: Row, mapping: Map<string, number>): AnchorInvoiceRow => {
    const anchorRow = {
      invoiceNumber:
        row.findCell(mapping.get(selectedFormat.InvoiceNumber.name)) !== undefined
          ? row.getCell(mapping.get(selectedFormat.InvoiceNumber.name)).text.trim()
          : "Invalid",
      supplier:
        row.findCell(mapping.get(selectedFormat.Supplier.name)) !== undefined
          ? row.getCell(mapping.get(selectedFormat.Supplier.name)).text.trim()
          : "Invalid",
      supplierId:
        row.findCell(mapping.get(selectedFormat.Supplier.name)) !== undefined
          ? findTier1IdByName(customers, row.getCell(mapping.get(selectedFormat.Supplier.name)).text.trim())
          : -1,
      invoiceDate:
        row.findCell(mapping.get(selectedFormat.InvoiceDate.name)) !== undefined
          ? (row.getCell(mapping.get(selectedFormat.InvoiceDate.name)).value as Date)
          : "Invalid",

      invoiceAmount:
        row.findCell(mapping.get(selectedFormat.InvoiceAmount.name)) !== undefined
          ? toDineroObject(row.getCell(mapping.get(selectedFormat.InvoiceAmount.name)).value as number)
          : toDineroObject(0),
    } as AnchorInvoiceRow;

    console.log("Date VALS  " + row.getCell(mapping.get(selectedFormat.InvoiceDate.name)));
    return anchorRow;
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    cellStyle: { color: "#4D4F5C", textAlign: "left" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  /**
   * This is the column definition for the table present on the screen.
   */
  const columnDefs: MyColDef[] = [
    { headerName: "Invoice Number", field: "invoiceNumber" },
    { headerName: "Supplier", field: "supplier" },
    {
      headerName: "Invoice Date",
      field: "invoiceDate",
      valueFormatter: dateValueFormatter,
    },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      valueFormatter: currencyValueFormatter,
    },
  ];
  const rowData = () => {
    return anchorInvoicesRows;
  };
  /**
   * This method is called to upload all the invoices intended to be uploaded in bulk. This terminates the bulk upload flow and user is redirected to early payment screen. This method also sends a notification to all the Tier1's involved in this bulk upload.
   */
  const uploadAllInvoices = async () => {
    console.log("UPLOADING");
    console.log("Anchor Invoices Row", anchorInvoicesRows);
    setUploading(true);
    await Promise.all(
      anchorInvoicesRows.map(async (row) => {
        //   const fileUrl = await uploadImageFile(row.invoiceFileBuffer);
        console.log("row dtf", row, customers);

        const invoice = {
          invoiceId: row.invoiceNumber,
          invoiceDate: row.invoiceDate,
          invoiceAmount: row.invoiceAmount,
          // tslint:disable-next-line: prettier
          tier1Id: findTier1IdByName(customers, row.supplier),
        } as Tier1Invoice;
        console.log("Invoice DATE", row.invoiceDate);
        await axios.post<Tier1InvoiceResponse>("/api/InsertTier1Invoice", invoice);
      })
    );
    console.log("DONE");
    setUploading(false);
    alert("Invoices are uploaded");
    history.push("/anchor/early/Undiscounted");
  };
  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Upload", actor.name, location?.state?.openCollapseOb, actor)}
    >
      <div className="tier2-bulk-upload">
        {invoiceCheck && (
          <InvoiceFlowChecksForAnchorInvoicesBulkUploads
            tier2Invoices={anchorInvoices}
            tier2InvoiceRows={anchorInvoicesRows}
            update={updateDuplicateInvoices}
            onClose={() => setInvoiceCheck(false)}
            currentModal={modalState}
            tier2invoicesCheck={anchorInvoicesCheck}
          />
        )}
        <DropDownComponent
          format={customFormat}
          updateFormat={updateCustomFormat}
          onChangeFormat={(data: { [key: string]: { name: any } }) => setSelectedFormat(data)}
        />
        <UploadButtonComponent
          headerText="Upload your invoices in the prescribed format"
          onChange={excelFileUploadOnChange}
        />
        <div className="column errors">
          {(excelFileErrors.length !== 0 || columnHeaderError === true) && <h1>Fix the following errors</h1>}
          {columnHeaderError && <p>Kindly Check the Column Headers and then re-upload.</p>}
          {excelFileErrors.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </div>
        <div className="ag-theme-alpine mygrid" id="table">
          <AgGridReact
            enableCellTextSelection={true}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData()}
            domLayout="autoHeight"
          />
        </div>
        <div className="column">
          {
            <input
              className="button is-bold is-success theme_btn"
              type="button"
              value="Submit all invoices"
              onClick={async () => {
                await uploadAllInvoices();
              }}
              disabled={
                excelFileErrors.length > 0 || anchorInvoicesRows.length === 0 || uploading === true ? true : false
              }
            />
          }
        </div>
      </div>
    </LoggedInSkeleton>
  );
};
/**
 * @Component
 * UI component for the upload button.
 */
const UploadButtonComponent = (props: {
  headerText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="column">
      <div className="field">
        <div className="control">
          <label className="label">{props.headerText}</label>
        </div>
      </div>
      <label id="file-js-example" className="field file has-name">
        <input
          className="file-input"
          type="file"
          name="invoicefile"
          value={""}
          onChange={props.onChange}
          required={true}
          multiple={true}
        />
        <span
          className="file-cta theme_btn"
          style={{
            backgroundColor: "#4072e3",
          }}
        >
          <span className="file-icon">
            <i className="fas fa-upload" />
          </span>
          <span className="file-label">Upload</span>
        </span>
      </label>
    </div>
  );
};
async function getAlreadyUploadedInvoices(anchorInvoices: AnchorInvoiceRow[]) {
  const ids = anchorInvoices.map((i) => i.invoiceNumber);
  const tierIds = anchorInvoices.map((i) => i.supplierId);
  console.log(ids);
  const { data } = await axios.get<ListTier1InvoicesResponse>("/api/listTier1Invoices", {
    params: {
      ids,
      tierIds,
    },
  });

  console.log("Already Uploaded Invoices:", data.invoices);
  return data.invoices;
}

// DropDown Component

const DropDownComponent = ({
  format,
  updateFormat,
  onChangeFormat,
}: {
  format: ExternalDocTemplate[];
  updateFormat: () => Promise<number>;
  onChangeFormat: (data: { [key: string]: { name: any } }) => void;
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [headerName, setHeaderName] = useState<HeadersName>({} as HeadersName);
  const [templateName, setTemplateName] = useState<string>("");
  const [errTag, setErrTag] = useState<JSX.Element>(<div />);
  const [newMapping, setNewMapping] = useState<boolean>(true);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [selectValue, setSelectValue] = useState<string | number>("default");
  const [changeValidity, setChangeValidity] = useState<boolean>(false);
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaderName({ ...headerName, [event.target.name]: event.target.value });
  };
  const onChangeTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(event.target.value);
  };

  const { actor } = useContext(userContext);
  const nakadFormat: {
    [key: string]: { name: any };
  } = {
    InvoiceNumber: { name: "InvoiceNumber" },
    InvoiceAmount: { name: "InvoiceAmount" },
    InvoiceDate: { name: "InvoiceDate" },
    Supplier: { name: "Supplier" },
  };
  const nameValuePair: {
    [key: string]: any;
    name: string;
    value: string;
    label: string;
  }[] = [
    {
      name: "invoiceNumber",
      value: "InvoiceNumber",
      label: "Invoice Number",
    },
    {
      name: "invoiceAmount",
      value: "InvoiceAmount",
      label: "Invoice Amount",
    },
    {
      name: "invoiceDate",
      value: "InvoiceDate",
      label: "Invoice Date",
    },
    {
      name: "supplier",
      value: "Supplier",
      label: "Supplier",
    },
  ];
  const checkForRepeatedTemplateName = (): boolean => {
    for (const fmat of format) {
      if (templateName === fmat.name) return true;
    }
    return false;
  };
  const saveNewTemplate = async () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    if (templateName.length === 0) {
      setErrTag(<div style={{ color: "red" }}>This field is mandatory</div>);
      setTimeout(() => setErrTag(<div />), 6000);
    } else if (checkForRepeatedTemplateName() && newMapping) {
      setErrTag(
        <div style={{ color: "red" }}>The template name {templateName} already exists.Please Change the name.</div>
      );
      setTimeout(() => {
        setErrTag(<div />);
        btnRef.current.removeAttribute("disabled");
      }, 3000);
    } else {
      const templateToAdd = {
        actorId: actor.id,
        name: templateName,
        type: "Invoice",
        data: {
          InvoiceNumber: {
            name: headerName.invoiceNumber ? headerName.invoiceNumber : "InvoiceNumber",
          },
          InvoiceAmount: {
            name: headerName.invoiceAmount ? headerName.invoiceAmount : "InvoiceAmount",
          },
          InvoiceDate: {
            name: headerName.invoiceDate ? headerName.invoiceDate : "InvoiceDate",
          },
          Supplier: {
            name: headerName.supplier ? headerName.supplier : "Supplier",
          },
        },
      } as ExternalDocTemplate;
      if (selectValue !== "default") {
        templateToAdd.templateId = Number(selectValue);
      }
      const { data } = await axios.post("/api/InsertExernalDocTemplates", templateToAdd);
      console.log("template saved", data.template.data);
      const lastTemplateId = await updateFormat();
      setSelectValue(lastTemplateId);
      setNewMapping(false);
      setOpenDialog(false);
      onChangeFormat(data.template.data);
    }
  };
  const allFieldsAreNotEmpty = () => {
    for (const [, value] of Object.entries(headerName)) {
      if (value !== "") {
        setChangeValidity(true);
        return;
      }
    }
    setChangeValidity(false);
    return;
  };
  useEffect(() => {
    allFieldsAreNotEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerName]);
  const node = (): ReactNode => {
    return (
      <>
        <header className="modal-card-head">
          <p className="modal-card-title">Make your custom format</p>
          <button className="delete theme_btn" aria-label="close" onClick={() => setOpenDialog(false)} />
        </header>
        <section className="modal-card-body">
          <div className="container">
            <div className="column" style={{ paddingLeft: "0" }}>
              <div className="field">
                <label>
                  <strong>Template Name</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="templateName"
                    value={templateName || ""}
                    onChange={onChangeTemplateName}
                  />
                </div>
                {errTag}
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column">
                <strong>Source (Column Headers)</strong>
                {nameValuePair.map(({ name, label }) => {
                  return (
                    <div className="field" key={name}>
                      <label>{label}</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name={name}
                          value={headerName[name] || ""}
                          onChange={onChangeInput}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="column ">
                <strong>Destination (Column Headers)</strong>
                {nameValuePair.map((element) => {
                  return (
                    <div className="field" key={element.name}>
                      <br />
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name={element.name}
                          value={element.value}
                          disabled={true}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          {changeValidity && templateName.length !== 0 && (
            <>
              <button ref={btnRef} className="button is-success theme_btn" onClick={saveNewTemplate}>
                Save changes
              </button>
              <button className="button theme_btn" onClick={() => setOpenDialog(false)}>
                Cancel
              </button>
            </>
          )}
        </footer>
      </>
    );
  };
  const getDialog = () => {
    return (
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        {node()}
      </Dialog>
    );
  };
  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "default") {
      const filtered = format.filter((f) => {
        return f.templateId === Number(e.target.value);
      });
      const { data } = filtered[0] as {
        data: { [key: string]: { name: any } };
      };
      onChangeFormat(data);
      // console.log(data);
      const preHeader = {} as HeadersName;
      const findKeyByValue = (value: string) => {
        let result: string = value;
        nameValuePair.forEach((element) => {
          if (element.value === value) {
            result = element.name;
            console.log("Result is ", result);
          }
        });

        return result;
      };
      // tslint:disable-next-line:forin
      for (const key in data) {
        console.log(key, data[key].name);
        preHeader[findKeyByValue(key)] = data[key].name;
      }
      console.log(preHeader);

      setHeaderName(preHeader as HeadersName);
      setTemplateName(filtered[0].name);
      setSelectValue(e.target.value);
      setNewMapping(false);
    } else {
      setHeaderName({} as HeadersName);
      setTemplateName("");
      setSelectValue("default");
      setNewMapping(true);
      onChangeFormat(nakadFormat);
    }
  };
  return (
    <div className="column">
      <div className="field">
        <label className="label" style={{ float: "left" }}>
          Choose the custom format
        </label>
        <div className="control">
          <div className="select">
            <select
              name="formatid"
              required={true}
              value={selectValue}
              onChange={(e) => {
                //  console.log(e.target.value);
                onSelectChange(e);
              }}
            >
              <option value="default">Add new template</option>{" "}
              {format.map((fmat) => (
                <option key={fmat.templateId} value={fmat.templateId}>
                  {fmat.name}
                </option>
              ))}
            </select>
          </div>
          {newMapping ? (
            <FontAwesomeIcon
              icon={"plus-square"}
              size={"2x"}
              style={{
                color: "#48c774",
                marginTop: "5px",
                cursor: "pointer",
                paddingLeft: "5px",
              }}
              onClick={() => {
                setOpenDialog(true);
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={"edit"}
              size={"2x"}
              style={{
                color: "#48c774",
                marginTop: "5px",
                cursor: "pointer",
                paddingLeft: "5px",
              }}
              onClick={() => {
                setOpenDialog(true);
              }}
            />
          )}
          {getDialog()}
        </div>
      </div>
    </div>
  );
};
