import { Autocomplete, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorResponse, ListReconSummaryAccountingSettingResponse } from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
import ReconSettingsTable from "../../ReactTable/ReconSettingsTable";
import ReconSummarySettingsLegacyTable from "../../ReactTable/ReconSummarySettingsLegacyTable";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import CollapseSubRowForSettings from "./CollapseSubRowForSettings";

const ReconSummary = () => {
  const [selectedReconSummary, setSelectedReconSummary] = useState<string>(null);
  const [errReconSummary, setErrReconSummary] = useState<boolean>(false);
  const options = ["legacy", "Ledger + Gaps", "Both"];
  const [showUploadErrorAlert, setShowUploadErrorAlert] = useState<boolean>(false);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [summaryTypeSelected, setSummaryTypeSelected] = useState<string>(null);
  const [loader, setLoader] = useState(true);

  const ledgerComparisonSummaryData = [
    {
      line_item: (
        <>
          <b>Closing balance as per BP's ledger</b>
        </>
      ),
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },
    {
      line_item: <p>Invoice value gap</p>,
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },
    {
      line_item: <p>Payment value gap</p>,
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },

    {
      line_item: <p>Debit/credit note value gap</p>,
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },

    {
      line_item: <p>TDS Value Gap</p>,
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },
    {
      line_item: <p>Adjustment Of Miscellaneous Entries</p>,
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },
    {
      line_item: (
        <>
          <b>Reconciled closing balance</b>
        </>
      ),
      sub_total: "",
      total: "",
      num_of_item: "",
      value_of_item: "",
    },
    {
      line_item: (
        <>
          <b>Closing balance as per RU ledger</b>
        </>
      ),
      total: "",
    },
    {
      line_item: <p>Difference in opening balance</p>,
      total: "",
    },
    {
      line_item: (
        <>
          <b>Residual difference</b>
        </>
      ),
      total: "",
    },
  ];

  const colDefns = [
    {
      Header: (): any => null,
      id: "expander",
      Cell: ({ row }: any) => {
        if (row.id === "1" || row.id === "2" || row.id === "3" || row.id === "4" || row.id === "5" || row.id === "8") {
          return <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "➖" : "➕"}</span>;
        } else {
          return null;
        }
      },
      // We can override the cell renderer with a SubCell to be used with an expanded row
      SubCell: (): any => null, // No expander on an expanded row
      minWidth: 40,
      maxWidth: 40,
      width: 40,
    },
    {
      Header: "Reconciliation Status",
      accessor: "line_item",
      Sort: true,
      minWidth: 400,
      maxWidth: 400,
      width: 400,
    },

    {
      Header: "Breakup (INR)",
      accessor: "sub_total",
      Sort: true,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: "Value (INR)",
      accessor: "total",
      Sort: true,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
  ];

  const columnDefinitionRUSummary = [
    {
      Header: "",
      accessor: "addLess",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "Particulars",
      accessor: "particulars",
      maxWidth: 400,
      minWidth: 400,
      width: 400,
    },
    {
      Header: "Total amount",
      accessor: "totalAmount",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "Reconciled amount",
      accessor: "reconciledAmount",
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
  ];
  const columnDefinitionBPSummary = [
    {
      Header: "",
      accessor: "addLess",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "Particulars",
      accessor: "particulars",
      maxWidth: 400,
      minWidth: 400,
      width: 400,
    },
    {
      Header: "Total amount",
      accessor: "totalAmount",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "Reconciled amount",
      accessor: "reconciledAmount",
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
  ];

  const rowsDataRUSummary = [
    {
      addLess: "",
      particulars: `Opening balance as per "RU's" ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total Invoice amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total Debit/Credit Note amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total Payment/Receipt amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total TDS amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Others/ Miscellaneous amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "",
      particulars: `Closing balance as per "RU's" ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Opening balance gap in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Invoices amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Debit/Credit Note amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Payment/Receipt amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `TDS amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Others amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "",
      particulars: `Total GAP amount in "RU's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "",
      particulars: `Reconciled closing balance`,
      totalAmount: "",
      reconciledAmount: "",
    },
  ];
  const rowsDataBPSummary = [
    {
      addLess: "",
      particulars: `Opening balance as per "BP's" ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total Invoice amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total Debit/Credit Note amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total Payment/Receipt amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Total TDS amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "As per Ledger",
      particulars: `Others/ Miscellaneous amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "",
      particulars: `Closing balance as per "BP's" ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Opening balance gap in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Invoices amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Debit/Credit Note amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Payment/Receipt amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `TDS amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "Add/Less",
      particulars: `Others amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "",
      particulars: `Total GAP amount in "BP's" given ledger`,
      totalAmount: "",
      reconciledAmount: "",
    },
    {
      addLess: "",
      particulars: `Reconciled closing balance`,
      totalAmount: "",
      reconciledAmount: "",
    },
  ];

  const reconSummarySelect = (e: any, value: any) => {
    if (value !== null) {
      e.persist();
      setSelectedReconSummary(value);
      setErrReconSummary(false);
    } else {
      setSelectedReconSummary(null);
    }
  };

  useEffect(() => {
    listReconSummaryAccountingSetting();
  }, []);

  const listReconSummaryAccountingSetting = () => {
    try {
      Axios.get<ListReconSummaryAccountingSettingResponse>("/api/ListReconSummaryAccountingSetting")
        .then((response) => {
          setSummaryTypeSelected(response.data.reconSummaryAccountingSetting.summaryTypeSelected);
          setSelectedReconSummary(response.data.reconSummaryAccountingSetting.summaryTypeSelected);
          setLoader(false);
          // setRulesArray(response.data.sanitizationRules);
        })
        .catch((error) => {
          console.log("error ListReconSummaryAccountingSetting", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListReconSummaryAccountingSetting`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListReconSummaryAccountingSetting", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListReconSummaryAccountingSetting`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const updateReconSummaryType = () => {
    if (selectedReconSummary === null) {
      setErrReconSummary(true);
    }
    if (selectedReconSummary !== null) {
      try {
        Axios.post<any>("/api/UpdateReconSummaryType", {
          summaryTypeSelected: selectedReconSummary,
        })
          .then((response) => {
            console.log("response UpdateReconSummaryType", response);
            setSummaryTypeSelected(selectedReconSummary);
            setApiErrorMsg(response.data.message);
            setShowUploadSuccessAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error UpdateReconSummaryType", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowUploadErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            } else {
              setShowUploadErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UpdateReconSummaryType`);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error UpdateReconSummaryType", error?.response);
        setShowUploadErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}UpdateReconSummaryType`);
        apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
      }
    }
  };

  const renderRowSubComponent = React.useCallback(({ row, rowProps, visibleColumns }) => {
    return <CollapseSubRowForSettings row={row} rowProps={rowProps} visibleColumns={visibleColumns} />;
  }, []);

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showUploadSuccessAlert}
        showUploadErrorAlert={showUploadErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <Grid container={true}>
            <Grid item={true} xs={12} sm={12} md={12}>
              <Card>
                <CardContent style={{ height: "100px" }}>
                  <div className="space_between pt_15">
                    <div className="w_50_per">
                      <div className="fs_20 fw_600">
                        Recon summary{" "}
                        {summaryTypeSelected ? <span className="fs_18 fw_600">: ({summaryTypeSelected})</span> : ""}
                      </div>
                      {/* <div>Selected summary</div> */}
                    </div>
                    <div className="space_between w_50_per">
                      <div>
                        <Autocomplete
                          onChange={(e, value) => reconSummarySelect(e, value)}
                          id="ReconSummarySelect"
                          className="w_300"
                          options={options}
                          size="small"
                          //   groupBy={(option) => option}
                          getOptionLabel={(option) => option}
                          // defaultValue = "legacy"
                          defaultValue={summaryTypeSelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={true}
                              label="Choose format"
                              error={errReconSummary}
                              helperText={errReconSummary ? "please select recon summary" : ""}
                            />
                          )}
                        />
                      </div>
                      <div>
                        {/* selectedReconSummary !== summaryTypeSelected */}
                        {/* {selectedReconSummary === summaryTypeSelected} */}
                        {selectedReconSummary === summaryTypeSelected ? (
                          <Button variant="contained" className="theme_btn" disabled={true}>
                            Summary Selected
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => {
                              updateReconSummaryType();
                            }}
                            className="theme_btn"
                          >
                            Save Selection
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {selectedReconSummary && (
            <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ pt: 2 }}>
              <Grid item={true} xs={12} sm={12} md={12}>
                {selectedReconSummary === "legacy" ? (
                  <div className="center_align">
                    <ReconSummarySettingsLegacyTable
                      id="summaryTableForSettings"
                      columns={colDefns}
                      rows={ledgerComparisonSummaryData}
                      sortEnable={false}
                      renderRowSubComponent={renderRowSubComponent}
                      className="center_table"
                    />
                  </div>
                ) : selectedReconSummary === "Ledger + Gaps" ? (
                  <Grid item={true} xs={12} sm={12} md={12} className="d_flex">
                    <Grid item={true} xs={12} sm={12} md={6}>
                      <div className="p_20 fs_16 fw_600">Recon User's (RU's) Summary</div>
                      <ReconSettingsTable columns={columnDefinitionRUSummary} rows={rowsDataRUSummary} />
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={6}>
                      <div className="p_20 fs_16 fw_600">Business partner's (BP's) Summary</div>
                      <ReconSettingsTable columns={columnDefinitionBPSummary} rows={rowsDataBPSummary} />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ReconSummary;
