import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import Table from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const CollectionAccount = () => {
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [anchorId, setAnchorId] = useState<number>();
  const [accountNumber, setAccountNumber] = useState<number>(null);
  const [balance, setBalance] = useState<number>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [amountToBorrowerAccount, setAmountToBorrowerAccount] = useState<string>("");
  const [amountToLenderAccount, setAmountToLenderAccount] = useState<string>("");
  const [tier1Id, setTier1Id] = useState<number>(null);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [anchorList, setAnchorList] = useState<any[]>([]);
  const [lenderList, setLenderList] = useState<any[]>([]);
  const [tier1List, setTier1List] = useState<any[]>([]);
  const [action, setAction] = useState<string>("");
  const [beneficiaryId, setBeneficiaryId] = useState<string>("");
  const [accountNumberAddBenfi, setAccountNumberAddBenfi] = useState<any>(null);
  const [ifscCode, setIfscCode] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<any>(null);
  const [selectedLender, setSelectedLender] = useState<string>("");
  console.log("selectedRow", selectedRow);
  useEffect(() => {
    getAllActors();
    const getLenderList = async () => {
      try {
        await axios
          .get<any>("/api/ListLenders", {
            params: {
              anchorId,
              tier1Id,
              // bankId,
            },
          })
          .then((response) => {
            console.log("responseresponseresponse", response);
            setLenderList(response.data.lenders);
          })
          .catch((error) => {
            console.log("error ListLenders", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            } else {
              setShowErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UpdateBusinessPartnerDetails`);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error ListLenders", error?.response);
        setShowErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}ListLenders`);
        apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
      }
    };
    getLenderList();
  }, [anchorId, tier1Id]);

  const getAllActors = async () => {
    try {
      await axios
        .get<any>("/api/listAllActors")
        .then((response) => {
          console.log("responseresponseresponse", response);
          const anchorArr: any[] = [];
          const tier1Arr: any[] = [];
          const bankArr: any[] = [];
          response.data.data.forEach((actor: any) => {
            if (actor.actorType === "Anchor") {
              anchorArr.push(actor);
            } else if (actor.actorType === "Tier1") {
              tier1Arr.push(actor);
            } else if (actor.actorType === "Bank") {
              bankArr.push(actor);
            }
          });
          setTier1List(tier1Arr);
          // setBankList(bankArr);
          setAnchorList(anchorArr);
        })
        .catch((error) => {
          console.log("error listAllActors", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}listAllActors`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error listAllActors", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}listAllActors`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const columnDefinition = React.useMemo(
    () => [
      {
        Header: "Decentro Urn",
        accessor: "decentroUrn",
        Sort: true,
        minWidth: 350,
        maxWidth: 350,
        width: 350,
      },
      {
        Header: "Sender Account",
        accessor: "senderAccountNumber",
        Sort: true,
        minWidth: 250,
        maxWidth: 250,
        width: 250,
      },
      {
        Header: "Receiver Account",
        accessor: "recieverAccountNumber",
        Sort: true,
        minWidth: 250,
        maxWidth: 250,
        width: 250,
      },
      {
        Header: "Description",
        accessor: "description",
        Sort: true,
        minWidth: 250,
        maxWidth: 250,
        width: 250,
      },
      {
        Header: "Time Stamp",
        accessor: "timestamp",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Transfer Type",
        accessor: "transferType",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },

      {
        Header: "Transaction Type",
        accessor: "transactionType",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "Balance",
        accessor: "balance",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Deposit Amount",
        accessor: "depositAmount",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "Withdrawal Amount",
        accessor: "withdrawalAmount",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },

      {
        Header: "Status",
        accessor: "moneyTransferStatus",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "Type",
        accessor: "type",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
    ],
    []
  );

  const getCollectionAcStatement = async () => {
    try {
      await axios
        .get<any>("/api/ListCollectionAccountStatement", {
          params: {
            anchorId,
            tier1Id,
            // bankId,
          },
        })
        .then((response) => {
          console.log("responseresponseresponse", response.data);
          setRowsData(response.data.statement.statement);
          setBalance(response.data.statement.closingBalance);
          setAccountNumber(response.data.statement.accountNumber);
        })
        .catch((error) => {
          console.log("error ListCollectionAccountStatement", error?.response);
          setShowErrorAlert(true);
          setApiErrorMsg("No account associated with these Actors");
          apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
        });
    } catch (error: any) {
      console.log("error ListCollectionAccountStatement", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListCollectionAccountStatement`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const initiateCollectionTransaction = async () => {
    try {
      await axios
        .post<any>(
          "/api/InitiateCollectionTranscation",
          { amountToLenderAccount, amountToBorrowerAccount, selectedLender },
          {
            params: {
              anchorId,
              tier1Id,
              // bankId,
            },
          }
        )
        .then((response) => {
          console.log("initiate", response.data);
          getCollectionAcStatement();
        })
        .catch((error) => {
          console.log("error InitiateCollectionTranscation", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}InitiateCollectionTranscation`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error InitiateCollectionTranscation", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}InitiateCollectionTranscation`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const addBeneficiary = async () => {
    try {
      await axios
        .post<any>(
          "/api/AddBeneficiary",
          {
            beneficiaryFor: "Collection",
            beneficiaryId,
            action,
            ifscCode,
            accountNumber: accountNumberAddBenfi,
            emailAddress,
            mobileNumber,
          },
          {
            params: {
              anchorId,
              tier1Id,
              // bankId,
            },
          }
        )
        .then((response) => {
          console.log("initiate", response.data);
          setApiErrorMsg(response.data.message);
          setShowSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error AddBeneficiary", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}AddBeneficiary`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error AddBeneficiary", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}AddBeneficiary`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Collection Account")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid className="account_center_align coulmn_grid">
        <Grid className="account_center_align">
          <select className="select_ui" value={anchorId} onChange={(e: any) => setAnchorId(e.target.value)}>
            {" "}
            <option defaultValue="">Select Anchor</option>{" "}
            {anchorList.map((element) => (
              <option value={element.actorId} key={element.actorId}>
                {element.actorInfo.name}
              </option>
            ))}
          </select>
          <select className="select_ui" value={tier1Id} onChange={(e: any) => setTier1Id(e.target.value)}>
            {" "}
            <option defaultValue="">Select Borrower</option>{" "}
            {tier1List.map((element) => (
              <option value={element.actorId} key={element.actorId}>
                {element.actorInfo.name}
              </option>
            ))}
          </select>
          <Button
            variant="contained"
            className="theme_btn"
            style={{
              width: "100px",
              height: "40px",
              margin: "auto 0",
            }}
            onClick={() => getCollectionAcStatement()}
          >
            Submit
          </Button>
        </Grid>
        <Grid className="account_headings">
          <h3>
            <strong>Account No :</strong> {accountNumber}
          </h3>
          <h3>
            <strong>Account Balance :</strong> {balance}
          </h3>
        </Grid>
        {rowsData.length > 0 ? (
          <Grid className="account_table_row">
            <Grid className="account_table_column">
              <Table
                columns={columnDefinition}
                rows={rowsData}
                setSelectedRow={setSelectedRow}
                sortEnable={true}
                actorType="admin"
                pageSize={10}
                showPagination={true}
              />
            </Grid>
          </Grid>
        ) : null}

        <div className="benificiary_wrapper">
          <Box className="benificiary_box">
            <Grid>
              <h1>Initiate Transactions</h1>
            </Grid>
            <Grid className="account_center_align">
              <FormControl sx={{ minWidth: 195 }}>
                <InputLabel id="demo-simple-select-label">Select Lender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLender}
                  label="Select Lender"
                  onChange={(e: any) => setSelectedLender(e.target.value)}
                >
                  {lenderList.map((element) => (
                    <MenuItem value={element} key={element}>
                      {" "}
                      {element}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="amounttolenderaccount"
                label="Amount to Lender"
                type="text"
                style={{ margin: "0 40px" }}
                value={amountToLenderAccount}
                onChange={(e: any) => setAmountToLenderAccount(e.target.value)}
              />
              <TextField
                id="amounttoborroweraccount"
                label="Amount to Borrower"
                type="text"
                style={{ marginRight: "40px" }}
                value={amountToBorrowerAccount}
                onChange={(e: any) => setAmountToBorrowerAccount(e.target.value)}
              />

              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  width: "100px",
                  height: "40px",
                  margin: "auto 0",
                }}
                onClick={() => initiateCollectionTransaction()}
              >
                Confirm
              </Button>
            </Grid>
          </Box>
        </div>
        <div className="benificiary_wrapper">
          <Box className="benificiary_box">
            <Grid>
              <h1>Add Beneficiary</h1>
            </Grid>
            <Grid className="account_center_align">
              <FormControl sx={{ minWidth: 195 }}>
                <InputLabel id="demo-simple-select-label">Action</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={action}
                  label="Action"
                  onChange={(e: any) => setAction(e.target.value)}
                >
                  <MenuItem value={"Add"}>Add</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="beneficiaryId"
                label="Beneficiary Id"
                type="text"
                style={{ margin: "0 40px" }}
                value={beneficiaryId}
                onChange={(e: any) => setBeneficiaryId(e.target.value)}
              />
            </Grid>
            <Grid className="account_center_align">
              <TextField
                id="accountNumberAddBenfi"
                label="Account Number"
                type="text"
                value={accountNumberAddBenfi}
                onChange={(e: any) => setAccountNumberAddBenfi(e.target.value)}
              />
              <TextField
                id="ifscCode"
                label="IFSC Code"
                type="text"
                style={{ margin: "0 40px" }}
                value={ifscCode}
                onChange={(e: any) => setIfscCode(e.target.value)}
              />
            </Grid>
            <Grid className="account_center_align">
              <TextField
                id="emailAddress"
                label="Email"
                type="text"
                value={emailAddress}
                onChange={(e: any) => setEmailAddress(e.target.value)}
              />
              <TextField
                id="mobileNumber"
                label="Mobile Number"
                type="text"
                style={{ margin: "0 40px" }}
                value={mobileNumber}
                onChange={(e: any) => setMobileNumber(e.target.value)}
              />
            </Grid>
            <Grid className="account_center_align">
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  margin: "auto 0",
                }}
                onClick={() => addBeneficiary()}
              >
                Add Beneficiary
              </Button>
            </Grid>
          </Box>
        </div>
      </Grid>
    </LoggedInSkeleton>
  );
};

export default CollectionAccount;
