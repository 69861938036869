import { Button, Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import axios from "axios";
import React, { useState } from "react";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import Table from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const LedgerData = () => {
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [actorId, setActorId] = useState<number>(null);
  const [supplierCode, setSupplierCode] = useState<number>(null);
  const [type, setType] = useState<string>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);

  console.log("selectedRow", selectedRow);

  const columnDefinition = React.useMemo(
    () => [
      {
        Header: "OwnId",
        accessor: "ownId",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "BusinessPartnerId",
        accessor: "businessPartnerId",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },

      {
        Header: "DocumentNumber",
        accessor: "documentNumber",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "DocumentType",
        accessor: "documentType",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "DocumentDate",
        accessor: "documentDate",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "PostingDate",
        accessor: "postingDate",
        Sort: true,
        minWidth: 180,
        maxWidth: 180,
        width: 180,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Reference",
        accessor: "referenceNumber",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
    ],
    []
  );

  const getListOwnLedger = async () => {
    try {
      await axios
        .post<any>("/api/ListOwnLedger", {
          ownId: actorId,
          businessPartnerId: supplierCode,
        })
        .then((response) => {
          console.log("response", response);
          setType("Own");
          setRowsData(response.data.ledgers);
        })
        .catch((error) => {
          console.log("error ListOwnLedger", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListOwnLedger`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListOwnLedger", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListOwnLedger`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };
  const getListBusinessPartnerLedger = async () => {
    try {
      await axios
        .post<any>("/api/ListBusinessPartnerLedger", {
          ownId: actorId,
          businessPartnerId: supplierCode,
        })
        .then((response) => {
          console.log("response", response);
          setType("BusinessPartner");
          setRowsData(response.data.ledgers);
        })
        .catch((error) => {
          console.log("error ListBusinessPartnerLedger", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListBusinessPartnerLedger`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListBusinessPartnerLedger", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListBusinessPartnerLedger`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const deleteAllRow = async () => {
    if (type === "Own") {
      try {
        await axios
          .delete("/api/DeleteOwnLedger", {
            data: {
              ownId: actorId,
              businessPartnerId: supplierCode,
            },
          })
          .then((response) => {
            console.log("delete response", response);
            getListOwnLedger();
            setType(null);
            setShowSuccessAlert(true);
            setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error DeleteOwnLedger", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            } else {
              setShowErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}DeleteOwnLedger`);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error DeleteOwnLedger", error?.response);
        setShowErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}DeleteOwnLedger`);
        apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
      }
    } else if (type === "BusinessPartner") {
      try {
        await axios
          .delete("/api/DeleteBusinessPartnerLedger", {
            data: {
              ownId: actorId,
              businessPartnerId: supplierCode,
            },
          })
          .then((response) => {
            console.log("delete response", response);
            getListBusinessPartnerLedger();
            setType(null);
            setShowSuccessAlert(true);
            setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error DeleteBusinessPartnerLedger", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              setShowErrorAlert(true);
              setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            } else {
              setShowErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}DeleteBusinessPartnerLedger`);
              apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error DeleteBusinessPartnerLedger", error?.response);
        setShowErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}DeleteBusinessPartnerLedger`);
        apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
      }
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Ledger Data")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Grid style={{ paddingBottom: "40px" }}>
        <Grid>
          <Card
            className="center_align"
            style={{
              padding: "20px 40px",
              alignItems: "center",
              flexFlow: "column",
            }}
          >
            <Grid className="center_align">
              <TextField
                id="outlined-number"
                label="Own Id"
                type="number"
                value={actorId}
                onChange={(e: any) => setActorId(e.target.value)}
              />
              <TextField
                id="outlined-number"
                label="BusinessPartner Id"
                type="number"
                style={{ margin: "0 40px" }}
                value={supplierCode}
                onChange={(e: any) => setSupplierCode(e.target.value)}
              />
            </Grid>
            <Grid className="center_align" style={{ marginTop: "30px" }}>
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  height: "40px",
                  marginRight: "40px",
                  minWidth: "200px",
                }}
                onClick={() => getListOwnLedger()}
              >
                OwnLedger
              </Button>
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  height: "40px",
                }}
                onClick={() => getListBusinessPartnerLedger()}
              >
                BusinessPartnerLedger
              </Button>
            </Grid>
          </Card>
        </Grid>
        {rowsData.length > 0 ? (
          <Grid>
            <Grid>
              <p className="box_head">{type === "Own" ? "Own Ledger Data" : "Business Partner Ledger Data"}</p>
            </Grid>
            <Grid className="account_table_column">
              <Table
                columns={columnDefinition}
                rows={rowsData}
                sortEnable={true}
                setSelectedRow={setSelectedRow}
                actorType="admin"
                showPagination={true}
              />
            </Grid>
            <Grid className="center_align">
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  margin: "30px",
                }}
                onClick={() => deleteAllRow()}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </LoggedInSkeleton>
  );
};

export default LedgerData;
