import { Check } from "@mui/icons-material";
import { Box, ToggleButton } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  Legend,
  LinearScale,
  Plugin,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { ReconOverviewData } from "../../../entity/recon-entity/ReconInterfaces";
import { DOD, MOM, QOQ, WOW } from "../../../Utils/Recon/ReconMIS/Constants";
import { namedColor, transparentize } from "./utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options: ChartOptions<"bar"> = {
  plugins: {
    title: {
      display: false,
      text: "MIS Overview",
    },
    tooltip: {
      callbacks: {
        footer: (_e) => null,
      },
    },
    legend: {
      labels: {
        font: {
          size: 13,
        },
        color: "#222",
        boxWidth: 13,
        padding: 25,
      },
      position: "bottom",
      align: "start",
      fullSize: true,
      maxWidth: 500,
      display: false,
    },
  },
  responsive: true,
  interaction: {
    // comment or uncomment this line (mode) to have a grouped hover data
    mode: "index" as const,
    intersect: true,
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 13,
        },
        color: "#222",
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 13,
        },
        color: "#222",
      },
    },
  },
};

const getDataSet = (data: ReconOverviewData, index: number) => {
  return {
    label: data.Section,
    data: Object.values(data.Entries || {}),
    backgroundColor: transparentize(namedColor(index), 0.4),
    borderWidth: 1,
    borderColor: namedColor(index),
    stack: "Stack " + index,
    minBarLength: 3,
    maxBarThickness: 24,
  } as ChartDataset<"bar">;
};

const getLabel = (dateRange: "2022-09-01~2022-10-01" | string, dateRangeType: DateRangeType) => {
  const dateParseFormat = {
    [DOD]: "DD MMM",
    [WOW]: "DD MMM",
    [MOM]: "MMM 'YY",
    [QOQ]: "MMM 'YY",
  };

  const spiltDate1 = dateRange.split("-")[0];
  const spiltDate2 = dateRange.split("-")[1];

  let dateLabel = "";

  dateLabel += moment(spiltDate1).isValid()
    ? moment(spiltDate1).format(dateParseFormat[dateRangeType])
    : moment().format(dateParseFormat[dateRangeType]);
  if (spiltDate2) {
    dateLabel += " ~ ";
    dateLabel += moment(spiltDate2).isValid()
      ? moment(spiltDate2).format(dateParseFormat[dateRangeType])
      : moment().format(dateParseFormat[dateRangeType]);
  }

  return dateLabel;
};

const getOrCreateLegendList = () => {
  const legendContainer = document.getElementById("legendContainer");
  let listContainer = legendContainer?.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.className = "LegendUL";

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin: Plugin<"bar"> = {
  id: "htmlLegend",
  afterUpdate(chart) {
    const ul = getOrCreateLegendList();

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement("li");

      li.onclick = () => {
        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));

        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = transparentize(item.fillStyle.toString(), 0.4);
      boxSpan.style.borderColor = item.strokeStyle.toString();
      boxSpan.style.borderWidth = item.lineWidth + "px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = item.fontColor.toString();
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

type DateRangeType = "dod" | "wow" | "mom" | "qoq";

export default function OverViewChart({
  pData,
  pDateRangeType,
}: {
  pData: ReconOverviewData[];
  pDateRangeType: DateRangeType;
}) {
  const [interactMode, setInteractMode] = useState(false);

  // getLabel(Object.keys(pData[0].Entries)[0], pDateRangeType);

  const data: ChartData<"bar"> = {
    labels: Object.keys(pData[0]?.Entries)?.map((date) => getLabel(date, pDateRangeType)),
    datasets: pData.map((d, i) => getDataSet(d, i)),
  };

  return (
    <div className="chart_container">
      <Box>
        <Box display={"flex"} justifyContent={"end"} alignItems={"center"} gap={1} px={4} py={1}>
          <ToggleButton
            value="check"
            placeholder="Grouped Tooltip"
            selected={interactMode}
            onChange={() => {
              setInteractMode(!interactMode);
            }}
            color="info"
            size="small"
            sx={{
              borderRadius: 25,
              height: 32,
              width: 32,
            }}
          >
            <Check />
          </ToggleButton>
          <label style={{ fontSize: 14 }}>
            <input
              type="checkbox"
              checked={interactMode}
              onChange={() => setInteractMode(!interactMode)}
              hidden={true}
            />
            Consolidated Tooltip?
          </label>
        </Box>

        <Bar
          options={{
            ...options,
            maintainAspectRatio: true,
            interaction: { ...options.interaction, mode: interactMode ? options.interaction?.mode : undefined },
          }}
          plugins={[htmlLegendPlugin]}
          data={data}
          height={300}
          width={900}
        />
      </Box>
      <Box id="legendContainer" />
    </div>
  );
}
