import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./LedgerRequestFilter.scss";

type setAppliedFilters = React.Dispatch<
  React.SetStateAction<{
    [key: string]: string;
  }>
>;
const LedgerRequestFilter = (props: { [key: string]: any; setAppliedFilters: setAppliedFilters }) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  const [fromDateAsOfLastStatusChange, setFromDateAsOfLastStatusChange] = useState<string>(null);
  const [toDateAsOfLastStatusChange, setToDateAsOfLastStatusChange] = useState<string>(null);
  const [fromDateAsOfLastLedgerReceived, setFromDateAsOfLastLedgerReceived] = useState<string>(null);
  const [toDateAsOfLastLedgerReceived, setToDateAsOfLastLedgerReceived] = useState<string>(null);
  const [partnerName, setPartnerName] = useState<string>("");
  const allLedgerRequestStatus = [
    "Ledger requested",
    "Check partner reply",
    "Reminder sent",
    "Configuration Underway",
    "Request ledger",
    "Download ledger",
    "Send Reminder",
    "Recon shared with Partner",
  ];

  const handleClose = () => {
    props.setOpenLedgerRequestFilter(false);
  };

  const filterLedgerRequest = () => {
    let tempRowData = props.storeRowsDataLedgerRequest;

    if (partnerName?.trim()) {
      const partnerNameWithoutMultiSpace = partnerName.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedCategory?.some((val) => item.category?.includes(val));
      });
    }

    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedStatus?.some((val) => item.ledgerRequestStatus?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          moment(item.dateOfLastLedgerRequestStatusChange)?.format("YYYY-MM-DD") <= toDateAsOfLastStatusChange &&
          moment(item.dateOfLastLedgerRequestStatusChange)?.format("YYYY-MM-DD") >= fromDateAsOfLastStatusChange
        );
      });
    }
    if (fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange === null) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.dateOfLastLedgerRequestStatusChange)?.format("YYYY-MM-DD") >= fromDateAsOfLastStatusChange;
      });
    }
    if (fromDateAsOfLastStatusChange === null && toDateAsOfLastStatusChange) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.dateOfLastLedgerRequestStatusChange)?.format("YYYY-MM-DD") <= toDateAsOfLastStatusChange;
      });
    }
    if (fromDateAsOfLastLedgerReceived && toDateAsOfLastLedgerReceived) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          moment(item.latestLedgerReceivedOn)?.format("YYYY-MM-DD") <= toDateAsOfLastLedgerReceived &&
          moment(item.latestLedgerReceivedOn)?.format("YYYY-MM-DD") >= fromDateAsOfLastLedgerReceived
        );
      });
    }
    if (fromDateAsOfLastLedgerReceived && toDateAsOfLastLedgerReceived === null) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.latestLedgerReceivedOn)?.format("YYYY-MM-DD") >= fromDateAsOfLastLedgerReceived;
      });
    }
    if (fromDateAsOfLastLedgerReceived === null && toDateAsOfLastLedgerReceived) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.latestLedgerReceivedOn)?.format("YYYY-MM-DD") <= toDateAsOfLastLedgerReceived;
      });
    }

    props.setAppliedFilters({
      "Partner Name": partnerName?.trim(),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "Date As of Last Status Change":
        fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange
          ? [fromDateAsOfLastStatusChange, toDateAsOfLastStatusChange].join(" - ")
          : fromDateAsOfLastStatusChange
          ? `${fromDateAsOfLastStatusChange} Onwards`
          : toDateAsOfLastStatusChange
          ? `Till ${toDateAsOfLastStatusChange}`
          : "",
      "Date As of Last Ledger Received":
        fromDateAsOfLastLedgerReceived && toDateAsOfLastLedgerReceived
          ? [fromDateAsOfLastLedgerReceived, toDateAsOfLastLedgerReceived].join(" - ")
          : fromDateAsOfLastLedgerReceived
          ? `${fromDateAsOfLastLedgerReceived} Onwards`
          : toDateAsOfLastLedgerReceived
          ? `Till ${toDateAsOfLastLedgerReceived}`
          : "",
    });

    props.setRowsDataLedgerRequest(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterLedgerRequest();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);
    setFromDateAsOfLastStatusChange(null);
    setToDateAsOfLastStatusChange(null);
    setFromDateAsOfLastLedgerReceived(null);
    setToDateAsOfLastLedgerReceived(null);
    setPartnerName(null);

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenLedgerRequestFilter(true);
      props.setRowsDataLedgerRequest(props.storeRowsDataLedgerRequest);
      props.setIsFilterAppliedLedgerRequest(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openLedgerRequestFilter}
        aria-labelledby="LedgerRequestFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Name:</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={props.storeRowsDataLedgerRequest?.map((option) => option?.businessPartnerName)}
                  value={partnerName}
                  onChange={(event, value) => {
                    console.log(event);
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setPartnerName(value);
                    } else setPartnerName("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(e: any, value: any) => {
                    console.log(e);
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option: any) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(e: any, value: any) => {
                    console.log(e);
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={allLedgerRequestStatus || []}
                  getOptionLabel={(option: any) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date as of last status change:</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateAsOfLastStatusChange}
                  onChange={(e: any) => {
                    setFromDateAsOfLastStatusChange(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateAsOfLastStatusChange}
                  onChange={(e: any) => {
                    setToDateAsOfLastStatusChange(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date as of last ledger received:</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateAsOfLastLedgerReceived}
                  onChange={(e: any) => {
                    setFromDateAsOfLastLedgerReceived(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateAsOfLastLedgerReceived}
                  onChange={(e: any) => {
                    setToDateAsOfLastLedgerReceived(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              id="filterLedgerRequest_button"
              className="theme_btn"
              onClick={() => {
                filterLedgerRequest();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LedgerRequestFilter;
