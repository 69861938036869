import { Button, Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";

interface UseDateFilterPropsInterface {
  setShowUseDateFilter: Dispatch<SetStateAction<boolean>>;
  startDateOwn: Date;
  setStartDateOwnRef: any;
  endDateOwn: Date;
  setEndDateOwnRef: any;
  setStartDateOwn: Dispatch<SetStateAction<any>>;
  setStartDateBusinessPartner: Dispatch<SetStateAction<any>>;
  setEndDateOwn: Dispatch<SetStateAction<any>>;
  setEndDateBusinessPartner: Dispatch<SetStateAction<any>>;
}

const UseDateFilter = (props: UseDateFilterPropsInterface) => {
  const [errorFutureDate, setErrorFutureDate] = useState<boolean>(false);
  const [errorEndFutureDate, setErrorEndFutureDate] = useState<boolean>(false);

  const changeDateFormat = () => {
    props.setStartDateOwn(moment(props.startDateOwn)?.format("YYYY-MM-DD"));
    props.setStartDateBusinessPartner(moment(props.startDateOwn)?.format("YYYY-MM-DD"));
    props.setEndDateOwn(moment(props.endDateOwn)?.format("YYYY-MM-DD"));
    props.setEndDateBusinessPartner(moment(props.endDateOwn)?.format("YYYY-MM-DD"));
  };

  return (
    <>
      <header className="modal-card-head">
        <p className="modal-card-title">Apply date filter on ledger entries</p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            changeDateFormat();
            props.setShowUseDateFilter(false);
          }}
        />
      </header>
      <section className="modal-card-body">
        <div className="space_around">
          <Grid xs={12} md={12} style={{ marginBottom: "20px" }} className="center_align">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              style={{ minWidth: "300px" }}
              className="closingblc_input"
            >
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                label="Start Date"
                value={props.startDateOwn}
                onChange={(date: Date) => {
                  if (date !== null) {
                    if (date.getTime() > new Date().getTime()) {
                      setErrorFutureDate(true);
                    } else {
                      setErrorFutureDate(false);
                    }
                  }
                  props.setStartDateOwnRef.current = date;
                  props.setStartDateOwn(date);
                  props.setStartDateBusinessPartner(date);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      params.error ? (errorFutureDate ? "Date cannot be in the future" : "Invalid date format") : ""
                    }
                  />
                )}
                maxDate={new Date()}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={12} style={{ marginBottom: "20px" }} className="center_align">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              style={{ minWidth: "300px" }}
              className="closingblc_input"
            >
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                label="End Date"
                value={props.endDateOwn}
                onChange={(date: Date) => {
                  if (date !== null) {
                    if (
                      moment(date).format("YYYY-MM-DD").valueOf() < moment(new Date()).format("YYYY-MM-DD").valueOf()
                    ) {
                      setErrorEndFutureDate(false);
                    } else if (
                      moment(date).format("YYYY-MM-DD").valueOf() > moment(new Date()).format("YYYY-MM-DD").valueOf()
                    ) {
                      // setErrorEndFutureDate(true);
                      // allow future end date
                      setErrorEndFutureDate(false);
                    } else {
                      setErrorEndFutureDate(false);
                    }
                  }
                  props.setEndDateOwnRef.current = date;
                  props.setEndDateOwn(date);
                  props.setEndDateBusinessPartner(date);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      params.error ? (errorEndFutureDate ? "Date cannot be in the future" : "Invalid date format") : ""
                    }
                  />
                )}
                // maxDate={new Date()}
              />
            </LocalizationProvider>
          </Grid>
        </div>
        {moment(props.startDateOwn).format("YYYY-MM-DD").valueOf() >
        moment(props.endDateOwn).format("YYYY-MM-DD").valueOf() ? (
          <Grid className="date_error_text text_center">The Start Date must be earlier than the End Date.</Grid>
        ) : null}
      </section>
      <footer className="modal-card-foot flex_end">
        <Button
          className="button is-success theme_btn"
          onClick={() => {
            changeDateFormat();
            props.setShowUseDateFilter(false);
          }}
        >
          Save
        </Button>
      </footer>
    </>
  );
};

export default UseDateFilter;
