import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

Axios.defaults.baseURL = process.env.REACT_APP_NAKAD_LOCAL
  ? null
  : process.env.REACT_APP_NAKAD_ENV === "test"
  ? "https://api.dev.nakad.co"
  : process.env.REACT_APP_NAKAD_ENV === "staging"
  ? "https://api.staging.nakad.co"
  : process.env.REACT_APP_NAKAD_ENV === "production"
  ? process.env.REACT_APP_NAKAD_ENV_TAG === "demo"
    ? "https://api.demo.nakad.co"
    : "https://api.nakad.co"
  : null;
Axios.defaults.withCredentials = true;
// Axios.defaults.baseURL = "http://localhost:8082";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
