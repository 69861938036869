import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { DOCUMENT_TYPES } from "../../../Utils/Recon/Recon360/Constants";
import { ManualReconContext } from "./ManualRecon";

const ManualReconReconcileOwnFilter = () => {
  const {
    openManualReconReconcileOwnFilter,
    setOpenManualReconReconcileOwnFilter,
    storeRowsDataReconciledOwn,
    entryNumOwn,
    setEntryNumOwn,
    setIsFilterAppliedOwnSide,
    setRowsDataReconciledOwn,
    reFilter,
    setReFilter,
    isFilterAppliedOwnSide,
  } = useContext(ManualReconContext);
  const [fromDate, setFromDate] = useState<string>(null);
  const [toDate, setToDate] = useState<string>(null);
  const [minAmount, setMinAmount] = useState<number>(null);
  const [maxAmount, setMaxAmount] = useState<number>(null);
  const [docType, setDocType] = useState<string>(null);
  const [entryType, setEntryType] = useState<string>(null);
  const [reference, setReference] = useState<string>(null);

  const handleClose = () => {
    setOpenManualReconReconcileOwnFilter(false);
  };

  const applyManualReconReconcileOwnFilter = () => {
    let tempRowData = storeRowsDataReconciledOwn;

    if (entryNumOwn?.trim()) {
      const numbersArray = entryNumOwn?.split(/[ ,]+/)?.map(Number);
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item) => numbersArray?.includes(item.entryNo));
    }

    if (docType?.trim()) {
      const docTypeWithoutMultiSpace = docType.replace(/\s+/g, " ");
      const docTypeStr = docTypeWithoutMultiSpace.toLowerCase();
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.documentType?.toLowerCase()?.includes(docTypeStr?.trim());
      });
    }
    if (entryType?.trim()) {
      const entryTypeWithoutMultiSpace = entryType.replace(/\s+/g, " ");
      const entryTypeStr = entryTypeWithoutMultiSpace.toLowerCase();
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.type?.toLowerCase()?.includes(entryTypeStr?.trim());
      });
    }
    if (reference?.trim()) {
      const referenceWithoutMultiSpace = reference.replace(/\s+/g, " ");
      const referenceStr = referenceWithoutMultiSpace.toLowerCase();
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.referenceNumber?.toLowerCase()?.includes(referenceStr?.trim());
      });
    }

    if (fromDate && toDate) {
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.date)?.format("YYYY-MM-DD") <= toDate && moment(item.date)?.format("YYYY-MM-DD") >= fromDate;
      });
    }
    if (fromDate && toDate === null) {
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.date)?.format("YYYY-MM-DD") >= fromDate;
      });
    }
    if (fromDate === null && toDate) {
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.date)?.format("YYYY-MM-DD") <= toDate;
      });
    }

    if (minAmount && maxAmount) {
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return Number(item.amount) <= Number(maxAmount) && Number(item.amount) >= Number(minAmount);
      });
    }
    if (minAmount && maxAmount === null) {
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return Number(item.amount) >= Number(minAmount);
      });
    }
    if (minAmount === null && maxAmount) {
      setIsFilterAppliedOwnSide(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return Number(item.amount) <= Number(maxAmount);
      });
    }

    setRowsDataReconciledOwn(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (reFilter) {
      applyManualReconReconcileOwnFilter();
      setReFilter(false);
    }
    if (!isFilterAppliedOwnSide) {
      setFromDate(null);
      setToDate(null);
      setMinAmount(null);
      setMaxAmount(null);
      setDocType(null);
      setEntryType(null);
      setReference(null);
      setEntryNumOwn("");
    }
    // eslint-disable-next-line
  }, [reFilter, isFilterAppliedOwnSide]);

  const clearAllAppliedFilters = () => {
    setFromDate(null);
    setToDate(null);
    setMinAmount(null);
    setMaxAmount(null);
    setDocType(null);
    setEntryType(null);
    setReference(null);
    setEntryNumOwn("");

    handleClose();
    setTimeout(() => {
      setOpenManualReconReconcileOwnFilter(true);
      setRowsDataReconciledOwn(storeRowsDataReconciledOwn);
      setIsFilterAppliedOwnSide(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={openManualReconReconcileOwnFilter}
        aria-labelledby="manualReconReconcileOwnFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Entry no:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  label="Enter Entry no"
                  fullWidth={true}
                  placeholder="Insert Entry no.s like 23, 24"
                  id="outlined-size-small"
                  size="small"
                  value={entryNumOwn}
                  onChange={(e: any) => {
                    setEntryNumOwn(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDate}
                  onChange={(e: any) => {
                    setFromDate(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDate}
                  onChange={(e: any) => {
                    setToDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Doc Type:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  //   className="mr_20"
                  label="Enter Name"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={docType}
                  onChange={(e: any) => {
                    setDocType(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Entry Type:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                {/* <TextField
                  //   className="mr_20"
                  label="Enter Name"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={entryType}
                  onChange={(e: any) => {
                    setEntryType(e.target.value);
                  }}
                /> */}
                <Autocomplete
                  freeSolo={true}
                  options={DOCUMENT_TYPES.map((name) => name[0].toUpperCase() + name.slice(1))}
                  renderInput={(params) => <TextField {...params} label="Enter or Select Type" />}
                  fullWidth={true}
                  size="small"
                  value={entryType}
                  onChange={(_e, v) => {
                    setEntryType(v);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Reference:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  //   className="mr_20"
                  label="Enter Name"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={reference}
                  onChange={(e: any) => {
                    setReference(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Amount :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="Min"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  value={minAmount}
                  onChange={(e: any) => {
                    setMinAmount(e.target.value.trim());
                  }}
                />
                <TextField
                  label="Max"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  value={maxAmount}
                  onChange={(e: any) => {
                    setMaxAmount(e.target.value.trim());
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterBalanceConfirmation_button"
              onClick={() => {
                applyManualReconReconcileOwnFilter();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManualReconReconcileOwnFilter;
