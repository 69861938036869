import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InboxIcon from "@mui/icons-material/DoubleArrow";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Collapse } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Nakad from "../../Graphics/nakad-logo.png";
import { userContext } from "../Contexts/userContext";

const drawerWidth = 180;

// import Tnc from "../Login/TncData/tnc.pdf";

export interface LoggedInSkeletonProps {
  // topBarButtons: JSX.Element[];
  topBarButtons: any;
}

// This class encapsulate the common UI which is present in all the logged in pages.
export const LoggedInSkeleton = (props: React.PropsWithChildren<LoggedInSkeletonProps>) => {
  const history = useHistory();
  const { actor } = useContext(userContext);
  // const { setActor } = useContext(userContext);
  // const inputRef = useRef(null);
  const [isActive, setisActive] = React.useState(false);
  // const [openTncDialog, setOpenTncDialog] = useState<boolean>(false);
  // const [acceptBtnEnable, setAcceptBtnEnable] = useState<boolean>(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [showNotificatons, setShowNotifications] = useState<boolean>(false);
  localStorage.setItem("actor", JSON.stringify(actor));
  const [open, setOpen] = React.useState(true);
  // const [openCollapse, setOpenCollapse] = useState<any>({ RECON360: true });
  const [openCollapse, setOpenCollapse] = useState<any>({
    "Partner Communication": true,
    "Automated Recon": true,
    "Recon MIS": true,
  });
  // console.log("openCollapse", openCollapse);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleListItemClick = (name: any) => {
    setOpenCollapse({ ...openCollapse, [name]: !openCollapse[name] });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if (actor.acceptedTNC === false) {
  //     setOpenTncDialog(true);
  //   }
  // }, [actor.acceptedTNC]);

  useEffect(() => {
    if (props.topBarButtons[0]?.openCollapseObj !== undefined) {
      setOpenCollapse(props.topBarButtons[0]?.openCollapseObj);
    }
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    // localStorage.removeItem("actor");
    localStorage.clear();
    axios
      .get("/logout")
      .then((_response) => {
        history.push("/");
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  // console.log("props.topBarButtons", props.topBarButtons);

  // const acceptTnc = () => {
  //   axios
  //     .get("/api/AcceptTNC")
  //     .then((response) => {
  //       console.log("acceptTncresponse", response);
  //       if (response.data.acceptedTNC === true) {
  //         //setOpenTncDialog(false);
  //         setActor({
  //           name: response.data.actorInfo.name,
  //           id: response.data.actorId,
  //           acceptedTNC: response.data.acceptedTNC,
  //           actorType: response.data.actorType,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const onScroll = () => {
  //   console.log("onscroll");
  //   console.log("inputRef", inputRef);
  //   if (inputRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = inputRef.current;
  //     console.log("scrollTop, scrollHeight, clientHeight", scrollTop, scrollHeight, clientHeight);
  //     console.log("scrollTop + clientHeight===scrollHeight", scrollTop + clientHeight, "===", scrollHeight);
  //     console.log(
  //       "scrollTop + clientHeight===scrollHeight",
  //       Math.round(scrollTop + clientHeight),
  //       ">",
  //       scrollHeight - 100
  //     );
  //     if (Math.round(scrollTop + clientHeight) > scrollHeight - 100) {
  //       console.log("Reached bottom");
  //       setAcceptBtnEnable(true);
  //     } else {
  //       setAcceptBtnEnable(false);
  //     }
  //   }
  // };

  // const node = (): ReactNode => {
  //   return (
  //     <>
  //       <header className="modal-card-head">
  //         <div>
  //           <h2 className="modal-card-title" style={{ fontWeight: 900 }}>
  //             Terms of Service
  //           </h2>
  //           <p>Last Updated Februry 11, 2018</p>
  //         </div>
  //       </header>
  //       <section className="modal-card-body" onScroll={() => onScroll()} ref={inputRef}>
  //         <div className="container">
  //           <div className="row">
  //             <h2 className="tncHeading">1. Terms</h2>
  //             <p>
  //               This Software as a Service Agreement (“Agreement”) is entered into between Customer and SailPoint
  //               Technologies, Inc. a Delaware corporation (“SailPoint”), with its principal place of business at 11305
  //               Four Points Dr. Suite 100, Austin, Texas 78726. SailPoint and Customer agree that the following terms
  //               and conditions will apply to the services provided under this Agreement and Orders placed thereunder.
  //               Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus atque adipisci nobis tempore
  //               maiores eaque minima cum aperiam alias id in nulla, sit eum ipsum quaerat laboriosam unde quidem
  //               impedit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet excepturi quisquam velit,
  //               commodi id, officiis sint tenetur repellat quibusdam natus nobis. Quis quae sint minus rerum ratione
  //               nemo totam nihil vel mollitia ipsam unde provident beatae, praesentium libero molestiae quisquam? Vero
  //               eius iure facere praesentium alias commodi sapiente repellendus quibusdam ducimus voluptates quasi,
  //               expedita sit quisquam in fugit! Debitis quia quibusdam odio eum quod officia, consequatur fugit amet
  //               dolores ex vel distinctio reprehenderit? Architecto illum libero ducimus reprehenderit, excepturi
  //               mollitia consectetur quas quis impedit dolor cum cumque magni vel rerum ipsam repellat aspernatur iusto
  //               molestiae provident facilis vitae, dolores voluptatum pariatur quam? Voluptate deserunt earum commodi,
  //               itaque a eveniet cumque, provident eaque at ad voluptatibus consequuntur, amet cupiditate quia impedit
  //               tempore! Itaque, voluptates eum voluptatibus quia tempore rerum porro, provident necessitatibus culpa
  //               exercitationem voluptas cupiditate sequi nisi laborum soluta qui? Minima voluptatem animi vitae ut
  //               suscipit doloribus quidem. Quibusdam beatae minus ad aliquid vero sit suscipit debitis voluptatum
  //               dolorum sequi facilis soluta deleniti rerum sint inventore enim, laboriosam corporis, quos quam tempore
  //               nihil quas explicabo qui eligendi! Perspiciatis amet consequatur alias inventore laboriosam fuga dicta
  //               similique accusamus tempora soluta nisi rerum reprehenderit magnam cupiditate sed, aspernatur ipsum quae
  //               commodi? Voluptate?
  //             </p>
  //           </div>
  //           <div className="row">
  //             <h2 className="tncHeading">2. DEFINITIONS</h2>
  //             <p>
  //               This Software as a Service Agreement (“Agreement”) is entered into between Customer and SailPoint
  //               Technologies, Inc. a Delaware corporation (“SailPoint”), with its principal place of business at 11305
  //               Four Points Dr. Suite 100, Austin, Texas 78726. SailPoint and Customer agree that the following terms
  //               and conditions will apply to the services provided under this Agreement and Orders placed thereunder.
  //               Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus atque adipisci nobis tempore
  //               maiores eaque minima cum aperiam alias id in nulla, sit eum ipsum quaerat laboriosam unde quidem
  //               impedit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet excepturi quisquam velit,
  //               commodi id, officiis sint tenetur repellat quibusdam natus nobis. Quis quae sint minus rerum ratione
  //               nemo totam nihil vel mollitia ipsam unde provident beatae, praesentium libero molestiae quisquam? Vero
  //               eius iure facere praesentium alias commodi sapiente repellendus quibusdam ducimus voluptates quasi,
  //               expedita sit quisquam in fugit! Debitis quia quibusdam odio eum quod officia, consequatur fugit amet
  //               dolores ex vel distinctio reprehenderit? Architecto illum libero ducimus reprehenderit, excepturi
  //               mollitia consectetur quas quis impedit dolor cum cumque magni vel rerum ipsam repellat aspernatur iusto
  //               molestiae provident facilis vitae, dolores voluptatum pariatur quam? Voluptate deserunt earum commodi,
  //               itaque a eveniet cumque, provident eaque at ad voluptatibus consequuntur, amet cupiditate quia impedit
  //               tempore! Itaque, voluptates eum voluptatibus quia tempore rerum porro, provident necessitatibus culpa
  //               exercitationem voluptas cupiditate sequi nisi laborum soluta qui? Minima voluptatem animi vitae ut
  //               suscipit doloribus quidem. Quibusdam beatae minus ad aliquid vero sit suscipit debitis voluptatum
  //               dolorum sequi facilis soluta deleniti rerum sint inventore enim, laboriosam corporis, quos quam tempore
  //               nihil quas explicabo qui eligendi! Perspiciatis amet consequatur alias inventore laboriosam fuga dicta
  //               similique accusamus tempora soluta nisi rerum reprehenderit magnam cupiditate sed, aspernatur ipsum quae
  //               commodi? Voluptate?
  //             </p>
  //           </div>
  //         </div>
  //       </section>
  //       <footer className="modal-card-foot" style={{ display: "flex", justifyContent: "space-between" }}>
  //         <div>
  //           <button
  //             className="button"
  //             onClick={() => {
  //               setOpenTncDialog(false);
  //               logout();
  //             }}
  //             style={{
  //               width: "175px",
  //               color: "#4072E3",
  //               border: "2px solid #4072E3",
  //             }}
  //           >
  //             Decline
  //           </button>
  //         </div>
  //         <div>
  //           <a href={Tnc} download="Terms of Service.pdf">
  //             <button
  //               className="button is-success"
  //               onClick={acceptTnc}
  //               style={{ width: "175px", backgroundColor: "#4072E3" }}
  //               disabled={acceptBtnEnable ? false : true}
  //             >
  //               Accept & Download
  //             </button>
  //           </a>
  //         </div>
  //       </footer>
  //     </>
  //   );
  // };

  // const getDialogTncModal = () => {
  //   return (
  //     <Dialog open={openTncDialog} onClose={() => setOpenTncDialog(true)}>
  //       {node()}
  //     </Dialog>
  //   );
  // };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(6)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  interface AppBarProps extends MuiAppBarProps {
    openmenu?: boolean;
  }
  interface DrawerProps extends MuiDrawerProps {
    sidebaropen?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, openmenu }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(openmenu && {
      marginLeft: 0,
      zIndex: 999,
      width: `100%`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })<DrawerProps>(({ theme, sidebaropen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(sidebaropen && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!sidebaropen && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted={true}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className="logout_menu"
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted={true}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <MailIcon />
        </IconButton>
        <p>Support</p>
      </MenuItem>
      <MenuItem onClick={logout}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p> {JSON.parse(localStorage.getItem("actor")).name}</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );

  const LogoImage = () => {
    return React.useMemo(() => {
      return <img key="nakad-logo" src={Nakad} alt="NAKAD-logo" className="main-logo" style={{ marginLeft: "0" }} />;
    }, []);
  };

  return (
    <>
      <CssBaseline />
      <div className="layout_wrapper">
        <CSSTransition in={open} timeout={300} classNames="alert">
          <div className="layout-vertical-nav" onMouseOver={() => setOpen(true)}>
            <Drawer
              variant="permanent"
              // sx={{
              //   width: "20%",
              //   maxWidth: drawerWidth,
              //   flexShrink: 0,
              //   [`& .MuiDrawer-paper`]: {
              //     width: drawerWidth,
              //     boxSizing: "border-box",
              //   },
              // }}
              sidebaropen={open}
              className="menu_drawer"
            >
              <DrawerHeader style={{ justifyContent: "space-between" }}>
                <div className="logo">
                  <Link className="navbar-item" to="/">
                    <LogoImage />
                  </Link>
                </div>
                {open && (
                  <IconButton onClick={handleDrawerClose} className="menu_close">
                    <ChevronLeftIcon style={{ color: "white" }} />
                  </IconButton>
                )}
              </DrawerHeader>
              <Box
                sx={{
                  overflow: "auto",
                  overflowX: "hidden",
                  marginTop: "30px",
                }}
                className="themhead"
              >
                <List className={open ? "leftNavbar" : ""}>
                  {props.topBarButtons?.map((text: any, index: number) => {
                    return (
                      <>
                        <ListItem
                          className={`${open ? "menu_item" : "menu_item_open"} ${isActive ? "active" : ""}`}
                          key={index}
                          // disabled={text.name === "Partner Master"}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              // pr: open ? "auto" : 6,
                              justifyContent: "center",
                              width: "10%",
                            }}
                          >
                            <InboxIcon />
                          </ListItemIcon>
                          {open ? (
                            text.isSubTab ? (
                              <span
                                onClick={() => handleListItemClick(text.name)}
                                className="navbar-item isSubTab"
                                style={{ width: "90%" }}
                              >
                                {text.name}
                                {openCollapse[text.name] ? <ExpandLess /> : <ExpandMore />}
                              </span>
                            ) : (
                              // <Link
                              //   style={{ width: "90%" }}
                              //   key={index}
                              //   to={text.to}
                              //   className={text.className}
                              //   onClick={() => {
                              //     setisActive(!isActive);
                              //   }}
                              // >
                              //   {text.name}
                              // </Link>
                              <span
                                style={{ width: "90%", cursor: "pointer" }}
                                className={text.className}
                                onClick={() => {
                                  setisActive(!isActive);
                                  history.push({
                                    pathname: text.to,
                                    state: {
                                      openCollapseObj: openCollapse,
                                    },
                                  });
                                }}
                              >
                                {text.name}
                              </span>
                            )
                          ) : null}
                        </ListItem>
                        {text.isSubTab && open && (
                          <Collapse in={openCollapse[text.name]}>
                            <List component="div" disablePadding={true}>
                              {text.subMenu?.map((subTab: any, subInd: number) => {
                                return (
                                  <ListItem
                                    button={true}
                                    style={{ justifyContent: "end" }}
                                    key={subInd}
                                    disabled={
                                      subTab.name === "Balance Confirmation" || subTab.name === "Ledger Request"
                                        ? false
                                        : true
                                    }
                                  >
                                    {/* <Link
                                        style={{ width: "85%" }}
                                        key={subInd}
                                        to={subTab.to}
                                        className={subTab.className}
                                        onClick={() => {
                                          setisActive(!isActive);
                                        }}
                                      >
                                        {subTab.name}
                                      </Link> */}
                                    <span
                                      style={{ width: "85%", cursor: "pointer" }}
                                      className={subTab.className}
                                      onClick={() => {
                                        history.push({
                                          pathname: subTab.to,
                                          state: {
                                            openCollapseObj: openCollapse,
                                          },
                                        });
                                      }}
                                    >
                                      {subTab.name}
                                    </span>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        )}
                      </>
                    );
                  })}
                </List>
              </Box>
            </Drawer>
          </div>
        </CSSTransition>
        <div className="layout_content">
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
            className="themhead"
            openmenu={open}
          >
            <Toolbar>
              {/* {!open && <div className="logo">
              <a className="navbar-item" href="/">
                <img
                  src={Nakad}
                  alt="NAKAD-logo"
                  className="main-logo"
                  style={{ marginLeft: "0" }}
                />
              </a>
            </div>} */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginLeft: "30px",
                  ...(open && { display: "none" }),
                }}
                className="menu_open"
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Link to="/faq" className="vertical_center_align white_color">
                  FAQs
                </Link>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <Badge color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>

                {/* <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <button className="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                    <span className="name-full">{JSON.parse(localStorage.getItem("actor")).name}</span>

                    {/* Need to make this dynamic */}
                    <span
                      className="name-first"
                      style={{
                        justifyContent: "center",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      <AccountCircle />
                    </span>
                    <span className="icon is-small" style={{ marginLeft: "2px" }}>
                      <i className="fas fa-angle-down" aria-hidden="true" />
                    </span>
                  </button>
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Box component="main" sx={{ flexGrow: 1 }} className="main-rightbar">
            {props.children}
          </Box>
          {renderMobileMenu}
          {renderMenu}
        </div>
      </div>
    </>
  );
};
