import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Axios from "axios";

import React, { useContext, useState } from "react";
import { EmailTemplate, ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { dynamicContentOptions } from "../../../Utils/PartnerCommunication";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { PartnerCommsContext } from "../PartnerCommunication/PartnerCommunicationContext";
import Editor from "./Editor";
import "./SendEmailTemplate.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;
interface CreateEmailTemplateProps {
  setApiErrorMsg: StateDispatch<string>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowSuccessAlert: StateDispatch<boolean>;
  listUserEmailTemplate: (param: string) => void;
  templateType: "LR" | "CB";
}

const CreateEmailTemplate = (props: CreateEmailTemplateProps) => {
  const [dynamicContent, setDynamicContent] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  // const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [createTemplateName, setCreateTemplateName] = useState<any>("");
  const [errCreateTemplateName, setErrCreateTemplateName] = useState<boolean>(false);
  const [errEmailSubject, setErrEmailSubject] = useState<boolean>(false);
  const [selectedEmailTemplateContentForCreateEmailTemplate, setSelectedEmailTemplateContentForCreateEmailTemplate] =
    useState<any>([]);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  // const insertDynamiccontent = (value: string) => {
  //   const textBeforeCursorPosition = dynamicContent.substring(0, cursorPosition);
  //   const textAfterCursorPosition = dynamicContent.substring(cursorPosition, dynamicContent.length);
  //   setDynamicContent(textBeforeCursorPosition + value + textAfterCursorPosition);
  // };

  // Email States START
  const { openCreateEmailTemplate, setOpenCreateEmailTemplate, emailTemplates } = useContext(PartnerCommsContext);
  // Email States END

  const handleClose = () => {
    setOpenCreateEmailTemplate(false);
    setSelectedTemplateName("");
  };

  const clearAllState = () => {
    setDynamicContent("");
    setEmailSubject("");
    setCreateTemplateName("");
    setSelectedTemplateName("");
    setSelectedEmailTemplateContentForCreateEmailTemplate([]);
  };

  const saveCreateEmailTemplate = () => {
    if (createTemplateName.trim() === "") {
      setErrCreateTemplateName(true);
    }
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    if (createTemplateName.trim() !== "" && emailSubject.trim() !== "") {
      const bodyFormData = new FormData();
      bodyFormData.append("templateName", createTemplateName);
      bodyFormData.append("emailBody", dynamicContent);
      bodyFormData.append("emailSubject", emailSubject);
      try {
        Axios.post<any>("/api/AddUserEmailTemplate", {
          templateName: createTemplateName,
          emailBody: dynamicContent,
          emailSubject,
          templateType: props.templateType,
        })
          .then((response) => {
            handleClose();
            clearAllState();
            props.listUserEmailTemplate(createTemplateName);
            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error AddUserEmailTemplate", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}AddUserEmailTemplate`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error AddUserEmailTemplate", error?.response);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}AddUserEmailTemplate`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCreateEmailTemplate}
        // onClose={handleClose}
        aria-labelledby="SendEmailTemplate"
        // className="editBpContactMaster_popup"
      >
        <DialogContent>
          <div className="pt_10 email_text">
            <div>
              <label>
                <b>Template Name</b>
              </label>
              <div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Template Name"
                  value={createTemplateName}
                  error={errCreateTemplateName}
                  helperText={errCreateTemplateName ? "please enter template name" : ""}
                  onChange={(e) => {
                    setCreateTemplateName(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setErrCreateTemplateName(false);
                    } else {
                      setErrCreateTemplateName(true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt_30">
            <div className="mb_25">
              <p>Subject:</p>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter Subject"
                value={emailSubject}
                error={errEmailSubject}
                helperText={errEmailSubject ? "please enter subject" : ""}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrEmailSubject(false);
                  } else {
                    setErrEmailSubject(true);
                  }
                }}
              />
            </div>

            {/* <TextareaAutosize
              minRows={6}
              // maxRows={4}
              onBlur={(e) => {
                // console.log("a", e.target.selectionStart);
                setCursorPosition(e.target.selectionStart);
              }}
              aria-label="maximum height"
              // placeholder=""
              style={{ width: "100%", padding: "10px" }}
              value={dynamicContent}
              onChange={(e) => {
                // console.log("ab", e.target);
                // console.log(e.target.selectionStart);
                setDynamicContent(e.target.value);
                //   setCursorPosition(e.target.selectionStart)
              }}
            /> */}
            <div>
              <Editor
                dynamicContent={dynamicContent}
                setDynamicContent={setDynamicContent}
                selectedTemplateName={selectedTemplateName}
                selectedEmailTemplateContent={selectedEmailTemplateContentForCreateEmailTemplate}
              />
            </div>
          </div>
          <div className="mt_30">
            <div style={{ height: "50px" }} className="mb_15">
              {copyAlert && (
                <Alert severity="success" style={{ width: "200px", height: "48px" }}>
                  Copied!
                </Alert>
              )}
            </div>
            <Grid item={true} xs={12} sm={12} className="d_flex">
              <div className="email_width">
                <Grid item={true} xs={4} sm={4}>
                  <label className="fs_18">Insert Dynamic content</label>
                  <div>
                    <Autocomplete
                      onChange={(e, value: any) => {
                        console.log(e);
                        if (value !== null) {
                          navigator.clipboard.writeText(value);
                          // insertDynamiccontent(value);
                          setCopyAlert(true);
                          setTimeout(() => {
                            setCopyAlert(false);
                          }, 3000);
                        }
                      }}
                      disablePortal={true}
                      id="combo-box-demo"
                      options={dynamicContentOptions}
                      //   value={dynamicContent}
                      //   sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </Grid>
              </div>
              <div className="email_width">
                <Grid item={true} xs={4} sm={4}>
                  <label className="fs_18">Choose template</label>
                  <div>
                    <Select
                      name="Choosetemplate"
                      variant="outlined"
                      fullWidth={true}
                      displayEmpty={true}
                      onChange={(e) => {
                        setSelectedTemplateName(e.target.value);
                        const filteredSelectedTemplate = emailTemplates.filter((item: any) => {
                          return item.templateName === e.target.value;
                        });
                        setCreateTemplateName(filteredSelectedTemplate[0].templateName);
                        setEmailSubject(filteredSelectedTemplate[0].emailSubject);
                        setDynamicContent(filteredSelectedTemplate[0].emailBody);
                        setSelectedEmailTemplateContentForCreateEmailTemplate(filteredSelectedTemplate);
                      }}
                      value={selectedTemplateName}
                    >
                      {emailTemplates.length > 0 &&
                        emailTemplates.map((item: EmailTemplate, itemInd: number) => (
                          <MenuItem key={itemInd} value={item.templateName}>
                            {item.templateName}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="email_dialog_footer_btn">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
                clearAllState();
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn"
              onClick={() => {
                saveCreateEmailTemplate();
              }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateEmailTemplate;
