import { Button, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const DesignationSetting = (props: any) => {
  const errdesignation = useRef<boolean>(false);
  const [isEmptyDesignation, setIsEmptyDesignation] = useState<boolean>(false);

  const bottomRef = useRef<any>();

  useEffect(() => {
    // getListAllDesignation();

    if (props.from === "AddBpUser") {
      addTableRows();
    }

    const newtempListAllDesignation = props.listAllDesignation.map((v: any) => ({ ...v, isError: false }));
    props.setListAllDesignation(newtempListAllDesignation);
    // eslint-disable-next-line
  }, []);

  const saveDesignationSetting = async () => {
    const designationNames: string[] = [];
    let hasDuplicateDesignationNames = false;
    for (const entry of props.listAllDesignation) {
      if (!designationNames.includes(entry.designationName?.trim())) {
        designationNames.push(entry.designationName);
      } else {
        // errdesignation.current = true;
        // setIsEmptyDesignation(true);
        hasDuplicateDesignationNames = true;
        props.setShowErrorAlert(true);
        props.setApiErrorMsg("Duplicate designation name(s) exist");
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
      for (const property in entry) {
        if (property === "designationName") {
          if (entry[property]?.trim() === "") {
            entry.isError = true;
            errdesignation.current = true;
            console.log(isEmptyDesignation);
            setIsEmptyDesignation(true);
          } else {
            errdesignation.current = false;
            setIsEmptyDesignation(false);
          }
        }
      }
    }

    const hasNAObject = props.listAllDesignation?.some(
      (obj: any) => obj.ledgerRequest === "NA" && obj.balanceConfirmation === "NA" && obj.paymentAdvice === "NA"
    );
    if (hasNAObject && !errdesignation.current) {
      props.setShowErrorAlert(true);
      props.setApiErrorMsg("All 'NA' not possible");
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }

    if (!errdesignation.current && !hasDuplicateDesignationNames && !hasNAObject) {
      props.listAllDesignation.forEach((object: any) => {
        delete object.isError;
      });
      console.log("final  props.listAllDesignation", props.listAllDesignation);

      try {
        await axios
          .post<any>("/api/AddDesignations", {
            designations: props.listAllDesignation,
          })
          .then((response) => {
            // if (props.from === "AddBpUser") {
            // props.setDesignationNameObj(designationObj);
            // props.setAddDesignationDialogValue(designation);
            props.getListAllDesignation();
            // props.listAllBPContacts();
            // }
            props.setOpenDesignationSetting(false);
            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error AddDesignation", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}AddDesignation`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error AddDesignation", error?.response);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}AddDesignation`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const designationChange = (index: number, evnt: any) => {
    const { name, value } = evnt.target;
    const rowsInput = [...props.listAllDesignation];
    rowsInput[index][name] = value;
    props.setListAllDesignation(rowsInput);
  };
  const addTableRows = () => {
    const rowsInput: any = {
      defaultDesignation: false,
      designationId: null,
      designationName: "",
      ledgerRequest: "to",
      balanceConfirmation: "to",
      paymentAdvice: "to",
      // warning: "",
      // paymentStopping: "",
      // NOC: "",
      // disputeManagement: "",
      // confirmConfiguration: "",
      // other: "",
    };
    props.setListAllDesignation([...props.listAllDesignation, rowsInput]);
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
    // window.scrollBy(0, -10);
  };

  return (
    <>
      <header className="modal-card-head">
        <p className="modal-card-title">Designation setting</p>
        <button className="delete" aria-label="close" onClick={() => props.setOpenDesignationSetting(false)} />
      </header>
      <section className="modal-card-body">
        <div className="mb_25">
          <Button
            className="theme_btn"
            onClick={() => {
              addTableRows();
            }}
          >
            Add new designation
          </Button>
        </div>
        <div className="table_container">
          <div className="designationSetting table_fixed">
            <table>
              <thead>
                <tr>
                  <th className="text_center">Designation</th>
                  <th className="text_center">Ledger Request</th>
                  <th className="text_center">Balance confirmation</th>
                  <th className="text_center">Payment Advice</th>
                  {/* <th className="text_center">Warning</th>
                  <th className="text_center">Payment stopping</th>
                  <th className="text_center">NOC</th>
                  <th className="text_center">Dispute management</th>
                  <th className="text_center">Confirm Configuration</th>
                  <th className="text_center">Other</th> */}
                </tr>
              </thead>
              <tbody>
                {props.listAllDesignation?.map((data: any, index: number) => (
                  <tr key={index} style={{ backgroundColor: "#ffffff" }}>
                    <td className="text_center">
                      <TextField
                        disabled={data?.nonEditableUsername}
                        size="small"
                        variant="outlined"
                        required={true}
                        fullWidth={true}
                        label="Enter Designation"
                        name="designationName"
                        error={data?.isError}
                        // helperText={data.isError ? "enter designation" : ""}
                        value={data?.designationName}
                        onChange={(e) => {
                          designationChange(index, e);
                          if (e.target.value.trim() !== "") {
                            data.isError = false;
                          } else {
                            data.isError = true;
                          }
                        }}
                      />
                    </td>
                    <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.ledgerRequest}
                        name="ledgerRequest"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={"NA"}>NA</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td>
                    <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.balanceConfirmation}
                        name="balanceConfirmation"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={"NA"}>NA</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td>
                    <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.paymentAdvice}
                        name="paymentAdvice"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={"NA"}>NA</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td>
                    {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.warning}
                        name="warning"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                    {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.paymentStopping}
                        name="paymentStopping"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                    {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.NOC}
                        name="NOC"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                    {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.disputeManagement}
                        name="disputeManagement"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                    {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.confirmConfiguration}
                        name="confirmConfiguration"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                    {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.other}
                        name="other"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                  </tr>
                ))}
              </tbody>
              <div ref={bottomRef} className="mb_80" />
            </table>
          </div>
        </div>
      </section>
      <footer className="modal-card-foot flex_end">
        <div>
          <Button
            className="theme_btn"
            onClick={() => {
              saveDesignationSetting();
            }}
          >
            Save
          </Button>
        </div>
      </footer>
    </>
  );
};

export default DesignationSetting;
