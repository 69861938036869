import { DineroObject } from "dinero.js";
import moment from "moment";
import { FrontendActor } from "../entity/models/FrontendActor";
// import { Tier2Customer } from "../../../src/routes/v0/ListTier2Customers";

export interface Tier2Customer {
  customerActor: FrontendActor;
}

export const toDineroObject = (amountInDecimal: number): DineroObject => {
  if (isNaN(amountInDecimal)) {
    amountInDecimal = 0;
  }
  return {
    amount: Math.abs(Math.round(amountInDecimal)) === 0 ? 0 : Math.floor(amountInDecimal * 100),
    precision: 2,
    currency: "INR",
  } as DineroObject;
};
export const validateDateType = (date: Date): boolean => {
  return moment(date).isValid() && moment().diff(date) > 0 ? true : false;
};

export function findTier1IdByName(customers: Tier2Customer[], name: string): number {
  const found = customers?.find((c) => c.customerActor.name.toLowerCase().trim() === name.toLowerCase().trim());
  if (found === undefined) return -1;
  return found.customerActor.id;
}
