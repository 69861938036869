import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import * as XLSX from "xlsx-js-style";
import { ConfigUseCaseType, ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { userContext } from "../Contexts/userContext";
import PdfTable from "../ReactTable/PdfTable";

import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "./ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import ReconLoader from "./ReconLoader/ReconLoader";

const LedgerPdfUpload = (props: any) => {
  const { actor } = useContext(userContext);
  const headers = Object.keys(props.pdfTableData.JSON[0]);
  const dataList = props.pdfTableData.data;
  // const [selectedPdfConverter, setSelectedPdfConverter] = useState<string>("AWSTextract");

  const data = props.pdfTableData.JSON.map((item: any) => {
    const temp = item;
    for (const [i, header] of headers.entries()) {
      if (header.includes(".")) {
        temp[header.replace(/\./g, "")] = item[header];
        delete item[header];
        headers.splice(i, 1);
        headers.push(header);
      }
    }
    return temp;
  });

  const columnDefinition = Object.keys(data[0]).map((val) => {
    return {
      accessor: val,
      Sort: true,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
      Cell: ({ value }: any) => <div style={{ width: 200 }}>{value}</div>,
    };
  });

  const downloadUploadedExcel = () => {
    XLSX.writeFile(
      props.pdfTableData.workbook,
      `${props.selectedBusinessPartnerName.split(" ")[0]}-(${props.downloadUploadedPdfName})-(${moment(
        new Date()
      ).format("DD/MM/YYYY")}).xlsx`
    );
  };

  const uploadLedger = async (list: any, own: boolean) => {
    if (own) {
      try {
        await axios
          .post("/api/uploadBusinessPartnerLedgerFromRU", {
            ownLedgerData: list,
            businessPartnerId: props.pdfTableData.businessPartnerId,
            configUseCaseType:
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
          })
          .then((response: any) => {
            console.log("uploadLedger", response.data);

            if (response.data.result.response === "warning-unidentified") {
              props.setApiErrorMsg("Unidentified columns present.");
              props.setShowWarningAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowWarningAlert, props.setApiErrorMsg);
            } else if (response.data.result.response === "warning-nonEmptyDocTypes") {
              props.setApiErrorMsg("Unidentified document types present.");
              props.setShowWarningAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowWarningAlert, props.setApiErrorMsg);
            } else if (response.data.result.response === "warning") {
              props.setApiErrorMsg("File uploaded successfully. Duplicate column headers exist. Please review.");
              props.setShowWarningAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowWarningAlert, props.setApiErrorMsg);
            } else {
              props.setApiErrorMsg("File uploaded successfully");
              props.setShowSuccessAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
              props.setShowUploadedSummaryObject(response.data.result.uploadSummary);
              props.setShowUploadedSummary(true);
            }
            props.setEndDateOwn(new Date());
            props.setEndDateBusinessPartner(new Date());
            props.setUploadedFileName(props.pdfTableData.fileName);
            props.setUploadedLastModifiedDate(
              new Date().getDate() +
                "/" +
                (new Date().getMonth() + 1) +
                "  " +
                new Date().toLocaleTimeString("en-US", {
                  // en-US can be set to 'default' to use user's browser settings
                  hour: "2-digit",
                  minute: "2-digit",
                })
            );

            // props.integration
            //   ? props.getOwnDateSyncFromSAP(props.businessPartnerSelected)
            //   : props.getOwnDataGetReconMetaData(props.businessPartnerSelected);

            actor.integration && actor.actorInfo.accountingSoftware === "SAP"
              ? props.getOwnDateSyncFromSAP(props.businessPartnerSelected)
              : actor.integration && actor.actorInfo.accountingSoftware === "Tally"
              ? props.getOwnDataSyncFromTally()
              : props.getOwnDataGetReconMetaData(props.businessPartnerSelected);

            if (response?.data?.result?.unIdentifiedColumns.length > 0) {
              const columnHeaderArrwithErrorFlag = response.data.result.unIdentifiedColumns.map((object: any) => {
                return { ...object, errorFlag: false, columnHeaderConfigValue: "" };
              });
              props.setColumnHeaderArr(columnHeaderArrwithErrorFlag);
              props.setConfigUserType("Own Ledger");
              props.setConfigUserName(actor?.name);
              props.setOpenColumnHeaderDialog(true);
            }
            if (
              response?.data?.result?.nonEmptyDocTypes.length > 0 &&
              props.headerName.nonRelevantDocTypeFlag === false
            ) {
              props.setDocumentTypesArr(response.data.result.nonEmptyDocTypes);
              props.finaldocumentTypesObj.current = Object.fromEntries(
                response.data.result.nonEmptyDocTypes.map((item: any) => [
                  item,
                  {
                    documentType: "",
                    transactionType: 0,
                  },
                ])
              );

              props.setConfigUserType("Own Ledger");
              props.setConfigUserName(actor?.name);
              if (response?.data?.result?.unIdentifiedColumns.length > 0) {
                props.setOpenDocumentTypeDialog(false);
              } else {
                props.setOpenDocumentTypeDialog(true);
              }
            } else {
              props.setDocumentTypesArr([]);
            }
          })
          .catch((error: any) => {
            console.log("error uploadBusinessPartnerLedgerFromRU", error?.response);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromRU`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error uploadBusinessPartnerLedgerFromRU", error?.response);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromRU`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    } else {
      try {
        await axios
          .post("/api/uploadBusinessPartnerLedgerFromBusinessPartner", {
            businessPartnerLedgerData: list,
            businessPartnerId: props.pdfTableData.businessPartnerId,
            configUseCaseType:
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
          })
          .then((response: any) => {
            console.log("uploadLedger", response.data);
            if (response.data.result.response === "warning-unidentified") {
              props.setApiErrorMsg("Unidentified columns present.");
              props.setShowWarningAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowWarningAlert, props.setApiErrorMsg);
            } else if (response.data.result.response === "warning-nonEmptyDocTypes") {
              props.setApiErrorMsg("Unidentified document types present.");
              props.setShowWarningAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowWarningAlert, props.setApiErrorMsg);
            } else if (response.data.result.response === "warning") {
              props.setApiErrorMsg("File uploaded successfully. Duplicate column headers exist. Please review.");
              props.setShowWarningAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowWarningAlert, props.setApiErrorMsg);
            } else {
              props.setApiErrorMsg("File uploaded successfully");
              props.setShowSuccessAlert(true);
              apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
              props.setShowUploadedSummaryObject(response.data.result.uploadSummary);
              props.setShowUploadedSummary(true);
            }
            console.log("props.pdfTableData", props.pdfTableData);
            props.setEndDateBusinessPartner(new Date());
            props.setUploadedFileName(props.pdfTableData.fileName);
            props.setUploadedLastModifiedDate(
              new Date().getDate() +
                "/" +
                (new Date().getMonth() + 1) +
                "  " +
                new Date().toLocaleTimeString("en-US", {
                  // en-US can be set to 'default' to use user's browser settings
                  hour: "2-digit",
                  minute: "2-digit",
                })
            );

            // props.integration
            //   ? props.getBusinessPartnerDateSyncFromSAP(props.businessPartnerSelected)
            //   : props.getBusinessPartnerDateGetReconMetaData(props.businessPartnerSelected);

            props.integration && props.businessPartnerAccountingSoftware === "SAP"
              ? props.getBusinessPartnerDateSyncFromSAP(props.businessPartnerSelected)
              : props.integration && props.businessPartnerAccountingSoftware === "Tally"
              ? props.getBusinessPartnerDataSyncFromTally(props.businessPartnerSelectedCompanyId)
              : props.getBusinessPartnerDateGetReconMetaData(props.businessPartnerSelected);

            if (response?.data?.result?.unIdentifiedColumns.length > 0) {
              const columnHeaderArrwithErrorFlag = response.data.result.unIdentifiedColumns.map((object: any) => {
                return { ...object, errorFlag: false, columnHeaderConfigValue: "" };
              });
              props.setColumnHeaderArr(columnHeaderArrwithErrorFlag);
              props.setConfigUserType("BusinessPartner Ledger");
              props.setConfigUserName(props.assignBusinessPartnerName);
              props.setOpenColumnHeaderDialog(true);
            }

            if (
              response?.data?.result?.nonEmptyDocTypes.length > 0 &&
              props.headerName.nonRelevantDocTypeFlag === false
            ) {
              props.setDocumentTypesArr(response.data.result.nonEmptyDocTypes);
              props.finaldocumentTypesObj.current = Object.fromEntries(
                response.data.result.nonEmptyDocTypes.map((item: any) => [
                  item,
                  {
                    documentType: "",
                    transactionType: 0,
                  },
                ])
              );

              props.setConfigUserType("BusinessPartner Ledger");
              props.setConfigUserName(props.assignBusinessPartnerName);
              if (response?.data?.result?.unIdentifiedColumns.length > 0) {
                props.setOpenDocumentTypeDialog(false);
              } else {
                props.setOpenDocumentTypeDialog(true);
              }
            } else {
              props.setDocumentTypesArr([]);
            }
          })
          .catch((error: any) => {
            console.log("error uploadBusinessPartnerLedgerFromBusinessPartner", error?.response);
            console.log("props.pdfTableData", props.pdfTableData);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromBusinessPartner`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error uploadBusinessPartnerLedgerFromBusinessPartner", error?.response);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromBusinessPartner`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  return (
    <>
      {props.pdfConversionLoader && <ReconLoader />}
      <header className="modal-card-head">
        <div className="w_100_per space_between">
          <div>
            <p className="modal-card-title">Review the file before uploading back</p>
          </div>
          <div>
            <FormControl>
              <RadioGroup
                row={true}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={props.selectedPdfConverter.current}
                onChange={(e) => {
                  console.log("selectedPdfConverter", e.target.value);
                  props.selectedPdfConverter.current = e.target.value;
                  props.setPdfConversionLoader(true);
                  props.isPdfOptionConfirm.current = true;
                  props.onChangePdfConversionMethod();
                }}
              >
                {!props.disablePdfTableOptionForMultiplePdf && (
                  <>
                    <FormControlLabel value="PDFLedgerParser" control={<Radio />} label="Tally" />
                    <FormControlLabel value="AWSTextract" control={<Radio />} label="Option 2" />
                    <FormControlLabel value="PDFTables" control={<Radio />} label="Option 3" />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </header>
      <section className="modal-card-body">
        <Grid>
          <p className="note_pdf">
            Note: Highlighted columns headers have been identified, check if the information in column below is correct.
          </p>
        </Grid>
        <Grid>
          <PdfTable
            columns={columnDefinition}
            rows={data}
            sortEnable={false}
            pdfTableHeading={true}
            errorMessage={props?.pdfTableData?.errorMessage}
            highlightedColumns={props?.pdfTableData?.highlightedColumns}
          />
        </Grid>
      </section>
      <footer className="modal-card-foot" style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {false && (
            <Button
              className="button is-success"
              onClick={() => {
                uploadLedger(dataList, props.pdfTableData.own);
              }}
            >
              Approve
            </Button>
          )}
          <Button
            className="button is-success"
            onClick={() => {
              downloadUploadedExcel();
            }}
          >
            Download
          </Button>
        </div>
        <div>
          <Button
            className="button is-success"
            onClick={() => {
              props.setOpenPdfDialog(false);
            }}
          >
            Close
          </Button>
        </div>
      </footer>
    </>
  );
};

export default LedgerPdfUpload;
