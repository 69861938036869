import React, { useContext } from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { ConfigPopupAnchorRecon360Context } from "../Transformer/ColumnHeaderConfig";
import DraggableListItem from "./DraggableListItem";

export type Item = {
  category: string;
  n: false;
  rule: string;
  ruleId: 0;
  string1: true;
  string2: false;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
};
export type rule = {
  category: string;
  n: false;
  rule: string;
  ruleId: 0;
  string1: true;
  string2: false;
};

export type DraggableListProps = {
  items: Item[];
  onDragEnd: OnDragEndResponder;
  setItems: (items: Item[]) => void;
  rules: rule[];
};

const DraggableList = (props: any) => {
  const types = useContext(ConfigPopupAnchorRecon360Context);
  console.log(
    "[saveRules, columnName, labelName, open, setOpen, getRules, rules, items, setItems, rulesWithVal, setRulesWithVal, isDelete, handleRemoveClick]",
    types
  );

  console.log("items in draggablelist = ", types.items);

  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {types.items.map((item: any, index: number) => (
              <DraggableListItem
                item={item}
                index={index}
                key={"item" + index}
                disabledrag={props.disabledrag}
                setDisabledrag={props.setDisabledrag}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
