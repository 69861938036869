import { MenuItem, Pagination, Select, Skeleton, Typography } from "@mui/material";
import Card from "@mui/material/Card";
// import { useBlockLayout, useExpanded, useFilters, usePagination, useRowSelect, useSortBy } from "react-table";
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { ColumnFilter } from "./ColumnFilter";
import "./Table.scss";

const defaultPropGetter = () => ({});

const Styles = styled.div`
  .table {
    // border: 1px solid #ddd;
    border-collapse: collapse;

    thead tr {
      background-color: #531c4c;
    }
    tbody tr {
      align-items: center;
      min-height: 30px;
    }
    tr:nth-child(even) {
      font-family: "WorkSans";
      background-color: #ffffff;
      // border-bottom: 0.1px solid #6c6c6c;
    }

    tr:nth-child(odd) {
      font-family: "WorkSans";
      background-color: #eaf0f9;
      // border-bottom: 0.1px solid #6c6c6c;
    }
    tr:last-child {
      border-bottom: 1px solid #dbdbdb;
    }

    th {
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      font-family: "WorkSans";
      text-align: center;
      background-color: #531c4c !important;
      color: white;
      padding: 10px 0px 10px 0px;
      border-bottom: 1px solid #53526c;
    }
    .th-bg {
      background-color: #391033;
    }
    td {
      padding: 11px;
      font-family: "WorkSans";
      text-align: center;
      color: black;
      border: none;
      min-height: 30px;
      word-break: break-word;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        background-color: inherit;
      }
      .stickyLeft,
      .stickyRight {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        left: var(--data-val, 0);
        height: var(--height, 30px);
        z-index: 2;
        background-color: inherit;
      }
      .stickyRight {
        left: unset;
        right: var(--data-val, 0);
      }

      [data-sticky-last-left-td],
      [data-sticky-first-right-td] {
        content: " ";
        // box-shadow: -4px 0 8px -6px rgba(0, 0, 0, 0.2) inset;
        clip-path: inset(0px -30px 0px 0px);
        box-shadow: -4px 0px 10px 2px rgba(0, 0, 0, 0.2);
      }

      [data-sticky-first-right-td] {
        clip-path: inset(0px 0px 0px -30px);
        box-shadow: -3px 0px 10px 1px rgb(0 0 0 / 10%);
      }
    }
  }
`;

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type Modify<T, R> = Omit<T, keyof R>;

type _PartialColumnDef = Modify<
  ColumnDef<any, any>,
  {
    accessorFn?: () => undefined;
  }
>;

type ColumnDefs = _PartialColumnDef & { sticky?: "left" | "right" };

enum stickyClassName {
  left = "stickyLeft",
  right = "stickyRight",
}

interface TableProps<T extends object> {
  columns: ColumnDefs[];
  rows: T[];
  sortEnable?: boolean;
  setSelectedRow?: StateDispatch<T[] | {}[]>;
  actorType?: string;
  id?: string;
  loading?: boolean;
  reconInsightSortOn?: string;
  fromTab?: string;
  callApiOnPagination?: () => undefined | void;
  getCellProps?: (props: any) => {};
  renderRowSubComponent?: (...props: any) => undefined;
  setTableInstance?: StateDispatch<Table<any>>;
  setAllLeafColumns?: StateDispatch<Column<any>[]>;
}

function BpDashboardTable<T extends object>({
  columns,
  rows,
  getCellProps = defaultPropGetter,
  id,
  loading,
  // sortEnable,
  renderRowSubComponent,
  setSelectedRow,
  actorType,
  reconInsightSortOn,
  fromTab,
  callApiOnPagination,
  setTableInstance,
  setAllLeafColumns,
}: TableProps<T>) {
  const columnsData = useMemo(() => columns, [columns]);

  const rowsData = useMemo(() => rows, [rows]);

  const [rowSelection, setRowSelection] = React.useState({});

  const tableInstance = useReactTable({
    columns: columnsData as ColumnDef<any>[],
    data: rowsData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 100 } },
    state: {
      rowSelection,
      globalFilter: ColumnFilter,
    },
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
  });

  const {
    getHeaderGroups,
    getRowModel,
    getPageCount,
    setPageSize,
    setPageIndex,
    getState,
    setSorting,
    getFilteredRowModel,
    getSelectedRowModel,
    getAllLeafColumns,
  } = tableInstance;

  const {
    pagination: { pageIndex, pageSize },
  } = getState();

  const setCSSVarsOnStickyEl = (elArr: NodeListOf<Element>, reverse = false) => {
    elArr.forEach((stickyEl, i) => {
      const el = stickyEl as HTMLDivElement;
      const lastI = elArr.length - 1;
      const leftNextWidth = i !== 0 ? elArr[i - 1].clientWidth.toString() + "px" : "0";
      const rightPrevWidth = i !== lastI ? elArr[i + 1].clientWidth.toString() + "px" : "0";
      el.style.setProperty("--height", el.parentElement.scrollHeight + "px");

      if (reverse) {
        el.style.setProperty("--data-val", rightPrevWidth);
        elArr[0].setAttribute(`data-sticky-first-right-td`, "");
      } else {
        el.style.setProperty("--data-val", leftNextWidth);
        i === lastI
          ? elArr[i].setAttribute(`data-sticky-last-left-td`, "")
          : elArr[i].removeAttribute(`data-sticky-last-left-td`);
      }
    });
  };

  useEffect(() => {
    if (actorType === "admin" || actorType === "BPDashboard") {
      const filterSelectedFlatRows = getSelectedRowModel().flatRows.map((item) => item.original);

      setSelectedRow(filterSelectedFlatRows);
    }

    if (setTableInstance && tableInstance) {
      setTableInstance(tableInstance);
    }

    if (setAllLeafColumns && getAllLeafColumns) {
      setAllLeafColumns(getAllLeafColumns());
    }
  }, [
    actorType,
    getAllLeafColumns,
    getSelectedRowModel,
    rowSelection,
    setAllLeafColumns,
    setSelectedRow,
    setTableInstance,
    tableInstance,
  ]);

  useEffect(() => {
    if (fromTab === "reconMIS") {
      setSorting([{ id: reconInsightSortOn, desc: true }]);
    }
    // eslint-disable-next-line
  }, []);
  const callApiOnPaginationHandler = () => {
    // this will no need when pagination apply on all table
    if (fromTab === "reconMIS") {
      callApiOnPagination();
    }
  };

  return (
    <>
      <Card
        sx={{
          overflowX: "scroll",
          p: fromTab !== "ManualRecon" ? 2.5 : null,
          borderRadius: fromTab !== "ManualRecon" ? 1.5 : null,
        }}
      >
        {/* <CssBaseline /> */}
        <Styles>
          <div className="table_fixed_div" style={{ borderRadius: fromTab !== "ManualRecon" ? "12px" : null }}>
            <table
              id={id}
              style={{
                borderRadius: fromTab !== "ManualRecon" ? "1%" : null,
                width: fromTab !== "ManualRecon" ? 1000 : null,
                height: 500,
              }}
              className="table sticky"
            >
              <thead>
                {getHeaderGroups().map((headerGroup, iHead) => (
                  <tr key={"thead_tr" + iHead} className="tr" style={{ display: "flex" }}>
                    {headerGroup.headers.map((header, iRow) => {
                      const isSorted = header.column.getIsSorted();
                      const sticky = (header.column.columnDef as ColumnDefs).sticky;

                      return (
                        <th
                          colSpan={header.colSpan}
                          key={header.id + iRow}
                          style={{ width: header.getSize(), gap: 8 }}
                          onClick={header.column.getToggleSortingHandler()}
                          className={header.column.getCanSort() ? "" : "noSort"}
                          ref={(th) => {
                            if (th) {
                              if (stickyClassName[sticky]) th.classList.add(stickyClassName[sticky]);
                              const leftStick = th.parentElement.querySelectorAll("th.stickyLeft");
                              const rightStick = th.parentElement.querySelectorAll("th.stickyRight");
                              setCSSVarsOnStickyEl(leftStick);
                              setCSSVarsOnStickyEl(rightStick, true);
                            }
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}{" "}
                          {isSorted && (
                            <div
                              style={{ transition: "all .25s ease-out" }}
                              ref={(div) => div?.classList?.toggle("rotated", isSorted === "desc")}
                            >
                              <i className="fa fa-long-arrow-alt-up" aria-hidden="true" />
                            </div>
                          )}
                          {header.column.getCanSort() && isSorted === false && (
                            <div className="header">
                              <i className="sort-icon fa fa-sort" aria-hidden="true" />
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="body">
                {getRowModel().rows.map((row, iRow) => {
                  return (
                    <React.Fragment key={row.id}>
                      <tr key={"tbody_tr" + iRow} className="tr" style={{ display: "flex" }}>
                        {row.getVisibleCells().map((cell, iCell) => {
                          const sticky = (cell.column.columnDef as ColumnDefs).sticky;
                          return (
                            <td
                              key={cell.id + iCell}
                              style={{ width: cell.column.getSize() }}
                              ref={(td) => {
                                if (td) {
                                  if (stickyClassName[sticky]) td.classList.add(stickyClassName[sticky]);
                                  const leftStick = td.parentElement.querySelectorAll("td.stickyLeft");
                                  const rightStick = td.parentElement.querySelectorAll("td.stickyRight");
                                  setCSSVarsOnStickyEl(leftStick);
                                  setCSSVarsOnStickyEl(rightStick, true);
                                }
                              }}
                              {...getCellProps(cell)}
                            >
                              {loading ? (
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: 12, borderRadius: 12, width: "100%" }}
                                  animation="wave"
                                />
                              ) : (
                                flexRender(cell.column.columnDef.cell, cell.getContext())
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      {row.getIsExpanded &&
                        renderRowSubComponent &&
                        renderRowSubComponent({
                          row,
                        })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Styles>
        {/* Pagination And Table Footer Component */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: fromTab !== "ManualRecon" ? "15px" : "5px",
            paddingLeft: "10px",
            marginTop: "15px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            {`${pageSize * pageIndex + 1}–${
              pageSize * (pageIndex + 1) < getFilteredRowModel().rows.length
                ? pageSize * (pageIndex + 1)
                : getFilteredRowModel().rows.length
            } of ${getFilteredRowModel().rows.length}`}
          </Typography>
          <Select
            value={pageSize}
            size="small"
            sx={{
              "&>div": { py: "2px" },
              "& > fieldset": { borderWidth: "0.5px" },
            }}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50, 100, 200].map((PageSize) => (
              <MenuItem key={PageSize} value={PageSize}>
                {PageSize} per Page
              </MenuItem>
            ))}
          </Select>
          <div style={{ flex: 1 }} />
          <Pagination
            count={getPageCount()}
            page={pageIndex + 1}
            defaultPage={1}
            siblingCount={2}
            boundaryCount={2}
            onChange={(_e, pageNum) => {
              setPageIndex(pageNum - 1);
              callApiOnPaginationHandler();
            }}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "rgba(84, 28, 76, 0.1) !important",
              },
            }}
          />
        </div>
      </Card>
    </>
  );
}

export default BpDashboardTable;
