export const dynamicContentOptions = [
  "{BP NAME}",
  // "{BP USER NAME}",
  "{CLOSING BALANCE}",
  "{CLOSING BALANCE DATE}",
  "{CURRENT DATE}",
  "{OWN LEDGER RECON START DATE}",
  "{OWN LEDGER RECON END DATE}",
  "{OWN LEDGER OLDEST ENTRY}",
  "{OWN LEDGER LATEST ENTRY}",
  "{BP LEDGER RECON START DATE}",
  "{BP LEDGER RECON END DATE}",
  "{BP LEDGER OLDEST ENTRY}",
  "{BP LEDGER LATEST ENTRY}",
  "{RECON AMOUNT MISMATCH}",
  "{OWN LEDGER EXTRA/UNMATCHED ENTRIES}",
  "{ENTRIES UNACCOUNTED IN OWN LEDGER}",
  "{STARTING OF THE CURRENT FY}",
  "{PREVIOUS MONTH'S LAST DATE}",
  "{UNPAID INVOICES}",
  "{LOCATION}",
  "{PAYMENT DETAILS}",
  "{PARTNER USER NAME}",
];
