import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./LedgerRequestFilter.scss";

type setAppliedFilters = React.Dispatch<
  React.SetStateAction<{
    [key: string]: string;
  }>
>;
const BalanceConfirmationFilter = (props: { [key: string]: any; setAppliedFilters: setAppliedFilters }) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [fromDateAsOfLastStatusChange, setFromDateAsOfLastStatusChange] = useState<string>(null);
  const [toDateAsOfLastStatusChange, setToDateAsOfLastStatusChange] = useState<string>(null);
  const [minClosingBalance, setMinClosingBalance] = useState<number>(null);
  const [maxClosingBalance, setMaxClosingBalance] = useState<number>(null);
  const [fromDateOfClosingBalance, setFromDateOfClosingBalance] = useState<string>(null);
  const [toDateOfClosingBalance, setToDateOfClosingBalance] = useState<string>(null);
  const [fromDateAsOfLastBalanceConfirmed, setFromDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [toDateAsOfLastBalanceConfirmed, setToDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [partnerName, setPartnerName] = useState<string>("");
  const BalanceConfirmationStatus = [
    "Balance confirmation requested",
    "Check partner reply",
    "Reminder sent",
    "Configuration Underway",
    "Request balance confirmation",
    "Download ledger",
    "Balance confirmed",
    "Send Reminder",
    "Recon shared with Partner",
  ];

  const handleClose = () => {
    props.setOpenBalanceConfirmationFilter(false);
  };

  const filterBalanceConfirmation = () => {
    let tempRowData = props.storeRowsDataBalanceConfirmation;

    if (partnerName?.trim()) {
      const partnerNameWithoutMultiSpace = partnerName.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr.trim());
      });
    }
    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedCategory?.some((val) => item.category?.includes(val));
      });
    }
    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedStatus?.some((val) =>
          item.balanceConfirmationStatus?.toLowerCase()?.includes(val?.toLowerCase())
        );
      });
    }

    if (fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") <= toDateAsOfLastStatusChange &&
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") >= fromDateAsOfLastStatusChange
        );
      });
    }
    if (fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") >= fromDateAsOfLastStatusChange
        );
      });
    }
    if (fromDateAsOfLastStatusChange === null && toDateAsOfLastStatusChange) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          moment(item.dateOfLastBalanceConfirmationStatusChange)?.format("YYYY-MM-DD") <= toDateAsOfLastStatusChange
        );
      });
    }

    if (minClosingBalance && maxClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          Number(item.closingBalance) <= Number(maxClosingBalance) &&
          Number(item.closingBalance) >= Number(minClosingBalance)
        );
      });
    }
    if (minClosingBalance && maxClosingBalance === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return Number(item.closingBalance) >= Number(minClosingBalance);
      });
    }
    if (minClosingBalance === null && maxClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return Number(item.closingBalance) <= Number(maxClosingBalance);
      });
    }

    if (fromDateOfClosingBalance && toDateOfClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        if (item.dateOfClosingBalance) {
          return (
            moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") <= toDateOfClosingBalance &&
            moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") >= fromDateOfClosingBalance
          );
        }
        return false;
      });
    }
    if (fromDateOfClosingBalance && toDateOfClosingBalance === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        if (item.dateOfClosingBalance) {
          return moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") >= fromDateOfClosingBalance;
        }
        return false;
      });
    }
    if (fromDateOfClosingBalance === null && toDateOfClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        if (item.dateOfClosingBalance) {
          return moment(item.dateOfClosingBalance)?.format("YYYY-MM-DD") <= toDateOfClosingBalance;
        }
        return false;
      });
    }
    if (fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return (
          moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") <= toDateAsOfLastBalanceConfirmed &&
          moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") >= fromDateAsOfLastBalanceConfirmed
        );
      });
    }
    if (fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed === null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") >= fromDateAsOfLastBalanceConfirmed;
      });
    }
    if (fromDateAsOfLastBalanceConfirmed === null && toDateAsOfLastBalanceConfirmed) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return moment(item.latestBalanceConfirmedOn)?.format("YYYY-MM-DD") <= toDateAsOfLastBalanceConfirmed;
      });
    }

    props.setAppliedFilters({
      "Partner Name": partnerName?.trim(),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "Closing Balance":
        minClosingBalance && maxClosingBalance
          ? `${minClosingBalance} - ${maxClosingBalance}`
          : minClosingBalance
          ? `Minimum: ${minClosingBalance}`
          : maxClosingBalance
          ? `Maximum: ${maxClosingBalance}`
          : "",
      "Date As of Last Status Change":
        fromDateAsOfLastStatusChange && toDateAsOfLastStatusChange
          ? [fromDateAsOfLastStatusChange, toDateAsOfLastStatusChange].join(" - ")
          : fromDateAsOfLastStatusChange
          ? `${fromDateAsOfLastStatusChange} Onwards`
          : toDateAsOfLastStatusChange
          ? `Till ${toDateAsOfLastStatusChange}`
          : "",
      "Date of Closing balance":
        fromDateOfClosingBalance && toDateOfClosingBalance
          ? [fromDateOfClosingBalance, toDateOfClosingBalance].join(" - ")
          : fromDateOfClosingBalance
          ? `${fromDateOfClosingBalance} Onwards`
          : toDateOfClosingBalance
          ? `Till ${toDateOfClosingBalance}`
          : "",
      "Date As of Last Balance Confirmed":
        fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed
          ? [fromDateAsOfLastBalanceConfirmed, toDateAsOfLastBalanceConfirmed].join(" - ")
          : fromDateAsOfLastBalanceConfirmed
          ? `${fromDateAsOfLastBalanceConfirmed} Onwards`
          : toDateAsOfLastBalanceConfirmed
          ? `Till ${toDateAsOfLastBalanceConfirmed}`
          : "",
    });

    props.setRowsDataBalanceConfirmation(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterBalanceConfirmation();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);
    setFromDateAsOfLastStatusChange(null);
    setToDateAsOfLastStatusChange(null);
    setMinClosingBalance(null);
    setMaxClosingBalance(null);
    setFromDateOfClosingBalance(null);
    setToDateOfClosingBalance(null);
    setFromDateAsOfLastBalanceConfirmed(null);
    setToDateAsOfLastBalanceConfirmed(null);
    setPartnerName(null);

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenBalanceConfirmationFilter(true);
      props.setRowsDataBalanceConfirmation(props.storeRowsDataBalanceConfirmation);
      props.setIsFilterAppliedBalanceConfirmation(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openBalanceConfirmationFilter}
        aria-labelledby="BalanceConfirmationFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Name :</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={props.storeRowsDataBalanceConfirmation?.map((option) => option?.businessPartnerName)}
                  value={partnerName}
                  onChange={(event, value) => {
                    console.log(event);
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setPartnerName(value);
                    } else setPartnerName("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(e: any, value: any) => {
                    console.log(e);
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option: any) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(e: any, value: any) => {
                    console.log(e);
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={BalanceConfirmationStatus || []}
                  getOptionLabel={(option: any) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date as of last status change :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateAsOfLastStatusChange}
                  onChange={(e: any) => {
                    setFromDateAsOfLastStatusChange(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateAsOfLastStatusChange}
                  onChange={(e: any) => {
                    setToDateAsOfLastStatusChange(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Closing Balance :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="Min"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  value={minClosingBalance}
                  onChange={(e: any) => {
                    setMinClosingBalance(e.target.value.trim());
                  }}
                />
                <TextField
                  label="Max"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  value={maxClosingBalance}
                  onChange={(e: any) => {
                    setMaxClosingBalance(e.target.value.trim());
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date of closing balance :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateOfClosingBalance}
                  onChange={(e: any) => {
                    setFromDateOfClosingBalance(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateOfClosingBalance}
                  onChange={(e: any) => {
                    setToDateOfClosingBalance(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date as of last balance confirmed :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateAsOfLastBalanceConfirmed}
                  onChange={(e: any) => {
                    setFromDateAsOfLastBalanceConfirmed(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateAsOfLastBalanceConfirmed}
                  onChange={(e: any) => {
                    setToDateAsOfLastBalanceConfirmed(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterBalanceConfirmation_button"
              onClick={() => {
                filterBalanceConfirmation();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BalanceConfirmationFilter;
