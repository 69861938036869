import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import React, { MouseEventHandler } from "react";
import ReactDOM from "react-dom";
export interface DialogProps {
  open: boolean;
  onClose: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}
export const Dialog = (props: React.PropsWithChildren<any>) => {
  const renderDialog = () => {
    if (props.open) {
      // console.log(props.open);
      return (
        <div className="modal" style={{ display: "flex" }}>
          <div className="modal-background" onClick={props.onClose} />
          <div className="modal-card" style={{ width: props.width70Per ? "70vw" : null }}>
            {props.children}
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={props.onClose} />
        </div>
      );
    }
  };
  // console.log(props.open);

  return props.open ? ReactDOM.createPortal(renderDialog(), document.body) : null;
};
