import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useLocation } from "react-router";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getAdminTopBarButtons, getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import "./Faq.scss";

const Faq = () => {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();
  const Faqs = [
    {
      question: "How to login in Recon360?",
      answer:
        "Go to app.nakad.co Type in your username and password. After logging in, you have to click on Recon360 tab which will be present in the left pane.",
    },
    {
      question: "What is “Business Partner”?",
      answer:
        "The term “Business Partner” refers to all the companies or the entities with whom you are doing business with. They may be Customers, Suppliers, or another firm with whom you have both buy and sell transactions.",
    },
    {
      question: "What should be the format of our ledgers?",
      answer:
        "Our tool is capable of handling both Excel (.xlsx file only) and PDF ledgers. It accepts both ledgers (your company’s ledger and business partner ledger) in pdf format, or in excel, or 1 in pdf and another in Excel. Still, if you have an option, kindly upload Excel ledgers.",
    },
    {
      question: "I want to reconcile my ledgers with my business partner. What is the process to do the same?",
      answer: (
        <>
          Kindly follow the following steps.
          <br />
          1. Visit app.nakad.co. Login using your username and password.
          <br />
          2. Visit Recon360 tab.
          <br />
          3. Select your business partner, and then fill in the closing balance.
          <br />
          4. Upload the ledgers one by one and then click on reconcile ledgers.
          <br />
          5. You can see the result summary on the website itself. For detailed results, kindly download the results by
          clicking on the “Download Results” button. For more detailed steps, you can check out this PPT.
        </>
      ),
    },
    {
      question: "I am getting an error, which has not been covered in FAQ Section. What should I do now?",
      answer: "Kindly take a screenshot and mail it to support@nakad.co. We will check and reply to you asap.",
    },
    {
      question: "I am not able to find my Business Partner in the drop-down list. What should I do now?",
      answer:
        "First, check for the possible spellings or try long forms, short forms with “.” Etc. If you are not able to find after doing all this, you can add your business partner by clicking on “Add Business Partner” tab. Fill in the details as asked and you will have that business partner added successfully.",
    },
    {
      question:
        "I got an error saying, “Config for your business partner doesn’t exist.” What does it mean and what should I do?",
      answer:
        "It means that configuration for the business partner you selected has not been done. You have to create the configuration for your business partner. You may refer to this video",
    },
  ];
  return (
    <LoggedInSkeleton
      topBarButtons={
        actor.actorType === "Admin"
          ? getAdminTopBarButtons("Userlist")
          : getReconTopBarButtons("Recon360", actor.name, location?.state?.openCollapseObj, actor)
      }
    >
      <Grid container={true} sx={{ p: 6, pt: 0 }} className="faq_container">
        <Grid md={12} sm={12} xs={12}>
          <Grid md={12} sm={12} xs={12} className="faq_title">
            FREQUENTLY ASKED QUESTIONS
          </Grid>
          <Grid md={12} sm={12} xs={12} sx={{ p: 12, pt: 2 }}>
            {Faqs.map((val, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography className="question">{val.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{val.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </LoggedInSkeleton>
  );
};

export default Faq;
