import React, { useContext, useEffect, useState } from "react";
import { toDineroObject } from "../../Utils/BulkUploadUtility";
import { formatMoney } from "../../Utils/MoneyUtils";
import { userContext } from "../Contexts/userContext";
function SubRows({ row, rowProps, data }: any) {
  // console.log("SubRows", row);
  return (
    <>
      {data.map((x: any, i: any) => {
        return (
          <tr {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
            {row.cells.map((cell: any, index: any) => {
              return (
                <td
                  {...cell.getCellProps()}
                  key={index}
                  className={cell.column.Header === "See details" ? "openBtnPadding" : ""}
                >
                  {cell.render(cell.column.SubCell ? "SubCell" : "Cell", {
                    value: cell.column.accessor && cell.column.accessor(x, i),
                    row: { ...row, original: x },
                  })}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

const CollapseSubRow = ({
  row,
  rowProps,
  visibleColumns,
  invoiceAmountMismatchOthers,
  paymentAmountMismatchOthers,
  debitNoteCreditNoteAmountMismatchOthers,
  invoiceUnaccountedInBusinessPartner,
  paymentNotRecognisedInBusinessPartner,
  dncnNotRecognisedInBusinessPartner,
  invoiceUnaccountedInOwn,
  extraUnmatchedPaymentInBusinessPartner,
  extraUnmatchedDNCNInBusinessPartner,
  tdsNotRecognisedInBusinessPartner,
  extraUnmatchedTDSInBusinessPartner,
  suggestedTDSGap,
  miscellaneousOwnSOA,
  miscellaneousBusinessPartnerSOA,
  openingBalanceOwnSOA,
  openingBalanceBusinessPartnerSOA,
  openDetailsMismatched,
  openDetailsExtra,
  openDetailsMissed,
  openPaymentAmountMismatch,
  openPaymentAmountExtra,
  openTDSAmountExtra,
  openPaymentAmountMissed,
  openTDSAmountMissed,
  openDNCNMismatch,
  openDNCNExtra,
  openDNCNMissed,
  openTDSMismatched,
  openOwnMiscellaneous,
  openBusinessPartnerMiscellaneous,
  assignBusinessPartnerName,
}: any) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { actor } = useContext(userContext);

  useEffect(() => {
    // const timer = setTimeout(() => {
    if (row.id === "1") {
      setData([
        {
          line_item: <p className="table_subrow">Amount Mismatch</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.Others)),
          sub_total: formatMoney(toDineroObject(invoiceAmountMismatchOthers)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button
              className="button is-bold is-success open_btn"
              type="button"
              onClick={() => openDetailsMismatched()}
            >
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">Invoice not present in {assignBusinessPartnerName}'s Ledger</p>,
          sub_total: formatMoney(toDineroObject(invoiceUnaccountedInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openDetailsExtra()}>
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">Invoice not present in {actor.name}'s Ledger</p>,
          sub_total: formatMoney(toDineroObject(invoiceUnaccountedInOwn)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openDetailsMissed()}>
              Open
            </button>
          ),
        },
      ]);
      setLoading(false);
    }

    if (row.id === "2") {
      setData([
        {
          line_item: <p className="table_subrow">Amount Mismatch</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.Others)),
          sub_total: formatMoney(toDineroObject(paymentAmountMismatchOthers)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button
              className="button is-bold is-success open_btn"
              type="button"
              onClick={() => openPaymentAmountMismatch()}
            >
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">Payment not present in {assignBusinessPartnerName}'s Ledger</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.suggestedTDSMismatch)),
          sub_total: formatMoney(toDineroObject(paymentNotRecognisedInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button
              className="button is-bold is-success  open_btn"
              type="button"
              onClick={() => openPaymentAmountExtra()}
            >
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">Payment not present in {actor.name}'s Ledger</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.Others)),
          sub_total: formatMoney(toDineroObject(extraUnmatchedPaymentInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button
              className="button is-bold is-success open_btn"
              type="button"
              onClick={() => openPaymentAmountMissed()}
            >
              Open
            </button>
          ),
        },
      ]);
      setLoading(false);
    }

    if (row.id === "3") {
      setData([
        {
          line_item: <p className="table_subrow">Amount Mismatch</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.Others)),
          sub_total: formatMoney(toDineroObject(debitNoteCreditNoteAmountMismatchOthers)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openDNCNMismatch()}>
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">DN/CN not present in {assignBusinessPartnerName}'s Ledger</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.suggestedTDSMismatch)),
          sub_total: formatMoney(toDineroObject(dncnNotRecognisedInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openDNCNExtra()}>
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">DN/CN not present in {actor.name}'s Ledger</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.Others)),
          sub_total: formatMoney(toDineroObject(extraUnmatchedDNCNInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openDNCNMissed()}>
              Open
            </button>
          ),
        },
      ]);
      setLoading(false);
    }
    if (row.id === "4") {
      setData([
        {
          line_item: <p className="table_subrow">TDS/TCS not present in {assignBusinessPartnerName}'s Ledger</p>,
          // sub_total: formatMoney(toDineroObject(summaryObjectInvoice.suggestedTDSMismatch)),
          sub_total: formatMoney(toDineroObject(tdsNotRecognisedInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openTDSAmountExtra()}>
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">TDS/TCS not present in {actor.name}'s Ledger</p>,
          sub_total: formatMoney(toDineroObject(extraUnmatchedTDSInBusinessPartner)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openTDSAmountMissed()}>
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">Suggested TDS gap in invoice/payment/DN/CN value</p>,
          sub_total: formatMoney(toDineroObject(suggestedTDSGap)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openTDSMismatched()}>
              Open
            </button>
          ),
        },
      ]);
      setLoading(false);
    }
    if (row.id === "5") {
      setData([
        {
          line_item: <p className="table_subrow">In {actor.name}'s SOA</p>,
          sub_total: formatMoney(toDineroObject(miscellaneousOwnSOA)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button className="button is-bold is-success open_btn" type="button" onClick={() => openOwnMiscellaneous()}>
              Open
            </button>
          ),
        },
        {
          line_item: <p className="table_subrow">In {assignBusinessPartnerName}'s SOA</p>,
          sub_total: formatMoney(toDineroObject(miscellaneousBusinessPartnerSOA)),
          total: "",
          num_of_item: "",
          value_of_item: "",
          details: (
            <button
              className="button is-bold is-success open_btn"
              type="button"
              onClick={() => openBusinessPartnerMiscellaneous()}
            >
              Open
            </button>
          ),
        },
      ]);
      setLoading(false);
    }
    if (row.id === "8") {
      setData([
        {
          line_item: <p className="table_subrow">Opening balance of {actor.name}</p>,
          sub_total: formatMoney(toDineroObject(openingBalanceOwnSOA)),
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
        {
          line_item: <p className="table_subrow">Opening balance of {assignBusinessPartnerName}</p>,
          sub_total: formatMoney(toDineroObject(openingBalanceBusinessPartnerSOA)),
          total: "",
          num_of_item: "",
          value_of_item: "",
        },
      ]);
      setLoading(false);
    }
    // }, 300);

    // return () => {
    //   clearTimeout(timer);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    row.id,
    invoiceAmountMismatchOthers,
    paymentAmountMismatchOthers,
    debitNoteCreditNoteAmountMismatchOthers,
    invoiceUnaccountedInBusinessPartner,
    paymentNotRecognisedInBusinessPartner,
    dncnNotRecognisedInBusinessPartner,
    invoiceUnaccountedInOwn,
    extraUnmatchedPaymentInBusinessPartner,
    extraUnmatchedDNCNInBusinessPartner,
    tdsNotRecognisedInBusinessPartner,
    extraUnmatchedTDSInBusinessPartner,
    suggestedTDSGap,
    miscellaneousOwnSOA,
    miscellaneousBusinessPartnerSOA,
    openingBalanceOwnSOA,
    openingBalanceBusinessPartnerSOA,
    openDetailsMismatched,
    openDetailsExtra,
    openDetailsMissed,
    openPaymentAmountMismatch,
    openPaymentAmountExtra,
    openTDSAmountExtra,
    openPaymentAmountMissed,
    openTDSAmountMissed,
    openDNCNMismatch,
    openDNCNExtra,
    openDNCNMissed,
    openTDSMismatched,
    openOwnMiscellaneous,
    openBusinessPartnerMiscellaneous,
  ]);

  return <SubRows row={row} rowProps={rowProps} visibleColumns={visibleColumns} data={data} loading={loading} />;
};

export default CollapseSubRow;
