import { Delete } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import Table from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      ownId: " ",
      businessPartnerId: " ",
      ownStartDate: " ",
      businessPartnerStartDate: " ",
      ownClosingBalance: " ",
      businessPartnerClosingBalance: " ",
      updatedAt: " ",
    });
  }
  return dataArray;
};

const ReconMapping = () => {
  const [loader, setLoader] = useState(true);
  const [rowsData, setRowsData] = useState(getBlankData());
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);

  console.log("selectedRow", selectedRow);

  const columnDefinition = React.useMemo(
    () => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: any) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        minWidth: 50,
        maxWidth: 50,
        width: 50,
      },
      {
        Header: "OwnId",
        accessor: "ownId",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },

      {
        Header: "BusinessPartnerId",
        accessor: "businessPartnerId",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "OwnStartDate ",
        accessor: "ownStartDate",
        Sort: true,
        minWidth: 150,
        maxWidth: 150,
        width: 150,
      },
      {
        Header: "BusinessPartnerStartDate",
        accessor: "businessPartnerStartDate",
        Sort: true,
        minWidth: 250,
        maxWidth: 250,
        width: 250,
      },
      {
        Header: "OwnClosingBalance",
        accessor: "ownClosingBalance",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "BusinessPartnerClosingBalance",
        accessor: "businessPartnerClosingBalance",
        Sort: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
      },
      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        Sort: true,
        minWidth: 350,
        maxWidth: 350,
        width: 350,
      },
    ],
    []
  );

  useEffect(() => {
    ListAllOwnBusinessPartnerMappingsByAdmin();
  }, []);

  const ListAllOwnBusinessPartnerMappingsByAdmin = async () => {
    try {
      await axios
        .get<any>("/api/ListAllOwnBusinessPartnerMappingsByAdmin")
        .then((response) => {
          console.log("response", response);
          setRowsData(response.data.data);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ListAllOwnBusinessPartnerMappingsByAdmin", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllOwnBusinessPartnerMappingsByAdmin`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllOwnBusinessPartnerMappingsByAdmin", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllOwnBusinessPartnerMappingsByAdmin`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const deleteSelectedRow = async () => {
    console.log("selectedRow deleteSelectedRow", selectedRow);
    try {
      await axios
        .delete("/api/DeleteOwnBusinessPartnerMapping", {
          data: {
            mappings: selectedRow,
          },
        })
        .then((response) => {
          console.log("delete response", response);
          ListAllOwnBusinessPartnerMappingsByAdmin();
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error DeleteOwnBusinessPartnerMapping", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DeleteOwnBusinessPartnerMapping`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DeleteOwnBusinessPartnerMapping", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DeleteOwnBusinessPartnerMapping`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Recon Mapping")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {
        <Grid className="table_list">
          <Grid className="userlisttable" sx={{ height: "78vh" }}>
            <Table
              columns={columnDefinition}
              rows={rowsData}
              sortEnable={true}
              setSelectedRow={setSelectedRow}
              actorType="admin"
              showPagination={true}
              loading={loader}
            />
          </Grid>
          <Grid className="center_align">
            <Button
              variant="contained"
              color="warning"
              startIcon={<Delete />}
              style={{
                margin: "30px",
              }}
              onClick={() => deleteSelectedRow()}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      }
    </LoggedInSkeleton>
  );
};

export default ReconMapping;
