import { Autocomplete, Button, Chip, createFilterOptions, Grid, TextareaAutosize, TextField } from "@mui/material";
import Axios from "axios";
import React, { useState } from "react";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const options: any[] = [];
const filter = createFilterOptions<any>();

const UserEmailSetting = (props: any) => {
  const [filePayload, setFilePayload] = useState<any>(undefined);
  const [errDisplayName, setErrDisplayName] = useState<boolean>(false);
  const [errReplyToEmailAddress, setErrReplyToEmailAddress] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [disableReplyTo, setDisableReplyTo] = useState<boolean>(true);

  const uploadSignatureImage = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append("image", filePayload);
    try {
      await Axios.post<any>("/api/UploadSignatureImage", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log("response", response);
          updateUserEmailSetting();
        })
        .catch((error) => {
          console.log("error UpdateUserEmailSetting", error?.response);
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}UpdateUserEmailSetting`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UpdateUserEmailSetting", error?.response);
      setShowLoadingIcon(false);
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}UpdateUserEmailSetting`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }
  };
  const updateUserEmailSetting = async () => {
    try {
      await Axios.put<any>("/api/UpdateUserEmailSetting", {
        name: props.emailDisplayName,
        email: props.fromEmailAddress,
        appPassword: props.password,
        replyToEmailAddress: props.replyToEmailAddress,
        signature: props.signatureName,
        emailCredentialsProvided: props.emailCredentialsProvided,
        smtpHost: props.smtpHost,
      })
        .then((response) => {
          setShowLoadingIcon(false);
          handleClose();
          props.setSmtpHost(null);
          props.setShowSuccessAlert(true);
          props.setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error UpdateUserEmailSetting", error?.response);
          setShowLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}UpdateUserEmailSetting`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UpdateUserEmailSetting", error?.response);
      setShowLoadingIcon(false);
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}UpdateUserEmailSetting`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }
  };

  const saveUserEmailSetting = async () => {
    if (props.emailDisplayName.trim() === "") {
      setErrDisplayName(true);
    }
    if (props.replyToEmailAddress.length === 0) {
      setErrReplyToEmailAddress(true);
    }

    if (props.emailDisplayName.trim() !== "" && props.replyToEmailAddress.length > 0) {
      setShowLoadingIcon(true);
      uploadSignatureImage();
    }
  };

  const handleClose = () => {
    props.setOpenUserEmailSetting(false);
    props.setUserEmailID("");
    props.setPassword("");
  };

  return (
    <>
      <header className="modal-card-head">
        <p className="modal-card-title">User E-Mail Setting</p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
        />
      </header>
      <section className="modal-card-body">
        <Grid>
          <Grid className="d_flex mt_15">
            <Grid className="width_250 vertical_center_align p_10">
              <label className="addBpUser_label">Email Display Name :</label>
            </Grid>
            <Grid className="w_300 vertical_center_align">
              <TextField
                variant="outlined"
                required={true}
                fullWidth={true}
                label="Email Display Name"
                name="emailDisplayName"
                error={errDisplayName}
                helperText={errDisplayName ? "please enter name" : ""}
                value={props.emailDisplayName}
                onChange={(e) => {
                  props.setEmailDisplayName(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrDisplayName(false);
                  } else {
                    setErrDisplayName(true);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid className="d_flex mt_15">
            <Grid className="width_250 vertical_center_align p_10">
              <label className="addBpUser_label">"From" Email address :</label>
            </Grid>
            <Grid className="w_300 vertical_center_align">
              <TextField
                disabled={true}
                variant="outlined"
                required={true}
                fullWidth={true}
                label="From Email address"
                name="fromEmailAddress"
                value={props.fromEmailAddress}
                onChange={(e) => {
                  props.setFromEmailAddress(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="d_flex mt_15">
            <Grid className="width_250 vertical_center_align p_10">
              <label className="addBpUser_label">"Reply to" email address :</label>
            </Grid>
            <Grid className="vertical_center_align replyToEmailAddress">
              <span className="w_300">
                <Autocomplete
                  fullWidth={true}
                  disabled={disableReplyTo}
                  value={props.replyToEmailAddress}
                  onChange={(name, value) => {
                    console.log(name);
                    if (value.length > 0) {
                      if (value[value.length - 1].includes("Add => ")) {
                        value[value.length - 1] = value[value.length - 1].replace('Add => "', "");
                        value[value.length - 1] = value[value.length - 1].replace('"', "");
                        const removeEmptyVal = value.filter((el) => {
                          return el !== "";
                        });
                        const uniqArr: any = [...new Set(removeEmptyVal)];
                        value = uniqArr;
                      }
                    }

                    const validEmails = value.filter((email) =>
                      email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
                    );
                    props.setReplyToEmailAddress(validEmails);
                    value.forEach((email) => {
                      if (!email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
                        props.setShowErrorAlert(true);
                        props.setApiErrorMsg(`${email} is not a valid email`);
                        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
                      }
                    });
                  }}
                  noOptionsText={"Add a new email"}
                  multiple={true}
                  id="tags-outlined"
                  options={options}
                  getOptionLabel={(option) => option}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => {
                      return (
                        <Chip
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                          disabled={option === props.fromEmailAddress && props.emailCredentialsProvided}
                        />
                      );
                    });
                  }}
                  filterSelectedOptions={true}
                  filterOptions={(optionsFilt, params) => {
                    console.log(optionsFilt);
                    const filtered = filter(options, params);
                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      setErrReplyToEmailAddress(false);
                      filtered.push(`Add => "${params.inputValue.trim()}"`);
                    }
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reply to email address"
                      placeholder="Enter email"
                      required={true}
                      error={errReplyToEmailAddress}
                      helperText={errReplyToEmailAddress ? "please enter valid email" : ""}
                      onKeyDown={(event: any) => {
                        if (event.key === "Backspace") {
                          event.stopPropagation();
                        }
                      }}
                    />
                  )}
                />
              </span>
              <Button
                className="theme_btn"
                onClick={() => {
                  setDisableReplyTo(false);
                }}
                style={{ minWidth: "40px", height: "25px", marginLeft: "10px" }}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid className="d_flex mt_15">
            <Grid className="width_250  p_10">
              <label className="addBpUser_label">Signature :</label>
            </Grid>
            <Grid className="w_300 ">
              <div>
                <div className="mt_15">
                  <TextareaAutosize
                    minRows={2}
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Signature Name"
                    style={{ width: "280px", padding: "10px" }}
                    value={props.signatureName}
                    onChange={(e) => {
                      props.setSignatureName(e.target.value);
                    }}
                  />
                </div>
                <div className="mt_15">
                  <Button variant="contained" component="label" className="theme_btn">
                    Upload Your Signature
                    <input
                      hidden={true}
                      accept="image/png, image/jpeg, image/jpg"
                      multiple={true}
                      type="file"
                      onChange={(e) => {
                        setFilePayload(e.target.files[0]);
                        props.setFile(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </Button>
                </div>
                <div className="mt_15">
                  {props.file && <img src={props.file} style={{ width: "180px", height: "80px" }} />}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <footer className="modal-card-foot space_between">
        <div>
          {props.emailCredentialsProvided ? (
            <>
              <Button
                disabled={true}
                className="theme_outline_btn"
                // onClick={() => {
                //   // saveUserEmailSetting();
                //   props.setOpenSelectEmailDomain(true);
                // }}
              >
                Official email is authenticated
              </Button>
              <Button
                className="theme_btn ml_10"
                onClick={() => {
                  props.setOpenSelectEmailDomain(true);
                }}
              >
                Reset Password
              </Button>
            </>
          ) : (
            <Button
              className="theme_outline_btn"
              onClick={() => {
                // saveUserEmailSetting();
                props.setOpenSelectEmailDomain(true);
              }}
            >
              Send email from official email ID
            </Button>
          )}
        </div>
        <div>
          <Button
            className="theme_btn"
            onClick={() => {
              saveUserEmailSetting();
            }}
            disabled={showLoadingIcon}
          >
            {showLoadingIcon ? (
              <span className="file-icon">
                <i className="fas fa-circle-notch fa-spin" />
              </span>
            ) : null}
            Save
          </Button>
        </div>
      </footer>
    </>
  );
};

export default UserEmailSetting;
