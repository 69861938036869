import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValueFormatterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { DineroObject } from "dinero.js";
import { Row, Workbook, Worksheet } from "exceljs";
import { ReactNode, useCallback, useContext } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ExternalDocTemplate } from "../../entity/ExternalDocTemplate";
import { FrontendActor } from "../../entity/models/FrontendActor";
import { Tier2Invoice } from "../../entity/Tier2Invoice";
import { findTier1IdByName, toDineroObject, validateDateType } from "../../Utils/BulkUploadUtility";
import { dateValueFormatter, formatDate } from "../../Utils/DateUtils";
import { currencyValueFormatter, formatMoney } from "../../Utils/MoneyUtils";
import { InvoiceFlowChecksForBulkUploadsForTier1 } from "../Common/InvoiceFlowChecksForBulkUploads";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import { Dialog } from "../Dialog/Dialog";
import "../Tier2Upload/Tier2BulkUpload.scss";

export interface ListTier2CustomersResponse {
  results: Tier2Customer[];
}

export interface Tier2Customer {
  customerActor: FrontendActor;
}

export interface ListTier2InvoicesResponse {
  invoices: Tier2Invoice[];
}

export interface Tier2InvoiceResponse {
  invoice: Tier2Invoice;
}

export interface ListExternalDocTemplateResponse {
  templates: ExternalDocTemplate[];
}

export interface Tier2InvoiceRow {
  invoiceNumber: string;
  supplier: string;
  invoiceDate: Date;
  invoiceAmount: DineroObject;
  supplierId?: number;
}

interface HeadersName {
  [key: string]: any;
  templateName: string;
  invoiceNumber: string;
  invoiceAmount: string;
  invoiceDate: string;
  supplier: string;
}
type Fields = keyof Tier2InvoiceRow;

interface MyColDef {
  headerName: string;
  field: Fields;
  minWidth: number;
  valueFormatter?: (params: ValueFormatterParams) => string;
}
interface InvoicesTypes {
  pendingInvoices: Tier2Invoice[];
  rejectedInvoices: Tier2Invoice[];
  approvedInvoices: Tier2Invoice[];
}
const Tier1Upload = () => {
  const location = useLocation<any>();
  const [tier2InvoiceRows, setTier2InvoiceRow] = useState<Tier2InvoiceRow[]>([]);
  const [excelFileErrors, setExcelFileErrors] = useState<string[]>([]);
  const isFirstRun = useRef(true);
  const [excelUploadClicked, setExcelUploadClicked] = useState<boolean>(true);
  // const [anchorInvoices, setAnchorInvoices] = useState<AnchorInvoice[]>([]);
  const [tier2Invoices, setTier2Invoices] = useState<Tier2Invoice[]>([]);
  const [modalState, setModalState] = useState<number>(-1);
  const [invoiceCheck, setInvoiceCheck] = useState<boolean>(false);
  const [allDuplicatesinvoicesIds, setAllDuplicateInvoiceIds] = useState<string[]>([]);

  const [tier2InvoicesCheck, setTier2InvoicesCheck] = useState<InvoicesTypes>({
    pendingInvoices: [],
    rejectedInvoices: [],
    approvedInvoices: [],
  });
  const [uploading, setUploading] = useState<boolean>(false);
  const history = useHistory();
  const { actor } = useContext(userContext);
  const [customers, setCustomers] = useState<Tier2Customer[]>([]);
  const [columnHeaderError, setColumnHeaderError] = useState<boolean>(false);
  // Anchor Transpose
  const nakadFormat: {
    [key: string]: { name: any };
  } = {
    InvoiceNumber: { name: "InvoiceNumber" },
    InvoiceAmount: { name: "InvoiceAmount" },
    InvoiceDate: { name: "InvoiceDate" },
    Supplier: { name: "Supplier" },
  };
  const [customFormat, setCustomFormat] = useState<ExternalDocTemplate[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<{
    [key: string]: { name: any };
  }>(nakadFormat);

  const updateCustomFormat = useCallback(async (): Promise<number> => {
    const { data } = await axios.get<ListExternalDocTemplateResponse>("/api/ListExernalDocTemplates");
    console.log(data);
    setCustomFormat(data.templates);
    return data.templates.length > 0 && data.templates[data.templates.length - 1].templateId;
  }, []);

  useEffect(() => {
    axios
      .get<ListTier2CustomersResponse>("/api/ListTier2Customers", {
        params: {
          tier1Id: actor.id,
        },
      })
      .then((response) => {
        setCustomers(response.data.results);
        console.log("Customers", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    updateCustomFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomFormat]);

  const excelFileUploadOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setExcelFileErrors([]);
    const file = event.target.files[0];
    const workbook = new Workbook();
    const buffer = await file.arrayBuffer();
    await workbook.xlsx.load(buffer);
    (window as any).xxx = workbook;
    const worksheet = workbook.worksheets[0];
    const { rowIndex, mappingColumnHeaderToColumnIndex } = setColumnHeaderNameWithColumnIndex(worksheet);
    console.log("Row Index, Total rows", rowIndex, worksheet.rowCount);
    const count = assertColumnHeaders(mappingColumnHeaderToColumnIndex);
    if (count > 0) {
      setColumnHeaderError(true);
      return;
    } else {
      setColumnHeaderError(false);
    }
    const inv = worksheet.getRows(rowIndex + 1, worksheet.actualRowCount);
    console.log("Inv", inv);
    const invoicesRows = worksheet
      .getRows(rowIndex + 1, worksheet.rowCount - rowIndex)
      .filter((r) => {
        return (
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.InvoiceNumber.name)).text.trim() !== "" ||
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.InvoiceAmount.name)).text.trim() !== "" ||
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.Supplier.name)).text.trim() !== "" ||
          r.getCell(mappingColumnHeaderToColumnIndex.get(selectedFormat.InvoiceDate.name)).text.trim() !== ""
        );
      })
      .map((row) => rowToTier2InvoiceRow(row, mappingColumnHeaderToColumnIndex));
    const nonDuplicates = eliminateDuplicateRows(invoicesRows);
    checkForErrorsInRowsWithSameInvoiceId(nonDuplicates);
    validateMandatoryInvoiceFields(nonDuplicates);
    setTier2InvoiceRow(nonDuplicates);
    setExcelUploadClicked(!excelUploadClicked);
    setAllDuplicateInvoiceIds([]);
  };
  const eliminateDuplicateRows = (invoiceRows: Tier2InvoiceRow[]) => {
    const nonDuplicateRows = [];
    for (let i = 0; i < invoiceRows.length; i++) {
      let booleanCheck = false;
      for (let j = 0; j < i; j++) {
        if (
          invoiceRows[j].invoiceNumber === invoiceRows[i].invoiceNumber &&
          invoiceRows[j].supplier === invoiceRows[i].supplier &&
          formatDate(invoiceRows[j].invoiceDate) === formatDate(invoiceRows[i].invoiceDate) &&
          formatMoney(invoiceRows[j].invoiceAmount) === formatMoney(invoiceRows[i].invoiceAmount)
        ) {
          console.log(i, j);
          booleanCheck = true;
          break;
        }
      }
      if (!booleanCheck) {
        nonDuplicateRows.push(invoiceRows[i]);
      }
    }
    return nonDuplicateRows;
  };
  const checkForErrorsInRowsWithSameInvoiceId = (invoiceRows: Tier2InvoiceRow[]) => {
    for (let i = 0; i < invoiceRows.length; i++) {
      for (let j = 0; j < i; j++) {
        if (invoiceRows[j].invoiceNumber === invoiceRows[i].invoiceNumber) {
          setExcelFileErrors((currentErrors) => {
            const errors = [...currentErrors];
            errors.push(
              `Row Number ${j + 1} and ${i + 1} have the same invoice id - ${
                invoiceRows[j].invoiceNumber
              } - but different other fields. Kindly change the invoice ids or make it uniform. `
            );
            return errors;
          });
        }
      }
    }
  };
  const setColumnHeaderNameWithColumnIndex = (worksheet: Worksheet) => {
    const mappingColumnHeaderToColumnIndex = new Map<string, number>();
    let rowIndex = 0;
    for (let i = 1; i < 101; i++) {
      let checkBoolean = false;
      for (let j = 1; j < 101; j++) {
        const columnHeading = worksheet.getRow(i).getCell(j).text.trim();
        if (
          columnHeading === selectedFormat.InvoiceDate.name ||
          columnHeading === selectedFormat.InvoiceNumber.name ||
          columnHeading === selectedFormat.Supplier.name ||
          columnHeading === selectedFormat.InvoiceAmount.name
        ) {
          checkBoolean = true;

          mappingColumnHeaderToColumnIndex.set(columnHeading, j);
          rowIndex = i;
        }
      }
      if (checkBoolean) break;
    }
    console.log("Column Header To Column Index", mappingColumnHeaderToColumnIndex);

    return { rowIndex, mappingColumnHeaderToColumnIndex };
  };
  const check2 = async () => {
    const alreadyUploadedInvoices = await getAlreadyUploadedInvoices(tier2InvoiceRows);
    if (alreadyUploadedInvoices.length > 0) {
      let pendingInvoices: Tier2Invoice[];
      let rejectedInvoices: Tier2Invoice[];
      let approvedInvoices: Tier2Invoice[];
      pendingInvoices = alreadyUploadedInvoices.filter(
        (inv) =>
          inv.approvalStatus === "Pending" ||
          (inv.approvalStatus === "Approved" && inv.isRejected !== true && inv.tier1Tier2InvoiceMappings.length === 0)
      );
      rejectedInvoices = alreadyUploadedInvoices.filter(
        (inv) => inv.approvalStatus === "Rejected" || inv.isRejected === true
      );
      approvedInvoices = alreadyUploadedInvoices.filter(
        (inv) =>
          inv.tier1Tier2InvoiceMappings.length > 0 &&
          (inv.tier1Tier2InvoiceMappings[0].bankApprovalStatus === "Approved" ||
            inv.tier1Tier2InvoiceMappings[0].bankApprovalStatus === "Pending")
      );
      console.log("Pending Invoices:", pendingInvoices);
      console.log("Rejected Invoices:", rejectedInvoices);
      console.log("Approved Invoices:", approvedInvoices);
      setTier2InvoicesCheck({
        pendingInvoices,
        rejectedInvoices,
        approvedInvoices,
      });
      setModalState(() => {
        return pendingInvoices.length > 0 ? 1 : rejectedInvoices.length > 0 ? 2 : 3;
      });
      setTier2Invoices(alreadyUploadedInvoices);
      setInvoiceCheck(true);

      //  setExcelFileErrors([]);
      return;
    }
  };

  if (modalState === 0) {
    setTier2InvoiceRow((rows) => {
      const updatedRows = [...rows];
      console.log("UPDATED ROWS", updatedRows);
      console.log("Duplicate Ids to be deleted", allDuplicatesinvoicesIds);
      return updatedRows.filter((row) => !allDuplicatesinvoicesIds.includes(row.invoiceNumber));
    });
    setModalState(-1);
    setInvoiceCheck(false);
  }

  const updateDuplicateInvoices = (ids: string[]) => {
    if (modalState === 1) {
      setTier2InvoicesCheck({ ...tier2InvoicesCheck, pendingInvoices: [] });
      setAllDuplicateInvoiceIds((duplicateIds) => {
        const allIds = [...duplicateIds];
        for (const id of ids) {
          allIds.push(id);
        }
        return allIds;
      });

      setModalState(() => {
        return tier2InvoicesCheck.rejectedInvoices.length > 0
          ? 2
          : tier2InvoicesCheck.approvedInvoices.length > 0
          ? 3
          : 0;
      });
    }
    if (modalState === 2) {
      setTier2InvoicesCheck({ ...tier2InvoicesCheck, rejectedInvoices: [] });
      setAllDuplicateInvoiceIds((duplicateIds) => {
        const allIds = [...duplicateIds];
        for (const id of ids) {
          allIds.push(id);
        }
        return allIds;
      });
      setModalState(() => {
        return tier2InvoicesCheck.approvedInvoices.length > 0 ? 3 : 0;
      });
    }
    if (modalState === 3) {
      console.log("Approved Invoices Ids", ids);
      setTier2InvoicesCheck({ ...tier2InvoicesCheck, approvedInvoices: [] });
      setAllDuplicateInvoiceIds((duplicateIds) => {
        const allIds = [...duplicateIds];
        for (const id of ids) {
          allIds.push(id);
        }
        return allIds;
      });
      setModalState(0);
    }
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      if (excelFileErrors.length > 0) {
        console.log(excelFileErrors);
        // setAnchorInvoiceRows([]);
        return;
      } else {
        check2();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelUploadClicked]);

  const assertColumnHeaders = (mapping: Map<string, number>) => {
    console.log("Selected Format", selectedFormat);
    let count = 0;
    setExcelFileErrors((currentErrors) => {
      const errors = [...currentErrors];
      for (const element in selectedFormat) {
        if (mapping.get(selectedFormat[element].name) === undefined) {
          errors.push(`${selectedFormat[element].name} column is missing from excel file.`);
          count++;
        }
      }

      return errors;
    });

    return count;
  };

  const validateMandatoryInvoiceFields = (nonDuplicates: Tier2InvoiceRow[]) => {
    setExcelFileErrors((currentErrors) => {
      const errors = [...currentErrors];
      nonDuplicates.map((item, index) => {
        if (item.invoiceNumber.length === 0) {
          errors.push(`Row num ${index + 1} has empty column Invoice Number`);
        }
        if (item.supplier.length === 0) {
          errors.push(`Row num ${index + 1} has empty column Supplier`);
        }
        if (item.invoiceAmount.amount <= 0) {
          errors.push(`Row num ${index + 1} has invalid Invoice Amount`);
        }
        if (!validateDateType(item.invoiceDate as Date)) {
          errors.push(`Row num ${index + 1} has invalid Date`);
        }
        return null;
      });
      return errors;
    });
  };

  const rowToTier2InvoiceRow = (row: Row, mapping: Map<string, number>): Tier2InvoiceRow => {
    const anchorRow = {
      invoiceNumber:
        row.findCell(mapping.get(selectedFormat.InvoiceNumber.name)) !== undefined
          ? row.getCell(mapping.get(selectedFormat.InvoiceNumber.name)).text.trim()
          : "Invalid",
      supplier:
        row.findCell(mapping.get(selectedFormat.Supplier.name)) !== undefined
          ? row.getCell(mapping.get(selectedFormat.Supplier.name)).text.trim()
          : "Invalid",
      supplierId:
        row?.findCell(mapping.get(selectedFormat.Supplier.name)) !== undefined
          ? findTier1IdByName(customers, row.getCell(mapping.get(selectedFormat.Supplier.name)).text.trim())
          : -1,
      invoiceDate:
        row.findCell(mapping.get(selectedFormat.InvoiceDate.name)) !== undefined
          ? (row.getCell(mapping.get(selectedFormat.InvoiceDate.name)).value as Date)
          : "Invalid",
      invoiceAmount:
        row.findCell(mapping.get(selectedFormat.InvoiceAmount.name)) !== undefined
          ? toDineroObject(row.getCell(mapping.get(selectedFormat.InvoiceAmount.name)).value as number)
          : toDineroObject(0),
    } as Tier2InvoiceRow;

    return anchorRow;
  };
  const defaultColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    cellStyle: { color: "#4D4F5C" },
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };
  const columnDefs: MyColDef[] = [
    { headerName: "Invoice Number", field: "invoiceNumber", minWidth: 200 },
    { headerName: "Supplier", field: "supplier", minWidth: 200 },
    {
      headerName: "Invoice Date",
      field: "invoiceDate",
      valueFormatter: dateValueFormatter,
      minWidth: 200,
    },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      valueFormatter: currencyValueFormatter,
      minWidth: 200,
    },
  ];
  const rowData = () => {
    return tier2InvoiceRows;
  };
  const uploadAllInvoices = async () => {
    console.log("UPLOADING");
    console.log("Anchor Invoices Row", tier2InvoiceRows);
    setUploading(true);
    await Promise.all(
      tier2InvoiceRows.map(async (row) => {
        //   const fileUrl = await uploadImageFile(row.invoiceFileBuffer);
        // const invoice = {
        //   invoiceId: row.invoiceNumber,
        //   invoiceDate: row.invoiceDate,
        //   invoiceAmount: row.invoiceAmount,
        //   receivableAmount: row.invoiceAmount,
        //   // tslint:disable-next-line: prettier
        //   tier2Id: findTier1IdByName(customers, row.supplier),
        //   tier2InvoiceDetails: {
        //     debitNotes: [],
        //   } as Tier2InvoiceDetails,
        // } as Tier2Invoice;

        const invoice = {
          invoiceId: row.invoiceNumber,
          invoiceDate: row.invoiceDate,
          invoiceAmount: row.invoiceAmount,
          // tslint:disable-next-line: prettier
          tier2Id: findTier1IdByName(customers, row.supplier),
        } as Tier2Invoice;

        // console.log("Invoice", invoice);
        console.log("row", row);
        // const invoice = {} as Tier2Invoice;
        await axios.post<Tier2InvoiceResponse>("/api/InsertTier2Invoice", invoice);
      })
    );
    console.log("DONE");
    setUploading(false);
    alert("Invoices are uploaded");
    history.go(0);
  };
  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Upload", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <div className="tier2-bulk-upload">
        {invoiceCheck && (
          <InvoiceFlowChecksForBulkUploadsForTier1
            tier2Invoices={tier2Invoices}
            tier2InvoiceRows={tier2InvoiceRows}
            update={updateDuplicateInvoices}
            onClose={() => setInvoiceCheck(false)}
            currentModal={modalState}
            tier2invoicesCheck={tier2InvoicesCheck}
          />
        )}

        <DropDownComponent
          format={customFormat}
          updateFormat={updateCustomFormat}
          onChangeFormat={(data: { [key: string]: { name: any } }) => setSelectedFormat(data)}
        />
        <UploadButtonComponent
          headerText="Upload your invoices in the prescribed format"
          onChange={excelFileUploadOnChange}
        />
        <div className="column errors">
          {(excelFileErrors.length !== 0 || columnHeaderError === true) && <h1>Fix the following errors</h1>}
          {columnHeaderError && <p>Kindly Check the Column Headers and then re-upload.</p>}
          {excelFileErrors.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </div>
        <div className="ag-theme-alpine mygrid" id="table">
          <AgGridReact
            enableCellTextSelection={true}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData()}
            domLayout="autoHeight"
          />
        </div>
        <div className="column">
          {
            <input
              className="button is-bold is-success theme_btn"
              type="button"
              value="Submit all invoices"
              onClick={async () => {
                await uploadAllInvoices();
              }}
              disabled={
                excelFileErrors.length > 0 || tier2InvoiceRows.length === 0 || uploading === true ? true : false
              }
            />
          }
        </div>
      </div>
    </LoggedInSkeleton>
  );
};
const UploadButtonComponent = (props: {
  headerText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="column">
      <div className="field">
        <div className="control">
          <label className="label">{props.headerText}</label>
        </div>
      </div>
      <label id="file-js-example" className="field file has-name">
        <input
          className="file-input"
          type="file"
          name="invoicefile"
          value={""}
          onChange={props.onChange}
          required={true}
          multiple={true}
        />
        <span
          className="file-cta"
          style={{
            backgroundColor: "#4072e3",
          }}
        >
          <span className="file-icon">
            <i className="fas fa-upload" />
          </span>
          <span className="file-label">Upload</span>
        </span>
      </label>
    </div>
  );
};
async function getAlreadyUploadedInvoices(tier2Invoices: Tier2InvoiceRow[]) {
  const ids = tier2Invoices.map((i) => i.invoiceNumber);
  const tierIds = tier2Invoices.map((i) => i.supplierId);
  console.log(ids);
  const { data } = await axios.get<ListTier2InvoicesResponse>("/api/listTier2Invoices", {
    params: {
      ids,
      tierIds,
    },
  });

  console.log("Already Uploaded Invoices:", data.invoices);
  return data.invoices;
}

// DropDown Component

const DropDownComponent = ({
  format,
  updateFormat,
  onChangeFormat,
}: {
  format: ExternalDocTemplate[];
  updateFormat: () => Promise<number>;
  onChangeFormat: (data: { [key: string]: { name: any } }) => void;
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [headerName, setHeaderName] = useState<HeadersName>({} as HeadersName);
  const [templateName, setTemplateName] = useState<string>("");
  const [errTag, setErrTag] = useState<JSX.Element>(<div />);
  const [newMapping, setNewMapping] = useState<boolean>(true);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [selectValue, setSelectValue] = useState<string | number>("default");
  const [changeValidity, setChangeValidity] = useState<boolean>(false);
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeaderName({ ...headerName, [event.target.name]: event.target.value });
  };
  const onChangeTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateName(event.target.value);
  };

  const { actor } = useContext(userContext);
  const nakadFormat: {
    [key: string]: { name: any };
  } = {
    InvoiceNumber: { name: "InvoiceNumber" },
    InvoiceAmount: { name: "InvoiceAmount" },
    InvoiceDate: { name: "InvoiceDate" },
    Supplier: { name: "Supplier" },
  };
  const nameValuePair: {
    [key: string]: any;
    name: string;
    value: string;
    label: string;
  }[] = [
    {
      name: "invoiceNumber",
      value: "InvoiceNumber",
      label: "Invoice Number",
    },
    {
      name: "invoiceAmount",
      value: "InvoiceAmount",
      label: "Invoice Amount",
    },
    {
      name: "invoiceDate",
      value: "InvoiceDate",
      label: "Invoice Date",
    },
    {
      name: "supplier",
      value: "Supplier",
      label: "Supplier",
    },
  ];
  const checkForRepeatedTemplateName = (): boolean => {
    for (const fmat of format) {
      if (templateName === fmat.name) return true;
    }
    return false;
  };
  const saveNewTemplate = async () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    if (templateName.length === 0) {
      setErrTag(<div style={{ color: "red" }}>This field is mandatory</div>);
      setTimeout(() => setErrTag(<div />), 6000);
    } else if (checkForRepeatedTemplateName() && newMapping) {
      setErrTag(
        <div style={{ color: "red" }}>The template name {templateName} already exists.Please Change the name.</div>
      );
      setTimeout(() => {
        setErrTag(<div />);
        btnRef.current.removeAttribute("disabled");
      }, 3000);
    } else {
      const templateToAdd = {
        actorId: actor.id,
        name: templateName,
        type: "Invoice",
        data: {
          InvoiceNumber: {
            name: headerName.invoiceNumber ? headerName.invoiceNumber : "InvoiceNumber",
          },
          InvoiceAmount: {
            name: headerName.invoiceAmount ? headerName.invoiceAmount : "InvoiceAmount",
          },
          InvoiceDate: {
            name: headerName.invoiceDate ? headerName.invoiceDate : "InvoiceDate",
          },
          Supplier: {
            name: headerName.supplier ? headerName.supplier : "Supplier",
          },
        },
      } as ExternalDocTemplate;
      if (selectValue !== "default") {
        templateToAdd.templateId = Number(selectValue);
      }
      const { data } = await axios.post("/api/InsertExernalDocTemplates", templateToAdd);
      console.log("template saved", data.template.data);
      const lastTemplateId = await updateFormat();
      setSelectValue(lastTemplateId);
      setNewMapping(false);
      setOpenDialog(false);
      onChangeFormat(data.template.data);
    }
  };
  const allFieldsAreNotEmpty = () => {
    for (const [, value] of Object.entries(headerName)) {
      if (value !== "") {
        setChangeValidity(true);
        return;
      }
    }
    setChangeValidity(false);
    return;
  };
  useEffect(() => {
    allFieldsAreNotEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerName]);
  const node = (): ReactNode => {
    return (
      <>
        <header className="modal-card-head">
          <p className="modal-card-title">Make your custom format</p>
          <button className="delete" aria-label="close" onClick={() => setOpenDialog(false)} />
        </header>
        <section className="modal-card-body">
          <div className="container">
            <div className="column" style={{ paddingLeft: "0" }}>
              <div className="field">
                <label>
                  <strong>Template Name</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="templateName"
                    value={templateName || ""}
                    onChange={onChangeTemplateName}
                  />
                </div>
                {errTag}
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column">
                <strong>Source (Column Headers)</strong>
                {nameValuePair.map(({ name, label }) => {
                  return (
                    <div className="field" key={name}>
                      <label>{label}</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name={name}
                          value={headerName[name] || ""}
                          onChange={onChangeInput}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="column ">
                <strong>Destination (Column Headers)</strong>
                {nameValuePair.map((element) => {
                  return (
                    <div className="field" key={element.name}>
                      <br />
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name={element.name}
                          value={element.value}
                          disabled={true}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          {changeValidity && templateName.length !== 0 && (
            <>
              <button ref={btnRef} className="button is-success" onClick={saveNewTemplate}>
                Save changes
              </button>
              <button className="button" onClick={() => setOpenDialog(false)}>
                Cancel
              </button>
            </>
          )}
        </footer>
      </>
    );
  };
  const getDialog = () => {
    return (
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        {node()}
      </Dialog>
    );
  };
  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "default") {
      const filtered = format.filter((f) => {
        return f.templateId === Number(e.target.value);
      });
      const { data } = filtered[0] as {
        data: { [key: string]: { name: any } };
      };
      onChangeFormat(data);
      // console.log(data);
      const preHeader = {} as HeadersName;
      const findKeyByValue = (value: string) => {
        let result: string = value;
        nameValuePair.forEach((element) => {
          if (element.value === value) {
            result = element.name;
            //     console.log(result);
          }
        });

        return result;
      };
      // tslint:disable-next-line:forin
      for (const key in data) {
        console.log(key, data[key].name);
        preHeader[findKeyByValue(key)] = data[key].name;
      }
      console.log(preHeader);

      setHeaderName(preHeader as HeadersName);
      setTemplateName(filtered[0].name);
      setSelectValue(e.target.value);
      setNewMapping(false);
    } else {
      setHeaderName({} as HeadersName);
      setTemplateName("");
      setSelectValue("default");
      setNewMapping(true);
      onChangeFormat(nakadFormat);
    }
  };
  return (
    <div className="column">
      <div className="field">
        <label className="label" style={{ float: "left" }}>
          Choose the custom format
        </label>
        <div className="control">
          <div className="select">
            <select
              name="formatid"
              required={true}
              value={selectValue}
              onChange={(e) => {
                //  console.log(e.target.value);
                onSelectChange(e);
              }}
            >
              <option value="default">Add new template</option>{" "}
              {format.map((fmat) => (
                <option key={fmat.templateId} value={fmat.templateId}>
                  {fmat.name}
                </option>
              ))}
            </select>
          </div>
          {newMapping ? (
            <FontAwesomeIcon
              icon={"plus-square"}
              size={"2x"}
              style={{ color: "#87CEEB", marginTop: "5px", cursor: "pointer" }}
              onClick={() => {
                setOpenDialog(true);
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={"edit"}
              size={"2x"}
              style={{ color: "#87CEEB", marginTop: "5px", cursor: "pointer" }}
              onClick={() => {
                setOpenDialog(true);
              }}
            />
          )}
          {getDialog()}
        </div>
      </div>
    </div>
  );
};
export { Tier1Upload };
