import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./PartnerUseCaseMasterFilter.scss";

type setAppliedFilters = React.Dispatch<
  React.SetStateAction<{
    [key: string]: string;
  }>
>;
const PartnerUserMasterFilter = (props: { [key: string]: any; setAppliedFilters: setAppliedFilters }) => {
  const [partnerName, setPartnerName] = useState<string>("");
  const [userName, setUserName] = useState<string>(null);
  const [designation, setDesignation] = useState<string>(null);
  const [email, setEmail] = useState<string>(null);
  const [paymentAdvice, setPaymentAdvice] = useState<string>(null);
  const [balanceConfirmation, setBalanceConfirmation] = useState<string>(null);
  const [ledgerRequest, setLedgerRequest] = useState<string>(null);

  const handleClose = () => {
    props.setOpenPartnerUserMasterFilter(false);
  };

  const filterLedgerRequest = () => {
    let tempRowData = props.storeRowsDataPartnerUserMaster;

    if (partnerName?.trim()) {
      const partnerNameWithoutMultiSpace = partnerName.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (userName?.trim()) {
      const partnerNameWithoutMultiSpace = userName.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.name.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (designation?.trim()) {
      const partnerNameWithoutMultiSpace = designation.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.designationName.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }
    if (email?.trim()) {
      const partnerNameWithoutMultiSpace = email.replace(/\s+/g, " ");
      const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.email.toLowerCase()?.includes(partnerNameStr?.trim());
      });
    }

    if (balanceConfirmation) {
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.balanceConfirmation === balanceConfirmation;
      });
    }
    if (ledgerRequest) {
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.ledgerRequest === ledgerRequest;
      });
    }
    if (paymentAdvice) {
      props.setIsFilterAppliedPartnerUserMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.paymentAdvice === paymentAdvice;
      });
    }

    props.setAppliedFilters({
      "Partner Name": partnerName?.trim(),
      "User Name": userName?.trim(),
      Designation: designation?.trim(),
      Email: email?.trim(),
      "Balance Confirmation": balanceConfirmation,
      "Ledger Request": ledgerRequest,
      "Payment Advice": paymentAdvice,
    });

    props.setRowsDataPartnerUserMaster(tempRowData);
    props.storePartnerUserMasterData.current = tempRowData;

    handleClose();
  };

  const clearAllAppliedFilters = () => {
    setPartnerName(null);
    setUserName(null);
    setDesignation(null);
    setEmail(null);
    setPaymentAdvice(null);
    setBalanceConfirmation(null);
    setLedgerRequest(null);

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenPartnerUserMasterFilter(true);
      // props.setRowsDataPartnerUserMaster(props.storeRowsDataPartnerUserMaster);
      props.listAllBusinessPartnersForPartnerUserMaster();
      props.setIsFilterAppliedPartnerUserMaster(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openPartnerUserMasterFilter}
        aria-labelledby="PartnerUserMasterFilter"
        className="partnerUseCaseMasterFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Name:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <Autocomplete
                  freeSolo={true}
                  id="free-solo-2-demo"
                  size="small"
                  disableClearable={false}
                  options={Array.from(
                    new Set(props.storeRowsDataPartnerUserMaster?.map((option) => option?.businessPartnerName))
                  )}
                  value={partnerName}
                  onChange={(event, value) => {
                    console.log(event);
                    if (typeof value === "string") {
                      // Check if the value is a string before setting it
                      setPartnerName(value);
                    } else setPartnerName("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Name"
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">User Name:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  //   className="mr_20"
                  label="Enter Name"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={userName}
                  onChange={(e: any) => {
                    setUserName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Email ID:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  //   className="mr_20"
                  label="Enter Name"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={email}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Designation :</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TextField
                  //   className="mr_20"
                  label="Enter Name"
                  fullWidth={true}
                  //   InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  //   type="date"
                  value={designation}
                  onChange={(e: any) => {
                    setDesignation(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Balance Confirmation :</div>
              <div className="w_400 category_textField_width_390">
                <Select
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="balanceConfirmation"
                  value={balanceConfirmation}
                  onChange={(e: any) => {
                    setBalanceConfirmation(e.target.value);
                  }}
                >
                  <MenuItem value={"NA"}>NA</MenuItem>
                  <MenuItem value={"to"}>to</MenuItem>
                  <MenuItem value={"cc"}>cc</MenuItem>
                  <MenuItem value={"bcc"}>bcc</MenuItem>
                </Select>
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Ledger request :</div>
              <div className="w_400 category_textField_width_390">
                <Select
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="ledgerRequest"
                  value={ledgerRequest}
                  onChange={(e: any) => {
                    setLedgerRequest(e.target.value);
                  }}
                >
                  <MenuItem value={"NA"}>NA</MenuItem>
                  <MenuItem value={"to"}>to</MenuItem>
                  <MenuItem value={"cc"}>cc</MenuItem>
                  <MenuItem value={"bcc"}>bcc</MenuItem>
                </Select>
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Payment advice:</div>
              <div className="w_400 category_textField_width_390">
                <Select
                  size="small"
                  fullWidth={true}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="paymentAdvice"
                  value={paymentAdvice}
                  onChange={(e: any) => {
                    setPaymentAdvice(e.target.value);
                  }}
                >
                  <MenuItem value={"NA"}>NA</MenuItem>
                  <MenuItem value={"to"}>to</MenuItem>
                  <MenuItem value={"cc"}>cc</MenuItem>
                  <MenuItem value={"bcc"}>bcc</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterPartnerUserMaster_button"
              onClick={() => {
                filterLedgerRequest();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PartnerUserMasterFilter;
