/* eslint-disable react-hooks/rules-of-hooks */
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UseCaseType } from "../../../entity/recon-entity/ReconInterfaces";
import { userContext } from "../../Contexts/userContext";
import Table, { ColumnDefs } from "../../ReactTable/Table";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import CreateEmailTemplate from "./CreateEmailTemplate";
import { PartnerCommsContext } from "./PartnerCommunicationContext";
import useFetch from "./useFetch";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      ledgerRequestFrequency: " ",
      ledgerRequestDay: " ",
    });
  }
  return dataArray;
};

interface Props {
  optionsLedgerRequestFrequency: string[];
  frequencyTableSettings: any;
  listUserEmailTemplate: (name: string) => void;
}
const LedgerRequestSettings = (props: Props) => {
  const { actor } = useContext(userContext);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [loading, setLoading] = useState(true);
  const [resetStatusAuto, setResetStatusAuto] = useState(false);

  const [rowsDataLedgerRequestSetting, setRowsDataLedgerRequestSetting] = useState<any[]>(getBlankData());

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const columnDefinitionLedgerRequestSetting = useMemo(
    (): ColumnDefs[] => [
      {
        Header: "Business Partner",
        id: "Business Partner",
        accessor: (row) => row.businessPartnerName?.toLowerCase(),
        Cell: ({ row }) => <div>{row.original.businessPartnerName}</div>,
        maxWidth: 350,
        minWidth: 350,
        width: 350,
      },
      {
        Header: "Ledger request frequency",
        accessor: "ledgerRequestFrequency",
        Sort: false,
        maxWidth: 350,
        minWidth: 300,
        width: 300,
        Cell: ({ row }) => (
          <p className="category_ui">
            <Autocomplete
              onChange={(e, value) => {
                console.log(e);
                let ledgerRequestDay: string = null;
                if (value === "Once a month") {
                  ledgerRequestDay = "1st of every month";
                } else if (value === "Twice a month") {
                  ledgerRequestDay = "1st and 15th of every month";
                } else if (value === "Once a quarter") {
                  ledgerRequestDay = "1th day of the quarter";
                }

                updateLedgerRequestSettings(row?.original?.businessPartnerId, value, ledgerRequestDay);
              }}
              id="partnerCommunicationSelect"
              className="w_250"
              options={props.optionsLedgerRequestFrequency}
              size="small"
              getOptionLabel={(option) => option}
              defaultValue={row.original.ledgerRequestFrequency}
              disableClearable={true}
              renderInput={(params) => <TextField {...params} />}
            />
          </p>
        ),
      },
      {
        Header: "Ledger request day",
        accessor: "ledgerRequestDay",
        Sort: false,
        maxWidth: 350,
        minWidth: 350,
        width: 350,
        Cell: ({ row }) => (
          <p className="category_ui">
            <Autocomplete
              onChange={(e, value) => {
                console.log(e);
                updateLedgerRequestSettings(
                  row?.original?.businessPartnerId,
                  row?.original?.ledgerRequestFrequency,
                  value
                );
              }}
              id="partnerCommunicationSelect"
              className="w_250"
              options={
                row.original.ledgerRequestFrequency
                  ? props.frequencyTableSettings?.find((item) => item.frequency === row.original.ledgerRequestFrequency)
                      ?.days || []
                  : []
              }
              size="small"
              getOptionLabel={(option) => option}
              defaultValue={row.original.ledgerRequestDay}
              disableClearable={true}
              renderInput={(params) => <TextField {...params} />}
            />
          </p>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history, props.optionsLedgerRequestFrequency, rowsDataLedgerRequestSetting]
  );

  useEffect(() => {
    listPartnerCommsStatusResetMethod();
  }, []);

  useEffect(() => {
    if (resetStatusAuto) ListAllLedgerRequestSettings();
  }, [resetStatusAuto]);

  const listPartnerCommsStatusResetMethod = () => {
    const thenCallBack = (response: any) => {
      if (response.data?.autoReset) setResetStatusAuto(true);
      else setResetStatusAuto(false);
    };
    useFetch("/api/ListEmailAutoResetSetting", "GET", { setApiErrorMsg, setShowErrorAlert, thenCallBack });
  };

  const updatePartnerCommsStatusResetMethod = (status: boolean) => {
    useFetch("/api/UpdateEmailAutoResetSetting", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      config: {
        autoReset: status,
      },
    });
  };

  const ListAllLedgerRequestSettings = async () => {
    const thenCallBack = (response) => {
      setRowsDataLedgerRequestSetting(response.data.businessPartnersList);
      setLoading(false);
    };

    useFetch("/api/ListAllLedgerRequestSettings", "GET", { setApiErrorMsg, setShowErrorAlert, thenCallBack });
  };

  const updateLedgerRequestSettings = async (id: number, freq: string, ledgerRequestDay: string) => {
    useFetch("/api/UpdateLedgerRequestSettings", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      thenCallBack: () => ListAllLedgerRequestSettings(),
      config: {
        businessPartnerId: id,
        ledgerRequestFrequency: freq,
        ledgerRequestDay,
      },
    });
  };

  return (
    <>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Reset Status" sx={{ textTransform: "none" }} />
          <Tab label="Send Reminder" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid className="mb_15">
          <Card sx={{ maxWidth: "1040px", mx: "auto" }}>
            <CardContent sx={{ p: "16px !important" }}>
              <div className="d_flex">
                <div style={{ width: "90%" }} className="vertical_center_align">
                  <div className="vertical_center_align space_between" style={{ width: "100%" }}>
                    <div className="fs_20"> Reset Status Automatically as per defined Frequency: </div>
                    <div className="ml_60">
                      <FormControl>
                        <RadioGroup
                          row={true}
                          aria-labelledby="row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={resetStatusAuto}
                          onChange={(e) => {
                            if (e.target.value === "true") {
                              setResetStatusAuto(true);
                              updatePartnerCommsStatusResetMethod(true);
                            } else {
                              setResetStatusAuto(false);
                              updatePartnerCommsStatusResetMethod(false);
                            }
                          }}
                        >
                          <FormControlLabel value={true} control={<Radio />} label="Yes" />
                          <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <div className="center_align" style={{ height: "69vh" }}>
          {resetStatusAuto && (
            <Table
              columns={columnDefinitionLedgerRequestSetting}
              rows={rowsDataLedgerRequestSetting}
              sortEnable={true}
              showPagination={true}
              loading={loading}
            />
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <SendReminderTab
          setShowSuccessAlert={setShowSuccessAlert}
          setShowErrorAlert={setShowErrorAlert}
          setApiErrorMsg={setApiErrorMsg}
          listUserEmailTemplate={props.listUserEmailTemplate}
        />
      </TabPanel>
    </>
  );
};

interface TabProps {
  setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setApiErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  listUserEmailTemplate: (name: string) => void;
}
const SendReminderTab = ({
  setShowSuccessAlert,
  setShowErrorAlert,
  setApiErrorMsg,
  listUserEmailTemplate,
}: TabProps) => {
  const [reminderAuto, setReminderAuto] = useState(false);
  const [reminderDays, setReminderDays] = useState<number>(0);
  const [reminderCount, setReminderCount] = useState<number>(0);
  const [templateID, setTemplateID] = useState(0);
  const [templateName, setTemplateName] = useState<string>("");
  const [valueChanged, setValueChanged] = useState(false);
  const { emailTemplates, setOpenCreateEmailTemplate, setHideSendEmailTemplate } = useContext(PartnerCommsContext);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);

  useEffect(() => {
    ListEmailAutoSendReminderSetting();
    listUserEmailTemplate("");
    ListEmailReminderSettings();
    ListAutoSendReminderTemplate();
    // eslint-disable-next-line
  }, []);

  // ListEmailAutoSendReminderSetting
  const ListEmailAutoSendReminderSetting = async () => {
    useFetch<{ autoSend: boolean; response: string }>("/api/ListEmailAutoSendReminderSetting", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack: (response) => setReminderAuto(response.data.autoSend || false),
      config: {
        useCaseType: UseCaseType.ledgerRequest,
      },
    });
  };

  const UpdateAutoReminderSettings = async () => {
    setShowLoadingIcon(true);
    useFetch<{ response: string }>("/api/UpdateAutoReminderSettings", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      setShowSuccessAlert,
      config: {
        autoSendStatus: reminderAuto,
        numberOfReminders: reminderCount,
        reminderDays,
        templateId: templateID,
        templateName,
        useCaseType: UseCaseType.ledgerRequest,
      },
      thenCallBack: (res) => {
        console.log(res);
        setShowLoadingIcon(false);
      },
      catchCallBack: (err) => {
        console.log(err);
        setShowLoadingIcon(false);
      },
      errorCallback: (err) => {
        console.log(err);
        setShowLoadingIcon(false);
      },
    });
  };

  const ListEmailReminderSettings = async () => {
    useFetch<{ reminderSettings: { reminderDays: number; numberOfReminders: number } }>(
      "/api/ListEmailReminderSettings",
      "POST",
      {
        setApiErrorMsg,
        setShowErrorAlert,
        thenCallBack: ({ data: { reminderSettings } }) => {
          setReminderDays(reminderSettings?.reminderDays);
          setReminderCount(reminderSettings?.numberOfReminders);
        },
        config: {
          useCaseType: UseCaseType.ledgerRequest,
        },
      }
    );
  };

  const ListAutoSendReminderTemplate = async () => {
    useFetch<{ templateName: string; templateId: number }>("/api/ListAutoSendReminderTemplate", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      thenCallBack: (param) => {
        setTemplateID(param.data.templateId);
        setTemplateName(param.data.templateName);
      },
      config: {
        useCaseType: UseCaseType.ledgerRequest,
      },
    });
  };

  return (
    <Grid className="mb_15">
      <Card sx={{ maxWidth: "1040px", mx: "auto" }}>
        <CardContent sx={{ p: "16px !important" }}>
          <div className="d_flex">
            <div style={{ width: "90%" }}>
              <div className="vertical_center_align space_between">
                <div className="fs_20"> Send Reminder automatically: </div>
                <div className="ml_60">
                  <FormControl>
                    <RadioGroup
                      row={true}
                      aria-labelledby="row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={reminderAuto}
                      onChange={(e) => {
                        if (e.target.value === "true") {
                          setReminderAuto(true);
                          // UpdateEmailAutoSendReminderSetting(true);
                        } else {
                          setReminderAuto(false);
                          // UpdateEmailAutoSendReminderSetting(false);
                        }
                      }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {reminderAuto && (
        <Card sx={{ maxWidth: "1040px", mx: "auto", mt: 2 }}>
          <CardContent sx={{ p: "16px !important" }}>
            <div className="d_flex">
              <div style={{ width: "90%" }}>
                <div className="fs_20 fw_600">If the partner is not responding:</div>
                <div className="vertical_center_align space_between mt_10">
                  <div className="fs_20"> After how many days the reminder should go?: </div>
                  <div className="ml_60">
                    <FormControl>
                      <TextField
                        label="Number of Days"
                        type="number"
                        size="small"
                        value={reminderDays ?? ""}
                        onChange={(e) => {
                          const days = parseInt(e.target.value as string, 10);
                          if (reminderCount !== days) setValueChanged(true);
                          setReminderDays(days);
                        }}
                        onBlur={() => {
                          if (isNaN(reminderDays)) setReminderDays(0);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="vertical_center_align space_between mt_10">
                  <div className="fs_20"> How many times the reminders should go?: </div>
                  <div className="ml_60">
                    <FormControl>
                      <TextField
                        label="Number of Reminders"
                        type="number"
                        size="small"
                        value={reminderCount ?? ""}
                        onChange={(e) => {
                          const count = parseInt(e.target.value as string, 10);
                          if (reminderCount !== count) setValueChanged(true);
                          setReminderCount(count);
                        }}
                        onBlur={() => {
                          if (isNaN(reminderCount)) setReminderCount(0);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {reminderAuto && (
        <Card sx={{ maxWidth: "1040px", mx: "auto", mt: 2 }}>
          <CardContent sx={{ p: "16px !important" }}>
            <div className="d_flex">
              <div style={{ width: "90%" }}>
                <div className="vertical_center_align space_between">
                  <div className="fs_20"> Select reminder template: </div>
                  <div className="ml_60">
                    <FormControl sx={{ minWidth: 195 }} size="small">
                      <InputLabel id="select-helper-label">Select Template</InputLabel>
                      <Select
                        labelId="select-helper-label"
                        label="Select Template"
                        value={templateID}
                        onChange={(e) => {
                          if (templateID !== e.target.value && e.target.value) setValueChanged(true);
                          if (e.target.value) {
                            const templateId = parseInt(e.target.value as string, 10);
                            const template = emailTemplates.find((_template) => _template.templateId === templateId);
                            setTemplateID(templateId);
                            setTemplateName(template?.templateName);
                          }
                        }}
                        onBlur={() => {
                          if (valueChanged) {
                            // if (templateID) UpdateAutoSendReminderTemplate();
                            setValueChanged(false);
                          }
                        }}
                        displayEmpty={true}
                      >
                        {emailTemplates.map((template) => (
                          <MenuItem key={template.templateId} value={template.templateId} title="">
                            {template.templateName}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value=""
                          onClick={() => {
                            setOpenCreateEmailTemplate(true);
                            setHideSendEmailTemplate(true);
                          }}
                        >
                          <em>Add New Template</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      <Box position={"relative"} top={40} left={"50%"}>
        <Button
          className="theme_btn"
          variant="contained"
          size="small"
          startIcon={<i className="fas fa-save" />}
          sx={{
            px: 3,
            py: 0.25,
          }}
          onClick={() => {
            UpdateAutoReminderSettings();
          }}
          disabled={showLoadingIcon}
        >
          Save
          {showLoadingIcon ? (
            <span className="file-icon ml_10">
              <i className="fas fa-circle-notch fa-spin" />
            </span>
          ) : null}
        </Button>
      </Box>
      <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
        setShowErrorAlert={setShowErrorAlert}
        setApiErrorMsg={setApiErrorMsg}
        setShowSuccessAlert={setShowSuccessAlert}
        listUserEmailTemplate={listUserEmailTemplate}
        templateType={UseCaseType.ledgerRequest}
      />
    </Grid>
  );
};

export default LedgerRequestSettings;
