import { Close, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorResponse } from "../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import { ColumnFilter } from "../ReactTable/ColumnFilter";
import Table, { ColumnDefs } from "../ReactTable/Table";
import ApiErrorCatch from "../Recon360/ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../Recon360/ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

interface ListAllActorData {
  actorId: string;
  username: string;
  email: string;
  actorType: string;
  actorInfo: {
    [key: string]: string;
  };
  data: {};
  integration: boolean;
  tallyCompanies: string[];
}

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      actorId: " ",
      username: " ",
      email: " ",
      actorType: " ",
      actorInfo: {
        name: " ",
      },
      data: {},
      integration: true,
      tallyCompanies: [""],
    });
  }
  return dataArray;
};

const Userlist = () => {
  const [loader, setLoader] = useState(true);
  const [rowsData, setRowsData] = useState(getBlankData());
  const [selectedRow, setSelectedRow] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const handleClose = () => setDeleteOpen(false);

  console.log("selectedRow", selectedRow);

  const columnDefinition = React.useMemo(
    (): ColumnDefs[] => [
      {
        id: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: (props) => (
          <React.Fragment key={props?.columns[0]?.id}>
            {/* Select All Button Commented */}
            {/* <IconButton
              onClick={() => props.toggleAllRowsSelected()}
              className="selectAllBtn"
              sx={{
                minWidth: "36px",
                px: 0,
                color: "#fff",
                "&.Mui-disabled": {
                  color: "#fff6",
                  pointerEvents: "auto",
                },
              }}
              disabled={props.filteredRows.length > 11}
            >
              <SelectAll />
            </IconButton> */}
          </React.Fragment>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
          </div>
        ),
        width: 50,
        Sort: false,
      },
      {
        Header: "ActorId",
        accessor: "actorId",
        Filter: ColumnFilter,
        Sort: true,
        minWidth: 100,
        maxWidth: 120,
        width: 120,
      },

      {
        Header: "ActorType",
        accessor: "actorType",
        Filter: ColumnFilter,
        Sort: true,
        maxWidth: 130,
        width: 130,
      },
      {
        Header: "Account Name",
        accessor: "username",
        Filter: ColumnFilter,
        sticky: "left",
        Sort: true,
        maxWidth: 250,
        width: 250,
      },
      // removed actor info name and company name
      {
        Header: "No. of suppliers",
        accessor: "actorInfo.numberOfSuppliers",
        Sort: true,
        maxWidth: 200,
        width: 190,
      },
      {
        Header: "User designation",
        accessor: "actorInfo.userDesignation",
        Filter: ColumnFilter,
        sticky: "left",
        Sort: true,
        maxWidth: 200,
        width: 190,
      },
      {
        Header: "Applicable TDS %",
        accessor: (data: { actorInfo: { applicableTDSPercentages: { value: any }[] } }) => {
          const output: string[] = [];
          if (typeof data?.actorInfo?.applicableTDSPercentages === "object")
            data?.actorInfo?.applicableTDSPercentages?.forEach((tds: { value: string }) => output.push(tds.value));
          else output.push(data?.actorInfo?.applicableTDSPercentages);
          return output.join("% , ");
        },
        width: 190,
      },
      {
        Header: "Accounting Software",
        accessor: "actorInfo.accountingSoftware",
        Filter: ColumnFilter,
        width: 220,
      },
      {
        Header: "User phone number",
        accessor: "actorInfo.userPhoneNumber",
        Sort: true,
        width: 210,
      },
      {
        Header: "Integration",
        accessor: "integration",
        Sort: true,
        width: 150,
        Cell: ({ cell: { value } }: any) => <span>{value.toString()}</span>,
      },
      {
        Header: "Tally Companies",
        accessor: "tallyCompanies",
        Filter: ColumnFilter,
        Sort: true,
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        Cell: ({ cell: { value } }: any) => <span>{value.toString()}</span>,
      },
    ],
    []
  );

  useEffect(() => {
    getAllActorsList();
  }, []);

  const getAllActorsList = async () => {
    try {
      await axios
        .get<any>("/api/listAllActors")
        .then((response) => {
          console.log("response", response);
          setRowsData(response.data.data);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error listAllActors", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}listAllActors`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error listAllActors", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}listAllActors`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const deleteSelectedRow = async () => {
    console.log("selectedRow deleteSelectedRow", selectedRow);
    try {
      await axios
        .delete("/api/DeleteActors", {
          data: {
            actors: selectedRow,
          },
        })
        .then((response) => {
          console.log("delete response", response);
          getAllActorsList();
          setShowSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error DeleteActors", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DeleteActors`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DeleteActors", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DeleteActors`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Userlist")}>
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      {columnDefinition && (
        <Grid className="table_list" sx={{ pb: 0 }}>
          <Grid className="userlisttable" sx={{ height: "78vh" }}>
            <Table<ListAllActorData>
              columns={columnDefinition}
              rows={rowsData}
              sortEnable={true}
              setSelectedRow={setSelectedRow}
              actorType="admin"
              showPagination={true}
              loading={loader}
            />
          </Grid>
          <Grid className="center_align">
            {process.env.REACT_APP_NAKAD_ENV !== "production" && (
              <Button
                variant="contained"
                startIcon={<Delete />}
                style={{
                  marginTop: "10px",
                }}
                onClick={() => setDeleteOpen(true)}
                color="warning"
              >
                Delete
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <DeleteDialog
        open={deleteOpen}
        handleClose={handleClose}
        selectedRows={selectedRow}
        deleteRows={deleteSelectedRow}
      />
    </LoggedInSkeleton>
  );
};

interface BasicDialogProp {
  open: boolean;
  handleClose: () => void;
}
interface DialogProp extends BasicDialogProp {
  selectedRows: any[];
  deleteRows: () => void;
}

const DeleteDialog = ({ open, handleClose, selectedRows, deleteRows }: DialogProp) => {
  const [queVals, setQueVals] = useState([0]);
  const [ansVals, setAnsVals] = useState([{ correct: false, value: 0 }]);
  const [answer, setAnswer] = useState(0);
  const [option, setOption] = useState(0);

  const RandomNumber = (start = 0, end = 10) => Math.floor(Math.random() * end + start);
  const genQnA = () => {
    const queVal1 = RandomNumber(1);
    const queVal2 = RandomNumber(2);
    const correctAns = queVal1 * queVal2;
    const incorrectAns1 = RandomNumber(0) * queVal2 + 1;
    const incorrectAns2 = RandomNumber(1) * queVal2 + 2;

    setAnswer(correctAns);
    setQueVals([queVal1, queVal2]);
    setAnsVals([
      { correct: true, value: correctAns },
      { correct: false, value: incorrectAns1 },
      { correct: false, value: incorrectAns2 },
    ]);
  };

  React.useEffect(() => {
    if (open) genQnA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDelete = () => {
    deleteRows();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle sx={{ px: 1 }} className="dialog_header">
        <Typography color={"orangered"} variant="h6">
          Delete The Following Actors?{" "}
        </Typography>
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ position: "relative", pt: "32px !important" }}>
        <Typography color={"orange"} variant="caption" sx={{ position: "absolute", top: 4, right: 8 }}>
          Note: This Action Can't be Undone!
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <FormLabel>The Following {selectedRows.length ? selectedRows.length : ""} Actors will be deleted:</FormLabel>
          {selectedRows.length ? (
            <Typography variant="body2">
              {selectedRows.map((row: { username: string }) =>
                row.username.toLowerCase().includes("admin") ? (
                  <>
                    <span className="bold">{row.username}</span>
                    <>, </>
                  </>
                ) : (
                  row.username + ", "
                )
              )}
            </Typography>
          ) : (
            <Typography variant="caption" fontStyle="italic">
              No Actors Selected
            </Typography>
          )}
          <Box>
            <FormControl sx={{ flexDirection: "row", gap: 2, alignItems: "center" }}>
              <FormLabel id="select-answer">
                What's {queVals[0]} x {queVals[1]} ?
              </FormLabel>
              <RadioGroup row={true} aria-labelledby="select-answer" name="captcha">
                {ansVals
                  .sort((a, b) => a.value - b.value)
                  .map((val) => (
                    <FormControlLabel
                      key={val.value}
                      value={val.value}
                      control={<Radio size="small" />}
                      label={val.value}
                      onChange={() => setOption(val.value)}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error" disabled={option !== answer || selectedRows?.length < 1}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Userlist;
