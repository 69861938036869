import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import { ManualReconContext } from "./ManualRecon";

const UnreconcileConfirmWarningModal = () => {
  const {
    isOpenUnreconcileConfirmWarningModal,
    setIsOpenUnreconcileConfirmWarningModal,
    unReconcileEntriesOnMatchId,
    showLoadingIconUnreconcile,
  } = useContext(ManualReconContext);
  return (
    <div>
      <Dialog open={isOpenUnreconcileConfirmWarningModal} aria-labelledby="UnreconcileConfirmWarningModal">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent />
        <DialogActions className="dialog_footer">
          <div className="space_between">
            <Button
              variant="contained"
              className="theme_btn theme_btn_warning"
              disabled={showLoadingIconUnreconcile}
              onClick={() => {
                unReconcileEntriesOnMatchId();
              }}
              sx={{ marginRight: 1 }}
            >
              {showLoadingIconUnreconcile ? (
                <span className="file-icon">
                  <i className="fas fa-circle-notch fa-spin" />
                </span>
              ) : null}
              Yes
            </Button>
            <Button
              variant="outlined"
              disabled={showLoadingIconUnreconcile}
              onClick={() => {
                setIsOpenUnreconcileConfirmWarningModal(false);
              }}
              className="theme_outline_btn mr_10"
            >
              No
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnreconcileConfirmWarningModal;
