import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Nakad from "../../Graphics/NAKAD_FINAL (1).png";
import "../Login/Login.scss";
const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [accountType, setAccountType] = useState<string>("");

  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await axios.post("/api/Reset", {
        email,
        accountType,
      });
      console.log(response);
      alert(response.data.message);
      setEmail("");
      history.push("/login");
    } catch (error: any) {
      console.log(error);
      alert(error.response.data.message);
      setEmail("");
      history.push("/reset");
    }
  };

  return (
    <div className="resetpass_wrapper">
      <div>
        <img src={Nakad} alt="" className="resetlogo" />
        <p className="enter-info-text">Please choose the account for which you want to reset the password</p>
        <div className="center_align">
          <Button
            className="theme_btn reset_user_btn"
            style={{ border: accountType === "user" ? "2px solid #531c4c" : null }}
            onClick={() => setAccountType("user")}
          >
            User
          </Button>
          <Button
            className="theme_btn reset_company_btn"
            style={{ border: accountType === "company" ? "2px solid #531c4c" : null }}
            onClick={() => setAccountType("company")}
          >
            Company
          </Button>
        </div>
        {accountType === "user" ? (
          <>
            <p className="enter-info-text">Please enter registered user email id</p>
            <form onSubmit={onSubmitHandler}>
              <div className="field">
                <div className="control">
                  <input
                    className="input input-box"
                    type="text"
                    name="username"
                    placeholder="Enter the registered user email id"
                    value={email}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="buttons">
                <button type="submit" className="button login-button">
                  Confirm
                </button>
              </div>
            </form>
          </>
        ) : accountType === "company" ? (
          <>
            <p className="enter-info-text">Please enter registered company email id</p>
            <form onSubmit={onSubmitHandler}>
              <div className="field">
                <div className="control">
                  <input
                    className="input input-box"
                    type="text"
                    name="username"
                    placeholder="Enter the registered company email id"
                    value={email}
                    onChange={update}
                    required={true}
                  />
                </div>
              </div>
              <div className="buttons">
                <button type="submit" className="button login-button">
                  Confirm
                </button>
              </div>
            </form>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ResetPassword;
