import React from "react";
import "./Loader.scss";
const Loader = () => {
  return (
    <div className="outer" style={{ marginTop: "60px" }}>
      <div className="loader" />
    </div>
  );
};

export default Loader;
