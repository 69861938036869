import React from "react";
import { Link } from "react-router-dom";

import {
  AdminTopBar,
  AdminTopBarType,
  AnchorTopBar,
  AnchorTopBarType,
  AtherTestTopBar,
  BankTopBar,
  BankTopBarType,
  ReconTopBar,
  // ReconTopBarType,
  Tier1TopBar,
  Tier1TopBarType,
  Tier2TopBar,
  Tier2TopBarType,
} from "./Constants";

export function getBankTopBarButtons(currentPage: BankTopBarType) {
  return [
    {
      isSubTab: false,
      name: "Disbursement Screen",
      to: "/bank/disbursement",
      className: `navbar-item ${currentPage === BankTopBar.Disbursement ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Collection Screen",
      to: "/bank/collection",
      className: `navbar-item ${currentPage === BankTopBar.Collection ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Settings",
      to: "/bank/settings",
      className: `navbar-item ${currentPage === BankTopBar.Settings ? "this-page" : ""}`,
    },
  ];
}

export function getTier2TopBarButtons(currentPage: Tier2TopBarType, actorName: string, openCollapseObj: any) {
  console.log(actorName, openCollapseObj);
  return [
    {
      isSubTab: false,
      name: "Early Payment",
      to: "/tier2/early",
      className: `navbar-item ${currentPage === Tier2TopBar.EarlyPayment ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Ledger Adjustment",
      to: "/tier2/ledger",
      className: `navbar-item ${currentPage === Tier2TopBar.LedgerAdjustment ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Audit Trail",
      to: "/tier2/audit",
      className: `navbar-item ${currentPage === Tier2TopBar.AuditTrail ? "this-page" : ""}`,
    },
  ];
}

export function getTier1TopBarButtons(currentPage: Tier1TopBarType, actorName: string, openCollapseObj: any) {
  console.log(actorName, openCollapseObj);

  return [
    {
      isSubTab: false,
      name: "Early Payment",
      to: "/tier1/early",
      className: `navbar-item ${currentPage === Tier1TopBar.InvoiceApproval ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Ledger Adjustment",
      to: "/tier1/ledger",
      className: `navbar-item ${currentPage === Tier1TopBar.EntryAdjustment ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Audit Trail",
      to: "/tier1/audit",
      className: `navbar-item ${currentPage === Tier1TopBar.AuditTrail ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Upload",
      to: "/tier1/upload",
      className: `navbar-item ${currentPage === Tier1TopBar.Upload ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Settings",
      to: "/tier1/settings",
      className: `navbar-item ${currentPage === Tier1TopBar.Settings ? "this-page" : ""}`,
    },
  ];
}

export function getAnchorTopBarButtons(currentPage: AnchorTopBarType, actorName: string, openCollapseObj: any) {
  console.log(actorName, openCollapseObj);
  return [
    {
      isSubTab: false,
      name: "Upload",
      to: "/anchor/upload",
      className: `navbar-item ${currentPage === AnchorTopBar.Upload ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Audit Trail",
      to: "/anchor/audit",
      className: `navbar-item ${currentPage === AnchorTopBar.AnchorLanding ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Dashboard",
      to: "/anchor/ld",
      className: `navbar-item ${currentPage === AnchorTopBar.LD ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Settings",
      to: "/anchor/settings",
      className: `navbar-item ${currentPage === AnchorTopBar.Settings ? "this-page" : ""}`,
    },
  ];
}

export function getAtherTestTopBarButtons(currentPage: AnchorTopBarType) {
  return [
    <Link
      key={4}
      className={`navbar-item ${currentPage === AtherTestTopBar.Recon360 ? "this-page" : ""}`}
      to="/athertest/recon360"
    >
      RECON360
    </Link>,
  ];
}

export function getAdminTopBarButtons(currentPage: AdminTopBarType) {
  return [
    {
      isSubTab: false,
      name: "Userlist",
      to: "/admin/userlist",
      className: `navbar-item ${currentPage === AdminTopBar.Userlist ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Recon Mapping",
      to: "/admin/reconmapping",
      className: `navbar-item ${currentPage === AdminTopBar.ReconMapping ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Sap Data",
      to: "/admin/sapdata",
      className: `navbar-item ${currentPage === AdminTopBar.SapData ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Ledger Data",
      to: "/admin/ledgerdata",
      className: `navbar-item ${currentPage === AdminTopBar.LedgerData ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Disbursment Account",
      to: "/admin/disbursmentaccount",
      className: `navbar-item ${currentPage === AdminTopBar.DisbursmentAccount ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Collection Account",
      to: "/admin/collectionaccount",
      className: `navbar-item ${currentPage === AdminTopBar.CollectionAccount ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Config Templates",
      to: "/admin/configtemplates",
      className: `navbar-item ${currentPage === AdminTopBar.ConfigTemplates ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Tally Voucher",
      to: "/admin/tallyvoucher",
      className: `navbar-item ${currentPage === AdminTopBar.TallyVoucher ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Tally Ledger",
      to: "/admin/tallyledger",
      className: `navbar-item ${currentPage === AdminTopBar.TallyLedger ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "CB Update",
      to: "/admin/CBUpdate",
      className: `navbar-item ${currentPage === AdminTopBar.CBUpdate ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Unpaid Invoice",
      to: "/admin/UnpaidInvoice",
      className: `navbar-item ${currentPage === AdminTopBar.UnpaidInvoice ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Payment Details",
      to: "/admin/PaymentDetails",
      className: `navbar-item ${currentPage === AdminTopBar.PaymentDetails ? "this-page" : ""}`,
    },
    {
      isSubTab: false,
      name: "Register",
      to: "/admin/Register",
      className: `navbar-item ${currentPage === AdminTopBar.Register ? "this-page" : ""}`,
    },
  ];
}

export function getReconTopBarButtons(currentPage: any, actorName: string, openCollapseObj: any, actor: any) {
  // const { actor } = useContext(userContext);
  const accountName = actorName
    .toString()
    .toLowerCase()
    .replace(/[^a-z\d]+/gi, "");
  let buttons: any[] = [];

  if (actor.actorType === "Tier1" && process.env.REACT_APP_NAKAD_ENV === "test") {
    buttons = getTier1TopBarButtons(currentPage, actorName, openCollapseObj);
  } else if (actor.actorType === "Tier2" && process.env.REACT_APP_NAKAD_ENV === "test") {
    buttons = getTier2TopBarButtons(currentPage, actorName, openCollapseObj);
  } else if (actor.actorType === "Anchor" && process.env.REACT_APP_NAKAD_ENV === "test") {
    buttons = getAnchorTopBarButtons(currentPage, actorName, openCollapseObj);
  }
  const allNavBarButtons = [
    {
      isSubTab: true,
      name: "Partner Communication",
      openCollapseObj,
      subMenu: [
        {
          name: "Balance Confirmation",
          to: `/${accountName}/partnerCommunication/balanceConfirmation`,
          className: `navbar-item ${currentPage === ReconTopBar.BalanceConfirmation ? "this-page" : ""}`,
        },
        {
          name: "Ledger Request",
          to: `/${accountName}/partnerCommunication/ledgerRequest`,
          className: `navbar-item ${currentPage === ReconTopBar.LedgerRequest ? "this-page" : ""}`,
        },
      ],
    },
    {
      isSubTab: false,
      name: "Partner Master",
      to: `/${accountName}/partnerMaster`,
      className: `navbar-item ${currentPage === ReconTopBar.PartnerMaster ? "this-page" : ""}`,
      openCollapseObj,
    },
    {
      isSubTab: true,
      name: "Automated Recon",
      openCollapseObj,
      subMenu: [
        {
          name: "Ledger",
          to: `/${accountName}/recon360/Summary/Ledger`,
          className: `navbar-item ${currentPage === ReconTopBar.Ledger ? "this-page" : ""}`,
        },
        {
          name: "Payment Advice",
          to: `/${accountName}/recon360/Summary/PaymentAdvice`,
          className: `navbar-item ${currentPage === ReconTopBar.PaymentAdvice ? "this-page" : ""}`,
        },
      ],
    },
    {
      isSubTab: true,
      name: "Recon MIS",
      openCollapseObj,
      subMenu: [
        {
          name: "Process Management",
          to: `/${accountName}/ReconMIS/ProcessManagement`,
          className: `navbar-item ${currentPage === ReconTopBar.ProcessManagement ? "this-page" : ""}`,
        },
        {
          name: "AP Recon Insights",
          to: `/${accountName}/ReconMIS/APReconInsights`,
          className: `navbar-item ${currentPage === ReconTopBar.APReconInsights ? "this-page" : ""}`,
        },
        {
          name: "AR Recon Insights",
          to: `/${accountName}/ReconMIS/ARReconInsights`,
          className: `navbar-item ${currentPage === ReconTopBar.ARReconInsights ? "this-page" : ""}`,
        },
      ],
    },
    // {
    //   isSubTab: false,
    //   name: "Settings",
    //   to: `/${accountName}/reconSettings`,
    //   className: `navbar-item ${currentPage === ReconTopBar.ReconSettings ? "this-page" : ""}`,
    //   openCollapseObj,
    // },
    // {
    //   isSubTab: false,
    //   name: "Supply Chain Dashboard",
    //   to: `/${accountName}/supplierchaindashboard`,
    //   className: `navbar-item ${currentPage === ReconTopBar.SupplierChainDashboard ? "this-page" : ""}`,
    //   openCollapseObj,
    //   show: process.env.REACT_APP_NAKAD_ENV !== "production",
    // },
    ...buttons,
  ];
  const filteredNavBarButtons = allNavBarButtons?.filter((button) => {
    if (button.show === undefined) {
      return true;
    }
    return button.show;
  });

  return filteredNavBarButtons;
}
