import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  AllOwnBusinessPartnerMapping,
  amountEntryType,
  ConfigUseCaseType,
  EmailTemplate,
  ErrorResponse,
  FetchDataFromERPResponse,
  FileWiseDocTypeAndColumnHeader,
  InsertLedgerConfig,
  LedgerConfiguration,
  ListAllOwnBusinessPartnerMappingResponse,
  ListBusinessPartnerLedgerConfigResponse,
  ListOwnLedgerConfigResponse,
  ListOwnTallyCompanies,
  ListOwnTallyCompaniesRes,
  ListReconSummaryAccountingSettingResponse,
  ListSanitizationRulesResponse,
  ListUserEmailTemplateResponse,
  ManualReconciledEntriesTrack,
  OutputUISummary,
  OwnBusinessPartnerMappingUpdateClosingBalances,
  OwnRecon360Response,
  ReconResultForSendingEmail,
  UnIdentifiedDoctype,
  UploadOwnLedgerDataResponse,
} from "../../entity/recon-entity/ReconInterfaces";
import { toDineroObject } from "../../Utils/BulkUploadUtility";
import { formatMoney } from "../../Utils/MoneyUtils";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import { userContext } from "../Contexts/userContext";
import DetailsTable from "../ReactTable/DetailsTable";
import SummaryTable from "../ReactTable/SummaryTable";

import { Download, Mail, Upload } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@mui/material";
import { clarity } from "react-microsoft-clarity";
import {
  Customized,
  insightsMetricName,
  RECON360_SUB_TAB,
  reconciliationStatus,
  summaryTypeSelectedStatus,
  transFormColumnHeader,
  transFormMetricName,
  UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY,
} from "../../Utils/Recon/Recon360/Constants";
import Loader from "../Common/Loader";
import { Dialog } from "../Dialog/Dialog";
import ApiErrorCatch from "./ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "./ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import CollapseSubRow from "./CollapseSubRow";
import DownloadTest from "./DownloadTest";
import DuplicateFoundModal from "./DuplicateEntries";
import LedgerUpload from "./LedgerUpload";
import ManualRecon from "./ManualRecon/ManualRecon";
import AddNewBusinessPartner from "./NewBusinessPartner/AddNewBusinessPartner";
import useFetch from "./PartnerCommunication/useFetch";
import PdfOptionConfirmModal from "./PdfOptionConfirmModal";
import ReconInsightsSection from "./ReconMIS/ReconInsightsSection/ReconInsightsSection";
import { SendMail } from "./SendMail";
import { Codes, Element } from "./Transformer/DocumentTypeConfig";
import UploadedSummary from "./UploadedSummary";
import UseDateFilter from "./UseDateFilter";

/**
 * @Component
 * Own Component for Own Entry Adjustment tab. It contains two sub tabs - pending and completed.
 *
 * 1.Pending - All the own invoices(BusinessPartnerInvoices) which were matched against a Tier2Invoice. The loan for the mapping has been sanctioned and disbursed. This tab represents the invoices which are due to be adjusted in Own's ERP.
 *
 * 2.Completed - All the own invoices(BusinessPartnerInvoices) which are adjusted in ERP.
 *
 * This component uses Ag grid library to demostrate the data in tabular form.
 */

export const Recon360Context = createContext<any>(null);

const Recon360 = (props: any) => {
  const location = useLocation<any>();

  const [prevLocation, setPrevLocation] = useState(null);

  // const dataFromAPReconInsights = JSON.parse(localStorage.getItem('dataFromAPReconInsights'));
  const { actor } = useContext(userContext);
  const history = useHistory();
  const { pathParam } = useParams<{ pathParam?: string }>();
  const DEFAULT_ACTIVE_TAB = "Summary";
  const pathWay = "/" + actor.name + "/recon360";
  const [ledgerComparisonSummaryData, setLedgerComparisonSummaryData] = useState<any[]>(null);
  const [openDetailsJSON, setOpendetailsJSON] = useState<any[]>(null);
  // const [displayDownloadButton, setDisplayDownloadButton] = useState<string>("none");
  const [columnDefinition, setColumnDefinition] = useState<any>(null);
  const [detailsHeading, setDetailsHeading] = useState<any>(null);
  const detailsHeadingName = useRef(null);
  const itemNuminvoiceAmountMismatchDispute = 0;
  const allinvoiceAmountMismatchDisputeValue = 0;
  const [ownClosingbalance, setOwnClosingBalance] = useState<number>(null);
  const ownClosingbalanceRef = useRef<number>(null);
  const [businessPartnerClosingbalance, setBusinessPartnerClosingBalance] = useState<number>(null);
  const businessPartnerClosingbalanceRef = useRef<number>(null);
  const [businessPartnerSelected, setSelectedBusinessPartner] = useState<number>(null);
  const businessPartnerSelectedRef = useRef(null);
  const [businessPartnerSelectedCompanyId, setSelectedBusinessPartnerCompanyId] = useState<string>(null);
  const businessPartnerSelectedCompanyIdRef = useRef<string>(null);
  const [ownBusinessPartners, setOwnBusinessPartners] = useState<AllOwnBusinessPartnerMapping[]>(null);
  const [headerName, setHeaderNameOwn] = useState<any>({} as any);
  const [sanitizeColOwn, setSanitizeColOwn] = useState([]);
  const [sanitizeColBusiness, setSanitizeColBusiness] = useState([]);
  const [headerNameBp, setHeaderNameBusiness] = useState<any>({} as any);
  const [templateName, setTemplateNameOwn] = useState<string>("");
  const [templateNameBp, setTemplateNameBusiness] = useState<string>("");
  const [newMappingOwn, setNewMappingOwn] = useState<boolean>(false);
  const [newMappingBusiness, setNewMappingBusiness] = useState<boolean>(false);
  const [displayTemplateNameOwn, setDisplayTemplateNameOwn] = useState<string>(null);
  const [displayTemplateNameBusiness, setDisplayTemplateNameBusiness] = useState<string>(null);
  const summaryUIRef = useRef<OutputUISummary>(null);
  const [showLoadingIconOwn, setShowLoadingIconOwn] = useState<boolean>(false);
  const [showLoadingIconBusinessPartner, setShowLoadingIconBusinessPartner] = useState<boolean>(false);
  const [uploadedFileNameOwn, setUploadedFileNameOwn] = useState<string[]>(null);
  const [uploadedFileNameBusinessPartner, setUploadedFileNameBusinessPartner] = useState<string[]>(null);
  const [uploadedLastModifiedDateOwn, setUploadedLastModifiedDateOwn] = useState(null);
  const [uploadedLastModifiedDateBusinessPartner, setUploadedLastModifiedDateBusinessPartner] = useState(null);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState<boolean>(false);
  const [showUploadErrorAlert, setShowUploadErrorAlert] = useState<boolean>(false);
  const [showUploadWarningAlert, setShowUploadWarningAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>(null);
  const [rulesArray, setRulesArray] = useState([]);
  const [showReconcileLoadingIcon, setShowReconcileLoadingIcon] = useState<boolean>(false);
  const [finalWorkbookBase64, setFinalWorkbookBase64] = useState<string>("");
  const [waitingForOutputFile, setWaitingForOutputFile] = useState<boolean>(false);
  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [openOwnPdfDialog, setOpenOwnPdfDialog] = useState<boolean>(false);
  const [openBusinessPartnerPdfDialog, setOpenBusinessPartnerPdfDialog] = useState<boolean>(false);
  const [openPdfDialogErrorMessage, setOpenPdfDialogErrorMessage] = useState<boolean>(false);
  const [pdfDialogErrorMessage, setPdfDialogErrorMessage] = useState<any>("");
  const [ownPdfTableData, setOwnPdfTableData] = useState(null);
  const [businessPartnerPdfTableData, setBusinessPartnerPdfTableData] = useState(null);
  const [paymentGroupBy, setPaymentGroupBy] = useState([""]);
  const [expanded, setExpanded] = useState(null);
  const [selectedBusinessPartnerName, setSelectedBusinessPartnerName] = useState<string>("");
  const [ownDateSyncFromSAP, setOwnDateSyncFromSAP] = useState<any>({});
  const [businessPartnerDateSyncFromSAP, setbusinessPartnerDateSyncFromSAP] = useState<any>(null);
  const [businessPartnerIntegrationWithERP, setBusinessPartnerIntegrationWithERP] = useState<boolean>(false);
  const [businessPartnerAccountingSoftware, setbusinessPartnerAccountingSoftware] = useState<string>(null);
  const [assignBusinessPartnerName, setAssignBusinessPartnerName] = useState(null);
  const assignBusinessPartnerNameRef = useRef(null);
  const [errorPastDate, setErrorPastDate] = useState<boolean>(false);
  const [ownSpecificConfig, setOwnSpecificConfig] = useState(false);
  const [disableOwnSpecificConfigCheckbox, setDisableOwnSpecificConfigCheckbox] = useState(true);
  const [configList, setConfigList] = useState<any[]>([]);
  const [openColumnHeaderDialog, setOpenColumnHeaderDialog] = useState<boolean>(false);
  const [openDocumentTypeDialog, setOpenDocumentTypeDialog] = useState<boolean>(false);
  const [columnHeaderArr, setColumnHeaderArr] = useState<any>([]);
  const [documentTypesArr, setDocumentTypesArr] = useState<UnIdentifiedDoctype[]>([]);
  const groupedDocTypesArr = useRef<{ [k: string]: UnIdentifiedDoctype[] }>({});
  const [sanitizerModalOpened, setSanitizerModalOpened] = useState(false);
  const [configUserType, setConfigUserType] = useState<string>("");
  const [configUserName, setConfigUserName] = useState<string>("");
  const finaldocumentTypesObj = useRef<any>({});
  const ignoreDoctypesList = useRef<string[]>([]);
  const documentTypes =
    props.subTab === "Payment Advice"
      ? [
          "Invoice",
          "Discount/Rebate",
          "DN/CN",
          "Reversal",
          "TDS",
          "Inter Document",
          "Ignore this time",
          "Never ask again",
        ]
      : ["Invoice", "Payment", "DN/CN", "Reversal", "TDS", "Inter Document", "Ignore this time", "Never ask again"];

  const documentTypeSuggestionMap: Record<string, any> = {
    Invoice: "Invoice",
    Payments: "Payment",
    DNCN: "DN/CN",
    Reversal: "Reversal",
    InternalDoc: "Inter Document",
    TDS: "TDS",
    Ask: "Ignore this time",
  };

  const docTypeOnScreenSuggestionMap: Record<string, any> = {
    Invoice: "Invoice",
    Payments: props.subTab === "Payment Advice" ? "Discount/Rebate" : "Payment",
    DNCN: "DN/CN",
    Reversal: "Reversal",
    InternalDoc: "Inter Document",
    TDS: "TDS",
    Ask: "Ignore this time",
  };

  const [errorColumnHeaderConfig, setErrorColumnHeaderConfig] = useState<boolean>(false);
  const [errorchangeColumnHeaderConfig, setErrorchangeColumnHeaderConfig] = useState<boolean>(false);
  const [excelFileUploadOnChangeEvent, setExcelFileUploadOnChangeEvent] = useState<any>(null);
  const [excelFileUploadOnChangeFileName, setexcelFileUploadOnChangeFileName] = useState<string>("");
  const [sanitizerWarning, setSanitizerWarning] = useState<boolean>(false);
  const [sanitizerWarningMsg, setSanitizerWarningMsg] = useState<string>(null);
  const [listOwnTallyCompaniesForRecon, setListOwnTallyCompaniesForRecon] = useState<any>([]);
  const [listBPTallyCompaniesForRecon, setListBPTallyCompaniesForRecon] = useState<any>([]);
  const companyId = useRef<string>(null);
  const [summaryTypeSelected, setSummaryTypeSelected] = useState<string>(null);
  const summaryTypeSelectedRef = useRef<string>(null);
  const [showUploadedSummary, setShowUploadedSummary] = useState<boolean>(false);
  const [showUploadedSummaryObject, setShowUploadedSummaryObject] = useState<any>(null);
  const [showConfigMapped, setShowConfigMapped] = useState<boolean>(false);
  const [showConfigMappedData, setShowConfigMappedData] = useState<FileWiseDocTypeAndColumnHeader>(null);
  const selectedPdfConverter = useRef<string>("PDFLedgerParser");
  const [disablePdfTableOptionForMultiplePdf, setDisablePdfTableOptionForMultiplePdf] = useState<boolean>(false);
  const [pdfConversionLoader, setPdfConversionLoader] = useState<boolean>(true);
  const totalNumberOfUploadedFiles = useRef<number>(null);
  const currentFileNumber = useRef<number>(0);
  const currentFileName = useRef<string>(null);
  const [debitAmountSignReverseFlagOwn, setDebitAmountSignReverseFlagOwn] = useState<boolean>(false);
  const [debitAmountSignReverseFlagBP, setDebitAmountSignReverseFlagBP] = useState<boolean>(false);
  const [creditAmountSignReverseFlagOwn, setCreditAmountSignReverseFlagOwn] = useState<boolean>(false);
  const [creditAmountSignReverseFlagBP, setCreditAmountSignReverseFlagBP] = useState<boolean>(false);
  const [fetchDataFromERP, setFetchDataFromERP] = useState<boolean>(false);
  const [owntallyCompaniesLoaded, setOwnTallyCompaniesLoaded] = useState<boolean>(false);
  const [ownBusinessPartnersLoaded, setOwnBusinessPartnersLoaded] = useState<boolean>(true);
  const isDuplicatePresent = useRef<boolean>(false);
  const ownLedgerUpload = useRef<boolean>(false);

  const [rowsDataOverviewInsights, setRowsDataOverviewInsights] = useState<any>([]);
  const [isExpandedRunRecon, setIsExpandedRunRecon] = useState(true);
  const [isExpandedInsights, setIsExpandedInsights] = useState(false);
  const [isExpandedSummary, setIsExpandedSummary] = useState(false);
  const [isExpandedSignOffStatement, setIsExpandedSignOffStatement] = useState(false);
  const [showExpandedInsights, setShowExpandedInsights] = useState(false);
  const [showExpandedSummary, setShowExpandedSummary] = useState(false);
  const [showExpandedSignOffStatement, setShowExpandedSignOffStatement] = useState(false);
  const [accountingTerminology, setAccountingTerminology] = useState<amountEntryType>(null);

  // ---- UseDateFilter start ----//
  const [showUseDateFilter, setShowUseDateFilter] = useState<boolean>(false);
  const [startDateOwn, setStartDateOwn] = useState(null);
  const startDateOwnRef = useRef(null);
  const [startDateBusinessPartner, setStartDateBusinessPartner] = useState(null);
  const [endDateOwn, setEndDateOwn] = useState(null);
  const endDateOwnRef = useRef(null);
  const [endDateBusinessPartner, setEndDateBusinessPartner] = useState(null);
  // ----UseDateFilter end ------//

  // ---- disable fields when ledger is not uploaded start----//
  const [ledgerUploadedOwn, setLedgerUploadedOwn] = useState<boolean>(false);
  const [ledgerUploadedBP, setLedgerUploadedBP] = useState<boolean>(false);
  // ---- disable fields when ledger is not uploaded end----//

  // -- UnassignedDoctypeAdvanced Modal start  --//
  const [openUnassignedDoctypeAdvancedDialog, setUnassignedDoctypeAdvancedDialog] = useState<boolean>(false);
  const [itemForUnassignedDoctypeAdvancedDialog, setItemForUnassignedDoctypeAdvancedDialog] = useState(null);
  const [checkedAddParticular, setCheckedAddParticular] = useState<boolean>(true);
  const [addParticularText, setAddParticularText] = useState<string>("");
  const [addParticularTextArr, setAddParticularTextArr] = useState<string[]>([]);
  const [checkedExcludeParticular, setCheckedExcludeParticular] = useState<boolean>(false);
  const [excludeParticularText, setExcludeParticularText] = useState<string>("");
  const [excludeParticularTextArr, setExcludeParticularTextArr] = useState<string[]>([]);
  const [transactionValue, setTransactionValue] = useState<number>(0);
  const [selectedDocumentTypeInUnassignedModal, setSelectedDocumentTypeInUnassignedModal] = useState<string>(null);
  // const selectedDocumentTypeInUnassignedModalRef = useRef<string>(null);
  const isChangedDocTypeOnScreenSuggestionMap = useRef<boolean>(false);
  const [documentTypeRowInUnassignedAdvancedModal, setDocumentTypeRowInUnassignedAdvancedModal] = useState([]);
  // console.log("documentTypeRowInUnassignedAdvancedModal", documentTypeRowInUnassignedAdvancedModal);
  // console.log("headerName", headerName);

  const [documentTypesTagsInUnassignedAdvancedModal, setDocumentTypesTagsInUnassignedAdvancedModal] = useState<any>([]);
  const [documentTypesTags, setDocumentTypesTags] = useState<any>([]);
  const [documentTypeRow, setDocumentTypeRow] = useState([]);
  const [duplicateTagName, setDuplicateTagName] = useState("");
  // -- UnassignedDoctypeAdvanced Modal end  --//

  const [loadingConfigBP, setLoadingConfigBP] = useState<boolean>(false);
  const [loadingConfigOwn, setLoadingConfigOwn] = useState<boolean>(false);
  const [reconMetricEntries, setReconMetricEntries] = useState<any>([]);

  // -----Dispute UI------
  const [openMsgDialog, setOpenMsgDialog] = useState<boolean>(false);
  const [showInputMsg, setShowInputMsg] = useState<boolean>(false);
  const [displayInputMsg, setDisplayInputMsg] = useState<any[]>([]);
  const [inputMsg, setInputMsg] = useState<any>();
  const [invoiceAmountMismatchDispute, setInvoiceAmountMismatchDispute] = useState<any>();
  const [invoiceAmountMismatchDisputeValue, setInvoiceAmountMismatchDisputeValue] = useState<any>();
  const [allinvoiceAmountMismatchDispute, setAllinvoiceAmountMismatchDispute] = useState<any[]>([]);
  const [allinvoiceAmountMismatchDisputeValue1, setAllinvoiceAmountMismatchDisputeValue] = useState<number[]>([]);
  const [docBusinessPartner, setDocBusinessPartner] = useState<any>(null);
  // -----------------------------

  // ------Summary Details-----
  const summaryDetailsData = useRef([]);
  const [loader, setLoader] = useState<boolean>(false);
  // ------

  // --------open pdf option confirm------
  const [openConfirmOptionModal, setOpenConfirmOptionModal] = useState<boolean>(false);
  const isPdfOptionConfirm = useRef<boolean>(false);
  // --------
  // Manual Recon--------
  const [openManualRecon, setOpenManualRecon] = useState<boolean>(false);
  // const [hasReconciled, setHasReconciled] = useState<boolean>(false);
  const [amountTolerance, setAmountTolerance] = useState<number>(null);
  const [checkboxDataManualRecon, setCheckboxDataManualRecon] = useState<ManualReconciledEntriesTrack[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [hasManuallyReconciled, setHasManuallyReconciled] = useState<boolean>(true);
  // ---------

  const [selectedOwnCompany, setSelectedOwnCompany] = useState<ListOwnTallyCompanies>(null);
  const [selectedBusinessPartnerTallyCompany, setSelectedBusinessPartnerTallyCompany] = useState(null);
  const [showDownloadResultsLoadingIcon, setShowDownloadResultsLoadingIcon] = useState<boolean>(false);
  const [showDuplicateFoundModal, setShowDuplicateFoundModal] = useState<boolean>(false);

  // sign off statement
  const [showLoadingIconUploadGapSummary, setShowLoadingIconUploadGapSummary] = useState(false);
  // const [generateGapSummaryBase64, setGenerateGapSummaryBase64] = useState(null);
  const [dateOfLastRecon, setDateOfLastRecon] = useState(null);
  const [dateOfLastSummary, setDateOfLastSummary] = useState(null);
  const [showDownloadGapSummary, setShowDownloadGapSummary] = useState<boolean>(false);
  const [showLoadingIconDownloadGapSummary, setShowLoadingIconDownloadGapSummary] = useState(false);
  const EMAIL_TEMPLATE_NAME = "Share Recon with Partner";
  const [openSendMailDialog, setOpenSendMailDialog] = useState(false);
  const [emailTemplateContent, setEmailTemplateContent] = useState<EmailTemplate>(null);
  const [templateBody, setTemplateBody] = useState<string>("");
  const [loadingReconResultEmail, setLoadingReconResultEmail] = useState(false);
  const [reconEmailFiles, setReconEmailFiles] = useState<{
    originalOwnLedger: File[];
    originalBPLedger: File[];
    summaryOutput: File;
  }>({
    originalBPLedger: [],
    originalOwnLedger: [],
    summaryOutput: null,
  });

  const guidCommonTimestamp = useRef<string>("");

  interface ObjectEle {
    [code: string]: boolean;
  }
  /**
   * react hook
   * runs first time only when dependency array is empty
   * if empty array not given than it runs everytime on the change dependency array
   * dependency array defines variable on which use effect is used e.g: api call on first load of page
   * @public
   */
  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[process.env.REACT_APP_NAKAD_ENV]);
  }, []);

  useEffect(() => {
    setPrevLocation(location);
  }, [location, prevLocation]);

  useEffect(() => {
    if (!pathParam) {
      // history.push(`/${pathWay}/${DEFAULT_ACTIVE_TAB}`);
      history.push(`/${pathWay}/${DEFAULT_ACTIVE_TAB}/${props.subTab}`);
    }
    console.log("actor", actor);

    getLedgerConfigList();
    listReconSummaryAccountingSetting();
    if (actor.integration === true && actor.actorInfo.accountingSoftware === "Tally") {
      getListOwnTallyCompaniesForRecon();
    } else {
      listAllOwnBusinessPartnerMapping();
    }

    try {
      axios
        .get<ListSanitizationRulesResponse>("/api/listSanitizeRule")
        .then((response) => {
          setRulesArray(response.data.sanitizationRules);
        })
        .catch((error) => {
          console.log("error listSanitizeRule", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}listSanitizeRule`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error listSanitizeRule", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}listSanitizeRule`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }

    clearAllStates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subTab]);

  useEffect(() => {
    if (actor.integration && actor.actorInfo.accountingSoftware === "Tally") {
      if (businessPartnerSelected && endDateOwn) UpdateTallyClosingBalance();
    }
    // eslint-disable-next-line
  }, [businessPartnerSelected, endDateOwn]);

  const UpdateTallyClosingBalance = () => {
    const Response = {
      businessPartnerId: 0,
      closingBalance: 0,
      closingBalanceDate: "",
      openingBalance: 0,
    };

    useFetch<typeof Response>("/api/UpdateTallyClosingBalance", "POST", {
      setApiErrorMsg,
      setShowErrorAlert: setShowUploadErrorAlert,
      config: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        closingBalanceDate: moment(endDateOwn).format("YYYY-MM-DD"),
      },
      thenCallBack: (res) => {
        if (res.data.businessPartnerId === businessPartnerSelected) setOwnClosingBalance(res.data.closingBalance);
      },
    });
  };

  const listReconSummaryAccountingSetting = () => {
    try {
      axios
        .get<ListReconSummaryAccountingSettingResponse>("/api/ListReconSummaryAccountingSetting")
        .then((response) => {
          setSummaryTypeSelected(response.data.reconSummaryAccountingSetting.summaryTypeSelected);
          summaryTypeSelectedRef.current = response.data.reconSummaryAccountingSetting.summaryTypeSelected;
          setAccountingTerminology(response.data.amountEntryType);
        })
        .catch((error) => {
          console.log("error ListReconSummaryAccountingSetting", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListReconSummaryAccountingSetting`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListReconSummaryAccountingSetting", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListReconSummaryAccountingSetting`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getLedgerConfigList = async () => {
    try {
      await axios
        .get("/api/ListAllLedgerConfigTemplate")
        .then((response) => {
          setConfigList(response?.data?.ledgerConfigTemplates);
        })
        .catch((error) => {
          console.log("error ListAllLedgerConfigTemplate", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowUploadErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllLedgerConfigTemplate`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllLedgerConfigTemplate", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllLedgerConfigTemplate`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getListOwnTallyCompaniesForRecon = async () => {
    try {
      await axios
        .get<ListOwnTallyCompaniesRes>("/api/ListOwnTallyCompaniesForRecon")
        .then((response) => {
          setListOwnTallyCompaniesForRecon(response?.data?.tallyCompanies);
          setOwnTallyCompaniesLoaded(true);
          const lastTallyCompID = response?.data?.lastSelectedTallyCompanyId;
          if (lastTallyCompID) {
            const foundTallyCompany = response.data.tallyCompanies.find((c) => c.companyId === lastTallyCompID);
            const foundLastOwnTallyCompany = {
              firstLetter: foundTallyCompany?.companyName[0].toUpperCase(),
              ...foundTallyCompany,
            };
            setSelectedOwnCompany(foundLastOwnTallyCompany);
            companyNameSelect({}, foundLastOwnTallyCompany);
          }
        })
        .catch((error) => {
          console.log("error ListOwnTallyCompaniesForRecon", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowUploadErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListOwnTallyCompaniesForRecon`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListOwnTallyCompaniesForRecon", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListOwnTallyCompaniesForRecon`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const listAllOwnBusinessPartnerMapping = async () => {
    console.log("listAllOwnBusinessPartnerMapping");

    setOwnBusinessPartnersLoaded(false);
    try {
      await axios
        .get<ListAllOwnBusinessPartnerMappingResponse>("/api/listAllBusinessPartnerMapping", {
          params: {
            ownId: actor.id,
            companyId: companyId.current,
          },
        })
        .then((response) => {
          if (businessPartnerSelected !== null && response?.data?.ownBusinessPartnerMapping.length > 0) {
            const child = response.data.ownBusinessPartnerMapping.filter((value: AllOwnBusinessPartnerMapping) => {
              return value.ownBusinessPartnerMapping.businessPartnerId === businessPartnerSelected;
            });
            // businessPartnerSpecificConfig need to check
            if (props.subTab === "Ledger") {
              setOwnSpecificConfig(child[0]?.ownBusinessPartnerMapping?.ownReconSpecificConfig);
              setDisableOwnSpecificConfigCheckbox(child[0]?.ownBusinessPartnerMapping?.ownReconSpecificConfig);
            } else if (props.subTab === "Payment Advice") {
              setOwnSpecificConfig(child[0]?.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig);
              setDisableOwnSpecificConfigCheckbox(child[0]?.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig);
            }

            // if (templateNameBp !== "") {
            getBusinessPartnerLedgerConfigResponse(businessPartnerSelected);
            // }
          }
          setOwnBusinessPartners(response.data.ownBusinessPartnerMapping);
          setOwnBusinessPartnersLoaded(true);
          listUserEmailTemplates();

          const businessPartnerSelectFromDashboard = async (id: any, ownBusinessPartnersFromDash: any) => {
            console.log("id123123", id);

            // event.persist();
            // actor.integration && actor.actorInfo.accountingSoftware === "SAP" ? getOwnDateSyncFromSAP(id) : !actor.integration ? getOwnDataGetReconMetaData(id) :
            // getLastReconciliationStatus(id);
            if (actor.integration && actor.actorInfo.accountingSoftware === "SAP") {
              getOwnDateSyncFromSAP(id);
            } else if (!actor.integration) {
              getOwnDataGetReconMetaData(id);
            }
            const child = ownBusinessPartnersFromDash.filter((value: AllOwnBusinessPartnerMapping) => {
              return value.ownBusinessPartnerMapping.businessPartnerId === Number(id) ? value : false;
            });
            setBusinessPartnerIntegrationWithERP(child[0].ownBusinessPartnerMapping.integrationWithERP);
            setbusinessPartnerAccountingSoftware(child[0].ownBusinessPartnerMapping.accountingSoftware);

            // child[0].ownBusinessPartnerMapping.integrationWithERP &&
            // child[0].ownBusinessPartnerMapping.accountingSoftware === "SAP"
            //   ? getBusinessPartnerDateSyncFromSAP(id)
            //   : child[0].ownBusinessPartnerMapping.integrationWithERP &&
            //     child[0].ownBusinessPartnerMapping.accountingSoftware === "Tally"
            //   ? getBusinessPartnerDataSyncFromTally(child[0].ownBusinessPartnerMapping.companyId)
            //   : getBusinessPartnerDateGetReconMetaData(id);

            if (
              child[0].ownBusinessPartnerMapping.integrationWithERP &&
              child[0].ownBusinessPartnerMapping.accountingSoftware === "SAP"
            ) {
              getBusinessPartnerDateSyncFromSAP(id);
            } else if (!child[0].ownBusinessPartnerMapping.integrationWithERP) {
              getBusinessPartnerDateGetReconMetaData(id);
            }

            setSelectedBusinessPartner(child[0].ownBusinessPartnerMapping.businessPartnerId);
            businessPartnerSelectedRef.current = child[0].ownBusinessPartnerMapping.businessPartnerId;
            setSelectedBusinessPartnerName(child[0].ownBusinessPartnerMapping.businessPartnerName);
            setAssignBusinessPartnerName(child[0].ownBusinessPartnerMapping.businessPartnerName);
            assignBusinessPartnerNameRef.current = child[0].ownBusinessPartnerMapping.businessPartnerName;

            // setSelectedBusinessPartnerCompanyId(child[0].ownBusinessPartnerMapping.companyId);

            getBusinessPartnerLedgerConfigResponse(child[0].ownBusinessPartnerMapping.businessPartnerId);
            setOwnClosingBalance(
              child[0].ownBusinessPartnerMapping.ownClosingBalance === null
                ? parseInt("", 10)
                : child[0].ownBusinessPartnerMapping.ownClosingBalance
            );
            ownClosingbalanceRef.current =
              child[0].ownBusinessPartnerMapping.ownClosingBalance === null
                ? parseInt("", 10)
                : child[0].ownBusinessPartnerMapping.ownClosingBalance;
            setBusinessPartnerClosingBalance(
              child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance === null
                ? parseInt("", 10)
                : child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance
            );
            businessPartnerClosingbalanceRef.current =
              child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance === null
                ? parseInt("", 10)
                : child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance;
            setStartDateOwn(child[0].ownBusinessPartnerMapping.ownStartDate);
            startDateOwnRef.current = child[0].ownBusinessPartnerMapping.ownStartDate;
            setStartDateBusinessPartner(child[0].ownBusinessPartnerMapping.businessPartnerStartDate);
            if (child[0].ownBusinessPartnerMapping.ownEndDate !== null) {
              setEndDateOwn(child[0].ownBusinessPartnerMapping.ownEndDate);
              endDateOwnRef.current = child[0].ownBusinessPartnerMapping.ownEndDate;
            }
            if (child[0].ownBusinessPartnerMapping.businessPartnerEndDate !== null) {
              setEndDateBusinessPartner(child[0].ownBusinessPartnerMapping.businessPartnerEndDate);
            }
            setLedgerComparisonSummaryData(null);
            // setDisplayDownloadButton("none");
            setUploadedFileNameOwn(null);
            setUploadedFileNameBusinessPartner(null);
            setUploadedLastModifiedDateOwn(null);
            setUploadedLastModifiedDateBusinessPartner(null);
            if (props.subTab === "Ledger") {
              setOwnSpecificConfig(child[0]?.ownBusinessPartnerMapping?.ownReconSpecificConfig);
              setDisableOwnSpecificConfigCheckbox(child[0]?.ownBusinessPartnerMapping?.ownReconSpecificConfig);
            } else if (props.subTab === "Payment Advice") {
              setOwnSpecificConfig(child[0]?.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig);
              setDisableOwnSpecificConfigCheckbox(child[0]?.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig);
            }
            if (id) {
              getLastReconciliationStatus(id);
            }
          };

          if (!actor.integration) {
            const params = new URLSearchParams(location.search);
            // const paramChangeEvt = new CustomEvent("paramChange");

            const bpName = atob(params.get("bpName"));

            const foundBpId = response.data.ownBusinessPartnerMapping?.find(
              (value: AllOwnBusinessPartnerMapping) => value.ownBusinessPartnerMapping.businessPartnerName === bpName
            )?.ownBusinessPartnerMapping?.businessPartnerId;
            const bpId = Number(params.get("bpId")) || foundBpId;
            if (bpId && bpId !== undefined) {
              console.log("businessPartnerSelectFromDashboard");

              businessPartnerSelectFromDashboard(bpId, response.data.ownBusinessPartnerMapping);
            }
          }
          if (businessPartnerSelected && prevLocation && location.pathname !== prevLocation.pathname) {
            businessPartnerSelectFromDashboard(businessPartnerSelected, response.data.ownBusinessPartnerMapping);
          }
        })
        .catch((error) => {
          console.log("error listAllOwnBusinessPartnerMapping", error?.response, location.state.businessPartnerId);

          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}listAllOwnBusinessPartnerMapping`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      if (error?.response !== undefined) {
        console.log("error listAllOwnBusinessPartnerMapping", error?.response);
        setShowUploadErrorAlert(true);
        setApiErrorMsg(`${defaultErrorMsg}listAllOwnBusinessPartnerMapping`);
        apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
      }
    }
  };

  const getOwnLedgerConfigResponse = async (businessPartnerId: number) => {
    try {
      await axios
        .get<ListOwnLedgerConfigResponse>("/api/ListOwnLedgerConfig", {
          params: {
            businessPartnerId,
            businessPartnerName: assignBusinessPartnerNameRef.current,
            configUseCaseType:
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
          },
        })
        .then((response) => {
          console.log("ListOwnLedgerConfig", response.data);
          if (response.data.ledgerConfig.reconTemplateName || response.data.ledgerConfig.paymentAdviceTemplateName) {
            setNewMappingOwn(true);
          } else setNewMappingOwn(false);

          if (props.subTab === "Ledger") {
            // own
            setDisplayTemplateNameOwn(response.data.ledgerConfig.reconTemplateName);
            setTemplateNameOwn(response.data.ledgerConfig.reconTemplateName);
            setHeaderNameOwn(response.data.ledgerConfig?.reconConfig ? response.data.ledgerConfig?.reconConfig : {});
            setDebitAmountSignReverseFlagOwn(response?.data?.ledgerConfig?.reconConfig?.debitAmountSignReverseFlag);
            setCreditAmountSignReverseFlagOwn(response?.data?.ledgerConfig?.reconConfig?.creditAmountSignReverseFlag);
            setSanitizeColOwn(response.data.ledgerConfig.reconColumnsToSanitize);
          } else if (props.subTab === "Payment Advice") {
            // own
            setDisplayTemplateNameOwn(response.data.ledgerConfig.paymentAdviceTemplateName);
            setTemplateNameOwn(response.data.ledgerConfig.paymentAdviceTemplateName);
            setHeaderNameOwn(
              response.data.ledgerConfig?.paymentAdviceConfig ? response.data.ledgerConfig?.paymentAdviceConfig : {}
            );
            setDebitAmountSignReverseFlagOwn(
              response?.data?.ledgerConfig?.paymentAdviceConfig?.debitAmountSignReverseFlag
            );
            setCreditAmountSignReverseFlagOwn(
              response?.data?.ledgerConfig?.paymentAdviceConfig?.creditAmountSignReverseFlag
            );
            setSanitizeColOwn(response.data.ledgerConfig.paymentAdviceColumnsToSanitize);
          }
          setLoadingConfigOwn(false);
        })
        .catch((error) => {
          console.log("error ListOwnLedgerConfig", error?.response);
          setNewMappingBusiness(false);
          setLoadingConfigOwn(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListOwnLedgerConfig`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListOwnLedgerConfig", error?.response);
      setLoadingConfigOwn(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListOwnLedgerConfig`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getBusinessPartnerLedgerConfigResponse = async (businessPartnerId: any) => {
    getOwnLedgerConfigResponse(businessPartnerId);
    try {
      await axios
        .get<ListBusinessPartnerLedgerConfigResponse>("/api/ListBusinessPartnerLedgerConfig", {
          params: {
            businessPartnerId,
            businessPartnerName: assignBusinessPartnerNameRef.current,
            configUseCaseType:
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
          },
        })
        .then((response) => {
          console.log("ListBusinessPartnerLedgerConfig", response.data);
          if (response.data.ledgerConfig.reconTemplateName || response.data.ledgerConfig.paymentAdviceTemplateName) {
            setNewMappingBusiness(true);
          } else setNewMappingBusiness(false);

          if (props.subTab === "Ledger") {
            // bp
            setDisplayTemplateNameBusiness(response.data.ledgerConfig.reconTemplateName);
            setTemplateNameBusiness(response.data.ledgerConfig.reconTemplateName);
            setHeaderNameBusiness(
              response.data.ledgerConfig?.reconConfig ? response.data.ledgerConfig?.reconConfig : {}
            );
            setDebitAmountSignReverseFlagBP(response?.data?.ledgerConfig?.reconConfig?.debitAmountSignReverseFlag);
            setCreditAmountSignReverseFlagBP(response?.data?.ledgerConfig?.reconConfig?.creditAmountSignReverseFlag);
            setSanitizeColBusiness(response.data.ledgerConfig.reconColumnsToSanitize);
          } else if (props.subTab === "Payment Advice") {
            // bp
            setDisplayTemplateNameBusiness(response.data.ledgerConfig.paymentAdviceTemplateName);
            setTemplateNameBusiness(response.data.ledgerConfig.paymentAdviceTemplateName);
            setHeaderNameBusiness(
              response.data.ledgerConfig?.paymentAdviceConfig ? response.data.ledgerConfig?.paymentAdviceConfig : {}
            );
            setDebitAmountSignReverseFlagBP(
              response?.data?.ledgerConfig?.paymentAdviceConfig?.debitAmountSignReverseFlag
            );
            setCreditAmountSignReverseFlagBP(
              response?.data?.ledgerConfig?.paymentAdviceConfig?.creditAmountSignReverseFlag
            );
            setSanitizeColBusiness(response.data.ledgerConfig.paymentAdviceColumnsToSanitize);
          }
          setLoadingConfigBP(false);
        })
        .catch((error) => {
          console.log("error ListBusinessPartnerLedgerConfig", error?.response);
          setNewMappingBusiness(false);
          setDisplayTemplateNameBusiness("");
          setTemplateNameBusiness("");
          setHeaderNameBusiness({});
          setLoadingConfigBP(false);

          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListBusinessPartnerLedgerConfig`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListBusinessPartnerLedgerConfig", error?.response);
      setLoadingConfigBP(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListBusinessPartnerLedgerConfig`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  /** This is a column definition of the grid on Own Entry Adjustment page - Pending sub-tab
   * @public
   */

  const colDefns = [
    {
      Header: (): any => null,
      id: "expander",
      Cell: ({ row }: any) => {
        if (row.id === "1" || row.id === "2" || row.id === "3" || row.id === "4" || row.id === "5" || row.id === "8") {
          return <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "➖" : "➕"}</span>;
        } else {
          return null;
        }
      },
      // We can override the cell renderer with a SubCell to be used with an expanded row
      SubCell: (): any => null, // No expander on an expanded row
      minWidth: 40,
      maxWidth: 40,
      width: 40,
    },
    {
      Header: "Reconciliation Status",
      accessor: "line_item",
      Sort: true,
      minWidth: 400,
      maxWidth: 400,
      width: 400,
    },

    {
      Header: "Breakup (INR)",
      accessor: "sub_total",
      Sort: true,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    },
    {
      Header: "Value (INR)",
      accessor: "total",
      Sort: true,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    },
    {
      Header: "See details",
      accessor: "details",
      Sort: false,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      padding: 0,
      textAlign: "center",
    },
  ];
  /** This is a column definition of the grid on Own Entry Adjustment page - Completed sub-tab
   * @public
   */

  const settledColDefnsOpenDetailsMismatched: any[] = [
    {
      // Header: "Line Item",
      Header: "Reconciliation Status",
      accessor: "item",
      Sort: true,
      columns: [],
      maxWidth: 225,
      minWidth: 225,
      width: 225,
    },
    {
      Header: () => <div className="borderRight">Own</div>,
      id: "own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      columns: [
        {
          Header: "Document Match Id",
          accessor: "own_invoice_match",
          Sort: true,
          maxWidth: 100,
          minWidth: 100,
          width: 100,
        },
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
      Sort: true,
    },
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
    },
    {
      Header: "Difference",
      accessor: "difference",
      Sort: true,
      columns: [],
      maxWidth: 140,
      minWidth: 140,
      width: 140,
    },
    // -----Dispute UI------

    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];
  const settledColDefnsOpenDetailsMismatchedInvoiceOthers: any[] = [
    {
      // Header: "Line Item",
      Header: "Reconciliation Status",
      accessor: "item",
      Sort: true,
      columns: [],
      maxWidth: 225,
      minWidth: 225,
      width: 225,
    },
    {
      Header: () => <div className="borderRight">Own</div>,
      id: "own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      columns: [
        {
          Header: "Document Match Id",
          accessor: "own_invoice_match",
          Sort: true,
          maxWidth: 100,
          minWidth: 100,
          width: 100,
        },
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
      Sort: true,
    },
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
    },
    {
      Header: "Difference",
      accessor: "difference",
      Sort: true,
      columns: [],
      maxWidth: 140,
      minWidth: 140,
      width: 140,
    },

    // -----Dispute UI------

    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_own)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_own, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  // -----Dispute UI------

  const adjustEntry = (e: any) => {
    console.log("demo", e);
    setDocBusinessPartner(e);
    console.log("docBusinessPartner", docBusinessPartner);
    console.log("detailsHeading o", detailsHeading);
    console.log(" detailsHeadingName.current o", detailsHeadingName.current);

    //
    if (detailsHeadingName.current === reconciliationStatus.invoiceAmountMismatch) {
      const tempInvoicePaymentsDNCNSummary = summaryDetailsData.current;
      for (const [i, value] of tempInvoicePaymentsDNCNSummary.entries()) {
        if (e === value.doc_own) {
          tempInvoicePaymentsDNCNSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsMismatchedInvoiceOthers);
      setPaymentGroupBy(["own_invoice_match"]);

      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(tempInvoicePaymentsDNCNSummary);
      console.log("tempInvoicePaymentsDNCNSummary", tempInvoicePaymentsDNCNSummary);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInPartnerLedger) {
      const tempInvoicePaymentsDNCNSummary = summaryDetailsData.current;
      for (const [i, value] of tempInvoicePaymentsDNCNSummary.entries()) {
        if (e === value.doc_own) {
          tempInvoicePaymentsDNCNSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInOwnLedger) {
      const tempInvoicePaymentsDNCNSummary = summaryDetailsData.current;
      for (const [i, value] of tempInvoicePaymentsDNCNSummary.entries()) {
        if (e === value.doc_businessPartner) {
          tempInvoicePaymentsDNCNSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentAmountMismatch) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_own) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }

      setColumnDefinition(settledColDefnsOpenPaymentAmountMismatch);
      setPaymentGroupBy(["own_payment_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_payment_match.length > 0) {
          console.log("own_payment_match", setExpandedValue);
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInPartnerLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenPaymentAmountExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInOwnLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenPaymentAmountMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }

      setColumnDefinition(settledColDefnsOpenDNCNMismatch);
      setPaymentGroupBy(["own_dncn_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_dncn_match.length > 0) {
          console.log("inoiceme", setExpandedValue);
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInOwnLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenTDSAmountExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenTDSAmountMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsAmountMismatch) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsMismatched);
      setPaymentGroupBy(["own_invoice_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
        if (item.own_payment_match.length > 0) {
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
        if (item.own_dncn_match.length > 0) {
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    }
    //
  };

  const raiseDisputeHandler = (doc: string, value: number) => {
    setOpenMsgDialog(true);
    setInvoiceAmountMismatchDispute(doc);
    setInvoiceAmountMismatchDisputeValue(value);
    console.log("raise doc", doc, "value", value);
  };

  const msgRaiseDisputeHandler = (doc: string, value: number) => {
    console.log("raise doc & value inside msg", doc, "value", value);
    const updateallinvoiceAmountMismatchDispute = [...allinvoiceAmountMismatchDispute, doc];
    setAllinvoiceAmountMismatchDispute(updateallinvoiceAmountMismatchDispute);
    const updateallinvoiceAmountMismatchDisputeValue = [...allinvoiceAmountMismatchDisputeValue1, value];

    let sumOfValues: number = 0;
    updateallinvoiceAmountMismatchDisputeValue.map((ele) => {
      return (sumOfValues += ele);
    });
    setAllinvoiceAmountMismatchDisputeValue(updateallinvoiceAmountMismatchDisputeValue);

    console.log(
      "updateallinvoiceAmountMismatchDispute",
      updateallinvoiceAmountMismatchDispute,
      updateallinvoiceAmountMismatchDisputeValue
    );
    setOpenMsgDialog(false);
    setInputMsg("");
    setShowInputMsg(false);

    //
    if (detailsHeadingName.current === reconciliationStatus.invoiceAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_own) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsMismatchedInvoiceOthers);
      setPaymentGroupBy(["own_invoice_match"]);

      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(tempDetailsJSON);
      console.log("tempDetailsJSON", tempDetailsJSON);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_own) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_businessPartner) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_own) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenPaymentAmountMismatch);
      setPaymentGroupBy(["own_payment_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_payment_match.length > 0) {
          console.log("own_payment_match", setExpandedValue);
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenPaymentAmountExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenPaymentAmountMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMismatch);
      setPaymentGroupBy(["own_dncn_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_dncn_match.length > 0) {
          console.log("inoiceme", setExpandedValue);
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenTDSAmountExtra);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenTDSAmountMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDetailsMismatched);
      setPaymentGroupBy(["own_invoice_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
        if (item.own_payment_match.length > 0) {
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
        if (item.own_dncn_match.length > 0) {
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(settledColDefnsOpenDNCNMissed);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    }
    //
  };

  const getTimeAMPMFormat = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const hourStr = hours.toString().padStart(2, "0");
    // appending zero in the start if hours less than 10
    const minuteStr = minutes.toString().padStart(2, "0");
    return hourStr + ":" + minuteStr + " " + ampm;
  };

  const displayInputMsgHandler = (e: any) => {
    const updatedInputMsg = e.target.value;
    setInputMsg(updatedInputMsg);
  };

  const handleSend = (e: any) => {
    e.preventDefault();
    displayInputMsg.push(inputMsg);
    setDisplayInputMsg(displayInputMsg);
    setShowInputMsg(true);
    setInputMsg("");
  };
  // ----------------------

  const settledColDefnsOpenDetailsExtra: any[] = [
    {
      Header: "Own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_own)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_own, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenDetailsMissed: any[] = [
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_businessPartner)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_businessPartner, row?.original?.value_businessPartner);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenPaymentAmountMismatch: any[] = [
    {
      Header: "Reconciliation Status",
      accessor: "item",
      Sort: true,
      columns: [],
      maxWidth: 225,
      minWidth: 225,
      width: 225,
    },
    {
      Header: () => <div className="borderRight">Own</div>,
      id: "own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Payment Match Id",
          accessor: "own_payment_match",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
    },
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        // {
        //   Header: "Payment Match",
        //   accessor: "businessPartner_payment_match",
        //   Sort: true,
        //   maxWidth: 140,
        //   minWidth: 140,
        //   width: 140,
        // },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_own)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_own, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenPaymentAmountExtra: any[] = [
    {
      Header: "Own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenTDSAmountExtra: any[] = [
    {
      Header: "Own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenPaymentAmountMissed: any[] = [
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 260,
          minWidth: 260,
          width: 260,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 260,
          minWidth: 260,
          width: 260,
        },
        // {
        //   Header: "Payment Match",
        //   accessor: "businessPartner_payment_match",
        //   Sort: true,
        //   maxWidth: 260,
        //   minWidth: 260,
        //   width: 260,
        // },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr_sup)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr_sup, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenTDSAmountMissed: any[] = [
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 260,
          minWidth: 260,
          width: 260,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 260,
          minWidth: 260,
          width: 260,
        },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr_sup)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr_sup, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenDNCNMismatch: any[] = [
    {
      Header: "Reconciliation Status",
      accessor: "item",
      Sort: true,
      columns: [],
      maxWidth: 225,
      minWidth: 225,
      width: 225,
    },
    {
      Header: () => <div className="borderRight">Own</div>,
      id: "own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 150,
          minWidth: 150,
          width: 150,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 150,
          minWidth: 150,
          width: 150,
        },

        {
          Header: "DNCN Match Id",
          accessor: "own_dncn_match",
          Sort: true,
          maxWidth: 150,
          minWidth: 150,
          width: 150,
        },
      ],
    },
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 150,
          minWidth: 150,
          width: 150,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 150,
          minWidth: 150,
          width: 150,
        },

        // {
        //   Header: "DNCN Match",
        //   accessor: "businessPartner_dncn_match",
        //   Sort: true,
        //   maxWidth: 150,
        //   minWidth: 150,
        //   width: 150,
        // },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenDNCNExtra: any[] = [
    {
      Header: "Own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },
    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr, row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const settledColDefnsOpenDNCNMissed: any[] = [
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        // {
        //   Header: "DNCN Match",
        //   accessor: "businessPartner_dncn_match",
        //   Sort: true,
        //   maxWidth: 300,
        //   minWidth: 300,
        //   width: 300,
        // },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.doc_date_qtr_sup)}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  console.log("value", "value");
                  raiseDisputeHandler(row?.original?.doc_date_qtr_sup, row?.original?.value_businessPartner);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];

  const insightsLatestEntriesColDefOwn = [
    {
      Header: "Own",
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 400,
          minWidth: 400,
          width: 400,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 300,
          minWidth: 300,
          width: 300,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 300,
          minWidth: 300,
          width: 300,
        },
      ],
    },
  ];
  const insightsLatestEntriesColDefBP = [
    {
      Header: "Business Partner",
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 400,
          minWidth: 400,
          width: 400,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 300,
          minWidth: 300,
          width: 300,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 300,
          minWidth: 300,
          width: 300,
        },
      ],
    },
  ];
  const insightsLatestEntriesColDefOwnBP = [
    {
      Header: () => <div className="borderRight">Own</div>,
      id: "insightsLatestOwn",
      accessor: "",
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value (INR)",
          accessor: "value",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
      Sort: true,
    },
    {
      Header: "Business Partner",
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value (INR)",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
    },
  ];

  const excelFileUploadOnChange = async (file: any, fileName: string) => {
    const fileArr: any[] = [];
    if (file !== undefined && file !== null && file.length > 0) {
      for (const item of file) {
        fileArr.push(item);
      }
      totalNumberOfUploadedFiles.current = fileArr.length;

      setExcelFileUploadOnChangeEvent(fileArr);
      setexcelFileUploadOnChangeFileName(fileName);

      if (fileArr.length > 1) {
        setDisablePdfTableOptionForMultiplePdf(true);
      } else {
        setDisablePdfTableOptionForMultiplePdf(false);
      }
    }

    const firstType = file[0].type;

    const allSameType = Object.keys(file).every((key: any) => {
      if (firstType.includes("spreadsheet") || firstType.includes("excel")) {
        return file[key].type.includes("spreadsheet") || file[key].type.includes("excel");
      } else {
        return file[key].type === firstType;
      }
    });

    if (allSameType) {
      if (firstType === "application/pdf") {
        if (!isPdfOptionConfirm.current) {
          setOpenConfirmOptionModal(true);
        } else {
          isPdfOptionConfirm.current = false;
          if (fileName === "Own Ledger") {
            setShowLoadingIconOwn(true);

            const bodyFormData = new FormData();
            bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
            bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
            // bodyFormData.append("user", "own");
            bodyFormData.append("ownId", actor.id);
            bodyFormData.append("pdfConversionMethod", selectedPdfConverter.current);
            bodyFormData.append("isOwnConfig", "true");
            bodyFormData.append(
              "configUseCaseType",
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
            );
            for (const item of file) {
              bodyFormData.append("data", item);
            }

            try {
              axios
                .post<any>("/api/UploadPDFAsExcel", bodyFormData, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                  clarity.setTag("Own Ledger Upload", `PDF`);
                  setPdfConversionLoader(false);
                  setShowLoadingIconOwn(false);
                  setOwnPdfTableData({
                    JSON: response.data.result.data.JSON,
                    businessPartnerId: businessPartnerSelected,
                    own: true,
                    data: response.data.result.data.data,
                    fileName: file.name,
                    workbook: response?.data?.result?.data?.workbook,
                    highlightedColumns: response?.data?.result?.data?.highlightedColumns,
                    errorMessage: response?.data?.result?.data?.errorMessage,
                  });
                  setPdfDialogErrorMessage(response?.data?.result?.data?.errorMessage);
                  response.data.result.data.JSON.length === 0
                    ? setOpenPdfDialogErrorMessage(true)
                    : setOpenOwnPdfDialog(true);
                })
                .catch((error) => {
                  console.log("error UploadPDFAsExcel", error?.response);
                  setPdfConversionLoader(false);
                  setShowLoadingIconOwn(false);

                  if (error?.response?.data?.message !== undefined) {
                    const dataObj = error.response.data as ErrorResponse;
                    setShowUploadErrorAlert(true);
                    setApiErrorMsg(dataObj.message);
                    apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                  } else {
                    setShowUploadErrorAlert(true);
                    setApiErrorMsg(`${defaultErrorMsg}UploadPDFAsExcel`);
                    apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                  }
                });
            } catch (error: any) {
              console.log("error UploadPDFAsExcel", error?.response);
              setPdfConversionLoader(false);
              setShowUploadErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UploadPDFAsExcel`);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            }
            // };
          } else if (fileName === "BusinessPartner Ledger") {
            setShowLoadingIconBusinessPartner(true);

            const bodyFormData = new FormData();
            bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
            bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
            // bodyFormData.append("user", "bp");
            bodyFormData.append("ownId", actor.id);
            bodyFormData.append("pdfConversionMethod", selectedPdfConverter.current);
            bodyFormData.append("isOwnConfig", "false");
            bodyFormData.append(
              "configUseCaseType",
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
            );
            for (const item of file) {
              bodyFormData.append("data", item);
            }

            try {
              axios
                .post<any>("/api/UploadPDFAsExcel", bodyFormData, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                  clarity.setTag("BP Ledger Upload", `PDF`);
                  setPdfConversionLoader(false);
                  setShowLoadingIconBusinessPartner(false);
                  setBusinessPartnerPdfTableData({
                    JSON: response.data.result.data.JSON,
                    businessPartnerId: businessPartnerSelected,
                    own: false,
                    data: response.data.result.data.data,
                    fileName: file.name,
                    workbook: response?.data?.result?.data?.workbook,
                    highlightedColumns: response?.data?.result?.data?.highlightedColumns,
                    errorMessage: response?.data?.result?.data?.errorMessage,
                  });
                  setPdfDialogErrorMessage(response?.data?.result?.data?.errorMessage);
                  response.data.result.data.JSON.length === 0
                    ? setOpenPdfDialogErrorMessage(true)
                    : setOpenBusinessPartnerPdfDialog(true);
                })
                .catch((error) => {
                  console.log("error", error.response);
                  setPdfConversionLoader(false);
                  setShowLoadingIconBusinessPartner(false);
                  if (error?.response?.data?.message !== undefined) {
                    const dataObj = error.response.data as ErrorResponse;
                    setShowUploadErrorAlert(true);
                    setApiErrorMsg(dataObj.message);
                    apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                  } else {
                    setShowUploadErrorAlert(true);
                    setApiErrorMsg(`${defaultErrorMsg}UploadPDFAsExcel`);
                    apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                  }
                });
            } catch (error: any) {
              console.log("error UploadPDFAsExcel", error?.response);
              setPdfConversionLoader(false);
              setShowUploadErrorAlert(true);
              setApiErrorMsg(`${defaultErrorMsg}UploadPDFAsExcel`);
              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
            }
            // };
          }
        }
      } else {
        const bodyFormData = new FormData();
        bodyFormData.append("file", fileArr[currentFileNumber.current]);
        bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
        bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
        bodyFormData.append("guid", guidCommonTimestamp.current);
        bodyFormData.append("replaceOldData", currentFileNumber.current === 0 ? "true" : "false");
        bodyFormData.append(
          "showSummary",
          currentFileNumber.current === totalNumberOfUploadedFiles.current - 1 ? "true" : "false"
        );
        if (
          ignoreDoctypesList.current === undefined ||
          ignoreDoctypesList.current === null ||
          ignoreDoctypesList.current?.toString()?.trim() === ""
        ) {
          bodyFormData.append("ignoreDoctypesList", JSON.stringify([]));
        } else {
          bodyFormData.append("ignoreDoctypesList", JSON.stringify(ignoreDoctypesList.current));
        }
        bodyFormData.append(
          "configUseCaseType",
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
        );
        bodyFormData.append("isSingleUpload", totalNumberOfUploadedFiles.current === 1 ? "true" : "false");
        bodyFormData.append("isDuplicatePresent", isDuplicatePresent.current ? "true" : "false");

        if (fileName === "Own Ledger") {
          setShowLoadingIconOwn(true);
          currentFileName.current = fileArr[currentFileNumber.current]?.name;
          try {
            await axios
              .post<UploadOwnLedgerDataResponse>("/api/uploadBusinessPartnerLedgerFromRU", bodyFormData)
              .then((response) => {
                clarity.setTag("Own Ledger Upload", `Excel`);
                setShowLoadingIconOwn(false);
                if (response.data.result.response === "warning-unidentified") {
                  setApiErrorMsg("Unidentified columns present.");
                  setShowUploadWarningAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadWarningAlert, setApiErrorMsg);
                } else if (response.data.result.response === "warning-nonEmptyDocTypes") {
                  setApiErrorMsg("Unidentified document types present.");
                  setShowUploadWarningAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadWarningAlert, setApiErrorMsg);
                } else if (response.data.result.response === "warning") {
                  setApiErrorMsg("File uploaded successfully. Duplicate column headers exist. Please review.");
                  setShowUploadWarningAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadWarningAlert, setApiErrorMsg);
                } else {
                  // set headers from success response after file successfully uploaded and including configUsedInMappingFile key
                  if (props.subTab === "Ledger") {
                    setHeaderNameOwn(response.data?.result?.config?.reconConfig || {});
                  } else if (props.subTab === "Payment Advice") {
                    setHeaderNameOwn(response.data?.result?.config?.paymentAdviceConfig || {});
                  }

                  setUploadedLastModifiedDateOwn(
                    new Date().getDate() +
                      "/" +
                      (new Date().getMonth() + 1) +
                      "  " +
                      new Date().toLocaleTimeString("en-US", {
                        // en-US can be set to 'default' to use user's browser settings
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                  );
                  setApiErrorMsg("File uploaded successfully");
                  setShowUploadSuccessAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
                }
                if (response.data?.result?.isDuplicatePresent === true) {
                  isDuplicatePresent.current = true;
                  ownLedgerUpload.current = true;
                }
                if (response.data?.result?.uploadSummary !== null) {
                  setShowUploadedSummaryObject(response.data?.result?.uploadSummary);
                  setShowConfigMappedData(response.data?.result?.configMapped);

                  if (response.data?.result?.isDuplicatePresent === true) setShowDuplicateFoundModal(true);
                  else setShowUploadedSummary(true);

                  const summaryObj = response.data?.result?.uploadSummary;

                  setUploadedFileNameOwn(() => {
                    const fileNames = summaryObj
                      ? Object.keys(summaryObj).filter((k) => k.toLowerCase() !== UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY)
                      : [];
                    return fileNames;
                  });
                }
                setEndDateOwn(new Date());
                endDateOwnRef.current = new Date();
                setEndDateBusinessPartner(new Date());

                actor.integration && actor.actorInfo.accountingSoftware === "SAP"
                  ? getOwnDateSyncFromSAP(businessPartnerSelected)
                  : actor.integration && actor.actorInfo.accountingSoftware === "Tally"
                  ? getOwnDataSyncFromTally()
                  : getOwnDataGetReconMetaData(businessPartnerSelected);

                if (response?.data?.result?.unIdentifiedColumns.length > 0) {
                  const columnHeaderArrwithErrorFlag = response.data.result.unIdentifiedColumns.map((object: any) => {
                    return {
                      ...object,
                      errorFlag: false,
                      columnHeaderConfigValue: object.columnHeaders[object.suggestedHeaderIndex],
                    };
                  });
                  if (Object.keys(headerName).length === 0) setHeaderNameOwn(response.data.result.defaultConfig);
                  setColumnHeaderArr(columnHeaderArrwithErrorFlag);
                  setConfigUserType("Own Ledger");
                  setFetchDataFromERP(false);
                  setConfigUserName(actor?.name);
                  setOpenColumnHeaderDialog(true);
                }
                if (response?.data?.result?.nonEmptyDocTypes?.length > 0) {
                  setDocumentTypesArr(response.data.result.nonEmptyDocTypes);
                  finaldocumentTypesObj.current = Object.fromEntries(
                    response.data.result.nonEmptyDocTypes.map((item) => [
                      item.unidentifiedText,
                      {
                        documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                        transactionType: 0,
                      },
                    ])
                  );

                  setConfigUserType("Own Ledger");
                  setFetchDataFromERP(false);
                  setConfigUserName(actor?.name);
                  changeDocumentTypeConfig(
                    response.data.result.nonEmptyDocTypes[0].unidentifiedText,
                    documentTypeSuggestionMap[response.data.result.nonEmptyDocTypes[0].suggestedDoctype]
                  );
                  if (response?.data?.result?.unIdentifiedColumns.length > 0) {
                    setOpenDocumentTypeDialog(false);
                  } else {
                    setOpenDocumentTypeDialog(true);
                  }
                } else {
                  setDocumentTypesArr([]);
                }
                if (
                  response?.data?.result?.unIdentifiedColumns.length === 0 &&
                  response?.data?.result?.nonEmptyDocTypes.length === 0
                ) {
                  currentFileNumber.current = currentFileNumber.current + 1;
                  if (fileArr.length > 1) {
                    if (currentFileNumber.current < totalNumberOfUploadedFiles.current) {
                      excelFileUploadOnChange(fileArr, fileName);
                    } else {
                      ignoreDoctypesList.current = [];
                    }
                  } else {
                    ignoreDoctypesList.current = [];
                  }
                }
              })
              .catch((error) => {
                console.log("error uploadBusinessPartnerLedgerFromOwn", error?.response);

                const summaryObj = error?.response.data?.uploadSummary || {};
                if (error?.response.data?.uploadSummary !== null) {
                  setShowUploadedSummaryObject(error?.response.data?.uploadSummary);
                  setShowConfigMappedData(error?.response.data?.configMapped);
                  if (currentFileNumber.current === totalNumberOfUploadedFiles.current - 1) {
                    setShowUploadedSummary(true);
                  }

                  setUploadedFileNameOwn(() => {
                    const fileNames = Object.keys(summaryObj).filter(
                      (k) => k.toLowerCase() !== UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY
                    );
                    return fileNames;
                  });
                }

                currentFileNumber.current = currentFileNumber.current + 1;
                if (fileArr.length > 1) {
                  if (currentFileNumber.current < totalNumberOfUploadedFiles.current) {
                    excelFileUploadOnChange(fileArr, fileName);
                  } else {
                    ignoreDoctypesList.current = [];
                  }
                } else {
                  ignoreDoctypesList.current = [];
                }

                setShowLoadingIconOwn(false);
                if (error?.response?.data?.message !== undefined) {
                  const dataObj = error.response.data as ErrorResponse;
                  setShowUploadErrorAlert(true);
                  setApiErrorMsg(dataObj.message);
                  apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                } else {
                  setShowUploadErrorAlert(true);
                  setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromOwn`);
                  apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                }
              });
          } catch (error: any) {
            console.log("error uploadBusinessPartnerLedgerFromOwn", error?.response);
            setShowLoadingIconOwn(false);
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromOwn`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        } else if (fileName === "BusinessPartner Ledger") {
          setShowLoadingIconBusinessPartner(true);
          currentFileName.current = fileArr[currentFileNumber.current]?.name;
          try {
            await axios
              .post<UploadOwnLedgerDataResponse>("/api/uploadBusinessPartnerLedgerFromBusinessPartner", bodyFormData)
              .then((response) => {
                clarity.setTag("BP Ledger Upload", `Excel`);
                setShowLoadingIconBusinessPartner(false);
                if (response.data.result.response === "warning-unidentified") {
                  setApiErrorMsg("Unidentified columns present.");
                  setShowUploadWarningAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadWarningAlert, setApiErrorMsg);
                } else if (response.data.result.response === "warning-nonEmptyDocTypes") {
                  setApiErrorMsg("Unidentified document types present.");
                  setShowUploadWarningAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadWarningAlert, setApiErrorMsg);
                } else if (response.data.result.response === "warning") {
                  setApiErrorMsg("File uploaded successfully. Duplicate column headers exist. Please review.");
                  setShowUploadWarningAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadWarningAlert, setApiErrorMsg);
                } else {
                  // set headers from success response after file successfully uploaded and including configUsedInMappingFile key
                  if (props.subTab === "Ledger") {
                    setHeaderNameBusiness(response.data?.result?.config?.reconConfig || {});
                  } else if (props.subTab === "Payment Advice") {
                    setHeaderNameBusiness(response.data?.result?.config?.paymentAdviceConfig || {});
                  }

                  setUploadedLastModifiedDateBusinessPartner(
                    new Date().getDate() +
                      "/" +
                      (new Date().getMonth() + 1) +
                      "  " +
                      new Date().toLocaleTimeString("en-US", {
                        // en-US can be set to 'default' to use user's browser settings
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                  );
                  setApiErrorMsg("File uploaded successfully");
                  setShowUploadSuccessAlert(true);
                  apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
                }
                if (response.data?.result?.isDuplicatePresent === true) {
                  isDuplicatePresent.current = true;
                  ownLedgerUpload.current = false;
                }
                if (response.data?.result?.uploadSummary !== null) {
                  setShowUploadedSummaryObject(response.data?.result?.uploadSummary);
                  setShowConfigMappedData(response.data?.result?.configMapped);

                  if (response.data?.result?.isDuplicatePresent === true) setShowDuplicateFoundModal(true);
                  else setShowUploadedSummary(true);

                  const summaryObj = response.data?.result?.uploadSummary;

                  setUploadedFileNameBusinessPartner(() => {
                    const fileNames = Object.keys(summaryObj).filter(
                      (k) => k.toLowerCase() !== UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY
                    );
                    return fileNames;
                  });
                }
                setEndDateBusinessPartner(new Date());

                businessPartnerIntegrationWithERP && businessPartnerAccountingSoftware === "SAP"
                  ? getBusinessPartnerDateSyncFromSAP(businessPartnerSelected)
                  : businessPartnerIntegrationWithERP && businessPartnerAccountingSoftware === "Tally"
                  ? getBusinessPartnerDataSyncFromTally(businessPartnerSelectedCompanyId)
                  : getBusinessPartnerDateGetReconMetaData(businessPartnerSelected);

                if (response?.data?.result?.unIdentifiedColumns.length > 0) {
                  const columnHeaderArrwithErrorFlag = response.data.result.unIdentifiedColumns.map((object: any) => {
                    return {
                      ...object,
                      errorFlag: false,
                      columnHeaderConfigValue: object.columnHeaders[object.suggestedHeaderIndex],
                    };
                  });
                  if (Object.keys(headerNameBp).length === 0) setHeaderNameBusiness(response.data.result.defaultConfig);
                  setColumnHeaderArr(columnHeaderArrwithErrorFlag);
                  setConfigUserType("BusinessPartner Ledger");
                  setFetchDataFromERP(false);
                  setConfigUserName(assignBusinessPartnerName);
                  setOpenColumnHeaderDialog(true);
                }

                if (response?.data?.result?.nonEmptyDocTypes?.length > 0) {
                  setDocumentTypesArr(response.data.result.nonEmptyDocTypes);
                  finaldocumentTypesObj.current = Object.fromEntries(
                    response.data.result.nonEmptyDocTypes.map((item) => [
                      item.unidentifiedText,
                      {
                        documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                        transactionType: 0,
                      },
                    ])
                  );

                  setConfigUserType("BusinessPartner Ledger");
                  setFetchDataFromERP(false);
                  setConfigUserName(assignBusinessPartnerName);
                  changeDocumentTypeConfig(
                    response.data.result.nonEmptyDocTypes[0].unidentifiedText,
                    documentTypeSuggestionMap[response.data.result.nonEmptyDocTypes[0].suggestedDoctype]
                  );
                  if (response?.data?.result?.unIdentifiedColumns.length > 0) {
                    setOpenDocumentTypeDialog(false);
                  } else {
                    setOpenDocumentTypeDialog(true);
                  }
                } else {
                  setDocumentTypesArr([]);
                }

                if (
                  response?.data?.result?.unIdentifiedColumns.length === 0 &&
                  response?.data?.result?.nonEmptyDocTypes.length === 0
                ) {
                  currentFileNumber.current = currentFileNumber.current + 1;
                  if (fileArr.length > 1) {
                    if (currentFileNumber.current < totalNumberOfUploadedFiles.current) {
                      excelFileUploadOnChange(fileArr, fileName);
                    } else {
                      ignoreDoctypesList.current = [];
                    }
                  } else {
                    ignoreDoctypesList.current = [];
                  }
                }
              })
              .catch((error) => {
                console.log("error uploadBusinessPartnerLedgerFromBusinessPartner", error?.response);

                const summaryObj = error?.response.data?.uploadSummary || {};
                if (error?.response.data?.uploadSummary !== null) {
                  setShowUploadedSummaryObject(error?.response.data?.uploadSummary);
                  setShowConfigMappedData(error?.response.data?.configMapped);
                  if (currentFileNumber.current === totalNumberOfUploadedFiles.current - 1) {
                    setShowUploadedSummary(true);
                  }

                  setUploadedFileNameBusinessPartner(() => {
                    const fileNames = Object.keys(summaryObj).filter(
                      (k) => k.toLowerCase() !== UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY
                    );
                    return fileNames;
                  });
                }

                currentFileNumber.current = currentFileNumber.current + 1;
                if (fileArr.length > 1) {
                  if (currentFileNumber.current < totalNumberOfUploadedFiles.current) {
                    excelFileUploadOnChange(fileArr, fileName);
                  } else {
                    ignoreDoctypesList.current = [];
                  }
                } else {
                  ignoreDoctypesList.current = [];
                }

                setShowLoadingIconBusinessPartner(false);
                if (error?.response?.data?.message !== undefined) {
                  const dataObj = error.response.data as ErrorResponse;
                  setShowUploadErrorAlert(true);
                  setApiErrorMsg(dataObj.message);
                  apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                } else {
                  setShowUploadErrorAlert(true);
                  setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromBusinessPartner`);
                  apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                }
              });
          } catch (error: any) {
            console.log("error uploadBusinessPartnerLedgerFromBusinessPartner", error?.response);
            setShowLoadingIconBusinessPartner(false);
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}uploadBusinessPartnerLedgerFromBusinessPartner`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        }
      }
    } else {
      setShowUploadErrorAlert(true);
      setApiErrorMsg("Error: All files must have the same type");
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const goBack = () => {
    const summaryEle = document.getElementById("summary_page");
    summaryEle.style.display = "block";
    const detailsEle = document.getElementById("open_details_page");
    detailsEle.style.display = "none";
  };

  // const downloadExcel = () => {
  //   const excelData = Buffer.from(finalWorkbookBase64, "base64");
  //   const fileType = "application/vnd.ms-excel.sheet.macroEnabled.12";
  //   const blob = new Blob([excelData], { type: fileType });
  //   saveAs(blob, actor?.name + "-" + assignBusinessPartnerName + " Reconciled Output.xlsm");
  // };

  const fetchDataFromERPHandler = async (type: string) => {
    try {
      await axios
        .post<FetchDataFromERPResponse>("/api/FetchDataFromERP", {
          ownCompanyId: companyId.current,
          ownStartDate: startDateOwn,
          ownEndDate: endDateOwn,
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyId,
          businessPartnerStartDate: startDateBusinessPartner,
          businessPartnerEndDate: endDateBusinessPartner,
          ignoreDoctypesList: ignoreDoctypesList.current,
          type,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        })
        .then((response) => {
          if (response.data?.nonEmptyDocTypes === null) {
            if (actor.integration && businessPartnerIntegrationWithERP && type === "own") {
              fetchDataFromERPHandler("bp");
            } else {
              compareLedgers();
            }
          } else {
            if (response.data?.unIdentifiedColumns?.length > 0) {
              const columnHeaderArrwithErrorFlag = response.data.unIdentifiedColumns?.map((object) => {
                return { ...object, errorFlag: false, columnHeaderConfigValue: "" };
              });
              setColumnHeaderArr(columnHeaderArrwithErrorFlag);
              setConfigUserType(response.data?.configUserType);
              if (response.data?.configUserType === "Own Ledger") {
                setConfigUserName(actor?.name);
              } else if (response.data?.configUserType === "BusinessPartner Ledger") {
                setConfigUserName(assignBusinessPartnerName);
              }
              setFetchDataFromERP(true);
              setOpenColumnHeaderDialog(true);
            }

            if (response.data?.nonEmptyDocTypes?.length > 0) {
              setDocumentTypesArr(response.data?.nonEmptyDocTypes);

              finaldocumentTypesObj.current = Object.fromEntries(
                response.data?.nonEmptyDocTypes.map((item) => [
                  item.unidentifiedText,
                  {
                    documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                    transactionType: 0,
                  },
                ])
              );

              setConfigUserType(response.data?.configUserType);
              changeDocumentTypeConfig(
                response.data.nonEmptyDocTypes[0].unidentifiedText,
                documentTypeSuggestionMap[response.data.nonEmptyDocTypes[0].suggestedDoctype]
              );
              if (response.data?.configUserType === "Own Ledger") {
                setConfigUserName(actor?.name);
              } else if (response.data?.configUserType === "BusinessPartner Ledger") {
                setConfigUserName(assignBusinessPartnerName);
              }
              setFetchDataFromERP(true);
              setOpenDocumentTypeDialog(true);
            } else {
              setDocumentTypesArr([]);
            }
          }
          setShowUploadSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error FetchDataFromERP", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}FetchDataFromERP`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error FetchDataFromERP", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}FetchDataFromERP`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const compareLedgersCheckEndDate = () => {
    // if (
    //   (moment(endDateOwn).isBefore(moment(new Date()), "day") ||
    //     moment(endDateBusinessPartner).isBefore(moment(new Date()), "day")) &&
    //   !fetchDataFromERP
    // ) {
    //   setErrorPastDate(true);
    // } else {
    setErrorPastDate(false);
    if (actor.integration || businessPartnerIntegrationWithERP) {
      if (actor.integration) {
        fetchDataFromERPHandler("own");
      } else if (businessPartnerIntegrationWithERP) {
        fetchDataFromERPHandler("bp");
      }
    } else {
      compareLedgers();
    }
    // }
  };

  const compareLedgers = async () => {
    setShowReconcileLoadingIcon(true);
    setErrorPastDate(false);
    try {
      await axios
        .post<OwnRecon360Response>("/api/Recon360", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerName: assignBusinessPartnerNameRef.current,
          ownStartDate: startDateOwn,
          ownEndDate: endDateOwn,
          ownClosingBalance: ownClosingbalance,
          businessPartnerStartDate: startDateBusinessPartner,
          businessPartnerEndDate: endDateBusinessPartner,
          businessPartnerClosingBalance: businessPartnerClosingbalance,
          ownCompanyId: companyId.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyId,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        })
        .then(async (responseData) => {
          // getLastReconciliationStatus(businessPartnerSelected);
          try {
            await axios
              .get("/api/GetLastReconciliationStatus", {
                params: {
                  businessPartnerId: businessPartnerSelected,
                  businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
                  ownCompanyId: companyId.current,
                },
              })
              .then(async (responseStatus) => {
                console.log(" response msg", responseStatus);
                // setHasReconciled(response.data.hasReconciled);
                setAmountTolerance(responseStatus.data.amountTolerance);
                setDateOfLastRecon(responseStatus.data?.dateOfLastRecon);
                setDateOfLastSummary(responseStatus.data?.dateOfLastSummary);
                if (responseStatus.data?.dateOfLastSummary) {
                  setShowDownloadGapSummary(true);
                } else {
                  setShowDownloadGapSummary(false);
                }
                if (responseStatus.data.hasReconciled) {
                  setWaitingForOutputFile(true);
                  if (props.subTab === "Ledger") {
                    setIsExpandedRunRecon(false);
                    setShowExpandedInsights(true);
                    setIsExpandedInsights(false);
                    console.log("summaryTypeSelected", summaryTypeSelected);
                    console.log("summaryTypeSelectedRef", summaryTypeSelectedRef.current);

                    if (summaryTypeSelectedRef.current === "legacy") {
                      setShowExpandedSummary(true);
                      setIsExpandedSummary(true);
                      setShowExpandedSignOffStatement(true);
                      setIsExpandedSignOffStatement(true);
                    }
                  }
                }
                // downloadExcelFromApi("fromBpSelect");
                if (responseStatus.data?.dateOfLastRecon) {
                  try {
                    await axios
                      .post("/api/GetSummaryForReconciliation", {
                        ownId: actor.id,
                        businessPartnerId: businessPartnerSelected,
                        configUseCaseType:
                          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
                        ownClosingBalance: ownClosingbalance,
                        businessPartnerClosingBalance: businessPartnerClosingbalance,
                        sanitizationWarning: responseData?.data?.warning,
                        startDate: startDateOwn,
                        endDate: endDateOwn,
                        ownCompanyId: companyId.current,
                        businessPartnerCompanyId: businessPartnerSelectedCompanyId,
                      })
                      .then((response) => {
                        if (response.data.result.reconOutputJsonFilename) {
                          setWaitingForOutputFile(true);
                          axios
                            .post("/api/ReconOutputFile", {
                              reconOutputJsonFilename: response.data.result.reconOutputJsonFilename,
                              businessPartnerId: businessPartnerSelected,
                              ownCompanyId: companyId.current,
                              businessPartnerCompanyId: businessPartnerSelectedCompanyId,
                            })
                            .then((reconOutputFileResponse) => {
                              setFinalWorkbookBase64(reconOutputFileResponse.data.finalWorkbookBase64);
                              setWaitingForOutputFile(false);
                            })
                            .catch(() => {
                              setWaitingForOutputFile(false);
                              setShowUploadErrorAlert(true);
                              setApiErrorMsg("Error in fetching Recon Excel output.");
                              apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                            });
                        }

                        const saveOwnBusinessPartnerMapping: OwnBusinessPartnerMappingUpdateClosingBalances = {
                          ownId: actor.id,
                          businessPartnerId: businessPartnerSelected,
                          ownClosingBalance: ownClosingbalance,
                          businessPartnerClosingBalance: businessPartnerClosingbalance,
                          ownStartDate: startDateOwn,
                          ownEndDate: endDateOwn,
                          businessPartnerStartDate: startDateBusinessPartner,
                          businessPartnerEndDate: endDateBusinessPartner,
                          createdAt: undefined,
                          updatedAt: undefined,
                          closingDifference: response.data.result.closingDifference,
                          ownClosingBalanceDate: new Date(),
                          businessPartnerClosingBalanceDate: new Date(),
                          ownClosingBalanceDateForDashboard: new Date(),
                          businessPartnerClosingBalanceDateForDashboard: new Date(),
                          businessPartnerClosingBalanceForDashboard: businessPartnerClosingbalance,
                          ownClosingBalanceForDashboard: ownClosingbalance,
                          closingDifferenceForDashboard: response.data.result.closingDifference,
                          lastReconOn: new Date(),
                        };

                        axios
                          .post("/api/UpdateClosingBalancesOnReconRun", {
                            ownBusinessPartnerMapping: saveOwnBusinessPartnerMapping,
                          })
                          .then((res) => {
                            console.log("sussess msg", res.data);
                            setNewMappingOwn(true);
                            setDisplayTemplateNameOwn(templateName);
                          })
                          .catch((error) => {
                            console.log("post error", error.response.data);
                          });

                        if (props.subTab === "Ledger") {
                          setRowsDataOverviewInsights(response.data.result?.reconMetrics);
                        }

                        // common function for summary
                        if (
                          summaryTypeSelected === summaryTypeSelectedStatus.legacy &&
                          props.subTab === RECON360_SUB_TAB.LEDGER
                        ) {
                          summaryUIRef.current = response.data.result.summary;

                          const tempLedgerComparisonSummaryData = [
                            {
                              line_item: (
                                <>
                                  <b>Closing balance as per {assignBusinessPartnerName}'s ledger</b>
                                </>
                              ),
                              sub_total: "",
                              total: formatMoney(
                                toDineroObject(summaryUIRef.current.closingBalanceAsPerBusinessPartner)
                              ),
                              num_of_item: "",
                              value_of_item: "",
                            },
                            {
                              line_item: <p>Invoice value gap</p>,
                              sub_total: "",
                              total: formatMoney(toDineroObject(summaryUIRef.current.invoice.valueGap)),
                              num_of_item: <p>{itemNuminvoiceAmountMismatchDispute}</p>,
                              value_of_item: <p>{allinvoiceAmountMismatchDisputeValue}</p>,
                            },
                            {
                              line_item: <p>Payment value gap</p>,
                              sub_total: "",
                              total: formatMoney(toDineroObject(summaryUIRef.current.payment.valueGap)),
                              num_of_item: "4",
                              value_of_item: "12454",
                            },

                            {
                              line_item: <p>Debit/credit note value gap</p>,
                              sub_total: "",
                              total: formatMoney(toDineroObject(summaryUIRef.current.dncn.valueGap)),
                              num_of_item: "4",
                              value_of_item: "",
                            },

                            {
                              line_item: <p>TDS Value Gap</p>,
                              sub_total: "",
                              total: formatMoney(toDineroObject(summaryUIRef.current.tds.valueGap)),
                              num_of_item: "1",
                              value_of_item: "",
                            },
                            {
                              line_item: <p>Adjustment Of Miscellaneous Entries</p>,
                              sub_total: "",
                              total: formatMoney(toDineroObject(summaryUIRef.current.miscellaneous.valueGap)),
                              num_of_item: "1",
                              value_of_item: "",
                            },
                            {
                              line_item: (
                                <>
                                  <b>Reconciled closing balance</b>
                                </>
                              ),
                              sub_total: "",
                              total: formatMoney(toDineroObject(summaryUIRef.current.reconciledClosingBalance)),
                              num_of_item: "",
                              value_of_item: "",
                            },
                            {
                              line_item: (
                                <>
                                  <b>Closing balance as per {actor.name} ledger</b>
                                </>
                              ),
                              total: formatMoney(toDineroObject(summaryUIRef.current.closingBalanceAsPerOwn)),
                            },
                            {
                              line_item: <p>Difference in opening balance</p>,
                              total: formatMoney(toDineroObject(summaryUIRef.current.openingBalance.valueGap)),
                            },
                            {
                              line_item: (
                                <>
                                  <b>Residual difference</b>
                                </>
                              ),

                              // total:'12411392'
                              total: formatMoney(toDineroObject(summaryUIRef.current.residualDifference)),
                            },
                          ];

                          setLedgerComparisonSummaryData(tempLedgerComparisonSummaryData);
                        }

                        // setDisplayDownloadButton("block");
                        setShowReconcileLoadingIcon(false);
                        // setDateOfLastRecon(new Date());
                        if (props.subTab === "Ledger") {
                          setIsExpandedRunRecon(false);
                          setShowExpandedInsights(true);
                          setIsExpandedInsights(false);
                          if (summaryTypeSelected === "legacy") {
                            setShowExpandedSummary(true);
                            setIsExpandedSummary(true);
                            setShowExpandedSignOffStatement(true);
                            setIsExpandedSignOffStatement(true);
                          }
                        }
                      })
                      .catch((error) => {
                        console.log("error GetSummaryForReconciliation", error?.response);
                        setShowReconcileLoadingIcon(false);
                        if (error?.response?.data?.message !== undefined) {
                          const dataObj = error.response.data as ErrorResponse;
                          setShowUploadErrorAlert(true);
                          setApiErrorMsg(dataObj.message);
                          apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                        } else {
                          setShowUploadErrorAlert(true);
                          setApiErrorMsg(`${defaultErrorMsg}GetSummaryForReconciliation`);
                          apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                        }
                      });
                  } catch (error: any) {
                    console.log("error GetSummaryForReconciliation", error?.response);
                    setShowUploadErrorAlert(true);
                    setApiErrorMsg(`${defaultErrorMsg}GetSummaryForReconciliation`);
                    apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                  }
                }
              })
              .catch((error) => {
                console.log("error GetLastReconciliationStatus", error?.response);
                setWaitingForOutputFile(false);
                if (error?.response?.data?.message !== undefined) {
                  const dataObj = error.response.data as ErrorResponse;
                  setShowUploadErrorAlert(true);
                  setApiErrorMsg(dataObj.message);
                  apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                } else {
                  setShowUploadErrorAlert(true);
                  setApiErrorMsg(`${defaultErrorMsg}GetLastReconciliationStatus`);
                  apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                }
              });
          } catch (error: any) {
            console.log("error GetLastReconciliationStatus", error?.response);
            setWaitingForOutputFile(false);
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetLastReconciliationStatus`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
          if (responseData?.data?.warning !== null) {
            setSanitizerWarningMsg(responseData.data.warning);
            setSanitizerWarning(true);
          }
          // setReconMetricEntries(response.data?.result?.reconMetricEntries);
        })
        .catch((error) => {
          console.log("error ownRecon360", error?.response);
          setShowReconcileLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ownRecon360`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ownRecon360", error?.response);
      setShowReconcileLoadingIcon(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ownRecon360`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getOwnDateSyncFromSAP = async (id: any) => {
    try {
      await axios
        .get("/api/reconMetaDataFromSAP", {
          params: {
            getMetaDataFor: "own",
            businessPartnerId: id,
          },
        })
        .then((response) => {
          // console.log(" SyncFromSAPsussess msg", response.data);
          setOwnDateSyncFromSAP(response.data);
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedOwn(true);
          } else {
            setLedgerUploadedOwn(false);
          }
        })
        .catch((error) => {
          console.log("error reconMetaDataFromSAP", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}reconMetaDataFromSAP`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error reconMetaDataFromSAP", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}reconMetaDataFromSAP`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getOwnDataSyncFromTally = async () => {
    try {
      await axios
        .get("/api/ReconMetaDataFromTally", {
          params: {
            getMetaDataFor: "own",
            businessPartnerId: businessPartnerSelected,
            companyId: companyId.current,
          },
        })
        .then((response) => {
          // console.log(" SyncFromSAPsussess msg", response.data);
          setOwnDateSyncFromSAP(response.data);
          // change state name
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedOwn(true);
          } else {
            setLedgerUploadedOwn(false);
          }
        })
        .catch((error) => {
          console.log("error ReconMetaDataFromTally", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ReconMetaDataFromTally`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ReconMetaDataFromTally", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ReconMetaDataFromTally`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getOwnDataSyncFromTallyWhenNoBPintegrationWithERP = async (id: number) => {
    try {
      await axios
        .get("/api/ReconMetaDataFromTally", {
          params: {
            getMetaDataFor: "own",
            businessPartnerId: id,
            companyId: companyId.current,
          },
        })
        .then((response) => {
          // console.log(" SyncFromSAPsussess msg", response.data);
          setOwnDateSyncFromSAP(response.data);
          // change state name
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedOwn(true);
          } else {
            setLedgerUploadedOwn(false);
          }
        })
        .catch((error) => {
          console.log("error ReconMetaDataFromTally", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ReconMetaDataFromTally`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ReconMetaDataFromTally", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ReconMetaDataFromTally`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getOwnDataGetReconMetaData = async (id: any) => {
    try {
      await axios
        .get("/api/ListMetaDataFromUpload", {
          params: {
            getMetaDataFor: "own",
            businessPartnerId: id,
            configUseCaseType:
              props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE
                ? ConfigUseCaseType.paymentAdvice
                : ConfigUseCaseType.recon,
          },
        })
        .then((response) => {
          // console.log(" SyncFromSAPsussess msg", response.data);
          setOwnDateSyncFromSAP(response.data);
          if (
            response.data.lastTimeToSync &&
            response.data.latestEntry &&
            response.data.oldestEntry &&
            response.data.overallEnteries
          ) {
            setLedgerUploadedOwn(true);
          } else {
            setLedgerUploadedOwn(false);
          }
          if (
            !response.data?.lastTimeToSync &&
            !response.data?.latestEntry &&
            !response.data?.oldestEntry &&
            !response.data?.overallEnteries
          ) {
            setUploadedLastModifiedDateOwn(null);
            setUploadedFileNameOwn(null);
          }
        })
        .catch((error) => {
          console.log("error ListMetaDataFromUpload", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListMetaDataFromUpload`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListMetaDataFromUpload", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListMetaDataFromUpload`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getBusinessPartnerDateSyncFromSAP = async (id: any) => {
    try {
      await axios
        .get("/api/reconMetaDataFromSAP", {
          params: {
            getMetaDataFor: "businessPartner",
            businessPartnerId: id,
          },
        })
        .then((response) => {
          // console.log(" SyncFromSAPsussess msg", response.data);
          setbusinessPartnerDateSyncFromSAP(response.data);
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedBP(true);
          } else {
            setLedgerUploadedBP(false);
          }
        })
        .catch((error) => {
          console.log("error reconMetaDataFromSAP", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}reconMetaDataFromSAP`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error reconMetaDataFromSAP", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}reconMetaDataFromSAP`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getBusinessPartnerDataSyncFromTally = async (compId: any) => {
    try {
      await axios
        .get("/api/ReconMetaDataFromTally", {
          params: {
            getMetaDataFor: "businessPartner",
            businessPartnerId: businessPartnerSelected,
            companyId: compId,
          },
        })
        .then((response) => {
          // console.log(" SyncFromSAPsussess msg", response.data);
          setbusinessPartnerDateSyncFromSAP(response.data);
          // change state name
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedBP(true);
          } else {
            setLedgerUploadedBP(false);
          }
        })
        .catch((error) => {
          console.log("error reconMetaDataFromSAP", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}reconMetaDataFromSAP`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error reconMetaDataFromSAP", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}reconMetaDataFromSAP`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getBusinessPartnerDateGetReconMetaData = async (id: any) => {
    try {
      await axios
        .get("/api/ListMetaDataFromUpload", {
          params: {
            getMetaDataFor: "businessPartner",
            businessPartnerId: id,
            configUseCaseType:
              props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE
                ? ConfigUseCaseType.paymentAdvice
                : ConfigUseCaseType.recon,
          },
        })
        .then((response) => {
          console.log(" SyncFromSAPsussess msg", response.data);
          setbusinessPartnerDateSyncFromSAP(response.data);
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedBP(true);
          } else {
            setLedgerUploadedBP(false);
          }
          if (
            !response.data?.lastTimeToSync &&
            !response.data?.latestEntry &&
            !response.data?.oldestEntry &&
            !response.data?.overallEnteries
          ) {
            setUploadedLastModifiedDateBusinessPartner(null);
            setUploadedFileNameBusinessPartner(null);
          }
        })
        .catch((error) => {
          console.log("error ListMetaDataFromUpload", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListMetaDataFromUpload`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListMetaDataFromUpload", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListMetaDataFromUpload`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getBPTallyCompaniesForRecon = async (id: number) => {
    try {
      await axios
        .get("/api/ListBPTallyCompaniesForRecon", {
          params: {
            businessPartnerId: id,
          },
        })
        .then((response) => {
          setListBPTallyCompaniesForRecon(response?.data?.tallyCompanies);
        })
        .catch((error) => {
          console.log("error ListBPTallyCompaniesForRecon", error?.response);
          setListBPTallyCompaniesForRecon([]);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setApiErrorMsg(dataObj.message);
            setShowUploadErrorAlert(true);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListBPTallyCompaniesForRecon`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListBPTallyCompaniesForRecon", error?.response);
      setListBPTallyCompaniesForRecon([]);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListBPTallyCompaniesForRecon`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const businessPartnerSelect = async (e: any, option: any) => {
    if (option !== null) {
      clarity.setTag("RU-BP", `${actor.name}-${option.name}`);
      if (e && e?.persist) e.persist();

      // if (actor.integration === true && actor.actorInfo.accountingSoftware === "Tally") {
      //   getBPTallyCompaniesForRecon(option.id);
      // }
      // actor.integration && actor.actorInfo.accountingSoftware === "SAP"
      //   ? getOwnDateSyncFromSAP(option.id)
      //   : actor.integration && actor.actorInfo.accountingSoftware === "Tally"
      //   ? getOwnDataSyncFromTally(option.id)
      //   : getOwnDataGetReconMetaData(option.id);

      // actor.integration && actor.actorInfo.accountingSoftware === "SAP" ? getOwnDateSyncFromSAP(option.id) : !actor.integration ? getOwnDataGetReconMetaData(option.id) :

      if (actor.integration && actor.actorInfo.accountingSoftware === "SAP") {
        getOwnDateSyncFromSAP(option.id);
      } else if (!actor.integration) {
        getOwnDataGetReconMetaData(option.id);
      }

      setSelectedBusinessPartnerName(option.name);
      setAssignBusinessPartnerName(option.name);
      assignBusinessPartnerNameRef.current = option.name;
      const child = ownBusinessPartners.filter((value: AllOwnBusinessPartnerMapping) => {
        return value.ownBusinessPartnerMapping.businessPartnerId === Number(option.id) ? value : false;
      });
      setBusinessPartnerIntegrationWithERP(child[0].ownBusinessPartnerMapping.integrationWithERP);
      setbusinessPartnerAccountingSoftware(child[0].ownBusinessPartnerMapping.accountingSoftware);
      // child[0].ownBusinessPartnerMapping.integrationWithERP &&
      // child[0].ownBusinessPartnerMapping.accountingSoftware === "SAP"
      //   ? getBusinessPartnerDateSyncFromSAP(option.id)
      //   : child[0].ownBusinessPartnerMapping.integrationWithERP &&
      //     child[0].ownBusinessPartnerMapping.accountingSoftware === "Tally"
      //   ? getBusinessPartnerDataSyncFromTally(option.id, option.companyId)
      //   : getBusinessPartnerDateGetReconMetaData(option.id);

      // child[0].ownBusinessPartnerMapping.integrationWithERP && child[0].ownBusinessPartnerMapping.accountingSoftware === "SAP"
      //   ? getBusinessPartnerDateSyncFromSAP(option.id)
      //   : !child[0].ownBusinessPartnerMapping.integrationWithERP ? getBusinessPartnerDateGetReconMetaData(option.id):

      if (
        child[0].ownBusinessPartnerMapping.integrationWithERP &&
        child[0].ownBusinessPartnerMapping.accountingSoftware === "SAP"
      ) {
        getBusinessPartnerDateSyncFromSAP(option.id);
      } else if (!child[0].ownBusinessPartnerMapping.integrationWithERP) {
        getBusinessPartnerDateGetReconMetaData(option.id);
      }

      if (
        actor.integration === true &&
        actor.actorInfo.accountingSoftware === "Tally" &&
        !child[0].ownBusinessPartnerMapping.integrationWithERP
      ) {
        // getOwnDataSyncFromTally();
        getOwnDataSyncFromTallyWhenNoBPintegrationWithERP(option.id);
        // getBusinessPartnerDateGetReconMetaData(option.id);
      }

      if (
        child[0].ownBusinessPartnerMapping.accountingSoftware === "Tally" &&
        child[0].ownBusinessPartnerMapping.integrationWithERP
      ) {
        getBPTallyCompaniesForRecon(option.id);
      }

      setSelectedBusinessPartner(child[0].ownBusinessPartnerMapping.businessPartnerId);
      businessPartnerSelectedRef.current = child[0].ownBusinessPartnerMapping.businessPartnerId;
      // setSelectedBusinessPartnerCompanyId(child[0].ownBusinessPartnerMapping.companyId);
      getBusinessPartnerLedgerConfigResponse(child[0].ownBusinessPartnerMapping.businessPartnerId);
      setOwnClosingBalance(
        child[0].ownBusinessPartnerMapping.ownClosingBalance === null
          ? parseInt("", 10)
          : child[0].ownBusinessPartnerMapping.ownClosingBalance
      );
      ownClosingbalanceRef.current =
        child[0].ownBusinessPartnerMapping.ownClosingBalance === null
          ? parseInt("", 10)
          : child[0].ownBusinessPartnerMapping.ownClosingBalance;
      setBusinessPartnerClosingBalance(
        child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance === null
          ? parseInt("", 10)
          : child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance
      );
      businessPartnerClosingbalanceRef.current =
        child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance === null
          ? parseInt("", 10)
          : child[0].ownBusinessPartnerMapping.businessPartnerClosingBalance;
      setStartDateOwn(child[0].ownBusinessPartnerMapping.ownStartDate);
      startDateOwnRef.current = child[0].ownBusinessPartnerMapping.ownStartDate;
      setStartDateBusinessPartner(child[0].ownBusinessPartnerMapping.businessPartnerStartDate);
      if (child[0].ownBusinessPartnerMapping.ownEndDate !== null) {
        setEndDateOwn(child[0].ownBusinessPartnerMapping.ownEndDate);
        endDateOwnRef.current = child[0].ownBusinessPartnerMapping.ownEndDate;
      }
      if (child[0].ownBusinessPartnerMapping.businessPartnerEndDate !== null) {
        setEndDateBusinessPartner(child[0].ownBusinessPartnerMapping.businessPartnerEndDate);
      }
      setLedgerComparisonSummaryData(null);
      // setDisplayDownloadButton("none");
      setUploadedFileNameOwn(null);
      setUploadedFileNameBusinessPartner(null);
      setUploadedLastModifiedDateOwn(null);
      setUploadedLastModifiedDateBusinessPartner(null);
      if (props.subTab === "Ledger") {
        setOwnSpecificConfig(child[0]?.ownBusinessPartnerMapping?.ownReconSpecificConfig);
        setDisableOwnSpecificConfigCheckbox(child[0]?.ownBusinessPartnerMapping?.ownReconSpecificConfig);

        // on BP select call getReconMisInsights function to get last recon run results
        // getReconMisInsights(option.id, null);
      } else if (props.subTab === "Payment Advice") {
        setOwnSpecificConfig(child[0]?.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig);
        setDisableOwnSpecificConfigCheckbox(child[0]?.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig);
      }

      getLastReconciliationStatus(option.id);

      //
      setFinalWorkbookBase64("");
      setShowExpandedInsights(false);
      setShowExpandedSummary(false);
      setShowExpandedSignOffStatement(false);
      setIsExpandedInsights(false);
      setIsExpandedSummary(false);
      setIsExpandedSignOffStatement(false);
      setIsExpandedRunRecon(true);
    } else if (option === null) {
      clearAllStates();
    }
  };

  const clearAllStates = () => {
    // inter sap api all fun
    setOwnDateSyncFromSAP({});
    setbusinessPartnerDateSyncFromSAP(null);
    setSelectedBusinessPartnerName("");
    setAssignBusinessPartnerName(null);
    assignBusinessPartnerNameRef.current = null;
    setBusinessPartnerIntegrationWithERP(false);
    setbusinessPartnerAccountingSoftware(null);
    setSelectedBusinessPartner(null);
    businessPartnerSelectedRef.current = null;
    // setSelectedBusinessPartnerCompanyId(null);
    setOwnClosingBalance(parseInt("", 10));
    ownClosingbalanceRef.current = parseInt("", 10);
    setBusinessPartnerClosingBalance(parseInt("", 10));
    businessPartnerClosingbalanceRef.current = parseInt("", 10);
    setStartDateOwn(null);
    startDateOwnRef.current = null;
    setStartDateBusinessPartner(null);
    setEndDateOwn(null);
    endDateOwnRef.current = null;
    setEndDateBusinessPartner(null);
    setLedgerComparisonSummaryData(null);
    // setDisplayDownloadButton("none");
    setUploadedFileNameOwn(null);
    setUploadedFileNameBusinessPartner(null);
    setUploadedLastModifiedDateOwn(null);
    setUploadedLastModifiedDateBusinessPartner(null);
    setNewMappingBusiness(false);
    setNewMappingOwn(false);
    setDisplayTemplateNameBusiness(null);
    setTemplateNameBusiness("");
    setHeaderNameOwn({} as LedgerConfiguration);
    setHeaderNameBusiness({} as LedgerConfiguration);
    setSanitizeColBusiness([]);
    setOwnSpecificConfig(false);
    setDisableOwnSpecificConfigCheckbox(true);
    setListBPTallyCompaniesForRecon([]);

    setFinalWorkbookBase64("");
    setShowExpandedInsights(false);
    setShowExpandedSummary(false);
    setShowExpandedSignOffStatement(false);
    setIsExpandedInsights(false);
    setIsExpandedSummary(false);
    setIsExpandedSignOffStatement(false);
    setIsExpandedRunRecon(true);
    setLedgerUploadedOwn(false);
    setLedgerUploadedBP(false);

    // setHasReconciled(false);
    setAmountTolerance(null);
    setCheckboxDataManualRecon([]);
    setTotalAmount(null);
    setHasManuallyReconciled(true);
  };

  const businessPartnerTallyCompanySelect = (e: any, option: any) => {
    console.log("option", option);

    if (option !== null) {
      e.persist();
      if (actor.integration && actor.actorInfo.accountingSoftware === "Tally") {
        getOwnDataSyncFromTally();
      }
      getBusinessPartnerDataSyncFromTally(option.companyId);
      setSelectedBusinessPartnerCompanyId(option.companyId);
      businessPartnerSelectedCompanyIdRef.current = option.companyId;
      setSelectedBusinessPartnerTallyCompany(option);
      getLastReconciliationStatus(businessPartnerSelected);
    } else if (option === null) {
      setSelectedBusinessPartnerCompanyId(null);
      businessPartnerSelectedCompanyIdRef.current = null;
      setLedgerUploadedOwn(false);
      setLedgerUploadedBP(false);
      // setHasReconciled(false);
      setAmountTolerance(null);
      setCheckboxDataManualRecon([]);
      setTotalAmount(null);
      setHasManuallyReconciled(true);
    }
  };

  const saveRuleApi = async (header: any, template: any, data: any) => {
    console.log("header bef", header);
    setLoadingConfigBP(true);

    Object.keys(header).map((k: any) => {
      if (
        k !== "amount" &&
        k !== "closingBalance" &&
        k !== "nonRelevantDocTypeFlag" &&
        k !== "debitAmountSignReverseFlag" &&
        k !== "creditAmountSignReverseFlag" &&
        k !== "configUsedInMappingFile"
      ) {
        header[k] =
          header[k] !== null
            ? header[k].map((item: any) => {
                if (typeof item === "string") {
                  return item?.trim();
                } else if (typeof item === "object") {
                  // item !== null || item !== undefined
                  return {
                    code: item?.code?.trim(),
                    sign: item.sign,
                    addParticulars: item.addParticulars,
                    excludeParticulars: item.excludeParticulars,
                    group: item.group,
                  };
                }
                return header;
              })
            : header[k];
      } else if (k === "debitAmountSignReverseFlag") {
        header.debitAmountSignReverseFlag = debitAmountSignReverseFlagBP;
      } else if (k === "creditAmountSignReverseFlag") {
        header.creditAmountSignReverseFlag = creditAmountSignReverseFlagBP;
      }
      return header;
    });

    if (!header.hasOwnProperty("debitAmountSignReverseFlag")) {
      header.debitAmountSignReverseFlag = debitAmountSignReverseFlagBP;
    }
    if (!header.hasOwnProperty("creditAmountSignReverseFlag")) {
      header.creditAmountSignReverseFlag = creditAmountSignReverseFlagBP;
    }
    console.log("header", header);

    const ledgerConfig: InsertLedgerConfig = {
      templateName: template,
      config: header,
      actorId: businessPartnerSelected,
      columnsToSanitize: data,
      createdAt: undefined,
      updatedAt: undefined,
    };
    try {
      await axios
        .post("/api/InsertBusinessPartnerLedgerConfig", {
          ledgerConfig,
          specificConfig: false,
          businessPartnerId: businessPartnerSelected,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        })
        .then((response) => {
          console.log("sussess msg", response.data);
          clarity.setTag("BP Config", `Save`);
          setShowUploadSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          setNewMappingBusiness(true);
          setDisplayTemplateNameBusiness(templateNameBp);
          listAllOwnBusinessPartnerMapping();
          // getBusinessPartnerLedgerConfigResponse(businessPartnerSelected, businessPartnerConfigName);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error insertBusinessPartnerLedgerConfig", error?.response);
          setLoadingConfigBP(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}insertBusinessPartnerLedgerConfig`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error insertBusinessPartnerLedgerConfig", error?.response);
      setLoadingConfigBP(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}insertBusinessPartnerLedgerConfig`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const saveRuleApiOwn = async (header: any, template: any, data: any) => {
    setLoadingConfigOwn(true);

    Object.keys(header).map((k: any) => {
      if (
        k !== "amount" &&
        k !== "closingBalance" &&
        k !== "nonRelevantDocTypeFlag" &&
        k !== "debitAmountSignReverseFlag" &&
        k !== "creditAmountSignReverseFlag" &&
        k !== "configUsedInMappingFile"
      ) {
        header[k] =
          header[k] !== null
            ? header[k].map((item: any) => {
                if (typeof item === "string") {
                  return item?.trim();
                } else if (typeof item === "object" && item !== null && item.code !== null && item.sign !== null) {
                  // item !== null || item !== undefined
                  return {
                    code: item?.code?.trim(),
                    sign: item.sign,
                    addParticulars: item.addParticulars,
                    excludeParticulars: item.excludeParticulars,
                    group: item.group,
                  };
                }
                return header;
              })
            : header[k];
      } else if (k === "debitAmountSignReverseFlag") {
        header.debitAmountSignReverseFlag = debitAmountSignReverseFlagOwn;
      } else if (k === "creditAmountSignReverseFlag") {
        header.creditAmountSignReverseFlag = creditAmountSignReverseFlagOwn;
      }
      return header;
    });
    // console.log("template = ", template);

    if (!header.hasOwnProperty("debitAmountSignReverseFlag")) {
      header.debitAmountSignReverseFlag = debitAmountSignReverseFlagOwn;
    }
    if (!header.hasOwnProperty("creditAmountSignReverseFlag")) {
      header.creditAmountSignReverseFlag = creditAmountSignReverseFlagOwn;
    }

    console.log("header saveRuleApiOwn = ", JSON.parse(JSON.stringify(header)));
    // console.log("data = ", data);
    // console.log("sanitizeColOwn = ", sanitizeColOwn);

    const ledgerConfig: InsertLedgerConfig = {
      templateName: template,
      config: header,
      actorId: actor.id,
      columnsToSanitize: data,
      createdAt: undefined,
      updatedAt: undefined,
    };
    try {
      await axios
        .post("/api/InsertOwnLedgerConfig", {
          ledgerConfig,
          specificConfig: ownSpecificConfig,
          businessPartnerId: businessPartnerSelected,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        })
        .then((response) => {
          console.log("sussess msg", response.data);
          clarity.setTag("Own Config", `Save`);
          setShowUploadSuccessAlert(true);
          setApiErrorMsg(response.data.message);
          setNewMappingOwn(true);
          setDisplayTemplateNameOwn(templateName);
          listAllOwnBusinessPartnerMapping();
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error insertOwnLedgerConfig", error?.response);
          setLoadingConfigOwn(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}insertOwnLedgerConfig`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error insertOwnLedgerConfig", error?.response);
      setLoadingConfigOwn(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}insertOwnLedgerConfig`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <CollapseSubRow
          // tdsPresent={tdsPresent}
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          invoiceAmountMismatchOthers={summaryUIRef.current.invoice.amountMismatch}
          paymentAmountMismatchOthers={summaryUIRef.current.payment.amountMismatch}
          debitNoteCreditNoteAmountMismatchOthers={summaryUIRef.current.dncn.amountMismatch}
          invoiceUnaccountedInBusinessPartner={summaryUIRef.current.invoice.invoiceUnaccountedInBusinessPartner}
          paymentNotRecognisedInBusinessPartner={summaryUIRef.current.payment.paymentNotRecognisedInBusinessPartner}
          dncnNotRecognisedInBusinessPartner={summaryUIRef.current.dncn.dncnNotRecognisedInBusinessPartner}
          invoiceUnaccountedInOwn={summaryUIRef.current.invoice.invoiceUnaccountedInOwn}
          extraUnmatchedPaymentInBusinessPartner={summaryUIRef.current.payment.extraUnmatchedPaymentInBusinessPartner}
          extraUnmatchedDNCNInBusinessPartner={summaryUIRef.current.dncn.extraUnmatchedDNCNInBusinessPartner}
          tdsNotRecognisedInBusinessPartner={summaryUIRef.current.tds.tdsNotRecognisedInBusinessPartner}
          extraUnmatchedTDSInBusinessPartner={summaryUIRef.current.tds.extraUnmatchedTDSInBusinessPartner}
          suggestedTDSGap={summaryUIRef.current.tds.suggestedTDSGap}
          miscellaneousOwnSOA={summaryUIRef.current.miscellaneous.inOwnSOA}
          miscellaneousBusinessPartnerSOA={summaryUIRef.current.miscellaneous.inBusinessPartnerSOA}
          openingBalanceOwnSOA={summaryUIRef.current.openingBalance.ownSOA}
          openingBalanceBusinessPartnerSOA={summaryUIRef.current.openingBalance.businessPartnerSOA}
          openDetailsMismatched={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.invoiceAmountMismatch,
              settledColDefnsOpenDetailsMismatchedInvoiceOthers,
              "both",
              "invoice"
            );
          }}
          openDetailsExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.invoiceNotPresentInPartnerLedger,
              settledColDefnsOpenDetailsExtra,
              "own",
              "invoice"
            );
          }}
          openDetailsMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.invoiceNotPresentInOwnLedger,
              settledColDefnsOpenDetailsMissed,
              "businessPartner",
              "invoice"
            );
          }}
          openPaymentAmountMismatch={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.paymentAmountMismatch,
              settledColDefnsOpenPaymentAmountMismatch,
              "both",
              "payments"
            );
          }}
          openPaymentAmountExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.paymentNotPresentInPartnerLedger,
              settledColDefnsOpenPaymentAmountExtra,
              "own",
              "payments"
            );
          }}
          openTDSAmountExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.tdsTcsNotPresentInPartnerLedger,
              settledColDefnsOpenTDSAmountExtra,
              "own",
              "tds"
            );
          }}
          openPaymentAmountMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.paymentNotPresentInOwnLedger,
              settledColDefnsOpenPaymentAmountMissed,
              "businessPartner",
              "payments"
            );
          }}
          openTDSAmountMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.tdsTcsNotPresentInOwnLedger,
              settledColDefnsOpenTDSAmountMissed,
              "businessPartner",
              "tds"
            );
          }}
          openDNCNMismatch={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.debitNoteCreditNoteAmountMismatch,
              settledColDefnsOpenDNCNMismatch,
              "both",
              "dncn"
            );
          }}
          openDNCNExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.dnCnNotPresentInPartnerLedger,
              settledColDefnsOpenDNCNExtra,
              "own",
              "dncn"
            );
          }}
          openDNCNMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.dnCnNotPresentInOwnLedger,
              settledColDefnsOpenDNCNMissed,
              "businessPartner",
              "dncn"
            );
          }}
          openTDSMismatched={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.tdsAmountMismatch,
              settledColDefnsOpenDetailsMismatched,
              "both",
              "tds"
            );
          }}
          openOwnMiscellaneous={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.miscellaneousEntriesInOwnSOA,
              settledColDefnsOpenDNCNExtra,
              "own",
              "miscellaneous"
            );
          }}
          openBusinessPartnerMiscellaneous={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA,
              settledColDefnsOpenDNCNMissed,
              "businessPartner",
              "miscellaneous"
            );
          }}
          assignBusinessPartnerName={assignBusinessPartnerName}
        />
      );
    },
    // eslint-disable-next-line
    [assignBusinessPartnerName]
  );

  const downloadExcelTestFile = async () => {
    try {
      await axios
        .post<any>("/api/downloadTestSheet", {
          businessPartnerId: businessPartnerSelected,
          ownStartDate: startDateOwn,
          ownEndDate: endDateOwn,
          ownClosingBalance: ownClosingbalance,
          businessPartnerStartDate: startDateBusinessPartner,
          businessPartnerEndDate: endDateBusinessPartner,
          businessPartnerClosingBalance: businessPartnerClosingbalance,
        })
        .then((response) => {
          const worksheet1 = XLSX.utils.json_to_sheet(response.data.originalOwnLedgerData);
          const worksheet2 = XLSX.utils.json_to_sheet(response.data.santiziedOwnLedgerData);
          const worksheet3 = XLSX.utils.json_to_sheet(response.data.identifiedOwnLedgerData);
          const worksheet4 = XLSX.utils.json_to_sheet(response.data.originalBusinessPartnerLedgerData);
          const worksheet5 = XLSX.utils.json_to_sheet(response.data.sanitizedBusinessPartnerLedgerData);
          const worksheet6 = XLSX.utils.json_to_sheet(response.data.identifiedBusinessPartnerLedgerData);

          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet1, "Cleaned Own Ledger");
          XLSX.utils.book_append_sheet(workbook, worksheet2, "Sanitized Own Ledger");
          XLSX.utils.book_append_sheet(workbook, worksheet3, "Identified Own Ledger");
          XLSX.utils.book_append_sheet(workbook, worksheet4, "Cleaned BusinessPartner Ledger");
          XLSX.utils.book_append_sheet(workbook, worksheet5, "Sanitized BusinessPartner Ledger");
          XLSX.utils.book_append_sheet(workbook, worksheet6, "Identified BusinessPartner Ledger");

          XLSX.writeFile(workbook, `${actor.name}-${selectedBusinessPartnerName}-LedgerInputTest.xlsx`);
        })
        .catch((error) => {
          console.log("error downloadTestSheet", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}downloadTestSheet`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error downloadTestSheet", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}downloadTestSheet`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const businessPartners: any[] = [];
  ownBusinessPartners?.forEach((value) => {
    businessPartners.push({
      name: value.businessPartnerName,
      id: value.ownBusinessPartnerMapping.businessPartnerId,
    });
  });
  const options = businessPartners?.map((option) => {
    const firstLetter = option.name[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const optionslistOwnTallyCompaniesForRecon = listOwnTallyCompaniesForRecon?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const optionslistBPTallyCompaniesForRecon = listBPTallyCompaniesForRecon?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const tempGrouped = {};
  documentTypesArr.forEach((docType) => {
    const firstLetter = docType.unidentifiedText[0];
    if (tempGrouped[firstLetter]) tempGrouped[firstLetter]?.push(docType);
    else tempGrouped[firstLetter] = [docType];
  });
  groupedDocTypesArr.current = { ...tempGrouped };

  // console.log("businessPartners", businessPartners);

  const openSanitizerModal = (configUser: "OWN" | "BP") => {
    const configurationBtn = document.querySelectorAll(`span#ConfigBtn`) as NodeListOf<HTMLSpanElement>;

    const handleOpenSanitizerModal = () => {
      clickSanitizerButton()
        .then()
        .catch(() => {
          window.alert("Could not open Sanitizer Window.");
        });
    };

    const clickSanitizerButton = () => {
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          const sanitizerBtn = document.querySelector(".sanitizerButton#docId") as HTMLSpanElement;
          if (sanitizerBtn) {
            sanitizerBtn.click();
            resolve();
          } else reject();
        }, 500);
      });
    };

    if (configUser === "OWN") {
      configurationBtn[0]?.click();
      handleOpenSanitizerModal();
    } else if (configUser === "BP") {
      configurationBtn[1]?.click();
      handleOpenSanitizerModal();
    }
  };

  const changeColumnHeaderConfig = (item: any, value: any) => {
    setErrorchangeColumnHeaderConfig(false);
    columnHeaderArr.map((val: any) => {
      if (val.columnHeaderName === item) {
        val.errorFlag = false;
        console.log("errorchangeColumnHeaderConfig", errorchangeColumnHeaderConfig);
        setErrorchangeColumnHeaderConfig(true);
      }
      console.log("columnHeaderArr", columnHeaderArr);

      return columnHeaderArr;
    });

    if (configUserType === "BusinessPartner Ledger") {
      Object.keys(headerNameBp).forEach((k: any) => {
        if (k === item) {
          // const tempColumnHeaderConfigValue =
          columnHeaderArr.map((object: any, index: any) => {
            // return { ...object, columnHeaderConfigValue: value };
            if (object.columnHeaderName === item) {
              columnHeaderArr[index].columnHeaderConfigValue = value;
            }
            return columnHeaderArr;
          });
          // setColumnHeaderArr(tempColumnHeaderConfigValue);
          setColumnHeaderArr(columnHeaderArr);
        }
      });
    } else if (configUserType === "Own Ledger") {
      Object.keys(headerName).forEach((k: any) => {
        if (k === item) {
          // const tempColumnHeaderConfigValue =
          columnHeaderArr.map((object: any, index: any) => {
            if (object.columnHeaderName === item) {
              columnHeaderArr[index].columnHeaderConfigValue = value;
            }
            // return { ...object, columnHeaderConfigValue: value };
            return columnHeaderArr;
          });
          // setColumnHeaderArr(tempColumnHeaderConfigValue);
          setColumnHeaderArr(columnHeaderArr);
        }
      });
    }
  };

  const saveColumnHeaderConfig = async () => {
    setErrorColumnHeaderConfig(false);
    if (configUserType === "BusinessPartner Ledger") {
      Object.keys(headerNameBp).map((k: any) => {
        columnHeaderArr.map((item: any) => {
          if (k === item.columnHeaderName) {
            const temp = headerNameBp[k].length;
            let tempFlag = true;
            if (item?.columnHeaderConfigValue !== undefined && item?.columnHeaderConfigValue !== "") {
              if (headerNameBp[k][0] === "") {
                headerNameBp[k][0] = item?.columnHeaderConfigValue;
                tempFlag = false;
              } else {
                headerNameBp[k].push(item?.columnHeaderConfigValue);
              }
            }
            if (headerNameBp[k][0] === "") {
              item.errorFlag = true;
              console.log(errorColumnHeaderConfig);
              setErrorColumnHeaderConfig(true);
            } else if (temp === headerNameBp[k].length && tempFlag) {
              item.errorFlag = true;
              console.log(errorColumnHeaderConfig);
              setErrorColumnHeaderConfig(true);
            }
          }
          return columnHeaderArr;
        });
        return columnHeaderArr;
      });
      // const checkObjError = columnHeaderArr.map((obj: any) => obj.errorFlag === false);
      // const ErrorCheck = checkObjError.includes(false);
      // if (ErrorCheck === false) {
      // headerNameBp.nonRelevantDocTypeFlag = true;
      await saveRuleApi(headerNameBp, Customized, sanitizeColBusiness);
      if (businessPartnerIntegrationWithERP) {
        compareLedgersCheckEndDate();
      }
      setOpenColumnHeaderDialog(false);
      if (documentTypesArr.length > 0) {
        setOpenDocumentTypeDialog(true);
      } else {
        if (!fetchDataFromERP) {
          excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
        }
      }
      // }
    } else if (configUserType === "Own Ledger") {
      Object.keys(headerName).map((k) => {
        columnHeaderArr.map((item: any) => {
          if (k === item.columnHeaderName) {
            const temp = headerName[k].length;
            let tempFlag = true;
            if (item?.columnHeaderConfigValue !== undefined && item?.columnHeaderConfigValue !== "") {
              if (headerName[k][0] === "") {
                headerName[k][0] = item?.columnHeaderConfigValue;
                tempFlag = false;
              } else {
                headerName[k].push(item?.columnHeaderConfigValue);
              }
            }

            if (headerName[k][0] === "") {
              item.errorFlag = true;
              console.log(errorColumnHeaderConfig);
              setErrorColumnHeaderConfig(true);
            } else if (temp === headerName[k].length && tempFlag) {
              item.errorFlag = true;
              console.log(errorColumnHeaderConfig);
              setErrorColumnHeaderConfig(true);
            }
          }
          return columnHeaderArr;
        });
        return columnHeaderArr;
      });

      // const checkObjError = columnHeaderArr.map((obj: any) => obj.errorFlag === false);
      // const ErrorCheck = checkObjError.includes(false);
      // if (ErrorCheck === false) {
      // headerName.nonRelevantDocTypeFlag = true;
      await saveRuleApiOwn(headerName, Customized, sanitizeColOwn);
      if (actor.integration) {
        compareLedgersCheckEndDate();
      }
      setOpenColumnHeaderDialog(false);
      if (documentTypesArr.length > 0) {
        setOpenDocumentTypeDialog(true);
      } else {
        if (!fetchDataFromERP) {
          excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
        }
      }
    }
  };

  const camelize = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  // const changeDocumentTypeConfig = (item: any, value: any) => {
  //   value = value === "Discount/Rebate" ? "Payment" : value;
  //   const tempObj: any = {};
  //   for (const docType of ignoreDoctypesList.current) {
  //     tempObj[docType as keyof any] = true;
  //   }
  //   Object.keys(finaldocumentTypesObj.current).map((k) => {
  //     if (k === item) {
  //       finaldocumentTypesObj.current[k].documentType = value === "Never ask again" ? "garbageDoctype" : value;
  //     }
  //     tempObj[k] = finaldocumentTypesObj.current[k].documentType;

  //     return finaldocumentTypesObj.current;
  //   });
  //   ignoreDoctypesList.current = Object.keys(finaldocumentTypesObj.current).filter((k) =>
  //     ["Ignore this time", "garbageDoctype", true].includes(tempObj[k])
  //   );
  // };

  const changeDocumentTypeConfig = (item: string, value: string) => {
    value = value === "Discount/Rebate" ? "Payment" : value;
    const oldIgnoreDoctypes: { [k: string]: any } = {};
    for (const docType of ignoreDoctypesList.current) oldIgnoreDoctypes[docType] = true;

    if (item in finaldocumentTypesObj.current)
      finaldocumentTypesObj.current[item].documentType = value === "Never ask again" ? "garbageDoctype" : value;
    Object.keys(finaldocumentTypesObj.current).forEach(
      (key) => (oldIgnoreDoctypes[key] = finaldocumentTypesObj.current[key].documentType)
    );
    // append the ignored document types from first file in the upload call into the second file,
    // so not to ask ignored doc types again in the current multi upload loop.
    ignoreDoctypesList.current.push(
      ...Object.keys(finaldocumentTypesObj.current).filter((k) =>
        ["Ignore this time", true].includes(oldIgnoreDoctypes[k])
      )
    );

    console.log("finaldocumentTypesObj", finaldocumentTypesObj.current);
  };

  // const changeTransactionTypeConfig = (item: any, value: any) => {
  //   Object.keys(finaldocumentTypesObj).map((k) => {
  //     if (k === item) {
  //       finaldocumentTypesObj.current[k].transactionType = value;
  //     }
  //     return finaldocumentTypesObj.current;
  //   });
  // };

  // const changeTransactionTypeConfig = (item: any, value: any) => {
  //   if(item in finaldocumentTypesObj.current) finaldocumentTypesObj.current[item].transactionType = value;
  // };

  const saveDocumentTypeConfig = async () => {
    if (configUserType === "BusinessPartner Ledger") {
      headerNameBp.garbageDoctype = headerNameBp?.garbageDoctype ? headerNameBp.garbageDoctype : [];
      Object.keys(headerNameBp).map((k: any) => {
        Object.keys(finaldocumentTypesObj.current).map((val: any) => {
          let value1 = camelize(finaldocumentTypesObj.current[val].documentType.toLowerCase());
          if (value1 === "dn/Cn") {
            value1 = "debitAndCreditNotes";
          }
          if (k === value1 || k.toLowerCase() === value1) {
            if (headerNameBp[k][0]?.code === "") {
              headerNameBp[k][0] = {
                code: val,
                sign: finaldocumentTypesObj.current[val].transactionType,
                addParticulars: [],
                excludeParticulars: [],
                group: { groupId: "All", groupName: "All" },
              };
            } else {
              headerNameBp[k].push({
                code: val,
                sign: finaldocumentTypesObj.current[val].transactionType,
                addParticulars: [],
                excludeParticulars: [],
                group: { groupId: "All", groupName: "All" },
              });
            }
          }
          return headerNameBp;
        });
        return headerNameBp;
      });
      // headerNameBp.nonRelevantDocTypeFlag =
      //   currentFileNumber.current === totalNumberOfUploadedFiles.current - 1 ? true : false;
      await saveRuleApi(headerNameBp, Customized, sanitizeColBusiness);
      if (businessPartnerIntegrationWithERP) {
        compareLedgersCheckEndDate();
      }
    } else if (configUserType === "Own Ledger") {
      headerName.garbageDoctype = headerName?.garbageDoctype ? headerName.garbageDoctype : [];
      Object.keys(headerName).map((k: any) => {
        Object.keys(finaldocumentTypesObj.current).map((val: any) => {
          let value1 = camelize(finaldocumentTypesObj.current[val].documentType.toLowerCase());
          if (value1 === "dn/Cn") {
            value1 = "debitAndCreditNotes";
          }
          if (k === value1 || k.toLowerCase() === value1) {
            if (headerName[k][0]?.code === "") {
              headerName[k][0] = {
                code: val,
                sign: finaldocumentTypesObj.current[val].transactionType,
                addParticulars: [],
                excludeParticulars: [],
                group: { groupId: "All", groupName: "All" },
              };
            } else {
              headerName[k].push({
                code: val,
                sign: finaldocumentTypesObj.current[val].transactionType,
                addParticulars: [],
                excludeParticulars: [],
                group: { groupId: "All", groupName: "All" },
              });
            }
          }
          return headerName;
        });
        return headerName;
      });
      // headerName.nonRelevantDocTypeFlag =
      //   currentFileNumber.current === totalNumberOfUploadedFiles.current - 1 ? true : false;
      await saveRuleApiOwn(headerName, Customized, sanitizeColOwn);
      if (actor.integration) {
        compareLedgersCheckEndDate();
      }
    }
    setOpenDocumentTypeDialog(false);
    if (!fetchDataFromERP) {
      excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
    }
  };

  const closeUnassignedColumnsHeaderDocTypeModal = () => {
    setShowUploadErrorAlert(true);
    setApiErrorMsg("File not uploaded");
    apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
  };

  const companyNameSelect = (e: any, option: ListOwnTallyCompanies) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      setSelectedOwnCompany(option);
      companyId.current = option.companyId;
      setSelectedBusinessPartner(null);
      businessPartnerSelectedRef.current = null;
      setSelectedBusinessPartnerName("");
      setAssignBusinessPartnerName(null);
      assignBusinessPartnerNameRef.current = null;
      listAllOwnBusinessPartnerMapping();
    } else if (option === null) {
      setOwnBusinessPartners([]);
      setSelectedBusinessPartner(null);
      businessPartnerSelectedRef.current = null;
      setSelectedBusinessPartnerName("");
      setAssignBusinessPartnerName(null);
      assignBusinessPartnerNameRef.current = null;
      companyId.current = null;
    }
  };

  const toggleExpandRunRecon = () => {
    setIsExpandedRunRecon(!isExpandedRunRecon);
  };
  const toggleExpandInsights = () => {
    setIsExpandedInsights(!isExpandedInsights);
  };
  const toggleExpandSummary = () => {
    setIsExpandedSummary(!isExpandedSummary);
  };
  const toggleExpandedSignoffStatement = () => {
    setIsExpandedSignOffStatement(!isExpandedSignOffStatement);
  };

  const handleChangeAddParticular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAddParticular(event.target.checked);
  };

  const handleChangeExcludeParticular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedExcludeParticular(event.target.checked);
  };

  const handleChangeTransactionValue = (event: any) => {
    setTransactionValue(event.target.value);
  };

  const createdocumentTypeTags = (newtemp: any) => {
    const obj = newtemp.map((item: any) => ({
      name: item.name,
      allTransaction: item.codes.filter((val: any) => val.sign === 0),
      debit: item.codes.filter((val: any) => val.sign === 1),
      credit: item.codes.filter((val: any) => val.sign === 2),
    }));
    setDocumentTypesTagsInUnassignedAdvancedModal(obj);
  };

  const delRow = (element: any, ind: number) => {
    element.codes.splice(ind, 1);
    const newData = documentTypeRowInUnassignedAdvancedModal;
    const user = newData.find((d) => d.name === element.name);
    Object.assign(user, element);
    const resultWithCodes = [...newData]?.filter((obj) => obj?.codes?.length > 0);
    setDocumentTypeRowInUnassignedAdvancedModal(resultWithCodes);
    createdocumentTypeTags(resultWithCodes);
  };

  const replaceWord = (word: string) => {
    const replacements = {
      Invoice: "invoice",
      Payment: "payment",
      "Discount/Rebate": "payment",
      "DN/CN": "debitAndCreditNotes",
      Reversal: "reversal",
      "Inter Document": "interDocument",
      TDS: "tds",
      "Ignore this time": "Ignore this time",
      "Never ask again": "Never ask again",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };
  const rverseReplaceWord = (word: string) => {
    const replacements = {
      invoice: "Invoice",
      payment: props.subTab === "Payment Advice" ? "Discount/Rebate" : "Payment",
      debitAndCreditNotes: "DN/CN",
      reversal: "Reversal",
      interDocument: "Inter Document",
      tds: "TDS",
      "Ignore this time": "Ignore this time",
      "Never ask again": "Never ask again",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };

  const addCodesToHeaderNameJSON = (codesArray: any, jsonObject: any) => {
    // Loop through each code object in the codesArray
    codesArray.forEach((codeObj: any) => {
      // Find the keyName in the jsonObject that matches the name of the codeObj
      const keyName = Object.keys(jsonObject).find((key) => key.toLowerCase() === codeObj.keyName.toLowerCase());
      if (keyName) {
        // Push the codeObj into the codes array of the matching keyName object
        jsonObject[keyName] = [...jsonObject[keyName], ...codeObj.codes];
      }
    });
    return jsonObject;
  };

  const resetStatesOfUnassignedDoctypeAdvancedModal = () => {
    setUnassignedDoctypeAdvancedDialog(false);
    setItemForUnassignedDoctypeAdvancedDialog(null);
    setCheckedAddParticular(true);
    setAddParticularText("");
    setAddParticularTextArr([]);
    setCheckedExcludeParticular(false);
    setExcludeParticularTextArr([]);
    setTransactionValue(0);
    setSelectedDocumentTypeInUnassignedModal(null);
    isChangedDocTypeOnScreenSuggestionMap.current = false;
    setDocumentTypeRowInUnassignedAdvancedModal([]);
    setDocumentTypesTagsInUnassignedAdvancedModal([]);
  };

  const extractCodeDetails = (obj: any, targetCode: string) => {
    const result = [];
    // Iterate over the keys in the object
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        // Check if the current key contains an array
        const codeArray = obj[key];

        // Iterate over the items in the array
        for (const item of codeArray) {
          if (item.code === targetCode) {
            // Add the matching code and its details to the result array
            result.push({
              name: key,
              codes: [
                {
                  code: item.code,
                  sign: item.sign,
                  addParticulars: item.addParticulars,
                  excludeParticulars: item.excludeParticulars,
                  group: item.group,
                },
              ],
            });
          }
        }
      }
    }

    return result;
  };

  const createdocumentTypeTagsForMappedTags = (newtemp: any) => {
    const obj = newtemp.map((item: any) => ({
      name: item.name,
      allTransaction: item.codes.filter((val: any) => val.sign === 0),
      debit: item.codes.filter((val: any) => val.sign === 1),
      credit: item.codes.filter((val: any) => val.sign === 2),
    }));
    setDocumentTypesTags(obj);
  };

  const delRowAlreadyMapped = (element: any, ind: number, item: any) => {
    element.codes.splice(ind, 1);
    const newData = documentTypeRow;
    // const user = newData.find((d) => d.name === element.name);
    // Object.assign(user, element);
    const resultWithCodes = [...newData]?.filter((obj) => obj?.codes?.length > 0);
    setDocumentTypeRow(resultWithCodes);
    createdocumentTypeTagsForMappedTags(resultWithCodes);
    if (configUserType === "Own Ledger") {
      const deletedTagFromheaderName = removeObjectByProperties(headerName, item);
      setHeaderNameOwn(deletedTagFromheaderName);
    } else if (configUserType === "BusinessPartner Ledger") {
      const deletedTagFromheaderName = removeObjectByProperties(headerNameBp, item);
      setHeaderNameBusiness(deletedTagFromheaderName);
    }
  };

  const removeObjectByProperties = (obj: any, properties: any) => {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].filter((item: any) => {
          for (const prop in properties) {
            if (item[prop] !== properties[prop]) {
              return true;
            }
          }
          return false;
        });
      }
    }
    return obj;
  };

  const checkIsDuplicateSign = (oldSign: 0 | 1 | 2, newSign: 0 | 1 | 2) => {
    if (oldSign === 0) return true;
    else if (oldSign === newSign) return true;
    else if (oldSign !== newSign && newSign === 0) return true;
    else return false;
  };

  const checkArrayValueEqual = (ComparandArray: string[], ComparatorArray: string[]) => {
    if (ComparandArray.length !== ComparatorArray.length) return false;
    ComparandArray.sort();
    ComparatorArray.sort();
    for (let i = 0; i < ComparandArray.length; i++) {
      if (ComparandArray[i].trim() !== ComparatorArray[i].trim()) return false;
    }
    return true;
  };

  const checkIsDuplicateCode = (newCode: Codes, docTypeCodes: Element[]) => {
    let isDuplicateCode = false;
    let duplicateDocTypeName = "";

    docTypeCodes.forEach((docTypeCode) => {
      docTypeCode.codes.forEach((existingCode) => {
        if (existingCode.code.trim() === newCode.code.trim()) {
          if (
            checkArrayValueEqual(existingCode.addParticulars, newCode.addParticulars) &&
            checkArrayValueEqual(existingCode.excludeParticulars, newCode.excludeParticulars)
          ) {
            const duplicateSign = checkIsDuplicateSign(existingCode.sign as 0 | 1 | 2, newCode.sign as 0 | 1 | 2);

            if (duplicateSign) isDuplicateCode = true;
            if (isDuplicateCode) {
              duplicateDocTypeName = docTypeCode.name[0].toUpperCase() + docTypeCode.name.slice(1);
              return;
            }
          }
        }
      });
    });

    return { isDuplicateCode, duplicateDocTypeName };
  };

  const getUnaccountedAndMisMatchEntries = async (
    reconciliationStatusName: string,
    detailsColDef,
    forTypeForDetailsTable,
    entryTypeForDetailsTable
  ) => {
    setLoader(true);
    try {
      await axios
        .get("/api/GetUnaccountedAndMisMatchEntries", {
          params: {
            businessPartnerId: businessPartnerSelected,
            for: forTypeForDetailsTable,
            entryType: entryTypeForDetailsTable,
            ownCompanyId: companyId.current,
            businessPartnerCompanyId: businessPartnerSelectedCompanyId,
          },
        })
        .then((response) => {
          console.log("responseaaaa", response);

          setDetailsHeading(reconciliationStatusName);
          detailsHeadingName.current = reconciliationStatusName;
          setColumnDefinition(detailsColDef);

          summaryDetailsData.current = response.data.responseData;
          console.log("summaryDetailsData.current", summaryDetailsData.current);
          console.log("reconciliationStatusName", reconciliationStatusName);

          if (reconciliationStatusName === reconciliationStatus.invoiceAmountMismatch) {
            setPaymentGroupBy(["own_invoice_match"]);
            const setExpandedValue: ObjectEle = {};
            summaryDetailsData.current?.forEach((item: any) => {
              if (item.own_invoice_match.length > 0) {
                setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
              }
            });
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus?.invoiceNotPresentInPartnerLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.invoiceNotPresentInOwnLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.paymentAmountMismatch) {
            setPaymentGroupBy(["own_payment_match"]);

            const setExpandedValue: ObjectEle = {};
            summaryDetailsData.current?.forEach((item: any) => {
              if (item.own_payment_match.length > 0) {
                console.log("own_payment_match", setExpandedValue);
                setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
              }
            });
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.paymentNotPresentInPartnerLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.paymentNotPresentInOwnLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
            setPaymentGroupBy(["own_dncn_match"]);
            const setExpandedValue: ObjectEle = {};
            summaryDetailsData.current?.forEach((item: any) => {
              if (item.own_dncn_match.length > 0) {
                setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
              }
            });
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.dnCnNotPresentInOwnLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.tdsAmountMismatch) {
            setPaymentGroupBy(["own_invoice_match"]);
            const setExpandedValue: ObjectEle = {};
            summaryDetailsData.current?.forEach((item: any) => {
              if (item.own_invoice_match.length > 0) {
                setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
              }
              if (item.own_payment_match.length > 0) {
                setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
              }
              if (item.own_dncn_match.length > 0) {
                setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
              }
            });
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          } else if (reconciliationStatusName === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA) {
            const setExpandedValue: ObjectEle = {};
            setExpanded(setExpandedValue);
          }
          setOpendetailsJSON(summaryDetailsData.current);

          const summaryEle = document.getElementById("summary_page");
          const detailsEle = document.getElementById("open_details_page");
          summaryEle.style.display = "none";
          detailsEle.style.display = "block";

          setLoader(false);
        })
        .catch((error) => {
          console.log("error GetUnaccountedAndMisMatchEntries", error?.response);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetUnaccountedAndMisMatchEntries`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetUnaccountedAndMisMatchEntries", error?.response);
      setLoader(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetUnaccountedAndMisMatchEntries`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const onPdfOptionConfirm = () => {
    isPdfOptionConfirm.current = true;
    setOpenConfirmOptionModal(false);
    excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
  };

  const getLastReconciliationStatus = async (bpid) => {
    try {
      await axios
        .get("/api/GetLastReconciliationStatus", {
          params: {
            businessPartnerId: bpid,
            businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
            ownCompanyId: companyId.current,
          },
        })
        .then((response) => {
          console.log(" response msg", response);
          // setHasReconciled(response.data.hasReconciled);
          setAmountTolerance(response.data.amountTolerance);
          setDateOfLastRecon(response.data?.dateOfLastRecon);
          setDateOfLastSummary(response.data?.dateOfLastSummary);
          if (response.data?.dateOfLastSummary) {
            setShowDownloadGapSummary(true);
          } else {
            setShowDownloadGapSummary(false);
          }
          if (response.data.hasReconciled) {
            if (props.subTab === "Ledger") {
              setWaitingForOutputFile(true);
              setIsExpandedRunRecon(false);
              setShowExpandedInsights(true);
              setIsExpandedInsights(false);
              console.log("summaryTypeSelected", summaryTypeSelected);
              console.log("summaryTypeSelectedRef", summaryTypeSelectedRef.current);

              if (summaryTypeSelectedRef.current === "legacy") {
                setShowExpandedSummary(true);
                setIsExpandedSummary(true);
                setShowExpandedSignOffStatement(true);
                setIsExpandedSignOffStatement(true);
              }
            }
          }
          if (response.data?.dateOfLastRecon) {
            downloadExcelFromApi("fromBpSelect");
          }
        })
        .catch((error) => {
          console.log("error GetLastReconciliationStatus", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetLastReconciliationStatus`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetLastReconciliationStatus", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetLastReconciliationStatus`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getManuallyChangedEntries = async () => {
    try {
      await axios
        .get("/api/GetChangesDoneManually", {
          params: {
            businessPartnerId: businessPartnerSelected,
            businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
            ownCompanyId: companyId.current,
          },
        })
        .then((response) => {
          console.log("setCheckboxDataManualRecon response.data.data", response.data.data);

          //  const checkboxDataManualRecondata = response.data.data?.map((item) => ({
          //     ...item,
          //     isSelected: false,
          //   }))

          // console.log("checkboxDataManualRecondata", checkboxDataManualRecondata);

          setCheckboxDataManualRecon(response.data.data);
          setTotalAmount(response.data.total);
          setHasManuallyReconciled(response.data.hasManuallyReconciled);
          setOpenManualRecon(true);
        })
        .catch((error) => {
          console.log("error GetChangesDoneManually", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetChangesDoneManually`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetChangesDoneManually", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetChangesDoneManually`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const downloadExcelFromApi = async (from: string) => {
    console.log("downloadExcelFromApi");

    setShowDownloadResultsLoadingIcon(true);
    try {
      await axios
        .post("/api/GetSummaryForReconciliation", {
          ownId: actor.id,
          businessPartnerId: businessPartnerSelectedRef.current,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
          ownClosingBalance: ownClosingbalanceRef.current,
          businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
          sanitizationWarning: null,
          startDate: startDateOwnRef.current,
          endDate: endDateOwnRef.current,
          ownCompanyId: companyId.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        })
        .then((response) => {
          if (response.data.result.reconOutputJsonFilename) {
            axios
              .post("/api/ReconOutputFile", {
                reconOutputJsonFilename: response.data.result.reconOutputJsonFilename,
                businessPartnerId: businessPartnerSelectedRef.current,
                ownCompanyId: companyId.current,
                businessPartnerCompanyId: businessPartnerSelectedCompanyId,
              })
              .then((reconOutputFileResponse) => {
                setFinalWorkbookBase64(reconOutputFileResponse.data.finalWorkbookBase64);
                setWaitingForOutputFile(false);
                if (from === "fromDownloadBtn") {
                  const excelData = Buffer.from(reconOutputFileResponse.data.finalWorkbookBase64, "base64");
                  const fileType = "application/vnd.ms-excel.sheet.macroEnabled.12";
                  const blob = new Blob([excelData], { type: fileType });
                  saveAs(blob, actor?.name + "-" + assignBusinessPartnerName + " Reconciled Output.xlsm");
                }
                setShowDownloadResultsLoadingIcon(false);
              })
              .catch(() => {
                setShowDownloadResultsLoadingIcon(false);
                setWaitingForOutputFile(false);
                setShowUploadErrorAlert(true);
                setApiErrorMsg("Error in fetching Recon Excel output.");
                apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
              });
          }
          if (from === "fromBpSelect") {
            // common function for summary
            if (
              summaryTypeSelectedRef.current === summaryTypeSelectedStatus.legacy &&
              props.subTab === RECON360_SUB_TAB.LEDGER
            ) {
              summaryUIRef.current = response.data.result.summary;

              const tempLedgerComparisonSummaryData = [
                {
                  line_item: (
                    <>
                      <b>Closing balance as per {assignBusinessPartnerNameRef.current}'s ledger</b>
                    </>
                  ),
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.closingBalanceAsPerBusinessPartner)),
                  num_of_item: "",
                  value_of_item: "",
                },
                {
                  line_item: <p>Invoice value gap</p>,
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.invoice.valueGap)),
                  num_of_item: <p>{itemNuminvoiceAmountMismatchDispute}</p>,
                  value_of_item: <p>{allinvoiceAmountMismatchDisputeValue}</p>,
                },
                {
                  line_item: <p>Payment value gap</p>,
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.payment.valueGap)),
                  num_of_item: "4",
                  value_of_item: "12454",
                },

                {
                  line_item: <p>Debit/credit note value gap</p>,
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.dncn.valueGap)),
                  num_of_item: "4",
                  value_of_item: "",
                },

                {
                  line_item: <p>TDS Value Gap</p>,
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.tds.valueGap)),
                  num_of_item: "1",
                  value_of_item: "",
                },
                {
                  line_item: <p>Adjustment Of Miscellaneous Entries</p>,
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.miscellaneous.valueGap)),
                  num_of_item: "1",
                  value_of_item: "",
                },
                {
                  line_item: (
                    <>
                      <b>Reconciled closing balance</b>
                    </>
                  ),
                  sub_total: "",
                  total: formatMoney(toDineroObject(summaryUIRef.current.reconciledClosingBalance)),
                  num_of_item: "",
                  value_of_item: "",
                },
                {
                  line_item: (
                    <>
                      <b>Closing balance as per {actor.name} ledger</b>
                    </>
                  ),
                  total: formatMoney(toDineroObject(summaryUIRef.current.closingBalanceAsPerOwn)),
                },
                {
                  line_item: <p>Difference in opening balance</p>,
                  total: formatMoney(toDineroObject(summaryUIRef.current.openingBalance.valueGap)),
                },
                {
                  line_item: (
                    <>
                      <b>Residual difference</b>
                    </>
                  ),

                  // total:'12411392'
                  total: formatMoney(toDineroObject(summaryUIRef.current.residualDifference)),
                },
              ];

              setLedgerComparisonSummaryData(tempLedgerComparisonSummaryData);
            }
          }
        })
        .catch((error) => {
          console.log("error GetSummaryForReconciliation", error?.response);
          setShowReconcileLoadingIcon(false);
          setShowDownloadResultsLoadingIcon(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetSummaryForReconciliation`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetSummaryForReconciliation", error?.response);
      setShowDownloadResultsLoadingIcon(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetSummaryForReconciliation`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const uploadGapSummary = async (e) => {
    setShowLoadingIconUploadGapSummary(true);
    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", businessPartnerSelected.toString());
    bodyFormData.append("ownCompanyId", companyId.current);
    bodyFormData.append("businessPartnerCompanyId", businessPartnerSelectedCompanyIdRef.current);
    bodyFormData.append("file", e.target.files[0]);

    try {
      await axios
        .post("/api/uploadReconReport", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response);
          setShowLoadingIconUploadGapSummary(false);
          setDateOfLastSummary(new Date());
          setShowDownloadGapSummary(true);
          // setGenerateGapSummaryBase64()
          setShowUploadSuccessAlert(true);
          setApiErrorMsg(response?.data?.message);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
        })
        .catch((error) => {
          console.log("error uploadReconReport", error?.response);
          setShowLoadingIconUploadGapSummary(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}uploadReconReport`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error uploadReconReport", error?.response);
      setShowLoadingIconUploadGapSummary(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}uploadReconReport`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const downloadGapSummary = async () => {
    setShowLoadingIconDownloadGapSummary(true);
    const payloadDownloadSignOffStatement: any = {
      ownId: actor.id,
      ownClosingBalance: ownClosingbalanceRef.current,
      businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
    };
    // Conditionally add properties if they are not null
    if (businessPartnerSelectedRef.current !== null) {
      payloadDownloadSignOffStatement.businessPartnerId = businessPartnerSelectedRef.current;
    }
    if (companyId.current !== null) {
      payloadDownloadSignOffStatement.ownCompanyId = companyId.current;
    }
    if (businessPartnerSelectedCompanyIdRef.current !== null) {
      payloadDownloadSignOffStatement.businessPartnerCompanyId = businessPartnerSelectedCompanyIdRef.current;
    }
    try {
      await axios
        .post("/api/DownloadSignOffStatement", payloadDownloadSignOffStatement)
        .then((response) => {
          const excelData = Buffer.from(response.data.finalWorkbookBase64, "base64");
          const fileType = "application/vnd.ms-excel.sheet.macroEnabled.12";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, actor?.name + "-" + assignBusinessPartnerName + " Gap Summary.xlsm");
          setShowLoadingIconDownloadGapSummary(false);
        })
        .catch((error) => {
          console.log("error DownloadSignOffStatement", error?.response);
          setShowLoadingIconDownloadGapSummary(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}DownloadSignOffStatement`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error DownloadSignOffStatement", error?.response);
      setShowLoadingIconDownloadGapSummary(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}DownloadSignOffStatement`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const generateReconciliationTextBasedOnDate = () => {
    if (startDateOwn && endDateOwn && startDateOwn !== "Invalid date" && endDateOwn !== "Invalid date") {
      return `Reconciliation to be done from ${moment(startDateOwn)?.format("DD-MM-YYYY")} to ${moment(
        endDateOwn
      )?.format("DD-MM-YYYY")}`;
    } else if (moment(startDateOwn)?.format("DD-MM-YYYY") && startDateOwn !== "Invalid date") {
      return `Reconciliation to be done from ${moment(startDateOwn)?.format("DD-MM-YYYY")}`;
    } else if (moment(endDateOwn)?.format("DD-MM-YYYY") && endDateOwn !== "Invalid date") {
      return `Reconciliation to be done from till ${moment(endDateOwn)?.format("DD-MM-YYYY")}`;
    } else {
      return "";
    }
  };

  const uiLogger = (functionName: string) => {
    try {
      axios
        .post<any>("/api/uiLogger", {
          businessPartnerId: businessPartnerSelectedRef.current,
          functionName,
        })
        .then((response) => {
          console.log("response", response);
        })
        .catch((error) => {
          console.log("error uiLogger", error?.response);
        });
    } catch (error: any) {
      console.log("error uiLogger", error?.response);
    }
  };
  /* eslint-disable react-hooks/rules-of-hooks */

  const listUserEmailTemplates = () => {
    useFetch<ListUserEmailTemplateResponse>("/api/ListUserEmailTemplate", "GET", {
      config: {
        params: {
          templateType: "LR",
        },
      },
      setApiErrorMsg,
      setShowErrorAlert: setShowUploadErrorAlert,
      thenCallBack: (res) => {
        const { emailTemplates } = res.data;
        const foundTemplate = emailTemplates.find((template) => template.templateName === EMAIL_TEMPLATE_NAME);
        setEmailTemplateContent(foundTemplate);
        setTemplateBody(foundTemplate?.emailBody || " ");
      },
    });
  };

  const base64ToFile = (base64str: string, fileName: string) => {
    const excelData = Buffer.from(base64str, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    return new File([blob], fileName);
  };

  const GetReconResultForEmailSend = async (bpId: number, uri: string) => {
    return new Promise((resolve: (v: ReconResultForSendingEmail) => void, reject) => {
      useFetch<ReconResultForSendingEmail>(uri, "GET", {
        config: {
          params: {
            businessPartnerId: bpId,
          },
        },
        setApiErrorMsg,
        setShowErrorAlert: setShowUploadErrorAlert,
        thenCallBack: (res) => {
          const { originalOwnLedger, originalBPLedger } = res.data;
          const { reconDoneStatement, summaryTable, summaryOutput } = res.data;

          const Data: ReconResultForSendingEmail = {};

          if (summaryTable && summaryOutput?.content) {
            Data.summaryTable = summaryTable;
            Data.summaryOutput = summaryOutput;
            Data.reconDoneStatement = reconDoneStatement;

            setReconEmailFiles((files) => ({
              ...files,
              summaryOutput: base64ToFile(summaryOutput.content, summaryOutput.fileName),
            }));
          }

          if (originalOwnLedger) {
            Data.originalOwnLedger = originalOwnLedger;

            setReconEmailFiles((files) => ({
              ...files,
              originalOwnLedger: originalOwnLedger.map((ownL) => base64ToFile(ownL.content, ownL.fileName)),
            }));
          }

          if (originalBPLedger) {
            Data.originalBPLedger = originalBPLedger;

            setReconEmailFiles((files) => ({
              ...files,
              originalBPLedger: originalBPLedger.map((bpL) => base64ToFile(bpL.content, bpL.fileName)),
            }));
          }

          resolve({
            ...Data,
          });
        },
        catchCallBack: (err) => reject(err),
        errorCallback: (err) => reject(err),
      });
    });
  };

  const GetReconResultForSendingEmail = async (bpId: number) => {
    const uriOutput = "/api/FetchReconSummaryFilesFromS3";
    const uriInpOwn = "/api/FetchOwnInputFilesFromS3";
    const uriInpBp = "/api/FetchBusinessPartnerInputFilesFromS3";

    const Data: ReconResultForSendingEmail = {
      originalBPLedger: [],
      originalOwnLedger: [],
      reconDoneStatement: "",
      summaryOutput: { fileName: "", content: "" },
      summaryTable: [],
    };

    const data = await Promise.all([
      GetReconResultForEmailSend(bpId, uriOutput),
      GetReconResultForEmailSend(bpId, uriInpOwn),
      GetReconResultForEmailSend(bpId, uriInpBp),
    ]);

    if (data[0].summaryOutput && data[0].summaryTable) {
      Data.summaryOutput = data[0].summaryOutput;
      Data.summaryTable = data[0].summaryTable;
      Data.reconDoneStatement = data[0].reconDoneStatement;
    }

    if (data[1].originalOwnLedger) Data.originalOwnLedger = data[1].originalOwnLedger;

    if (data[2].originalBPLedger) Data.originalBPLedger = data[2].originalBPLedger;

    if (!data[0].summaryOutput?.content || !data[0].reconDoneStatement) {
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`Can't send Email as disputes are not resolved.`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }

    return Data;
  };
  // ----Recon360ContextValue------

  const Recon360ContextValue: any = {
    businessPartnerSelected,
    businessPartnerSelectedCompanyIdRef,
    companyId,
    setShowUploadErrorAlert,
    setApiErrorMsg,
    checkboxDataManualRecon,
    amountTolerance,
    totalAmount,
    hasManuallyReconciled,
    setOpenManualRecon,
    getManuallyChangedEntries,
    downloadExcelFromApi,
    setShowUploadSuccessAlert,
    showDownloadResultsLoadingIcon,
  };

  // ----Recon360ContextValue------

  return (
    <LoggedInSkeleton
      // topBarButtons={getReconTopBarButtons("Recon360", actor.name, location?.state?.openCollapseObj)}
      topBarButtons={getReconTopBarButtons(props.subTab, actor.name, location?.state?.openCollapseObj, actor)}
    >
      <Recon360Context.Provider value={Recon360ContextValue}>
        <ApiErrorCatch
          showUploadSuccessAlert={showUploadSuccessAlert}
          showUploadErrorAlert={showUploadErrorAlert}
          showUploadWarningAlert={showUploadWarningAlert}
          apiErrorMsg={apiErrorMsg}
        />
        {!openManualRecon ? (
          <>
            <Grid container={true} id="summary_page" sx={{ p: 4, pt: 0 }}>
              <Grid container={true} xs={12}>
                <Grid md={12}>
                  <Card>
                    <CardContent className="business_content">
                      {actor.integration === true && actor.actorInfo.accountingSoftware === "Tally" && (
                        <div className="width_30_per d_block">
                          <div className="center_align_ver_horiz h_40 text_center" style={{ position: "relative" }}>
                            <label>Own Tally Company</label>
                            {!owntallyCompaniesLoaded && (
                              <span style={{ position: "absolute", right: "0px" }}>
                                <i
                                  className="fas fa-circle-notch fa-spin"
                                  style={{ width: "16px", height: "16px", color: "#36a" }}
                                />
                              </span>
                            )}
                          </div>
                          <div className="d_flex center_align_ver_horiz">
                            <Autocomplete
                              size="small"
                              className="dropdown_Tally"
                              onChange={(e, value) => companyNameSelect(e, value as ListOwnTallyCompanies)}
                              id="businessPartner"
                              options={optionslistOwnTallyCompaniesForRecon.sort(
                                (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                              )}
                              value={selectedOwnCompany}
                              // defaultValue={
                              //   location.state
                              //     ? { name: location.state.businessPartnerName, id: location.state.businessPartnerId }
                              //     : null
                              // }
                              sx={{ "&": { minWidth: "200px" } }}
                              // handle when come from dashboard
                              groupBy={(option: any) => option.firstLetter}
                              getOptionLabel={(option: any) => option.companyName}
                              renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                            />
                          </div>
                        </div>
                      )}
                      {actor.integration === true && actor.actorInfo.accountingSoftware === "Tally" ? (
                        <div className="width_30_per d_block ml_60">
                          <div className="center_align_ver_horiz h_40 text_center" style={{ position: "relative" }}>
                            <label>Partner Name</label>
                            {!ownBusinessPartnersLoaded && (
                              <span style={{ position: "absolute", right: "20px" }}>
                                <i
                                  className="fas fa-circle-notch fa-spin"
                                  style={{ width: "16px", height: "16px", color: "#36a" }}
                                />
                              </span>
                            )}
                          </div>
                          <div className="d_flex center_align_ver_horiz" style={{ gap: "8px", position: "relative" }}>
                            <Autocomplete
                              size="small"
                              disabled={companyId.current ? false : true}
                              className="dropdown_Tally"
                              onChange={(name, value) => businessPartnerSelect(name, value)}
                              value={{
                                name: selectedBusinessPartnerName,
                                id: businessPartnerSelected,
                              }}
                              id="businessPartner"
                              options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                              // defaultValue={
                              //   location.state
                              //     ? { name: location.state.businessPartnerName, id: location.state.businessPartnerId }
                              //     : null
                              // }
                              groupBy={(option) => option.firstLetter}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="width_50_per" style={{ position: "relative" }}>
                          <div
                            className="center_align_ver_horiz h_40 text_center"
                            style={{ position: "relative", paddingRight: "16px" }}
                          >
                            <label>Partner Name</label>
                            {!ownBusinessPartnersLoaded && (
                              <span style={{ position: "absolute", right: "-8px" }}>
                                <i
                                  className="fas fa-circle-notch fa-spin"
                                  style={{ width: "16px", height: "16px", color: "#36a" }}
                                />
                              </span>
                            )}
                          </div>
                          <Autocomplete
                            size="small"
                            className="dropdown1"
                            onChange={(evt, value) => businessPartnerSelect(evt, value)}
                            id="businessPartner"
                            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            value={{
                              name: selectedBusinessPartnerName,
                              id: businessPartnerSelected,
                            }}
                            defaultValue={
                              location.state?.businessPartnerId
                                ? {
                                    name: location.state?.businessPartnerName,
                                    id: location.state?.businessPartnerId,
                                  }
                                : null
                            }
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                          />
                        </div>
                      )}
                      {businessPartnerIntegrationWithERP && businessPartnerAccountingSoftware === "Tally" && (
                        <div className="width_25_per d_block">
                          <div className="center_align_ver_horiz h_40 text_center">
                            <label>Partner Tally Company</label>
                          </div>
                          <Autocomplete
                            size="small"
                            disabled={
                              (companyId.current && businessPartnerSelected) ||
                              (businessPartnerIntegrationWithERP && businessPartnerAccountingSoftware === "Tally")
                                ? false
                                : true
                            }
                            className="dropdown_Tally"
                            onChange={(e, value) => businessPartnerTallyCompanySelect(e, value)}
                            id="businessPartner"
                            options={optionslistBPTallyCompaniesForRecon.sort(
                              (a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            value={selectedBusinessPartnerTallyCompany}
                            groupBy={(option: any) => option.firstLetter}
                            getOptionLabel={(option: any) => option.companyName}
                            renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                          />
                        </div>
                      )}
                      {!actor.integration && (
                        <div className="width_25_per">
                          <AddNewBusinessPartner
                            setShowUploadSuccessAlert={setShowUploadSuccessAlert}
                            setShowUploadErrorAlert={setShowUploadErrorAlert}
                            setApiErrorMsg={setApiErrorMsg}
                            listAllOwnBusinessPartnerMapping={listAllOwnBusinessPartnerMapping}
                            from={"Recon360"}
                            className={"theme_btn"}
                          />
                        </div>
                      )}

                      {/* <div>
                  {finalWorkbookBase64 && (
                    <Button variant="contained" onClick={() => downloadExcel()} className="theme_outline_btn">
                      Download Results
                    </Button>
                  )}
                </div> */}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <div onClick={toggleExpandRunRecon} className="vertical_center_align space_between recon_segment">
                  Run Reconciliation
                  {isExpandedRunRecon ? (
                    <ArrowDropUpIcon className="up_down_arrow" />
                  ) : (
                    <ArrowDropDownIcon className="up_down_arrow" />
                  )}
                </div>
              </Grid>
              {isExpandedRunRecon && (
                <>
                  <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="buttons" sx={{ pt: 2 }}>
                    <Grid item={true} xs={12} sm={12} md={6}>
                      <Card>
                        <CardContent>
                          <LedgerUpload
                            name="Own Documents"
                            uploadSectionName={
                              props.subTab === "Payment Advice" ? "Upload Open Items" : "Upload Own Ledger"
                            }
                            saveRules={(header: any, template: any, value: any) =>
                              saveRuleApiOwn(header, template, value)
                            }
                            newMapping={newMappingOwn}
                            newMappingOwn={newMappingOwn}
                            setNewMapping={setNewMappingOwn}
                            templateName={templateName}
                            setTemplateName={setTemplateNameOwn}
                            sanitizeCol={sanitizeColOwn}
                            setSanitizeCol={setSanitizeColOwn}
                            headerName={headerName}
                            setHeaderName={setHeaderNameOwn}
                            displayTemplateName={displayTemplateNameOwn}
                            excelUpload={(e: any) => {
                              totalNumberOfUploadedFiles.current = 0;
                              currentFileNumber.current = 0;
                              excelFileUploadOnChange(e.target.files, "Own Ledger");
                            }}
                            closingBalance={ownClosingbalance}
                            setClosingBalance={setOwnClosingBalance}
                            setClosingBalanceRef={ownClosingbalanceRef}
                            startDate={startDateOwn}
                            setStartDate={setStartDateOwn}
                            endDate={endDateOwn}
                            setEndDate={setEndDateOwn}
                            setEndDateOwn={setEndDateOwn}
                            setStartDateBusinessPartner={setStartDateBusinessPartner}
                            setEndDateBusinessPartner={setEndDateBusinessPartner}
                            showLoadingIcon={showLoadingIconOwn}
                            uploadedLastModifiedDate={uploadedLastModifiedDateOwn}
                            uploadedFileName={uploadedFileNameOwn}
                            rulesArray={rulesArray}
                            openPdfDialog={openOwnPdfDialog}
                            setOpenPdfDialog={setOpenOwnPdfDialog}
                            pdfTableData={ownPdfTableData}
                            setUploadedLastModifiedDate={setUploadedLastModifiedDateOwn}
                            setUploadedFileName={setUploadedFileNameOwn}
                            integration={actor.integration}
                            businessPartnerSelected={businessPartnerSelected}
                            dateSyncFromSAP={ownDateSyncFromSAP}
                            getOwnDateSyncFromSAP={getOwnDateSyncFromSAP}
                            getOwnDataSyncFromTally={getOwnDataSyncFromTally}
                            getOwnDataGetReconMetaData={getOwnDataGetReconMetaData}
                            specificConfig={ownSpecificConfig}
                            setSpecificConfig={setOwnSpecificConfig}
                            disableSpecificConfigCheckbox={disableOwnSpecificConfigCheckbox}
                            configList={configList}
                            setColumnHeaderArr={setColumnHeaderArr}
                            setConfigUserType={setConfigUserType}
                            setConfigUserName={setConfigUserName}
                            setOpenColumnHeaderDialog={setOpenColumnHeaderDialog}
                            setDocumentTypesArr={setDocumentTypesArr}
                            assignBusinessPartnerName={assignBusinessPartnerName}
                            // setFinaldocumentTypesObj={setFinaldocumentTypesObj}
                            finaldocumentTypesObj={finaldocumentTypesObj}
                            setOpenDocumentTypeDialog={setOpenDocumentTypeDialog}
                            setShowUploadedSummary={setShowUploadedSummary}
                            setShowUploadedSummaryObject={setShowUploadedSummaryObject}
                            selectedPdfConverter={selectedPdfConverter}
                            isPdfOptionConfirm={isPdfOptionConfirm}
                            onChangePdfConversionMethod={() =>
                              excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName)
                            }
                            disablePdfTableOptionForMultiplePdf={disablePdfTableOptionForMultiplePdf}
                            selectedBusinessPartnerName={selectedBusinessPartnerName}
                            downloadUploadedPdfName="Own Ledger"
                            pdfConversionLoader={pdfConversionLoader}
                            setPdfConversionLoader={setPdfConversionLoader}
                            debitAmountSignReverseFlag={debitAmountSignReverseFlagOwn}
                            setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlagOwn}
                            creditAmountSignReverseFlag={creditAmountSignReverseFlagOwn}
                            setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlagOwn}
                            subTab={props.subTab}
                            ledgerUploadedOwn={ledgerUploadedOwn}
                            ledgerUploadedBP={ledgerUploadedBP}
                            accountingTerminology={accountingTerminology}
                            getConfigResponse={getOwnLedgerConfigResponse}
                            loadingConfig={loadingConfigOwn}
                            uiLogger={(functionName: string) => uiLogger(functionName)}
                            guidCommonTimestamp={guidCommonTimestamp}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={6}>
                      <Card>
                        <CardContent>
                          <LedgerUpload
                            name="Business Partner Documents"
                            uploadSectionName={
                              props.subTab === "Payment Advice" ? "Upload Payment Advice" : "Upload Partner Ledger"
                            }
                            saveRules={(header: any, template: any, value: any) => {
                              console.log("inside save rules", header, template, value);
                              saveRuleApi(header, template, value);
                            }}
                            newMapping={newMappingBusiness}
                            setNewMapping={setNewMappingBusiness}
                            newMappingOwn={newMappingOwn}
                            templateName={templateNameBp}
                            setTemplateName={setTemplateNameBusiness}
                            sanitizeCol={sanitizeColBusiness}
                            setSanitizeCol={setSanitizeColBusiness}
                            headerName={headerNameBp}
                            setHeaderName={setHeaderNameBusiness}
                            displayTemplateName={displayTemplateNameBusiness}
                            excelUpload={(e: any) => {
                              totalNumberOfUploadedFiles.current = 0;
                              currentFileNumber.current = 0;
                              excelFileUploadOnChange(e.target.files, "BusinessPartner Ledger");
                            }}
                            closingBalance={businessPartnerClosingbalance}
                            setClosingBalance={setBusinessPartnerClosingBalance}
                            setClosingBalanceRef={businessPartnerClosingbalanceRef}
                            startDate={startDateBusinessPartner}
                            setStartDate={setStartDateBusinessPartner}
                            endDate={endDateBusinessPartner}
                            setEndDate={setEndDateBusinessPartner}
                            setEndDateBusinessPartner={setEndDateBusinessPartner}
                            showLoadingIcon={showLoadingIconBusinessPartner}
                            uploadedLastModifiedDate={uploadedLastModifiedDateBusinessPartner}
                            uploadedFileName={uploadedFileNameBusinessPartner}
                            rulesArray={rulesArray}
                            openPdfDialog={openBusinessPartnerPdfDialog}
                            setOpenPdfDialog={setOpenBusinessPartnerPdfDialog}
                            pdfTableData={businessPartnerPdfTableData}
                            setUploadedLastModifiedDate={setUploadedLastModifiedDateBusinessPartner}
                            setUploadedFileName={setUploadedFileNameBusinessPartner}
                            integration={businessPartnerIntegrationWithERP}
                            businessPartnerAccountingSoftware={businessPartnerAccountingSoftware}
                            businessPartnerSelectedCompanyId={businessPartnerSelectedCompanyId}
                            dateSyncFromSAP={businessPartnerDateSyncFromSAP}
                            businessPartnerSelected={businessPartnerSelected}
                            getBusinessPartnerDateSyncFromSAP={getBusinessPartnerDateSyncFromSAP}
                            getBusinessPartnerDataSyncFromTally={getBusinessPartnerDataSyncFromTally}
                            getBusinessPartnerDateGetReconMetaData={getBusinessPartnerDateGetReconMetaData}
                            // specificConfig={businessPartnerspecificConfig}
                            // setSpecificConfig={setBusinessPartnerSpecificConfig}
                            // disableSpecificConfigCheckbox={disableBusinessPartnerSpecificConfigCheckbox}
                            configList={configList}
                            setColumnHeaderArr={setColumnHeaderArr}
                            setConfigUserType={setConfigUserType}
                            setConfigUserName={setConfigUserName}
                            setOpenColumnHeaderDialog={setOpenColumnHeaderDialog}
                            setDocumentTypesArr={setDocumentTypesArr}
                            assignBusinessPartnerName={assignBusinessPartnerName}
                            // setFinaldocumentTypesObj={setFinaldocumentTypesObj}
                            finaldocumentTypesObj={finaldocumentTypesObj}
                            setOpenDocumentTypeDialog={setOpenDocumentTypeDialog}
                            getConfigResponse={getBusinessPartnerLedgerConfigResponse}
                            setShowUploadedSummary={setShowUploadedSummary}
                            setShowUploadedSummaryObject={setShowUploadedSummaryObject}
                            selectedPdfConverter={selectedPdfConverter}
                            isPdfOptionConfirm={isPdfOptionConfirm}
                            onChangePdfConversionMethod={() =>
                              excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName)
                            }
                            disablePdfTableOptionForMultiplePdf={disablePdfTableOptionForMultiplePdf}
                            selectedBusinessPartnerName={selectedBusinessPartnerName}
                            downloadUploadedPdfName="Partner Ledger"
                            pdfConversionLoader={pdfConversionLoader}
                            setPdfConversionLoader={setPdfConversionLoader}
                            debitAmountSignReverseFlag={debitAmountSignReverseFlagBP}
                            setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlagBP}
                            creditAmountSignReverseFlag={creditAmountSignReverseFlagBP}
                            setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlagBP}
                            subTab={props.subTab}
                            ledgerUploadedOwn={ledgerUploadedOwn}
                            ledgerUploadedBP={ledgerUploadedBP}
                            accountingTerminology={accountingTerminology}
                            loadingConfig={loadingConfigBP}
                            uiLogger={(functionName: string) => uiLogger(functionName)}
                            guidCommonTimestamp={guidCommonTimestamp}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid className="center_align_ver_horiz w_100_per">
                    {ledgerUploadedOwn && ledgerUploadedBP && <span>{generateReconciliationTextBasedOnDate()}</span>}
                  </Grid>
                  <Grid container={true} xs={12} sx={{ pt: 2 }}>
                    <Grid md={12} className="center_align_ver_horiz mt_10 mb_35" style={{ position: "relative" }}>
                      <Button
                        disabled={ledgerUploadedOwn && ledgerUploadedBP ? false : true}
                        variant="contained"
                        value="Compare"
                        onClick={() => {
                          if (businessPartnerSelected !== null) {
                            clarity.setTag("Reconciliation Button", `Click`);
                            compareLedgersCheckEndDate();
                          } else {
                            setShowUploadErrorAlert(true);
                            setApiErrorMsg("please select business partner");
                            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                          }
                        }}
                        className="theme_btn"
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                          pointerEvents: "all",
                        }}
                      >
                        <Tooltip
                          title={
                            ledgerUploadedOwn && ledgerUploadedBP
                              ? ""
                              : "Please upload the file on both sides before reconcile"
                          }
                        >
                          <span className="center_align_ver_horiz">
                            {showReconcileLoadingIcon ? (
                              <span className="file-icon">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                            ) : null}
                            {props.subTab === "Payment Advice" ? "Reconcile" : "Reconcile Ledgers"}
                          </span>
                        </Tooltip>
                      </Button>
                      {/* <Button style={{
                    position: "absolute",
                    right: '20%',
                    pointerEvents: "all",
                  }}>
                  download
                </Button> */}
                      <Button
                        disabled={ledgerUploadedOwn && ledgerUploadedBP ? false : true}
                        className="date_filter_btn"
                        style={{
                          position: "absolute",
                          right: 0,
                          pointerEvents: "all",
                        }}
                        onClick={() => {
                          setShowUseDateFilter(true);
                        }}
                      >
                        <Tooltip
                          title={
                            ledgerUploadedOwn && ledgerUploadedBP
                              ? ""
                              : "Please upload the file on both sides before reconcile"
                          }
                        >
                          <span className="fs_14"> Use Date Filter</span>
                        </Tooltip>
                      </Button>
                    </Grid>
                  </Grid>
                  {/* {hasReconciled && !finalWorkbookBase64 && props.subTab === RECON360_SUB_TAB.LEDGER && (
                    <Grid container={true} xs={12} className="center_align">
                      <Button variant="contained" onClick={() => downloadExcelFromApi()} className="theme_btn w_300">
                        {showDownloadResultsLoadingIcon ? (
                          <span className="file-icon">
                            <i className="fas fa-circle-notch fa-spin" />
                          </span>
                        ) : null}
                        Download Results
                      </Button>

                      <span
                        key="edit"
                        style={{
                          color: "rgb(90 90 90)",
                          width: "20px",
                          fontSize: "25px",
                          cursor: "pointer",
                          paddingLeft: "15px",
                          display: "flex",
                          alignItems: "center",
                          // marginLeft:'20px'
                        }}
                        onClick={() => {
                          getManuallyChangedEntries();
                          // setOpenManualRecon(true);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </span>
                    </Grid>
                  )} */}
                </>
              )}
              <Grid container={true} xs={12} className="right_align">
                <DownloadTest
                  show={showBtn}
                  onClickOutside={() => {
                    setShowBtn(false);
                  }}
                  callbackFn={() => {
                    if (businessPartnerSelected !== null) {
                      downloadExcelTestFile();
                    } else {
                      setShowUploadErrorAlert(true);
                      setApiErrorMsg("please select business partner");
                      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
                    }
                  }}
                />
                {/* download test file bottom right side */}
                {/* <Button
            variant="contained"
            value="Download"
            onClick={() => {
              setShowBtn(true);
            }}
            className="download_test_canvas theme_btn"
            style={{
              display: showBtn ? "none" : "block",
              float: "right",
            }}
          >
            Click here for download test file
          </Button> */}
              </Grid>

              {showExpandedSummary && (
                <Grid xs={12}>
                  <div onClick={toggleExpandSummary} className="vertical_center_align space_between recon_segment">
                    <span>
                      Reconciliation Statement :{" "}
                      <span className="as_on_text">
                        {dateOfLastRecon && `As on ${moment(dateOfLastRecon)?.format("DD-MM-YY hh:mmA")}`}
                      </span>
                    </span>
                    {isExpandedSummary ? (
                      <ArrowDropUpIcon className="up_down_arrow" />
                    ) : (
                      <ArrowDropDownIcon className="up_down_arrow" />
                    )}
                  </div>
                </Grid>
              )}
              {pathParam === "Summary" && (
                <>
                  <div className="recon360 account_table_column" id="table-1">
                    {summaryTypeSelected === "legacy" && props.subTab === "Ledger" && isExpandedSummary ? (
                      <div>
                        {waitingForOutputFile && (
                          <div className="mt_25 mb_25 center_align w_100_per">
                            <Button variant="contained" disabled={true} className="theme_btn">
                              <span className="file-icon">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              Preparing Results
                            </Button>
                          </div>
                        )}
                        {!waitingForOutputFile && finalWorkbookBase64 && (
                          <div className="mt_25 mb_25 center_align">
                            <Button
                              variant="contained"
                              onClick={() => {
                                clarity.setTag("Reconciliation Download", `Click`);
                                // downloadExcel()
                                downloadExcelFromApi("fromDownloadBtn");
                              }}
                              className="theme_btn"
                            >
                              {showDownloadResultsLoadingIcon ? (
                                <span className="file-icon">
                                  <i className="fas fa-circle-notch fa-spin" />
                                </span>
                              ) : null}
                              Download Results
                            </Button>
                            <span
                              key="edit"
                              style={{
                                color: "rgb(90 90 90)",
                                width: "20px",
                                fontSize: "25px",
                                cursor: "pointer",
                                paddingLeft: "15px",
                                display: "flex",
                                alignItems: "center",
                                // marginLeft: "20px",
                              }}
                              onClick={() => {
                                getManuallyChangedEntries();
                              }}
                            >
                              <i className="fas fa-edit" />
                            </span>
                            {/* } */}
                          </div>
                        )}
                        {ledgerComparisonSummaryData === null ? (
                          ""
                        ) : (
                          <div>
                            <SummaryTable
                              id="summaryTable"
                              getCellProps={(cellInfo: any) => ({
                                style: {
                                  backgroundColor:
                                    cellInfo.value === "Reconciled"
                                      ? "#82FB7F"
                                      : cellInfo.value === "Mismatched Entry"
                                      ? "#FDE485"
                                      : cellInfo.value === "Missed Entry" ||
                                        cellInfo.value === "Extra Entry with Suggested Match" ||
                                        cellInfo.value === "Extra Entry" ||
                                        cellInfo.value === "Missed Entry with Suggested Match"
                                      ? "#FD8C85"
                                      : "",
                                },
                              })}
                              columns={colDefns}
                              rows={ledgerComparisonSummaryData}
                              sortEnable={false}
                              renderRowSubComponent={renderRowSubComponent}
                              // openDetailsMismatched={openDetailsMismatched}
                              className="center_table"
                            />
                          </div>
                        )}
                        {/* {showSignoffBtn && summaryTypeSelected === "legacy" ? (
                          <div className="column mt_20" style={{ width: "100%", textAlign: "center" }}>
                            <input
                              className="button is-bold is-success theme_btn"
                              type="button"
                              value="Sign off difference"
                              onClick={() => {
                                // gridApi.exportDataAsCsv({ fileName: actor.name + "_Adjustment_" + pathParam });
                              }}
                            />
                          </div>
                        ) : null} */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}

              {/* added false to remove insight section for now */}
              {false && showExpandedInsights && (
                <Grid xs={12}>
                  <div onClick={toggleExpandInsights} className="vertical_center_align space_between recon_segment">
                    Insights/findings
                    {isExpandedInsights ? (
                      <ArrowDropUpIcon className="up_down_arrow" />
                    ) : (
                      <ArrowDropDownIcon className="up_down_arrow" />
                    )}
                  </div>
                </Grid>
              )}
              {/* added false to remove insight section for now */}
              {false && isExpandedInsights && (
                <Grid className="mt_20">
                  <ReconInsightsSection
                    rowsDataOverview={rowsDataOverviewInsights}
                    onClickCircle={(entriesForMetric: string) => {
                      // added dummy , need to call api on this then set data into setReconMetricEntries
                      setReconMetricEntries([]);
                      // ----
                      if (entriesForMetric !== insightsMetricName.MATCH_PERCENTAGE) {
                        // getReconMisInsights(businessPartnerSelected, entriesForMetric);
                        setDetailsHeading(transFormMetricName(entriesForMetric));
                        if (
                          entriesForMetric === insightsMetricName.MISSING_DEBIT_EXTRA_CREDIT ||
                          entriesForMetric === insightsMetricName.INCORRECT_AMOUNT ||
                          entriesForMetric === insightsMetricName.TDS_VALUE_GAP
                        ) {
                          setColumnDefinition(insightsLatestEntriesColDefOwnBP);
                        } else if (
                          entriesForMetric === insightsMetricName.POTENTIAL_MISSED_INVOICES ||
                          entriesForMetric === insightsMetricName.POTENTIAL_MISSED_DEBIT_NOTES
                        ) {
                          setColumnDefinition(insightsLatestEntriesColDefBP);
                        } else if (
                          entriesForMetric === insightsMetricName.DEBIT_NOTE_MISSED_BY_SUPPLIER ||
                          entriesForMetric === insightsMetricName.INVOICE_MISSED_BY_BUYER ||
                          entriesForMetric === insightsMetricName.NET_INVOICE_VALUE_RECONCILED
                        ) {
                          setColumnDefinition(insightsLatestEntriesColDefOwn);
                        }

                        if (entriesForMetric === insightsMetricName?.MISSING_DEBIT_EXTRA_CREDIT) {
                          setOpendetailsJSON(reconMetricEntries?.missingDebitExtraCreditEntries);
                        } else if (entriesForMetric === insightsMetricName?.INVOICE_MISSED_BY_BUYER) {
                          setOpendetailsJSON(reconMetricEntries?.invoiceMissedByBuyerEntries);
                        } else if (entriesForMetric === insightsMetricName?.INCORRECT_AMOUNT) {
                          setOpendetailsJSON(reconMetricEntries?.incorrectAmountEntries);
                        } else if (entriesForMetric === insightsMetricName?.POTENTIAL_MISSED_DEBIT_NOTES) {
                          setOpendetailsJSON(reconMetricEntries?.potentialMissedDebitNoteEntries);
                        } else if (entriesForMetric === insightsMetricName?.TDS_VALUE_GAP) {
                          setOpendetailsJSON(reconMetricEntries?.tdsValueGapEntries);
                        } else if (entriesForMetric === insightsMetricName?.NET_INVOICE_VALUE_RECONCILED) {
                          setOpendetailsJSON(reconMetricEntries?.invoiceValueReconciledEntries);
                        } else if (entriesForMetric === insightsMetricName?.DEBIT_NOTE_MISSED_BY_SUPPLIER) {
                          setOpendetailsJSON(reconMetricEntries?.debitNoteMissedBySupplierEntries);
                        } else if (entriesForMetric === insightsMetricName?.POTENTIAL_MISSED_INVOICES) {
                          setOpendetailsJSON(reconMetricEntries?.potentialMissedInvoicesEntries);
                        }
                        const summaryEle = document.getElementById("summary_page");
                        const detailsEle = document.getElementById("open_details_page");
                        summaryEle.style.display = "none";
                        detailsEle.style.display = "block";
                        const setExpandedValue: ObjectEle = {};
                        setExpanded(setExpandedValue);
                      }
                    }}
                    from={"recon360"}
                  />
                </Grid>
              )}

              {showExpandedSignOffStatement && (
                <Grid xs={12}>
                  <div
                    onClick={toggleExpandedSignoffStatement}
                    className="vertical_center_align space_between recon_segment"
                  >
                    <span>
                      Signoff Statement :{" "}
                      <span className="as_on_text">
                        {dateOfLastSummary && `As on ${moment(dateOfLastSummary)?.format("DD-MM-YY hh:mmA")}`}
                      </span>
                    </span>
                    {isExpandedSignOffStatement ? (
                      <ArrowDropUpIcon className="up_down_arrow" />
                    ) : (
                      <ArrowDropDownIcon className="up_down_arrow" />
                    )}
                  </div>
                </Grid>
              )}
              {isExpandedSignOffStatement && (
                <Grid className="mt_20 center_align_ver_horiz w_100_per" sx={{ gap: 2.5 }}>
                  <Button
                    className="closingblc_input theme_btn"
                    startIcon={
                      !showLoadingIconUploadGapSummary ? (
                        <Upload />
                      ) : (
                        <span className="file-icon">
                          <i className="fas fa-circle-notch fa-spin" />
                        </span>
                      )
                    }
                    sx={{ "& *": { font: "inherit" } }}
                  >
                    <label htmlFor="invoicefile">
                      <input
                        // disabled={props.loadingConfig || props.businessPartnerSelected === null}
                        className="file-input"
                        type="file"
                        name="invoicefile"
                        id="invoicefile"
                        value={""}
                        onChange={uploadGapSummary}
                        required={true}
                        multiple={true}
                        accept=".xlsm"
                        disabled={showLoadingIconDownloadGapSummary}
                      />

                      <span className="file-label">Generate Gap Summary</span>
                    </label>
                  </Button>
                  <div className="download_gap_summary_box">
                    {showDownloadGapSummary && (
                      <Button
                        variant="contained"
                        onClick={() => downloadGapSummary()}
                        className="theme_outline_btn"
                        disabled={showLoadingIconUploadGapSummary}
                        startIcon={
                          !showLoadingIconDownloadGapSummary ? (
                            <Download />
                          ) : (
                            <span className="file-icon">
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          )
                        }
                      >
                        Download Gap Summary
                      </Button>
                    )}
                  </div>
                  <Button
                    className="theme_btn"
                    startIcon={
                      !loadingReconResultEmail ? (
                        <Mail />
                      ) : (
                        <span className="file-icon">
                          <i className="fas fa-circle-notch fa-spin" />
                        </span>
                      )
                    }
                    onClick={async () => {
                      setLoadingReconResultEmail(true);

                      const ReconResultForEmail = await GetReconResultForSendingEmail(businessPartnerSelected);

                      const reconRunDateStr = "<p>" + ReconResultForEmail?.reconDoneStatement + "</p>";
                      const columns = ReconResultForEmail.summaryTable
                        ? Object.keys(ReconResultForEmail.summaryTable[0] || {})
                        : [""];

                      const dom = new Document();
                      const tableEl = dom.createElement("table");
                      const tableBody = dom.createElement("tbody");
                      const tableHead = dom.createElement("thead");

                      columns.forEach((col) => {
                        const th = dom.createElement("th");
                        th.textContent = col;
                        tableHead.append(th);
                      });

                      ReconResultForEmail?.summaryTable.forEach((row) => {
                        const tr = dom.createElement("tr");
                        Object.values(row).forEach((value) => {
                          const td = dom.createElement("td");
                          td.textContent = value;
                          tr.append(td);
                        });
                        tableBody.append(tr);
                      });

                      tableEl.append(tableHead, tableBody);

                      setEmailTemplateContent({
                        ...emailTemplateContent,
                        emailBody: templateBody + "<br>" + reconRunDateStr + "<br>" + tableEl.outerHTML,
                      });

                      setLoadingReconResultEmail(false);

                      if (
                        ReconResultForEmail?.reconDoneStatement &&
                        ReconResultForEmail?.summaryOutput?.content &&
                        ReconResultForEmail?.summaryTable?.length
                      )
                        setOpenSendMailDialog(true);
                    }}
                  >
                    Send Result To Partner
                  </Button>
                </Grid>
              )}

              {waitingForOutputFile && summaryTypeSelected !== "legacy" && (
                //  props.subTab !== "Ledger" &&
                <div className="mt_40 center_align w_100_per">
                  <Button variant="contained" disabled={true} className="theme_btn">
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                    Preparing Results
                  </Button>
                </div>
              )}

              {!waitingForOutputFile &&
                finalWorkbookBase64 &&
                (summaryTypeSelected === "Ledger + Gaps" ||
                  summaryTypeSelected === "Both" ||
                  props.subTab === "Payment Advice") && (
                  <div className="mt_40 center_align w_100_per">
                    <Button
                      variant="contained"
                      onClick={() => downloadExcelFromApi("fromDownloadBtn")}
                      className="theme_btn"
                      disabled={showDownloadResultsLoadingIcon}
                    >
                      {showDownloadResultsLoadingIcon ? (
                        <span className="file-icon">
                          <i className="fas fa-circle-notch fa-spin" />
                        </span>
                      ) : null}
                      Download Results
                    </Button>
                    {props.subTab === RECON360_SUB_TAB.LEDGER && (
                      <span
                        key="edit"
                        style={{
                          color: "rgb(90 90 90)",
                          width: "20px",
                          fontSize: "25px",
                          cursor: "pointer",
                          paddingLeft: "15px",
                          display: "flex",
                          alignItems: "center",
                          // marginLeft:'20px'
                        }}
                        onClick={() => {
                          getManuallyChangedEntries();
                          // setOpenManualRecon(true);
                        }}
                      >
                        <i className="fas fa-edit" />
                      </span>
                    )}
                  </div>
                )}
            </Grid>
            <div id="open_details_page" style={{ display: "none" }}>
              {loader ? (
                <Loader />
              ) : (
                <>
                  <div className="details_page">
                    <div style={{ paddingLeft: "20px", fontSize: "24px", fontWeight: 600 }}>{detailsHeading}</div>

                    <button
                      className="button is-bold is-success theme_btn"
                      type="button"
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        goBack();
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                  <div id="table-1" className="center_align" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
                    {openDetailsJSON === null ? (
                      ""
                    ) : (
                      <DetailsTable
                        getCellProps={(cellInfo: any) => ({
                          style: {
                            backgroundColor:
                              cellInfo.value === "Reconciled"
                                ? "#82FB7F"
                                : cellInfo.value === "Mismatched Entry"
                                ? "#FDE485"
                                : cellInfo.value === "Missed Entry" ||
                                  cellInfo.value === "Extra Entry with Suggested Match" ||
                                  cellInfo.value === "Extra Entry" ||
                                  cellInfo.value === "Missed Entry with Suggested Match"
                                ? "#FD8C85"
                                : "",
                          },
                        })}
                        columns={columnDefinition}
                        rows={openDetailsJSON?.map((value) => {
                          return value;
                        })}
                        sortEnable={false}
                        paymentGroupBy={paymentGroupBy}
                        setExpandedValue={expanded}
                      />
                    )}
                  </div>
                  <div className="details_page">
                    <button
                      className="button is-bold is-success theme_btn"
                      type="button"
                      style={{ fontSize: "12px", width: "100px" }}
                      onClick={() => goBack()}
                    >
                      Go Back
                    </button>
                  </div>
                </>
              )}
              {/* {getMsgDialog(invoiceAmountMismatchDispute, invoiceAmountMismatchDisputeValue)} */}
            </div>
          </>
        ) : (
          <ManualRecon />
        )}
        <Dialog open={errorPastDate} onClose={() => setErrorPastDate(false)}>
          <header className="modal-card-head flex_end top_border_radius" style={{ padding: "8px" }}>
            <button className="delete" aria-label="close" onClick={() => setErrorPastDate(false)} />
          </header>
          <section className="modal-card-body bottom_border_radius">
            <p className="errorPastDate_modal_text">
              Please ensure that you have added correct closing balance after accounting for change in the period of
              reconciliation.
            </p>
            <div className="mt_15 center_align">
              <Button
                variant="contained"
                value="Confirm"
                onClick={() => {
                  if (actor.integration || businessPartnerIntegrationWithERP) {
                    setErrorPastDate(false);
                    if (actor.integration) {
                      fetchDataFromERPHandler("own");
                    } else if (businessPartnerIntegrationWithERP) {
                      fetchDataFromERPHandler("bp");
                    }
                  } else {
                    compareLedgers();
                  }
                }}
                className="theme_btn"
              >
                Confirm
              </Button>
            </div>
          </section>
        </Dialog>
        {/* // -----Dispute UI------ */}
        <Dialog open={openMsgDialog} onClose={() => setOpenMsgDialog(false)}>
          <header className="modal-card-head" style={{ display: "block" }}>
            <p className="modal-card-title">Dispute Resolution</p>
          </header>
          <section className="modal-card-body">
            <div className="container">
              <div className="column" style={{ paddingLeft: "0" }}>
                <div className="field">
                  <div
                    style={{
                      background: "#eae6df",
                      padding: "20px",
                      borderRadius: "18px",
                      fontSize: "24px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 6px",
                      marginBottom: "30px",
                    }}
                  >
                    {showInputMsg && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          marginLeft: "55%",
                        }}
                      >
                        <span>
                          {displayInputMsg.map((msg, i) => (
                            <p
                              style={{
                                background: "rgb(217, 253, 211)",
                                color: "rgb(17, 27, 33)",
                                padding: "10px",
                                borderRadius: "10px",
                                marginTop: "10px",
                              }}
                              key={i + msg}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  fontWeight: "bold",
                                  color: "#06cf9c",
                                  fontSize: "14px",
                                }}
                              >
                                Ramesh Kumar
                              </p>
                              {msg}
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                  fontSize: "12px",
                                  color: "#667781",
                                }}
                              >
                                {getTimeAMPMFormat(new Date())}
                              </p>
                            </p>
                          ))}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="control" style={{ display: "flex" }}>
                    <input
                      className="input"
                      type="text"
                      name="inputMsg"
                      value={inputMsg}
                      onChange={(e) => {
                        displayInputMsgHandler(e);
                      }}
                    />
                    <span style={{ fontSize: "20px", padding: "5px 3px 5px 10px" }}>
                      <i className="fa fa-paperclip" aria-hidden="true" />
                    </span>
                    <button
                      className="button"
                      type="button"
                      onClick={(e) => {
                        handleSend(e);
                      }}
                      style={{
                        marginLeft: "10px",
                        borderRadius: "10px",
                        color: "#fff",
                        background: "#21b47d",
                      }}
                    >
                      <i className="fa fa-paper-plane" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot" style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <button
                className="button"
                style={{ width: "130px", color: "#fff", background: "#21b47d" }}
                onClick={() => {
                  //
                  if (detailsHeadingName.current === reconciliationStatus.invoiceAmountMismatch) {
                    setColumnDefinition(settledColDefnsOpenDetailsMismatchedInvoiceOthers);
                    setPaymentGroupBy(["own_invoice_match"]);
                    const setExpandedValue: ObjectEle = {};
                    summaryDetailsData.current?.forEach((item: any) => {
                      if (item.own_invoice_match.length > 0) {
                        setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
                      }
                    });
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus?.invoiceNotPresentInPartnerLedger) {
                    setColumnDefinition(settledColDefnsOpenDetailsExtra);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInOwnLedger) {
                    setColumnDefinition(settledColDefnsOpenDetailsMissed);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.paymentAmountMismatch) {
                    setColumnDefinition(settledColDefnsOpenPaymentAmountMismatch);
                    setPaymentGroupBy(["own_payment_match"]);
                    const setExpandedValue: ObjectEle = {};
                    summaryDetailsData.current?.forEach((item: any) => {
                      if (item.own_payment_match.length > 0) {
                        console.log("own_payment_match", setExpandedValue);
                        setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
                      }
                    });
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInPartnerLedger) {
                    setColumnDefinition(settledColDefnsOpenPaymentAmountExtra);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInOwnLedger) {
                    setColumnDefinition(settledColDefnsOpenPaymentAmountMissed);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
                    setColumnDefinition(settledColDefnsOpenDNCNMismatch);
                    setPaymentGroupBy(["own_dncn_match"]);
                    const setExpandedValue: ObjectEle = {};
                    summaryDetailsData.current?.forEach((item: any) => {
                      if (item.own_dncn_match.length > 0) {
                        console.log("inoiceme", setExpandedValue);
                        setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
                      }
                    });
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
                    setColumnDefinition(settledColDefnsOpenDNCNExtra);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInOwnLedger) {
                    setColumnDefinition(settledColDefnsOpenDNCNMissed);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
                    setColumnDefinition(settledColDefnsOpenTDSAmountExtra);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
                    setColumnDefinition(settledColDefnsOpenTDSAmountMissed);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.tdsAmountMismatch) {
                    setColumnDefinition(settledColDefnsOpenDetailsMismatched);
                    setPaymentGroupBy(["own_invoice_match"]);
                    const setExpandedValue: ObjectEle = {};
                    summaryDetailsData.current?.forEach((item: any) => {
                      if (item.own_invoice_match.length > 0) {
                        setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
                      }
                      if (item.own_payment_match.length > 0) {
                        setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
                      }
                      if (item.own_dncn_match.length > 0) {
                        setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
                      }
                    });
                    setExpanded(setExpandedValue);
                  } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
                    setColumnDefinition(settledColDefnsOpenDNCNMissed);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  } else if (
                    detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA
                  ) {
                    setColumnDefinition(settledColDefnsOpenDNCNMissed);
                    const setExpandedValue: ObjectEle = {};
                    setExpanded(setExpandedValue);
                  }
                  //

                  setOpenMsgDialog(false);
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="button"
                style={{ width: "130px", color: "#fff", background: "#d6235c" }}
                onClick={() => {
                  msgRaiseDisputeHandler(invoiceAmountMismatchDispute, invoiceAmountMismatchDisputeValue);
                }}
              >
                Raise Dispute
              </button>
            </div>
          </footer>
        </Dialog>
        <Dialog open={openPdfDialogErrorMessage} onClose={() => setOpenPdfDialogErrorMessage(false)}>
          <header className="modal-card-head">
            <p className="modal-card-title">Uploaded PDF File</p>
          </header>
          <section className="modal-card-body">
            <Grid>
              <p className="errorPastDate_modal_text">{pdfDialogErrorMessage}</p>
            </Grid>
          </section>
          <footer className="modal-card-foot" style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button
                className="button is-success"
                onClick={() => {
                  setOpenPdfDialogErrorMessage(false);
                }}
              >
                Close
              </Button>
            </div>
          </footer>
        </Dialog>
        <Dialog open={openColumnHeaderDialog} onClose={() => setOpenColumnHeaderDialog(true)}>
          <header className="modal-card-head">
            <p className="modal-card-title config_dialog_title">
              <p>{!fetchDataFromERP && <small>FileName: {currentFileName.current}</small>}</p>
              Unassigned column headers in {configUserName} ledger{" "}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                // clear ignore doc type list on close click
                ignoreDoctypesList.current = [];

                if (fetchDataFromERP) {
                  setOpenColumnHeaderDialog(false);
                } else {
                  setOpenColumnHeaderDialog(false);
                  closeUnassignedColumnsHeaderDocTypeModal();
                }
              }}
            />
          </header>
          <section className="modal-card-body">
            {columnHeaderArr.map((item: any, index: any) => (
              <div className="space_between mb_25" key={index}>
                <div className="column_header_name">
                  <p>{transFormColumnHeader(item.columnHeaderName)}</p>
                </div>
                <div className="column_header_dropdown">
                  <FormControl
                    fullWidth={true}
                    // error={item?.errorFlag}
                  >
                    <Select
                      size="small"
                      name="columnHeader"
                      variant="outlined"
                      fullWidth={true}
                      defaultValue={item.columnHeaders[item.suggestedHeaderIndex]}
                      displayEmpty={true}
                      onChange={(e) => {
                        changeColumnHeaderConfig(item.columnHeaderName, e.target.value);
                      }}
                    >
                      <MenuItem disabled={true}>Select Column Header</MenuItem>
                      {item.columnHeaders.map((val: any, ind: any) => (
                        <MenuItem key={ind} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>{item?.errorFlag ? "please select column header" : ""}</FormHelperText> */}
                  </FormControl>
                </div>
              </div>
            ))}
          </section>
          <footer className="modal-card-foot flex_end">
            <div>
              <Button
                className="button is-success"
                onClick={() => {
                  saveColumnHeaderConfig();
                }}
              >
                Save
              </Button>
            </div>
          </footer>
        </Dialog>
        <Dialog open={openDocumentTypeDialog} onClose={() => setOpenDocumentTypeDialog(true)}>
          <header className="modal-card-head">
            <p className="modal-card-title config_dialog_title">
              <p>{!fetchDataFromERP && <small>FileName: {currentFileName.current}</small>}</p>
              {documentTypesArr?.length > 25
                ? `Too Many Document Types Found!`
                : `Potentially missed document types in ${configUserName} ledger`}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                // clear ignore doc type list on close click
                ignoreDoctypesList.current = [];

                if (fetchDataFromERP) {
                  setOpenDocumentTypeDialog(false);
                  // on close don't run recon
                  // compareLedgers();
                } else {
                  setOpenDocumentTypeDialog(false);
                  closeUnassignedColumnsHeaderDocTypeModal();
                }
              }}
            />
          </header>
          <section className="modal-card-body" style={{ position: "relative" }}>
            {documentTypesArr?.length > 25 && (
              <div
                style={{
                  position: "sticky",
                  fontSize: 16,
                  top: -20,
                  right: 20,
                  color: "#e42",
                  background: "#fff",
                  zIndex: 10,
                }}
              >
                Apply Sanitizer or Check With CS Team.
              </div>
            )}
            <div className="unassigned_documentType_table_container">
              <table className="unassigned_documentType_table">
                <thead className="fixed-header">
                  <tr>
                    <th>Unassigned DocType</th>
                    <th># Entries</th>
                    {documentTypesArr?.length < 25 && <th>Map document type</th>}
                  </tr>
                </thead>
                <tbody>
                  {documentTypesArr?.length > 25 &&
                    Object.values(groupedDocTypesArr.current).map((valArr) => (
                      <tr key={valArr[0].unidentifiedText}>
                        <td>
                          <div className="documentType_name">
                            <p>
                              {valArr[0].unidentifiedText}
                              <span style={{ color: "#4a4", fontSize: 14 }}>
                                {valArr.length - 1 ? " + " + (valArr.length - 1) + " Others" : ""}
                              </span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="documentType_count">
                            <p>{valArr.map((ele) => ele.occurenceCount).reduce((a, b) => a + b)}</p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {documentTypesArr?.length < 25 &&
                    documentTypesArr.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="documentType_name">
                            <p>{item.unidentifiedText}</p>
                          </div>
                        </td>
                        <td>
                          <div className="documentType_count">
                            <p>{item.occurenceCount}</p>
                          </div>
                        </td>
                        {documentTypesArr?.length < 25 && (
                          <td>
                            <div className="documentType_dropdown_div">
                              <span className="documentType_select">
                                <Select
                                  size="small"
                                  name="documentType"
                                  variant="outlined"
                                  fullWidth={true}
                                  displayEmpty={true}
                                  defaultValue={docTypeOnScreenSuggestionMap[item.suggestedDoctype]}
                                  onChange={(e) => {
                                    isChangedDocTypeOnScreenSuggestionMap.current = true;
                                    setSelectedDocumentTypeInUnassignedModal(e.target.value);
                                    // selectedDocumentTypeInUnassignedModalRef.current = e.target.value;
                                    changeDocumentTypeConfig(item.unidentifiedText, e.target.value);
                                  }}
                                >
                                  <MenuItem disabled={true}>Select Document Type</MenuItem>
                                  {documentTypes.map((val, i) => (
                                    <MenuItem
                                      key={i}
                                      value={val}
                                      style={{
                                        color:
                                          val === "Ignore this time"
                                            ? "orange"
                                            : val === "Never ask again"
                                            ? "red"
                                            : "",
                                      }}
                                    >
                                      {val}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </span>
                              <Tooltip title="Advanced mapping: Map the unassigned doc type using particulars/narration. Also define using transaction type [Debit/Credit (+/-) transactions)]">
                                <span
                                  className="documentType_advanced_settings"
                                  onClick={() => {
                                    if (!isChangedDocTypeOnScreenSuggestionMap.current) {
                                      setSelectedDocumentTypeInUnassignedModal(
                                        docTypeOnScreenSuggestionMap[item.suggestedDoctype]
                                      );
                                    }
                                    setItemForUnassignedDoctypeAdvancedDialog(item);
                                    setUnassignedDoctypeAdvancedDialog(true);
                                    setDuplicateTagName("");

                                    if (configUserType === "Own Ledger") {
                                      const extractedDetails = extractCodeDetails(headerName, item.unidentifiedText);
                                      createdocumentTypeTagsForMappedTags(extractedDetails);
                                      setDocumentTypeRow(extractedDetails);
                                    } else if (configUserType === "BusinessPartner Ledger") {
                                      const extractedDetails = extractCodeDetails(headerNameBp, item.unidentifiedText);
                                      createdocumentTypeTagsForMappedTags(extractedDetails);
                                      setDocumentTypeRow(extractedDetails);
                                    }
                                  }}
                                >
                                  <SettingsIcon />
                                </span>
                              </Tooltip>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          <footer className="modal-card-foot flex_end">
            <span className="mr_20">{sanitizerModalOpened ? "Sanitizer Updated, Please Reupload Files" : ""}</span>
            <div>
              <Button
                className="theme_btn"
                // disabled={documentTypesArr?.length > 25}
                onClick={() => {
                  if (documentTypesArr?.length < 25) saveDocumentTypeConfig();
                  else {
                    setSanitizerModalOpened(true);
                    openSanitizerModal(configUserType === "Own Ledger" ? "OWN" : "BP");
                  }
                }}
                startIcon={documentTypesArr?.length > 25 ? <SettingsIcon /> : <></>}
              >
                {documentTypesArr?.length < 25 ? `Save and proceed` : `Sanitizer`}
              </Button>
            </div>
          </footer>
        </Dialog>
        <Dialog open={openUnassignedDoctypeAdvancedDialog} onClose={() => setUnassignedDoctypeAdvancedDialog(true)}>
          <header className="modal-card-head">
            <p className="fs_16 fw_900">
              Define Unassigned Doc type using Particulars/Narration:{" "}
              <span className="err_text">{itemForUnassignedDoctypeAdvancedDialog?.occurenceCount} Entries missing</span>
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                resetStatesOfUnassignedDoctypeAdvancedModal();
              }}
            />
          </header>
          <section className="modal-card-body">
            <div>
              {duplicateTagName ? (
                <div className="mb_15 error_text_config">
                  This tag is already mapped in{" "}
                  {duplicateTagName === "Payment"
                    ? props.subTab === "Payment Advice"
                      ? "Discount/Rebate"
                      : "Payment"
                    : duplicateTagName}{" "}
                </div>
              ) : (
                ""
              )}
              <div>
                {/* show here already added tags and also tags after Add more */}
                {/* already mapped tags from config */}
                {documentTypeRow.map((element, docTypeIndex) => {
                  return (
                    <Grid key={`documentTypeRowAlreadyMapped_${docTypeIndex}`}>
                      <div className="mb_15 mt_10 unassignedAdvanceDocTypeName">{rverseReplaceWord(element?.name)}</div>
                      <div>
                        {element.codes.length > 0 &&
                        element.codes[0].code !== "" &&
                        documentTypesTags[docTypeIndex].allTransaction[0] !== "" &&
                        documentTypesTags[docTypeIndex].allTransaction.length > 0 ? (
                          <div className="config_tags_div_docType ">
                            <span className="fw_600">All Transaction : </span>
                            {documentTypesTags[docTypeIndex].allTransaction.map((item: any, itemInd: number) => {
                              return (
                                <>
                                  {item?.addParticulars &&
                                  item?.addParticulars.length > 0 &&
                                  item?.excludeParticulars &&
                                  item?.excludeParticulars.length > 0 ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.addParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                +{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "red" }} key={particularInd}>
                                                -{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            delRowAlreadyMapped(element, itemInd, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : item?.addParticulars &&
                                    item?.addParticulars.length > 0 &&
                                    (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.addParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                +{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            delRowAlreadyMapped(element, itemInd, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : item?.excludeParticulars &&
                                    item?.excludeParticulars.length > 0 &&
                                    (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "red" }} key={particularInd}>
                                                -{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            delRowAlreadyMapped(element, itemInd, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            delRowAlreadyMapped(element, itemInd, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : null}

                        {element.codes.length > 0 &&
                        element.codes[0].code !== "" &&
                        documentTypesTags[docTypeIndex].debit[0] !== "" &&
                        documentTypesTags[docTypeIndex].debit.length > 0 ? (
                          <div className="config_tags_div_docType ">
                            <span className="fw_600">Debit Transaction: </span>
                            {documentTypesTags[docTypeIndex].debit.map((item: any, itemInd: number) => {
                              return (
                                <>
                                  {item?.addParticulars &&
                                  item?.addParticulars.length > 0 &&
                                  item?.excludeParticulars &&
                                  item?.excludeParticulars.length > 0 ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.addParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                +{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "red" }} key={particularInd}>
                                                -{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            const indexForDelete =
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0
                                                ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                                : itemInd;
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : item?.addParticulars &&
                                    item?.addParticulars.length > 0 &&
                                    (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.addParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                +{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            const indexForDelete =
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0
                                                ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                                : itemInd;
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : item?.excludeParticulars &&
                                    item?.excludeParticulars.length > 0 &&
                                    (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "red" }} key={particularInd}>
                                                -{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            const indexForDelete =
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0
                                                ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                                : itemInd;
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            const indexForDelete =
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0
                                                ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                                : itemInd;
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : null}

                        {element.codes.length > 0 &&
                        element.codes[0].code !== "" &&
                        documentTypesTags[docTypeIndex].credit[0] !== "" &&
                        documentTypesTags[docTypeIndex].credit.length > 0 ? (
                          <div className="config_tags_div_docType ">
                            <span className="fw_600">Credit Transaction: </span>
                            {documentTypesTags[docTypeIndex].credit.map((item: any, itemInd: number) => {
                              return (
                                <>
                                  {item?.addParticulars &&
                                  item?.addParticulars.length > 0 &&
                                  item?.excludeParticulars &&
                                  item?.excludeParticulars.length > 0 ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.addParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                +{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "red" }} key={particularInd}>
                                                -{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            let indexForDelete;
                                            if (
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                              documentTypesTags[docTypeIndex].debit.length > 0
                                            ) {
                                              indexForDelete =
                                                itemInd +
                                                documentTypesTags[docTypeIndex].allTransaction.length +
                                                documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                              indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                              indexForDelete =
                                                itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                            } else {
                                              indexForDelete = itemInd;
                                            }
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : item?.addParticulars &&
                                    item?.addParticulars.length > 0 &&
                                    (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.addParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                +{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            let indexForDelete;
                                            if (
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                              documentTypesTags[docTypeIndex].debit.length > 0
                                            ) {
                                              indexForDelete =
                                                itemInd +
                                                documentTypesTags[docTypeIndex].allTransaction.length +
                                                documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                              indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                              indexForDelete =
                                                itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                            } else {
                                              indexForDelete = itemInd;
                                            }
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : item?.excludeParticulars &&
                                    item?.excludeParticulars.length > 0 &&
                                    (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                          return (
                                            <>
                                              <span style={{ color: "red" }} key={particularInd}>
                                                -{particular}
                                              </span>
                                            </>
                                          );
                                        })}
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            let indexForDelete;
                                            if (
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                              documentTypesTags[docTypeIndex].debit.length > 0
                                            ) {
                                              indexForDelete =
                                                itemInd +
                                                documentTypesTags[docTypeIndex].allTransaction.length +
                                                documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                              indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                              indexForDelete =
                                                itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                            } else {
                                              indexForDelete = itemInd;
                                            }
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button className="config_tags" key={itemInd}>
                                        <span>{item.code}</span>
                                        <button
                                          className="delete config_delete"
                                          aria-label="close"
                                          onClick={() => {
                                            let indexForDelete;
                                            if (
                                              documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                              documentTypesTags[docTypeIndex].debit.length > 0
                                            ) {
                                              indexForDelete =
                                                itemInd +
                                                documentTypesTags[docTypeIndex].allTransaction.length +
                                                documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                              indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                            } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                              indexForDelete =
                                                itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                            } else {
                                              indexForDelete = itemInd;
                                            }
                                            delRowAlreadyMapped(element, indexForDelete, item);
                                          }}
                                        />
                                      </button>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  );
                })}
                {documentTypeRowInUnassignedAdvancedModal.map((element, docTypeIndex) => {
                  return (
                    <div key={`documentTypeRowInUnassignedAdvancedModal_${docTypeIndex}`}>
                      <div className="mb_15 mt_10 unassignedAdvanceDocTypeName">{element?.name}</div>
                      <div>
                        {element.codes.length > 0 &&
                        element.codes[0].code !== "" &&
                        documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction[0] !== "" &&
                        documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction.length > 0 ? (
                          <div className="config_tags_div_docType ">
                            <span className="fw_600">All Transaction : </span>
                            {documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction.map(
                              (item: any, itemInd: number) => {
                                return (
                                  <>
                                    {item?.addParticulars &&
                                    item?.addParticulars.length > 0 &&
                                    item?.excludeParticulars &&
                                    item?.excludeParticulars.length > 0 ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.addParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                  +{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "red" }} key={particularInd}>
                                                  -{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              delRow(element, itemInd);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : item?.addParticulars &&
                                      item?.addParticulars.length > 0 &&
                                      (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.addParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                  +{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              delRow(element, itemInd);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : item?.excludeParticulars &&
                                      item?.excludeParticulars.length > 0 &&
                                      (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "red" }} key={particularInd}>
                                                  -{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              delRow(element, itemInd);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              delRow(element, itemInd);
                                            }}
                                          />
                                        </button>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        ) : null}

                        {element.codes.length > 0 &&
                        element.codes[0].code !== "" &&
                        documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit[0] !== "" &&
                        documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length > 0 ? (
                          <div className="config_tags_div_docType ">
                            <span className="fw_600">Debit Transaction: </span>
                            {documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.map(
                              (item: any, itemInd: number) => {
                                return (
                                  <>
                                    {item?.addParticulars &&
                                    item?.addParticulars.length > 0 &&
                                    item?.excludeParticulars &&
                                    item?.excludeParticulars.length > 0 ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.addParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                  +{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "red" }} key={particularInd}>
                                                  -{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              const indexForDelete =
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0
                                                  ? itemInd +
                                                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                      .allTransaction.length
                                                  : itemInd;
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : item?.addParticulars &&
                                      item?.addParticulars.length > 0 &&
                                      (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.addParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                  +{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              const indexForDelete =
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0
                                                  ? itemInd +
                                                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                      .allTransaction.length
                                                  : itemInd;
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : item?.excludeParticulars &&
                                      item?.excludeParticulars.length > 0 &&
                                      (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "red" }} key={particularInd}>
                                                  -{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              const indexForDelete =
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0
                                                  ? itemInd +
                                                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                      .allTransaction.length
                                                  : itemInd;
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              const indexForDelete =
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0
                                                  ? itemInd +
                                                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                      .allTransaction.length
                                                  : itemInd;
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        ) : null}

                        {element.codes.length > 0 &&
                        element.codes[0].code !== "" &&
                        documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].credit[0] !== "" &&
                        documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].credit.length > 0 ? (
                          <div className="config_tags_div_docType ">
                            <span className="fw_600">Credit Transaction: </span>
                            {documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].credit.map(
                              (item: any, itemInd: number) => {
                                return (
                                  <>
                                    {item?.addParticulars &&
                                    item?.addParticulars.length > 0 &&
                                    item?.excludeParticulars &&
                                    item?.excludeParticulars.length > 0 ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.addParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                  +{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "red" }} key={particularInd}>
                                                  -{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              let indexForDelete;
                                              if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0 &&
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length >
                                                  0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length;
                                              } else {
                                                indexForDelete = itemInd;
                                              }
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : item?.addParticulars &&
                                      item?.addParticulars.length > 0 &&
                                      (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.addParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "#00c1ff" }} key={particularInd}>
                                                  +{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              let indexForDelete;
                                              if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0 &&
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length >
                                                  0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length;
                                              } else {
                                                indexForDelete = itemInd;
                                              }
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : item?.excludeParticulars &&
                                      item?.excludeParticulars.length > 0 &&
                                      (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                            return (
                                              <>
                                                <span style={{ color: "red" }} key={particularInd}>
                                                  -{particular}
                                                </span>
                                              </>
                                            );
                                          })}
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              let indexForDelete;
                                              if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0 &&
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length >
                                                  0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length;
                                              } else {
                                                indexForDelete = itemInd;
                                              }
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button className="config_tags" key={itemInd}>
                                          <span>{item.code}</span>
                                          <button
                                            className="delete config_delete"
                                            aria-label="close"
                                            onClick={() => {
                                              let indexForDelete;
                                              if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length > 0 &&
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length >
                                                  0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                              } else if (
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit
                                                  .length === 0
                                              ) {
                                                indexForDelete =
                                                  itemInd +
                                                  documentTypesTagsInUnassignedAdvancedModal[docTypeIndex]
                                                    .allTransaction.length;
                                              } else {
                                                indexForDelete = itemInd;
                                              }
                                              delRow(element, indexForDelete);
                                            }}
                                          />
                                        </button>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              <hr style={{ height: "1px", backgroundColor: "gray" }} />
              <div className="vertical_center_align gap_30 mt_20">
                <span>
                  <Chip label={itemForUnassignedDoctypeAdvancedDialog?.unidentifiedText} />
                </span>
                <span>
                  <Select
                    size="small"
                    name="documentType"
                    variant="outlined"
                    sx={{ minWidth: "200px" }}
                    displayEmpty={true}
                    value={selectedDocumentTypeInUnassignedModal}
                    renderValue={(value) =>
                      value === "Ignore this time" || value === "Never ask again" ? "Select Document Type" : value
                    }
                    onChange={(e) => {
                      setSelectedDocumentTypeInUnassignedModal(e.target.value);
                    }}
                  >
                    <MenuItem disabled={true}>Select Document Type</MenuItem>
                    {documentTypes
                      ?.filter((val) => val !== "Ignore this time" && val !== "Never ask again")
                      ?.map((val, i) => (
                        <MenuItem
                          key={i}
                          value={val}
                          style={{
                            color: val === "Ignore this time" ? "orange" : val === "Never ask again" ? "red" : "",
                          }}
                        >
                          {val}
                        </MenuItem>
                      ))}
                  </Select>
                </span>
              </div>
              <div className="space_between mt_20">
                <div>
                  <div className="mt_10">
                    <div>
                      <span>
                        <Checkbox
                          checked={checkedAddParticular}
                          onChange={handleChangeAddParticular}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                      <span className="fw_600">Add Particular/ Narration</span>
                      <span>
                        <Tooltip title="Please click on the check box and add the word that you want to match that will appear in particular/narration column. You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank , TDS for ‘Particular’">
                          <IconButton>
                            <InfoIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </div>
                    {checkedAddParticular && (
                      <div>
                        <TextField
                          id="outlined-name-addParticularText"
                          placeholder="Enter Particular/ Narration"
                          fullWidth={true}
                          value={addParticularText}
                          onChange={(e: any) => {
                            const strAdd = e.target.value.replace(/\s\s+/g, " ");
                            const arrAdd = strAdd.split(",");
                            const arrWithoutEmptyStringAdd = arrAdd.filter((entry: any) => entry.trim() !== "");
                            const arrWithTrimmedStringAdd = arrWithoutEmptyStringAdd.map((stringAdd: any) =>
                              stringAdd.trim()
                            );
                            const uniqArrAdd: any = [...new Set(arrWithTrimmedStringAdd)];
                            setAddParticularTextArr(uniqArrAdd);
                            setAddParticularText(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt_10">
                    <div>
                      <span>
                        <Checkbox
                          checked={checkedExcludeParticular}
                          onChange={handleChangeExcludeParticular}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                      <span className="fw_600">Exclude Particular/ Narration</span>
                      <span>
                        <Tooltip title="Please click on the check box and add the word that you want to match that will appear in particular/narration column. You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank , TDS for ‘Particular’">
                          <IconButton>
                            <InfoIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </div>
                    {checkedExcludeParticular && (
                      <div>
                        <TextField
                          id="outlined-name-excludeParticularText"
                          placeholder="Enter Particular/ Narration"
                          fullWidth={true}
                          value={excludeParticularText}
                          onChange={(e: any) => {
                            const str = e.target.value.replace(/\s\s+/g, " ");
                            const arr = str.split(",");
                            const arrWithoutEmptyString = arr.filter((entry: any) => entry.trim() !== "");
                            const arrWithTrimmedStringExclude = arrWithoutEmptyString.map((stringExclude: any) =>
                              stringExclude.trim()
                            );
                            const uniqArr: any = [...new Set(arrWithTrimmedStringExclude)];
                            setExcludeParticularTextArr(uniqArr);
                            setExcludeParticularText(e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div>Select transaction</div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={transactionValue}
                      onChange={(e) => {
                        handleChangeTransactionValue(e);
                      }}
                    >
                      <FormControlLabel value={0} control={<Radio />} label="All Transaction" />
                      <FormControlLabel value={1} control={<Radio />} label="Debit Transaction" />
                      <FormControlLabel value={2} control={<Radio />} label="Credit Transaction" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot space_between">
            <div>
              <Button
                className="theme_outline_btn"
                disabled={
                  selectedDocumentTypeInUnassignedModal === "Ignore this time" ||
                  selectedDocumentTypeInUnassignedModal === "Never ask again"
                }
                onClick={() => {
                  // check duplicate code here
                  const currentCode = {
                    code: itemForUnassignedDoctypeAdvancedDialog?.unidentifiedText,
                    sign: Number(transactionValue),
                    addParticulars: addParticularTextArr,
                    excludeParticulars: excludeParticularTextArr,
                    group: { groupId: "All", groupName: "All" },
                  };

                  const DocTypes = ["invoice", "payment", "debitAndCreditNotes", "reversal", "tds", "interDocument"];
                  let DocTypeRowsFromHeaderName: Element[];
                  const DocTypeRowsFromUnassignedAdvanceModal: Element[] = DocTypes.map((docType) => {
                    return {
                      name: docType,
                      codes:
                        documentTypeRowInUnassignedAdvancedModal.find((row) => row?.keyName === docType)?.codes || [],
                    };
                  });
                  // check duplicate code START
                  if (configUserType === "Own Ledger") {
                    DocTypeRowsFromHeaderName = DocTypes.map((docType) => {
                      return { name: docType, codes: headerName[docType] };
                    });
                  } else if (configUserType === "BusinessPartner Ledger") {
                    DocTypeRowsFromHeaderName = DocTypes.map((docType) => {
                      return { name: docType, codes: headerNameBp[docType] };
                    });
                  }

                  const isDuplicateAtHeaderName = checkIsDuplicateCode(currentCode, DocTypeRowsFromHeaderName);

                  const isDuplicateAtUnassignedAdvanceModal = checkIsDuplicateCode(
                    currentCode,
                    DocTypeRowsFromUnassignedAdvanceModal
                  );

                  if (isDuplicateAtHeaderName.isDuplicateCode) {
                    setDuplicateTagName(isDuplicateAtHeaderName.duplicateDocTypeName);
                  } else if (isDuplicateAtUnassignedAdvanceModal.isDuplicateCode) {
                    setDuplicateTagName(isDuplicateAtUnassignedAdvanceModal.duplicateDocTypeName);
                  }

                  // check duplicate code END, if not duplicate at either case, allow saving of code

                  if (
                    !isDuplicateAtHeaderName.isDuplicateCode &&
                    !isDuplicateAtUnassignedAdvanceModal.isDuplicateCode
                  ) {
                    const data = [
                      ...documentTypeRowInUnassignedAdvancedModal,
                      {
                        name: selectedDocumentTypeInUnassignedModal,
                        keyName: replaceWord(selectedDocumentTypeInUnassignedModal),
                        codes: [
                          {
                            code: itemForUnassignedDoctypeAdvancedDialog?.unidentifiedText,
                            sign: Number(transactionValue),
                            addParticulars: addParticularTextArr,
                            excludeParticulars: excludeParticularTextArr,
                            group: { groupId: "All", groupName: "All" },
                          },
                        ],
                      },
                    ];

                    const resultDocumentTypeRowInUnassignedAdvancedModal = data.reduce((acc, obj) => {
                      const existingObj = acc.find((item: any) => item.name === obj.name);
                      if (existingObj) {
                        existingObj.codes.push(...obj.codes);
                      } else {
                        acc.push(obj);
                      }
                      return acc;
                    }, []);
                    setDocumentTypeRowInUnassignedAdvancedModal(resultDocumentTypeRowInUnassignedAdvancedModal);
                    createdocumentTypeTags(resultDocumentTypeRowInUnassignedAdvancedModal);
                    setAddParticularText("");
                    setExcludeParticularText("");
                    setAddParticularTextArr([]);
                    setExcludeParticularTextArr([]);
                    setDuplicateTagName("");
                  }
                }}
              >
                Add More
              </Button>
            </div>
            <div>
              <Button
                className="theme_btn"
                onClick={async () => {
                  if (configUserType === "Own Ledger") {
                    const modifiedJSONHeaderName = await addCodesToHeaderNameJSON(
                      documentTypeRowInUnassignedAdvancedModal,
                      headerName
                    );
                    await saveRuleApiOwn(modifiedJSONHeaderName, Customized, sanitizeColOwn);
                    if (actor.integration) {
                      compareLedgersCheckEndDate();
                    }
                  } else if (configUserType === "BusinessPartner Ledger") {
                    const modifiedJSONHeaderName = await addCodesToHeaderNameJSON(
                      documentTypeRowInUnassignedAdvancedModal,
                      headerNameBp
                    );
                    await saveRuleApi(modifiedJSONHeaderName, Customized, sanitizeColBusiness);
                    if (businessPartnerIntegrationWithERP) {
                      compareLedgersCheckEndDate();
                    }
                  }
                  setOpenDocumentTypeDialog(false);
                  resetStatesOfUnassignedDoctypeAdvancedModal();

                  if (!fetchDataFromERP) {
                    ignoreDoctypesList.current = [];
                    excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
                  }
                }}
              >
                Save and proceed
              </Button>
            </div>
          </footer>
        </Dialog>
        <Dialog open={sanitizerWarning} onClose={() => setSanitizerWarning(false)}>
          <header className="modal-card-head flex_end top_border_radius" style={{ padding: "8px" }}>
            <button className="delete" aria-label="close" onClick={() => setSanitizerWarning(false)} />
          </header>
          <section className="modal-card-body bottom_border_radius">
            <p className="errorPastDate_modal_text">{sanitizerWarningMsg}</p>
          </section>
        </Dialog>
        <Dialog open={showUploadedSummary} onClose={() => setShowUploadedSummary(true)}>
          <UploadedSummary
            setShowUploadedSummary={setShowUploadedSummary}
            showUploadedSummaryObject={showUploadedSummaryObject}
            subTab={props.subTab}
            showConfigMappedData={showConfigMappedData}
            showConfigMapped={showConfigMapped}
            setShowConfigMapped={setShowConfigMapped}
          />
        </Dialog>
        <Dialog open={showUseDateFilter} onClose={() => setShowUseDateFilter(true)}>
          <UseDateFilter
            setShowUseDateFilter={setShowUseDateFilter}
            startDateOwn={startDateOwn}
            setStartDateOwn={setStartDateOwn}
            setStartDateOwnRef={startDateOwnRef}
            //  startDateBusinessPartner={startDateBusinessPartner}
            setStartDateBusinessPartner={setStartDateBusinessPartner}
            endDateOwn={endDateOwn}
            setEndDateOwn={setEndDateOwn}
            setEndDateOwnRef={endDateOwnRef}
            //  endDateBusinessPartner={endDateBusinessPartner}
            setEndDateBusinessPartner={setEndDateBusinessPartner}
          />
        </Dialog>
        <PdfOptionConfirmModal
          openConfirmOptionModal={openConfirmOptionModal}
          onPdfOptionConfirm={() => {
            onPdfOptionConfirm();
          }}
          selectedPdfConverter={selectedPdfConverter}
        />
        <DuplicateFoundModal
          open={showDuplicateFoundModal}
          setOpen={setShowDuplicateFoundModal}
          setApiErrorMsg={setApiErrorMsg}
          setShowErrorAlert={setShowUploadErrorAlert}
          setShowUploadedSummary={setShowUploadedSummary}
          businessPartnerId={businessPartnerSelected}
          configUseCaseType={
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
          }
          userType={ownLedgerUpload.current ? "own" : "businessPartner"}
          OnModalClose={() => {
            if (ownLedgerUpload.current) {
              getOwnDataGetReconMetaData(businessPartnerSelected);
            } else {
              getBusinessPartnerDateGetReconMetaData(businessPartnerSelected);
            }
          }}
          OnYesClick={(res) => {
            if (ownLedgerUpload.current) {
              getOwnDataGetReconMetaData(businessPartnerSelected);
            } else {
              getBusinessPartnerDateGetReconMetaData(businessPartnerSelected);
            }
            setShowUploadedSummaryObject(res.data?.uploadSummary);
            setShowConfigMappedData(res.data?.configMapped);
          }}
        />
        {/* ------------------- */}
        <SendMail
          openSendMailDialog={openSendMailDialog}
          companyId={companyId.current}
          emailTemplate={emailTemplateContent}
          recieverId={businessPartnerSelected}
          setOpenSendMailDialog={setOpenSendMailDialog}
          files={
            reconEmailFiles.summaryOutput
              ? [
                  ...reconEmailFiles?.originalBPLedger,
                  ...reconEmailFiles?.originalOwnLedger,
                  reconEmailFiles?.summaryOutput,
                ]
              : [...reconEmailFiles?.originalBPLedger, ...reconEmailFiles?.originalOwnLedger]
          }
          setShowErrorAlert={setShowUploadErrorAlert}
          setApiErrorMsg={setApiErrorMsg}
          setShowSuccessAlert={setShowUploadSuccessAlert}
        />
      </Recon360Context.Provider>
    </LoggedInSkeleton>
  );
};

export default Recon360;
