import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { UserTypes } from "../../../entity/models/FrontendActor";
import { EmailSMTPHosts, ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import ApiErrorCatch from "../ApiSuccessErrorAlertPopup/ApiErrorCatch";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import UserEmailSetting from "../BpContactMaster/ UserEmailSetting";
import "../BpContactMaster/BpContactMaster.scss";
import DesignationSetting from "../BpContactMaster/DesignationSetting";
import SelectEmailDomain from "../BpContactMaster/SelectEmailDomain";
import SendEmailFromOfficialEmail from "../BpContactMaster/SendEmailFromOfficialEmail";
import AlgoCustomization from "./AlgoCustomization";
import ReconRuleConfigurations from "./ReconRuleConfigurations";
import "./ReconSettings.scss";
import ReconSummary from "./ReconSummary";
import ReconSummaryCalculation from "./ReconSummaryCalculation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReconSettings = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const [value, setValue] = useState<number>(0);
  const [openUserEmailSetting, setOpenUserEmailSetting] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");
  const [emailDisplayName, setEmailDisplayName] = useState<string>("");
  const [fromEmailAddress, setFromEmailAddress] = useState<string>("");
  const [replyToEmailAddress, setReplyToEmailAddress] = useState<string[]>([]);
  const [file, setFile] = useState<any>(undefined);
  const [signatureName, setSignatureName] = useState<string>("");
  const [emailCredentialsProvided, setEmailCredentialsProvided] = useState<boolean>(false);
  const [openSendEmailFromOfficialEmail, setOpenSendEmailFromOfficialEmail] = useState<boolean>(false);
  const [openSelectEmailDomain, setOpenSelectEmailDomain] = useState<boolean>(false);
  const [userEmailID, setUserEmailID] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [smtpHost, setSmtpHost] = useState<string>(null);
  const [openSendEmailFromOfficialEmailForOutlook, setOpenSendEmailFromOfficialEmailForOutlook] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForZimbra, setOpenSendEmailFromOfficialEmailForZimbra] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForGoDaddy, setOpenSendEmailFromOfficialEmailForGoDaddy] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForMicrosoftExchange, setOpenSendEmailFromOfficialEmailForMicrosoftExchange] =
    useState<boolean>(false);
  const [openDesignationSettingFromBpContactMaster, setOpenDesignationSettingFromBpContactMaster] =
    useState<boolean>(false);
  const [listAllDesignation, setListAllDesignation] = useState<any[]>([]);
  // const [rowsData, setRowsData] = useState<BPContactListInterface[]>([]);
  // const [loader, setLoader] = useState<boolean>(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event);

    setValue(newValue);
  };

  useEffect(() => {
    getListUserEmailSetting();
    getListAllDesignation();
    // eslint-disable-next-line
  }, []);

  const getListUserEmailSetting = async () => {
    try {
      await Axios.get<any>("/api/ListUserEmailSetting", {
        params: {
          userId: actor.userId,
        },
      })
        .then((response) => {
          if (
            response.data.userEmailSetting.display_name !== null &&
            response.data.userEmailSetting.display_name !== undefined &&
            response.data.userEmailSetting.display_name !== ""
          ) {
            setEmailDisplayName(response.data.userEmailSetting.display_name);
          }
          if (
            response.data.userEmailSetting.from_email !== null &&
            response.data.userEmailSetting.from_email !== undefined &&
            response.data.userEmailSetting.from_email !== ""
          ) {
            setFromEmailAddress(response.data.userEmailSetting.from_email);
          }
          if (
            response.data.userEmailSetting.reply_to !== null &&
            response.data.userEmailSetting.reply_to !== undefined &&
            response.data.userEmailSetting.reply_to !== ""
          ) {
            setReplyToEmailAddress(response.data.userEmailSetting.reply_to); // ----
          }
          if (
            response.data.userEmailSetting.signature.image !== null &&
            response.data.userEmailSetting.signature.image !== undefined &&
            response.data.userEmailSetting.signature.image !== ""
          ) {
            // setFile(response.data.userEmailSetting.signature.image);
            setFile(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
          }
          if (
            response.data.userEmailSetting.signature.text !== null &&
            response.data.userEmailSetting.signature.text !== undefined &&
            response.data.userEmailSetting.signature.text !== ""
          ) {
            setSignatureName(response.data.userEmailSetting.signature.text);
          }
          setEmailCredentialsProvided(response.data.userEmailSetting.emailCredentialsProvided);
          // setOpenUserEmailSetting(true);
        })
        .catch((error: any) => {
          console.log("error ListUserEmailSetting", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListUserEmailSetting`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListUserEmailSetting", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListUserEmailSetting`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  const setStateTrueAfterTimeout = (stateFn: any) => {
    const s = setTimeout(() => {
      if (stateFn) stateFn(true);
      window.clearTimeout(s);
    }, 500);
  };

  // const listAllBPContacts = async () => {
  //   try {
  //     await Axios
  //       .get<ListAllBPContactsInterface>("/api/ListAllBPContacts")
  //       .then((response) => {
  //         console.log("response ListAllBPContacts", response);
  //         setRowsData(response.data.BPContactList);
  //         setLoader(false);
  //       })
  //       .catch((error: any) => {
  //         console.log("error ListAllBPContacts", error?.response);
  //         setLoader(false);
  //         if (error?.response?.data?.message !== undefined) {
  //           const dataObj = error.response.data as ErrorResponse;
  //           setShowErrorAlert(true);
  //           setApiErrorMsg(dataObj.message);
  //           apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //         } else {
  //           setShowErrorAlert(true);
  //           setApiErrorMsg(`${defaultErrorMsg}ListAllBPContacts`);
  //           apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //         }
  //       });
  //   } catch (error: any) {
  //     console.log("error ListAllBPContacts", error?.response);
  //     setShowErrorAlert(true);
  //     setApiErrorMsg(`${defaultErrorMsg}ListAllBPContacts`);
  //     apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
  //   }
  // };

  const getListAllDesignation = async () => {
    try {
      await Axios.get<any>("/api/ListAllDesignation")
        .then((response) => {
          console.log("ListAllDesignation", response.data.designations);

          setListAllDesignation(response.data.designations);
        })
        .catch((error: any) => {
          console.log("error ListAllDesignation", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          } else {
            setShowErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ListAllDesignation`);
            apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ListAllDesignation", error?.response);
      setShowErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ListAllDesignation`);
      apiSuccessErrorAlertSetTimeout(setShowErrorAlert, setApiErrorMsg);
    }
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Recon Settings", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <ApiErrorCatch
        showUploadSuccessAlert={showSuccessAlert}
        showUploadErrorAlert={showErrorAlert}
        apiErrorMsg={apiErrorMsg}
      />
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Recon Summary" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="Recon Summary calculation" {...a11yProps(1)} sx={{ textTransform: "none" }} />
              {actor.userType === UserTypes.CustomerSuccess ? (
                <Tab label="Recon Rule Configurations" {...a11yProps(2)} sx={{ textTransform: "none" }} />
              ) : (
                <div />
              )}
              <Tab
                label="User email setting"
                {...a11yProps(3)}
                sx={{ textTransform: "none" }}
                onClick={() => setStateTrueAfterTimeout(setOpenUserEmailSetting)}
              />
              <Tab
                label="Designation setting"
                {...a11yProps(4)}
                sx={{ textTransform: "none" }}
                onClick={() => setStateTrueAfterTimeout(setOpenDesignationSettingFromBpContactMaster)}
              />
              {actor.userType === UserTypes.CustomerSuccess ? (
                <Tab label="Algo Customization" {...a11yProps(5)} sx={{ textTransform: "none" }} />
              ) : (
                <div />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ReconSummary />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReconSummaryCalculation />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReconRuleConfigurations />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="center_align">
              <Button
                variant="contained"
                className="theme_btn width_180"
                onClick={() => {
                  getListUserEmailSetting();
                  setOpenUserEmailSetting(true);
                }}
              >
                User email setting
              </Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div className="center_align">
              <Button
                variant="contained"
                className="theme_btn width_180 mr_10"
                onClick={() => {
                  getListAllDesignation();
                  setOpenDesignationSettingFromBpContactMaster(true);
                }}
              >
                Designation setting
              </Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <AlgoCustomization />
          </TabPanel>
        </Box>
      </div>
      <Dialog open={openUserEmailSetting} onClose={() => setOpenUserEmailSetting(true)}>
        <UserEmailSetting
          setOpenUserEmailSetting={setOpenUserEmailSetting}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          emailDisplayName={emailDisplayName}
          setEmailDisplayName={setEmailDisplayName}
          fromEmailAddress={fromEmailAddress}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          file={file}
          setFile={setFile}
          getListUserEmailSetting={getListUserEmailSetting}
          signatureName={signatureName}
          setSignatureName={setSignatureName}
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmail}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          emailCredentialsProvided={emailCredentialsProvided}
          password={password}
          setUserEmailID={setUserEmailID}
          setPassword={setPassword}
          smtpHost={smtpHost}
          setSmtpHost={setSmtpHost}
        />
      </Dialog>
      <SelectEmailDomain
        openSelectEmailDomain={openSelectEmailDomain}
        setOpenSelectEmailDomain={setOpenSelectEmailDomain}
        setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmail}
        setOpenSendEmailFromOfficialEmailForOutlook={setOpenSendEmailFromOfficialEmailForOutlook}
        setOpenSendEmailFromOfficialEmailForZimbra={setOpenSendEmailFromOfficialEmailForZimbra}
        setOpenSendEmailFromOfficialEmailForGoDaddy={setOpenSendEmailFromOfficialEmailForGoDaddy}
        setOpenSendEmailFromOfficialEmailForMicrosoftExchange={setOpenSendEmailFromOfficialEmailForMicrosoftExchange}
      />
      <Dialog open={openSendEmailFromOfficialEmail} onClose={() => setOpenSendEmailFromOfficialEmail(true)}>
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmail}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.gmail}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForOutlook}
        onClose={() => setOpenSendEmailFromOfficialEmailForOutlook(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForOutlook}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.outlook}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForZimbra}
        onClose={() => setOpenSendEmailFromOfficialEmailForZimbra(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForZimbra}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.zimbra}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForGoDaddy}
        onClose={() => setOpenSendEmailFromOfficialEmailForGoDaddy(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForGoDaddy}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.goDaddy}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForMicrosoftExchange}
        onClose={() => setOpenSendEmailFromOfficialEmailForMicrosoftExchange(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForMicrosoftExchange}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.microsoftExchange}
        />
      </Dialog>
      <Dialog
        open={openDesignationSettingFromBpContactMaster}
        onClose={() => setOpenDesignationSettingFromBpContactMaster(true)}
        // width70Per={true}
      >
        <DesignationSetting
          setOpenDesignationSetting={setOpenDesignationSettingFromBpContactMaster}
          from={"BpContactMaster"}
          setShowErrorAlert={setShowErrorAlert}
          setShowSuccessAlert={setShowSuccessAlert}
          setApiErrorMsg={setApiErrorMsg}
          listAllDesignation={listAllDesignation}
          setListAllDesignation={setListAllDesignation}
          // listAllBPContacts={listAllBPContacts}
          getListAllDesignation={getListAllDesignation}
        />
      </Dialog>
    </LoggedInSkeleton>
  );
};

export default ReconSettings;
