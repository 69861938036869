import { ChevronLeft } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, IconButton, TextField } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import axios from "axios";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ErrorResponse, MatchedEntriesForManualRecon } from "../../../entity/recon-entity/ReconInterfaces";
import {
  gapAccountedAsName,
  manualReconStatusName,
  manualReconTypeName,
} from "../../../Utils/Recon/Recon360/Constants";
import IndeterminateCheckbox from "../../Admin/IndeterminateCheckbox";
// import DetailsTable from '../../ReactTable/DetailsTable';
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";
import { Recon360Context } from "../Recon360";
import { formatNumberINR } from "../ReconMIS/utils";
import "./ManualRecon.scss";
import ManualReconReconcileBpFilter from "./ManualReconReconcileBpFilter";
import ManualReconReconcileOwnFilter from "./ManualReconReconcileOwnFilter";
import ReconcileConfirmWarningModal from "./ReconcileConfirmWarningModal";
import UnreconcileConfirmWarningModal from "./UnreconcileConfirmWarningModal";

type ColDef = ColumnDef<any> & {
  sticky?: "left" | "right";
};

type StateDispatch<T = any> = Dispatch<SetStateAction<T>>;

export interface ManualReconContextInterface {
  isOpenUnreconcileConfirmWarningModal: boolean;
  setIsOpenUnreconcileConfirmWarningModal: StateDispatch<boolean>;
  isOpenReconcileConfirmWarningModal: boolean;
  setIsOpenReconcileConfirmWarningModal: StateDispatch<boolean>;
  unReconcileEntriesOnMatchId: () => void;
  reconcileEntries: (val) => void;
  reconcileTotalSum: number;
  selectedRowOwn: any[];
  selectedRowBp: any[];
  amountTolerance: number;
  openManualReconReconcileOwnFilter: boolean;
  setOpenManualReconReconcileOwnFilter: StateDispatch<boolean>;
  storeRowsDataReconciledOwn: any[];
  entryNumOwn: string;
  setEntryNumOwn: StateDispatch<string>;
  setIsFilterAppliedOwnSide: StateDispatch<boolean>;
  setRowsDataReconciledOwn: StateDispatch<any[]>;
  openManualReconReconcileBpFilter: boolean;
  setOpenManualReconReconcileBpFilter: StateDispatch<boolean>;
  storeRowsDataReconciledBp: any[];
  entryNumBp: string;
  setEntryNumBp: StateDispatch<string>;
  setIsFilterAppliedBpSide: StateDispatch<boolean>;
  setRowsDataReconciledBp: StateDispatch<any[]>;
  absoluteReconcileTotalSum: number;
  showLoadingIconUnreconcile: boolean;
  showLoadingIconReconcile: boolean;
  gapAccountedName: string;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  isFilterAppliedOwnSide: boolean;
  isFilterAppliedBpSide: boolean;
}

export const ManualReconContext = createContext<ManualReconContextInterface>(null);

const ManualRecon = () => {
  const {
    businessPartnerSelected,
    businessPartnerSelectedCompanyIdRef,
    companyId,
    setShowUploadErrorAlert,
    setApiErrorMsg,
    checkboxDataManualRecon,
    amountTolerance,
    totalAmount,
    hasManuallyReconciled,
    setOpenManualRecon,
    getManuallyChangedEntries,
    downloadExcelFromApi,
    setShowUploadSuccessAlert,
    showDownloadResultsLoadingIcon,
  } = useContext(Recon360Context);
  const [rowsDataUnreconciled, setRowsDataUnreconciled] = useState<MatchedEntriesForManualRecon[]>([]);
  const [rowsDataReconciledOwn, setRowsDataReconciledOwn] = useState([]);
  const [storeRowsDataReconciledOwn, setStoreRowsDataReconciledOwn] = useState([]);
  const [rowsDataReconciledBp, setRowsDataReconciledBp] = useState([]);
  const [storeRowsDataReconciledBp, setStoreRowsDataReconciledBp] = useState([]);
  const [matchIdValue, setMatchIdValue] = useState<string>(null);
  const [matchIdValueArr, setMatchIdValueArr] = useState<string[]>(null);
  const [isOpenUnreconcileConfirmWarningModal, setIsOpenUnreconcileConfirmWarningModal] = useState<boolean>(false);
  const [isOpenReconcileConfirmWarningModal, setIsOpenReconcileConfirmWarningModal] = useState<boolean>(false);
  const [manualReconType, setManualReconType] = useState<string>(null);
  const [isFilterAppliedOwnSide, setIsFilterAppliedOwnSide] = useState<boolean>(false);
  const [isFilterAppliedBpSide, setIsFilterAppliedBpSide] = useState<boolean>(false);
  const [entryNumOwn, setEntryNumOwn] = useState<string>(null);
  const [entryNumBp, setEntryNumBp] = useState<string>(null);
  const [selectedEntryNumbersOwn, setSelectedEntryNumbersOwn] = useState([]);
  const [selectedEntryNumbersBp, setSelectedEntryNumbersBp] = useState([]);
  const [reconcileTotalSum, setReconcileTotalSum] = useState<number>(0);
  const [absoluteReconcileTotalSum, setAbsoluteReconcileTotalSum] = useState<number>(0);
  const [showApplyOptions, setShowApplyOptions] = useState<boolean>(false);
  const [selectedRowOwn, setSelectedRowOwn] = useState<any[]>([]);
  const [selectedRowBp, setSelectedRowBp] = useState<any[]>([]);
  const [showUnreconcileButton, setShowUnreconcileButton] = useState<boolean>(true);
  const [showReconcileButton, setShowReconcileButton] = useState<boolean>(true);
  const textFieldRefUnReconcile = useRef(null);
  const textFieldRefReconcileOwn = useRef(null);
  const textFieldRefReconcileBp = useRef(null);
  const [openManualReconReconcileOwnFilter, setOpenManualReconReconcileOwnFilter] = useState<boolean>(false);
  const [openManualReconReconcileBpFilter, setOpenManualReconReconcileBpFilter] = useState<boolean>(false);
  const [showLoadingIconUnreconcile, setShowLoadingIconUnreconcile] = useState<boolean>(false);
  const [showLoadingIconReconcile, setShowLoadingIconReconcile] = useState<boolean>(false);
  const [gapAccountedName, setGapAccountedName] = useState<string>(null);
  const [showLoadingIconReconcileBtn, setShowLoadingIconReconcileBtn] = useState<boolean>(false);
  const [showChangesDoneCard, setShowChangesDoneCard] = useState(false);
  const [reFilter, setReFilter] = useState(false);
  const ColsRecon = { otherUniqueId: false, postingDate: false, particulars: false, dncnId: false, paymentId: false };
  const [hiddenColsReconOwn, setHiddenColsReconOwn] = useState(ColsRecon);
  const [hiddenColsReconBp, setHiddenColsReconBp] = useState(ColsRecon);

  const columnDefinitionUnreconciled: ColDef[] = [
    {
      header: "MATCH ID",
      accessorKey: "matchId",
      sticky: "left",
      maxSize: 140,
      minSize: 140,
      size: 140,
    },
    {
      header: () => <div className="borderRight">Own</div>,
      id: "own",
      maxSize: null,
      minSize: null,
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "ownDate",
          maxSize: 140,
          minSize: 140,
          size: 140,
        },
        {
          header: "Doc Type",
          accessorKey: "ownDocumentType",
          maxSize: 100,
          minSize: 100,
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "ownReferenceNumber",
          maxSize: 180,
          minSize: 180,
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "ownAmount",
          maxSize: 180,
          minSize: 180,
          size: 180,
          cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownAmount)}</div>,
        },
        {
          header: "Type",
          accessorKey: "ownType",
          maxSize: 150,
          minSize: 150,
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "ownCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "ownDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "ownPostingDate",
          maxSize: 150,
          minSize: 150,
          size: 150,
        },
      ],
    },
    {
      header: () => <div className="borderLeft">BP</div>,
      id: "bpUnreconcile",
      maxSize: null,
      minSize: null,
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "businessPartnerDate",
          maxSize: 100,
          minSize: 100,
          size: 100,
        },
        {
          header: "Doc Type",
          accessorKey: "businessPartnerDocumentType",
          maxSize: 100,
          minSize: 100,
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "businessPartnerReferenceNumber",
          maxSize: 180,
          minSize: 180,
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "businessPartnerAmount",
          maxSize: 180,
          minSize: 180,
          size: 180,
          cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerAmount)}</div>,
        },
        {
          header: "Type",
          accessorKey: "businessPartnerType",
          maxSize: 150,
          minSize: 150,
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "businessPartnerCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "businessPartnerDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "businessPartnerPostingDate",
          maxSize: 150,
          minSize: 150,
          size: 150,
        },
      ],
    },
  ];

  const columnDefinitionReconciledOwn = useMemo(
    (): ColDef[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        minSize: 35,
        maxSize: 35,
        size: 35,
      },
      {
        header: "Entry no.",
        accessorKey: "entryNo",
        sticky: "left",
        maxSize: 100,
        minSize: 100,
        size: 100,
      },
      {
        header: () => <div className="borderRight">Own</div>,
        id: "reconciledOwn",
        maxSize: null,
        minSize: null,
        size: null,
        accessorKey: "",
        columns: [
          {
            header: "Date",
            accessorKey: "date",
            maxSize: 140,
            minSize: 140,
            size: 140,
          },
          {
            header: "Reference",
            accessorKey: "referenceNumber",
            maxSize: 180,
            minSize: 180,
            size: 180,
          },
          {
            header: "Type",
            accessorKey: "type",
            maxSize: 100,
            minSize: 100,
            size: 100,
          },
          {
            header: "Amount",
            accessorKey: "amount",
            maxSize: 140,
            minSize: 140,
            size: 140,
            cell: ({ row }: any) => <div>{formatNumberINR(row.original.amount)}</div>,
          },
          {
            header: "Doc Type",
            accessorKey: "documentType",
            maxSize: 100,
            minSize: 100,
            size: 100,
          },
          hiddenColsReconOwn.otherUniqueId
            ? {
                id: "otherUniqueId",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Other Unique ID",
                accessorKey: "otherUniqueId",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          hiddenColsReconOwn.postingDate
            ? {
                id: "postingDate",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Posting Date",
                accessorKey: "postingDate",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          hiddenColsReconOwn.particulars
            ? {
                id: "particulars",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Particulars",
                accessorKey: "particulars",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          // {
          //   header: "Credit Amount",
          //   accessorKey: "creditAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.creditAmount)}</div>,
          // },
          // {
          //   header: "Debit Amount",
          //   accessorKey: "debitAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.debitAmount)}</div>,
          // },
          hiddenColsReconOwn.dncnId
            ? {
                id: "dncnId",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "DNCN ID",
                accessorKey: "dncnId",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          hiddenColsReconOwn.paymentId
            ? {
                id: "paymentId",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Payment ID",
                accessorKey: "paymentId",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
        ],
      },
    ],
    [hiddenColsReconOwn]
  );
  const columnDefinitionReconciledBp = useMemo(
    (): ColDef[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        minSize: 35,
        maxSize: 35,
        size: 35,
      },
      {
        header: "Entry no.",
        accessorKey: "entryNo",
        sticky: "left",
        maxSize: 100,
        minSize: 100,
        size: 100,
      },
      {
        header: () => <div className="borderRight">BP</div>,
        id: "reconciledBp",
        maxSize: null,
        minSize: null,
        size: null,
        accessorKey: "",
        columns: [
          {
            header: "Date",
            accessorKey: "date",
            maxSize: 140,
            minSize: 140,
            size: 140,
          },
          {
            header: "Reference",
            accessorKey: "referenceNumber",
            maxSize: 180,
            minSize: 180,
            size: 180,
          },
          {
            header: "Type",
            accessorKey: "type",
            maxSize: 100,
            minSize: 100,
            size: 100,
          },
          {
            header: "Amount",
            accessorKey: "amount",
            maxSize: 140,
            minSize: 140,
            size: 140,
            cell: ({ row }: any) => <div>{formatNumberINR(row.original.amount)}</div>,
          },
          {
            header: "Doc Type",
            accessorKey: "documentType",
            maxSize: 100,
            minSize: 100,
            size: 100,
          },
          hiddenColsReconBp.otherUniqueId
            ? {
                id: "otherUniqueId",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Other Unique ID",
                accessorKey: "otherUniqueId",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          hiddenColsReconBp.postingDate
            ? {
                id: "postingDate",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Posting Date",
                accessorKey: "postingDate",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          hiddenColsReconBp.particulars
            ? {
                id: "particulars",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Particulars",
                accessorKey: "particulars",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          // {
          //   header: "Credit Amount",
          //   accessorKey: "creditAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.creditAmount)}</div>,
          // },
          // {
          //   header: "Debit Amount",
          //   accessorKey: "debitAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.debitAmount)}</div>,
          // },
          hiddenColsReconBp.dncnId
            ? {
                id: "dncnId",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "DNCN ID",
                accessorKey: "dncnId",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
          hiddenColsReconBp.paymentId
            ? {
                id: "paymentId",
                accessorKey: "",
                size: 0,
              }
            : {
                header: "Payment ID",
                accessorKey: "paymentId",
                maxSize: 100,
                minSize: 100,
                size: 100,
              },
        ],
      },
    ],
    [hiddenColsReconBp]
  );

  useEffect(() => {
    const checkValExistsInArr = (arr: any[]) => {
      const keysWithValues = {
        ...ColsRecon,
      };
      Object.keys(keysWithValues).forEach((key) => {
        keysWithValues[key] = !arr.some((row) => row[key]);
      });

      return keysWithValues;
    };

    setHiddenColsReconOwn(checkValExistsInArr(rowsDataReconciledOwn));
    setHiddenColsReconBp(checkValExistsInArr(rowsDataReconciledBp));
    // eslint-disable-next-line
  }, [rowsDataReconciledOwn, rowsDataReconciledBp]);

  const [selectedValues, setSelectedValues] = useState(
    checkboxDataManualRecon.map((item) => ({
      ...item,
      isSelected: false,
    }))
  );

  useEffect(() => {
    // Calculate the sum of "amount" from selectedRowOwn
    const sumSelectedRowOwn =
      selectedRowOwn.length > 0 ? selectedRowOwn.reduce((accumulator, item) => accumulator + item.amount, 0) : 0;

    // Calculate the sum of "amount" from selectedRowBP
    const sumSelectedRowBP =
      selectedRowBp.length > 0 ? selectedRowBp.reduce((accumulator, item) => accumulator + item.amount, 0) : 0;

    // Calculate the overall sum
    const overallSum = sumSelectedRowOwn + sumSelectedRowBP;
    setReconcileTotalSum(overallSum);
    setAbsoluteReconcileTotalSum(Math.abs(overallSum));

    // Extract entry numbers from selectedRowOwn
    const entryNumbersArrayOwn = selectedRowOwn?.map((item) => item.entryNo);
    setSelectedEntryNumbersOwn(entryNumbersArrayOwn);
    const entryNumbersArrayBp = selectedRowBp?.map((item) => item.entryNo);
    setSelectedEntryNumbersBp(entryNumbersArrayBp);
  }, [selectedRowOwn, selectedRowBp]);

  const isAllStrikethroughChecked = selectedValues.every((checkbox) => !checkbox.isValid || checkbox?.isSelected);

  const handleChangeParent = (event) => {
    const isChecked = event.target.checked;
    setSelectedValues(
      selectedValues.map((checkbox) => (!checkbox.isValid ? checkbox : { ...checkbox, isSelected: isChecked }))
    );
  };

  const handleChangeChild = (index) => (event) => {
    if (checkboxDataManualRecon[index].isValid) {
      const newSelectedValues = [...selectedValues];
      newSelectedValues[index].isSelected = event.target.checked;
      setSelectedValues(newSelectedValues);
    }
  };

  // -----

  const preAppliedChanges = async (changes) => {
    try {
      await axios
        .post("/api/PreAppliedChanges", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
          changes,
        })
        .then((response) => {
          console.log(response);
          getManuallyChangedEntries();
          setManualReconType(null);
          setShowUnreconcileButton(true);
          setShowReconcileButton(true);
        })
        .catch((error) => {
          console.log("error PreAppliedChanges", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}PreAppliedChanges`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error PreAppliedChanges", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}PreAppliedChanges`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const applyAllChanges = async () => {
    // from selectedValues filter out selected true
    // then remove isSelected key and pass that array in to function
    // Filter out objects where isSelected is true
    const filteredIsSelectedData = selectedValues?.filter((item) => item?.isSelected);
    // Remove isSelected key from filteredIsSelectedData
    const filteredDataWithoutSelectedKey = filteredIsSelectedData?.map(({ isSelected, ...rest }) => rest);
    await preAppliedChanges(filteredDataWithoutSelectedKey);
    handleCloseApplyAllChanges();
  };

  const handleCloseApplyAllChanges = () => {
    setShowApplyOptions(false);
  };

  const getManuallyUnreconciledEntries = async (ownEntries, businessPartnerEntries, showTable) => {
    try {
      await axios
        .post("/api/GetManuallyUnreconciledEntries", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
          ownEntries,
          businessPartnerEntries,
        })
        .then((response) => {
          if (showTable === "showReconcileTable") {
            setManualReconType(manualReconTypeName.RECONCILE);
            setRowsDataReconciledOwn(response.data.data.own);
            setStoreRowsDataReconciledOwn(response.data.data.own);
            setRowsDataReconciledBp(response.data.data.businessPartner);
            setStoreRowsDataReconciledBp(response.data.data.businessPartner);
          }
        })
        .catch((error) => {
          console.log("error GetManuallyUnreconciledEntries", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetManuallyUnreconciledEntries`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetManuallyUnreconciledEntries", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetManuallyUnreconciledEntries`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const getManuallyReconciledEntries = async (ownEntries, businessPartnerEntries, showTable) => {
    try {
      await axios
        .post("/api/GetManuallyReconciledEntries", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
          ownEntries,
          businessPartnerEntries,
        })
        .then((response) => {
          if (showTable === "showUnReconcileTable") {
            setManualReconType(manualReconTypeName.UNRECONCILE);
            setRowsDataUnreconciled(response.data.data);

            const responseData: MatchedEntriesForManualRecon[] = response.data.data as MatchedEntriesForManualRecon[];

            // Extract unique matchId values
            const uniqueMatchIds = [...new Set(responseData.map((item) => item.matchId))];
            setMatchIdValueArr(uniqueMatchIds);
          }
        })
        .catch((error) => {
          console.log("error GetManuallyReconciledEntries", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetManuallyReconciledEntries`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetManuallyReconciledEntries", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetManuallyReconciledEntries`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const showDetailsOfEntriesOnMatchId = async (matchId) => {
    try {
      await axios
        .post("/api/ShowDetailsOfEntriesOnMatchId", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
          matchId,
        })
        .then((response) => {
          setRowsDataUnreconciled(response.data.data);
        })
        .catch((error) => {
          console.log("error ShowDetailsOfEntriesOnMatchId", error?.response);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ShowDetailsOfEntriesOnMatchId`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ShowDetailsOfEntriesOnMatchId", error?.response);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ShowDetailsOfEntriesOnMatchId`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const unReconcileEntriesOnMatchId = async () => {
    setShowLoadingIconUnreconcile(true);
    try {
      await axios
        .post("/api/UnreconcileEntriesOnMatchId", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
          matchId: matchIdValueArr,
        })
        .then((response) => {
          setApiErrorMsg(response.data?.message);
          setShowUploadSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          setRowsDataUnreconciled([]);
          getManuallyChangedEntries();
          setMatchIdValue("");
          setIsOpenUnreconcileConfirmWarningModal(false);
          setShowLoadingIconUnreconcile(false);
        })
        .catch((error) => {
          console.log("error UnreconcileEntriesOnMatchId", error?.response);
          setShowLoadingIconUnreconcile(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}UnreconcileEntriesOnMatchId`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error UnreconcileEntriesOnMatchId", error?.response);
      setShowLoadingIconUnreconcile(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}UnreconcileEntriesOnMatchId`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const reconcileEntries = async (gapAccountedAs) => {
    setShowLoadingIconReconcile(true);
    setGapAccountedName(gapAccountedAs);
    try {
      await axios
        .post("/api/ReconcileEntries", {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
          entryNumbersOwn: selectedEntryNumbersOwn,
          entryNumbersBusinessPartner: selectedEntryNumbersBp,
          gapAccountedAs,
        })
        .then((response) => {
          setManualReconType(null);
          setManualReconType(manualReconTypeName.RECONCILE);
          setApiErrorMsg(response.data?.message);
          setShowUploadSuccessAlert(true);
          apiSuccessErrorAlertSetTimeout(setShowUploadSuccessAlert, setApiErrorMsg);
          setRowsDataReconciledOwn([]);
          setStoreRowsDataReconciledOwn([]);
          setRowsDataReconciledBp([]);
          setStoreRowsDataReconciledBp([]);

          setReconcileTotalSum(0);
          setAbsoluteReconcileTotalSum(0);
          setSelectedRowOwn([]);
          setSelectedRowBp([]);
          getManuallyChangedEntries();
          setIsOpenReconcileConfirmWarningModal(false);
          setShowLoadingIconReconcile(false);
          setGapAccountedName(null);
          getUnreconciledEntriesOnReconcileClick();
        })
        .catch((error) => {
          console.log("error ReconcileEntries", error?.response);
          setShowLoadingIconReconcile(false);
          setGapAccountedName(null);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}ReconcileEntries`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error ReconcileEntries", error?.response);
      setShowLoadingIconReconcile(false);
      setGapAccountedName(null);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}ReconcileEntries`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };
  const getUnreconciledEntriesOnReconcileClick = async () => {
    setShowLoadingIconReconcileBtn(true);
    try {
      await axios
        .get("/api/GetUnreconciledEntries", {
          params: {
            businessPartnerId: businessPartnerSelected,
            businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
            ownCompanyId: companyId.current,
          },
        })
        .then((response) => {
          setRowsDataReconciledOwn(response.data.data.own);
          setStoreRowsDataReconciledOwn(response.data.data.own);
          setRowsDataReconciledBp(response.data.data.businessPartner);
          setStoreRowsDataReconciledBp(response.data.data.businessPartner);
          setShowLoadingIconReconcileBtn(false);
          setReFilter(true);
        })
        .catch((error) => {
          console.log("error GetUnreconciledEntries", error?.response);
          setShowLoadingIconReconcileBtn(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            setShowUploadErrorAlert(true);
            setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          } else {
            setShowUploadErrorAlert(true);
            setApiErrorMsg(`${defaultErrorMsg}GetUnreconciledEntries`);
            apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetUnreconciledEntries", error?.response);
      setShowLoadingIconReconcileBtn(false);
      setShowUploadErrorAlert(true);
      setApiErrorMsg(`${defaultErrorMsg}GetUnreconciledEntries`);
      apiSuccessErrorAlertSetTimeout(setShowUploadErrorAlert, setApiErrorMsg);
    }
  };

  const unReconcileShowDetailsClick = () => {
    const cleanedValue = matchIdValue.replace(/\s*,\s*/g, " "); // Replace commas surrounded by spaces with a single space
    const arrayFromString = cleanedValue.split(/\s+/).filter(Boolean);
    setMatchIdValueArr(arrayFromString);
    showDetailsOfEntriesOnMatchId(arrayFromString);
  };

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      textFieldRefUnReconcile.current.blur(); // Remove focus from the TextField
      unReconcileShowDetailsClick();
    }
  };
  const handleEnterKeyPressReconcileOwn = (e) => {
    if (e.keyCode === 13) {
      textFieldRefReconcileOwn.current.blur(); // Remove focus from the TextField
      filterRowsDataReconciledOwnFromEntryNum();
    }
  };
  const handleEnterKeyPressReconcileBp = (e) => {
    if (e.keyCode === 13) {
      textFieldRefReconcileBp.current.blur(); // Remove focus from the TextField
      filterRowsDataReconciledBpFromEntryNum();
    }
  };

  const filterRowsDataReconciledOwnFromEntryNum = () => {
    const tempRowData = storeRowsDataReconciledOwn;
    console.log("entryNumOwn", entryNumOwn);

    const numbersArray = entryNumOwn?.split(/[ ,]+/)?.map(Number);
    if (entryNumOwn?.trim()) {
      const filteredRowsData = tempRowData?.filter((item) => numbersArray?.includes(item.entryNo));
      setRowsDataReconciledOwn(filteredRowsData);
    } else {
      // entryNumOwn is empty or undefined, so do not apply filtering
      // Use the original data
      setIsFilterAppliedOwnSide(false);
      setRowsDataReconciledOwn(storeRowsDataReconciledOwn);
    }
  };
  const filterRowsDataReconciledBpFromEntryNum = () => {
    const tempRowData = storeRowsDataReconciledBp;
    const numbersArray = entryNumBp?.split(/[ ,]+/)?.map(Number);
    if (entryNumBp?.trim()) {
      const filteredRowsData = tempRowData?.filter((item) => numbersArray?.includes(item.entryNo));
      setRowsDataReconciledBp(filteredRowsData);
    } else {
      setIsFilterAppliedBpSide(false);
      setRowsDataReconciledBp(storeRowsDataReconciledBp);
    }
  };

  const manualReconContextValue: ManualReconContextInterface = {
    isOpenUnreconcileConfirmWarningModal,
    setIsOpenUnreconcileConfirmWarningModal,
    isOpenReconcileConfirmWarningModal,
    setIsOpenReconcileConfirmWarningModal,
    unReconcileEntriesOnMatchId,
    reconcileEntries,
    reconcileTotalSum,
    selectedRowOwn,
    selectedRowBp,
    amountTolerance,
    openManualReconReconcileOwnFilter,
    setOpenManualReconReconcileOwnFilter,
    storeRowsDataReconciledOwn,
    entryNumOwn,
    setEntryNumOwn,
    setIsFilterAppliedOwnSide,
    setRowsDataReconciledOwn,
    openManualReconReconcileBpFilter,
    setOpenManualReconReconcileBpFilter,
    storeRowsDataReconciledBp,
    entryNumBp,
    setEntryNumBp,
    setIsFilterAppliedBpSide,
    setRowsDataReconciledBp,
    absoluteReconcileTotalSum,
    showLoadingIconUnreconcile,
    showLoadingIconReconcile,
    gapAccountedName,
    reFilter,
    setReFilter,
    isFilterAppliedOwnSide,
    isFilterAppliedBpSide,
  };

  return (
    <>
      <ManualReconContext.Provider value={manualReconContextValue}>
        <Grid>
          <Grid>
            <Button
              className="theme_btn theme_btn_warning m_20 w_160"
              disabled={showLoadingIconReconcileBtn}
              onClick={() => {
                setManualReconType(manualReconTypeName.UNRECONCILE);
              }}
            >
              UNRECONCILE
            </Button>
            <Button
              className="theme_btn m_20 w_160"
              disabled={showLoadingIconReconcileBtn}
              onClick={() => {
                setManualReconType(manualReconTypeName.RECONCILE);
                getUnreconciledEntriesOnReconcileClick();
              }}
            >
              {showLoadingIconReconcileBtn ? (
                <span className="file-icon">
                  <i className="fas fa-circle-notch fa-spin" />
                </span>
              ) : null}
              RECONCILE
            </Button>
          </Grid>
          {manualReconType === manualReconTypeName.UNRECONCILE && (
            <Grid className="mb_15" justifyContent="center" display="flex">
              <div className="center_align matchId_heading">Type in the Match IDs You want to unreconcile</div>
            </Grid>
          )}

          <Grid container={true} className="min_height_500 manual_box_container" sx={{ position: "relative" }}>
            <Grid item={true} md={12} className="br_10 main_tables_box">
              {manualReconType === manualReconTypeName.UNRECONCILE && (
                <>
                  <Grid container={true} className="mb_15">
                    <Grid item={true} md={9}>
                      <TextField
                        inputRef={textFieldRefUnReconcile}
                        size="small"
                        variant="outlined"
                        fullWidth={false}
                        name="name"
                        placeholder="Insert Id's like I1, P7"
                        value={matchIdValue}
                        onChange={(e) => {
                          setMatchIdValue(e.target.value);
                        }}
                        onKeyDown={handleEnterKeyPress}
                        sx={{ background: "#fff", width: "400px" }}
                      />
                    </Grid>
                    <Grid item={true} md={3} className="flex_end">
                      <Button
                        className="theme_btn"
                        onClick={() => {
                          unReconcileShowDetailsClick();
                        }}
                      >
                        Show Details
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container={true}>
                    <Grid item={true} md={12}>
                      <BpDashboardTable
                        columns={columnDefinitionUnreconciled}
                        rows={rowsDataUnreconciled}
                        sortEnable={true}
                        fromTab="ManualRecon"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {manualReconType === manualReconTypeName.RECONCILE && (
                <>
                  <Grid className="mb_15" justifyContent="center" display="flex">
                    <div className="center_align matchId_heading">Gap: {reconcileTotalSum}</div>
                  </Grid>
                  <Grid container={true}>
                    <Grid item={true} md={5.9}>
                      <Grid container={true} className="mb_15">
                        <Grid item={true} md={7}>
                          <TextField
                            inputRef={textFieldRefReconcileOwn}
                            className="pl_10"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            name="name"
                            placeholder="Insert Entry no.s like 23, 24"
                            value={entryNumOwn}
                            onChange={(e) => {
                              setEntryNumOwn(e.target.value);
                            }}
                            onKeyDown={handleEnterKeyPressReconcileOwn}
                            sx={{ background: "white" }}
                          />
                        </Grid>
                        <Grid item={true} md={1} className="vertical_center_align">
                          <span
                            className="pl_10 cursor_pointer filter_icon"
                            onClick={() => {
                              setOpenManualReconReconcileOwnFilter(true);
                            }}
                          >
                            {isFilterAppliedOwnSide ? <FilterAltIcon /> : <FilterAltOffIcon />}
                          </span>
                        </Grid>
                        <Grid item={true} md={4} className="flex_end">
                          <Button
                            className="theme_btn"
                            onClick={() => {
                              filterRowsDataReconciledOwnFromEntryNum();
                            }}
                          >
                            Show Details
                          </Button>
                        </Grid>
                      </Grid>
                      <BpDashboardTable
                        columns={columnDefinitionReconciledOwn}
                        rows={rowsDataReconciledOwn}
                        setSelectedRow={setSelectedRowOwn}
                        sortEnable={true}
                        fromTab="ManualRecon"
                        actorType="BPDashboard"
                      />
                      <span style={{ fontSize: 11, color: "#777", textTransform: "capitalize" }}>
                        {Object.entries(hiddenColsReconOwn).filter((entry) => entry[1]).length &&
                          `These Columns Were Automatically Hidden: `}
                        {Object.entries(hiddenColsReconOwn)
                          .filter((entry) => entry[1])
                          .map((filtered) => filtered[0])
                          .join(", ")}
                      </span>
                    </Grid>
                    <Grid item={true} md={0.2} /> {/* space between tables */}
                    <Grid item={true} md={5.9}>
                      <Grid container={true} className="mb_15">
                        <Grid item={true} md={7}>
                          <TextField
                            inputRef={textFieldRefReconcileBp}
                            className="pl_10"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            name="name"
                            placeholder="Insert Entry no.s like 23, 24"
                            value={entryNumBp}
                            onChange={(e) => {
                              setEntryNumBp(e.target.value);
                            }}
                            onKeyDown={handleEnterKeyPressReconcileBp}
                            sx={{ background: "white" }}
                          />
                        </Grid>
                        <Grid item={true} md={1} className="vertical_center_align">
                          <span
                            className="pl_10 cursor_pointer filter_icon"
                            onClick={() => {
                              setOpenManualReconReconcileBpFilter(true);
                            }}
                          >
                            {isFilterAppliedBpSide ? <FilterAltIcon /> : <FilterAltOffIcon />}
                          </span>
                        </Grid>
                        <Grid item={true} md={4} className="flex_end">
                          <Button
                            className="theme_btn"
                            onClick={() => {
                              filterRowsDataReconciledBpFromEntryNum();
                            }}
                          >
                            Show Details
                          </Button>
                        </Grid>
                      </Grid>
                      <BpDashboardTable
                        columns={columnDefinitionReconciledBp}
                        rows={rowsDataReconciledBp}
                        setSelectedRow={setSelectedRowBp}
                        sortEnable={true}
                        fromTab="ManualRecon"
                        actorType="BPDashboard"
                      />
                      <span style={{ fontSize: 11, color: "#777", textTransform: "capitalize" }}>
                        {Object.entries(hiddenColsReconBp).filter((entry) => entry[1]).length &&
                          `These Columns Were Automatically Hidden: `}
                        {Object.entries(hiddenColsReconBp)
                          .filter((entry) => entry[1])
                          .map((filtered) => filtered[0])
                          .join(", ")}
                      </span>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            {/* <Grid item={true} md={0.2} /> */} {/* Empty spacer */}
            <Card className={showChangesDoneCard ? "changes_done active" : "changes_done"} component={"div"}>
              <IconButton className="open_changes_icon" onClick={() => setShowChangesDoneCard(!showChangesDoneCard)}>
                <ChevronLeft sx={{ rotate: showChangesDoneCard ? "180deg" : "0", transition: "inherit" }} />
              </IconButton>
              <Grid>
                {!hasManuallyReconciled ? (
                  <div className="changes_done_heading">Last Time Changes</div>
                ) : (
                  <div className="changes_done_heading">Changes Done</div>
                )}

                <div className="changes_box">
                  <Box sx={{ display: "flex", flexDirection: "column", px: 2, py: 1 }} className="total_changes_box">
                    <FormControlLabel
                      label={`Total: ${totalAmount ?? formatNumberINR(totalAmount)}`}
                      className="changes_done_total"
                      control={
                        <Checkbox
                          checked={isAllStrikethroughChecked}
                          indeterminate={
                            !isAllStrikethroughChecked && selectedValues.some((checkbox) => checkbox?.isSelected)
                          }
                          onChange={handleChangeParent}
                          style={{
                            opacity: !hasManuallyReconciled ? "1" : "0",
                            cursor: "unset",
                          }}
                        />
                      }
                      sx={{
                        mx: 0,
                        my: 0,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: !hasManuallyReconciled ? "300px" : "370px",
                      overflowY: "auto",
                      px: 2,
                      py: 1,
                      gap: 0.5,
                    }}
                    className="changes_done_box"
                  >
                    {checkboxDataManualRecon.map((data, index) => (
                      <FormControlLabel
                        className={
                          data.status === manualReconStatusName.manuallyUnreconciled
                            ? "changes_done_entries_unReconcile"
                            : "changes_done_entries_reconcile"
                        }
                        key={index}
                        label={formatNumberINR(data.amount)}
                        onClick={() => {
                          if (data.isValid) {
                            if (hasManuallyReconciled) {
                              if (data.status === manualReconStatusName.manuallyUnreconciled) {
                                getManuallyUnreconciledEntries(
                                  data.ownEntries,
                                  data.businessPartnerEntries,
                                  "showReconcileTable"
                                );
                              } else {
                                getManuallyReconciledEntries(
                                  data.ownEntries,
                                  data.businessPartnerEntries,
                                  "showUnReconcileTable"
                                );
                              }
                            } else {
                              if (data.status === manualReconStatusName.manuallyUnreconciled) {
                                getManuallyReconciledEntries(
                                  data.ownEntries,
                                  data.businessPartnerEntries,
                                  "showUnReconcileTable"
                                );
                                // don't show unreconcile button
                                setShowUnreconcileButton(false);
                              } else {
                                getManuallyUnreconciledEntries(
                                  data.ownEntries,
                                  data.businessPartnerEntries,
                                  "showReconcileTable"
                                );
                                // don't show reconcile button
                                setShowReconcileButton(false);
                              }
                            }
                          }
                        }}
                        control={
                          <Checkbox
                            checked={selectedValues[index]?.isSelected}
                            onChange={handleChangeChild(index)}
                            value={data.amount}
                            style={{
                              opacity: data.isValid && !hasManuallyReconciled ? "1" : "0",
                              // cursor: data.isValid && hasManuallyReconciled ? "pointer" : "unset",
                            }}
                          />
                        }
                        sx={{
                          mx: 0,
                          my: 0,
                          textDecoration: data.isValid ? "none" : "line-through",
                        }}
                      />
                    ))}
                  </Box>
                </div>
                {!hasManuallyReconciled && (
                  <div>
                    <div className="apply_changes">
                      <Button
                        className="apply_changes_text"
                        onClick={() => {
                          setShowApplyOptions(true);
                        }}
                        // disabled={selectedValues.length < 1 || selectedValues?.every((item) => item?.isSelected === false)}
                      >
                        Apply All Changes?
                      </Button>
                    </div>
                    {showApplyOptions && (
                      <div className="center_align">
                        <CheckIcon
                          className="cursor_pointer"
                          style={{ color: "#27b27c" }}
                          onClick={() => {
                            applyAllChanges();
                          }}
                        />
                        <ClearIcon
                          className="cursor_pointer"
                          style={{ marginLeft: "20px", color: "red" }}
                          onClick={() => {
                            handleCloseApplyAllChanges();
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid className="mt_30" display="flex" justifyContent="center" position="relative">
            {manualReconType === null && (
              <Button
                onClick={() => {
                  downloadExcelFromApi("fromDownloadBtn");
                }}
                className="theme_btn"
              >
                {showDownloadResultsLoadingIcon ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                Download Results
              </Button>
            )}
            {manualReconType === manualReconTypeName.UNRECONCILE && showUnreconcileButton && (
              <Button
                onClick={() => {
                  setIsOpenUnreconcileConfirmWarningModal(true);
                }}
                className="theme_btn theme_btn_warning w_300"
              >
                Unreconcile
              </Button>
            )}
            {manualReconType === manualReconTypeName.RECONCILE && showReconcileButton && (
              <Button
                disabled={selectedRowOwn?.length < 1 && selectedRowBp?.length < 1}
                onClick={() => {
                  absoluteReconcileTotalSum < 1
                    ? reconcileEntries(gapAccountedAsName.roundingError)
                    : setIsOpenReconcileConfirmWarningModal(true);
                }}
                className="theme_btn w_300"
              >
                Reconcile
              </Button>
            )}

            <Button
              className="theme_outline_btn"
              onClick={() => {
                setOpenManualRecon(false);
              }}
              sx={{ position: "absolute", right: "calc(30%)" }}
            >
              Back
            </Button>
          </Grid>

          <UnreconcileConfirmWarningModal />
          <ReconcileConfirmWarningModal />
          <ManualReconReconcileOwnFilter />
          <ManualReconReconcileBpFilter />
        </Grid>
      </ManualReconContext.Provider>
    </>
  );
};

export default ManualRecon;
