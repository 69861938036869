import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Nakad from "../../Graphics/NAKAD_FINAL (1).png";
import "../Login/Login.scss";
interface Password {
  password: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const { token } = useParams<{ token?: string }>();
  const { accountType } = useParams<{ accountType?: string }>();
  const history = useHistory();
  const [changedPassword, setChangedPassword] = useState<Password>({ password: "", confirmPassword: "" });
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangedPassword({ ...changedPassword, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    axios
      .get(`/api/Reset/${token}/${accountType}`)
      .then((response) => {
        console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
        alert(error.response.data.message);
        history.push("/reset");
      });
  }, [history, token, accountType]);

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (changedPassword.confirmPassword !== changedPassword.password) {
      alert("Password do not match. Please set it again.");
      history.push(`/reset/${token}/${accountType}`);
      setChangedPassword((p) => {
        p.confirmPassword = "";
        p.password = "";
        return p;
      });
      return;
    }
    try {
      const response = await axios.post(`/api/Reset/${token}/${accountType}`, {
        password: changedPassword.password,
      });
      console.log(response);
      alert(response.data.message);
      history.push("/login");
    } catch (error: any) {
      console.log(error);
      alert(error.response.data.message);
      history.push("/reset");
    }
  };
  return (
    <div className="resetpass_wrapper">
      <div>
        <img src={Nakad} alt="" className="resetlogo" />
        <p className="enter-info-text">Please enter your changed password</p>
        <form onSubmit={onSubmitHandler}>
          <div className="field">
            <div className="control">
              <input
                className="input input-box"
                type="password"
                name="password"
                placeholder="Enter Password"
                value={changedPassword.password}
                onChange={update}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input input-box"
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={changedPassword.confirmPassword}
                onChange={update}
                required={true}
              />
            </div>
          </div>
          <button type="submit" className="button login-button">
            Confirm
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
