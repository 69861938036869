import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useState } from "react";
import {
  ConfigUseCaseType,
  FileWiseDocTypeAndColumnHeader,
  UploadSummary,
} from "../../entity/recon-entity/ReconInterfaces";
import { userContext } from "../Contexts/userContext";
import useFetch from "./PartnerCommunication/useFetch";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface UploadSummaryres {
  uploadSummary: UploadSummary;
  configMapped: FileWiseDocTypeAndColumnHeader;
}

interface DuplicateModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  setApiErrorMsg: StateDispatch<string>;
  setShowErrorAlert: StateDispatch<boolean>;
  setShowUploadedSummary: StateDispatch<boolean>;
  businessPartnerId: number;
  userType: "own" | "businessPartner";
  configUseCaseType: ConfigUseCaseType;
  OnModalClose?: () => void;
  OnYesClick?: (res: AxiosResponse<UploadSummaryres>) => void;
}

export const DuplicateFoundModal = ({
  open,
  setOpen,
  setApiErrorMsg,
  setShowErrorAlert,
  ...props
}: DuplicateModalProps) => {
  const { actor } = useContext(userContext);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);

  const DeleteOnYes = async () => {
    useFetch("/api/DeleteDuplicateEntriesInUpload", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        businessPartnerId: props.businessPartnerId,
        userType: props.userType,
        configUseCaseType: props.configUseCaseType,
      },
      thenCallBack: () => {
        GetUploadSummaryOnYes();
      },
    });
  };

  const DownloadOnView = async () => {
    setShowLoadingIcon(true);
    useFetch<{ workbookBufferBase64: string }>("/api/ListDuplicateEntriesInUpload", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          businessPartnerId: props.businessPartnerId,
          userType: props.userType,
          configUseCaseType: props.configUseCaseType,
        },
      },
      thenCallBack: (res) => {
        const excelData = Buffer.from(res.data.workbookBufferBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, actor?.name + " - DuplicateEntries.xlsx");
        setShowLoadingIcon(false);
      },
    });
  };

  const DeleteOnModalClose = async () => {
    useFetch("/api/DeleteEntriesOnModalClose", "POST", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        businessPartnerId: props.businessPartnerId,
        userType: props.userType,
        configUseCaseType: props.configUseCaseType,
      },
      thenCallBack: (res) => {
        console.log(res);
        if (props.OnModalClose) props.OnModalClose();
      },
    });
  };

  const GetUploadSummaryOnYes = async () => {
    useFetch<UploadSummaryres>("/api/GetUploadSummary", "GET", {
      setApiErrorMsg,
      setShowErrorAlert,
      config: {
        params: {
          businessPartnerId: props.businessPartnerId,
          userType: props.userType,
          configUseCaseType: props.configUseCaseType,
        },
      },
      thenCallBack: (res) => {
        if (props.OnYesClick) props.OnYesClick(res);
        setOpen(false);
        props.setShowUploadedSummary(true);
      },
    });
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Duplicate Entries Found!</DialogTitle>
        <IconButton
          onClick={() => {
            setOpen(false);
            DeleteOnModalClose();
            // if (props.OnModalClose) props.OnModalClose();
            // props.setShowUploadedSummary(true);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>Would You like to Remove the Duplicates?</FormLabel>
        <Box display={"flex"} gap={2} justifyContent={"center"} py={2}>
          <Button className="theme_btn" onClick={DeleteOnYes} size="small">
            Yes
          </Button>
          <Button
            className="theme_btn"
            size="small"
            onClick={() => {
              setOpen(false);
              props.setShowUploadedSummary(true);
            }}
          >
            No
          </Button>
          <Button className="theme_btn" onClick={DownloadOnView} size="small">
            {showLoadingIcon ? (
              <span className="file-icon">
                <i className="fas fa-circle-notch fa-spin" />
              </span>
            ) : null}
            View
          </Button>
        </Box>
      </DialogContent>
      <DialogActions className="dialog_footer">
        {/* <Button
          autoFocus={true}
          onClick={() => {
            setOpen(false);
            props.setShowUploadedSummary(true);
          }}
        >
          Done
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateFoundModal;
