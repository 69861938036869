import { ValueFormatterParams } from "ag-grid-community";
import Dinero from "dinero.js";
import { DineroObject } from "dinero.js";

export function formatMoney(money: DineroObject): string {
  // if (money.amount === -0) {
  //   return '0';
  // }
  if (money === null || money === undefined) {
    return "NA";
  } else if (money.amount === -0) {
    money.amount = 0;
  }
  return Dinero(money).setLocale("en-IN").toFormat("$0,0");
}

export function currencyValueFormatter(params: ValueFormatterParams) {
  return formatMoney(params.value);
}

export function subtractMoney(money1: DineroObject, money2: DineroObject) {
  if (!money2) {
    return money1;
  }
  return Dinero(money1).subtract(Dinero(money2)).toObject();
}

export function addMoney(money1: DineroObject, money2: DineroObject) {
  if (!money2) {
    return money1;
  }
  return Dinero(money1).add(Dinero(money2)).toObject();
}

export function multiply(obj1: DineroObject, multiplier: number): DineroObject {
  return Dinero(obj1).multiply(multiplier).toObject();
}
