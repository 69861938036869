import AddIcon from "@mui/icons-material/Add";
import {
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import BusinessDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorResponse } from "../../../entity/recon-entity/ReconInterfaces";
import Loader from "../../Common/Loader";
import {
  apiSuccessErrorAlertSetTimeout,
  defaultErrorMsg,
} from "../ApiSuccessErrorAlertPopup/ApiSuccessErrorAlertSetTimeout";

const options = [
  {
    value: "Buyer",
    label: "Buyer",
  },
  {
    value: "Supplier",
    label: "Supplier",
  },
];

const EditBusinessPartner = (props: any) => {
  const [configList, setConfigList] = useState<any[]>([]);
  const [selectedAccountingSoftware, setSelectedAccountingSoftware] = useState<any>();
  const [selectedRelationship, setSelectedRelationship] = useState<any>();
  const [companyName, setCompanyName] = useState<any>("");
  const [companyGstNumber, setCompanyGstNumber] = useState<any>("");
  const initialTdsArr = [{ value: "0.10" }, { value: "0.09" }, { value: "0.08" }];
  const [tdsArr, setTdsArr] = useState<any>(initialTdsArr);
  const [errSelectedRelationship, setErrSelectedRelationship] = useState<boolean>(false);
  const [errCompanyName, setErrCompanyName] = useState<boolean>(false);
  const [errCompanyGstNumber, setErrCompanyGstNumber] = useState<boolean>(false);
  const [errPinCode, setErrPinCode] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<any>("");

  const [vendorCode, setVendorCode] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [showLoadingIconUpdate, setShowLoadingIconUpdate] = useState<boolean>(false);

  // Define GST and TDS values
  const gstValues = [0, 5, 12, 18, 28];
  const tdsValues = [0.1, 1, 2, 5, 10];
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cellToggles, setCellToggles] = useState([]);

  useEffect(() => {
    getLedgerConfigList();
    getBusinessPartnerDetails();
    // eslint-disable-next-line
  }, []);

  // Modify the toggleCell function to update tdsArr based on the selected combination values
  const toggleCell = (rowIndex, colIndex) => {
    const newCellToggles = [...cellToggles];
    newCellToggles[rowIndex][colIndex] = !newCellToggles[rowIndex][colIndex];

    const selectedGST = gstValues[rowIndex];
    const selectedTDS = tdsValues[colIndex];
    const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(2);

    if (newCellToggles[rowIndex][colIndex]) {
      // If the cell is toggled (checked), add the combination value to tdsArr
      const newTdsArr = [...tdsArr, { value: combination }];
      setTdsArr(newTdsArr);
    } else {
      // If the cell is unselected (unchecked), remove the corresponding combination value from tdsArr
      const newTdsArr = tdsArr.filter((item) => Number(item.value) !== Number(combination));
      setTdsArr(newTdsArr);
    }

    setCellToggles(newCellToggles);
  };

  const handleCheckboxChange = () => {
    setShowInput(!showInput);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0 && newValue <= 100) {
      setInputValue(newValue);
    }
  };
  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      const numericValue = parseFloat(inputValue);
      if (numericValue >= 0 && numericValue <= 100) {
        const stringValue = numericValue.toFixed(2).toString(); // Convert to a fixed-format string
        const uniqArr = removeDuplicates([...tdsArr, { value: stringValue }]);
        setTdsArr(uniqArr);
        setInputValue("");
        // Update cellToggles after adding the tag
        updateCellToggles(uniqArr);
        // setShowInput(false);
      } else {
        alert("Please enter a number between 0 and 100.");
      }
    }
  };
  const handleDeleteTag = (tagIndex) => {
    const updatedTdsArr = tdsArr.filter((_, index) => index !== tagIndex);
    setTdsArr(removeDuplicates(updatedTdsArr));
    // Update cellToggles after adding the tag
    updateCellToggles(removeDuplicates(updatedTdsArr));
  };

  const removeDuplicates = (arr) => {
    const uniqueValuesMap = new Map();
    const uniqueValuesArray = [];

    arr.forEach((tag) => {
      const numericValue = parseFloat(tag.value);

      if (!isNaN(numericValue)) {
        const stringValue = numericValue.toFixed(2).toString();

        if (!uniqueValuesMap.has(stringValue)) {
          uniqueValuesMap.set(stringValue, true);
          uniqueValuesArray.push(tag);
        }
      }
    });

    return uniqueValuesArray;
  };

  // Function to update cellToggles based on tdsArr and combination
  const updateCellToggles = (arr) => {
    const rows = gstValues.length;
    const cols = tdsValues.length;

    const updatedCellToggles = Array.from({ length: rows }, (_, rowIndex) =>
      Array.from({ length: cols }, (__, colIndex) => {
        const selectedGST = gstValues[rowIndex];
        const selectedTDS = tdsValues[colIndex];
        const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(2);

        // Check if the combination value exists in tdsArr

        return arr.some((item) => Number(item.value) === Number(combination));
      })
    );

    setCellToggles(updatedCellToggles);
  };

  const getBusinessPartnerDetails = () => {
    try {
      axios
        .get("/api/GetBusinessPartnerDetails", {
          params: {
            businessPartnerId: props.businessPartnerId,
          },
        })
        .then((response) => {
          setSelectedAccountingSoftware(response.data.businessPartnerInfo?.erpSoftware);
          setSelectedRelationship(response.data.businessPartnerInfo?.relationship);
          setCompanyName(response.data.businessPartnerInfo?.partnerName);
          setCompanyGstNumber(response.data.businessPartnerInfo?.gstin);
          setTdsArr(getUniqueList(response.data.businessPartnerInfo?.applicableTDS));
          updateCellToggles(removeDuplicates(response.data.businessPartnerInfo?.applicableTDS));
          setPinCode(response.data.businessPartnerInfo?.pincode?.toString());
          setVendorCode(response.data.businessPartnerInfo?.vendorCode?.toString());
          setLocation(response.data.businessPartnerInfo?.location);

          setLoader(false);
        })
        .catch((error) => {
          console.log("error GetBusinessPartnerDetails", error?.response);
          setLoader(false);
          if (error?.response?.data?.message !== undefined) {
            const dataObj = error.response.data as ErrorResponse;
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(dataObj.message);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          } else {
            props.setShowErrorAlert(true);
            props.setApiErrorMsg(`${defaultErrorMsg}GetBusinessPartnerDetails`);
            apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
          }
        });
    } catch (error: any) {
      console.log("error GetBusinessPartnerDetails", error?.response);
      setLoader(false);
      props.setShowErrorAlert(true);
      props.setApiErrorMsg(`${defaultErrorMsg}GetBusinessPartnerDetails`);
      apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
    }
  };

  const getLedgerConfigList = async () => {
    await axios
      .get("/api/ListAllLedgerConfigTemplate")
      .then((response) => {
        setConfigList(response.data.ledgerConfigTemplates);
      })
      .catch((err) => {
        console.log("get error", err.response.data.message);
      });
  };

  const handleClose = () => {
    props.setOpenBusinessPopup(false);
  };

  const getUniqueList = (originalList) => {
    const replacedList = originalList?.map((obj) => ({
      value: parseFloat(obj?.value) === 0 || obj?.value === "" ? 0.1 : parseFloat(obj?.value),
    }));

    const uniqueList = replacedList?.filter(
      (obj, index) => replacedList?.findIndex((o) => o.value === obj.value) === index
    );

    return uniqueList;
  };

  const updateBusinessPartnerDetails = async () => {
    let noErrorPresent = true;

    if (selectedRelationship === undefined) {
      console.log("errSelectedRelationship", errSelectedRelationship);
      setErrSelectedRelationship(true);
    }
    if (companyName.trim() === "") {
      setErrCompanyName(true);
    }
    if (
      companyGstNumber?.trim()?.length > 0 &&
      !companyGstNumber.match("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")
    ) {
      setErrCompanyGstNumber(true);
      noErrorPresent = false;
    }
    if (pinCode?.toString()?.trim()?.length > 0 && !pinCode?.toString()?.match("^[1-9][0-9]{5}$")) {
      setErrPinCode(true);
      noErrorPresent = false;
    }

    if (selectedRelationship !== undefined && companyName.trim() !== "" && noErrorPresent) {
      const tdsArrFilt = getUniqueList(tdsArr)?.filter((item: any) => {
        return item.value.toString().trim() !== "";
      });

      const convertapplicableTDSPercentagesToString = tdsArrFilt.map((item: any) => {
        return `${item.value}%`;
      });
      try {
        setShowLoadingIconUpdate(true);
        await axios
          .post("/api/UpdateBusinessPartnerDetails", {
            businessPartnerId: props.businessPartnerId,
            relationship: selectedRelationship,
            partnerName: companyName,
            vendorCode,
            gstin: companyGstNumber,
            pincode: pinCode,
            applicableTDS: convertapplicableTDSPercentagesToString.toString(),
            erpSoftware: selectedAccountingSoftware,
            location,
          })
          .then((response) => {
            console.log("sussess msg", response.data);
            const targetObject = props.storePartnerUseCaseMasterData.current.find(
              (v: any) => v.businessPartnerId === props.businessPartnerId
            );

            if (targetObject) {
              targetObject.applicableTds = getUniqueList(tdsArr);
              targetObject.relationship = selectedRelationship;
              targetObject.erpSoftware = selectedAccountingSoftware;
            }
            setShowLoadingIconUpdate(false);
            resetState();
            handleClose();
            props.setShowSuccessAlert(true);
            props.setApiErrorMsg(response.data.message);
            apiSuccessErrorAlertSetTimeout(props.setShowSuccessAlert, props.setApiErrorMsg);
          })
          .catch((error) => {
            console.log("error UpdateBusinessPartnerDetails", error?.response);
            setShowLoadingIconUpdate(false);
            if (error?.response?.data?.message !== undefined) {
              const dataObj = error.response.data as ErrorResponse;
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(dataObj.message);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            } else {
              props.setShowErrorAlert(true);
              props.setApiErrorMsg(`${defaultErrorMsg}UpdateBusinessPartnerDetails`);
              apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
            }
          });
      } catch (error: any) {
        console.log("error UpdateBusinessPartnerDetails", error?.response);
        setShowLoadingIconUpdate(false);
        props.setShowErrorAlert(true);
        props.setApiErrorMsg(`${defaultErrorMsg}UpdateBusinessPartnerDetails`);
        apiSuccessErrorAlertSetTimeout(props.setShowErrorAlert, props.setApiErrorMsg);
      }
    }
  };

  const resetState = () => {
    setSelectedRelationship(null);
    setSelectedAccountingSoftware(null);
    setCompanyName("");
    setCompanyGstNumber("");
    setVendorCode("");
    setLocation("");
    setTdsArr(initialTdsArr);
    setPinCode("");
    setErrSelectedRelationship(false);
    setErrCompanyName(false);
    setErrCompanyGstNumber(false);
    setErrPinCode(false);
    setInputValue("");
  };

  return (
    <>
      <BusinessDialog
        fullWidth={true}
        maxWidth="md"
        open={props.openBusinessPopup}
        onClose={handleClose}
        aria-labelledby="add-business-partner"
        className="businesspartner_popup"
      >
        <DialogTitle>Update Partner Details</DialogTitle>
        {loader ? (
          <Grid className="center_align_ver_horiz mb_80">
            <Loader />
          </Grid>
        ) : (
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} sm={12}>
                  <FormControl fullWidth={true} error={errSelectedRelationship}>
                    <Select
                      name="relationship"
                      variant="outlined"
                      required={true}
                      fullWidth={true}
                      displayEmpty={true}
                      error={errSelectedRelationship}
                      onChange={(e) => {
                        setSelectedRelationship(e.target.value);
                        setErrSelectedRelationship(false);
                      }}
                      value={selectedRelationship}
                      renderValue={() => (
                        <span style={{ textTransform: "capitalize" }}>
                          {selectedRelationship === "" ? "Select User Type" : selectedRelationship}
                        </span>
                      )}
                    >
                      <MenuItem disabled={true}>Select User Type</MenuItem>
                      {options.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          selected={selectedRelationship === item.value ? true : false}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errSelectedRelationship ? "please select user type" : ""}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    disabled={true}
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    label="Partner Name"
                    name="companyName"
                    error={errCompanyName}
                    helperText={errCompanyName ? "please enter partner name" : ""}
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      if (e.target.value.trim() !== "") {
                        setErrCompanyName(false);
                      } else {
                        setErrCompanyName(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    // required={true}
                    name="companyGstNumber"
                    label="GSTIN"
                    type="text"
                    error={errCompanyGstNumber}
                    helperText={errCompanyGstNumber ? "please enter correct company GST number" : ""}
                    value={companyGstNumber}
                    onChange={(e) => {
                      setCompanyGstNumber(e.target.value);
                      if (e.target.value.length > 0) {
                        if (!e.target.value.match("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")) {
                          setErrCompanyGstNumber(true);
                        } else {
                          setErrCompanyGstNumber(false);
                        }
                      } else {
                        setErrCompanyGstNumber(false);
                      }
                    }}
                  />
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="vendorCode"
                    label="Vendor Code"
                    type="text"
                    value={vendorCode}
                    onChange={(e) => {
                      setVendorCode(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="location"
                    label="Location"
                    type="text"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <Select
                    name="accountingSoftware"
                    variant="outlined"
                    fullWidth={true}
                    displayEmpty={true}
                    onChange={(e) => {
                      setSelectedAccountingSoftware(e.target.value);
                    }}
                    value={selectedAccountingSoftware}
                    renderValue={() => (
                      <span>
                        {selectedAccountingSoftware === "" ? "Select Accouting software" : selectedAccountingSoftware}
                      </span>
                    )}
                  >
                    <MenuItem selected={true} disabled={true} value="">
                      Select Accouting software
                    </MenuItem>
                    {configList.map((item) => (
                      <MenuItem key={item.templateId} value={item.templateName}>
                        {item.templateName}
                      </MenuItem>
                    ))}
                    <MenuItem key="other" value="Others" className="dropdown_other_highlight">
                      Other
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    // required={true}
                    name="PINCode"
                    label="PIN Code"
                    type="text"
                    error={errPinCode}
                    helperText={errPinCode ? "please enter correct PIN Code" : ""}
                    value={pinCode}
                    onChange={(e) => {
                      setPinCode(e.target.value);
                      if (e.target.value.length > 0) {
                        if (!e.target.value.match("^[1-9][0-9]{5}$")) {
                          setErrPinCode(true);
                        } else {
                          setErrPinCode(false);
                        }
                      } else {
                        setErrPinCode(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <div>
                    <label>TDS/TCS Percentage</label>
                  </div>
                  <table className="tds_gst_table mt_10">
                    <thead>
                      <tr>
                        <th className="text_center" colSpan={tdsValues.length + 2}>
                          TDS
                        </th>
                      </tr>
                      <tr>
                        <th />
                        <th />
                        {tdsValues.map((tds, index) => (
                          <th key={index} className="text_center">
                            {tds}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {gstValues.map((gst, rowIndex) => (
                        <tr key={rowIndex}>
                          {rowIndex === 0 ? (
                            <th
                              rowSpan={gstValues.length}
                              className="text_center"
                              style={{ verticalAlign: "middle", border: "1px solid #53526c" }}
                            >
                              GST
                            </th>
                          ) : null}
                          <th className="text_center">{gst}</th>
                          {tdsValues.map((tds, colIndex) => (
                            <td
                              key={`${colIndex}-${tds}`}
                              className={
                                cellToggles[rowIndex][colIndex]
                                  ? "toggled text_center cursor_pointer"
                                  : "text_center cursor_pointer"
                              }
                              onClick={() => toggleCell(rowIndex, colIndex)}
                            >
                              {cellToggles[rowIndex][colIndex] ? "✔" : "❌"}{" "}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <div className="mt_20">
                    <Checkbox onChange={handleCheckboxChange} />
                    <label className="fw_600">Add TDS % Manually (if it doesn't fit in the table)</label>
                  </div>
                  {showInput && (
                    <div>
                      <TextField
                        variant="outlined"
                        style={{ minWidth: "50%" }}
                        label="TDS/TCS Percentage"
                        name="value"
                        value={inputValue}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                      <IconButton color="primary" onClick={handleAddTag}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                  <div className="tag-container mt_20">
                    {tdsArr.map((tds, index) => (
                      <Chip
                        key={index}
                        label={tds.value}
                        onDelete={() => handleDeleteTag(index)}
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: "8px", marginBottom: "8px" }}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
              <DialogActions className="businessmodal_footer">
                <button onClick={handleClose} className="businesspartner_btn theme_outline_btn cursor_pointer">
                  Cancel
                </button>
                <button
                  onClick={() => updateBusinessPartnerDetails()}
                  className="businesspartner_btn theme_btn cursor_pointer center_align_ver_horiz"
                >
                  {showLoadingIconUpdate ? (
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                  ) : null}
                  Update
                </button>
              </DialogActions>
            </Box>
          </DialogContent>
        )}
      </BusinessDialog>
    </>
  );
};

export default EditBusinessPartner;
