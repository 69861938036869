import { Actor } from "../Actor";
import { ActorInfo } from "../recon-entity/ReconInterfaces";
/**
 * Utility interface for front end user.
 */

export enum ActorTypes {
  AtherTest = "AtherTest",
  Anchor = "Anchor",
  Tier1 = "Tier1",
  Tier2 = "Tier2",
  Bank = "Bank",
  Admin = "Admin",
  GroupedPartner = "GroupedPartner",
}

export enum UserTypes {
  Admin = "Admin",
  User = "User",
  CustomerSuccess = "CustomerSuccess",
}

export interface FrontendActor {
  id: any;
  name: string;
  acceptedTNC: boolean;
  actorType: ActorTypes;
  integration: boolean;
  userId: number;
  actorInfo: ActorInfo;
  replyToEmail?: string;
  userType?: UserTypes;
}

export function toFrontendActor(actor: Actor) {
  return {
    id: actor.actorId,
    name: actor.actorInfo.name,
    acceptedTNC: actor.acceptedTNC,
    actorType: actor.actorType,
    integration: actor.integration,
    userId: actor.userId,
    actorInfo: actor.actorInfo,
    replyToEmail: actor.replyToEmail,
  } as FrontendActor;
}
