import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Button from "@mui/material/Button";
import { useEffect, useRef } from "react";
import React from "react";

const DownloadTest = (props: any) => {
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!props.show) return null;

  return (
    <div ref={ref}>
      <Button
        variant="contained"
        value="Download"
        startIcon={<ArrowCircleDownIcon />}
        onClick={props.callbackFn}
        style={{
          backgroundColor: "#fff",
          color: "#363636",
          textTransform: "none",
          display: props.show ? "flex" : "none",
          float: "right",
          position: "fixed",
          right: "20px",
          bottom: "50px",
          zIndex: "999",
        }}
      >
        Download test file
      </Button>
    </div>
  );
};

export default DownloadTest;
